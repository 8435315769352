import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const semcastingConfig = {
  ...COMPANY_BASE_PROPERTIES,
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With Semcasting",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "semcasting",
  /**
   * HTML that explains how to use the form
   */
 instructions:
  "Select the device types or identifiers you'd like to deploy to your seat on your destination demand-side platform. You will also need to input required account information for each platform. This information may differ for each platform. Information may include account name, account number or ID, account or seat identification code, or encryption or API key. All fields must be completed in order to push an audience to your seat. Please add your Account Manager information if applicable.",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "semcasting",
    text: "Semcasting",
    img: "semcasting-logo-00a9c5.svg",
  },

  layout: [
    {
      fieldType: "row",
      layout: [
        {
          fieldType: "column",
          columnClass: "col-sm-6",
          layout: [
            {
              fieldType: "checkboxGroup",
              labelText: "Devices to Activate",
              fieldName: "device_type",
              required: true,
              checkboxes: [
                {
                  labelText: "Web Network Ranges",
                  fieldName: "web_network",
                  value: "web_network",
                  checked: false,
                },
                {
                  labelText: "Web WiFi Ranges",
                  fieldName: "web_wifi",
                  value: "web_wifi",
                  checked: false,
                },
                {
                  labelText: "Web Network and WiFi by Individual IP",
                  fieldName: "ip_single",
                  value: "ip_single",
                  checked: false,
                },
                //extra fields specifically for semcasting
                {
                  labelText: "Address",
                  fieldName: "address",
                  value: "address",
                  checked: false,
                },
                {
                  labelText: "Email",
                  fieldName: "email",
                  value: "email",
                  checked: false,
                },
                {
                  labelText: "SHA256 Email",
                  fieldName: "sha256email",
                  value: "sha256email",
                  checked: false,
                },
                {
                  labelText: "SHA1 Email",
                  fieldName: "sha1email",
                  value: "sha1email",
                  checked: false,
                },
                {
                  labelText: "MD5 Email",
                  fieldName: "md5email",
                  value: "md5email",
                  checked: false,
                },
                {
                  labelText: "Lat / Lng",
                  fieldName: "lat_lng",
                  value: "lat_lng",
                  checked: false,
                },
                {
                  labelText: "Phone Number",
                  fieldName: "phone",
                  value: "phone",
                  checked: false,
                },
                {
                  labelText: "Zip 5",
                  fieldName: "zip5",
                  value: "zip5",
                  checked: false,
                },
                {
                  labelText: "Zip 9",
                  fieldName: "zip9",
                  value: "zip9",
                  checked: false,
                },
                {
                  labelText: "Zip 11",
                  fieldName: "zip11",
                  value: "zip11",
                  checked: false,
                },
              ],
            },
          ],
        },
        {
          fieldType: "column",
          columnClass: "col-sm-6",
          layout: [
            {
              fieldType: "checkboxGroup",
              labelText: "",
              fieldName: "device_type",
              required: false,
              checkboxes: [
                {
                  labelText: "AdForm",
                  fieldName: "adform_id",
                  value: "ADFORM_ID",
                  disabled: true,
                  checked: false,
                },
                {
                  labelText: "Adobe",
                  fieldName: "adobe_id",
                  value: "ADOBE_ID",
                  checked: false,
                } /**,{
                                labelText:'Affinity',
                                fieldName:'affinity_id',
                                value:'AFFINITY_ID',
                                checked:false
                            } **/,
                {
                  labelText: "Amobee",
                  fieldName: "amobee_id",
                  value: "AMOBEE_ID",
                  checked: false,
                },
                {
                  labelText: "DeepIntent",
                  fieldName: "deepintent_id",
                  value: "DEEPINTENT_ID",
                  disabled: true,
                  checked: false,
                },
                {
                  labelText: "FreeWheel",
                  fieldName: "freewheel_id",
                  value: "FREEWHEEL_ID",
                  disabled: true,
                  checked: false,
                },
                {
                  labelText: "Facebook",
                  fieldName: "facebook_id",
                  value: "FACEBOOK_ID",
                  disabled: true,
                  checked: false,
                },
                {
                  labelText: "Roku",
                  fieldName: "roku",
                  value: "ROKU_ID",
                  checked: false,
                },
                {
                  labelText: "Spring Serve",
                  fieldName: "spring_serve_id",
                  value: "SPRING_SERVE_ID",
                  checked: false,
                },
                {
                  labelText: "Trade Desk",
                  fieldName: "trade_desk_id",
                  value: "TRADE_DESK_ID",
                  disabled: true,
                  checked: false,
                },
                {
                  labelText: "Xandr",
                  fieldName: "xandr_id",
                  value: "XANDR_ID",
                  checked: false,
                },
                {
                  labelText: "Zeta",
                  fieldName: "zeta",
                  value: "ZETA_ID",
                  checked: false,
                },
                {
                  labelText: "MRI Simmons",
                  fieldName: "mri_id",
                  value: "MRI_ID",
                  checked: false,
                },
                {
                  labelText: "I360",
                  fieldName: "i360_id",
                  value: "I360_ID",
                  checked: false,
                },
                {
                  labelText: "PDI",
                  fieldName: "pdi_id",
                  value: "PDI_ID",
                  checked: false,
                },
                {
                  labelText: "Freewheel MRM",
                  fieldName: "freewheel_mrm",
                  value: "FREEWHEEL_MRM",
                  checked: false,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Mobile Devices",
      fieldName: "mobile_devices",
      required: false,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: true,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },
      ],
    },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelect",
          fieldName: "path",
          labelText: "File Path",
          url: "/v2/secure/attributor/activate-credentials/semcasting/path",
          placeholder: "Select a File Path or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType: "searchSelect",
          fieldName: "account_email",
          labelText: "Account Email",
          url: "/v2/secure/attributor/activate-credentials/semcasting/account_email",
          placeholder: "Select an Account Email or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType:'checkbox',
          fieldName: "save_credentials",
          labelText:'Save Advertiser ID and File Path for future use',
          checked: false,
        },
      ],
    },
    {
      fieldType: "pricing",
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["path", "account_email"],
  formik: {
    initialValues: {
      device_type: [],
      mobile_devices: "top_ios_android",
      save_credentials: false,
      path: "",
      account_email: "",
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      path: Yup.string().required("File Path is required"),
      account_email: Yup.string()
        .email("Please enter a valid email")
        .required("Account Email is required"),
      device_type: Yup.array().min(1, "Must choose at least one"),
    }),
    onSubmit: (values) => {},
  },
};
