import React, {useEffect, useState} from "react";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./HomeOwnershipButtonGroup.css";

const HomeOwnershipButtonGroup = (props) => {
    const determineActive = (flag) => {
        const arr = props.controlsState.get("homeowner_flg");
        console.log(arr);
        if (arr) {
            if (flag === 'O' && arr.includes('Y')) return true;
            if (flag === 'R' && arr.includes('N')) return true;
        }
    };
    const [homeOwnerActive, setHomeOwnerActive] = useState(props.controlsState ?
        determineActive('O') : false);
    const [homeRenterActive, setHomeRenterActive] = useState(props.controlsState ?
        determineActive('R') :false);

    useEffect(() => {
        let arr = [];
        let values = [];
        if (homeOwnerActive) {
            arr.push('Y');
        }
        if (homeRenterActive) {
            arr.push('N');
        }
        props.formik.values.segment_design.primaryData.homeowner_flg = arr;
        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("homeowner_flg", arr)));
        }
    }, [homeOwnerActive, homeRenterActive]);

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.homeowner_flg) {
                props.segmentEditInfo.data.segment_design.primaryData.homeowner_flg.forEach(optionValue => {
                    if (optionValue === "Y") setHomeOwnerActive(true);
                    if (optionValue === "N") setHomeRenterActive(true);
                })
            }
        }
    }, [props.segmentEditInfo]);

    const onHomeOwnerChange = (e) => {
        e.preventDefault();
        setHomeOwnerActive(!homeOwnerActive);
        let arr = [];
        if (homeOwnerActive) arr.push('Y');

        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("homeowner_flg", arr)));
        }
    };

    const onHomeRenterChange = (e) => {
        e.preventDefault();
        setHomeRenterActive(!homeRenterActive);
        let arr = [];
        if (homeRenterActive) arr.push('N');

        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("homeowner_flg", arr)));
        }
    };

    return (
        <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 mb-3">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <ButtonGroup className={"targeting-full-onboarding-button-group"}>
                    <Button
                        className={homeOwnerActive ? "selected-ownership" : "default-ownership"}
                        togglable={true}
                        onClick={(e) => onHomeOwnerChange(e)}
                        selected={homeOwnerActive}
                        iconClass="fas fa-home">
                        Owner
                    </Button>
                    <Button
                        className={homeRenterActive ? "selected-ownership" : "default-ownership"}
                        togglable={true}
                        onClick={(e) => onHomeRenterChange(e)}
                        selected={homeRenterActive}
                        iconClass="fas fa-building">
                        Renter
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default HomeOwnershipButtonGroup;
