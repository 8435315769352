import React, {useState} from "react";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {Button} from "@progress/kendo-react-buttons";
import {Tooltip} from "@progress/kendo-react-tooltip";
import SegmentsEdit from "./SegmentsEdit";

const CategorySegmentsEdit = (props) => {

    const [visible, setVisible] = useState(props.visible);
    const [title, setTitle] = useState(props.selectedSegmentTitle);
    const [newSegmentsState, setNewSegmentsState] = useState({});
    const [segmentsState, setSegmentsState] = useState(props.segmentsState ? props.segmentsState : []);

    const handleSegmentsState = (state) => {
        // console.log("value of state in handleSegmentsState for cat seg", state);
        // console.log("value of state type", typeof(state));
        // console.log("value of state keys", Object.keys(state));
        // console.log("value of state keys length", Object.keys(state).length);
        setSegmentsState(state);
        props.handleSegmentsState(state);
    };

    const handleNewSegmentsState = (state) => {
        setNewSegmentsState(state);
    };

    const cancelSegmentSelections = (e, state) => {
        let segmentsState = props.segmentsState;
        Object.keys(state).map((key) => {
            delete segmentsState[key];
        })
        props.handleSegmentsState(segmentsState);
        props.setVisible(false);
    };

    return (
        <>
            {visible && (<Dialog className={"dialog-standard"} title={title} height={700} width={1400} closeIcon={false}>
                <SegmentsEdit
                    // formik={props.formik}
                    audienceState={props.audienceState}
                    handleAudienceState={props.handleAudienceState}
                    data={props.allData}
                    segmentsState={props.segmentsState}
                    handleSegmentsState={handleSegmentsState}
                    newSegmentsState={props.newSegmentsState}
                    handleNewSegmentsState={handleNewSegmentsState}
                    selectedTile={props.selectedTile}
                    selectedTileConfig={props.selectedTileConfig}
                    filtered={props.filteredData.filter((el)=>
                        el.value.groupName === props.selectedTile.groupName)}
                />

                <DialogActionsBar layout={"end"}>
                    <Tooltip
                        position="top"
                        anchorElement="target"
                        tooltipStyle={{
                            width: "10rem",
                            borderRadius: "0.25rem",
                        }}>
                        <Button
                            className={"button-standard button-white"}
                            title={"Cancel unsaved edits."}
                            onClick={(e) => cancelSegmentSelections(e, newSegmentsState)}>
                            Cancel
                        </Button>
                    </Tooltip>
                    <Tooltip
                        position="top"
                        anchorElement="target"
                        tooltipStyle={{
                            width: "8.5rem",
                            borderRadius: "0.25rem",
                        }}>
                        <Button
                            className={"button-standard button-submit"}
                            title={"Save current edits."}
                            onClick={props.saveSegmentSelections}>
                            Done
                        </Button>
                    </Tooltip>
                </DialogActionsBar>
            </Dialog>)}
        </>
    );
}

export default CategorySegmentsEdit;