import NumberFormat from "react-number-format";
import "./ActivateSegmentSummary.css";

const ActivateSegmentSummary = ({ leftData, rightData,chartId }) => {
  //For Sorting Activate Segment Summary to Match ADS
  const sortingArr1 = ["Total Records Uploaded", "Total Duplicate Records Uploaded", "Total Households","Total People", "Total Household Devices","Total Top Household Devices","Total Businesses", "Total Business Devices", "Total Top Business Devices"];
  const sortingArr2 = ["Total Household Network IP's", "Total Household WIFI IP's", "Total Phones", "Total Emails", "Total Business Network IP's", "Total Business WIFI IP's"];
  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" id={chartId}>
        <div className="card">
          <div className="text-center">
            <h4>Activate Segment Summary</h4>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="card-body-ActivateSegmentSummary">
                <div className="table-responsive">
                  <table className="table table-responsive-md table-striped">
                    <tbody>
                      {Object.entries(leftData).sort((a, b) => sortingArr1.indexOf(a) - sortingArr1.indexOf(b)).map(([key, value]) => {
                        return (
                          <tr>
                            <td>{key}</td>
                            <td className="right">
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={value}
                                decimalSeparator="."
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={true}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="card-body-ActivateSegmentSummary">
                <div className="table-responsive">
                  <table className="table table-responsive-md table-striped">
                    <tbody>
                      {Object.entries(rightData).sort((a, b) => sortingArr2.indexOf(a) - sortingArr2.indexOf(b)).map(([key, value]) => {
                        return (
                          <tr>
                            <td>{key}</td>
                            <td className="right">
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={value}
                                decimalSeparator="."
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={true}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ActivateSegmentSummary;
