import React, {useContext, useEffect, useRef, useState} from "react";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import MapComponent from "./MapComponent";
import {Button, ButtonGroup, Toolbar, ToolbarItem, ToolbarSeparator, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {
    postAudienceFileGeo,
    postLocationsForPoints,
    geoCode
} from "../../../actions/OnBoardingActions";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {Input} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";
import {Tooltip} from "@progress/kendo-react-tooltip";
import { Window } from "@progress/kendo-react-dialogs";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {useLoadScript} from "@react-google-maps/api";
import {FILE_TYPE_UNKNOWN, GOOGLE_MAPS_API_KEY, SUCCESS_STATUS} from "../../../api/constants";
import fileCoordinates from "../Segmentation/temporaryresources/sample-coordinates.txt";
import {OnBoardingContext} from "../../Pages/OnBoarding";
import "./Map.css";
import markerIcon from "../Segmentation/temporaryresources/images/pin-png-39475.png";

const google = window.google = window.google ? window.google : {};
const libraries = ['drawing'];

const Map = (props) => {
    const [pageLoader, setPageLoader] = useState(false);
    const [msg, setMsg] = useState("");
    const [address, setAddress] = useState("");
    const [center, setCenter] = useState({lat: 38, lng: -100});
    const [currentRadius, setCurrentRadius] = useState();
    const [showCurrentRadius, setShowCurrentRadius] = useState(false);
    const [zoom, setZoom] = useState(5);
    const [map, setMap] = useState(null);
    const [drawingManager, setDrawingManager] = useState(null);
    const [showSuccessLabel, setShowSuccessLabel] = useState(false);
    const [showErrorLabel, setShowErrorLabel] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorLocations, setErrorLocations] = useState([]);
    const [showErrorLocations, setShowErrorLocations] = useState(false);
    const [showErrorLocationsWindow, setShowErrorLocationsWindow] = useState(false);
    const [showUploadButton, setShowUploadButton] = useState(true);
    const [error, setError] = useState("");
    const [shapes, setShapes] = useState([]);
    const [selectedShape, setSelectedShape] = useState(null);
    const [circleActive, setCircleActive] = useState(false);
    const [polygonActive, setPolygonActive] = useState(false);
    const [rectangleActive, setRectangleActive] = useState(false);
    const [disableEraser, setDisableEraser] = useState(true);
    const [disableDrawing, setDisableDrawing] = useState(false);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [isFileValid, setIsFileValid] = useState(true);
    const [showFileFormatWindow, setShowFileFormatWindow] = useState(false);
    const [radiusValue, setRadiusValue] = useState({
        value: {
            text: "New Radius",
            meters: 8046.72
        }
    });
    const [disableNewRadius, setDisableNewRadius] = useState(true);

    const LOADING = "Loading...";
    const NO_COORDS = "No Coordinates Found!";
    const BAD_FILE_TYPE = "File not 'csv' or 'txt'!";

    const radiusSelections = [
        {text: "New Radius", meters: 8046.72},
        {text: "5 mi", meters: 8046.72},
        {text: "10 mi", meters: 16093.4},
        {text: "15 mi", meters: 24140.2},
        {text: "20 mi", meters: 32186.9},
        {text: "25 mi", meters: 40233.6}
    ]

    useEffect(() => {
        saveMap();
    }, [shapes]);

    useEffect(()  => {
        if (errorLocations.length > 0) {
            setShowErrorLocations(true);
        } else  {
            setShowErrorLocations(false);
        }
    }, [errorLocations])

    const mapRef = useRef(null);
    // We attach aa ref to the shapes state so we can always have
    // the accurate shape information (event listeners attached  before state update
    // may not provide accurate state.
    const shapesRef = useRef(shapes);

    const maxRadius = 40233; // In meters - equates to approximately 25 miles.
    const defaultLocationsRadius = 8046.72; // In meters - equates to approximately 5 miles.
    //FD: 10081 - Decrease to 250
    const maxLoadCapacity = 250; // Maximum number of records that can be loaded from file.

    // Default options for a shape
    const shapeOptions = {
        strokeWeight: 2,
        strokeColor:'#0000FF',
        // fillColor: '#FF000000',
        fillColor: '#CCE5FF',
        fillOpacity: 0.10,
        editable: false,
        draggable: true
    };

    const viewSampleFileFormat = (e) => {
        setShowFileFormatWindow(!showFileFormatWindow);
    };

    const viewErrorLocations = (e) => {
        setShowErrorLocationsWindow(!showErrorLocationsWindow);
    }

    const displayErrorLocations = () => {
        return (
            <Grid style={{height: "13rem"}}
                  data={errorLocations}>
                <GridColumn className={"map-error-records"} field={"primary_address"} title={"primary_address"} width={"200rem"}/>
                <GridColumn className={"map-error-records"} field={"secondary_address"} title={"secondary_address"} width={"150rem"}/>
                <GridColumn className={"map-error-records"} field={"city"} title={"city"} width={"150rem"}/>
                <GridColumn className={"map-error-records"} field={"state"} title={"state"} width={"60rem"}/>
                <GridColumn className={"map-error-records"} field={"zip5"} title={"zip5"} width={"65rem"}/>
                <GridColumn className={"map-error-records"} field={"radius"} title={"radius"} width={"70rem"}/>
            </Grid>
        )
    }

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: libraries
    });

    const handleCenter = () => {
        if (!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        setCenter(newPos);
    };

    const onMapLoaded = (mapInstance, drawingManagerInstance) => {
        mapRef.current = mapInstance;
        setMap(mapInstance);
        google.maps.event.addListener(mapInstance, 'click', function(e) {
            setShowCurrentRadius(false);
            clearSelections();
            clearStatus();
        });
        onDrawingManagerLoaded(drawingManagerInstance);
    };

    const onZoomChanged = () => {
        if (map) {
            setZoom(map.zoom);
        }
    };

    const onDrawingManagerLoaded = (drawingManagerInstance) => {
        drawingManagerInstance.setOptions({
            drawingControl: false,
            rectangleOptions: shapeOptions,
            polygonOptions: shapeOptions,
            circleOptions: shapeOptions}
        );
        google.maps.event.addListener(drawingManagerInstance, 'overlaycomplete', function (e) {
            overlayComplete(e, drawingManagerInstance);
        });
        setDrawingManager(drawingManagerInstance);
    }

    const mapShapeCreatedOrChanged = (shape) => {
        let changed = false;
        if (shape.formattedAddress || shape.method) {
            changed = true;
        }
        let squareMiles = null;
        let bounds = getShapeBounds(shape);
        reverseGeoCode(bounds.getCenter().lat(), bounds.getCenter().lng(), function(data) {
            const sqKm = computeAreaOfRectangle(bounds);
            let squareMiles = squareKmToMiles(sqKm);
            squareMiles = squareMiles >= 10 ? Math.round(squareMiles) : squareMiles.toFixed(2);
            shape.squareMiles = squareMiles;
            // shape.formattedAddress = data.results[0].formatted_address;
            let cityAndState = '';
            if (data.results && data.results.length) {
                cityAndState =  parseReverseGeocodeResults(data.results)
            } else if(data && data.plus_code && data.plus_code.compound_code) {
                cityAndState = parseCompoundCode(data.plus_code.compound_code);
            } else {
                cityAndState = formatLanLng(bounds.getCenter().lat(), bounds.getCenter().lng())
            }
            if (data.results && data.results[0]) {
                shape.formattedAddress = data.results[0].formatted_address;
            } else {
                shape.formattedAddress = cityAndState;
            }

            const metaData = {
                squareMiles:squareMiles,
                cityAndState:cityAndState
            };

            let arr = shapesRef.current;
            if (!changed) {
                arr.push(shape);
            }
            changed = false;
            shapesRef.current = arr;
            setShapes(arr);

            // saveMap(metaData);
            saveMap();
        });
    }

    const reverseGeoCode = (lat, lng, callback) => {
        geoCode(lat, lng, callback).then((res) => {
            if (res) {
                callback(res.data);
            }
        })
    };

    /**
     * returns the area of a rectangle in square kilometers
     * @param bounds returned from google
     * @returns {number} area of a rectangle in km Squared
     */
    const computeAreaOfRectangle = (bounds) => {
        if (!bounds) {
            return 0;
        }
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        const southWest = new google.maps.LatLng(sw.lat(), sw.lng());
        const northEast = new google.maps.LatLng(ne.lat(), ne.lng());
        const southEast = new google.maps.LatLng(sw.lat(), ne.lng());
        const northWest = new google.maps.LatLng(ne.lat(), sw.lng());
        return google.maps.geometry.spherical.computeArea([northEast, northWest, southWest, southEast]) / (1000000);
    };

    const squareKmToMiles = (kmSquared) => {
        const mi = 1.609344; //1 km = 1.609344 miles
        const milesSquared = mi*mi;
        return kmSquared / milesSquared;
    };

    /**
     * parses the city and state from the reverse geocode results
     * @param results reverse geo code results returned by google
     * @returns {String} the city and state returned by google
     */
    const parseReverseGeocodeResults = (results) => {
        if (!results || !results.length) {
            return '';
        }

        const result = results[0];
        const formattedAddress = result.formatted_address;
        const matches = formattedAddress.match(/[a-zA-Z ]+, [A-Z ]{2}/g); //turns "Bond, CO 80423, USA" into Bond, CO

        if(!matches){
            debugger
        }
        return matches && matches.length ? matches[0] : formattedAddress;

    };

    /**
     * parse the city and state from google maps compound_code
     * @param compoundCode compound_code returned from reverse geoCode
     * @returns {string}
     */
    const parseCompoundCode = (compoundCode) => {
        return compoundCode.replace(new RegExp('.+ (.+),.*', 'g'), '$1');
    };

    /**
     * properly formats lat and lng coordinates
     * @param lat {float} latitude
     * @param lng {float} longitude
     * @returns {string} formatted lat and lng
     */
    const formatLanLng = (lat, lng) => {
        let latLetter = lat < 0 ? 'S' : 'N';
        let lngLetter = lng < 0 ? 'W' : 'E';

        latLetter = lat === 0 ? '' : latLetter;
        lngLetter = lng === 0 ? '' : lngLetter;
        lat = Math.round(lat);
        lng = Math.round(lng);

        return lat+'&deg; '+latLetter+' and '+lng+'&deg; '+lngLetter;
    };

    const setSelection = (shape) => {
        shape.setEditable(true);
        setDisableEraser(false);
    };

    const clearSelections = () => {
        shapesRef.current.forEach(shape => {
            shape.setEditable(false);
        });
        setDisableEraser(true);
    };

    const deleteUploadedShapes = () => {
        for (let i = shapes.length-1; i >=0; --i) {
            if (shapes[i].method === "bulk") {
                shapes[i].setMap(null);
                if (shapes[i].marker) shapes[i].marker.setMap(null);
                shapes.splice(i, 1);
            }
        }
        saveMap();
        setShowUploadButton(true);
        setDisableNewRadius(true);
        setShowErrorLocations(false);
        setShowError(false);
        setShowErrorLocationsWindow(false);
        setShowCurrentRadius(false);
        setRadiusValue({
            value: {
                text: "New Radius",
                meters: 8046.72
            }});
    };

    const deleteShapes = () => {
        for (let i = shapes.length-1; i >=0; --i) {
            if (shapes[i].editable) deleteShape(shapes[i]);
        }
        setDisableEraser(true);
        setShowCurrentRadius(false);
        if (uploadedShapesFound()) {
            setDisableNewRadius(false);
            setShowUploadButton(false);
        } else {
            setDisableNewRadius(true);
            setShowUploadButton(true);
        }
    };

    const deleteShape = (shape) => {
        let localShapes = shapes;
        let index = localShapes.indexOf(shape);
        if (index >= 0) {
            localShapes.splice(index, 1);
            setShapes(localShapes);
        }
        shape.setMap(null);
        if (shape.marker) shape.marker.setMap(null);
        shape = null;
        setShowSuccessLabel(true);
        if (shapes.length === 0) {
            setMsg("");
        } else {
            saveMap();
        }
    };

    const uploadedShapesFound = () => {
        let cnt = 0;
        shapes.filter((shape)  => {
            if (shape.method  === "bulk") {
                cnt++;
            }
        });
        return cnt > 0;
    };

    const getShapeBounds = (shape) => {
        if (shape.getBounds) return shape.getBounds();
        let bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < shape.getPath().getLength(); i++) {
            bounds.extend(new google.maps.LatLng(shape.getPath().getAt(i).lat(), shape.getPath().getAt(i).lng()));
        }
        return bounds;
    };

    /**
     * Event handler for the 'move map' control.
     * @param e
     */
    // const moveMode = (e) => {
    //     e.preventDefault();
    //     clearStatus();
    //     drawingManager.setDrawingMode(null);
    //      setDisableMove(true);
    //     setDisableRectangle(false);
    //     setDisablePolygon(false);
    // };

    /**
     * Event handler for the 'circle' drawing control.
     * @param e
     */
    const drawCircleMode = (e) => {
        e.preventDefault();
        clearStatus();
        drawingManager.setDrawingMode("circle");
        setCircleActive(true);
        setPolygonActive(false);
        setRectangleActive(false);
        // setDisableMove(false);
    };

    /**
     * Event handler for the 'rectangle' drawing control.
     * @param e
     */
    const drawRectangleMode = (e) => {
        e.preventDefault();
        clearStatus();
        drawingManager.setDrawingMode("rectangle");
        setRectangleActive(true);
        setCircleActive(false);
        setPolygonActive(false);
    };

    /**
     * Event handler for the 'polygon' drawing control.
     * @param e
     */
    const drawPolygonMode = (e) => {
        e.preventDefault();
        clearStatus();
        drawingManager.setDrawingMode("polygon");
        setPolygonActive(true);
        setCircleActive(false);
        setRectangleActive(false);
    };

    /**
     * Event handler for the 'eraser' drawing control.
     * @param e
     */
    const eraserMode = (e) => {
        e.preventDefault();
        clearStatus();
        deleteShapes();
    };

    const editableShapes = () => {
        let arr = shapes.filter((shape) => {
            if (shape.editable) {
                setDisableEraser(true);
                return;
            }
        });
        setDisableEraser(false);
    };

    /**
     * Event handler for when the drawing of a shape is complete.
     * @param e
     * @param drawingManager
     */
    const overlayComplete = (e, drawingManager) => {
        let newShape = e.overlay;
        newShape.type = e.type;
        let dragging = false;
        drawingManager.setDrawingMode(null);
        if (newShape.type === 'circle') {
            if (Math.trunc(newShape.radius) > maxRadius) {
                newShape.setRadius(maxRadius);
            } else {
                newShape.setRadius(Math.trunc(newShape.getRadius()));
            }
            setShowCurrentRadius(true);
            setCurrentRadius(newShape.radius);
            newShape.addListener('dragstart', function () {
                clearStatus();
                dragging = true;
            });
            newShape.addListener('dragend', function(){
                clearStatus();
                dragging = false;
                mapShapeCreatedOrChanged(newShape);
            });
            newShape.addListener('center_changed', function () {
                clearStatus();
                mapShapeCreatedOrChanged(newShape);
            });
            newShape.addListener('radius_changed', function () {
                clearStatus();
                if (Math.trunc(newShape.radius) > maxRadius) {
                    newShape.setRadius(maxRadius);
                }
                mapShapeCreatedOrChanged(newShape);
                setShowCurrentRadius(true);
                setCurrentRadius(Math.trunc(newShape.radius));
            });
            const marker = new google.maps.Marker ({
                position: center,
                map,
                draggable: shapeOptions.draggable,
                // icon: {
                //     path: google.maps.SymbolPath.CIRCLE,
                //     strokeColor:'#2243B6',
                //     scale:2
                // }
                icon: {
                    url:markerIcon,
                    scaledSize: new google.maps.Size(20, 20),

                }
            });
            marker.setMap(newShape.map);
            marker.bindTo("position", newShape, "center");
            newShape.marker = marker;
        }
        if (newShape.type === 'rectangle') {
            // The only way to listen for a rectangle resize is with bounds_change, however bounds_change is also
            // fired repeatedly while dragging the shape.  So we stop listening for bounds_changed when dragging starts
            // and start listing for bounds_changed when dragging ends
            let boundsChangedHandle = newShape.addListener('bounds_changed', function () {
                clearStatus();
                mapShapeCreatedOrChanged(newShape);
            });
            newShape.addListener('dragstart', function () {
                clearStatus();
                boundsChangedHandle && boundsChangedHandle.remove();
            });
            newShape.addListener('dragend', function() {
                boundsChangedHandle = newShape.addListener('bounds_changed', function() {
                    clearStatus();
                    mapShapeCreatedOrChanged(newShape);
                });
                clearStatus();
                mapShapeCreatedOrChanged(newShape);
            });
        } else if (newShape.type === "polygon") {
            newShape.addListener('dragstart', function () {
                clearStatus();
                dragging = true;
            });
            newShape.addListener('dragend', function(){
                clearStatus();
                dragging = false;
                mapShapeCreatedOrChanged(newShape);
            });
            //this listens for changes to the polygon
            //to listen to changes on a polygon you actually need to listen to all the paths of the polygon
            //https://stackoverflow.com/questions/20789385/how-can-i-detect-when-an-editable-polygon-is-modified
            newShape.getPaths().forEach(function(path) {
                path.addListener('set_at', function() {
                    clearStatus();
                    if (dragging) return;
                    mapShapeCreatedOrChanged(newShape);
                });

                path.addListener('insert_at', function() {
                    clearStatus();
                    mapShapeCreatedOrChanged(newShape);
                });

                path.addListener('remove_at', function() {
                    clearStatus();
                    mapShapeCreatedOrChanged(newShape);
                });
            });
        }
        // Add an event listener that selects the newly-drawn shape when the user  mouses down on it.
        google.maps.event.addListener(newShape, 'click', function (e) {
            clearStatus();
            setSelection(newShape);
            if (newShape.type === "circle") {
                setCurrentRadius(Math.trunc(newShape.radius));
                setShowCurrentRadius(true);
            } else {
                setShowCurrentRadius(false);
            }
        });

        // let arr = shapes;
        // arr.push(newShape);
        // setShapes(arr);
        // newShape.setEditable(false);
        mapShapeCreatedOrChanged(newShape);
        setCircleActive(false);
        setPolygonActive(false);
        setRectangleActive(false);
    };

    const clearStatus = () => {
        setShowSuccessLabel(false);
        setShowErrorLabel(false);
    };

    const hasEditableShapes = () => {
        shapes.forEach((shape) => {
            if (shape.setEditable) return true;
        });
        return false;
    };

    /**
     * Saves the map metadata
     */
    const saveMap = () => {
        setShowSuccessLabel(false);
        console.log(shapesRef.current);
        let geoShapes = [];
        if (shapesRef.current.length === 0) return;
        // let cityState = metaData.cityAndState;
        // cityState = cityState.split('&deg; ').join(''); //remove html entity &deg;
        // cityState = cityState.split(' ').join('_'); //replaces spaces with underscore
        // cityState = cityState.replace(new RegExp('[^a-zA-Z0-9_\-]', 'g'), ''); //remove non alphanumerics keep underscores and hyphens
        // let fileName = cityState+'_'+metaData.squareMiles+'_sqmi.json';
        const fileName = "shapes-coordinates.json"

        for (const shape of shapesRef.current) {
            if (shape.type === "circle") {
                console.log(shape);
                let circleInfo = {};
                const lat = shape.center.lat();
                const lng = shape.center.lng();
                circleInfo.center = {"lat": lat, "lng": lng};
                circleInfo.radius = shape.radius;
                circleInfo.type = shape.type;
                geoShapes.push(circleInfo);
                continue;
            }
            const googleBounds = getShapeBounds(shape);
            let flattenedBounds = flattenBounds(googleBounds);
            flattenedBounds.type = shape.type;
            delete flattenedBounds.center;
            if (shape.type === 'polygon') {
                flattenedBounds.path = getPolygonPath(shape);
            }
            geoShapes.push(flattenedBounds);
        }
        let geoAttributes = {id: 0, job_id: 0, original_name: fileName, shapes: geoShapes};
        console.log(geoAttributes);
        postAudienceFileGeo(geoAttributes).then((data) => {
            if (data.id > 0) {
                shapes.forEach((shape) => {
                    if (!shape.id) shape.id = data.id;
                });
                // shape.id = data.id;
                // setSelectedShape(shape);
                setShowSuccessLabel(true);
                props.formik.values.audience_file_id = data.id;
            } else {
                setShowSuccessLabel(false);
            }
        });
    };

    /**
     * returns a bounds object with all it's properties set
     * @param googleBounds
     * @returns {{center: {lat: *, lng: *}, neLat: *, neLng: *, swLat: *, swLng: *}}
     */
    const flattenBounds = (googleBounds) => {
        return {
            center: {
                lat: googleBounds.getCenter().lat(),
                lng: googleBounds.getCenter().lng()
            },
            neLat: googleBounds.getNorthEast().lat(),
            neLng: googleBounds.getNorthEast().lng(),
            swLat: googleBounds.getSouthWest().lat(),
            swLng: googleBounds.getSouthWest().lng()
        }
    };

    const getPolygonPath = (polygon) => {
        let path = [];
        for (let i = 0; i < polygon.getPath().getLength(); i++) {
            path.push(polygon.getPath().getAt(i).toJSON());
        }
        return path;
    };

    const zoomIn = () => {
        map.setZoom(map.getZoom() + 1);
    };

    const zoomOut = () => {
        map.setZoom(map.getZoom() - 1);
    };

    const onLocationSelected = (shape) => {
        setSelection(shape);
    };

    const onSquareMilesSelected = (shape) => {
        setSelection(shape);
    };

    const readFileAsText = (file) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsText(file);
        });
    };

    const validateFileContents = async (file) => {
        let locations = [];
        let errorRecords = [];
        try {
            const fileContents = await readFileAsText(file);
            let allLines = fileContents.split(/\r\n|\n/).slice(0, maxLoadCapacity);
            let lineCnt = 0;
            allLines.forEach((line) => {
                console.log(line);
                if (line.toLowerCase().includes("primary_address")) return;
                let newLine = line.replaceAll('"', '');
                if (newLine === "") return;
                const lineArray = newLine.split(',');
                // Check for positional fields and zip 5 is 5 digits
                if ((lineArray.length !== 5 && lineArray.length !== 6) || (lineArray[4].length !== 5)) {
                    // errorRecords.push(newLine);
                    // errorRecords.push(lineArray);
                    errorRecords.push(
                        {"primary_address": lineArray[0],
                         "secondary_address": lineArray[1],
                         "city": lineArray[2],
                         "state": lineArray[3],
                         "zip5": lineArray[4],
                         "radius": lineArray[5]
                        }
                    )
                    return;
                }
                if (lineArray.length === 5) {
                    locations.push(
                        {
                            "primary_address": lineArray[0],
                            "secondary_address": lineArray[1],
                            "city": lineArray[2],
                            "state": lineArray[3],
                            "zip5": lineArray[4],
                            "radius": defaultLocationsRadius
                        }
                    )
                } else if (lineArray.length === 6) {
                    let customRadius = parseInt(lineArray[5]);
                    if (isNaN(customRadius)) {
                        // errorRecords.push(newLine);
                        errorRecords.push(lineArray);
                        return;
                    }
                    if (customRadius > 40233) customRadius = 40233; // Enforce 25 mile radius limit
                    locations.push(
                        {
                            "primary_address": lineArray[0],
                            "secondary_address": lineArray[1],
                            "city": lineArray[2],
                            "state": lineArray[3],
                            "zip5": lineArray[4],
                            "radius": customRadius
                        }
                    )
                }
            });
            setErrorLocations(errorRecords);
            setDisableNewRadius(false);
            return locations;
        } catch (e) {
            console.log("Could not read file");
        }
    };

    const onFileSelected = async (event) => {
        try {
            console.log(event);
            const fileInput = event.currentTarget;
            if (!fileInput.files || !fileInput.files.length) {
                return;
            }
            setDisableFileUpload(true);
            props.handleDisableSubmit(true);
            setDisableDrawing(true);
            setShowLoading(true);
            setShowError(false);
            setShowSuccessLabel(false);
            setShowErrorLabel(false);
            setShowErrorLocations(false);
            const file = fileInput.files[0];
            if (file.type !== "text/csv" && file.type !== "text/plain") {
                setShowLoading(false);
                setError(BAD_FILE_TYPE);
                setShowError(true);
                throw(error);
            }
            setErrorLocations([]);
            const locations = await validateFileContents(file);
            const mapAddresses = {"map-addresses": locations}
            // SEND LOCATIONS ARRAY TO BACKEND ENDPOINT - GET ARRAY OF LAT/LNG BACK..
            let data = null;
            await postLocationsForPoints(mapAddresses).then((data) => {
                console.log(data);
                if (data['address-points']) {
                    console.log(data['address-points'])
                    if (data['address-points'].length === 0) {
                        setShowLoading(false);
                        setError(NO_COORDS);
                        setShowError(true);
                    } else {
                        setShowLoading(false);
                        setShowError(false);
                        drawCircles(data['address-points']);
                    }
                }
            });
            setDisableFileUpload(false);
            props.handleDisableSubmit(false);
            setDisableDrawing(false);
            setShowUploadButton(false);
        } catch (error) {
            setDisableFileUpload(false);
            props.handleDisableSubmit(false);
            setDisableDrawing(false);
            setShowLoading(false);
        }
    };


    // Change radius for all uploaded circles
    const changeRadius = (e) => {
        setRadiusValue({value: e.target.value});
        shapesRef.current.map((shape) => {
            if (shape.type === "circle" && shape.method === "bulk") {
                google.maps.event.clearListeners(shape, "radius_changed");
                shape.setRadius(parseFloat(e.target.value.meters));
                shape.addListener("radius_changed");
            }
        })
        saveMap();
    }

    // Display circles on the map from uploaded file of coordinates and radius.
    // Since these circles are produced from known locations (uploaded file) we
    // do not allow these to be dragged (which would change the center coords from
    // the uploaded file.
    const drawCircles = (coords) => {
        let markers = [];
        let circles = [];
        let firstCoord = null;
        let bounds = new google.maps.LatLngBounds();
        coords.map((coord) => {
            if (!firstCoord) firstCoord = {lat: coord.latitude, lng: coord.longitude};
            const center = {lat: parseFloat(coord.latitude), lng: parseFloat(coord.longitude)};
            const radius = parseFloat(coord.radius);
            const point = new google.maps.LatLng(parseFloat(coord.latitude), parseFloat(coord.longitude));
            bounds.extend(point);
            const circle = new google.maps.Circle({
                strokeColor: shapeOptions.strokeColor,
                strokeWeight: shapeOptions.strokeWeight,
                fillColor: shapeOptions.fillColor,
                fillOpacity: shapeOptions.fillOpacity,
                editable: shapeOptions.editable,
                draggable: false,
                map,
                center: center,
                radius: radius
            });
            const marker = new google.maps.Marker ({
                position: center,
                map,
                draggable: false,
                // icon: {
                //     path: google.maps.SymbolPath.CIRCLE,
                //     strokeColor:'#2243B6',
                //     scale:2
                // }
                icon: {
                    url:markerIcon,
                    scaledSize: new google.maps.Size(20, 20),

                }

            });
            marker.bindTo("position", circle, "center");
            markers.push(marker);

            let newShape = circle;
            newShape.marker = marker;
            newShape.type = "circle";
            newShape.method = "bulk";
            newShape.addListener("click", () => {
                setSelection(newShape);
                setCurrentRadius(Math.trunc(newShape.radius));
                setShowCurrentRadius(true);
            });
            newShape.addListener("radius_changed", () => {
                clearStatus();
                if (Math.trunc(newShape.radius) > maxRadius) {
                    newShape.setRadius(maxRadius);
                }
                setShowCurrentRadius(true);
                setCurrentRadius(Math.trunc(newShape.radius));
                // mapShapeCreatedOrChanged(newShape);
                saveMap();
            });

            circles.push(newShape);
        });
        setCenter({lat: firstCoord.lat, lng: firstCoord.lng});
        map.setCenter({lat: firstCoord.lat, lng: firstCoord.lng});
        // setZoom(8);
        // map.fitBounds(bounds);
        setShowLoading(false);
        let arr = shapesRef.current;
        let combined = arr.concat(circles);
        shapesRef.current = combined;
        setShapes(combined);
        // setUploadedShapes(circles);
    };

    const onLocationClick = (event) => {
        console.log(event);
        const { target = {} } = event || {};
        target.value = "";
    };

    const renderMap = () => {
        const mapOptions = {
            center: center,
            zoom: zoom,
            gestureHandling: "auto",
            disableDefaultUI: true,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                position: google.maps.ControlPosition.TOP_RIGHT
            },

            drawingControl: false
        };

        return (
            <div>
                <LoadOverlay active={pageLoader}>
                    <Toolbar>
                        <ToolbarItem>
                            <ButtonGroup>
                                <Tooltip
                                    position="right"
                                    anchorElement="target"
                                    tooltipStyle={{
                                        width: "6rem",
                                        borderRadius: "0.25rem",
                                    }}
                                >
                                    <Button
                                        className={!circleActive ? "map-draw-button" : "map-draw-button-active"}
                                        title={"Draw Circle"}
                                        disabled={disableDrawing}
                                        onClick={drawCircleMode}>
                                        <div style={{alignItems: "center"}}>
                                            <i className="fa fa-circle-o" style={{color: "#269DB6"}}
                                               title={"Draw Circle"}></i>
                                        </div>
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    position="right"
                                    anchorElement="target"
                                    tooltipStyle={{
                                        width: "7rem",
                                        borderRadius: "0.25rem",
                                    }}
                                >
                                    <Button
                                        className={!polygonActive ? "map-draw-button" : "map-draw-button-active"}
                                        title={"Draw Polygon"}
                                        disabled={disableDrawing}
                                        onClick={drawPolygonMode}>
                                        <div style={{alignItems: "center"}}>
                                            <svg style={{color: "#269DB6", fontSize: "16px"}}
                                                 stroke="currentColor"
                                                 fill="currentColor"
                                                 strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em"
                                                 title={"Draw Polygon"}
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M384 352c-.35 0-.67.1-1.02.1l-39.2-65.32c5.07-9.17 8.22-19.56 8.22-30.78s-3.14-21.61-8.22-30.78l39.2-65.32c.35.01.67.1 1.02.1 35.35 0 64-28.65 64-64s-28.65-64-64-64c-23.63 0-44.04 12.95-55.12 32H119.12C108.04 44.95 87.63 32 64 32 28.65 32 0 60.65 0 96c0 23.63 12.95 44.04 32 55.12v209.75C12.95 371.96 0 392.37 0 416c0 35.35 28.65 64 64 64 23.63 0 44.04-12.95 55.12-32h209.75c11.09 19.05 31.49 32 55.12 32 35.35 0 64-28.65 64-64 .01-35.35-28.64-64-63.99-64zm-288 8.88V151.12A63.825 63.825 0 0 0 119.12 128h208.36l-38.46 64.1c-.35-.01-.67-.1-1.02-.1-35.35 0-64 28.65-64 64s28.65 64 64 64c.35 0 .67-.1 1.02-.1l38.46 64.1H119.12A63.748 63.748 0 0 0 96 360.88zM272 256c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zM400 96c0 8.82-7.18 16-16 16s-16-7.18-16-16 7.18-16 16-16 16 7.18 16 16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 416c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zm336 16c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z"></path>
                                            </svg>
                                        </div>
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    position="right"
                                    anchorElement="target"
                                    tooltipStyle={{
                                        width: "8rem",
                                        borderRadius: "0.25rem",
                                    }}
                                >
                                    <Button
                                        className={!rectangleActive ? "map-draw-button" : "map-draw-button-active"}
                                        title={"Draw Rectangle"}
                                        disabled={disableDrawing}
                                        onClick={drawRectangleMode}>
                                        <div style={{alignItems: "center"}}>
                                            <svg style={{color: "#269DB6", fontSize: "14px"}}
                                                 stroke="currentColor"
                                                 fill="currentColor"
                                                 strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em"
                                                 title={"Draw Rectangle"}
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M512 128V32c0-17.67-14.33-32-32-32h-96c-17.67 0-32 14.33-32 32H160c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v96c0 17.67 14.33 32 32 32v192c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32h192c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32V160c17.67 0 32-14.33 32-32zm-96-64h32v32h-32V64zM64 64h32v32H64V64zm32 384H64v-32h32v32zm352 0h-32v-32h32v32zm-32-96h-32c-17.67 0-32 14.33-32 32v32H160v-32c0-17.67-14.33-32-32-32H96V160h32c17.67 0 32-14.33 32-32V96h192v32c0 17.67 14.33 32 32 32h32v192z"></path>
                                            </svg>
                                        </div>
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    position="right"
                                    anchorElement="target"
                                    tooltipStyle={{
                                        width: "8rem",
                                        borderRadius: "0.25rem",
                                    }}
                                >
                                    <Button
                                        className={"map-draw-button"}
                                        title={"Erase Selected Shape"}
                                        onClick={eraserMode}
                                        disabled={disableEraser}>
                                        <div style={{alignItems: "center"}}>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0"
                                                 viewBox="0 0 512 512" height="1em"
                                                 width="1em" xmlns="http://www.w3.org/2000/svg"
                                                 title={"Erase Selected Shape"}
                                                 style={{color: "#269DB6", fontSize: "16px"}}>
                                                <path
                                                    d="M497.941 273.941c18.745-18.745 18.745-49.137 0-67.882l-160-160c-18.745-18.745-49.136-18.746-67.883 0l-256 256c-18.745 18.745-18.745 49.137 0 67.882l96 96A48.004 48.004 0 0 0 144 480h356c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H355.883l142.058-142.059zm-302.627-62.627l137.373 137.373L265.373 416H150.628l-80-80 124.686-124.686z"></path>
                                            </svg>
                                        </div>
                                    </Button>
                                </Tooltip>
                            </ButtonGroup>
                        </ToolbarItem>

                        <ToolbarSeparator/>

                        {showUploadButton && (
                        <ToolbarItem className={"map-file-upload-div"}>
                            <input
                                id={"file-upload"}
                                onChange={onFileSelected}
                                disabled={disableFileUpload}
                                onClick={onLocationClick}
                                multiple={false}
                                type="file"
                                hidden
                            />
                            <label className="file-upload" htmlFor="file-upload">
                                <i className="fas fa-upload" style={{paddingRight: ".3rem"}}></i>
                                Upload Locations
                            </label>
                        </ToolbarItem>
                        )}
                        {!showUploadButton && (
                            <ToolbarItem className={"map-file-upload-div"}>
                                <Button onClick={deleteUploadedShapes}>
                                    Clear Uploaded Locations
                                </Button>
                            </ToolbarItem>
                        )}
                        <ToolbarSeparator/>
                        <ToolbarItem>
                            <DropDownList
                                data={radiusSelections}
                                textField={"text"}
                                dataItemKey={"meters"}
                                value={radiusValue.value}
                                onChange={changeRadius}
                                disabled={disableNewRadius}
                            />

                        </ToolbarItem>
                        <ToolbarItem>
                            {showLoading && (
                                <div className={"text-warning"}>
                                    {LOADING}
                                    <div className={"dot-flashing"}></div>
                                </div>)
                            }
                            {showError && (
                                <div className={"text-danger"}>
                                    {error}
                                </div>)
                            }
                        </ToolbarItem>
                        <ToolbarSeparator/>
                        <ToolbarItem className={"map-sample-file-link"}>
                            <a
                                href="#"
                            onClick={(e) => viewSampleFileFormat(e)}>View Sample File Format</a>
                        </ToolbarItem>
                        <ToolbarSeparator/>
                        <ToolbarItem className={"map-status-area"}>
                            {showSuccessLabel && (
                                <Button className={"draw-success-label"} disabled>
                                    <Label className={"draw-success-label-text"}>Saved</Label>
                                </Button>)
                            }
                            {showErrorLabel && (
                                <Button className={"draw-error-label"} disabled>
                                    <Label className={"draw-error-label-text"}>Error</Label>
                                </Button>)
                            }
                        </ToolbarItem>
                        {showCurrentRadius && (
                            <ToolbarItem className={"map-current-radius"}>
                                <Label><b>Radius</b>: {currentRadius} m</Label>
                            </ToolbarItem>
                        )}
                        <ToolbarItem>
                            {showErrorLocations && (
                                <a href={"#"} onClick={viewErrorLocations} className={"text-danger map-problem-locations"}>{errorLocations.length} Problem Locations</a>
                            )}
                        </ToolbarItem>
                    </Toolbar>

                    <div className="col-md-12 mt-1 p-0">
                        <Label style={{color: "red"}}>{msg}</Label>
                    </div>
                    <div style={{paddingBottom: ".5rem"}}/>
                    <div className="row">
                        <div className={"col-md-12"}>
                            <MapComponent
                                id={"mapDiv"}
                                options={mapOptions}
                                onLoad={onMapLoaded}
                                onDragEnd={handleCenter}
                                onZoomChanged={onZoomChanged}
                                msg={msg}
                                setMsg={setMsg}
                            >
                            </MapComponent>
                        </div>
                    </div>
                </LoadOverlay>

                {showFileFormatWindow && (<Window title={"Locations File Format"} onClose={viewSampleFileFormat} height={340} width={550}>
                    <Label className={"field-label upload-template-dialog-warning"}><b>This file MUST contain locations in the following accepted formats:</b></Label>
                    <p><Label className={"field-label"}><b>primary_address, secondary_address, city, state, zip5</b></Label>
                    <Label className={"field-label"}>110 Harvard Rd,,Littleton,MA,01460<br/>
                        147 King St,Apt 609,Littleton,MA,01460</Label></p>
                    <p><Label className={"field-label"}><b>primary_address, secondary_address, city, state, zip5, radius</b></Label>
                    <Label className={"field-label"}>110 Harvard Rd,,Littleton,MA,01460,200</Label></p>
                    <p><Label className={"field-label"}><b>NOTE: Radius is optional and it is specified in meters.  If no radius is provided, a default of 8046.72 meters (5 miles) will apply to that location.</b></Label></p>
                    <p><Label className={"field-label"}><a href={fileCoordinates} download="sample-coordinates.txt">Download Sample Format</a></Label></p>
                </Window>)}

                {showErrorLocationsWindow && (
                    <Window
                        title={"The following records were found in error:"}
                        onClose={viewErrorLocations}
                        width={750}
                    >
                        {displayErrorLocations()}
                    </Window>
                )}
            </div>
        )
    }
    if (loadError) {
        console.log(loadError);
    }
    if (!isLoaded) return <div>Loading...</div>;
    return renderMap();
};

export default Map;
