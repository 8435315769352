import React, {useEffect, useState} from "react";
import adsLogo from "../assets/img/ads_logo.fec9a72f.png"
import semcastingLogo from "../assets/img/Semcasting_Archer_RGB_Blk.png";
import "./SplashLandingPage.css"

import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Input} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import ReCAPTCHA from "react-google-recaptcha";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import LandingPageFooter from "./LandingPageFooter";
import {
    extractAuthCookie,
    semcastAxios,
    setAuthCookieToStorage,
    setDataToStorage, showErrorToastMessage,
    showSuccessToastMessage
} from "../../Utils/Common";
import {AUTHORIZE_API, SUCCESS_STATUS} from "../../api/constants";
import {useFormik} from "formik";
import "./LoginPage.css";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import stopwatch from "../../Admin/Components/Segmentation/temporaryresources/images/stopwatch.png";

const SplashLandingPage = () => {
    const searchParams = new URLSearchParams(document.location.search)
    const timeout = searchParams.get('timeout');
    // Remove url timeout param after identified..
    window.history.replaceState(null, '', window.location.pathname);
    const { executeRecaptcha } = useGoogleReCaptcha();

    // captcha vars
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [showTimedOut, setShowTimedOut] = useState(timeout ? true: false);
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const emailValidator = value => !value ? "Email is required." : emailRegex.test(value) ? "" : "Email is not in a valid format.";
    const [showPassword, setShowPassword] = useState(false);
    const [disableResetPassButton, setDisableResetPassButton] = useState(false);

    const loginFormik= useFormik ({
        initialValues: {
            email: "",
            password: ""
        }
    })
    const showPass = () => setShowPassword(!showPassword);

    // Controlling the disabling of the submit button in the form submit is fine but may not be quick enough.
    // Here it is set in a click handler (before the form submit - then the form submit handler will take over
    const handleForgotPasswordClick = (e) => {
        setDisableResetPassButton(true);
    };

    const handleForgotPassword = async (e) => {
        // e.preventDefault();
        setDisableResetPassButton(true);

        await semcastAxios.post("/v2/attributor/password-reset",
            {
                email: loginFormik.values.email,
            },
            {withCredentials: true})
            .then(function (response) {
                // console.log(response);
                if (response.data.status === "success") {
                    showSuccessToastMessage(
                        `An email has been sent to ${loginFormik.values.email} with further instructions. If you can't find it be sure and check your spam folder.`
                    );
                }
                setDisableResetPassButton(false);
            })
            .catch(function (error) {
                showErrorToastMessage(`An email could not be sent to ${loginFormik.values.email}. Please try again or contact support@semcasting.com for further assistance.`);
                setDisableResetPassButton(false);
            });
    };

    const handlePasswordResetWindowChange = () => {
        if(showResetPassword) {
            setShowResetPassword(false);
        } else {
            setShowResetPassword(true);
        }
    };

    function setCookie(cname, cvalue, mins) {
        // console.log("inSetCookie");
        const d = new Date();
        d.setTime(d.getTime() + (mins*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    };
    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return 0;
    };
    // clickHandler generates and returns reCaptcha token
    const clickHandler = async () => {
        let captchaToken = "";

        // Uncomment the following for captcha
        if (executeRecaptcha) {
            await executeRecaptcha('SignInADSV2')
                .then(async (result) => {
                    captchaToken = result;
                    return captchaToken;
                })
        }
        return captchaToken;
    };

    // public recaptcha v2 checkbox key
    const SITE_KEY = "6LeSTW8eAAAAAP2cJ8RZfEnPR2GklW3_a-tQn9pt";
    const reCaptchaRef = React.createRef();

    // run when captcha solved successfully
    const handleChange = (token) => {
        setDisableSubmit(false);
        setCookie("attempts", 0, 10);
    };

    const handleSubmit = async (e) => {

        if (loginFormik.values.email.length && loginFormik.values.password.length) {

            // Check value of attempts cookie - if it has been edited to not a number, set to 4, triggering reCaptcha. Else, increment value of attempts cookie.

            // Uncomment the following two lines for captcha
            if(!parseInt(getCookie("attempts")) && parseInt(getCookie("attempts")) !== 0) setCookie("attempts", 4, 10);
            else setCookie("attempts", parseInt(getCookie("attempts")) + 1, 10);

            // clickhandler generates recaptcha v3 code to send to the backend
            await clickHandler()
                .then(async (captchaToken) => {
                    // console.log("Value of email and password and token", loginFormik.values.email, loginFormik.values.password, captchaToken);
                    // if "attempts" cookie > 3, don't execute sign-in. Disable submit button and render captcha v2 checkbox
                    if (getCookie("attempts") > 3) {
                        setDisableSubmit(true);
                    } else {
                        try {
                            let response = await semcastAxios.post(AUTHORIZE_API, {
                                email: loginFormik.values.email,
                                password: loginFormik.values.password,
                                c: captchaToken,
                            });
                            if (response?.data?.status === SUCCESS_STATUS) {

                                showSuccessToastMessage('Success! Logging in...', 3);

                                // set captcha badge to "hidden", so badge will not appear on other pages
                                let badge = document.getElementsByClassName("grecaptcha-badge").item(0);
                                badge.style.visibility = "hidden";
                                setDataToStorage(true, response.data.id);
                                setAuthCookieToStorage(extractAuthCookie());
                                // history.push("/segmentation-dashboard");
                                window.location.href ="/segmentation-dashboard"
                                //await loadUserInfo();
                            }
                        } catch (error) {
                            showErrorToastMessage("Login Failed: Incorrect email or password.", 5);
                        } finally {

                        }
                    }
                })
        } else {
            // Renders error message for failed login
            showErrorToastMessage("Login Failed: Incorrect email or password.", 5);
        }
    };

    useEffect(() => {
        if (document.getElementById("emailSignIn")) {
            document.getElementById("emailSignIn").focus();
        }
    }, [document.getElementById("emailSignIn")]);

    useEffect(() => {

        document.body.style.zoom = "100%";

        // Make recaptcha badge visible on this page only
        let badge = document.getElementsByClassName("grecaptcha-badge").item(0);
        if(badge) {
            badge.style.visibility = "visible";
            badge.style.bottom = '70px';
        }

        // Check value of attempts cookie - if it is not a number, set attempts to 4, triggering reCaptcha. If it does not exist, set to 0.

        // Uncomment following lines for captcha
        if(!parseInt(getCookie("attempts")) && parseInt(getCookie("attempts")) !== 0) setCookie("attempts", 4, 10);
        else if(!parseInt(getCookie("attempts"))) setCookie("attempts", 0, 10);

        if (!executeRecaptcha) {
            return;
        }
    }, [executeRecaptcha, disableSubmit, handleChange]);

    return (
        <div className={"splash-page-outer-container"}>
        <div className={"splash-page-background"}>
            <div className={"splash-page-main-text-div"}>
                {/*<div >*/}
                {/*    <img src={semcastingLogo} className={"semcasting-logo"}/>*/}
                {/*</div>*/}
                <div className={"login-dialog-container"} >
                    <div className={"section-card landing-page-login-card box-shadow-standard section-card-outer-margins"}>
                        <div className={"landing-page-login-container"}>
                            <div className={"landing-page-logo-wrapper"}>

                                <img src={adsLogo} className={"landing-page-logo"}/>
                                <a href="https://semcasting.com/" target="_blank" className={"splash-page-semcasting-link"}>Powered by Semcasting</a>

                            </div>

                            {showResetPassword === true ? (
                                <Form onSubmit={handleForgotPassword} onSubmitClick={handleForgotPasswordClick} className={"resetPwForm"} render={formRenderProps => <FormElement className={"reset-password-form"} style={{
                                    maxWidth: 650
                                }}>

                                    <div className={"signInDialogResetPwDiv signInDialogFormFieldsDiv"}>

                                        <Field
                                            name={'email'}
                                            className={"text-field"}
                                            id={'emailPwReset'}
                                            type={'email'}
                                            component={Input}
                                            label={'Email'}
                                            onChange={(e) => {
                                                loginFormik.values.email = e.target.value;
                                            }}
                                        />

                                        <a id="myLink" className="forgot-password-link" href="#" onClick={handlePasswordResetWindowChange}>Remembered Password?</a>

                                    </div>

                                    <div className="login-button-container">

                                        <Button className={"splash-page-dialog-button landing-page-button"} type={'submit'} disabled={!formRenderProps.allowSubmit || !loginFormik.values.email.length || emailValidator(loginFormik.values.email) || disableResetPassButton} >
                                            Send Password Reset Email
                                        </Button>

                                    </div>
                                </FormElement>} />
                            ) : (
                                <Form onSubmit={handleSubmit} render={formRenderProps => <FormElement className={"login-form"} style={{
                                    maxWidth: 650
                                }}>

                                    <div className={"login-fields-container"}>

                                        <Field
                                            name={'email'}
                                            className={"text-field"}
                                            id={'emailSignIn'}
                                            type={'email'}
                                            component={Input}
                                            label={'Email'}
                                            onChange={(e) => {
                                                loginFormik.values.email = e.target.value;
                                            }}
                                        />

                                        <div className={"password-field-wrapper"}>
                                            <Field
                                                name={'password'}
                                                className={"text-field login-password-field"}
                                                component={Input}
                                                label={'Password'}
                                                type={showPassword ? "text" : "password"}
                                                onChange={(e) => {
                                                    loginFormik.values.password = e.target.value;
                                                }}
                                            />
                                            <Button type={"button"} className="toggle-view-password" icon="eye"
                                                    onClick={showPass} disabled={!loginFormik.values.password && loginFormik.values.password.length}></Button>
                                        </div>
                                        <a id="myLink" className="forgot-password-link" href="#" onClick={handlePasswordResetWindowChange}>Forgot Password?</a>

                                    </div>

                                    {/*Div containing captcha*/}
                                    <div className="captcha-container">
                                        {getCookie("attempts") > 3 && (
                                            <ReCAPTCHA
                                                className={"g-recaptcha"}
                                                ref={reCaptchaRef}
                                                sitekey={SITE_KEY}
                                                onChange={handleChange}
                                            />
                                        )}
                                    </div>

                                    <div className="k-form-buttons login-button-container">

                                        {/*Captcha disabled*/}
                                        {/*<Button themeColor={'primary'} className={"splash-page-dialog-button landing-page-button"} type={'submit'}*/}
                                        {/*        disabled={!formRenderProps.allowSubmit || !email.length || !password.length || emailValidator(email)}>*/}
                                        {/*    Sign In*/}
                                        {/*</Button>*/}

                                        {/*Captcha enabled*/}
                                        <Button name="SignIn" themeColor={'primary'} className={"splash-page-dialog-button landing-page-button"} type={'submit'}
                                                disabled={!formRenderProps.allowSubmit || disableSubmit || !loginFormik.values.email.length || loginFormik.values.password && !loginFormik.values.password.length|| emailValidator(loginFormik.values.email)}>
                                            Sign In
                                        </Button>
                                    </div>
                                </FormElement>} />)}
                        </div>
                    </div>
                    <a id="myLink" className="no-account-link" href="https://www.semcasting.com/ads">Don't have an account?</a>
                </div>
                <div className={"semcasting-logo-container"}>
                    <img src={semcastingLogo} style={{maxWidth: "100%"}}/>
                </div>
            </div>

        </div>
            {showTimedOut && (
                <Dialog
                    // title={"Your session has expired!"}
                    width={400}
                    height={212}
                    closeIcon={false}
                    className={"dialog-standard"}
                >
                    <div className={"activity-tracker-msg-div"}>
                        <img className={"activity-tracker-stopwatch"} src={stopwatch} alt=""/>
                        <div className={"field-label"}>
                            Your session has expired.
                        </div>
                    </div>

                    <DialogActionsBar layout={"center"}>
                        <Button
                            className={"button-standard button-submit"}
                            onClick={() => {
                                setShowTimedOut(false);
                            }}>
                            OK
                        </Button>
                    </DialogActionsBar>
                </Dialog>
                )}
            <LandingPageFooter />
        </div>
    )
}

export default SplashLandingPage;