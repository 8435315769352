import React, {useCallback, useEffect, useState} from "react";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {Button} from "@progress/kendo-react-buttons";
import {Label} from "@progress/kendo-react-labels";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {
    SEGMENT_APPEND_REPLACE_API,
    SEGMENT_ID_PLACE_HOLDER,
    SEGMENTS_ACTIVATION_QUEUE_DATA,
    SUCCESS_STATUS
} from "../../../api/constants";
import {imagesObjectMap} from "../Segmentation/temporaryresources/images/TEMP_ICON_IMAGE_MAP";
import moment from "moment";
import "./AppendReplace.css";
import {loadSegmentDetails} from "../../../actions/TreeDashboardActions";
import LoadOverlay from "../SharedComponents/LoadOverlay";
const AppendReplace = (props) => {

    const [pageLoader, setPageLoader] = useState(false)
    const [visible, setVisible] = useState(props.visible);
    const [segmentName1, setSegmentName1] = useState(props.appendReplace.profileFormik.values.selectedSegments[0].objectName);
    const [segmentName2, setSegmentName2] = useState(props.appendReplace.profileFormik.values.selectedSegments[1].objectName);
    const [active, setActive] = useState([false, false]);
    const [segmentPlatforms, setSegmentPlatforms] = useState([]);
    const [action, setAction] = useState("APPEND");
    const [activeSegment, setActiveSegment] = useState({});
    const [upsertSegment, setUpsertSegment] = useState({});
    const [targetChange, setTargetChange] = useState(false);
    const [disableSegments, setDisableSegments] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [currentSegment, setCurrentSegment] = useState([false, false]);

    const segmentSelectMessage = "Select one segment as the target.";
    const [encryptedSegmentIds, setEncryptedSegmentIds] = useState([]);
    const [seg1activationDetails, setSeg1ActivationDetails] = useState([]);
    const [seg2activationDetails, setSeg2ActivationDetails] = useState([]);
    const [seg1activationDetailsLoaded, setSeg1activationDetailsLoaded] = useState(false);
    const [seg2activationDetailsLoaded, setSeg2activationDetailsLoaded] = useState(false);
    const [segmentsLoaded, setSegmentsLoaded] = useState(false);
    const [reminderMsg, setReminderMsg] = useState(false);
    const selectedSegmentsClone = props.appendReplace.profileFormik.values.selectedSegments;
    const [newSelectedSegments, setNewSelectedSegments] = useState({});
    const [dateReminderMsg, setDateReminderMsg] = useState(false);
    const [platformStatusMsg, setPlatformStatusMsg] = useState(false);

    function containsOnly(array1, array2) {
        return array2.every(elem => array1.includes(elem))
    }

    useEffect(async () => {
        //Wait for all response to comeback, then proceed
        await Promise.allSettled([getSegmentData()]);
    }, [seg1activationDetails, seg2activationDetails])

    const getSegmentData = async () => {
        //Get selected segment data if one of them is activated
        if (selectedSegmentsClone[0].children !== undefined || selectedSegmentsClone[1].children !== undefined) {
            await selectedSegmentsClone.map((i) => {
                loadSegmentDetails(i.id).then((r) => {
                    setEncryptedSegmentIds(prevState => [...prevState, r.segment_id])
                    for (let i = 0; i < selectedSegmentsClone.length; i++) {
                        if (selectedSegmentsClone[i].id === r.id) selectedSegmentsClone[i].segment_id = r.segment_id;
                    }
                    if (selectedSegmentsClone[0].segment_id && selectedSegmentsClone[1].segment_id) setSegmentsLoaded(true);
                })
            })
        } else {
            //Warn user and stop process because selected segments are not valid for append/replace
            setReminderMsg(true)
            setPageLoader(false);
        }
    }


    useEffect(async () => {
        //Get Activation queue data of segment 1 if it has been activated
        if (selectedSegmentsClone[0].children !== undefined) {
            for (let i = 0; i < selectedSegmentsClone[0].children.length; i++) {
                try {
                    await semcastAxios.get(SEGMENTS_ACTIVATION_QUEUE_DATA.replace(SEGMENT_ID_PLACE_HOLDER, selectedSegmentsClone[0].children[i].id), {withCredentials: true})
                        .then(function (response) {
                            setSeg1ActivationDetails(prevState => [...prevState, JSON.parse(response.data.data)]);
                            setSeg1activationDetailsLoaded(true);
                        })
                } catch(e) {
                    console.log(e);
                    setSeg1activationDetailsLoaded(true);
                }
            }
        } else {
            setSeg1ActivationDetails(prevState => [...prevState, ]);
            setSeg1activationDetailsLoaded(true);
        }

    }, [])
    useEffect(async () => {
        //Get Activation queue data of segment 1 if it has been activated
        if (selectedSegmentsClone[1].children !== undefined) {
            for (let i = 0; i < selectedSegmentsClone[1].children.length; i++) {
                try {
                    await semcastAxios.get(SEGMENTS_ACTIVATION_QUEUE_DATA.replace(SEGMENT_ID_PLACE_HOLDER, selectedSegmentsClone[1].children[i].id), {withCredentials: true})
                        .then(function (response) {
                            setSeg2ActivationDetails(prevState => [...prevState, JSON.parse(response.data.data)]);
                            setSeg2activationDetailsLoaded(true);
                        })
                } catch (e) {
                    console.log(e);
                }
            }
        } else {
            setSeg2ActivationDetails(prevState => [...prevState, ]);
            setSeg2activationDetailsLoaded(true);
        }
    }, [])

    useEffect(() => {
        //Create a new Object with all the information we need for each segment.
        if (seg1activationDetailsLoaded && seg2activationDetailsLoaded && segmentsLoaded) {
            setNewSelectedSegments(Object.assign({},
                {
                    0: {
                        selectedSegments: selectedSegmentsClone[0],
                        activation_queue_data: seg1activationDetails,
                        // segment_id: encryptedSegmentIds[0]
                        segment_id: selectedSegmentsClone[0].segment_id
                    },
                    1: {
                        selectedSegments: selectedSegmentsClone[1],
                        activation_queue_data: seg2activationDetails,
                        // segment_id: encryptedSegmentIds[1]
                        segment_id: selectedSegmentsClone[1].segment_id
                    }
                }));
            if (seg1activationDetails.length > 0  && seg2activationDetails.length > 0) {
                setDisableSubmit(true)
            } else {
                setDisableSubmit(false);
            }
        }

    }, [seg1activationDetailsLoaded, seg2activationDetailsLoaded, segmentsLoaded])

    useEffect(() => {
        setPageLoader(true);
        if (Object.keys(newSelectedSegments).length === 2) {
            if (!newSelectedSegments[0].type && !newSelectedSegments[1].type) {
                determineActiveSegmentsAndPlatforms();
            }  else {
                // if (newSelectedSegments[0].type && newSelectedSegments[1].type) {
                //     setDisableSubmit(true);
                // } else {
                    if (newSelectedSegments[0].type) {
                        setActiveSegment(newSelectedSegments[0]);
                        setUpsertSegment(newSelectedSegments[1]);
                    } else {
                        setActiveSegment(newSelectedSegments[1]);
                        setUpsertSegment(newSelectedSegments[0]);

                    }
                //     setDisableSubmit(false);
                // }
            }
        }
    }, [newSelectedSegments]);

    useEffect(() => {
        if (targetChange && activeSegment.activation_queue_data && activeSegment.selectedSegments.children) {
            determinePlatformsForSelectedSegment();
            setDisableSubmit(false);
        }
    }, [targetChange, activeSegment]);

    const determineActiveSegmentsAndPlatforms = () => {
        const today = moment();
        let newActive = [...active];
        let platforms = [];

        for (let i = 0; i < Object.keys(newSelectedSegments).length; i++) {
            if (newSelectedSegments[i].activation_queue_data.length > 0) {
                if (newSelectedSegments[i].selectedSegments.children) {
                    for (let j = 0; j < newSelectedSegments[i].selectedSegments.children.length; j++) {
                        let child = newSelectedSegments[i].selectedSegments.children[j];
                        // console.log(child);
                        if (child.objectType  === "Activation" && child.status === "COMPLETED")  {
                            newActive[i] = true;
                            setActive(newActive);
                            platforms.push({
                                platform: child.objectName,
                                segment: newSelectedSegments[i].selectedSegments.objectName
                            });
                            let segmentsCopy = JSON.parse(JSON.stringify(newSelectedSegments));
                            segmentsCopy[i].type = "active";
                            // const updatedSegment = {...newSelectedSegments[i], type: "active"};
                            setNewSelectedSegments(segmentsCopy);

                            setSegmentPlatforms(platforms);
                        }
                    }
                }
            }
        }

        setDisableSegments((newActive[0] && !newActive[1]) || (!newActive[0] && newActive[1]) || (!newActive[0] && !newActive[1]));
        setPageLoader(false)
    };
    const determinePlatformsForSelectedSegment = () => {
        const today = moment();
        const platforms = [];

        if (activeSegment && activeSegment.activation_queue_data) {
                let activations = activeSegment.selectedSegments.children.filter((item) => {
                    return item.objectType === "Activation"  && item.status === "COMPLETED";
                });
                // console.log(activations);
                // PER JOHN - NOT CHECKING DATE FOR APPEND REPLACE
                // const futureDate = moment(queueData.campaign_range_end, 'YYYY-MM-DD');
                // if (!today.isAfter(futureDate) && status === 'COMPLETED') {
                for (let k = 0; k < activations.length; k++) {
                    platforms.push({
                        platform: activations[k].objectName,
                        segment: activeSegment.selectedSegments.objectName
                    });
                }
                setSegmentPlatforms(platforms);
        }
    };

    useEffect(() => {
        if (active[0] === true) {
            setActiveSegment(newSelectedSegments[0]);
            setUpsertSegment(newSelectedSegments[1]);
            setPageLoader(false);
        } else if (active[1] === true) {
            setActiveSegment(newSelectedSegments[1]);
            setUpsertSegment(newSelectedSegments[0]);
            setPageLoader(false);
        }
    }, [active])

    const onTargetSegmentChange = useCallback((e) => {
        let newActive = [...active];
        if (e.target.id === 'seg1') {
            newActive[0] = !newActive[0];
            newActive[1] = !newActive[0];
            setActive(newActive);
        } else if (e.target.id === 'seg2') {
            newActive[1] = !newActive[1];
            newActive[0] = !newActive[1];
            setActive(newActive);
        }
        if (newActive[0] === true) {
            setActiveSegment(newSelectedSegments[0]);
            setUpsertSegment(newSelectedSegments[1]);
        } else if (newActive[1] === true) {
            setActiveSegment(newSelectedSegments[1]);
            setUpsertSegment(newSelectedSegments[0]);
        }
        setTargetChange(true);
    }, [activeSegment, upsertSegment]);

    // useEffect(() => {
    //     if ((!active[0] || !active[1]) && (activeSegment.segment_id && upsertSegment.segment_id)) {
    //         setDisableSubmit(false);
    //     } else {
    //         setDisableSubmit(true);
    //     }
    // }, [active, activeSegment.segment_id, upsertSegment.segment_id]);

    const onSegmentPlatformMouseOver = (e, segmentPlatform) => {
        if (segmentPlatform.segment === segmentName1) {
            let newCurrentSegment = [true, false];
            setCurrentSegment(newCurrentSegment);
        } else {
            let newCurrentSegment = [false, true];
            setCurrentSegment(newCurrentSegment);
        }
    };

    const onSegmentPlatformMouseLeave = (e) => {
        let newCurrentSegment = [false, false];
        setCurrentSegment(newCurrentSegment);
    };

    const onActionChange = (e) => {
        if (e.target.id === "append") {
            setAction("APPEND");
        } else if (e.target.id === "replace") {
            setAction("REPLACE");
        }
    };


    // const TooltipContentTemplate = (props) => {
    //     let platformItems = props.title.split("|");
    //     return (
    //         <>
    //             <div>
    //                 <b>Company:</b>
    //                 <br/>
    //                 {platformItems[0]}
    //             </div>
    //             <br/>
    //             <div>
    //                 <b>Segment:</b>
    //                 <br/>
    //                 {platformItems[1]}
    //             </div>
    //             <br/>
    //             {/*<div>*/}
    //             {/*    <b>Status:</b>*/}
    //             {/*    <br/>*/}
    //             {/*    {platformItems[3]}*/}
    //             {/*</div>*/}
    //             {/*<br/>*/}
    //             {/*<div>*/}
    //             {/*    <b>Active Until:</b>*/}
    //             {/*    <br/>*/}
    //             {/*    {platformItems[2]}*/}
    //             {/*</div>*/}
    //         </>
    //     );
    // };

    const _createPayload = () => {
        // console.log(newSelectedSegments);
        let activatedSegmentId = null;
        let upsertSegmentId = null;
        let groupId = null;
        if (active[0]) {
            activatedSegmentId = newSelectedSegments[0].segment_id;
            upsertSegmentId = newSelectedSegments[1].segment_id;
            groupId = newSelectedSegments[0].selectedSegments.groupId
        } else if (active[1]) {
            activatedSegmentId = newSelectedSegments[1].segment_id;
            upsertSegmentId = newSelectedSegments[0].segment_id;
            groupId = newSelectedSegments[1].selectedSegments.groupId;
        }
        let segmentDesign = {};
        segmentDesign.upsert_type = action;
        segmentDesign.target_type = activeSegment.selectedSegments.subType.toUpperCase();

        const payload = {
            activated_segment_id: activatedSegmentId,
            upsert_segment_id: upsertSegmentId,
            group_id: groupId,
            segment_design: segmentDesign
        };

        return payload
    };

    const appendReplace = async () => {
        setPageLoader(true)
        try {
            const payload = _createPayload();
            let response = await semcastAxios.post(
                SEGMENT_APPEND_REPLACE_API,
                payload,
                {
                    withCredentials: true,
                }
            );
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Append / Replace Request Sent.");
                props.reloadAfterAppendReplace();
            }
        } catch (error) {
            console.log(error);
            let msg = "Append / Replace Failed.";
            if (error.response && error.response.data) {
                msg += error.response.data.data + "\n" + msg;
            }
            showErrorToastMessage(msg);
        } finally {
            setPageLoader(false)
        }
        // setVisible(false);
        //props.handleSplit();
    }

    return (
        <>
            <div id="appendReplace">
                {visible && (<Dialog
                    title={"Append / Replace Target Segment"}
                    width={"40%"}
                    closeIcon={false}
                    className={"dialog-standard"}
                >
                    <LoadOverlay active={pageLoader}>
                    <div className={"container append-replace-container"}>
                        <div className={"d-flex"}>
                            <div>
                                <div style={reminderMsg ? {
                                    display: 'block',
                                    color: 'red'
                                } : {display: 'none'}}> (Attention: To submit Append/Replace request, at least one of
                                    the segments selected have to be activated by one platform.)
                                </div>
                                <div style={dateReminderMsg ? {
                                    display: 'block',
                                    color: 'red'
                                } : {display: 'none'}}> (Attention: Neither activation end date is sufficient for
                                    Append/Replace.)
                                </div>
                                <div style={platformStatusMsg ? {
                                    display: 'block',
                                    color: 'red'
                                } : {display: 'none'}}> (Attention: Neither activation status is sufficient for
                                    Append/Replace.)
                                </div>
                                <br/>
                                <Label className={"field-label"}>Target</Label>
                                {/*<div style={reminderMsg ? {*/}
                                {/*    display: 'block',*/}
                                {/*    color: 'red'*/}
                                {/*} : {display: 'none'}}> (Attention: To submit Append/Replace request, at least one of*/}
                                {/*    the segments selected have to be activated by one platform.)*/}
                                {/*</div>*/}
                                {/*<div style={dateReminderMsg ? {*/}
                                {/*    display: 'block',*/}
                                {/*    color: 'red'*/}
                                {/*} : {display: 'none'}}> (Attention: Neither activation end date is sufficient for*/}
                                {/*    Append/Replace.)*/}
                                {/*</div>*/}
                                {/*<div style={platformStatusMsg ? {*/}
                                {/*    display: 'block',*/}
                                {/*    color: 'red'*/}
                                {/*} : {display: 'none'}}> (Attention: Neither activation status is sufficient for*/}
                                {/*    Append/Replace.)*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                        <div className={"container mt-2 append-replace-container"}>
                            <div className={"d-flex ms-3"}>
                                <input
                                    id={"seg1"}
                                    className="k-checkbox k-checkbox-md k-rounded-md"
                                    checked={active[0] === true}
                                    onChange={onTargetSegmentChange}
                                    disabled={disableSegments}
                                    type={"checkbox"}>
                                </input>
                            </div>
                            <div className={"d-flex ms-2"}>
                                <Label
                                    className={`field-label ${currentSegment[0] === true ? 'append-replace-current-segment' : ''}`}
                                    for={"seg1"}>{segmentName1}</Label>
                            </div>
                        </div>
                        <div className={"container mt-2 append-replace-container"}>
                            <div className={"d-flex ms-3"}>
                            <input
                                id={"seg2"}
                                className="k-checkbox k-checkbox-md k-rounded-md"
                                checked={active[1] === true}
                                onChange={onTargetSegmentChange}
                                disabled={disableSegments}
                                type={"checkbox"}>
                            </input>
                        </div>
                        <div className={"d-flex ms-2"}>
                            <Label
                                className={`field-label ${currentSegment[1] === true ? 'append-replace-current-segment' : ''}`}
                                for={"seg2"}>{segmentName2}</Label>
                        </div>
                    </div>
                    <div className={"container mt-4 append-replace-container"}>
                        <div className={"d-flex"}>
                            <div>
                                <Label className={"field-label"}>Action</Label>
                            </div>
                        </div>
                    </div>
                    <div className={"container mt-2 append-replace-container"}>

                        <div className={"d-flex ms-3"}>
                            <Tooltip>
                                <input
                                    id={"append"}
                                    className="k-checkbox k-checkbox-md k-rounded-md"
                                    checked={action === "APPEND"}
                                    title={"Append will add only new members from the source to the target segment."}
                                    onChange={onActionChange}
                                    type={"checkbox"}>
                                </input>
                            </Tooltip>
                        </div>
                        <div className={"d-flex ms-2"}>
                            <Label className={"field-label"} for={"append"}>Append</Label>
                        </div>
                        <div className={"d-flex ms-4"}>
                            <Tooltip>
                                <input
                                    id={"replace"}
                                    className="k-checkbox k-checkbox-md k-rounded-md"
                                    checked={action === "REPLACE"}
                                    title={"Replace will remove all current members of the target segment and replace the members of the source."}
                                    onChange={onActionChange}
                                    type={"checkbox"}>
                                </input>
                            </Tooltip>
                        </div>
                        <div className={"d-flex ms-2"}>
                            <Label className={"field-label"} for={"replace"}>Replace</Label>
                        </div>


                    </div>
                    <div className={"container mt-4 append-replace-container"}>
                        <div className={"d-flex"}>
                            <div>
                                <Label className={"field-label"}>Platforms</Label>
                            </div>
                        </div>
                    </div>
                    <div className={"container mt-2 append-replace-container"}>
                        <div className={"d-flex ms-3"}>
                            <div className={"row"}>
                                {/*<span>*/}
                                {segmentPlatforms.map((segmentPlatform, index) => {
                                    let platformImage = imagesObjectMap[segmentPlatform.platform]?.icon;
                                    return (
                                        <>
                                            <div className={"col"}>
                                                {/*<Tooltip*/}
                                                {/*    content={(props) => <TooltipContentTemplate*/}
                                                {/*        title={props.title}*/}
                                                {/*        position={"right"}*/}
                                                {/*        anchorElement={"target"}/>}*/}
                                                {/*>*/}
                                                    <img
                                                        className={"append-replace-platform-image"}
                                                        src={platformImage}
                                                        // title={`${segmentPlatform.platform}|${segmentPlatform.segment}|${segmentPlatform.endDate}|${segmentPlatform.status}`}
                                                        alt={""}
                                                        onMouseOver={(e) => onSegmentPlatformMouseOver(e, segmentPlatform)}
                                                        onMouseLeave={onSegmentPlatformMouseLeave}
                                                        key={index}
                                                    />
                                                {/*</Tooltip>*/}
                                            </div>
                                        </>
                                    )
                                })}
                                {/*</span>*/}
                            </div>
                        </div>
                    </div>
                    <br/>

                    {active[0] && active[1] && (
                        <Label className={"append-replace-warning-msg"}><b>{segmentSelectMessage}</b></Label>
                    )}

                    <DialogActionsBar layout={"end"}>
                        <Button
                            className="button-standard button-white"
                            onClick={() => {
                                props.cancelSubscriber(undefined)
                            }}>
                            Cancel
                        </Button>
                        <Button
                            className="button-standard button-submit"
                            disabled={disableSubmit}
                            onClick={appendReplace}>
                            Submit
                        </Button>
                    </DialogActionsBar>
                    </LoadOverlay>
                </Dialog>)}
            </div>
        </>
    );
}

export default AppendReplace;