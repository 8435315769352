import {useEffect, useState} from "react";
import {
    boostrap2TableSearchOptions,
    dateFormatter,
    defaultSortedOnTotal,
    emailFormatter,
    ExportCSV, exportCSVOptions, noDataIndication,
    paginationOptions,
    semcastAxios
} from "../../../../Utils/Common";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {
    ACTIVATED_SEGMENTS_TOTALS_BY_ACCOUNT_API,
    ACTIVATED_SEGMENTS_TOTALS_BY_USER_API, ATTRIBUTIONS_TOTALS_BY_USER_API,
    CREATED_SEGMENTS_TOTALS_BY_USER_API, DORMANT_USERS_API,
    NO_DATA_TO_SHOW,
    SUCCESS_STATUS,
    YYYY_MM_DD_DATEFORMAT
} from "../../../../api/constants";
import LoadOverlay from "../../../Components/SharedComponents/LoadOverlay";
// es6
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css'
import filterFactory from 'react-bootstrap-table2-filter';
import moment from "moment";
import "../../Pages/AppStatus.css"
function DormantUsers() {
    const DORMANT_USERS_TIME_FRAME_DAYS = 30;
    const lastLoginDate = moment().subtract(DORMANT_USERS_TIME_FRAME_DAYS, 'days').format(YYYY_MM_DD_DATEFORMAT);
    const [loader, setLoader] = useState(false)
    const [rows, setRows] = useState([]);
    const {SearchBar} = Search;
    const defaultSorted = [{
        dataField: 'last_login_at',
        order: 'desc'
    }];
    const columns = [
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
            formatter: emailFormatter
        },
        {
            dataField: 'company',
            text: 'Company',
            sort: true
        },
        {
            dataField: 'last_login_at',
            text: 'Last Login',
            sort: true,
            formatter: dateFormatter
        }];

    useEffect(async () => {
        try {
            setLoader(true)
            await loadRows();
        } catch (e) {
            setRows([])
        } finally {
            setLoader(false)
        }
    }, []);

    const loadRows = async () => {
        const res = await semcastAxios.get(DORMANT_USERS_API +
            `?last_login_at_end=${lastLoginDate}&deleted_at=null`, {
            withCredentials: true,
        });
        if (res.data.status === SUCCESS_STATUS) {
            if (res?.data?.data?.length) {
                res?.data?.data.forEach((obj, index) => {
                    obj['id'] = index
                })
                setRows(res.data.data)
            }
        }
    };

    return (
        <>
            <LoadOverlay active={loader}>
                <div className="alert alert-warning" role="alert">
                    Users who have not logged in since before <strong>{lastLoginDate}</strong>
                </div>
               <ToolkitProvider
                    keyField="id"
                    bootstrap4={true}
                    data={rows}
                    columns={columns}
                    search={boostrap2TableSearchOptions()}
                    exportCSV={exportCSVOptions()}
                >
                    {
                        props => (
                            <div>
                                <SearchBar {...props.searchProps}
                                           className="form-control form-control-custom"/>
                                <ExportCSV {...props.csvProps}/>
                                <BootstrapTable {...props.baseProps}
                                                pagination={paginationFactory(paginationOptions(rows))}
                                                filter={filterFactory()}
                                                defaultSorted={ defaultSorted }
                                                bordered={false}
                                                noDataIndication={noDataIndication}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </LoadOverlay>
        </>
    )
}

export default DormantUsers