// import { Bar } from "test-react-chartjs-2";
// import { Chart as ChartJS, Legend } from "chart.js";
import "./BusinessNotes.css";


const BusinessNotes = ({ data, chartId }) => {

  return (
    <>
     <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split" id={chartId}>
                      <div className="card prevent-split">
                        <div className="text-center prevent-split">
                          <h4>Notes</h4>
                        </div>
                        <div className="row prevent-split">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split">
                            <div className="report-card prevent-split">
                              <div className="card-body consumer-dashboard-notes prevent-split text-dark">
                                          <p>{data[1]}</p>
                              </div>
                            </div>
                          </div>                         
                        </div>
                      </div>
                    </div>
    </>
  );
};
export default BusinessNotes;
