import {semcastAxios} from "../Utils/Common";
import {
    DORMANT_USERS_API,
    GROUPS_ACCESSIBLE_API, GROUPS_EDIT_GET_API,
    MANAGE_USERS_ACCESSIBLE_API, ROLE_EDIT_GET_API, ROLE_ID_PLACE_HOLDER, ROLE_PERMISSIONS_API,
    ROLES_ACCESSIBLE_API,
    SUCCESS_STATUS, USER,
    USER_INFO_API, USERS_GROUPS_EDIT_GET_API, USERS_PERMISSIONS_API
} from "../api/constants";


export const loadUserPermissions = async () => {
    let response = await semcastAxios.get(USERS_PERMISSIONS_API, {withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.data
        } catch (e) {
            return []
        }
    }
}

export const loadUsers = async (isFromAccount=false) => {
    let user = JSON.parse(sessionStorage.getItem(USER));
    let response = null
    if (user?.isAdmin && isFromAccount) {
        let path= DORMANT_USERS_API;
 //           + "?deleted_at=null";
 //        console.log("AllUsersPath=", path);
        response = await semcastAxios.get(path, {withCredentials: true}  );
    } else {
        response = await semcastAxios.get(MANAGE_USERS_ACCESSIBLE_API, {withCredentials: true});
    }
    if (response.data.status === SUCCESS_STATUS) {
        try {
            //console.log("loadUserResponse=", response?.data?.data);
            return response?.data?.data;
        } catch (e) {
            return []
        }
    }
}

export const loadAccessibleGroups = async () => {
    try {
        let response = await semcastAxios.get(GROUPS_ACCESSIBLE_API, {withCredentials: true});
        if (response.data.status === SUCCESS_STATUS) {
            return response.data.data || [];
        }
    } catch (e) {
        return []
    }
}

export const loadAccessibleRoles = async () => {
    try {
        let response = await semcastAxios.get(ROLES_ACCESSIBLE_API, {withCredentials: true});
        if (response.data.status === SUCCESS_STATUS) {
            return response?.data?.data || [];
        }
    } catch (e) {
        return []
    }
}

export const loadPermissions = async () => {
    try {
        let response = await semcastAxios.get(ROLE_PERMISSIONS_API, {withCredentials: true});
        if (response.data.status === SUCCESS_STATUS) {
            return response?.data?.data || [];
        }
    } catch (e) {
        return []
    }
}


export const loadUserEditGroupsNRoles = async (id) => {
    try {
        let response = await semcastAxios.get(USERS_GROUPS_EDIT_GET_API + id, {withCredentials: true})
        if (response.data.status === SUCCESS_STATUS) {
            return response?.data?.data
        }
    } catch (e) {
    }
}

export const loadGroupEditUserRoles = async (id) => {
    try {
        let response = await semcastAxios.get(GROUPS_EDIT_GET_API + id, {withCredentials: true})
        if (response.data.status === SUCCESS_STATUS) {
            return response?.data?.data
        }
    } catch (e) {
    }
}

export const loadRoleEdit = async (id) => {
    try {
        let response = await semcastAxios.get(ROLE_EDIT_GET_API.replace(ROLE_ID_PLACE_HOLDER, id), {withCredentials: true})
        if (response.data.status === SUCCESS_STATUS) {
            return response?.data?.data
        }
    } catch (e) {
    }
}

export const loadUserProfile = async () => {
    try {
        let response = await semcastAxios.get(USER_INFO_API, {withCredentials: true});
        if (response.data.status === SUCCESS_STATUS) {
            return response.data.data;
        }
    } catch (e) {
        return {};
    }
}