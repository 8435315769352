import { Bar } from "test-react-chartjs-2";
import { Chart as ChartJS, Legend } from "chart.js";
import "./TopBusinessNAICs.css";
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NumberFormat from "react-number-format";
const TopBusinessNAICs = ({ data, chartId, naicsRef, naicsHCOptions }) => {
  const naicsData = {
    labels: data.labels,
    datasets: [
      {
        label: "Top Business NAICS",
        data: data.values,
        backgroundColor: data.backgroundColor,
      },
    ],
  };

  const pluginOptions = {
    legend: {
      display: false,
      labels: {
        font: {
          size: 20,
          weight: 500,
        },
      },
    },
  };

  ChartJS.defaults.font.size = 17;
  ChartJS.defaults.color = "#000000";

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split" id={chartId}>
        <div className="card">
          <div className="text-center">
            <h4>Top Business NAICS</h4>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="report-card">
                <div className="card-body">
                  <div className="table-responsive business-naics">
                    <table className="table table-responsive-md business table-striped">
                      <thead>
                        <tr>
                          <th className="col-xl-2 col-lg-2 col-md-2">
                            <strong>NAICS</strong>
                          </th>
                          <th className="col-xl-5 col-lg-5 col-md-5 text-nowrap">
                            <strong>Industry</strong>
                          </th>
                          <th className="right col-xl-1 col-lg-1 col-md-1">
                            <strong>Count</strong>
                          </th>
                          {/* <th className="col-xl-1 col-lg-1 col-md-1"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data.rows.slice(0, 10).map((item, index) => {
                          return (
                            <tr>
                              <td>{item["NAICS"]}</td>
                              <td>{item["Industry"]}</td>
                              {/*<td className="right">{item["Count"]}</td>*/}
                              <td className="right">
                                <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={item["Count"]}
                                    decimalSeparator="."
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                    allowNegative={true}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="report-card">
                <div className="card-body">
                  <div className="table-responsive business-naics">
                    <table className="table table-responsive-md business table-striped">
                      <thead>
                        <tr>
                          <th className="col-xl-1 col-lg-1 col-md-1">
                            <strong>NAICS</strong>
                          </th>
                          <th className="col-xl-5 col-lg-5 col-md-5 text-nowrap">
                            <strong>Industry</strong>
                          </th>
                          <th className="right col-xl-1 col-lg-1 col-md-1">
                            <strong>Count</strong>
                          </th>
                          {/* <th className="col-xl-1 col-lg-1 col-md-1"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data.rows.slice(10).map((item, index) => {
                          return (
                            <tr>
                              <td>{item["NAICS"]}</td>
                              <td>{item["Industry"]}</td>
                              {/*<td className="right">{item["Count"]}</td>*/}
                              <td className="right">
                              <NumberFormat
                                  thousandsGroupStyle="thousand"
                                  value={item["Count"]}
                                  decimalSeparator="."
                                  displayType="text"
                                  type="text"
                                  thousandSeparator={true}
                                  allowNegative={true}
                              />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11">
              <div className="card business">
                <div className="card-body-businessgraphs">
                  <Bar
                      ref={naicsRef}
                    data={naicsData}
                    id={'naics'}
                    options={{
                      maintainAspectRatio: false,
                      plugins: pluginOptions,
                    }}
                  />
                  {/*<HighchartsReact*/}
                  {/*    constructorType={"chart"}*/}
                  {/*    options={naicsHCOptions}*/}
                  {/*    highcharts={Highcharts}*/}
                  {/*/>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TopBusinessNAICs;
