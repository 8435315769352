import React, { useEffect, useState, useContext} from "react";
import {
    loadAccessibleGroups,
    loadAccessibleRoles,
    loadUserEditGroupsNRoles, loadUserPermissions,
    loadUsers
} from "../../../actions/UserActions";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage, sortItemsByField, signInAs} from "../../../Utils/Common";
import {
    ASCENDING_ORDER, DESCENDING_ORDER,
    NOT_A_MEMBER,
    PASSWORD_RESET_API,
    SUCCESS_STATUS,
    USER_DELETE_API,
    USER_ID_PLACE_HOLDER, USER_REINSTATE_API,
    USER_SUSPEND_API,
    USERS_BASIC_INFO_UPDATE_API,
    USERS_GROUPS_EDIT_PUT_API,
    USER
} from "../../../api/constants";
import {useFormik} from "formik";
import * as Yup from "yup";
import {hasAtLeastOneInAnyGroup, UsersHelpers} from "../../../helpers/UsersHelpers";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import {Button} from "@progress/kendo-react-buttons";
import {Grid, GridColumn as Column, GridToolbar} from "@progress/kendo-react-grid";
import { process } from '@progress/kendo-data-query';
import "./UserPermissionsDashboard.css";
import {ExcelExport} from '@progress/kendo-react-excel-export';
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import PermissionsEditUser from "./PermissionsEditUser";

const EditCommandCell = (props) => {
    //console.log("EditCommandCellProps=", props);
    return (
        <td>
            <Button
                className="button-standard button-submit button-no-left-margin"
                iconClass="icon-edit"
                onClick={(e) => {
                    props.openEdit(props.dataItem);
                }}
            >
                Edit
            </Button>
        </td>
    );
};

const SignInAsCommandCell = (props) => {
    const handleSignInAs = async () => {
        // console.log("handleSignInAsProps=", props)
        if (props.user?.isAdmin) {
            try {
                await props.signInAs(props.dataItem.id);
            } catch (error) {
                console.error('Error while signing in as:', error);
            }
        }
    };

    return (
        <td>
            {props.user?.isAdmin && props.dataItem.deleted_at === null &&
                <Button
                    className="button-standard button-submit button-no-left-margin"
                    onClick={handleSignInAs}
                >
                    Sign In As
                </Button>
            }
        </td>
    );
};


const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "firstName",
            operator: "contains",
            value: ""
        }
    ]
};

const rowRender = (trElement, props) => {
    const suspended = props.dataItem.deactivated_at;

    const orange = {
        backgroundColor: "orange"
    };
    const transparent = {
        // backgroundColor: "transparent"
    };
    const trProps = {
        style: suspended ? orange : transparent
    };
    return React.cloneElement(trElement, {
        ...trProps
    }, trElement.props.children);
};

function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');

    const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return currentDateTime;
}

const UsersPermissionsDashboard = (props) => {

    console.log("UsersPermissionsDashboardProps=", props);
    const isFromAccount = props.isFromAccount
    let userHelpers = new UsersHelpers();
    const [overlayActive, setOverlayActive] = useState(false);

    const [users, setUsers] = useState([]);
    const [accessibleGroups, setAccessibleGroups] = useState([]);
    const [accessibleRoles, setAccessibleRoles] = useState([]);

    const [canCreateUser, setCanCreateUser] = useState(false);
    const [canViewUser, setCanViewUser] = useState(false);
    const [canEditUser, setCanEditUser] = useState(false);
    const [canDeleteUser, setCanDeleteUser] = useState(false);

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(initialFilter);

    const [openEditCreate, setOpenEditCreate] = useState(false);
    const [resetPwdConfirmDialog, setResetPwdConfirmDialog] = useState(false);
    const [userSelectedForResetPwd, setUserSelectedForResetPwd] = useState(false);

    const [userObj, setUserObj] = useState({})

    const [dialogMode, setDialogMode] = useState("");

    const [groupsNRolesObj, setGroupsNRolesObj] = useState({});
    const [topLevelGroupId, setTopLevelGroupId] = useState("");
    const [tableSortOrder, setTableSortOrder] = useState(ASCENDING_ORDER);

    const initialSort = [
        {
            field: "first_name",
            dir: "asc"
        }
    ]

    const [sort, setSort] = useState(initialSort);
    const [take, setTake] = React.useState(10);
    const [skip, setSkip] = React.useState(0);

    const dataState = {
        take,
        skip,
        sort,
        filter
    };

    const onDataStateChange = React.useCallback(
        (event) => {
            // console.log("event=", event)
            setTake(event.dataState.take);
            setSkip(event.dataState.skip);
            setSort(event.dataState.sort);
            setFilter(event.dataState.filter);
        },
        [setTake, setSkip, setSort]
    );

    const processedData = process(data, dataState);

    const _export = React.useRef(null);
    const exportExport = () => {
        if (_export.current !== null) {
//           console.log("exportOrigData=", data);
            //_export.current.save(process(data, dataState));
            _export.current.save(data);
            //_export.
        }
    };

    const SignInAsAdministrator = (props) => {
        const handleSignInAs = async () => {
            console.log("handleSignInAsAdministratorProps=", props);

            if (props.user?.isAdmin) {
                try {
                    const founder_id = props.dataItem.founder_id;
                    await props.signInAs(founder_id);
                } catch (error) {
                    console.error('Error while signing in as:', error);
                }
            }
        };

        // Function to find founder details by founder_id using the 'data' array
        const getFounderDetails = (founder_id) => {
            return data.find(item => item.id === founder_id);
        };

        // Get founder details if user is admin and data item is not deleted
        const founderDetails = props.user?.isAdmin
            //&& props.dataItem.deleted_at === null
            ? getFounderDetails(props.dataItem.founder_id)
            : null;

        return (
            <td>
                {founderDetails &&
                    <button
                        className="button-standard button-submit button-no-left-margin"
                        onClick={handleSignInAs}
                        style={{
                            background: 'none',
                            color: 'blue',
                            border: 'none',
                            padding: 0,
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                    >
                        {founderDetails.first_name} {founderDetails.last_name}
                    </button>
                }
            </td>
        );
    };

    const [groupNRolesNewUser, setGroupsNRolesNewUser] = useState([{
        group_id: "",
        group_name: "",
        role_id: "",
        founding_assignment: "",
        user_id: ""
    }]);

    const userGroupsEditFormik = useFormik({
        initialValues: [{
            group_id: "",
            group_name: "",
            role_id: "",
            founding_assignment: "",
            user_id: ""
        }],
        // We used Yup here.
        validationSchema: Yup.object().shape({
            groupNRoles: Yup.array().of(
                Yup.object().shape({
                    group_id: Yup.string(),
                    group_name: Yup.string(),
                    role_id: Yup.string(),
                    founding_assignment: Yup.boolean(),
                    user_id: Yup.string()
                })
            )
        }),
        onSubmit: values => {
        },
    })

    useEffect(() => {
        // console.log("USERGROUPSEDITFORMIK VALUES CHANGED, userGroupsEditFormik.values:", userGroupsEditFormik.values);
    }, [userGroupsEditFormik.values])

    const openEdit = (user) => {
        setOpenEditCreate(true);
        setUserObj(user);
        setDialogMode("edit");
    }

    const closeEdit = () => {
        setOpenEditCreate(false);
    }

    function convertNumberToDate(number) {
        // Convert the number to milliseconds
        const milliseconds = Date.parse(number);

        // Create a new Date object with the converted milliseconds
        const date = new Date(milliseconds);

        // Format the Date object as a string in the desired format
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;

        return formattedDate;
    }

    function convertTimestamp(timestamp) {
        const dateObj = new Date(timestamp);

        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        const hours = String(dateObj.getHours()).padStart(2, '0');
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const seconds = String(dateObj.getSeconds()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return formattedDate;
    }

    function unixTimestampToDateTime(unixTimestamp) {
        const date = new Date(unixTimestamp);

        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const day = daysOfWeek[date.getUTCDay()];
        const dateOfMonth = date.getUTCDate();
        const month = monthsOfYear[date.getUTCMonth()];
        const year = date.getUTCFullYear();

        // Adjust to EST time zone
        const hours = (date.getUTCHours() - 5).toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');

        return `${day}, ${dateOfMonth} ${month} ${year} ${hours}:${minutes}:${seconds} EST`;
    }

    const usersLoad = async () => {
        try{
            const values = await Promise.allSettled([loadUsers(isFromAccount)])
            //console.log("InUsersLoad:", values[0].value);

            let tableDataTemp = values[0].value;

            tableDataTemp.forEach((tableRow, index) => {
                let num = tableDataTemp[index]["last_login_at"];
                let convertedToTimeDate = unixTimestampToDateTime(num);
                let isDeleted = tableDataTemp[index]["deleted_at"] ? true : false;
                let suspendedStatus = tableDataTemp[index]["deactivated_at"] ? "Suspended" : "Active";
                if (isDeleted) {
                    suspendedStatus = "Deleted"
                }
                let lastLoginAtIsNull = tableDataTemp[index]["last_login_at"] === null;
                tableDataTemp[index]["last_login_at"] = lastLoginAtIsNull ? '-' : convertedToTimeDate;
                tableDataTemp[index]["suspended_status"] = suspendedStatus;
            })

            setUsers(values[0].value);
            setData(values[0].value);
        } catch (e) {
            // console.log("Error loading users:", e);
        }
    }

    const accessibleGroupsLoad = async () => {
        try {
            const groupsRes = await Promise.allSettled([loadAccessibleGroups()]);
            const groups = groupsRes[0].value;

            if (groups) {
                setTopLevelGroupId(groups.find(({top_level_group}) => top_level_group)?.id);
                let groupNRoles = groups.map(group => {
                    return {
                        group_id: group.id,
                        group_name: group.group_name,
                        role_id: '',
                        founding_assignment: false,
                    }
                })
                sortItemsByField(groupNRoles, 'group_name', tableSortOrder);
                await userGroupsEditFormik.setValues(groupNRoles);
                setGroupsNRolesNewUser(groupNRoles);
            }
        } catch (e) {
            // console.log("Error loading accessible groups:", e);
        }
    }

    const accessibleRolesLoad = async () => {
        try {
            const roles = await Promise.allSettled([loadAccessibleRoles()]);
            setAccessibleRoles(roles[0].value);
        } catch (e) {
            // console.log("Error loading accessible roles:", e);
        }
    }

    const userPermissionsLoad = async () => {
        const permissionsObj = await Promise.allSettled([loadUserPermissions()]);

        try {
            const permissionsObj = await Promise.allSettled([loadUserPermissions()]);
            setCanCreateUser(hasAtLeastOneInAnyGroup(['CREATE_USER_OWN_GROUP', 'CREATE_USER_CHILD_GROUP'], permissionsObj[0].value));
            setCanViewUser(hasAtLeastOneInAnyGroup(['VIEW_USER_OWN_GROUP', 'VIEW_USER_CHILD_GROUP'], permissionsObj[0].value));
            setCanEditUser(hasAtLeastOneInAnyGroup(['UPDATE_USER_OWN_GROUP', 'UPDATE_USER_CHILD_GROUP'], permissionsObj[0].value));
            setCanDeleteUser(hasAtLeastOneInAnyGroup(['DELETE_USER_OWN_GROUP', 'DELETE_USER_CHILD_GROUP'], permissionsObj[0].value));

        } catch (e) {
            // console.log("Error loading user permissions:", e);
        }
    }


    const refreshTable = async () => {
        setOverlayActive(true);
        await Promise.allSettled([usersLoad(), accessibleGroupsLoad(), accessibleRolesLoad(), userPermissionsLoad()]);
        setOverlayActive(false);
    }


    useEffect(async () => {
        let firstNameFilterCell = document.querySelector('[title="first-name-filter"]');
        firstNameFilterCell.placeholder = "Search..."

        let lastNameFilterCell = document.querySelector('[title="last-name-filter"]');
        lastNameFilterCell.placeholder = "Search..."

        let emailFilterCell = document.querySelector('[title="email-filter"]');
        emailFilterCell.placeholder = "Search..."

        let companyCell = document.querySelector('[title="company-filter"]');
        companyCell.placeholder = "Search..."

        let statusCell = document.querySelector('[title="status-filter"]');
        statusCell.placeholder = "Search..."

        await refreshTable();
    }, [])

    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} openEdit={openEdit}/>
    );

    const ResetPasswordCell = (props) => (
        <td>
            <Button
                className="button-standard button-submit button-no-left-margin"
                type={'button'}
                onClick={(e) => {
                    e.stopPropagation()
                    setResetPwdConfirmDialog(true)
                    setUserSelectedForResetPwd(props.dataItem)
                }}
            >
                Reset Password
            </Button>
        </td>
    );

    const resetPassword = async (user) => {
        try {
            setOverlayActive(true);
            if (user) {
                let response = await semcastAxios.patch(PASSWORD_RESET_API + user.id, {}, {
                    withCredentials: true,
                });
                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage(`${user.first_name} ${user.last_name}  Reset Password Requested.`)
                }
            }
        } catch (error) {
            showErrorToastMessage(`${user.first_name} ${user.last_name}  Reset Password Requested Fail.`)
        } finally {
            setOverlayActive(false);
            setResetPwdConfirmDialog(false);
        }
    }

    const MySignInAsCommandCell = (props) => {
        let user = JSON.parse(sessionStorage.getItem(USER));
//        console.info("SignInprops=", props, "userObj=", user);
        return (
            <SignInAsCommandCell {...props} signInAs={signInAs} user={user}/>
        );
    };


    const MySignInAsAdministratorCell = (props) => {
        let user = JSON.parse(sessionStorage.getItem(USER));

        console.info("SignInprops=", props, "userObj=", user);
        return (
            <SignInAsAdministrator {...props} signInAs={signInAs} user={user}/>
        );
    };

    return (
        <div className={"users-permissions-datagrid-container"}>
            <LoadOverlay active={overlayActive} height={50} width={50}>
                <ExcelExport ref={_export} fileName={`Permissions - ${getCurrentDateTime()}`}>
                    <Grid
                        {...dataState}
                        data={processedData}
                        filterable={true}
                        onDataStateChange={onDataStateChange}
                        sortable={true}
                        //sort={sort}
                        pageable={{
                            buttonCount: 4,
                            pageSizes: [5, 10, 25, 50, 100],
                        }}
                    >
                        <GridToolbar>
                            <div className={"perm-users-grid-toolbar-container"}>
                                <div className={"permissions-users-grid-toolbar-buttons"}>
                                    <Button
                                        className={"button-standard button-submit button-no-left-margin"}
                                        iconClass={"icon-plus"}
                                        onClick={() => {
                                            setDialogMode("new");
                                            setUserObj({});
                                            userGroupsEditFormik.setValues(groupNRolesNewUser);
                                            setOpenEditCreate(true);
                                        }}
                                    >
                                        New
                                    </Button>
                                </div>
                                <div className={"adx-grid-toolbar-right"}>
                                    <Button
                                        className={"button-standard button-white"}
                                        iconClass={"k-icon k-i-download"}
                                        onClick={exportExport}
                                    >
                                        as CSV
                                    </Button>
                                </div>

                            </div>
                        </GridToolbar>

                        <Column field="first_name" title="First Name" filterTitle={"first-name-filter"}/>
                        <Column field="last_name" title="Last Name" filterTitle={"last-name-filter"}/>
                        <Column field="email" title="Email" filterTitle={"email-filter"}/>
                        <Column field="company" title="Company" filterable={true} filterTitle={"company-filter"}/>
                        <Column field="last_login_at" title="Last Login" filterable={false}/>
                        <Column field="suspended_status" title="Status" filterable={true} width={"100px"}
                                filterTitle={"status-filter"}/>
                        {/*<Column cell={SuspendedCell} title="Status" filterable={false} width={"121px"} />*/}
                        <Column cell={MySignInAsCommandCell} filterable={false} width={"121px"}/>
                        {
                            isFromAccount ?
                        <Column cell={ResetPasswordCell} filterable={false} width={"140px"}/>
                                :
                                <Column cell={MyEditCommandCell} filterable={false} width={"121px"}/>
                        }
                        <Column field="founder" title="Founder" cell={MySignInAsAdministratorCell} filterable={false}/>

                    </Grid>
                </ExcelExport>
                {openEditCreate && (
                    <PermissionsEditUser
                        closeEdit={closeEdit}
                        userObj={userObj}
                        setUserObj={setUserObj}
                        dialogMode={dialogMode}
                        userGroupsEditFormik={userGroupsEditFormik}
                        accessibleRoles={accessibleRoles}
                        topLevelGroupId={topLevelGroupId}
                        userHelpers={userHelpers}
                        users={users}
                        setUsers={setUsers}
                        refreshTable={refreshTable}
                        openEditCreate={openEditCreate}
                        groupNRolesNewUser={groupNRolesNewUser}
                    >

                    </PermissionsEditUser>
                )}
                {resetPwdConfirmDialog && (
                    <div className={"admin-users-delete-dialog-container"}>
                        <Dialog title={"Reset User Password"} onClose={() => setResetPwdConfirmDialog(false)}
                                className={"admin-users-delete-dialog dialog-standard"}>
                            <p className={"admin-users-delete-dialog-message-text"}>Are you sure you want to reset user
                                Password ?</p>

                            <DialogActionsBar>
                                <Button className="button-standard button-white" onClick={() => setResetPwdConfirmDialog(false)}>No</Button>
                                <Button className="button-standard button-submit" onClick={() => resetPassword(userSelectedForResetPwd)}>Yes</Button>
                            </DialogActionsBar>
                        </Dialog>
                    </div>
                )}

            </LoadOverlay>
        </div>
    )

};

export default UsersPermissionsDashboard;