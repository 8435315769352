import React, {useContext, useEffect, useState} from "react";
import {getFTPSettings, postAudienceFile} from "../../../actions/OnBoardingActions";
import {Button, ButtonGroup, DropDownButton, DropDownButtonItem} from "@progress/kendo-react-buttons";
import {Label} from "@progress/kendo-react-labels";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import FTPSettings from "./FTPSettings";
import S3Settings from "./S3Settings";
import FileBrowser from "./FileBrowser";
import {FILE_TYPE_UNKNOWN, FAIL_STATUS} from "../../../api/constants";
import {OnBoardingContext} from "../../Pages/OnBoarding";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import "./FTPSS3.css";
import {NavLink, useHistory} from "react-router-dom";

const FTPSS3 = () => {

    const [pageLoader, setPageLoader] = useState(false);
    const [allSavedSettings, setAllSavedSettings] = useState([]);
    const [ftpVisible, setFtpVisible] = useState(false);
    const [s3Visible, setS3Visible] = useState(false);
    const [mode, setMode] = useState("");
    const [fileBrowserVisible, setFileBrowserVisible] = useState(false);
    const [isOpenBrowserModal, setIsOpenBrowserModal] = useState(false);
    const widgetProps = useContext(OnBoardingContext);
    const [fileChosen, setFileChosen] = useState(false);
    const [filePath, setFilePath] = useState("");
    const [columnMappingData, setColumnMappingData] = useState({});

    const [currentConnection, setCurrentConnection] = useState(
        {text: '', id: 0}
    );

    const [storageService, setStorageService] = useState("FTP");
    const [createEdit, setCreateEdit] = useState("Create");


    // current connection (default / chosen) details
    const [connection, setConnection] = useState(
        {
            id: 0,
            connectionName: '',
            protocol: 'ftps',
            host: '',
            port: '',
            destinationDir: '',
            delimiter: '',
            transferMode: 'Passive',
            username: '',
            password: '',
        }
    );


    const getAllSavedSettings = (storageServiceParam) => {
        setPageLoader(true);
        getFTPSettings().then((res) => {
            if (res && res.data.length) {

                setAllSavedSettings(res.data);

                const setting = res.data[res.data.length-1];

                if(setting) {
                    setCurrentConnection({
                        ...currentConnection,
                        ['text']: setting.connection_name,
                        ['id']: setting.id,
                        ['protocol']: setting.transfer_protocol
                    })
                } else {
                    setCurrentConnection({text: '', id: 0});
                }

                setConnection(setting);
            }
            setPageLoader(false);
        });
    };

    const connections = allSavedSettings?.map((item, i) => {
        if (allSavedSettings.length > 0) {
            return (
                {text: item.connection_name, id: item.id, protocol: item.transfer_protocol}
            )
        } else {
            return (
                {text: "No Saved Connections", id: 0}
            )
        }
    });

    const handleNewSetting = (e) => {
        if(storageService === "FTP") {
            setCreateEdit("Create");
            setFtpVisible(!ftpVisible);
            setMode("NEW");
        } else {
            setCreateEdit("Create");
            setS3Visible(!s3Visible);
            setMode("NEW");
        }
    };

    const handleEditSetting = (e) => {
        if (connection['transfer_protocol'] === "ftps" || connection['transfer_protocol'] === "sftp") {
            setCreateEdit("Edit");
            setFtpVisible(!ftpVisible);
            setMode("EDIT");
        } else if (connection['transfer_protocol'] === "s3") {
            setCreateEdit("Edit");
            setS3Visible(!s3Visible);
            setMode("EDIT");
        }
    };

    const handleConnectionChange = (e) => {
        console.log(e.value);
        const setting = allSavedSettings.filter(ftpSetting => ftpSetting.id === e.value.id);
        setConnection(setting[0]);
        setCurrentConnection({
            ...currentConnection,
            ['text']: e.value.text,
            ['id']: e.value.id
        });
        setFileChosen(false);
    };

    const cancelFTPSettings = () => {
        setFtpVisible(false);
        setIsOpenBrowserModal(false);
    };

    const cancelS3Settings = () => {
        setS3Visible(false);
        setIsOpenBrowserModal(false);
    };

    const handleClose = (type, params = {}) => {
        if (type) {
            const payload = {
                column_map: null,
                date_format: null,
                delimiter: null,
                file_type: null,
                ftp_settings_id: currentConnection.id,
                header_row_count: 0,
                id: 0,
                path: params.path,
                shapes: null,
                time_zone: "",
                use_time: 0,
            };
            setPageLoader(true);
            postAudienceFile(payload).then((res) => {
                widgetProps.clearSavedConfig();
                if (res === FILE_TYPE_UNKNOWN) {
                    widgetProps.handleUnkownFileTypeModel(true);
                    setFileChosen(false);
                } else if (res.status === FAIL_STATUS) {
                    if (!res.data) {
                        widgetProps.handleFileUploadErrors(`Invalid parameter: The file is not allowed. Allowed file extensions are csv, tsv, log, txt`);
                    } else {
                        widgetProps.handleFileUploadErrors(res.data);
                    }
                    setFileChosen(false);
                } else if (res) {
                    widgetProps.handleColumnMappingModel(true, true, res);
                    setFileChosen(true);
                    setFilePath(res.data.path);
                    setColumnMappingData(res);
                }
                setPageLoader(false);
            });
        }
        setIsOpenBrowserModal(false);
    };

    const browseSettings = (isFromFtp, settingId) => {
        // if (!isFromFileTransferPage) {
        //     if (isFromFtp) {
        //         setSettingId(settingId);
            // } else {
            //     setSettingId(settingId);
            // }
            setIsOpenBrowserModal(true);
            setFileBrowserVisible(!fileBrowserVisible);
        // }
    };

    const itemRender = (li, itemProps) => {
        const itemChildren = (
            <span
                style={{
                    // color: itemProps.dataItem.protocol === 's3' ? "blue" : "red",
                    fontSize: "16px"
                }}
            >
                {li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }

    useEffect( () => {
        async function fetchAllSavedSettings() {
            await getAllSavedSettings();
        }
        fetchAllSavedSettings();
    }, []);

    return (
        <div className={"ftp-s3-tab-outer-div"}>
            <LoadOverlay active={pageLoader}>
            {ftpVisible && (<FTPSettings
                visible={true}
                ftpSettings={connection}
                setFtpSettings={setConnection}
                setFtpVisible={setFtpVisible}
                cancelFTPSettings={cancelFTPSettings}
                getAllSavedSettings={getAllSavedSettings}
                mode={mode}
                createEdit={createEdit}
            />)}
            {s3Visible && (<S3Settings
                visible={true}
                s3Settings={connection}
                setS3Settings={setConnection}
                setS3Visible={setS3Visible}
                cancelS3Settings={cancelS3Settings}
                getAllSavedSettings={getAllSavedSettings}
                mode={mode}
                createEdit={createEdit}
            />)}


                <div className={"connections-container-div"}>
                    <div className={"ftp-settings-controls-container"}>
                        <div className={"ftp-settings-fts-row"}>
                            <div className={"fts-controls"}>
                                <div className={"existing-connections-dropdown-div"}>

                                    <Label className={"field-label fts-tab-ec-label"}>Connections</Label>


                                    <div className={"onboarding-conn-buttons-container"}>
                                        <div className={"manage-connections-button-div"}>
                                            <NavLink to={"/admin-settings"} className={"nav-link-to-admin"}
                                                     onClick={() => sessionStorage.setItem('adminTabSelected', 2)}>
                                                <Button
                                                    className={"button-standard button-submit ftps3-tab-button manage-conn-button"}
                                                    iconClass="k-icon k-i-track-changes-enable"
                                                    // disabled={allSavedSettings.length===0}
                                                    // onClick={(e) => handleEditSetting(e)}
                                                >
                                                    Manage Connections
                                                </Button>
                                            </NavLink>
                                        </div>

                                        <DropDownList
                                            data={connections}
                                            itemRender={itemRender}
                                            textField="text"
                                            dataItemKey="id"
                                            onChange={(e) => handleConnectionChange(e)}
                                            value={currentConnection}
                                            fillMode={"outline"}
                                            className="connection-settings"
                                            disabled={allSavedSettings.length===0}
                                            style={{fontSize:"18px"}}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={"tab-file-browser"}>
                    <Label className={"field-label"}>Browser</Label>
                    <div className={"tab-file-browser-inner-div"}>
                        {!fileChosen && (
                            <>
                                <Button
                                    className="choose-a-file"
                                    title={"Choose a File"}
                                    onClick={() => browseSettings(true, currentConnection.id)}>
                                    Choose a File
                                </Button>
                            </>
                        )}
                        {fileChosen && (
                            <>
                                <Button
                                    className="file-browse-button-1"
                                    iconClass="icon-folder"
                                    title="Upload FTP File"
                                    onClick={() => browseSettings(true, currentConnection.id)}>
                                </Button>
                                <Button
                                    className="file-mapping-button"
                                    iconClass="icon-file"
                                    title="View Column Mapping"
                                    onClick={() => widgetProps.handleColumnMappingModel(true, false, columnMappingData)}>
                                </Button>
                                <Button
                                    className="file-chosen"
                                    onClick={() => browseSettings(true, currentConnection.id)}>
                                    {filePath}
                                </Button>
                            </>
                        )}

                    </div>
                </div>

                {fileBrowserVisible && (<FileBrowser
                    isOpenModel={isOpenBrowserModal}
                    handleClose={handleClose}
                    settings={currentConnection.id}
                    visible={true}
                    setFileBrowserVisible={setFileBrowserVisible}
                    // connection={connection}
                    // currentConnection={currentConnection}
                    connectionTitle={currentConnection.text}
                />)}
            </LoadOverlay>
        </div>
    )
}

export default FTPSS3;