import "./TopEmployeeTitles.css";
import React from "react";

const TopEmployeeTitles = ({data, chartId}) => {

    return (
        <>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split" id={chartId}>
                <div className="card">
                    <div className="text-center">
                        <h4>Top Employee Titles</h4>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split">
                            <div className="report-card prevent-split">
                                <div className="card-body prevent-split">
                                    <div className="table-responsive business-topemployee prevent-split">
                                        <table className="table table-responsive-md business table-striped prevent-split">
                                            <thead>
                                            <tr>
                                                <th className="col-xl-1 col-lg-1 col-md-1">
                                                    <strong>Rank</strong>
                                                </th>
                                                <th className="col-xl-2 col-lg-2 col-md-2">
                                                    <strong>Category</strong>
                                                </th>
                                                <th className="col-xl-2 col-lg-2 col-md-2">
                                                    <strong>Title</strong>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.rows.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <td>{item["Rank"]}</td>
                                                        <td>{item["Category"]}</td>
                                                        <td>{item["Title"]}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default TopEmployeeTitles;
