import * as React from 'react';
import { ColumnMenuForm, ColumnMenuItem } from '@progress/kendo-react-data-tools';
import { ColumnMenuContext } from './ColumnMenuContext';
export const ColumnChooser = props => {
    const {
        columns,
        visibleColumns,
        onColumnToggle
    } = React.useContext(ColumnMenuContext);
    const [visible, setVisible] = React.useState(visibleColumns);
    const [expand, setExpand] = React.useState(false);
    const onToggleExpand = React.useCallback(() => {
        setExpand(!expand);
    }, [expand]);
    const onSubmit = React.useCallback(event => {
        event.preventDefault();
        props.closeMenu();
        onColumnToggle.call(undefined, {visible});
    }, [props, onColumnToggle, visible]);
    const onReset = React.useCallback(() => {
        props.closeMenu();
        onColumnToggle.call(undefined, {
            visible: columns.map(c => c.field)
        });
    }, [props, onColumnToggle, columns]);
    const onChange = React.useCallback((event, field) => {
        if (event.target.checked) {
            setVisible([...visible, field]);
        } else {
            setVisible(visible.filter(f => f !== field));
        }
    }, [visible]);
    return <div className="k-columnmenu-item-wrapper" key="filtering">
        <ColumnMenuItem iconClass="k-i-columns" title="Columns" onClick={onToggleExpand} />
        <ColumnMenuForm show={expand} onSubmit={onSubmit} onReset={onReset} actions={<React.Fragment>
            <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" type="reset">Reset</button>
            <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">Save</button>
        </React.Fragment>}>
            <div className="k-column-list">
                {columns.slice(2).map(column => {
                    const field = column.field;
                    const checked = visible.includes(field);
                    const id = 'column-menu-id-' + field;
                    return <div className="k-column-list-item" key={id}>
                    <span>
                      <input id={id} type="checkbox" className="k-checkbox k-checkbox-md k-rounded-md" checked={checked} value={checked} onChange={e => onChange(e, field)} disabled={visible.slice(1).length < 2} />
                      <label htmlFor={id} className="k-checkbox-label" style={{
                          userSelect: 'none'
                      }}>
                        {column.title || field}
                      </label>
                    </span>
                    </div>;
                })}
            </div>
        </ColumnMenuForm>
    </div>;
};