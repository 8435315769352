import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const facebookConfig = {
  ...COMPANY_BASE_PROPERTIES,
  /**
   * User Readable name of company the user is onBoarding with
   */
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: 'Onboarding With Facebook',

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: 'facebook',

  /**
   * HTML that explains how to use the form
   */
  instructions: '<p style="color:blue;font-size:18px;">This Platform does not support clearing and billing for data usage.<br>' +
      'Per our License Agreement you will be charged for 100% of the number of Platform Reach you\n' +
      'are about to activate. To proceed;<br>' +
      '<strong>1) Enter the email of your Billing Contact for this campaign.</strong><br>' +
      '<strong>2) Confirm your acceptance of these charges by checking the box below.</strong></p>' +
      '<p style="color:red;font-size:18px;"><Strong>NOTE:</Strong> By default, the segment data will be uploaded to Semcasting&apos;s Facebook<br>' +
      'account ID and we will in turn share it with your Facebook Account ID, or you can upload it directly<br>' +
      'to your Facebook Account ID if you previously grant Semcasting partner access to your account.</p>',

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: 'facebook',
    text: 'Facebook',
    img: 'facebook-logo.svg'
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink: '<a href="https://facebook.com" target="_blank">Log in to Facebook</a>',

  layout: [
    {
      fieldType: 'radioGroup',
      labelText: 'Upload To:',
      fieldName: 'upload',
      required: true,
      radioButtons: [
        {
          labelText: 'Semcasting (Recommended)',
          fieldName: 'upload_semcasting',
          value: 'upload_semcasting',
          checked: true
        }, {
          labelText: 'My Account (Requires Partner Access)',
          fieldName: 'upload_direct',
          value: 'upload_direct',
          checked: false
        },
      ],
    },


    // {
    //   fieldType: 'checkboxGroup',
    //   labelText: 'Mobile Devices',
    //   fieldName: 'include_mobile_devices',
    //   required: false,
    //   checkboxes:[
    //     {
    //       labelText: 'Include Mobile Devices',
    //       fieldName:'do_include_mobile_devices',
    //       value: 'do_include_mobile_devices',
    //       checked: true
    //     }
    //   ]
    // },
    {
      fieldType: "radioGroup",
      labelText: "Upload Options",
      fieldName: "device_type",
      required: true,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: true,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },
        {
          labelText: "Hashed Emails",
          fieldName: "hashed_emails_sha256",
          value: "hashed_emails_sha256",
          checked: false,
        },
      ],
    },
    // {
    //   fieldType: "checkboxGroup",
    //   labelText: "Emails to Activate",
    //   fieldName: "device_type",
    //   required: false,
    //   checkboxes: [
    //     {
    //       labelText: "Hashed Emails",
    //       fieldName: "hashed_emails",
    //       value: "hashed_emails_sha256",
    //       checked: false,
    //     },
    //   ],
    // },
    {
      fieldType: 'dateRange',
      fieldName: 'campaign_range',
      labelText: 'Campaign Date Range',
      required: 'dateRangeRequired',
    },
    {
      fieldType:'textField',
      fieldName:'billing_contact_email',
      labelText:'Billing Contact Email',
      required: true,
      validation:[{
        pattern: 'email',
        msg: 'Please enter a valid email'
      }]
    },
    {
      fieldType: 'savableFieldsWrapper',
      layout: [
        {
          fieldType: 'searchSelect',
          fieldName: 'account_id',
          labelText: 'Account ID',
          url: '/v2/secure/attributor/activate-credentials/facebook/account_id',
          placeholder: 'Select an Account ID or add a new one',
          namePropName: 'value',
          textPropName: 'value',
          valuePropName: 'id',
          required: true,
          // validation: [{
          //   maxLength: 255
          // }]
        },
        {
          fieldType: 'checkbox',
          fieldName: 'save_credentials',
          labelText: 'Save Account ID for future use',
          checked: false
        }
      ]
    },
    {
      fieldType: 'radioGroup',
      labelText: 'Planned Usage of this Segment:',
      fieldName: 'audience_usage',
      required: true,
      radioButtons: [
        {
          labelText: 'Targeting',
          fieldName: 'use_type_inclusion',
          value: 'I',
          checked: false
        }, {
          labelText: 'Suppression',
          fieldName: 'use_type_exclusion',
          value: 'X',
          checked: false
        }
      ]
    },
    {
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },{
      fieldType: "pricing",
    },
    {
      fieldType: 'checkbox',
      fieldName: 'client_agreement',
      required: true,
      labelText: '<strong>Please Confirm: By Checking this Box and Activating, you agree to all associated costs and fees described above</strong>',
      value: false
    }
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["account_id"],

  formik: {
    initialValues: {
      device_type: "hashed_emails_sha256",
      campaign_range_start: "",
      campaign_range_end: "",
      // device_type: ["hashed_emails_sha256"],
      //save fields
      save_credentials: false,
      account_id: "",
      audience_usage:"",
      creativeTagIdDropDown: "0",
      creativeTagId:"0",
      creativeTag:"%3Cimg%20src%3D%22https%3A%2F%2Fnon-usable-tag%2Ftagid%2F0%2F%22%20alt%3D%22%22%20style%3D%22display%3Anone%20!important%3B%22%20%2F%3E",
      billing_contact_email:"",
      client_agreement:"",
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      device_type: Yup.string().required("Device Type is required"),
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      account_id: Yup.string()
        .max(255, "Length of the ID should be less than 255")
        .required("Account ID is required"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      billing_contact_email: Yup.string().required("Billing Contact Email is required"),
      client_agreement: Yup.boolean().required("Client Agreement is required"),
    }),
    onSubmit: (values) => {},
  },
};
