import React, {useEffect, useState} from "react";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import "./EthnicGroup.css";

const EthnicGroup = (props) => {

    const [value, setValue] = useState([]);

    const [ethnicGroupOptions, setEthnicGroupOptions] = useState([
        {text: "African American", value: "AFR"},
        {text: "Asian", value: "ASN"},
        {text: "Hispanic", value: "HIS"},
        {text: "Middle Eastern", value: "MID"},
        {text: "White", value: "WHT"}
    ]);

    useEffect(() => {
        let arr = [];
        props.audienceState.sem_ethnic_region_cd.forEach(rangeValue => {
            ethnicGroupOptions.find(option => {
                if (option.value === rangeValue) {
                    arr.push(option);
                }
                ;
            });
        });
        setValue(arr);
    }, [props.audienceState]);

    const onEthnicGroupChange = (e) => {
        let values = [];
        setValue(e.value);
        e.value.forEach((val) => {
            values.push(val.value);
        });
        // props.audienceState.sem_ethnic_region_cd = values;
        props.handleAudienceState(prevState => {
            return {...prevState, sem_ethnic_region_cd: values}}
        );
    };

    return (
        <div>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={ethnicGroupOptions}
                    autoClose={false}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    onChange={onEthnicGroupChange}
                    size="large"
                    value={value}
                    // style={{width: "32rem"}}
                />

            </div>
        </div>
    );
}
export default EthnicGroup;
