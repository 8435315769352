import React, {useEffect, useState} from "react";
import AgeRange from "./AgeRange";
import AnnualRevenue from "./AnnualRevenue";
import DigitalActivityIndex from "./DigitalActivityIndex";
import EmployeesRange from "./EmployeesRange";
import EthnicBackground from "./EthnicBackground";
import EthnicGroup from "./EthnicGroup";
import GenderButtonGroup from "./GenderButtonGroup";
import HomeOwnershipButtonGroup from "./HomeOwnershipButtonGroup";
import HomeValueRange from "./HomeValueRange";
import IncomeRange from "./IncomeRange";
import InflationSensitivityIndex from "./InflationSensitivityIndex";
import LegislativeDistricts from "./LegislativeDistricts";
import Locations from "./Locations";
import Naics from "./Naics";
import PoliticalPartyButtonGroup from "./PoliticalPartyButtonGroup";
import SocialMatrix from "./SocialMatrix";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import { DateInput, DateRangePicker } from "@progress/kendo-react-dateinputs";
import {Form, FormElement, FieldWrapper} from "@progress/kendo-react-form";
import {Label} from "@progress/kendo-react-labels";
import {RadioGroup} from "@progress/kendo-react-inputs";
import {Notification} from "@progress/kendo-react-notification";
import moment from "moment";
import "./DesignAudienceEdit.css";
import {Tooltip} from "@progress/kendo-react-tooltip";

const DesignAudienceEdit = (props) => {
    const [selectedTargetType, setSelectedTargetType] = useState(props.targetType);

    const [msg, setMsg] = useState("");

    const targetTypes = [
        { label: "Consumer", value: "CONSUMER"},
        { label: "Business", value: "BUSINESS", disabled: props.originalType === "CONSUMER" ? true : false}
    ];

    const CustomEndDateInput = (props) => {
        return (
            <>
                <DateInput {...props} format="yyyy-MM-dd" placeholder="YYYY-MM-DD"
                           label={<span>End Date</span>}/>
            </>
        )
    };

    const CustomStartDateInput = (props) => {
        return (
            <>
                <DateInput {...props} format="yyyy-MM-dd" placeholder="YYYY-MM-DD"
                           label={<span>Start Date</span>}/>
            </>
        )
    };

    useEffect(() => {
        setSelectedTargetType(props.audienceState.target_type);
        props.handleTargetType(props.audienceState.target_type);

    }, []);

    // If we switch from BUSINESS to CONSUMER
    const handleTargetType = (e) => {
        setSelectedTargetType(e.value);
        props.handleTargetType(e.value);
        if (props.originalType === "BUSINESS" && e.value === "CONSUMER") {
            handleAudienceState(prevState => {
                return {...prevState, segment_name: "Customers of ".concat(
                        props.segmentName !== ""  ? props.segmentName : props.audienceState.originalSegmentName, )};
            });
            console.log(props.segmentName)
            props.handleSegmentName("Customers of ".concat(props.segmentName));
        } else {
            handleAudienceState(prevState => {
                return {...prevState, segment_name: props.audienceState.originalSegmentName};
            });
            props.handleSegmentName( props.audienceState.originalSegmentName);
        }
    };

    const onConsumerVisitDatesChange = (e) => {
        console.log(e);
        handleAudienceState(prevState => {
            return {...prevState, segment_date_start: moment(e.value.start).format("YYYY-MM-DD"), segment_date_end: moment(e.value.end).format("YYYY-MM-DD") };
        });
    };

    const handleAudienceState = (state) => {
        props.handleAudienceState(state);
    };

    const handleSubmit = (e) => {

    };
    const displayWarning = () => {
        if (props.originalType === "BUSINESS" &&
            props.targetType === "CONSUMER" && props.audienceState["titleMatching"].length > 0) {
            if (props.audienceState.first_party_location_focus === "S") {
                setMsg("You will now be creating a new segment targeting Consumers where the industry and title match the Industry Naics Codes of these businesses.");
            } else if (props.audienceState.first_party_location_focus === "B") {
                setMsg("You will now be creating a new segment targeting Consumers where the title matches are from these specific businesses.");
            }
            return true;
        } else if (props.originalType === "BUSINESS" &&
            props.targetType === "CONSUMER" && props.audienceState["titleMatching"].length === 0) {
            setMsg("You will now be targeting Consumers seen at these businesses.");
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <Card>
                <CardTitle>
                    <Label className="section-label">DESIGN AUDIENCE</Label>
                </CardTitle>
                <CardBody>
                    <Form render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                        <legend className={'k-form-legend spacingLine'}></legend>

                        <div className={"basic-segment-div"}>
                            <Label className={"basic-segment-label"}>
                                <span>Basic Segments are $1.00 per thousand</span>
                            </Label>
                        </div>
                        <div className={"notification-div"} style={{textAlign: "center", display: displayWarning() ? "" : "none"}}>
                            <Notification
                                style={{backgroundColor:"#FFFFCC"}}
                                type={{style:"none"}}
                                closable={false}>
                                <span>{msg}</span>
                            </Notification>
                        </div>

                        <div className={"design-audience-container"}>
                            <div className={"design-audience-column-1"}>
                                <Form render={formRenderProps => <FormElement style={{
                                    width: "100%"
                                }}>
                                    <FieldWrapper>
                                        <Label className={"field-label"} >Target Type</Label>
                                        <RadioGroup
                                            data={targetTypes}
                                            value={props.targetType}
                                            onChange={handleTargetType}
                                            layout="horizontal"
                                            style={{width:"100%", fontSize:".9rem"}}
                                        />
                                    </FieldWrapper>
                                    {/*{selectedTargetType === "CONSUMER" && (*/}
                                        {props.targetType === "CONSUMER" && (
                                        <>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Age Range</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "8rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                        <i className="fa fa-question-circle-o tooltip-question-icon"
                                                           aria-hidden="true"
                                                           title={"Age Range."}></i>
                                                    </Tooltip>
                                                </span>
                                                <AgeRange
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Income Range</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                        <i className="fa fa-question-circle-o tooltip-question-icon"
                                                           aria-hidden="true"
                                                           title={"Income Range specifies estimated Income ranges."}></i>
                                                    </Tooltip>
                                                </span>
                                                <IncomeRange
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Home Value</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                        <i className="fa fa-question-circle-o tooltip-question-icon"
                                                           aria-hidden="true"
                                                           title={"Home Value specifies estimated Home Value ranges."}></i>
                                                    </Tooltip>
                                                </span>
                                                <HomeValueRange
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                            <FieldWrapper>
                                                <div className={"design-audience-row"}>
                                                    <div className={"column"}>
                                                        <span className={"tooltip-label-and-icon-wrapper"}>
                                                            <Label className={"field-label"}>Home Ownership</Label>
                                                            <Tooltip
                                                                position="right"
                                                                anchorElement="target"
                                                                tooltipStyle={{
                                                                    width: "15rem",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                   aria-hidden="true"
                                                                   title={"Home Ownership identifies an individual as a Home Owner, Renter or both."}></i>
                                                            </Tooltip>
                                                        </span>
                                                        <HomeOwnershipButtonGroup
                                                            audienceState={props.audienceState}
                                                            handleAudienceState={handleAudienceState}
                                                        />
                                                    </div>
                                                    <div className={"column"}>
                                                        <span className={"tooltip-label-and-icon-wrapper"}>
                                                            <Label className={"field-label"}>Gender</Label>
                                                            <Tooltip
                                                                position="right"
                                                                anchorElement="target"
                                                                tooltipStyle={{
                                                                    width: "15rem",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                   aria-hidden="true"
                                                                   title={"Gender identifies individuals as Men or Women."}></i>
                                                            </Tooltip>
                                                        </span>
                                                        <GenderButtonGroup
                                                            audienceState={props.audienceState}
                                                            handleAudienceState={handleAudienceState}
                                                        />
                                                    </div>
                                                </div>
                                            </FieldWrapper>

                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Political Party</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                        <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Political Party is an organization that coordinates candidates to compete in a particular country's elections."}></i>
                                                    </Tooltip>
                                                </span>
                                                <PoliticalPartyButtonGroup
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                            <FieldWrapper className={"design-audience-edit-legislative-div"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Legislative Districts</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Legislative Districts refer to areas from which members are elected to state or equivalent entity legislatures."}></i>
                                                    </Tooltip>
                                                </span>
                                                <LegislativeDistricts
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                        </>
                                    )}
                                    {props.targetType === "BUSINESS" && (
                                        <>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Annual Revenue</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Annual Revenue specifies Revenue estimate ranges."}></i>
                                                    </Tooltip>
                                                </span>
                                                <AnnualRevenue
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={props.handleAudienceState}
                                                />
                                            </FieldWrapper>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>NAICS Codes</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"NAICS Codes refer to 3 or 6 digit Industry Codes."}></i>
                                                    </Tooltip>
                                                </span>
                                                <Naics
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={props.handleAudienceState}
                                                />
                                            </FieldWrapper>
                                        </>
                                    )}
                                </FormElement>} />
                            </div>
                            <div className={"design-audience-column-2"}>
                                <Form render={formRenderProps => <FormElement style={{
                                    width: "100%"
                                }}>
                                    <FieldWrapper>
                                        <Label className={"field-label targeting-selection-type-label"} style={{paddingBottom:"3.4rem"}}></Label>
                                        {/*Moved to Advanced Targeting Section - demo feedback - 1/27/23*/}
                                        {/*<RadioGroup*/}
                                        {/*    data={selectionTypes}*/}
                                        {/*    value={selectionType}*/}
                                        {/*    onChange={handleSelectionType}*/}
                                        {/*    layout="vertical"*/}
                                        {/*    style={{width:"100%", fontSize:"16px"}}*/}
                                        {/*/>*/}
                                    </FieldWrapper>
                                    {props.targetType === "CONSUMER" && (
                                        <>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Ethnic Group</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Ethnic Group refers to a specific community of people who share a common cultural, linguistic, and/or religious identity, and who are recognized as a distinct social group."}></i>
                                                    </Tooltip>
                                                </span>
                                                <EthnicGroup
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Ethnic Background</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Ethnic Background refers to a person's individual cultural and ancestral heritage, such as their language, customs, and traditions."}></i>
                                                    </Tooltip>
                                                </span>
                                                <EthnicBackground
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Social Matrix</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Social Matrix refers to personas based on Age, Life Style & Affluence."}></i>
                                                    </Tooltip>
                                                </span>
                                                <SocialMatrix
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Digital Activity Index</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Digital Activity Index refers to the measurement of internet engagement and time online."}></i>
                                                    </Tooltip>
                                                </span>
                                                <DigitalActivityIndex
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Inflation Sensitivity Index</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Inflation Sensitivity Index identifies how vulnerable a particular household may be in the current economic climate."}></i>
                                                    </Tooltip>
                                                </span>
                                                <InflationSensitivityIndex
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                            <FieldWrapper>
                                                <Label className={"field-label"}>Locations</Label>
                                                <Locations
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={handleAudienceState}
                                                />
                                            </FieldWrapper>
                                        </>
                                    )}
                                    {props.targetType === "BUSINESS" && (
                                        <>
                                            <FieldWrapper>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                <Label className={"field-label"}>Number of Employees</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Number of Employees specify ranges for the Number of Employees at a business."}></i>
                                                    </Tooltip>
                                                </span>
                                                <EmployeesRange
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={props.handleAudienceState}
                                                />
                                            </FieldWrapper>
                                            <FieldWrapper>
                                                <Label className={"field-label"}>Locations</Label>
                                                <Locations
                                                    audienceState={props.audienceState}
                                                    handleAudienceState={props.handleAudienceState}
                                                />
                                            </FieldWrapper>
                                        </>
                                    )}
                                </FormElement>} />
                            </div>
                        </div>
                    </FormElement>} />
                    {selectedTargetType === "CONSUMER" && props.originalType === "BUSINESS" && (
                        <FieldWrapper className={"design-audience-edit-consumer-date-range"}>
                            <Label className={"field-label"}>Consumer Visit Dates</Label>
                            <DateRangePicker
                                startDateInput={CustomStartDateInput}
                                endDateInput={CustomEndDateInput}
                                // onChange={onConsumerVisitDatesChange}
                            />
                        </FieldWrapper>
                    )}
                </CardBody>
            </Card>
        </>
    )
}

export default DesignAudienceEdit;