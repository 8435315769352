import React, {useEffect, useState, useRef, useCallback} from "react";
import {useParams} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";

import AboutAudience from "../Components/Onboarding/AboutAudience";
import AdvancedTargeting from "../Components/AudienceDesign/AdvancedTargeting";
import DesignAudience from "../Components/AudienceDesign/DesignAudience";
import KeywordsSites from "../Components/AudienceDesign/KeywordsSites";
import audienceDesignConfig from "../../config/audienceDesignConfig";
import LoadOverlay from "../Components/SharedComponents/LoadOverlay";
import "./Dashboard.css";
import {
    getPpkSegments,
    postSegments,
} from "../../actions/AudienceDesignActions";
import $, {isEmptyObject} from "jquery";
import "./AudienceDesign.css"
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button} from "@progress/kendo-react-buttons";
import {showErrorToastMessage} from "../../Utils/Common";

const AudienceDesign = () => {
    const segmentNameRegExp = RegExp(/^[a-zA-Z0-9_\- ]{3,64}$/);
    const [pageLoader, setPageLoader] = useState(false);
    const destinationUrl = "segmentation-dashboard";
    const [targetingData, setTargetingData] = useState({});
    const [targetType, setTargetType] = useState("CONSUMER");
    // The state (selections) for the controls (eg: age_range)
    const [controlsState, setControlsState] = useState(new Map());
    const [msg, setMsg] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const audienceDesignFormik = useFormik({
        initialValues: {
            target_type: "CONSUMER",
            segment_name: "",
            group_id: 0,
            notes: "",
            sites: "",
            keywords: "",
            segment_design: {
                consumer: [],
                sportsAttendees: [],
                politicalAffiliations: [],
                homeOwner: [],
                personalFinance: [],
                specialtySegments: [],
                specialtySegmentsBusiness: [],
                titleMatching: [],
                merge_type: "DISTINCT",
                primaryData: {
                    age_range: [],
                    digital_activity_index: [],
                    gender: [],
                    home_value_cd: [],
                    homeowner_flg: [],
                    income_cd: [],
                    inflation_sensitivity_index: [],
                    social_matrix: [],
                    reg_party_cd: [],
                    sem_ethnic_cd: [],
                    sem_ethnic_region_cd: [],
                    districts: [],
                    employees_total_cd: [],
                    naics: [],
                    revenue_cd: [],
                    city: [],
                    state: [],
                    zip3: [],
                    zip5: []
                },
                acxiomLifestyle: [],
                analyticsIQ: [],
                hcpspecialtySegmentsBusiness: [],
                healthWise: [],
                // FD:10912
                // i360: [],
                // iqvia: [],
                knowWhoConsumer: [],
                l2Data: [],
                datatrust: [],
                smllearning: [],
                purpleLab: [],
                purpleLabHCP: [],
                purpleLabHPD: [],
                salesintel: [],
                salesinteltechno: [],
                spectrumMedia: [],
                qwarry: [],
                // throtle: [],
                target_type: "CONSUMER"
            },
            // locations: []  // placeholder from Locations control
        },
        validationSchema: Yup.object().shape({
            segment_name: Yup.string()
                .required("Segment Name is required")
                .matches(segmentNameRegExp, "Name must be at 3-64 characters and only contains letters, numbers, hyphens and underscores")
            // audience_file_id: Yup.number().positive().integer()
        }),
        onSubmit: (values) => {
            // console.log(values);
        },
    });

    const handleTargetType = (targetType) => {
        setTargetType(targetType);
    };

    const handleControlsState = (state) => {
        setControlsState(state);
    };


    useEffect(() => {
    }, [submitDisabled]);
    const handleSubmit = async(e, formik) => {
        await formik.submitForm();
        //formik.isValid was returning true when should be false
        //maybe use setTimeout to wait 3 sec - aka ActivateSegmentForm.js
        if (formik.values.segment_name === ""){
            // Re-enable button
            setSubmitDisabled(false)
            return;
        }
        if (formik.isValid) {
            try {
                setPageLoader(true);
                const data = getData();

                postSegments(
                    data
                ).then((res) => {
                    setPageLoader(false);
                    if (res && res.status === 200) {
                        setTimeout(() => {
                            window.location.href = destinationUrl;
                        }, 3000);
                    } else {
                        // Re-enable button if failure.
                        setSubmitDisabled(false);
                    }
                });
            } catch (error) {
                // Re-enable button if failure.
                setSubmitDisabled(false);
                showErrorToastMessage("Error sending request. Request not sent.");
            } finally {
                console.log('finally')
            }
        } else {
            // Re-enable button if failure.
            setSubmitDisabled(false);
            showErrorToastMessage("Error sending request. Request not sent.");
        }
    }

    const getData = () => {
        // const audienceFileId = audienceDesignFormik.values.audience_file_id;
        const segmentName = audienceDesignFormik.values.segment_name;
        const notes = audienceDesignFormik.values.notes;
        const keywords = audienceDesignFormik.values.keywords;
        const sites = audienceDesignFormik.values.sites;
        const targetType = audienceDesignFormik.values.target_type;
        const groupId = audienceDesignFormik.values.group_id;
        const segmentDesign = buildSegmentDesign();
        let data = {
            segment_name: segmentName,
            notes: notes,
            keywords: keywords,
            sites: sites,
            group_id:groupId,
            // audience_file_id: audienceFileId,
            target_type: targetType,
            segment_design:segmentDesign,
        }
        if (data.sites === '') delete data.sites;
        if (data.keywords === '') delete data.keywords;
        return data;
    };

    const buildSegmentDesign = () => {
        const consumer = audienceDesignFormik.values.segment_design.consumer;
        const sportsAttendees = audienceDesignFormik.values.segment_design.sportsAttendees;
        const politicalAffiliations = audienceDesignFormik.values.segment_design.politicalAffiliations;
        const mergeType = audienceDesignFormik.values.segment_design.merge_type;
        const targetType = audienceDesignFormik.values.segment_design.target_type;
        const primaryData = buildPrimaryData(targetType);
        const homeOwnerData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.homeOwner : [];
        const personalFinanceData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.personalFinance : [];
        const titleMatchingData = targetType === "CONSUMER" || targetType === "BUSINESS" ? audienceDesignFormik.values.segment_design.titleMatching : [];
        const specialtyData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.specialtySegments : [];
        const businessSpecialtyData = targetType === "BUSINESS" ? audienceDesignFormik.values.segment_design.specialtySegmentsBusiness : [];
        const travelData = targetType === "BUSINESS" ? audienceDesignFormik.values.segment_design.travelAndEntertainment : [];
        // const socialMatrixData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.social_matrix : [];
        const spectrumMediaData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.spectrumMedia : [];
        const qwarryData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.qwarry : [];
        const acxiomLifestyleData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.acxiomLifestyle : [];
        const analyticsIQData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.analyticsIQ : [];
        // const affinitySolutionsData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.affinitySolutions : [];
        const hcpspecialtySegmentsData = targetType === "BUSINESS" ? audienceDesignFormik.values.segment_design.hcpspecialtySegmentsBusiness : [];
        // const healthWiseData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.healthWise : [];
        const knowWhoConsumerData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.knowWhoConsumer : [];
        const purpleLabData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.purpleLab : [];
        const purpleLabHCPData = targetType === "CONSUMER" || targetType === "BUSINESS" ? audienceDesignFormik.values.segment_design.purpleLabHCP : [];
        const purpleLabHPDData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.purpleLabHPD : [];
        const l2DataData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.l2Data : [];
        const datatrustData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.datatrust : [];
        const smllearningData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.smllearning : [];
        // FD: 10912
        // const i360Data = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.i360 : [];
        // const throtleData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.throtle : [];
        // const iqviaData = targetType === "CONSUMER" || targetType === "BUSINESS" ? audienceDesignFormik.values.segment_design.iqvia : [];
        const salesintelData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.salesintel : [];
        const salesinteltechnoData = targetType === "CONSUMER" ? audienceDesignFormik.values.segment_design.salesinteltechno : [];



        let fileData = {
            consumer: consumer,
            politicalAffiliations: politicalAffiliations,
            sportsAttendees: sportsAttendees,
            personalFinance: personalFinanceData,
            homeOwner: homeOwnerData,
            titleMatching:titleMatchingData,
            specialtySegments: specialtyData,
            specialtySegmentsBusiness: businessSpecialtyData,
            travelSegments: travelData,
            merge_type: mergeType,
            target_type: targetType,
            primaryData: primaryData,
            acxiomLifestyle:acxiomLifestyleData,
            analyticsIQ:analyticsIQData,
            hcpspecialtySegmentsBusiness:hcpspecialtySegmentsData,
            // healthWise:healthWiseData,
            // FD: 10912
            // i360:i360Data,
            // iqvia:iqviaData,
            knowWhoConsumer:knowWhoConsumerData,
            l2Data:l2DataData,
            datatrust:datatrustData,
            smllearning:smllearningData,
            purpleLab:purpleLabData,
            purpleLabHCP:purpleLabHCPData,
            purpleLabHPD:purpleLabHPDData,
            salesintel:salesintelData,
            salesinteltechno:salesinteltechnoData,
            spectrumMedia: spectrumMediaData,
            qwarry: qwarryData,
            // throtle:throtleData,
        }
        return stripEmptyValuesFromObject(fileData);
    };

    const buildPrimaryData = (targetType) => {
        if (targetType === "BUSINESS") {
            return buildBusinessSegments();
        } else {
            return buildPeopleSegments();
        }
    };

    const buildPeopleSegments = () => {
        return {
            age_range: audienceDesignFormik.values.segment_design.primaryData.age_range,
            income_cd: audienceDesignFormik.values.segment_design.primaryData.income_cd,
            gender: audienceDesignFormik.values.segment_design.primaryData.gender,
            sem_ethnic_cd: audienceDesignFormik.values.segment_design.primaryData.sem_ethnic_cd,
            sem_ethnic_region_cd: audienceDesignFormik.values.segment_design.primaryData.sem_ethnic_region_cd,
            reg_party_cd: audienceDesignFormik.values.segment_design.primaryData.reg_party_cd,
            districts: audienceDesignFormik.values.segment_design.primaryData.districts,
            digital_activity_index: audienceDesignFormik.values.segment_design.primaryData.digital_activity_index,
            inflation_sensitivity_index: audienceDesignFormik.values.segment_design.primaryData.inflation_sensitivity_index,
            social_matrix: audienceDesignFormik.values.segment_design.primaryData.social_matrix,
            home_value_cd: audienceDesignFormik.values.segment_design.primaryData.home_value_cd,
            homeowner_flg: audienceDesignFormik.values.segment_design.primaryData.homeowner_flg,
            zip3: audienceDesignFormik.values.segment_design.primaryData.zip3,
            zip5: audienceDesignFormik.values.segment_design.primaryData.zip5,
            state: audienceDesignFormik.values.segment_design.primaryData.state,
            city: audienceDesignFormik.values.segment_design.primaryData.city
        }
    };

    const buildBusinessSegments = () => {
        return {
            revenue_cd: audienceDesignFormik.values.segment_design.primaryData.revenue_cd,
            employees_total_cd: audienceDesignFormik.values.segment_design.primaryData.employees_total_cd,
            naics: audienceDesignFormik.values.segment_design.primaryData.naics,
            zip3: audienceDesignFormik.values.segment_design.primaryData.zip3,
            zip5: audienceDesignFormik.values.segment_design.primaryData.zip5,
            state: audienceDesignFormik.values.segment_design.primaryData.state,
            city: audienceDesignFormik.values.segment_design.primaryData.city
        }
    };

    /**
     * removes: nulls, empty strings, empty objects, and empty arrays from obj
     * this is a recursive function if obj[propName] is an object this will
     * @param obj {Object} any object
     * @returns {Object} object with empty values removed
     */
    const stripEmptyValuesFromObject = (obj) => {
        for (let propName in obj) {
            if ($.isPlainObject(obj[propName])) {
                obj[propName] = stripEmptyValuesFromObject(obj[propName])
            }
            if (obj[propName] === null || obj[propName] === '' || $.isEmptyObject(obj[propName]) || ($.isArray(obj[propName]) && !obj[propName].length)) {
                delete obj[propName];
            }
        }
        return obj;
    };

    // const fetchData = async () => {
    //     const allReq = [];
    //     for (const key in audienceDesignConfig) {
    //         allReq.push(
    //             getPpkSegments(
    //                 audienceDesignConfig[key].endPoint,
    //                 audienceDesignConfig[key].groupType
    //             )
    //         );
    //     }
    //     Promise.all(allReq).then((res) => {
    //         const allData = {};
    //         console.log(res);
    //         res.forEach((reqData) => {
    //             const {data, groupType} = reqData;
    //             allData[groupType] = data;
    //             allData[groupType].groupName = audienceDesignConfig[groupType].groupName;
    //         });
    //         setTargetingData(allData);
    //         setPageLoader(false);
    //         // setIsDataLoaded(true);
    //     });
    // };

    return (
        <>
            <div className={"audience-design-outer-div"}>
            <div className="audience-design-content">
                <AboutAudience formik={audienceDesignFormik}/>
                <div className={"audience-design-spacer"}></div>
                <div className={"design-audience-section-wrapper"}>
                <DesignAudience
                    formik={audienceDesignFormik}
                    targetType={targetType}
                    handleTargetType={handleTargetType}
                    controlsState={controlsState}
                    setMsg={setMsg}
                    msg={msg}
                    handleControlsState={handleControlsState}/>
                </div>
                <div className={"audience-design-spacer"}></div>
                <div className={"advanced-targeting-section-wrapper"}>
                <AdvancedTargeting
                    formik={audienceDesignFormik}
                    targetType={targetType}
                    handleTargetType={handleTargetType}
                    targetingData={{targetingData}}
                    controlsState={controlsState}
                    setMsg={setMsg}
                    handleControlsState={handleControlsState}/>
                </div>
                <div className={"audience-design-spacer"}></div>
                <KeywordsSites
                    formik={audienceDesignFormik}
                />
                <div className={"audience-design-submit"}>
                    <Button
                        // onClick={handleSubmit(audienceDesignFormik)}
                        onClick={(e) => {
                            handleSubmit(e, audienceDesignFormik);
                            setSubmitDisabled(true);
                        }}
                        className="submit-button" disabled={submitDisabled}>

                        Submit
                    </Button>
                </div>
            </div>
            </div>
        </>
    );
}

export default AudienceDesign;
