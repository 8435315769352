import React, {useEffect, useState} from "react";
import {Card, CardBody, CardTitle, GridLayout, GridLayoutItem} from "@progress/kendo-react-layout";
import {Label} from "@progress/kendo-react-labels";
import {RadioGroup} from "@progress/kendo-react-inputs";
import {Field, Form, FormElement, FieldWrapper} from "@progress/kendo-react-form";
import "./Targeting.css";

const Targeting = (props) => {

    const [selectedTargetType, setSelectedTargetType] = useState(props.formik.values.target_type);
    const targetTypes = [
        { label: "Consumer", value: "CONSUMER"},
        { label: "Business", value: "BUSINESS"}
    ]

    const [formik, setFormik] = useState(props.formik);

    const handleTargetType = (e) => {
        setSelectedTargetType(e.value);
        props.formik.values.target_type = e.value;
    };

    // useEffect(() => {
    //     props.formik ? setSelectedTargetType(props.formik.initialValues.target_type) :
    //     props.setSelectedTargetType(targetTypes[0].value);
    // }, []);

    return (
        <div className={"targeting-section-outer-div"}>
            <Card className={"targeting-section-card"}>
                <CardTitle>
                    <Label className="section-label">TARGETING</Label>
                </CardTitle>
                <CardBody>
                    <Form render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                        <legend className={'k-form-legend spacingLine'}></legend>
                        <div className={"container targeting-container"}>
                            <div className={"column-1"}>
                                <Form render={formRenderProps => <FormElement style={{
                                    width: "100%"
                                }}>
                                    {/*<FieldWrapper style={{marginLeft: "2rem"}}>*/}
                                    <FieldWrapper>
                                        <Label className={"field-label"} >Target Type</Label>
                                        <RadioGroup
                                            data={targetTypes}
                                            value={selectedTargetType}
                                            onChange={handleTargetType}
                                            className={"check"}
                                            layout="horizontal"
                                            style={{width:"20rem", fontSize:".9rem"}}
                                        />
                                    </FieldWrapper>
                                </FormElement>} />
                            </div>
                        </div>
                    </FormElement>} />
                </CardBody>
            </Card>
        </div>
    )
}

export default Targeting;