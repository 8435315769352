import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const semcastingamobeeConfig = {
  ...COMPANY_BASE_PROPERTIES,
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With Amobee via Semcasting",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "semcasting_amobee",

  /**
   * HTML that explains how to use the form
   */
  instructions:
  "You have selected a platform that automatically reports usage for targeting."
  +"You will be billed for data usage by the platform unless the segment is applied for suppression purposes."
  +"Suppression segments are billed by Semcasting directly based on the discounted deployed record formula."
  +"Activating this audience means you agree to this billing method.",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "semcasting_amobee",
    text: "Amobee via Semcasting",
    img: "semcasting-amobee-logo.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink:
    '<a href="https://console.turn.com/login/loginSSO.htm" target="_blank">Log in to Amobee</a>',

  layout: [
    {
      fieldType: "checkboxGroup",
      labelText: "Devices to Activate",
      fieldName: "device_type",
      required: true,
      checkboxes: [
        {
          labelText: "Amobee ID",
          fieldName: "CLIENT_ID",
          value: "CLIENT_ID",
          checked: true,
          disabled: true,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Mobile Devices",
      fieldName: "mobile_devices",
      required: false,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: false,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },
      ],
    },
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType: "radioGroup",
      labelText: "Classification Symbol",
      fieldName: "classificationSymbol",
      required: true,
      radioButtons: [
        {
          labelText: "2nd Party Segments",
          fieldName: "second_party",
          value: "1",
          checked: false,
        },
        {
          labelText: "CRM Data",
          fieldName: "crm_data",
          value: "4",
          checked: false,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Rate Type",
      fieldName: "rateType",
      required: true,
      radioButtons: [
        {
          labelText: "Flat Fee",
          fieldName: "rate_type_flat_fee",
          value: "FF",
          checked: false,
        },
        {
          labelText: "CPM",
          fieldName: "rate_type_cpm",
          value: "CPM",
          checked: false,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },
    {
      fieldType:'textField',
      fieldName:'currencyRate',
      labelText:'Currency Rate',
      required:true,
      value:'0.00',
      info:'The cost of the data contract.',
      validation:[{
        required:function(value, attr, computed){
          var val = !isNaN(value) ? parseFloat(value) : 0;
          if(computed.rateType === 'CPM'){
            return val <= 0 ? 'Currency Rate must be greater than 0.00 when Rate Type is CPM' : '';
          }
          return val < 0 ? 'Currency Rate must be greater than or equal to 0.00' : '';
        }
      },{
        positiveNumber: 'Currency Rate'
      }]
    },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelect",
          fieldName: "advertiserId",
          labelText: "Advertiser ID",
          url: "/v2/secure/attributor/activate-credentials/semcasting_amobee/advertiserId",
          placeholder: "Select an Advertiser ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          info: "The ID of the advertiser or use -1 for unassigned",
          required: true,
        },
        {
          fieldType: "searchSelect",
          fieldName: "marketId",
          labelText: "Market ID",
          url: "/v2/secure/attributor/activate-credentials/semcasting_amobee/marketId",
          placeholder: "Select a Market ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          style: "margin-bottom:60px;",
          info: "The ID of the market where you wish to create a data Contract (1516 for Semcasting). If it is an Always On data contract that needs to be available across different Amobee Markets, please enter -1",
          required: true,
        },
        {
          fieldType:'checkbox',
          fieldName: "save_credentials",
          labelText:'Save Advertiser ID and Market ID for future use',
          checked: false,
        },
      ],
    },
    {
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["marketId", "advertiserId"],
  formik: {
    initialValues: {
      campaign_range_start: "",
      campaign_range_end: "",
      rateType: "",
      classificationSymbol: "",
      save_credentials: false,
      device_type: ["CLIENT_ID"],
      advertiserId: "",
      currencyRate: "0.00",
      audience_usage:"",
      marketId:"",
      creativeTagIdDropDown: "",
      creativeTagId:"",
    },
    // We used Yup here for input format validation.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      currencyRate: Yup.number().required("Currency Rate must be greater than or equal to 0.00")
          .when("rateType", {
            is: "CPM",
            then: Yup.number()
                .positive()
                .min(
                    0.01,
                    "Currency Rate must be greater than 0.00 when Rate Type is CPM"
                ),
          }) ,
      classificationSymbol:Yup.string().required("Classification Symbol is required"),
      rateType:Yup.string().required("Rate Type is required"),
      advertiserId: Yup.string().required("Advertiser ID is required"),
      marketId: Yup.string().required("Market ID is required"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
    }),
    onSubmit: (values) => {},
  },
};
