import React, { useEffect, useState} from "react";
import "../css/ProfilePage.css"
import { Field, Form, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Label, Hint, Error } from '@progress/kendo-react-labels';
import { FormInput, FormMaskedTextBox, FormTextArea } from './form-components';
import { Input, TextArea, MaskedTextBox } from '@progress/kendo-react-inputs';
import { emailValidator, phoneValidator, firstNameValidator,
    lastNameValidator, currentPasswordValidator, newPasswordValidator } from './validators';
import {Link, Redirect, useHistory} from "react-router-dom";
// import "../../components/main-Header/sidCustomMaterial.css"
import { Slide } from "@progress/kendo-react-animation";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage, isUserLoggedIn} from "../../Utils/Common";
import {CONTACT_US_API, SUCCESS_STATUS, UPDATE_PASSWORD_API, USER_INFO_API} from "../../api/constants";
import { loadAccessibleGroups, loadUserProfile } from "../../actions/UserActions";
import LoadOverlay from "../Components/SharedComponents/LoadOverlay";

import AccountDetails from "./AccountDetails";
import AccountsDetailsSetup from "../Setup/Pages/AccountsDetailsSetup";

const ProfilePage = (props) => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [loadPage, setLoadPage] = useState(false);

    const [currPass, setCurrPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [confirmNewPass, setConfirmNewPass] = useState("");
    const [showCurrPass, setShowCurrPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfirmNewPass, setShowConfirmNewPass] = useState(false);

    const [accessibleGroups, setAccessibleGroups] = useState([]);
    const [dropdownObj, setDropdownObj] = useState({id: -1, group: ""});

    const [defaultGroupLoading, setDefaultGroupLoading] = useState(true);

    const [storedUserInfo, setStoredUserInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        defaultGroupID: 0
    })

    const [storedFirstName, setStoredFirstName] = useState("");
    const [storedLastName, setStoredLastName] = useState("");
    const [storedEmail, setStoredEmail] = useState("");
    const [founderID, setFounderID]= useState(0);
    const [founderCompany, setFounderCompany] = useState("");
    const [founderEmail, setFounderEmail] = useState("");
    const [storedDefaultGroupID, setStoredDefaultGroupID] = useState("");
    const [defaultGroupID, setDefaultGroupID] = useState([]);

    const confirmNewPasswordValidator = value => !value ? " " : newPass === confirmNewPass ? "" : "Passwords do not match";
    const history = useHistory();
    const showCurrentPassword = () => {
        setShowCurrPass(!showCurrPass);
    };
    const showNewPassword = () => {
        setShowNewPass(!showNewPass);
    };
    const showConfirmNewPassword = () => {
        setShowConfirmNewPass(!showConfirmNewPass);
    };
    const onChangeDropDown = (e) => {
        if(e.target.value.id) setDefaultGroupID(e.target.value.id)
        setDropdownObj(e.target.value);
    }

    const updateUserProfile = async () => {
        try {
            let response = await semcastAxios.patch(
                USER_INFO_API,
                {
                    email: email,
                    first_name: firstName,
                    last_name: lastName,
                    default_group_id: dropdownObj.id,
                },
                {
                    withCredentials: true,
                }
            ).then((response) => {
                if(response.data.status === SUCCESS_STATUS) {
                    setStoredFirstName(response.data.data.first_name);
                    setStoredLastName(response.data.data.last_name);
                    setStoredEmail(response.data.data.email);
                    setFounderID(response.data.data.founderID);
                    setFounderCompany(response.data.data.founderCompany);
                    setFounderEmail(response.data.data.founderEmail);
                    setStoredDefaultGroupID(response.data.data.default_group_id);
                    showSuccessToastMessage("Success! Your details have been updated");
                } else {
                    showErrorToastMessage("Error occurred: Your details have not been updated");
                }
            })
        } catch (error) {
            showErrorToastMessage("Error occurred: Your details have not been updated");
        }
    };
    // NEED TO TEST if this is correct format for request
    const updatePassword = async () => {
        try {
            let response = await semcastAxios.post(
                UPDATE_PASSWORD_API,
                {
                    current_password: currPass,
                    confirm_password: confirmNewPass,
                    password: newPass,
                },
                {
                    withCredentials: true,
                }
            );
            if(response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Success! Your password has been updated");
            }
        } catch (error) {
            if(error?.response?.status === 401){
                showErrorToastMessage("Error occurred: Password not changed.");
            } else showErrorToastMessage("Error occurred: Password not changed.")
        }
    }

    useEffect(() => {
        try {
            let dGroupID = 0;
            loadUserProfile().then((values) => {
                // Does not redirect to homepage if user
                // if(values.first_name == null || values.last_name == null || values.email == null) return props.history.push("/");
                console.log("UserProfileValues=", values);
                if(values.first_name == null || values.last_name == null || values.email == null) return <Redirect to="/" />;
                if(!firstName.length || !lastName.length || !email.length) {
                    setFirstName(values.first_name);
                    setLastName(values.last_name);
                    setEmail(values.email);
                    setDefaultGroupID(values.default_group_id);
                    setLoadPage(true);
                    dGroupID = values.default_group_id;
                    setStoredFirstName(values.first_name);
                    setStoredLastName(values.last_name);
                    setStoredEmail(values.email);
                    setFounderID(values.founder_id);
                    setFounderCompany(values.founderCompany);
                    setFounderEmail(values.founderEmail);
                    setStoredDefaultGroupID(values.default_group_id);

                };
            })
                .then(() => {
                loadAccessibleGroups().then((groups) => {

                    const groupList = [];

                    if(groups.length) {
                        groups.forEach(el => {
                            groupList.push({id: el.id, group: el.group_name});
                        })
                    };

                    setAccessibleGroups(groupList);
                    if(groupList.length) {

                        groupList.forEach((group) => {
                            if(group.id === dGroupID & dropdownObj.id === -1) {
                                setDropdownObj(group);
                                setDefaultGroupLoading(false);
                            }
                        })

                    }

                });
            })

        } catch (e) {
            return <Redirect to="/" />
        }
    }, [loadPage]);

    return (
        <React.Fragment>
        <div>
            {loadPage && <div className="user-profile-page-container">
                <div className="guided-tour-wrapper">
                <div className="section-card section-card-inner-margins update-profile">
                    <div className={"contactUsTitle fontContainer"}>
                        <label className={"field-label-large"}>UPDATE PROFILE</label>
                    </div>

                    <Form onSubmit={updateUserProfile}
                          initialValues={{firstName: firstName, lastName: lastName, email: email}}
                          render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                        <fieldset className={'k-form-fieldset  signInDialogFormFieldsDiv update-profile-fieldset'}>
                            <legend className={'k-form-legend spacingLine'}></legend>
                            <br/>
                            <div className={"nameFieldsDiv"}>
                                <div className={"field-and-label-wrapper"}>
                                    <Label className={"field-label"}>First Name</Label>
                                    <Field id={'firstName'} className={'text-field'} name={'firstName'} component={Input}
                                           validator={firstNameValidator} onChange={(e) => setFirstName(e.target.value)}/>
                                </div>
                                <br/>
                                <div className={"field-and-label-wrapper profile-page-column-2"}>
                                    <Label className={"field-label"}>Last Name</Label>
                                    <Field id={'lastName'} className={'text-field'} name={'lastName'} component={Input}
                                           validator={lastNameValidator} onChange={(e) => setLastName(e.target.value)}/>
                                </div>
                            </div>
                            <br/>
                            <div className={"nameFieldsDiv"}>
                                <div  className={"field-and-label-wrapper"}>
                                    <Label className={"field-label"}>Email</Label>
                                    <Field id={'email'} className={'text-field'} name={'email'} type={'email'} component={Input}
                                           validator={emailValidator} onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                                <br/>

                                <div className={"field-and-label-wrapper profile-page-column-2"}>
                                    <Label className={"field-label"}>Default Group</Label>
                                    <LoadOverlay active={defaultGroupLoading} height={25} width={25}>
                                    <DropDownList className={"dropdown-standard"} textField={"group"} value={dropdownObj}
                                                  onChange={onChangeDropDown} data={accessibleGroups} defaultValue={dropdownObj}/>
                                    </LoadOverlay>
                                </div>

                            </div>

                            <br/>
                            <br/>
                            <div className="k-form-buttons k-buttons-end">
                                <Button className={"button-standard button-submit"} type={'button'}
                                        onClick={updateUserProfile}
                                        disabled={!firstName.length || !lastName.length ||
                                            !email.length || emailValidator(email) ||
                                            (firstName === storedFirstName &&
                                            lastName === storedLastName &&
                                            email === storedEmail &&
                                            defaultGroupID === storedDefaultGroupID)}>
                                    Save
                                </Button>
                            </div>
                        </fieldset>
                    </FormElement>} />

                </div>

                {/*<div className="row row-2 box">*/}
                <div className="section-card section-card-inner-margins change-password">
                    <div className={"contactUsTitle fontContainer"}>
                        <label className={"field-label-large"}>CHANGE PASSWORD</label>
                    </div>

                    <Form onSubmit={updatePassword} render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                        <fieldset className={'k-form-fieldset contactUsFormFields signInDialogButtonDiv signInDialogFormFieldsDiv'}>
                            <legend className={'k-form-legend spacingLine'}></legend>
                            <br/>
                            <div className={"resetPasswordDiv"}>
                                <div className={"field-and-label-wrapper"}>
                                    <Label className={"field-label"}>Current Password</Label>
                                    <div className={"resetPasswordField"}>
                                        <Field id={'currentPassword'} className={'text-field'} name={'currentPassword'} component={Input}
                                               hint={'Hint: Enter your text here'} type={showCurrPass ? "text" : "password"} validator={currentPasswordValidator} onChange={(e) => setCurrPass(e.target.value)}/>
                                        <Button type={"button"} className="buttons-container-button showHidePassword" icon="eye" onClick={showCurrentPassword} disabled={!currPass.length}></Button>
                                    </div>
                                </div>
                                <br/>
                                <div className={"field-and-label-wrapper"}>
                                    <Label className={"field-label"}>New Password</Label>
                                    <div className={"resetPasswordField"}>
                                        <Field id={'newPassword'} className={'text-field'} name={'newPassword'} component={FormInput}
                                               type={showNewPass ? "text" : "password"} validator={newPasswordValidator} hint={"Password must contain at least 10 characters, 1 uppercase letter, 1 number, and 1 special character"} onChange={(e) => setNewPass(e.target.value)}/>
                                        <Button type={"button"} className="buttons-container-button showHidePassword" icon="eye" onClick={showNewPassword} disabled={!newPass.length}></Button>
                                    </div>
                                </div>
                                <br/>
                                <div className={"field-and-label-wrapper"}>
                                    <Label className={"field-label"}>Confirm Password</Label>
                                    <div className={"resetPasswordField"}>
                                        <Field id={'confirmNewPassword'} className={'text-field'} name={'confirmNewPassword'} component={FormInput}
                                               type={showConfirmNewPass ? "text" : "password"} validator={confirmNewPasswordValidator} onChange={(e) => setConfirmNewPass(e.target.value)}/>
                                        <Button type={"button"} className="buttons-container-button showHidePassword" icon="eye" onClick={showConfirmNewPassword} disabled={!confirmNewPass.length}></Button>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <div className="k-form-buttons k-buttons-end">
                                <Button className={"button-standard button-submit"} themeColor={'primary'} type={'submit'}
                                        disabled={!formRenderProps.allowSubmit || !currPass.length || !newPass.length ||
                                            !confirmNewPass.length}>
                                    Save
                                </Button>

                            </div>
                        </fieldset>
                    </FormElement>} />

                </div>
                </div>
                <div className="section-card section-card-inner-margins admin-profile-account">
                    <label className={"field-label-large-center"}>ACCOUNT</label>
                    <legend className={'k-form-legend spacingLine'}></legend>
                    <AccountsDetailsSetup
                        id={founderID}
                        refreshTable={() => {
                        }}
                        setShowEditCreate={false}
                        isAccountCreate={false}
                        setOverlayActive={() => {
                        }}
                        isEditable={false}
                    />
                </div>
            </div>}
        </div>
        </React.Fragment>
    );
};

export default ProfilePage;