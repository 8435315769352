import {semcastAxios} from "../Utils/Common";
import {ACCOUNT_DETAILS_API, FOUNDER_DETAILS_API, ID_PLACE_HOLDER, SUCCESS_STATUS} from "../api/constants";

export const loadAccountsData = async () => {
    let response = await semcastAxios.get(ACCOUNT_DETAILS_API, {withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.data;
        } catch (e) {
            return null
        }
    }
}
export const loadFounderAccountsData = async (id) => {
    let path = FOUNDER_DETAILS_API.replace(ID_PLACE_HOLDER, id);
    console.info("loadFounderAccountsDataPath=", path);
    let response = await semcastAxios.get(path, {withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.data;
        } catch (e) {
            return null
        }
    }
}
