import React, {useEffect, useState} from "react";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import { ListView } from "@progress/kendo-react-listview";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {
    DELETE_TRACKING_PIXELS,
    SEGMENT_DELETE_API,
    SEGMENT_ID_PLACE_HOLDER,
    SUCCESS_STATUS
} from "../../../api/constants";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import {DELETE_MODE_MULTI, DELETE_MODE_SINGLE} from "../../../api/constants";
import { Button } from "@progress/kendo-react-buttons";
import "./DeleteSegments.css";

const TreeDeleteSegments = (props) => {
    const deleteMode = props.deleteMode;
    const delete_segments = props.deleteSegments.profileFormik.values.selectedSegments;
    const singleSegmentName = props.segName;
    const singleSegmentId = props.segId;
    const selectedType = props.segType;
    console.log(delete_segments)
    const [visible, setVisible] = useState(props.visible);

    const [labelText, setLabelText] = useState("");

    const [pageLoader, setPageLoader] = useState(false);

    const [segmentNames, setSegmentNames] = useState([]);

    const [segmentIds, setSegmentIds] = useState([]);
    const [pixelNames, setPixelNames] = useState([]);

    const [pixelIds, setPixelIds] = useState([]);
    const type = [];
    delete_segments.forEach((item)=>{
        type.push(item.objectType)
    })
    function checkIfThereIsOnlyOneElement(arr) {
        arr.sort();
        return arr[0] === arr[arr.length -1];
    }

    useEffect(() => {
        let names = [];
        let ids = [];
        let pixelnames = [];
        let pixelids = [];

        console.log(type)
        //Delete if selected is/are segments
        if (deleteMode.deleteMode === DELETE_MODE_SINGLE && selectedType !== "Site-Visitor-Tag") {
            setLabelText("Are you sure you want to delete this segment?");
            names.push(singleSegmentName);
            ids.push(singleSegmentId);

            setSegmentNames(names);
            setSegmentIds(ids);
        } else if (deleteMode.deleteMode === DELETE_MODE_MULTI && selectedType !== "Site-Visitor-Tag" && checkIfThereIsOnlyOneElement(type)) {
            if (delete_segments.length > 1) {
                setLabelText("Are you sure you want to delete these segments?");
            } else {
                setLabelText("Are you sure you want to delete this segment?");
            }

            delete_segments.forEach((item, i) => {
                names.push(item.objectName);
                ids.push(item.id);
            });
            setSegmentNames(names);
            setSegmentIds(ids);
        }

        //Delete if selected is/are tracking-pixels
        if (deleteMode.deleteMode === DELETE_MODE_SINGLE && selectedType === "Site-Visitor-Tag") {
            setLabelText("Are you sure you want to delete this Site-Visitor-Tag?");
            pixelnames.push(props.objectName);
            pixelids.push(props.id);
            setPixelNames(pixelnames);
            setPixelIds(pixelids);
        } else if (deleteMode.deleteMode === DELETE_MODE_MULTI && selectedType === "Site-Visitor-Tag" && checkIfThereIsOnlyOneElement(type)) {
            if (delete_segments.length > 1) {
                setLabelText("Are you sure you want to delete these Site-Visitor-Tags?");
            } else {
                setLabelText("Are you sure you want to delete this Site-Visitor-Tag?");
            }
            delete_segments.forEach((item, i) => {
                pixelnames.push(item.objectName);
                pixelids.push(item.id);
            });
            setPixelNames(pixelnames);
            setPixelIds(pixelids);
        }
        //Delete if selected is/are IDX-pixels
        if (deleteMode.deleteMode === DELETE_MODE_SINGLE && selectedType === "IDX-Tag") {
            setLabelText("Are you sure you want to delete this IDX-Tag?");
            pixelnames.push(props.objectName);
            pixelids.push(props.id);
            setPixelNames(pixelnames);
            setPixelIds(pixelids);
        } else if (deleteMode.deleteMode === DELETE_MODE_MULTI && selectedType === "IDX-Tag" && checkIfThereIsOnlyOneElement(type)) {
            if (delete_segments.length > 1) {
                setLabelText("Are you sure you want to delete these IDX-Tags?");
            } else {
                setLabelText("Are you sure you want to delete this IDX-Tag?");
            }
            delete_segments.forEach((item, i) => {
                pixelnames.push(item.objectName);
                pixelids.push(item.id);
            });
            setPixelNames(pixelnames);
            setPixelIds(pixelids);
        }
        if(deleteMode.deleteMode === DELETE_MODE_MULTI && !checkIfThereIsOnlyOneElement(type)){
            setLabelText("Are you sure you want to delete these items?");
            delete_segments.forEach((item) => {
                if(item.objectType === 'Site-Visitor-Tag' || item.objectType === 'IDX-Tag'){
                    pixelnames.push(item.objectName);
                    pixelids.push(item.id);
                } else{
                    names.push(item.objectName);
                    ids.push(item.id);
                }
            })
            setPixelNames(pixelnames);
            setPixelIds(pixelids);
            setSegmentNames(names);
            setSegmentIds(ids);
        }
    }, []);

    const Segment = props => {
        console.log(props)
        let item = props.dataItem;
        return (
            <div className="k-listview-item"
                // style={{padding:10, fontSize: "1.1rem"}}
                 style={{padding:5, fontSize: "14px"}}
            >
                <i className="fa fa-circle"/> &nbsp; &nbsp; {item}
            </div>
        )
    };
    const Tag = props => {
        console.log(props)
        let item = props.dataItem;
        return (
            <div className="k-listview-item"
                // style={{padding:10, fontSize: "1.1rem"}}
                 style={{padding:5, fontSize: "14px"}}
            >
                <i className="fa fa-circle"/> &nbsp; &nbsp; {item}
            </div>
        )
    };

    const deleteSegments = async () => {
        setPageLoader(true);
        try {
            console.log(segmentIds)
            let response = await semcastAxios.delete(SEGMENT_DELETE_API, {data: {'semcastingNumericSegmentIds':segmentIds}, withCredentials: true});
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Segment(s) Deleted.");
                props.reloadAfterDelete();
            };
        }  catch (error) {
            showErrorToastMessage("Segment(s) Delete Failed With " + error);
        } finally {
            setPageLoader(false);
            setVisible(false);
        }
        // let response = await semcastAxios.delete(SEGMENT_DELETE_API, {data: {'semcastingNumericSegmentIds':segmentIds}, withCredentials: true});
        // if (response.data.status === SUCCESS_STATUS) {
        //     try {
        //         return response?.data?.data
        //     } catch (e) {
        //         return []
        //     }
        // }
    }

    const deleteTags = async () => {
        setPageLoader(true);
        try {
            for (const item of pixelIds) {
                console.log(item)
                let response = await semcastAxios.delete(DELETE_TRACKING_PIXELS.replace(SEGMENT_ID_PLACE_HOLDER, item), {withCredentials: true});
                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage("Tag(s) Deleted.");
                    props.reloadAfterDelete();
                };
            }
        }  catch (error) {
            showErrorToastMessage("Tag(s) Delete Failed With " + error);
            props.reloadAfterDelete();
        } finally {
            setPageLoader(false);
            setVisible(false);
        }
    }

    const conditionDelete = async () => {
        if(pixelIds.length > 0 && segmentIds.length === 0){
            await deleteTags();
        } else if (pixelIds.length === 0 && segmentIds.length > 0){
            await deleteSegments();
        } else if (pixelIds.length > 0 && segmentIds.length > 0) {
            await deleteTags();
            await deleteSegments();
        }
    }

    return (
        <>
            <div id="deleteSegments">
                {visible && (<Dialog
                    title={"Delete Segments"}
                    width={720}
                    closeIcon={false}
                    className={"dialog-standard"}
                >
                    <LoadOverlay active={pageLoader}>
                        <div style={{fontSize:"18px"}}>
                            <b>{labelText}</b>
                        </div>
                        <div
                            style={{
                                paddingTop: "0.75rem",
                                fontSize:"14px",
                                maxHeight: "20rem",
                                overflowY: "auto",
                            }}
                        >
                            {deleteMode.deleteMode === DELETE_MODE_SINGLE  && checkIfThereIsOnlyOneElement(type) && (<ListView data={segmentNames} item={Segment} style={{width: "100%", height:"100%"}}/>)}
                            {deleteMode.deleteMode === DELETE_MODE_MULTI && delete_segments.length === 1 && checkIfThereIsOnlyOneElement(type) && (<ListView data={segmentNames} item={Segment} style={{width: "100%", height:"100%"}}/>)}
                            {deleteMode.deleteMode === DELETE_MODE_MULTI && delete_segments.length === 2 && checkIfThereIsOnlyOneElement(type) && (<ListView data={segmentNames} item={Segment} style={{width: "100%", height:"100%"}}/>)}
                            {deleteMode.deleteMode === DELETE_MODE_MULTI && delete_segments.length > 2 && checkIfThereIsOnlyOneElement(type) && (<ListView data={segmentNames} item={Segment} style={{width: "100%", height:"100%"}}/>)}
                            {deleteMode.deleteMode === DELETE_MODE_SINGLE && checkIfThereIsOnlyOneElement(type) && (<ListView data={pixelNames} item={Tag} style={{width: "100%", height:"100%"}}/>)}
                            {deleteMode.deleteMode === DELETE_MODE_MULTI && delete_segments.length === 1 && checkIfThereIsOnlyOneElement(type) && (<ListView data={pixelNames} item={Tag} style={{width: "100%", height:"100%"}}/>)}
                            {deleteMode.deleteMode === DELETE_MODE_MULTI && delete_segments.length === 2 && checkIfThereIsOnlyOneElement(type) && (<ListView data={pixelNames} item={Tag} style={{width: "100%", height:"100%"}}/>)}
                            {deleteMode.deleteMode === DELETE_MODE_MULTI && delete_segments.length > 2 && checkIfThereIsOnlyOneElement(type) && (<ListView data={pixelNames} item={Tag} style={{width: "100%", height:"100%"}}/>)}

                            {deleteMode.deleteMode === DELETE_MODE_MULTI && delete_segments.length > 1 && !checkIfThereIsOnlyOneElement(type) && (<ListView data={pixelNames.concat(segmentNames)} item={Segment} style={{width: "100%", height:"100%"}}/>)}

                        </div>

                    </LoadOverlay>
                    <DialogActionsBar layout={"end"}>
                        <Button
                            className={"button-standard button-white"}
                            onClick={() => {
                                props.cancelSubscriber(undefined)
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            className={"button-standard button-red"}
                            onClick={conditionDelete}
                        >
                            Delete
                        </Button>


                        {/*<button*/}
                        {/*    // className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary dialog-cancel"*/}
                        {/*    className={""}*/}
                        {/*    onClick={() => {*/}
                        {/*        props.cancelSubscriber(undefined)*/}
                        {/*    }}>*/}
                        {/*    Cancel*/}
                        {/*</button>*/}
                        {/*<button*/}
                        {/*    // className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary dialog-save"*/}
                        {/*    className={"button-standard button-red"}*/}
                        {/*    onClick={deleteSegments}>*/}
                        {/*    Delete*/}
                        {/*</button>*/}
                    </DialogActionsBar>
                </Dialog>)}
            </div>
        </>
    );
}

export default TreeDeleteSegments;
