import React, {useCallback, useEffect, useState, useContext} from "react";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";
import {loadUserEditGroupsNRoles, loadUsers} from "../../../actions/UserActions";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import {Input} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";
import {useFormik, useFormikContext} from "formik";
import * as Yup from "yup";
import {filterBy} from "@progress/kendo-data-query";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {
    SUCCESS_STATUS,
    USER_DELETE_API,
    USER_ID_PLACE_HOLDER,
    USER_REINSTATE_API,
    USER_SUSPEND_API, USERS_BASIC_INFO_UPDATE_API, USERS_GROUPS_EDIT_PUT_API,
    PASSWORD_RESET_API, USERS_ACCOUNT_RESTORE
} from "../../../api/constants";
import {ungroupIcon} from "@progress/kendo-svg-icons";
// import {UserContext} from "../../../contexts/UserContext";
import {UserContext} from "../../../contexts/UserContext";

const valueRender = (element, value) => {
    if(value && value.role_name && value.role_name === "Not a Member") {
        return React.cloneElement(element, {
            ...element.props,
            class:`${element.props.className} not-a-member-dropdown-option`
        })
    } else {
        return React.cloneElement(element, {
            ...element.props,
            class:`${element.props.className}`
        })
    }
}

const valueRenderDefaultGroupDropdown = (element, value) => {
    if(value && value.group_name && value.group_name === "Please set a role...") {
        return React.cloneElement(element, {
            ...element.props,
            class:`${element.props.className} no-default-group-selected`
        })
    } else {
        return React.cloneElement(element, {
            ...element.props,
            class:`${element.props.className}`
        })
    }
}


function deleteElement(arr, index) {
    if (index < 0 || index >= arr.length) {
        return;
    };

    arr.splice(index, 1);
}

const EditCommandCell = (props) => {

    const {accessibleRoles, topLevelGroupId, userHelpers,
        groupRoleUserObj, setGroupRoleUserObj, openEditCreate,
        dialogMode, userObj, rolesDropdownData, setRolesDropdownData,
        state, setState, allGroupsRolesObj,setAllGroupsRolesObj, eachGroupAccessibleRoles,
        defaultGroupDropdownData, defaultGroupDropdownValue, setDefaultGroupDropdownData,
        setDefaultGroupDropdownValue} = props;
    const userGroupObj = props.dataItem;
    const groupID = userGroupObj.group_id;
    const groupName = userGroupObj.group_name;
    const [dropdownValue, setDropdownValue] = useState(allGroupsRolesObj[groupID]);

    const groupRoleDict = {};

    if(eachGroupAccessibleRoles && dialogMode === "edit") {
        eachGroupAccessibleRoles[groupID].forEach((role) => {
            groupRoleDict[role["role_id"]] = role["role_name"];
        })
    }

    const handleChange = (e) => {

        // console.log("in dropdown change, value of dropdownValue:", dropdownValue);
        // console.log("in dropdown change, value of e.target.value:", e.target.value.role_id);
        // console.log("in dropdown change, value of allGroupsRolesObj:", allGroupsRolesObj);
        // console.log("groupRoleDict:", groupRoleDict);
        // let groupRoleUserObjTemp = groupRoleUserObj;
        // groupRoleUserObjTemp[groupID] = e.target.value.role_id;
        // setGroupRoleUserObj(groupRoleUserObjTemp);
        // console.log("allGroupsRolesObj:", allGroupsRolesObj);

        let allGroupsRolesObjTemp = allGroupsRolesObj;
        if(!allGroupsRolesObjTemp[groupID]) allGroupsRolesObjTemp[groupID] = {};

        allGroupsRolesObjTemp[groupID]["role_id"] = e.target.value.role_id;
        allGroupsRolesObjTemp[groupID]["role_name"] = e.target.value.role_name;
        setAllGroupsRolesObj(allGroupsRolesObjTemp);
        setDropdownValue(allGroupsRolesObjTemp[groupID]);

        let defaultGroupDropdownDataTemp = defaultGroupDropdownData;
        let defaultGroupDropdownValueTemp = defaultGroupDropdownValue;

        // if selected role is 'Not a Member'
        if(e.target.value.role_name === "Not a Member") {
            // iterate through data array for dropdown
            for(let i = 0; i < defaultGroupDropdownDataTemp.length; i++) {
                // locate element for group
                if(defaultGroupDropdownDataTemp[i].group_ID === groupID) {
                    // delete element using splice()
                    deleteElement(defaultGroupDropdownDataTemp, i);
                    // if this group is the current selected Default Group
                    if(defaultGroupDropdownValueTemp.group_ID === groupID) {
                        // if there are other groups selected, set Default Group to the first element in defaultGroupDropdownData
                        if(defaultGroupDropdownDataTemp.length) {
                            defaultGroupDropdownValueTemp = defaultGroupDropdownDataTemp[0];
                        }
                        // if no other groups have been selected, set defaultGroupDropdownValueTemp to an empty value
                        else {
                            defaultGroupDropdownValueTemp = {group_name: "Please set a role...", group_ID: -1};
                        }
                    } else {
                        break;
                    }
                }
            }
        }
        // if selected role is anything but 'Not a Member'
        else {
            // iterate through data array for dropdown
            for(let i = 0; i < defaultGroupDropdownDataTemp.length; i++) {
                // locate element already in array from group
                if(defaultGroupDropdownDataTemp[i].group_ID === groupID) {
                    // delete element, break out of for loop
                    deleteElement(defaultGroupDropdownDataTemp, i);
                    break;
                }
            }
            // add group/selected role to data array for dropdown
            defaultGroupDropdownDataTemp.push({
                group_name: groupName,
                group_ID: groupID,
                role_ID: e.target.value.role_id
            })
            // if currently selected Default Group is not the same as group having role selected for
            if(defaultGroupDropdownValueTemp.group_ID !== groupID) {
                // if newly selected group is only selected group
                if(defaultGroupDropdownDataTemp.length === 1) {
                    // set selected Default Group
                    defaultGroupDropdownValueTemp = {
                        group_name: groupName,
                        group_ID: groupID,
                        role_ID: e.target.value.role_id
                    }
                }
            }
            // if currently selected Default Group is same as group having role selected for
            else {
                // set selected Default Group
                defaultGroupDropdownValueTemp = {
                    group_name: groupName,
                    group_ID: groupID,
                    role_ID: e.target.value.role_id
                }
            }
        }

        setDefaultGroupDropdownData(defaultGroupDropdownDataTemp);
        setDefaultGroupDropdownValue(defaultGroupDropdownValueTemp);

    }

    return (
        <td>
            <DropDownList
                className={"permissions-edit-user-roles-dropdown"}
                // data={rolesDropdownData}
                data={eachGroupAccessibleRoles[groupID]}
                textField={"role_name"}
                dataItemKey={"role_id"}
                // value={state.value}
                // value={allGroupsRolesObj[groupID]}
                value={dropdownValue}
                onChange={handleChange}
                valueRender={valueRender}
            >

            </DropDownList>

            {/*<Button*/}
            {/*    className="button-standard button-submit button-no-left-margin"*/}
            {/*    // onClick={() => props.enterEdit(props.dataItem)}*/}
            {/*    iconClass="icon-edit"*/}
            {/*    onClick={(e) => {*/}
            {/*        // console.log("edit button event:", e);*/}
            {/*        console.log("props.dataItem", props.dataItem);*/}

            {/*        props.openEdit(props.dataItem);*/}
            {/*    }}*/}
            {/*>*/}
            {/*    Edit*/}
            {/*</Button>*/}
        </td>
    );
};

const PermissionsEditUser = (props) => {

    const [canDeleteUser, setCanDeleteUser] = useState(true);
    const [canRestoreUser, setCanRestoreUser] = useState(false);
    const canEditUser = true;

    const {closeEdit, dialogMode, userGroupsEditFormik, userObj, setUserObj,
        accessibleRoles, topLevelGroupId, userHelpers, users, setUsers, refreshTable,
        openEditCreate, groupNRolesNewUser} = props;

    const [overlayActive, setOverlayActive] = useState(false);
    const [outerOverlayActive, setOuterOverlayActive] = useState(false);

    const [dataGridGroupsUserArr, setDataGridGroupsUserArr] = useState([]);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [showRestoreConfirmationDialog, setShowRestoreConfirmationDialog] = useState(false);

    // used to store selected value of all dropdowns for this user, used in generation of Group/Role update request
    const [allGroupsRolesObj, setAllGroupsRolesObj] = useState({});

    // used to store every group's accessible roles
    const [eachGroupAccessibleRoles, setEachGroupAccessibleRoles] = useState({});

    const [roleIDNameObj, setRoleIDNameObj] = useState({});

    const {user, setUser} = useContext(UserContext);
    const [userLoggedIn, setUserLoggedIn] = useState(user);

    const [editingUserId, setEditingUserId] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const [emailErrorMsg, setEmailErrorMsg] = useState("");

    const [defaultGroupDropdownValue, setDefaultGroupDropdownValue] = useState({group_name: "Please set a role...", group_ID: -1});
    const [defaultGroupDropdownData, setDefaultGroupDropdownData] = useState([]);

    const handleChangeDefaultGroupDropdown = (e) => {
        console.log("default group dropdown onChange, e.target.value:", e.target.value);

        setDefaultGroupDropdownValue(e.target.value);
    }

    function isValidEmail(email) {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return regex.test(email);
    }

    const handleEmailBlur = () => {
        if (!isValidEmail(email)) {
            // handle invalid email format case here
            console.log('Invalid email format');
            setEmailErrorMsg("Invalid Email Address")
        } else {
            console.log('Valid email format');
            // handle valid email format case here
            setEmailErrorMsg("");
        }
    };

    const userEditFormik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
        },
        // We used Yup here.
        validationSchema: Yup.object().shape({
            first_name: Yup.string().required('First Name is required'),
            last_name: Yup.string().required('Last Name is required'),
            email: Yup.string()
                .email("Invalid email address")
                .required("Please enter an email address")
        }),
        enableReinitialize: true,
        onSubmit: values => {
        },
    })

    const loadEditUserData = async (user) => {

        try {
            if (user && dialogMode === "edit") {
                setOverlayActive(true);

                //pre-populate the user groups roles in edit form
                let groupNRolesIds = await loadUserEditGroupsNRoles(user.id);

                console.log("groupNRolesIds:", groupNRolesIds);

                if (groupNRolesIds) {
                    try {
                        // allGroupsRolesObj used to store selected value of all dropdowns for this user
                        let allGroupsRolesObjTemp = allGroupsRolesObj;

                        // stores an object that has role IDs as keys and role names as values
                        let roleIDNameObjTemp = roleIDNameObj;

                        // builds roleIDNameObjTemp
                        accessibleRoles.forEach((role) => {
                            roleIDNameObjTemp[role.id] = role.role_name;
                        });

                        setRoleIDNameObj(roleIDNameObjTemp);

                        // Object with group IDs as keys, arrays of available roles for each group as values
                        let eachGroupAccessibleRolesTemp = eachGroupAccessibleRoles;

                        let groupIdRoleIdDict = groupNRolesIds.reduce((a, x) => ({...a, [x.group_id]: x}), {})

                        let groupNRoles = [...userGroupsEditFormik.values].map((obj) => {

                            allGroupsRolesObjTemp[obj.group_id] = {
                                role_id: groupIdRoleIdDict[obj.group_id] ? groupIdRoleIdDict[obj.group_id]["role_id"] : "",
                                role_name: groupIdRoleIdDict[obj.group_id] ? roleIDNameObjTemp[groupIdRoleIdDict[obj.group_id]["role_id"]] : "Not a Member"
                            }

                            let rolesDropdownDataTemp = [{
                                role_id: "",
                                role_name: "Not a Member",
                                className: "not-a-member-dropdown-option"
                            }];

                            accessibleRoles.forEach((item) => {
                                let isPickerForTopLevelGroup = (topLevelGroupId === obj.group_id)
                                let addOption = (userHelpers.isAdministrator(item) && isPickerForTopLevelGroup)
                                    || userHelpers.isDefaultRole(item)
                                    || userHelpers.roleBelongsToGroup(item, obj.group_id)

                                if(addOption) rolesDropdownDataTemp.push({
                                    role_id: item.id,
                                    role_name: item.role_name
                                })
                            })

                            eachGroupAccessibleRolesTemp[obj.group_id] = rolesDropdownDataTemp;

                            return ({
                            ...obj,
                            role_id: groupIdRoleIdDict[obj.group_id]?.role_id || '',
                            // user_id: user?.id || '',
                            user_id: user.id || '',
                            founding_assignment: groupIdRoleIdDict[obj.group_id]?.founding_assignment || false
                            })
                        }
                        )

                        setEachGroupAccessibleRoles(eachGroupAccessibleRolesTemp);
                        setAllGroupsRolesObj(allGroupsRolesObjTemp);
                        await userGroupsEditFormik.setValues(groupNRoles);
                        setDataGridGroupsUserArr(groupNRoles);

                    } catch (e) {
                        console.log("error occurred while while pre populating existing groups & roles of a user in user edit " + e)
                    }
                }
            } else if (dialogMode === "new" && user) {
                setOverlayActive(true);

                let groupNRolesIds = await loadUserEditGroupsNRoles(user.id);

                let allGroupsRolesObjTemp = allGroupsRolesObj;

                // Object with group IDs as keys, arrays of available roles for each group as values
                let eachGroupAccessibleRolesTemp = eachGroupAccessibleRoles;

                [...userGroupsEditFormik.values].forEach((obj) => {

                    allGroupsRolesObjTemp[obj.group_id] = {
                        role_id: "",
                        role_name: "Not a Member"
                    }

                    let rolesDropdownDataTemp = [{
                        role_id: "",
                        role_name: "Not a Member",
                        className: "not-a-member-dropdown-option"
                    }];

                    accessibleRoles.forEach((item) => {
                        let isPickerForTopLevelGroup = (topLevelGroupId === obj.group_id)
                        let addOption = (userHelpers.isAdministrator(item) && isPickerForTopLevelGroup)
                            || userHelpers.isDefaultRole(item)
                            || userHelpers.roleBelongsToGroup(item, obj.group_id)

                        if(addOption) rolesDropdownDataTemp.push({
                            role_id: item.id,
                            role_name: item.role_name,
                            className: ""
                        })
                    })

                    eachGroupAccessibleRolesTemp[obj.group_id] = rolesDropdownDataTemp;
                })

                setAllGroupsRolesObj(allGroupsRolesObjTemp);

                setEachGroupAccessibleRoles(eachGroupAccessibleRolesTemp);
            }
        } finally {
            setOverlayActive(false);
        }
    }

    const resetPassword = async (e, user) => {

        // console.log("in resetPassword, user.id:", user.id);
        // console.log("in resetPassword, user.first_name:", user.first_name);
        // console.log("in resetPassword, user.last_name:", user.last_name);

        try {
            // setSectionLoader(true);
            setOuterOverlayActive(true);
            e.stopPropagation()
            if (user) {
                let response = await semcastAxios.patch(PASSWORD_RESET_API + user.id, {}, {
                    withCredentials: true,
                });
                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage(`${user.first_name} ${user.last_name}  Reset Password Requested.`)
                }
            }
        } catch (error) {
            console.log(error);
            showErrorToastMessage(`${user.first_name} ${user.last_name}  Reset Password Requested Fail.`)
        } finally {
            // setSectionLoader(false);
            setOuterOverlayActive(false);
            // setPageLoader(false);
        }
    }

    const suspendOrReinstateUser = async (e, user) => {
        const performingAction = user?.deactivated_at ? "Reinstated" : "Suspended";

        try {
            // setPageLoader(true)
            setOuterOverlayActive(true);
            e.stopPropagation()
            let response = await semcastAxios.patch((performingAction === "Reinstated" ? USER_REINSTATE_API : USER_SUSPEND_API).replace(USER_ID_PLACE_HOLDER, user.id), {}, {
                withCredentials: true,
            });
            if (response.data.status === SUCCESS_STATUS) {
                updateUserDataAfterOperations(response?.data?.data, user.id)
                showSuccessToastMessage(`${user.first_name} ${user.last_name}  has been ${performingAction}.`)
            }

        } catch (error) {
            // console.log(error)
            showErrorToastMessage(`${user.first_name} ${user.last_name} has not been ${performingAction}.`)
        } finally {
            // setPageLoader(false)
            setOuterOverlayActive(false);
        }
    }

    const updateUserDataAfterOperations = (data, userId) => {
        try {
            setUserObj(data);

            // update user data in user list
            let userIndex = users.findIndex(u => u.id === userId);
            users[userIndex] = data;
            setUsers(users)
        } catch (e) {
        }
    }

    useEffect(() => {

        if(dialogMode === "edit") {
            setEditingUserId(userObj.id);
            setFirstName(userObj.first_name);
            setLastName(userObj.last_name);
            setEmail(userObj.email);
            if(userObj.deleted_at !== null){
                setCanDeleteUser(false);
                setCanRestoreUser(true);
            }
            loadEditUserData(userObj).then(() => {
            });
        } else {

            setDataGridGroupsUserArr(userGroupsEditFormik.values);

            loadEditUserData(userLoggedIn).then(() => {
            });
        }

        // console.log("userEditFormik.values:", userEditFormik.values);

    }, [dialogMode]);

    const [rolesDropdownData, setRolesDropdownData] = useState([]);
    const [state, setState] = useState({
        value: {
            role_id: "",
            role_name: "Not a Member",
        }
    });


    const MyEditCommandCell = (props) => {

        return (
        <EditCommandCell {...props} topLevelGroupId={topLevelGroupId}
                         userHelpers={userHelpers} accessibleRoles={accessibleRoles}
                         // groupRoleUserObj={groupRoleUserObj} setGroupRoleUserObj={setGroupRoleUserObj}
                         openEditCreate={openEditCreate} dialogMode={dialogMode} userObj={userObj}
                         rolesDropdownData={rolesDropdownData} setRolesDropdownData={setRolesDropdownData}
                         state={state} setState={setState} allGroupsRolesObj={allGroupsRolesObj}
                         setAllGroupsRolesObj={setAllGroupsRolesObj} eachGroupAccessibleRoles={eachGroupAccessibleRoles}
                         defaultGroupDropdownData={defaultGroupDropdownData} setDefaultGroupDropdownData={setDefaultGroupDropdownData}
                         defaultGroupDropdownValue={defaultGroupDropdownValue} setDefaultGroupDropdownValue={setDefaultGroupDropdownValue}
        />
    )};


    let createUserPayload = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        group_ID: defaultGroupDropdownValue.group_ID,
        role_id: defaultGroupDropdownValue.role_ID,
    }


    // Save new user
    const createUser = async (payload) => {
        try {
            setOuterOverlayActive(true);

            let response = await semcastAxios.post(USERS_BASIC_INFO_UPDATE_API, payload, {
                withCredentials: true,
            });
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage(`${payload.first_name} ${payload.last_name}  has been created`);
                loadUsers().then(users => setUsers(users));
                setOuterOverlayActive(false);
                closeEdit();
                return response;
            }
        } catch (error) {
            if (error.response?.data?.data?.length > 0) {
                showErrorToastMessage(error.response.data.data);
            } else {
                showErrorToastMessage(`Error occurred: ${payload.first_name} ${payload.last_name} was not created`);

            }
        } finally {
            setOuterOverlayActive(false);
            // closeEdit();
        }
    }

    // Save changes to existing user

    const updateUserBasicData = async (first_name, last_name, email_addr) => {
        try {
            setOuterOverlayActive(true);
            if (true) {
                let userUpdatedDataPayload = {
                    first_name: first_name,
                    last_name: last_name,
                    email: email_addr,
                }

                let response = await semcastAxios.patch(USERS_BASIC_INFO_UPDATE_API + '/' + userObj.id, userUpdatedDataPayload, {
                    withCredentials: true,
                });
                // console.log(response)
                if (response.data.status === SUCCESS_STATUS) {

                    showSuccessToastMessage(first_name + " " + last_name + " updated.")
                    refreshTable();
                    closeEdit();
                }
            }
        } catch (error) {
            //console.log("updateUserBasicDataError=", error);
            showErrorToastMessage(first_name + " " + last_name + " not updated.")
        } finally {
            setOuterOverlayActive(false);
        }

    }

    // Save user's groups & roles

    const updateUserGroupsData = async (updateUserPayload, first_name, last_name) => {

        try {
            setOuterOverlayActive(true);

            if (true) {
                let response = await semcastAxios.put(USERS_GROUPS_EDIT_PUT_API, updateUserPayload, {
                    withCredentials: true,
                });

                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage(first_name + " " + last_name + " Groups/Roles updated.");
                    refreshTable();
                    closeEdit();
                }
            }
        } catch (error) {
            showErrorToastMessage(first_name + " " + last_name + " Groups/Roles not updated.");
        } finally {
            setOuterOverlayActive(false);
        }

    }

    // Deletion

    const deleteUser = async (e, user) => {
        try {
            e.stopPropagation()
            if (user) {
                let response = await semcastAxios.delete(USER_DELETE_API + user.id, {
                    withCredentials: true,
                });
                if (response.data.status === SUCCESS_STATUS) {
                    let userIndex = users.findIndex(u => u.id === user.id);
                    if (userIndex > -1) {
                        users.splice(userIndex, 1)
                        setUsers(users)
                    }
                    showSuccessToastMessage(`${user.first_name} ${user.last_name} has been Deleted`);

                    // need to close dialog & refresh table
                    closeEdit();
                    refreshTable();

                }
            }
        } catch (error) {
            // console.log(error)
            showErrorToastMessage(`Error occurred: ${user.first_name} ${user.last_name} not deleted.`)
        } finally {
            // setPageLoader(false)
        }
    }
    const restoreUser = async (e, user) => {
        try {
            e.stopPropagation()
            if (user) {
                // console.log(user.id)
                let response = await semcastAxios.patch(USERS_ACCOUNT_RESTORE.replace(USER_ID_PLACE_HOLDER, user.id), {
                    withCredentials: true,
                });
                if (response.data.status === SUCCESS_STATUS) {
                    // console.log(response)
                    showSuccessToastMessage(`${user.first_name} ${user.last_name} has been restored`);

                    // need to close dialog & refresh table
                    closeEdit();
                    refreshTable();

                }
            }
        } catch (error) {
            // console.log(error)
            showErrorToastMessage(`Error occurred: ${user.first_name} ${user.last_name} is not restored.` + error)
        } finally {
            // setPageLoader(false)
        }
    }

    const deleteConfirmed = (e) => {
        setShowDeleteConfirmationDialog(false);
        deleteUser(e, userObj);
    }
    const restoreConfirmed = (e) => {
        setShowRestoreConfirmationDialog(false);
        restoreUser(e, userObj);
    }

    const deleteDenied = () => {
        setShowDeleteConfirmationDialog(false);
    }
    const restoreDenied = () => {
        setShowRestoreConfirmationDialog(false);
    }

    // Return statement

    return (
        <div>
            <Dialog
                className={"dialog-standard perm-users-dialog"}
                title={dialogMode === "edit" ?
                    userObj?.deactivated_at ?
                        (<div className={"permissions-user-dialog-suspended-container"}>
                            Edit User
                            <h6 className={"header-tertiary permissions-user-dialog-suspended-text"}> Suspended </h6>
                        </div>) :
                        "Edit User" :
                    "Create New User"}
                closeIcon={false}
                width={750}
            >
                <LoadOverlay active={outerOverlayActive} height={50} width={50}>


                {showDeleteConfirmationDialog && (

                    <div className={"admin-users-delete-dialog-container"}>
                        <Dialog title={"Delete User"} onClose={deleteDenied} className={"admin-users-delete-dialog dialog-standard"}>
                            <p className={"admin-users-delete-dialog-message-text"}>Are you sure you want to delete user <strong>{userObj.first_name} {userObj.last_name}</strong>?</p>

                            <DialogActionsBar>
                                <Button className="button-standard button-white" onClick={deleteDenied}>No</Button>
                                <Button className="button-standard button-submit" onClick={deleteConfirmed}>Yes</Button>
                            </DialogActionsBar>
                        </Dialog>
                    </div>

                )}
                    {showRestoreConfirmationDialog && (

                        <div className={"admin-users-delete-dialog-container"}>
                            <Dialog title={"Restore User"} onClose={restoreDenied} className={"admin-users-delete-dialog dialog-standard"}>
                                <p className={"admin-users-delete-dialog-message-text"}>Are you sure you want to restore user <strong>{userObj.first_name} {userObj.last_name}</strong>?</p>

                                <DialogActionsBar>
                                    <Button className="button-standard button-white" onClick={restoreDenied}>No</Button>
                                    <Button className="button-standard button-submit" onClick={restoreConfirmed}>Yes</Button>
                                </DialogActionsBar>
                            </Dialog>
                        </div>

                    )}

                <div className={"perm-users-form-container"}>
                    <div className={"perm-users-form-top-section"}>
                        <div className={"perm-users-name-fields"}>

                            <div className={"perm-users-first-name perm-users-form-field"}>
                                <Label
                                    className="field-label"
                                >
                                    First Name
                                </Label>
                                <Input
                                    name="first_name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    onBlur={userEditFormik.handleBlur}
                                    className={"text-field"}
                                >
                                </Input>
                                {!firstName.length && userEditFormik.touched.first_name && userEditFormik.errors.first_name && (
                                    <span style={{color: "red"}}>{userEditFormik.errors.first_name}</span>
                                )}
                            </div>

                            <div className={"perm-users-last-name perm-users-form-field"}>
                                <Label
                                    className="field-label"
                                >
                                    Last Name
                                </Label>
                                <Input
                                    name="last_name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    onBlur={userEditFormik.handleBlur}
                                    className={"text-field"}
                                >
                                </Input>
                                {!lastName.length && userEditFormik.touched.last_name && userEditFormik.errors.last_name && (
                                    <span style={{color: "red"}}>{userEditFormik.errors.last_name}</span>
                                )}

                            </div>
                        </div>
                        <div className={"perm-users-email-field perm-users-form-field"}>
                            <Label
                                className="field-label"
                            >
                                Email
                            </Label>
                            <Input
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={handleEmailBlur}
                                className={"text-field"}
                            >
                            </Input>

                            {(!email.length || emailErrorMsg) && (
                                <span style={{color: "red"}}>{emailErrorMsg}</span>
                            )}

                        </div>

                        <br/>

                    </div>

                    <LoadOverlay active={overlayActive} height={50} width={50}>

                    <div className={"perm-users-form-table-section"}>
                        <Grid
                            data={dataGridGroupsUserArr}
                        >
                            <Column field="group_name" title="Group" />
                            <Column cell={MyEditCommandCell} filterable={false}  title="Role"/>

                        </Grid>
                    </div>


                    <div hidden={dialogMode === "edit"} className={"permissions-new-user-default-group-dropdown-container"}>
                        <Label
                            className="field-label"
                        >
                            Default Group
                        </Label>

                        <DropDownList
                            className={"permissions-new-user-default-group-dropdown"}
                            data={defaultGroupDropdownData}
                            textField={"group_name"}
                            dataItemKey={"group_ID"}
                            value={defaultGroupDropdownValue}
                            onChange={handleChangeDefaultGroupDropdown}
                            valueRender={valueRenderDefaultGroupDropdown}
                        >
                        </DropDownList>
                    </div>
                    </LoadOverlay>
                </div>

                <DialogActionsBar>

                    <div className={"perm-users-dialog-buttons-container"}>
                        <div className={"perm-dialog-buttons-left"}>
                        {canDeleteUser &&
                        <Button
                            className={"button-standard button-red perm-dialog-delete-button"}
                            type={'button'}
                            disabled={dialogMode === "new"}
                            onClick={(e) => {
                                setShowDeleteConfirmationDialog(true);
                            }}
                        >
                            Delete User
                        </Button>}
                            {canRestoreUser &&
                                <Button
                                    className={"button-standard button-submit"}
                                    type={'button'}
                                    disabled={dialogMode === "new"}
                                    onClick={(e) => {
                                        setShowRestoreConfirmationDialog(true);
                                    }}
                                >
                                    Restore User
                                </Button>}
                        </div>
                        <div className={"perm-dialog-buttons-right"}>
                        {canEditUser &&
                        <Button
                            className={"button-standard button-white perm-user-suspend-user-button"}
                            type={'button'}
                            disabled={dialogMode === "new"}
                            onClick={(e) => {
                                suspendOrReinstateUser(e, userObj);
                             }}
                        >
                            {userObj?.deactivated_at ? "Reinstate User" : "Suspend User"}
                            {/*Suspend User*/}
                        </Button>}
                        {canEditUser &&
                            <Button
                                className={"button-standard button-white perm-user-reset-pass-button"}
                                type={'button'}
                                disabled={dialogMode === "new"}
                                onClick={(e) => {
                                    resetPassword(e, userObj);
                                }}
                                // onClick={async (e) => {
                                //     suspendOrReinstateUser(e, userObj);
                                //     e.preventDefault();
                                //     console.log(user);
                                //     let createUserPayload = {
                                //         first_name: firstName,
                                //         last_name: lastName,
                                //         email: email,
                                //         group_id: `${defaultGroupDropdownValue.group_ID}`,
                                //         role_id: `${defaultGroupDropdownValue.role_ID}`,
                                //     }
                                //     console.log(createUserPayload);
                                //     // await handleResetPassword(e, userEditFormik.values);
                                //     // console.log("userEditFormik.values:", userEditFormik.values);
                                // }}
                            >
                                Reset Password
                            </Button>}
                        <Button
                            className={"button-standard button-white"}
                            type={'button'}
                            disabled={false}
                            onClick={() => {
                                closeEdit();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={"button-standard button-submit"}
                            type={'button'}
                            disabled={!firstName.length || !lastName.length || !email.length || !isValidEmail(email) || (dialogMode === 'new' && !defaultGroupDropdownData.length)}
                            onClick={async () => {

                                if(dialogMode === 'edit') {
                                    updateUserBasicData(firstName, lastName, email).then((res) => {
                                        // console.log(res)
                                        let assignments = [];
                                        Object.keys(allGroupsRolesObj).forEach((groupID) => {
                                            let groupRoleObjTemp = {};
                                            groupRoleObjTemp["group_id"] = groupID;
                                            groupRoleObjTemp["role_id"] = allGroupsRolesObj[groupID]["role_id"];
                                            groupRoleObjTemp["user_id"] = userObj.id;
                                            assignments.push(groupRoleObjTemp);
                                        })

                                        let updateUserPayload = {
                                            assignments: assignments,
                                        }
                                        updateUserGroupsData(updateUserPayload, firstName, lastName).then((res) => {
                                            // console.log("response from updateUserGroupsData:", res);
                                        })

                                    })
                                } else {

                                    let createUserPayload = {
                                        first_name: firstName,
                                        last_name: lastName,
                                        email: email,
                                        group_id: `${defaultGroupDropdownValue.group_ID}`,
                                        role_id: `${defaultGroupDropdownValue.role_ID}`,
                                    }

                                    let assignments = [];

                                    createUser(createUserPayload).then((res) => {
                                        console.log("responseFromCreateUser:", res);
                                        if (res?.data?.data?.user?.id) {
                                            Object.keys(allGroupsRolesObj).forEach((groupID) => {
                                                let groupRoleObjTemp = {};
                                                groupRoleObjTemp["group_id"] = groupID;
                                                groupRoleObjTemp["role_id"] = allGroupsRolesObj[groupID]["role_id"];
                                                groupRoleObjTemp["user_id"] = res["data"]["data"]["user"]["id"];
                                                assignments.push(groupRoleObjTemp);
                                            })

                                            let updateUserPayload = {
                                                assignments: assignments,
                                            }

                                            let first_name = res["data"]["data"]["user"]["first_name"];
                                            let last_name = res["data"]["data"]["user"]["last_name"];

                                            updateUserGroupsData(updateUserPayload, first_name, last_name).then((res) => {
                                                console.log("responseFromUpdateUserData:", res);
                                            })
                                            refreshTable();
                                            closeEdit();
                                            setOuterOverlayActive(false);
                                        }
                                    });
                                }
                            }}
                        >
                            Save
                        </Button>
                        </div>
                    </div>
                </DialogActionsBar>
                </LoadOverlay>
            </Dialog>
        </div>
    )
}

export default PermissionsEditUser;