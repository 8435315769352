import 'shepherd.js/dist/css/shepherd.css';
import {semcastAxios} from "../../Utils/Common";
import {USER_INFO_API} from "../../api/constants";


function updateUserTourValue() {

    semcastAxios.patch(
        USER_INFO_API,
        {
            initial_guided_tour_completed: 1,
        },
        {
            withCredentials: true,
        }
    ).then((response) => {
        console.log(response.data.data);
        sessionStorage.setItem('guided_tour', Boolean(response.data.data.initial_guided_tour_completed));
    }).catch((error)=>{console.log(error)});
}
const standardButtons = [
    {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel'
    },
    {
        classes: 'shepherd-button-primary',
        text: 'Back',
        type: 'back'
    },
    {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next'
    }
];
const finalStepButtons = [
    {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel'
    },
    {
        classes: 'shepherd-button-primary',
        text: 'Back',
        type: 'back'
    },
    {
        classes: 'shepherd-button-primary',
        text: 'Done',
        type: 'cancel'
    }
];
 export const dashboardTourSteps = [
        {
            id: 'intro-dashboard',
            attachTo: { element: '.first-element', on: 'bottom' },
            beforeShowPromise: function () {
                return new Promise(function (resolve) {
                    setTimeout(function () {

                        window.scrollTo(0, 0);
                        resolve();
                    }, 0);
                });
            },
            buttons: standardButtons,
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: true,
            cancelIcon: {
                enabled: true,
            },
            title: '&nbsp;Welcome to AudienceDesigner!&nbsp;&nbsp;&nbsp;[1/7]',
            text: ['Welcome to AudienceDesigner!  Click "Next" to explore the Dashboard features. To resume later, exit now and click the "Sign Post" icon at the bottom of the navigation bar.'],
            when: {
                show: () => {

                },
                hide: () => {

                },
                cancel: function() {
                    console.log('cancel');

                },
                destroy: function() {
                    console.log('destroy');
                    if(sessionStorage.getItem("guided_tour") === 'false'){
                        console.log('here')
                        updateUserTourValue();
                    }
                },

            }
        },
        {
            id: 'step1',
            attachTo: { element: '.k-widget.k-drawer.k-drawer-start', on: 'left' },
            beforeShowPromise: function () {
                return new Promise(function (resolve) {
                    setTimeout(function () {
                        window.scrollTo(0, 0);
                        resolve();
                    }, 0);
                });
            },
            buttons: standardButtons,
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: true,
            cancelIcon: {
                enabled: true,
            },
            title: '&nbsp;Navigation Bar&nbsp;&nbsp;&nbsp;[2/7]',
            text: ['This bar redirects you to different pages. Hover over each icon to see its destination.'],
            when: {
                show: () => {

                },
                hide: () => {

                },
                cancel: function() {
                    console.log('cancel')
                },
                destroy: function() {
                    console.log('destroy');
                },
            }
        },
        {
            id: 'step2',
            attachTo: { element: '.k-appbar.k-appbar-top.k-appbar-static.k-appbar-light', on: 'top' },
            beforeShowPromise: function () {
                return new Promise(function (resolve) {
                    setTimeout(function () {
                        window.scrollTo(0, 0);
                        resolve();
                    }, 0);
                });
            },
            buttons: standardButtons,
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            scrollTo: true,
            cancelIcon: {
                enabled: true,
            },
            title: '&nbsp;Action Buttons&nbsp;&nbsp;&nbsp;[3/7]',
            text: ["Select"+" an item from the dashboard table using the checkbox to enable action buttons. Hover over each icon to see its function."],
            when: {
                show: () => {

                },
                hide: () => {

                },
                cancel: function() {
                    console.log('cancel')
                },
                destroy: function() {
                    console.log('destroy');
                },
            }
        },
     {
         id: 'step3',
         attachTo: { element: '.k-card.segmentation-dashboard-card.k-card-vertical table', on: 'top' },
         beforeShowPromise: function () {
             return new Promise(function (resolve) {
                 setTimeout(function () {
                     window.scrollTo(0, 0);
                     resolve();
                 }, 0);
             });
         },
         buttons: standardButtons,
         classes: 'custom-class-name-1 custom-class-name-2',
         highlightClass: 'highlight',
         scrollTo: true,
         cancelIcon: {
             enabled: true,
         },
         title: '&nbsp;Dashboard&nbsp;&nbsp;&nbsp;[4/7]',
         text: ['The Dashboard is a live-updated table showing all created segments and activations.'],
         when: {
             show: () => {

             },
             hide: () => {

             },
             cancel: function() {
                 console.log('cancel')
             },
             destroy: function() {
                 console.log('destroy');
             },
         }
     },
     {
         id: 'step4',
         attachTo: { element: '.k-input.k-input-md.k-rounded-md.k-input-solid', on: 'top' },
         beforeShowPromise: function () {
             return new Promise(function (resolve) {
                 setTimeout(function () {
                     window.scrollTo(0, 0);
                     resolve();
                 }, 0);
             });
         },
         buttons: standardButtons,
         classes: 'custom-class-name-1 custom-class-name-2',
         highlightClass: 'highlight',
         scrollTo: true,
         cancelIcon: {
             enabled: true,
         },
         title: '&nbsp;Filters&nbsp;&nbsp;&nbsp;[5/7]',
         text: ['Use filters to narrow down displayed items. Filter settings are saved locally until manually cleared.'],
         when: {
             show: () => {

             },
             hide: () => {

             },
             cancel: function() {
                 console.log('cancel')
             },
             destroy: function() {
                 console.log('destroy');

             },
         }
     },
     {
         id: 'step5',
         attachTo: { element: '.k-grid-header th[aria-colindex="2"]', on: 'top' },
         beforeShowPromise: function () {
             return new Promise(function (resolve) {
                 setTimeout(function () {
                     window.scrollTo(0, 0);
                     resolve();
                 }, 0);
             });
         },
         buttons: standardButtons,
         classes: 'custom-class-name-1 custom-class-name-2',
         highlightClass: 'highlight',
         scrollTo: true,
         cancelIcon: {
             enabled: true,
         },
         title: '&nbsp;Sorting&nbsp;&nbsp;&nbsp;[6/7]',
         text: ['Click on a header to sort column data.'],
         when: {
             show: () => {

             },
             hide: () => {

             },
             cancel: function() {
                 console.log('cancel')

             },
             destroy: function() {
                 console.log('destroy');
             },
         }
     },
     {
         id: 'step6',
         attachTo: { element: '.k-widget.k-drawer.k-drawer-start li:nth-child(7)', on: 'left' },
         beforeShowPromise: function () {
             return new Promise(function (resolve) {
                 setTimeout(function () {
                     window.scrollTo(0, 0);
                     resolve();
                 }, 0);
             });
         },
         buttons: finalStepButtons,
         classes: 'custom-class-name-1 custom-class-name-2',
         highlightClass: 'highlight',
         scrollTo: true,
         cancelIcon: {
             enabled: true,
         },
         title: '&nbsp;Conclusion&nbsp;&nbsp;&nbsp;[7/7]',
         text: ['This concludes the Dashboard tour. On different pages, click the icon to start a new tour.'],
         when: {
             show: () => {

             },
             hide: () => {

             },
             cancel: function() {
                 console.log('cancel')
             },
             destroy: function() {
                 console.log('destroy');
             },
         }
     },
    ];

export const onboardingTourSteps = [
    {
        id: 'intro-onboarding',
        attachTo: { element: '.first-element', on: 'bottom' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Welcome to 1st Party Data Onboarding&nbsp;&nbsp;&nbsp;[1/7]',
        text: ['Semcasting Onboarding allows you to upload your 1st party data anonymously using various identifiers like address, devices, emails, encrypted emails, NPI, company name and NAICS, domains, proprietary IDs. The platform accepts both B2B and B2C files.'],
        when: {
            show: () => {

            },
            hide: () => {

            },
        }
    },
    {
        id: 'step1',
        attachTo: { element: '.onboarding-content .k-card.k-card-vertical', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Create Segment&nbsp;&nbsp;&nbsp;[2/7]',
        text: ['Use this area to onboard your first-party segment data or create segments using our Mapping Tool. Onboarded segments can be sourced from your local network, sFTP, or S3 service.'],
        when: {
            show: () => {

            },
            hide: () => {

            },
            cancel: function() {
                console.log('cancel');

            },
            destroy: function() {
                console.log('destroy');
                // console.log(sessionStorage.getItem("guided_tour"))
                if(sessionStorage.getItem("guided_tour") === 'false'){
                    console.log('here')
                    updateUserTourValue();
                }
            },
        }
    },
    {
        id: 'step2',
        attachTo: { element: '.onboarding-content .k-card.k-card-vertical .k-tabstrip-items.k-reset', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Segment Setup Methods&nbsp;&nbsp;&nbsp;[3/7]',
        text: ['There are three methods available to set up segment data: from your computer, sFTP, or S3 bucket.'],
        when: {
            show: () => {
            },
            hide: () => {
            },
        }
    },
    // {
    //     id: 'step3',
    //     attachTo: { element: '.onboarding-content .k-card.k-card-vertical .k-tabstrip.k-floatwrap.k-tabstrip-top', on: 'top' },
    //     beforeShowPromise: function () {
    //         return new Promise(function (resolve) {
    //             setTimeout(function () {
    //                 //click the My Computer tab
    //                 const tab = document.querySelectorAll('.k-tabstrip-items .k-item')[0];
    //                 if (tab) {
    //                     tab.click();
    //                 }
    //                 window.scrollTo(0, 0);
    //                 resolve();
    //             }, 0);
    //         });
    //     },
    //     buttons: standardButtons,
    //     classes: 'custom-class-name-1 custom-class-name-2',
    //     highlightClass: 'highlight',
    //     scrollTo: true,
    //     canClickTarget: true,
    //     cancelIcon: {
    //         enabled: true,
    //     },
    //     title: '&nbsp;Create Segment&nbsp;&nbsp;&nbsp;[4/9]',
    //     text: ['Use a file from your current computer.'],
    //     when: {
    //         show: () => {
    //
    //         },
    //         hide: () => {
    //
    //         },
    //     }
    // },
    // {
    //     id: 'step4',
    //     attachTo: { element: '.onboarding-content .k-card.k-card-vertical .k-tabstrip.k-floatwrap.k-tabstrip-top', on: 'top' },
    //     beforeShowPromise: function () {
    //         return new Promise(function (resolve) {
    //             setTimeout(function () {
    //                 //click the sFTP or S3 tab
    //                 const tab = document.querySelectorAll('.k-tabstrip-items .k-item')[1];
    //                 if (tab) {
    //                     tab.click();
    //                 }
    //                 window.scrollTo(0, 0);
    //                 resolve();
    //             }, 0);
    //         });
    //     },
    //     buttons: standardButtons,
    //     classes: '',
    //     highlightClass: 'highlight',
    //     scrollTo: true,
    //     canClickTarget: true,
    //     cancelIcon: {
    //         enabled: true,
    //     },
    //     title: '&nbsp;Create Segment&nbsp;&nbsp;&nbsp;[5/9]',
    //     text: ['Use a file from your sFTP or S3 bucket.'],
    //     when: {
    //         show: () => {
    //
    //         },
    //         hide: () => {
    //
    //         },
    //     }
    // },
    {
        id: 'step5',
        attachTo: { element: '.onboarding-content .k-card.k-card-vertical .k-tabstrip.k-floatwrap.k-tabstrip-top', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    //click the Map Area tab
                    const tab = document.querySelectorAll('.k-tabstrip-items .k-item')[2];
                    if (tab) {
                        tab.click();
                    }
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Mapping Tool&nbsp;&nbsp;&nbsp;[4/7]',
        text: ['Use the Mapping Tool to draw a custom polygon or upload a list of locations with a radius to establish a trade area. Add additional filters to narrow down the target audience.'],
        when: {
            show: () => {

            },
            hide: () => {

            },
        }
    },
    {
        id: 'step6',
        attachTo: { element: '.onboarding-content .k-card.about-audience-card.k-card-vertical', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    //click the Map Area tab
                    const tab = document.querySelectorAll('.k-tabstrip-items .k-item')[0];
                    if (tab) {
                        tab.click();
                    }
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Segment Details&nbsp;&nbsp;&nbsp;[5/7]',
        text: ['Enter the segment name as you wish it to appear in the activation. Select the group where the segment will be stored for organizational purposes. To receive non-matched records, ensure you have an sFTP or S3 connection set up.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step7',
        attachTo: { element: '.onboarding-content .targeting-section-outer-div', on: 'bottom' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Targeting&nbsp;&nbsp;&nbsp;[6/7]',
        text: ['Select whether the onboarded file is a consumer or business file. If it\'s a combination, please load the file twice - once as a consumer file and once as a business file.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step8',
        attachTo: { element: '.onboarding-content .submit-button', on: 'bottom' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: finalStepButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Submit&nbsp;&nbsp;&nbsp;[7/7]',
        text: ['Once you have entered all the desired information, click submit to create this segment.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    }
];
export const audiencedesignTourSteps = [
    {
        id: 'intro-audiencedesign',
        attachTo: { element: '.first-element', on: 'bottom' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Welcome to AudienceDesigner&nbsp;&nbsp;&nbsp;[1/7]',
        text: ['Let\'s take a quick tour on how to create segment using 3rd party data'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
            cancel: function() {
                console.log('cancel');

            },
            destroy: function() {
                console.log('destroy');
                // console.log(sessionStorage.getItem("guided_tour"))
                if(sessionStorage.getItem("guided_tour") === 'false'){
                    console.log('here')
                    updateUserTourValue();
                }
            },
        }
    },
    {
        id: 'step1',
        attachTo: { element: '.audience-design-content .k-card.about-audience-card.k-card-vertical', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;About Audience&nbsp;&nbsp;&nbsp;[2/7]',
        text: ['Fill in basic information for the segment here.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step2',
        attachTo: { element: '.audience-design-content .design-audience-section-wrapper', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Design Audience&nbsp;&nbsp;&nbsp;[3/7]',
        text: ['Select your segment type and apply demographic filters to the audience.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step3',
        attachTo: { element: '.audience-design-content .advanced-targeting-section-wrapper', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Advanced Targeting&nbsp;&nbsp;&nbsp;[4/7]',
        text: ['Semcasting has developed specialized data elements and established strategic industry-specific data partners. CPM will be adjusted to the highest listed CPM value.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step4',
        attachTo: { element: '.audience-design-content .k-card.keywords-sites-card.k-card-vertical', on: 'bottom' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Keywords/Sites&nbsp;&nbsp;&nbsp;[5/7]',
        text: ['Contextual Keywords and Site Keywords are based on past trends and can be applied to future audience segments.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step5',
        attachTo: { element: '.audience-design-content .audience-design-submit', on: 'bottom' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Submit Segment&nbsp;&nbsp;&nbsp;[6/7]',
        text: ['Once you have selected all the filters, click submit to create this segment.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step6',
        attachTo: { element: '.k-widget.k-drawer.k-drawer-start li:nth-child(5) .k-item-text', on: 'left' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: finalStepButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Conclusion&nbsp;&nbsp;&nbsp;[7/7]',
        text: ['Thank you for completing this tour! If you have any questions, please feel free to visit our Help Center or contact us at adssupport@semcasting.com.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
];

export const adminsettingsTourSteps = [
    {
        id: 'intro-adminsettings',
        attachTo: { element: '.first-element', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Welcome to AudienceDesigner&nbsp;&nbsp;&nbsp;[1/8]',
        text: ['Let\'s take a quick tour of the Admin Settings area.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step1',
        attachTo: { element: '.user-profile-page-container .guided-tour-wrapper', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    //click the Profile tab
                    const tab = document.querySelectorAll('.k-tabstrip-items .k-item')[0];
                    if (tab) {
                        tab.click();
                    }
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Profile&nbsp;&nbsp;&nbsp;[2/8]',
        text: ['Here you can update your profile information and change your password.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    // {
    //     id: 'step2',
    //     attachTo: { element: '.user-profile-page-container .section-card.section-card-inner-margins.change-password', on: 'top' },
    //     beforeShowPromise: function () {
    //         return new Promise(function (resolve) {
    //             setTimeout(function () {
    //                 window.scrollTo(0, 0);
    //                 resolve();
    //             }, 0);
    //         });
    //     },
    //     buttons: standardButtons,
    //     classes: '',
    //     highlightClass: 'highlight',
    //     scrollTo: true,
    //     cancelIcon: {
    //         enabled: true,
    //     },
    //     title: '&nbsp;Change Password&nbsp;&nbsp;&nbsp;[3/9]',
    //     text: ['Change your password here.'],
    //     when: {
    //         show: () => {
    //             // console.log('show step');
    //         },
    //         hide: () => {
    //             // console.log('hide step');
    //         },
    //     }
    // },
    {
        id: 'step3',
        attachTo: { element: '.user-profile-page-container .section-card.section-card-inner-margins.admin-profile-account .user-info-section', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Account&nbsp;&nbsp;&nbsp;[3/8]',
        text: ['Under the User Info you can see your local Account Admin.  This person has the permission to add and remove users.  To change the admin please contact Semcasting.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step4',
        attachTo: { element: '.k-tabstrip.k-floatwrap.k-tabstrip-top', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    //click the Permissions tab
                    const tab = document.querySelectorAll('.k-tabstrip-items .k-item')[1];
                    if (tab) {
                        tab.click();
                    }
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Permissions&nbsp;&nbsp;&nbsp;[4/8]',
        text: ['Based on your User Role you will be able to see what access you have available.  '],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step5',
        attachTo: { element: '.k-tabstrip.k-floatwrap.k-tabstrip-top', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    //click the Connections Manager tab
                    const tab = document.querySelectorAll('.k-tabstrip-items .k-item')[2];
                    if (tab) {
                        tab.click();
                    }
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Connections Manager&nbsp;&nbsp;&nbsp;[5/8]',
        text: ['Set up your sFTP or S3 connections here to use files from these buckets to create segments or collect unmatched records during onboarding or receive Identity Reports.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step6',
        attachTo: { element: '.k-tabstrip.k-floatwrap.k-tabstrip-top', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    //click the ADX Tag Manager tab
                    const tab = document.querySelectorAll('.k-tabstrip-items .k-item')[3];
                    if (tab) {
                        tab.click();
                    }
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;ADX Tag Manager&nbsp;&nbsp;&nbsp;[6/8]',
        text: ['Create ADX Tags here to place in Google Tag Manager and track desired website traffic.  Please note there is additional charge for these tags and will be invoiced on a monthly basis.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step7',
        attachTo: { element: '.k-tabstrip.k-floatwrap.k-tabstrip-top', on: 'top' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    //click the Notification tab
                    const tab = document.querySelectorAll('.k-tabstrip-items .k-item')[4];
                    if (tab) {
                        tab.click();
                    }
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: standardButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Notifications&nbsp;&nbsp;&nbsp;[7/8]',
        text: ['This tab contains notifications that remind you of any process failures. If there are any notifications, you will see a red bubble with the number of notifications in the top right corner, in front of your name.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
        }
    },
    {
        id: 'step8',
        attachTo: { element: '.k-widget.k-drawer.k-drawer-start li:nth-child(5) .k-item-text', on: 'left' },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 0);
            });
        },
        buttons: finalStepButtons,
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        cancelIcon: {
            enabled: true,
        },
        title: '&nbsp;Conclusion&nbsp;&nbsp;&nbsp;[8/8]',
        text: ['Thank you for completing this tour! If you have any questions, please visit our Help Center or contact us at adssupport@semcasting.com.'],
        when: {
            show: () => {
                // console.log('show step');
            },
            hide: () => {
                // console.log('hide step');
            },
            cancel: function() {
                console.log('cancel');

            },
            destroy: function() {
                console.log('destroy');
                // console.log(sessionStorage.getItem("guided_tour"))
                if(sessionStorage.getItem("guided_tour") === 'false'){
                    console.log('here')
                    updateUserTourValue();
                }
            },
        }
    },
];


export const tourOptions = {
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
            classes: 'shepherd-theme-custom',
            when: {
                show() {
                }
            }
        },
        useModalOverlay: true,
    };
