import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";
import {UDX} from "../../../../Utils/UDX";

export const semcastingmeasurementConfig = {
    ...COMPANY_BASE_PROPERTIES,
    /**
     * User Readable name of company the user is onBoarding with
     */
    companyText: "Measurement With Semcasting",

    /**
     * lowercase no spaces or underscores name of company
     * This will be passed to the activate model and
     * used when saving to create the correct submission url
     */
    company: "semcastingmeasurement",
    isConsumer: null,
    isThirdPartySegment: null,
    /**
     * HTML that explains how to use the form
     */
    instructions:"",
    // "Select required fields from Location Properties followed by any listed platform identifiers, email types, devices etc. For every selected Platform ID a sample of up to 5 are provided. Consumer Segments created or edited from Audience Design will not be eligible for any PII options.",
    /**
     * data used to create a company activation block
     */
    companyBlockOptions: {
        val: 'semcastingmeasurement',
        text: 'Semcasting-Measurement',
        img: 'semcasting-measurement-logo-00a9c5.svg'
    },

    /**
     * anchor tag used to login to a specific platform
     */
    loginLink:
        '',

    layout: [
        {
            fieldType: 'row',
            layout: [
                {
                    fieldType: 'column',
                    columnClass: 'content-wrapper',
                    layout: [{
                        fieldType: 'semcastingMeasurementTreeComponent',
                        labelText: 'Properties',
                        fieldName: 'location_properties',
                        required: true,
                        data: [
                            {
                                id: 1,
                                labelText: "Demographic",
                                value: 'demographic',
                                children: [
                                    {
                                        labelText: 'Consumer PII: Address, Coordinates, First Name, Last Name, Phone',
                                        fieldName: 'consumer_pii',
                                        value: 'consumer_pii',
                                        parentId: 1,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'Consumer Demographics: Age, Income, Gender, Marital Status, Race, Area, Net Worth, Political Party, Home Owner, Home Value, Social Matrix Category, Digital Activity Score',
                                        fieldName: 'consumer_demographics',
                                        value: 'consumer_demographics',
                                        parentId: 1,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'Consumer Basics: City, State, Zip5, Home Owner, Home Value, Social Matrix Category, Digital Activity Score',
                                        fieldName: 'consumer_basics',
                                        value: 'consumer_basics',
                                        parentId: 1,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'Business Basics: Address, Coordinates, Company_name, Phone, Naics, Domain',
                                        fieldName: 'business_basics',
                                        value: 'business_basics',
                                        parentId: 1,
                                        disabled:false,
                                        checked: false
                                    },
                                ],
                            },
                            {
                                id: 2,
                                labelText: "Keys",
                                value: 'keys',
                                children: [
                                    {
                                        labelText: 'Semcasting ID',
                                        fieldName: 'semcasting_id',
                                        value: 'semcasting_id',
                                        parentId: 2,
                                        disabled:false,
                                        checked: false,
                                    }, {
                                        labelText: 'IP Address',
                                        fieldName: 'ip_sample',
                                        value: 'ip_sample',
                                        parentId: 2,
                                        checked: false,
                                        disabled:false,
                                    }, {
                                        labelText: 'Email',
                                        fieldName: 'email',
                                        value: 'email',
                                        parentId: 2,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'SHA256 Email',
                                        fieldName: 'sha256_email',
                                        value: 'sha256_email',
                                        parentId: 2,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'SHA1 Email',
                                        fieldName: 'sha1_email',
                                        value: 'sha1_email',
                                        parentId: 2,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'MD5 Email',
                                        fieldName: 'md5_email',
                                        value: 'md5_email',
                                        parentId: 2,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'UID2',
                                        fieldName: 'uid2_id',
                                        value: 'uid2_id',
                                        parentId: 2,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'Devices',
                                        fieldName: 'mobile_devices',
                                        value: 'mobile_devices',
                                        parentId: 2,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'Internet Sites',
                                        fieldName: 'internet_sites',
                                        value: 'internet_sites',
                                        parentId: 2,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'Internet Keywords',
                                        fieldName: 'internet_keywords',
                                        value: 'internet_keywords',
                                        parentId: 2,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'Hashed Semcasting ID',
                                        fieldName: 'hashed_semcasting_id',
                                        value: 'hashed_semcasting_id',
                                        parentId: 2,
                                        disabled:false,
                                        checked: false,
                                        defaultValue:false,
                                    },
                                ],
                            },
                            {
                                id: 3,
                                labelText: "Platform IDs",
                                value: 'platformIds',
                                children: [
                                    {
                                        labelText: 'Acxiom',
                                        fieldName: 'acxiom_id',
                                        value: 'acxiom_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'AdForm',
                                        fieldName: 'adform_id',
                                        value: 'adform_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    },
                                    {
                                        labelText: 'Adstra Individual',
                                        fieldName: 'adstra_id',
                                        value: 'adstra_id',
                                        parentId: 3,
                                        disabled: false,
                                        checked: false
                                    },
                                    {
                                        labelText: 'Adstra Household',
                                        fieldName: 'adstra_household_id',
                                        value: 'adstra_household_id',
                                        parentId: 3,
                                        disabled: false,
                                        checked: false
                                    }, {
                                        labelText: 'Adobe',
                                        fieldName: 'adobe_id',
                                        value: 'adobe_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    },{
                                        labelText: 'AudienceAcuity',
                                        fieldName: 'audienceacuity_id',
                                        value: 'audienceacuity_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    },{
                                        labelText: 'BlockGraph BGID',
                                        fieldName: 'blockgraph_id',
                                        value: 'blockgraph_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    },{
                                        labelText: 'DataTrust',
                                        fieldName: 'datatrust_id',
                                        value: 'datatrust_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'FreeWheel MRM',
                                        fieldName: 'freewheel_id',
                                        value: 'freewheel_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    },
                                    {
                                        labelText: 'InMobi',
                                        fieldName: 'inmobi_id',
                                        value: 'inmobi_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    },
                                    {
                                        labelText: 'L2 Voter',
                                        fieldName: 'l2_id',
                                        value: 'l2_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'MRI Simmons',
                                        fieldName: 'mri_id',
                                        value: 'mri_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'Nexxen',
                                        fieldName: 'nexxen_id',
                                        value: 'nexxen_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    },{
                                        labelText: 'NPI',
                                        fieldName: 'npi_id',
                                        value: 'npi_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'PubMatic',
                                        fieldName: 'pubmatic_id',
                                        value: 'pubmatic_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'PurpleLab',
                                        fieldName: 'purplelabs_id',
                                        value: 'purplelabs_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'PurpleLab Individual',
                                        fieldName: 'purplelab_individual_id',
                                        value: 'purplelab_individual_id',
                                        parentId: 3,
                                        disabled: false,
                                        checked: false
                                    }, {
                                        labelText: 'Roku',
                                        fieldName: 'roku_id',
                                        value: 'roku_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'Spring Serve',
                                        fieldName: 'spring_serve_id',
                                        value: 'spring_serve_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    }, {
                                        labelText: 'Trade Desk',
                                        fieldName: 'tradedesk_id',
                                        value: 'tradedesk_id',
                                        parentId: 3,
                                        disabled: false,
                                        checked: false
                                    }, {
                                        labelText: 'Xandr',
                                        fieldName: 'xandr_id',
                                        value: 'xandr_id',
                                        parentId: 3,
                                        disabled:false,
                                        checked: false
                                    },
                                ],
                            },
                        ]
                    }]
                }
            ]
        },
        {
            fieldType: 'savableFieldsWrapper',
            layout: [
                {
                    fieldType: 'searchSelect',
                    fieldName: 'path',
                    labelText: 'S3 File Path',
                    url: '/v2/secure/attributor/activate-credentials/semcasting/path',
                    placeholder: 'Select a File Path or add a new one',
                    namePropName: 'value',
                    textPropName: 'value',
                    valuePropName: 'id',
                    required: true

                }, {
                    fieldType: 'searchSelect',
                    fieldName: 'account_email',
                    labelText: 'Account Email',
                    url: '/v2/secure/attributor/activate-credentials/semcasting/account_email',
                    placeholder: 'Select an Account Email or add a new one',
                    namePropName: 'value',
                    textPropName: 'value',
                    valuePropName: 'id',
                    required: true,
                    // validation: [{
                    //     required: function (value, attr, computed) {
                    //         return !parseInt(value);
                    //     }
                    // }, {
                    //     pattern: 'email',
                    //     msg: 'Please enter a valid email'
                    // }]
                }, {
                    fieldType: 'checkbox',
                    fieldName: 'save_credentials',
                    labelText: 'Save File Path and Account Email for future use',
                    checked: false
                }
            ]
        }, {
            fieldType: 'pricing'
        }
    ],

    /**
     * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
     */
    savableDataNames: ['path', 'account_email'],

    formik: {
        initialValues: {
            platform_id_sampling:[],
            location_properties:[],
            path:"",
            account_email:"",
            save_credentials: false,

        },
        // We used Yup here for input format validation.
        validationSchema: Yup.object().shape({
            location_properties: Yup.array().min(1, "Must choose at least one"),
            path:Yup.string().required("S3 File Path is required"),
            account_email:Yup.string().required("Email is required").email("Email format is incorrect"),
        }),
        onSubmit: (values) => {},
    },
};
