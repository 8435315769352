import {Tab, Tabs} from "react-bootstrap";
import AttributionErrors from "../components/JobStatuses/AttributionErrors";
import AttributionInProgress from "../components/JobStatuses/AttributionInProgress";
import React, {useEffect, useState} from "react";
import ActivatedSegmentsTotalsByAccount from "../components/AppStatus/ActivatedSegmentsTotalsByAccount";
import DatePicker from "react-datepicker";
import ActivatedSegmentsTotalsByPlatform from "../components/AppStatus/ActivatedSegmentsTotalsByPlatform";
import ActivatedSegmentsTotalsByParty from "../components/AppStatus/ActivatedSegmentsTotalsByParty";
import ActivatedSegmentsTotalsByUser from "../components/AppStatus/ActivatedSegmentsTotalsByUser";
import CreatedSegmentsTotalsByAccount from "../components/AppStatus/CreatedSegmentsTotalsByAccount";
import CreatedSegmentsTotalsByUser from "../components/AppStatus/CreatedSegmentsTotalsByUser";
import AttributionsTotalsByAccount from "../components/AppStatus/AttributionsTotalsByAccount";
import AttributionsTotalsByUser from "../components/AppStatus/AttributionsTotalsByUser";
import DormantUsers from "../components/AppStatus/DormantUsers";
import {semcastAxios} from "../../../Utils/Common";
import {
    ACTIVE_USER_COUNT_API,
    DORMANT_USERS_API,
    LOADING_TEXT,
    SUCCESS_STATUS,
    YYYY_MM_DD_DATEFORMAT
} from "../../../api/constants";
import moment from "moment/moment";
import {UserContext} from "../../../contexts/UserContext";
import "./AppStatus.css";

function AppStatus() {
    const BY_ACCOUNT = 'by_account'
    const BY_USER = 'by_user'
    const BY_PLATFORM = 'by_platform'
    const BY_PARTY = 'by_party'
    const ACTIVATE_USERS = 'Active Users'
    const SEGMENTS_CREATED = 'Segments Created'
    const SEGMENTS_ACTIVATED = 'Segments Activated'
    const ATTRIBUTIONS = 'Attributions'

    const { user, setUser } = React.useContext(UserContext);
    const [activatedSegmentsBlockKey, setActivatedSegmentsBlockKey] = useState(BY_ACCOUNT); // or by_users, by_platform, by_party
    const [createdSegmentsBlockKey, setCreatedSegmentsBlockKey] = useState(BY_ACCOUNT); // or by_user
    const [attributionBlockKey, setAttributionBlockKey] = useState(BY_ACCOUNT); // or by_user

    const [activeUsersTileCount, setActiveUsersTileCount] = useState(null);
    const [attributionsTileCount, setAttributionsTileCount] = useState(null);
    const [segmentCreatedTileCount, setSegmentCreatedTileCount] = useState(null);
    const [segmentActivatedTileCount, setSegmentActivatedTileCount] = useState(null);

    const mStartOfWeek = moment().startOf('week')
    const mStartOfNextWeek = moment().endOf('week').add(1, 'day')
    const [propsToPass, setPropsToPass] = useState({
        startDate: new Date(mStartOfWeek),
        endDate: new Date(mStartOfNextWeek)
    });
    const [startDate, setStartDate] = useState(propsToPass.startDate);
    const [endDate, setEndDate] = useState(propsToPass.endDate);

    useEffect(async () => {
        await loadActiveUserCount()
    }, [])

    const handleUpdateClick = async () => {
        setPropsToPass({startDate: startDate, endDate: endDate})
        initiateTilesCount()
        await loadActiveUserCount()
    }

    const loadActiveUserCount = async () => {
        try {
            const res = await semcastAxios.get(ACTIVE_USER_COUNT_API +
                `?last_login_at_start=${moment(
                    startDate
                ).format(YYYY_MM_DD_DATEFORMAT)}&last_login_at_start=${moment(
                    endDate
                ).format(
                    YYYY_MM_DD_DATEFORMAT
                )}&deleted_at=null`, {
                withCredentials: true,
            });
            if (res.data.status === SUCCESS_STATUS && res?.data?.data) {
                setActiveUsersTileCount(res.data.data)
            } else {
                setActiveUsersTileCount(0)
            }
        } catch (e) {
            setActiveUsersTileCount(0)
        }
    };

    const reloadEventFromChild = async (key,value) => {
        switch (key) {
            case ATTRIBUTIONS: {
                setAttributionsTileCount(value)
                break;
            }
            case SEGMENTS_CREATED: {
                setSegmentCreatedTileCount(value)
                break;
            }
            case SEGMENTS_ACTIVATED: {
                setSegmentActivatedTileCount(value)
                break;
            }
            default: {
                break;
            }
        }
        //console.log("reload event fired ", key,value)
    }

    const initiateTilesCount = ()=>{
        setActiveUsersTileCount(null)
        setSegmentCreatedTileCount(null)
        setSegmentActivatedTileCount(null)
        setAttributionsTileCount(null)
    }

    return (
        <>
            { user?.isAdmin &&
                <div>
                    {/*className="content-body"*/}
                    {/*<div className="container-fluid">*/}
                        <div className="row admin-app-status">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="tab-content mt-3">
                                    <div className="card">
                                        <div className="card-header d-block">
                                            <div className="form-inline float-right mb-3">
                                                <div className="form-group">
                                                    <label className="form-label required-asterisk float-left">Select Dates</label>
                                                    <div className="input-group">
                                                        <DatePicker
                                                            className="form-control input-daterange-datepicker"
                                                            dateFormat="yyyy-MM-dd"
                                                            placeholderText={'Start Date'}
                                                            selected={startDate}
                                                            onChange={(date) => {
                                                                setStartDate(date);
                                                            }}
                                                        />
                                                        <span className="to-text">-</span>
                                                        <DatePicker
                                                            className="form-control input-daterange-datepicker"
                                                            dateFormat="yyyy-MM-dd"
                                                            placeholderText={'End Date'}
                                                            selected={endDate}
                                                            onChange={(date) => {
                                                                setEndDate(date);
                                                            }}
                                                        />
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="btn btn-primary updateBtn"
                                                            title="Update"
                                                            onClick={() => handleUpdateClick()}
                                                        >
                                                            Update
                                                        </a>
                                                    </div>
                                                    <small className="form-text text-muted float-left">
                                                        A week should go
                                                        from <strong>Sunday</strong> to <strong>Sunday</strong>.
                                                    </small>
                                                </div>

                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="row">
                                                <div className="col-xl-3 col-lg-6 col-sm-6">
                                                    <div className="widget-stat card bg-primary">
                                                        <div className="card-body">
                                                            <div className="media">
                                                                <div className="media-body">
                                                                    <p className="mb-1 text-white">
                                                                        {ACTIVATE_USERS}</p>
                                                                    <span className="text-white">
                                                                        {!activeUsersTileCount && activeUsersTileCount !== 0 ? LOADING_TEXT : activeUsersTileCount}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-6 col-sm-6">
                                                    <div className="widget-stat card bg-warning">
                                                        <div className="card-body">
                                                            <div className="media">
                                                                <div className="media-body">
                                                                    <p className="mb-1 text-white">{ATTRIBUTIONS}</p>
                                                                    <span className="text-white">
                                                                        {!attributionsTileCount && attributionsTileCount !== 0 ? LOADING_TEXT : attributionsTileCount}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-6 col-sm-6">
                                                    <div className="widget-stat card bg-secondary">
                                                        <div className="card-body">
                                                            <div className="media">
                                                                <div className="media-body text-white">
                                                                    <p className="mb-1 text-white">
                                                                        {SEGMENTS_CREATED}
                                                                    </p>
                                                                    <span className="text-white">
                                                                        {!segmentCreatedTileCount && segmentCreatedTileCount !== 0 ? LOADING_TEXT : segmentCreatedTileCount}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-6 col-sm-6">
                                                    <div className="widget-stat card bg-success ">
                                                        <div className="card-body">
                                                            <div className="media">
                                                                <div className="media-body text-white">
                                                                    <p className="mb-1 text-white">
                                                                        {SEGMENTS_ACTIVATED}
                                                                    </p>
                                                                    <span className="text-white">
                                                                        {!segmentActivatedTileCount && segmentActivatedTileCount !== 0 ? LOADING_TEXT : segmentActivatedTileCount}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-6 card-min-height">
                                <div className="card">
                                    <div className="card-header d-block">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <div className="card-title mt-3">
                                                        Activated Segment Totals
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={activatedSegmentsBlockKey}
                                            onSelect={(k) => setActivatedSegmentsBlockKey(k)}
                                            className="mb-3"
                                        >
                                            <Tab eventKey={BY_ACCOUNT} title="By Account">
                                                <ActivatedSegmentsTotalsByAccount data={{propsToPass}}
                                                                                  uniqueKey={SEGMENTS_ACTIVATED}
                                                                                  broadcastReloadEvent={reloadEventFromChild}/>
                                            </Tab>
                                            <Tab eventKey={BY_USER} title="By User">
                                                <ActivatedSegmentsTotalsByUser data={{propsToPass}}/>
                                            </Tab>
                                            <Tab eventKey={BY_PLATFORM} title="By Platform">
                                                <ActivatedSegmentsTotalsByPlatform data={{propsToPass}}/>
                                            </Tab>
                                            <Tab eventKey={BY_PARTY} title="By Party">
                                                <ActivatedSegmentsTotalsByParty data={{propsToPass}}/>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 card-min-height">
                                <div className="card">
                                    <div className="card-header d-block">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <div className="card-title mt-3">
                                                        Created Segment Totals
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={createdSegmentsBlockKey}
                                            onSelect={(k) => setCreatedSegmentsBlockKey(k)}
                                            className="mb-3"
                                        >
                                            <Tab eventKey={BY_ACCOUNT} title="By Account">
                                                <CreatedSegmentsTotalsByAccount data={{propsToPass}}
                                                                                uniqueKey={SEGMENTS_CREATED}
                                                                                broadcastReloadEvent={reloadEventFromChild}/>
                                            </Tab>
                                            <Tab eventKey={BY_USER} title="By User">
                                                <CreatedSegmentsTotalsByUser data={{propsToPass}}/>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 card-min-height">
                                <div className="card">
                                    <div className="card-header d-block">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <div className="card-title mt-3">Attribution Totals</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={attributionBlockKey}
                                            onSelect={(k) => setAttributionBlockKey(k)}
                                            className="mb-3"
                                        >
                                            <Tab eventKey={BY_ACCOUNT} title="By Account">
                                                <AttributionsTotalsByAccount data={{propsToPass}}
                                                                             uniqueKey={ATTRIBUTIONS}
                                                                             broadcastReloadEvent={reloadEventFromChild}/>
                                            </Tab>
                                            <Tab eventKey={BY_USER} title="By User">
                                                <AttributionsTotalsByUser data={{propsToPass}}/>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 card-min-height">
                                <div className="card">
                                    <div className="card-header d-block">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <div className="card-title mt-3">Dormant Users</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <DormantUsers/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/*</div>*/}
                </div>
            }
        </>
    );
}

export default AppStatus;
