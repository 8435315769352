import * as Yup from "yup";

export const keywordswhitelistConfig = {
    /**
     * User Readable name of company the user is onBoarding with
     */
    companyText: "Authenticated Keywords & Website Whitelist",

    /**
     * lowercase no spaces or underscores name of company
     * This will be passed to the activate model and
     * used when saving to create the correct submission url
     */
    company: "trending",

    /**
     * HTML that explains how to use the form
     */
    instructions:
        "Based on this onboarded segment or list selections we were able to identify up to a 1,000 Popular Keywords, Trending Keywords and Potential Endemic Websites that aligns with your audience."
    + "You can download and apply these lists for Search Retargeting or to Create a Whitelist of Websites to target."
    + "When you check one of the boxes below you are agreeing to a $0.50 CPM charge calculated " +
        "based on the number of households/businesses in your segment. Ex. If the household count is 500,000, each " +
        "list you select below will cost $250.00. There will be a minimum charge of $10.00."
    + "Within a calendar month, you can repeat these steps for current rankings at no additional cost. Semcasting will bill you directly."
    + "The lists can be retrieved from the Dashboard once they have been prepared. A list charge may not be reversed once downloaded.",

    /**
     * data used to create a company activation block
     */
    companyBlockOptions: {
        val: "trending",
        text: "Trending",
        img: "trending-logo.png",
    },

    /**
     * anchor tag used to login to a specific platform
     */

    layout: [
        {
            fieldType: "trendingCheckboxGroup",
            labelText: "Select for Download from Dashboard when Complete:",
            fieldName: "list_type",
            required: true,
            checkboxes: [
                {
                    labelText:'People',
                    fieldName:'POPULAR_PEOPLE',
                    value:'POPULAR_PEOPLE',
                    checked:false,
                    // disabled:true
                },{
                    labelText:'Places',
                    fieldName:'POPULAR_PLACES',
                    value:'POPULAR_PLACES',
                    checked:false,
                    // disabled:true
                },{
                    labelText:'Organizations',
                    fieldName:'POPULAR_ORGANIZATIONS',
                    value:'POPULAR_ORGANIZATIONS',
                    checked:false,
                    // disabled:true
                },{
                    labelText:'Other',
                    fieldName:'POPULAR_OTHER',
                    value:'POPULAR_OTHER',
                    checked:false,
                    // disabled:true
                },{
                    labelText:'Trending People',
                    fieldName:'TRENDING_PEOPLE',
                    value:'TRENDING_PEOPLE',
                    checked:false,
                    // disabled:true
                },{
                    labelText:'Trending Places',
                    fieldName:'TRENDING_PLACES',
                    value:'TRENDING_PLACES',
                    checked:false,
                    // disabled:true
                },{
                    labelText:'Trending Organizations',
                    fieldName:'TRENDING_ORGANIZATIONS',
                    value:'TRENDING_ORGANIZATIONS',
                    checked:false,
                    // disabled:true
                }, {
                    labelText: 'Trending Other',
                    fieldName: 'TRENDING_OTHER',
                    value: 'TRENDING_OTHER',
                    checked: false,
                },{
                    labelText:'Website URLs',
                    fieldName:'SITES',
                    value:'SITES',
                    checked:false
                },
            ],
        },{
            fieldType:'checkbox',
            fieldName:'client_agreement',
            required:true,
            labelText:'Please Confirm: By Checking this Box and Activating, you agree to all associated costs and fees described above.',
            value:true,
            checked:false
        },
        {
            fieldType: "selectPickerFTPConnection",
            fieldName: "selectPickerFTPConnection",
            labelText: "FTP Setting Connection",
            url: "/v2/secure/attributor/creative-tags",
            namePropName: "selectPickerFTPConnection",
            textPropName: "selectPickerFTPConnection",
            valuePropName: "selectPickerFTPConnection",
            required: true,
        },
        {
            fieldType:'textField',
            fieldName:'calculated_cost',
            labelText:'Calculated Cost:',
            required:false,
            value:'$0.00',
            defaultValue:'$0.00',
            readOnly:true
        },
        // {
        //     fieldType: "selectPickerTag",
        //     fieldName: "creativeTagIdDropDown",
        //     labelText: "Creative Tag ID",
        //     url: "/v2/secure/attributor/creative-tags",
        //     placeholder: "Select a New or existing Tag for your creative",
        //     namePropName: "creativeTagId",
        //     textPropName: "creativeTagId",
        //     valuePropName: "creativeTagId",
        //     required: true,
        // },
        {
            fieldType: "creativeTagId",
            fieldName: "creativeTagId",
            labelText: "Creative Tag ID",
            required: true,
        },
        // FD: 11538
        // {
        //     fieldType: "creativeTag",
        //     fieldName: "creativeTag",
        //     labelText: "Creative Tag",
        //     required: true,
        // },
    ],

    /**
     * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
     */
    savableDataNames: [],
    formik: {
        initialValues: {
            list_type: [],
            client_agreement: false,
            calculated_cost:'$0.00',
            selectPickerFTPConnection: ""
            // FD: 11538
            // creativeTagIdDropDown: "",
            // creativeTagId:"",
        },
        // We used Yup here for data validation and condition.
        validationSchema: Yup.object().shape({
            list_type: Yup.array().min(1, "Please choose at least one"),
            client_agreement: Yup.boolean().oneOf([true], 'Please confirm before submit'),
            calculated_cost:Yup.string().required("Please select at least one category"),
            selectPickerFTPConnection: Yup.object().required("Please select one from the list"),
            // FD: 11538
            // creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
            // creativeTagId: Yup.string().required("Creative Tag ID is required")
        }),
        onSubmit: () => {},
    },
};