/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../assets/img/logo.png";
import "./Header.css";
import AOS from "aos";
import "swiper/swiper.min.css";
import { Swiper } from "swiper";
// Captcha npm packages for v2 checkbox and v3
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { SignInDialog } from "./SignInDialog";

import {
  extractAuthCookie,
  isUserLoggedIn,
  semcastAxios,
  setAuthCookieToStorage,
  setDataToStorage,
  setUserDataToStorage,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../../Utils/Common";

import "boxicons";
import {
  AUTHORIZE_API,
  SUCCESS_STATUS,
  USER_INFO_API,
} from "../../api/constants";
import { UserContext } from "../../contexts/UserContext";
import LoadOverlay from "../../Admin/Components/SharedComponents/LoadOverlay";
import axios from "axios";
import {Slide} from "@progress/kendo-react-animation";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";

const Header = () => {
  AOS.init();

  let history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // captcha vars
  const [token, setToken] = useState("");
  const [noOfVerifications, setNoOfVerifications] = useState(0);
  const [loadCap, setLoadCap] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [show, setShow] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  // const [dialogHeight, setDialogHeight] = useState(370);
  const [dialogHeight, setDialogHeight] = useState(330);
  const [failedLogin, setFailedLogin] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [failedPwReset, setFailedPwReset] = useState(false);
  const [successPwReset, setSuccessPwReset] = useState(false);

  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [activeNotification, setActiveNotification] = useState(false);
  // function for rendering kendo notification
  // types = ['success', 'error', 'warning', 'info', 'none']
  // removeTime is in seconds
  const notification = (type, message, removeTime) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setActiveNotification(true);
    if(removeTime) {
      setTimeout(() => setActiveNotification(false), removeTime*1000);
    }
  };

  const handleForgotPassword = (e) => {
    // e.preventDefault();

    axios
        .post("https://audiencedesigner.com/v2/attributor/password-reset", {
          email: email,
        })
        .then(function (response) {
          console.log(response);
          if (response.data.status === "success") {

            setSuccessPwReset(true);
            // showSuccessToastMessage(
            //     `An email has been sent to ${email} with further instructions. If you can't find it be sure and check your spam folder.`
            // );
          }
        })
        .catch(function (error) {
          console.log(error);

          setFailedPwReset(true);
          // showErrorToastMessage("Please enter a valid email address");
        });
  };

  const handlePasswordResetWindowChange = () => {
    if(showResetPassword) {
      // setDialogHeight(420);
      // dialog.item(0).style.height = "315px";
      setShowResetPassword(false);
    } else {
      // dialog.item(0).style.height = "315px";
      // setDialogHeight(340);
      setShowResetPassword(true);
    }
  };

  const { user, setUser } = React.useContext(UserContext);

  // cookie functions
  function setCookie(cname, cvalue, mins) {
    // console.log("inSetCookie");
    const d = new Date();
    d.setTime(d.getTime() + (mins*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return 0;
  };
  function checkCookie(cookieName) {
    let attempts = getCookie(cookieName);
    if (attempts === 0) {
      setCookie("attempts", 0, 10);
    } else {
      setNoOfVerifications(parseInt(attempts));
    }
  };

  const clickHandler = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const result = await executeRecaptcha('SignInADSV2');
    await setToken(result);
  }, [executeRecaptcha]);

  const clickNavSignIn = async () => {
    await clickHandler();
    setShowResetPassword(false);
    setShow(!show);
  };

  // public recaptcha v2 checkbox key
  const SITE_KEY = "6LeSTW8eAAAAAP2cJ8RZfEnPR2GklW3_a-tQn9pt";
  const reCaptchaRef = React.createRef();

  // run when captcha solved successfully
  const handleChange = (token) => {
    // console.log("inHandleChange");
    setNoOfVerifications(0);
    setLoadCap(false);
    setDisableSubmit(false);
    setCookie("attempts", 0, 10);
    setDisableInput(false);
    setDialogHeight(330);
  };

  let mySwiper;
  useEffect(() => {

    console.log("Inside useEffect. Value of noOfVerifications: ", noOfVerifications);

    // Make recaptcha badge visible on this page only
    let badge = document.getElementsByClassName("grecaptcha-badge").item(0);
    if(badge) badge.style.visibility = "visible";

    // check to see if attempts cookie holds a value. if so, SetNoOfVerifications(attempts)
    if(noOfVerifications === 0) checkCookie("attempts");
    if(noOfVerifications > 3) {
      setLoadCap(true);
      setDisableInput(true);
      setDialogHeight(430);
    }

    // mySwiper = new Swiper(".swiper-container", {
    //   loop: true,
    //   slidesPerView: "1",
    //   centeredSlides: true,
    //
    //   pagination: {
    //     el: ".swiper-pagination",
    //     type: "bullets",
    //     clickable: true,
    //   },
    // });

    if (!executeRecaptcha) {
      return;
    }
  }, [executeRecaptcha, token, disableSubmit]);

  const handleSubmit = async (e) => {

    if (email.length && password.length) {

      console.log("value of noOfVerifications: ", noOfVerifications);

      // increment noOfVerifications, set attempts cookie
      setNoOfVerifications(noOfVerifications => noOfVerifications + 1);

      console.log("setting the cookie to this", noOfVerifications);

      setCookie("attempts", noOfVerifications, 10);

      console.log("cookie value is: ", getCookie("attempts"));

      // clickhandler generates recaptcha v3 code to send to the backend
      await clickHandler();
      // if noOfVerifications > 3, don't execute sign-in. Disable submit button and render captcha v2 checkbox
      if (noOfVerifications > 3) {
        setDisableSubmit(true);
      } else {
        setLoading(true)
        try {
          let response = await semcastAxios.post(AUTHORIZE_API, {
            email: email,
            password: password,
            c: token,
          });
          if (response?.data?.status === SUCCESS_STATUS) {

            // notification('success', 'Success! Logging in...', 3);

            showSuccessToastMessage('Success! Logging in...', 3);

            // set captcha badge to "hidden", so badge will not appear on other pages
            let badge = document.getElementsByClassName("grecaptcha-badge").item(0);
            badge.style.visibility = "hidden";
            // showSuccessToastMessage("Logged in Successfully.");
            setDataToStorage(true, response.data.id);
            setAuthCookieToStorage(extractAuthCookie());
            // history.push("/segmentation-dashboard");
            window.location.href ="/segmentation-dashboard"
            //await loadUserInfo();
          }
        } catch (error) {

          showErrorToastMessage("Login Failed: Incorrect email or password.", 5);

          // notification('error', 'Login Failed: Incorrect email or password.', 30000);
          // setFailedLogin(true);

          console.log("Error during login process:", error);
          // showErrorToastMessage("Email or password is incorrect");
        } finally {
          setLoading(false);
        }
      }
    } else {
      // Renders error message for failed login
      showErrorToastMessage("Login Failed: Incorrect email or password.", 5);
      // setFailedLogin(true);

      console.log("Failed login attempt");
      // showErrorToastMessage("Email or password is incorrect");
    }
  };

  return (
    <React.Fragment>
      {/*<ToastContainer />*/}
      {/* <!-- ======= Header ======= --> */}

      <NotificationGroup style={{
        right: 0,
        top: 0,
        alignItems: 'flex-end',
      }}>
        <Slide direction={activeNotification ? 'up' : 'down'}>
          {activeNotification && <Notification type={{
            style: `${notificationType}`,
            icon: true
          }} closable={true} onClose={() => setActiveNotification(false)}>
            <span>{notificationMessage}</span>
          </Notification>}
        </Slide>
      </NotificationGroup>

      <header id="header" className="fixed-top d-flex align-items-center headerLandingPage">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <div className="logo">
            <h1>
              <NavLink exact to="/">
                <img src={logo} alt="Audience Designer" />
              </NavLink>
            </h1>
            {/* <a href="index.html"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a> */}
          </div>
          <ul className="navbar" id={"navbar"}>
            <li className={"navbarListItem"}>
              <NavLink className="nav-link scrollto navBarLink headerContactUs" to="/contact">
                Contact Us
              </NavLink>
            </li>
            &nbsp; &nbsp; &nbsp; &nbsp;
            {isUserLoggedIn() === true ? (
              <li>
                <NavLink
                  className="nav-link scrollto navBarLink"
                  to="/segmentation-dashboard"
                >
                  Dashboard
                </NavLink>
              </li>
            ) : (

              <div className={"headerSignInDiv"}>

                <Button className={"loginDropdownButton navBarLink"}
                        onClick={clickNavSignIn}
                        size={"large"}
                        rounded={"large"}
                >
                  Sign In
                </Button>

              {show && (
                  <SignInDialog handleForgotPassword={handleForgotPassword} password={password} logo={logo} clickNavSignIn={clickNavSignIn} showResetPassword={showResetPassword}
                                handlePasswordResetWindowChange={handlePasswordResetWindowChange} handleSubmit={handleSubmit} failedLogin={failedLogin} disableInput={disableInput}
                                loadCap={loadCap} noOfVerifications={noOfVerifications} disableSubmit={disableSubmit} SITE_KEY={SITE_KEY} setEmail={setEmail} setFailedLogin={setFailedLogin}
                                setPassword={setPassword} email={email} reCaptchaRef={reCaptchaRef} dialogHeight={dialogHeight} handleChange={handleChange} notification={notification}
                                failedPwReset={failedPwReset} setFailedPwReset={setFailedPwReset} successPwReset={successPwReset} setSuccessPwReset={setSuccessPwReset}
                                setLoadCap={setLoadCap} setDisableInput={setDisableInput} setDialogHeight={setDialogHeight} setDisableSubmit={setDisableSubmit}
                  />
              )}
              </div>
            )}
          </ul>
        </div>
      </header>
      {/* <!-- End Header --> */}
    </React.Fragment>
  );
};

export default Header;
