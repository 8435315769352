import {
  ALL_ACCOUNT_DETAILS,
  BILLING, LOADING_TEXT,
} from "../../../api/constants";
import {
  semcastAxios,
  paginationOptions,
  noDataIndication,
  ExportCSV,
  boostrap2TableSearchOptions,
  exportCSVOptions,
} from "../../../Utils/Common";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Loader from "../../Components/Loader";
import {UserContext} from "../../../contexts/UserContext";
import "./Billing.css";
function Billing() {
  const { user, setUser } = React.useContext(UserContext);
  const [accounts, setAccounts] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [segmentsBilling, setSegmentsBilling] = useState([]);
  const [attributionsBilling, setAttributionsBilling] = useState([]);
  const [allAccounts, setAllAccounts] = useState(true);
  const [segmentFullReport, setSegmentFullReport] = useState([]);
  const [attrFullReport, setAttrFullReport] = useState([]);
  const [email, setEmail] = useState("totals");
  const [attrBillAmt, setAttrBillAmt] = useState(null);
  const [segBillAmt, setSegBillAmt] = useState(null);
  const [loading, setLoading] = useState(false);
  var strDate;
  var enDate;

  const formatAsMoney = (amount) => {
    return amount.toFixed(2); // Rounds to two decimal places and returns as a string
  };
  const getDetails = async (email) => {
    setLoading(true);
    let search =
        email === "totals"
            ? "totals"
            : accounts.find((o) => o.email === email).id;
    if (!search) {
      search = "totals";
      setEmail("totals");
    }
    if (search === "totals") {
      setAllAccounts(true);
      semcastAxios
          .get(
              BILLING +
              "segments/report" +
              `?completed_at_start=${moment(
                  startDate ? startDate : strDate
              ).format("YYYY-MM-DD")}&completed_at_end=${moment(
                  endDate ? endDate : enDate
              ).format(
                  "YYYY-MM-DD"
              )}&orderby=account_company%2Cgroup_name%2Csegment_name`,
              {
                withCredentials: true,
              }
          )
          .then((res) => {
            setSegmentFullReport(res.data);
          });

      semcastAxios
          .get(
              BILLING +
              "attributions/report" +
              `?completed_at_start=${moment(
                  startDate ? startDate : strDate
              ).format("YYYY-MM-DD")}&completed_at_end=${moment(
                  endDate ? endDate : enDate
              ).format(
                  "YYYY-MM-DD"
              )}&orderby=account_company%2Cgroup_name%2Csegment_name`,
              {
                withCredentials: true,
              }
          )
          .then((res) => setAttrFullReport(res.data));
    } else setAllAccounts(false);
    setSegmentsBilling([]);
    setAttributionsBilling([]);

    semcastAxios
        .get(
            BILLING +
            "segments/" +
            search +
            `?completed_at_start=${moment(startDate ? startDate : strDate).format(
                "YYYY-MM-DD"
            )}&completed_at_end=${moment(endDate ? endDate : enDate).format(
                "YYYY-MM-DD"
            )}`,
            {
              withCredentials: true,
            }
        )
        .then((res) => {
          setLoading(false);
          if (res?.data?.data?.length) {
            res?.data?.data.forEach((obj, index) => {
              obj["__id"] = index;
            });
            setSegmentsBilling(res.data.data);
          } else {
            setSegmentsBilling(
                res.data.data.sort((a, b) =>
                    a.group_name > b.group_name
                        ? 1
                        : b.group_name > a.group_name
                            ? -1
                            : 0
                )
            );
          }
          let header = [
            ["Company", "Email", "Name", "Total Audience Size", "Billing Amount"],
          ];
          let billAmt = 0;
          res.data.data.forEach((data) => {
            header = [
              ...header,
              [
                data.company,
                data.email,
                data.first_name + " " + data.last_name,
                data.billing_audience_size,
                data.billing_amount,
              ],
            ];
            billAmt = billAmt + data.billing_amount;
          });
          setSegBillAmt(formatAsMoney(billAmt));
          //setSegBillAmt(billAmt);

          // setSegmentCsvData(header);
          // reset(res.data.data);
          // setLoading(false);
          // setType("Success");
          // setAlert(true);
        })
        .catch((err) => {
          // setType("Error!");
          // setLoading(false);
          // setAlert(true);
          // setMsg(err.response.data.data);
        });

    semcastAxios
        .get(
            BILLING +
            "attributions/" +
            search +
            `?completed_at_start=${moment(startDate ? startDate : strDate).format(
                "YYYY-MM-DD"
            )}&completed_at_end=${moment(endDate ? endDate : enDate).format(
                "YYYY-MM-DD"
            )}`,
            {
              withCredentials: true,
            }
        )
        .then((res) => {
          if (res?.data?.data?.length) {
            res?.data?.data.forEach((obj, index) => {
              obj["__id"] = index;
            });
            setAttributionsBilling(res.data.data);
          } else {
            setAttributionsBilling(
                res.data.data.sort((a, b) =>
                    a.completed_at > b.completed_at
                        ? 1
                        : b.completed_at > a.completed_at
                            ? -1
                            : 0
                )
            );
          }
          let header2 = [
            ["Company", "Email", "Name", "Total Audience Size", "Billing Amount"],
          ];
          let billAmt = 0;
          res.data.data.forEach((data) => {
            header2 = [
              ...header2,
              [
                data.company,
                data.email,
                data.first_name + " " + data.last_name,
                data.audience_sum,
                data.billing_amount,
              ],
            ];
            billAmt = billAmt + data.billing_amount;
          });
          setAttrBillAmt(formatAsMoney(billAmt));
          // setAttributionCsvData(header2);

          // reset(res.data.data);
          // setLoading(false);
          // setType("Success");
          // setAlert(true);
        })
        .catch((err) => {
          // setType("Error!");
          // setLoading(false);
          // setAlert(true);
          // setMsg(err.response.data.data);
        });
  };

  const get = async () => {
    const res = await semcastAxios.get(ALL_ACCOUNT_DETAILS, {
      withCredentials: true,
    });
    if (res.data.status === "success") {
      setAccounts(res.data.data);
    }
  };

  const defaultDate = () => {
    var d = new Date();
    d.setMonth(d.getMonth() + 2);
    let month = "" + d.getMonth();
    if (month === "0") month = 1;

    if (month.length < 2) month = "0" + month;
    let day = "0" + 1;
    let year = "" + d.getFullYear();
    enDate = Date.parse([year, month, day].join("-"));
    console.log("END DATE", enDate);
    setEndDate(enDate);

    d = new Date();
    d.setMonth(d.getMonth());
    month = "" + d.getMonth();
    year = "" + d.getFullYear();
    if (month === "0") month = 1;
    console.log(month, year, day);
    if (month.length < 2) month = "0" + month;
    strDate = Date.parse([year, month, day].join("-"));
    console.log("START DATE", strDate);
    setStartDate(strDate);
  };

  useEffect(() => {
    defaultDate();
    get();
    getDetails("totals");
  }, []);

  const { SearchBar } = Search;

  const dateFormatter = (data, _) =>
      data && moment(data).format("MM/DD/YY h:mm A");
  const nameFormatter = (data, row) => data + " " + row.last_name;
  const emailFormatter = (data, _) => <a href={`mailto:${data}`}>{data}</a>;
  const dollarFormatter = (data, _) => <span>$ {data}</span>;

  const accountSelection = (data, row) => {
    return (
        <a
            onClick={() => {
              setEmail(row.email);
              getDetails(row.email);
            }}
            href="#/"
        >
          {data}
        </a>
    );
  };
  const defaultSortedAllAccounts = [
    {
      dataField: "company",
      order: "asc",
    },
  ];
  const columnsAllAccounts = [
    {
      dataField: "company",
      text: "Company",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      // formatter: emailFormatter,
      sort: true,
    },
    {
      dataField: "first_name",
      text: "Name",
      formatter: nameFormatter,
      sort: true,
    },

    {
      dataField: "billing_audience_size",
      text: "Total Audience Size",
      sort: true,
    },
    {
      dataField: "billing_amount",
      text: "Billing Amount",
      formatter: dollarFormatter,

      sort: true,
    },
  ];

  const defaultSortedSingleAccount = [
    {
      dataField: "group",
      order: "asc",
    },
  ];

  const columnsSingleAccount = [
    {
      dataField: "account_email",
      text: "User who Deployed",
      formatter: emailFormatter,
      sort: true,
    },
    {
      dataField: "segment_name",
      text: "Segment Name",
      // formatter: emailFormatter,
      sort: true,
    },
    {
      dataField: "segment_uuid",
      text: "Segment UUID",
      sort: true,
    },

    {
      dataField: "group_name",
      text: "Group",
      sort: true,
    },
    {
      dataField: "completed_at",
      text: "Completed At",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "deleted_at",
      text: "Deleted At",
      formatter: dateFormatter,

      sort: true,
    },
    {
      dataField: "Platform_name",
      text: "Platform",
      sort: true,
    },
    {
      dataField: "party",
      text: "Party",
      sort: true,
    },
    {
      dataField: "billing_audience_size",
      text: "Audience Size",
      sort: true,
    },
    {
      dataField: "billing_audience_rate",
      text: "Billing Rate",
      formatter: dollarFormatter,

      sort: true,
    },
    {
      dataField: "billing_amount",
      text: "Total",
      formatter: dollarFormatter,

      sort: true,
    },
  ];

  const defaultSortedAttrAllAccounts = [
    {
      dataField: "company",
      order: "asc",
    },
  ];
  const columnsAllAttrAccounts = [
    {
      dataField: "company",
      text: "Company",
      // formatter: accountSelection,
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      // formatter: emailFormatter,
      sort: true,
    },
    {
      dataField: "first_name",
      text: "Name",
      formatter: nameFormatter,
      sort: true,
    },

    {
      dataField: "audience_sum",
      text: "Total Audience Size",
      sort: true,
    },
    {
      dataField: "billing_amount",
      text: "Billing Amount",
      formatter: dollarFormatter,
      sort: true,
    },
  ];

  const defaultSortedAttrSingleAccount = [
    {
      dataField: "group",
      order: "asc",
    },
  ];
  const columnsSingleAttrAccount = [
    {
      dataField: "account_email",
      text: "Created By",
      formatter: emailFormatter,
      sort: true,
    },
    {
      dataField: "output_file_name",
      text: "Study Name",
      sort: true,
    },
    {
      dataField: "group_name",
      text: "Group",
      sort: true,
    },
    {
      dataField: "completed_at",
      text: "Completed At",
      formatter: dateFormatter,

      sort: true,
    },
    {
      dataField: "deleted_at",
      text: "Deleted At",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "impression_ct",
      text: "Impression Size",
      sort: true,
    },
    {
      dataField: "audience_ct",
      text: "Audience Size",
      sort: true,
    },
    {
      dataField: "billing_rate",
      text: "Billing Rate",
      formatter: dollarFormatter,

      sort: true,
    },
    {
      dataField: "billing_amount",
      text: "Total",
      formatter: dollarFormatter,

      sort: true,
    },
  ];

  return (
      <>
        { user?.isAdmin &&
            <div>
              {/*className="content-body"*/}
              {/* row */}
              <div className="container-fluid admin-billing">
                <div className="row ">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="tab-content mt-3">
                      <div className="card">
                        <div className="card-header d-block">
                          <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">Select Account</label>
                                  <div className="input-group">
                                  <select
                                      className="form-control form-control-custom form-select"
                                      value={email}
                                      onChange={(e) => {
                                        // setAccInd(e.target.value);
                                        getDetails(e.target.value);
                                        setEmail(e.target.value);
                                      }}
                                  >
                                    <option value={"totals"} email="totals">
                                      All Accounts
                                    </option>
                                    {accounts.map((account, index) => (
                                        <option value={account.email}>
                                          {account.company} &nbsp; {account.email}
                                        </option>
                                    ))}
                                  </select>
                                  </div>
                                </div>
                            </div>
                            {/*   <div className="col-6">
                          <div className>
                            <label>Select Starting Date *</label>

                            <DatePicker
                                className="form-control input-daterange-datepicker"
                                dateFormat="yyyy-MM-dd"
                                selected={startDate}
                                onChange={(date) => {
                                  setStartDate(date);
                                }}
                                required
                            />
                          </div>
                          <div className>
                            <label>Select Ending Date *</label>
                            <DatePicker
                                className="form-control input-daterange-datepicker"
                                dateFormat="yyyy-MM-dd"
                                selected={endDate}
                                onChange={(date) => {
                                  setEndDate(date);
                                }}
                                required
                            />
                          </div>
                          <div>
                            <button
                                className="btn btn-primary mr-2 mb-2"
                                onClick={() => getDetails(email)}
                            >
                              Search
                            </button>
                          </div>
                          <span>
                          To get an entire month of results Date Range should go
                          from the <strong>1st</strong> to the{" "}
                            <strong>1st</strong>.
                        </span>
                        </div>*/}
                            <div className="col-6 form-inline mb-4">
                              <div className="form-group">
                                <label className="form-label required-asterisk">Select Dates</label>
                                <div className="input-group">
                                                        <DatePicker
                                                            className="form-control input-daterange-datepicker"
                                                            dateFormat="yyyy-MM-dd"
                                                            placeholderText={'Start Date'}
                                                            selected={startDate}
                                                            onChange={(date) => {
                                                              setStartDate(date);
                                                            }}
                                                        />
                                  <span className="to-text">-</span>
                                                        <DatePicker
                                                            className="form-control input-daterange-datepicker"
                                                            dateFormat="yyyy-MM-dd"
                                                            placeholderText={'End Date'}
                                                            selected={endDate}
                                                            onChange={(date) => {
                                                              setEndDate(date);
                                                            }}
                                                        />
                                  <a
                                      href="javascript:void(0)"
                                      className="btn btn-primary searchBtn"
                                      title="Search"
                                      onClick={() => getDetails(email)}
                                  >
                                    Search
                                  </a>
                                </div>
                                <small className="form-text text-muted float-left">
                                  To get an entire month of results Date Range should go
                                  from the <strong>1st</strong> to the
                                  <strong> 1st</strong>.
                                </small>
                              </div>

                            </div>
                            <div className="clearfix"></div>
                            <div className="row">
                              <div className="col-xl-3 col-lg-6 col-sm-6">
                                <div className="widget-stat card bg-primary">
                                  <div className="card-body">
                                    <div className="media">
                                      <div className="media-body text-white">
                                        <p className="mb-1  text-white">Attribution Total</p>
                                        <span
                                            className="text-white">${!attrBillAmt && attrBillAmt !== 0 ? LOADING_TEXT : attrBillAmt}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-6 col-sm-6">
                                <div className="widget-stat card bg-warning">
                                  <div className="card-body">
                                    <div className="media">
                                      <div className="media-body text-white">
                                        <p className="mb-1  text-white">Deployed Segments Total</p>
                                        <span
                                            className="text-white">${!segBillAmt && segBillAmt !== 0 ? LOADING_TEXT : segBillAmt}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-6 col-sm-6">
                                <div className="widget-stat card bg-success">
                                  <div className="card-body">
                                    <div className="media">
                                      <div className="media-body text-white">
                                        <p className="mb-1  text-white">Monthly Total</p>
                                        <span
                                            className="text-white">${(!attrBillAmt && attrBillAmt !== 0 && !segBillAmt && segBillAmt !== 0 )  ? LOADING_TEXT : segBillAmt}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header d-block">
                          <div className="row">
                            <div className="col-6">
                              <div className="d-flex justify-content-start align-items-center">
                                <div className="card-title mt-3">
                                  Segment Billing Details By Account
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-end align-items-center"></div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          {loading ? (
                              <Loader height={"100%"}/>
                          ) : (
                              <>
                                {" "}
                                {allAccounts ? (
                                    <>
                                      <ToolkitProvider
                                          keyField="__id"
                                          bootstrap4={true}
                                          data={segmentsBilling}
                                          columns={columnsAllAccounts}
                                          search={boostrap2TableSearchOptions()}
                                          exportCSV={exportCSVOptions()}
                                      >
                                        {(props) => (
                                            <div className={"input-fields"}>
                                              <CSVLink
                                                  className="btn btn-primary float-right"
                                                  data={segmentFullReport}
                                                  filename={`SegmentReport_${moment(
                                                      startDate
                                                  ).format("YYYY-MM-DD")}_${moment(
                                                      endDate
                                                  ).format("YYYY-MM-DD")}.csv`}
                                              >
                                                <i className="fa fa-arrow-down"></i>Full Report </CSVLink>
                                              <ExportCSV {...props.csvProps} />

                                              <SearchBar
                                                  {...props.searchProps}
                                                  className="form-control form-control-custom"
                                              />
                                              <BootstrapTable
                                                  {...props.baseProps}
                                                  pagination={paginationFactory(
                                                      paginationOptions(segmentsBilling)
                                                  )}
                                                  filter={filterFactory()}
                                                  defaultSorted={defaultSortedAllAccounts}
                                                  bordered={false}
                                                  noDataIndication={noDataIndication}
                                                  defaultSortDirection="asc"
                                              />
                                            </div>
                                        )}
                                      </ToolkitProvider>
                                    </>
                                ) : (
                                    <>
                                      <ToolkitProvider
                                          keyField="__id"
                                          bootstrap4={true}
                                          data={segmentsBilling}
                                          columns={columnsSingleAccount}
                                          search={boostrap2TableSearchOptions()}
                                          exportCSV={exportCSVOptions()}
                                      >
                                        {(props) => (
                                            <div className={"input-fields"}>
                                              <ExportCSV {...props.csvProps} />
                                              <SearchBar
                                                  {...props.searchProps}
                                                  className="form-control form-control-custom"
                                              />
                                              <BootstrapTable
                                                  {...props.baseProps}
                                                  pagination={paginationFactory(
                                                      paginationOptions(segmentsBilling)
                                                  )}
                                                  filter={filterFactory()}
                                                  defaultSorted={defaultSortedSingleAccount}
                                                  bordered={false}
                                                  noDataIndication={noDataIndication}
                                              />
                                            </div>
                                        )}
                                      </ToolkitProvider>
                                    </>
                                )}
                              </>
                          )}
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header d-block">
                          <div className="row">
                            <div className="col-6">
                              <div className="d-flex justify-content-start align-items-center">
                                <div className="card-title mt-3">
                                  Attribution Billing Details By Account
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex justify-content-end align-items-center"></div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          {loading ? (
                              <Loader height={"100%"}/>
                          ) : (
                              <>
                                {" "}
                                {allAccounts ? (
                                    <>
                                      <ToolkitProvider
                                          keyField="__id"
                                          bootstrap4={true}
                                          data={attributionsBilling}
                                          columns={columnsAllAttrAccounts}
                                          search={boostrap2TableSearchOptions()}
                                          exportCSV={exportCSVOptions()}
                                      >
                                        {(props) => (
                                            <div className={"input-fields"}>
                                              <CSVLink
                                                  className="btn btn-primary float-right"
                                                  data={attrFullReport}
                                                  filename={`AttributionReport_${moment(
                                                      startDate
                                                  ).format("YYYY-MM-DD")}_${moment(
                                                      endDate
                                                  ).format("YYYY-MM-DD")}.csv`}
                                              >
                                                <i className="fa fa-arrow-down"></i>Full Report
                                              </CSVLink>
                                              <ExportCSV {...props.csvProps} />
                                              <SearchBar
                                                  {...props.searchProps}
                                                  className="form-control form-control-custom"
                                              />
                                              <BootstrapTable
                                                  {...props.baseProps}
                                                  pagination={paginationFactory(
                                                      paginationOptions(attributionsBilling)
                                                  )}
                                                  filter={filterFactory()}
                                                  defaultSorted={defaultSortedAttrAllAccounts}
                                                  bordered={false}
                                                  noDataIndication={noDataIndication}
                                              />
                                            </div>
                                        )}
                                      </ToolkitProvider>
                                    </>
                                ) : (
                                    <>
                                      <ToolkitProvider
                                          keyField="__id"
                                          bootstrap4={true}
                                          data={attributionsBilling}
                                          columns={columnsSingleAttrAccount}
                                          search={boostrap2TableSearchOptions()}
                                          exportCSV={exportCSVOptions()}
                                      >
                                        {(props) => (
                                            <div className={"input-fields"}>
                                              <ExportCSV {...props.csvProps} />
                                              <SearchBar
                                                  {...props.searchProps}
                                                  className="form-control form-control-custom"
                                              />
                                              <BootstrapTable
                                                  {...props.baseProps}
                                                  pagination={paginationFactory(
                                                      paginationOptions(attributionsBilling)
                                                  )}
                                                  filter={filterFactory()}
                                                  defaultSorted={
                                                    defaultSortedAttrSingleAccount
                                                  }
                                                  bordered={false}
                                                  noDataIndication={noDataIndication}
                                              />
                                            </div>
                                        )}
                                      </ToolkitProvider>
                                    </>
                                )}
                              </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
        }
      </>
  );
}

export default Billing;
