import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const openxConfig = {
    ...COMPANY_BASE_PROPERTIES,
    /**
     * User Readable name of company the user is onBoarding with
     */
    companyText: "Onboarding With OpenX",

    /**
     * lowercase no spaces or underscores name of company
     * This will be passed to the activate model and
     * used when saving to create the correct submission url
     */
    company: "openx",

    /**
     * HTML that explains how to use the form
     */
    instructions:
        "You have selected a platform that automatically reports usage for targeting. You will be billed for data usage by the platform unless the segment is applied for suppression purposes. Suppression segments are billed by Semcasting directly based on the discounted deployed record formula. Activating this audience means you agree to this billing method.",

    /**
     * data used to create a company activation block
     */
    companyBlockOptions: {
        val: "openx",
        text: "OpenX",
        img: "openx-logo.svg",
    },

    /**
     * anchor tag used to login to a specific platform
     */
    loginLink:
        '<a href="https://sso.openx.com/login/login" target="_blank">Log in to OpenX</a>',

    layout: [
        {
            fieldType: "checkboxGroup",
            labelText: "Devices to Activate",
            fieldName: "device_type",
            required: false,
            checkboxes: [
                {
                    labelText: "OpenX ID",
                    fieldName: "CLIENT_ID",
                    value: "CLIENT_ID",
                    checked: true,
                    disabled: false,
                },
                {
                    labelText: "Hashed Emails",
                    fieldName: "hashed_emails",
                    value: "hashed_emails_sha256",
                    checked: true,
                    disabled: false,
                },
            ],
        },
        {
            fieldType: "radioGroup",
            labelText: "Mobile Devices",
            fieldName: "mobile_devices",
            required: true,
            radioButtons: [
                {
                    labelText: "Top Devices",
                    fieldName: "top_devices",
                    value: "top_ios_android",
                    checked: true,
                },
                {
                    labelText: "All Devices",
                    fieldName: "all_devices",
                    value: "ios_android",
                    checked: false,
                },
                {
                    labelText: "No Devices",
                    fieldName: "no_devices",
                    value: "no_devices",
                    checked: false,
                },
            ],
        },
        {
            fieldType: "textField",
            fieldName: "deal_name",
            labelText: "Deal Name",
            required: true,
        },
        {
            fieldType:'selectPickerG',
            fieldName:'demand_partners',
            labelText:'Demand Partners',
            defaultOption:'Select Product',
            options:[
                {
                    text: "The Trade Desk - RTB",
                    val: "the_trade_desk_rtb"
                },
                {
                    text: "DV360 - RTB",
                    val: "dv360_rtb"
                },
                {
                    text: "Amazon DSP - RTB",
                    val: "amazon_dsp_rtb"
                },
                {
                    text: "Yahoo DSP - RTB",
                    val: "yahoo_dsp_rtb"
                },
                {
                    text: "Viant - RTB",
                    val: "viant_rtb"
                },
                {
                    text: "Simpli.fi - RTB",
                    val: "simpli_fi_rtb"
                },
                {
                    text: "Xandr - RTB",
                    val: "xandr_rtb"
                },
                {
                    text: "Roku - RTB",
                    val: "roku_rtb"
                },
                {
                    text: "Basis DSP - RTB",
                    val: "basis_dsp_rtb"
                },
                {
                    text: "Beeswax - RTB",
                    val: "beeswax_rtb"
                },
                {
                    text: "AdTheorent - RTB",
                    val: "adtheorent_rtb"
                },
                {
                    text: "Zeta Global - RTB",
                    val: "zeta_global_rtb"
                },
                {
                    text: "PulsePoint - RTB",
                    val: "pulsepoint_rtb"
                },
                {
                    text: "DeepIntent - RTB",
                    val: "deepintent_rtb"
                },
                {
                    text: "Bidswitch - RTB",
                    val: "bidswitch_rtb"
                },
                {
                    text: "Adobe Advertising Cloud - RTB",
                    val: "adobe_advertising_cloud_rtb"
                },
                {
                    text: "RTB House - RTB",
                    val: "rtb_house_rtb"
                },
                {
                    text: "VDX.tv - RTB",
                    val: "vdx_tv_rtb"
                },
                {
                    text: "Quantcast - RTB",
                    val: "quantcast_rtb"
                },
                {
                    text: "Nexxen - RTB",
                    val: "nexxen_rtb"
                },
                {
                    text: "Epsilon - RTB",
                    val: "epsilon_rtb"
                },
                {
                    text: "MadHive - RTB",
                    val: "madhive_rtb"
                },
                {
                    text: "Adform - RTB",
                    val: "adform_rtb"
                },
                {
                    text: "Acuity Ads - RTB",
                    val: "acuity_ads_rtb"
                },
                {
                    text: "Media.net - RTB",
                    val: "media_net_rtb"
                },
                {
                    text: "Vericast - RTB",
                    val: "vericast_rtb"
                },
                {
                    text: "SundaySky - RTB",
                    val: "sundaysky_rtb"
                },
                {
                    text: "GroundTruth - RTB",
                    val: "groundtruth_rtb"
                },
                {
                    text: "StackAdapt - RTB",
                    val: "stackadapt_rtb"
                },
                {
                    text: "AdRoll - RTB",
                    val: "adroll_rtb"
                },
                {
                    text: "Thinknear - RTB",
                    val: "thinknear_rtb"
                },
                {
                    text: "Blis - RTB",
                    val: "blis_rtb"
                },
                {
                    text: "Equativ - RTB",
                    val: "equativ_rtb"
                },
                {
                    text: "Remerge - RTB",
                    val: "remerge_rtb"
                },
                {
                    text: "So-net Media Networks (Logicad)",
                    val: "so_net_media_networks"
                },
                {
                    text: "4INFO - RTB",
                    val: "4info_rtb"
                },
                {
                    text: "Clickagy - RTB",
                    val: "clickagy_rtb"
                },
                {
                    text: "Sqreem - RTB",
                    val: "sqreem_rtb"
                },
                {
                    text: "Arpeely RTB",
                    val: "arpeely_rtb"
                },
                {
                    text: "Unicorn - RTB",
                    val: "unicorn_rtb"
                },
                {
                    text: "Affle - RTB",
                    val: "affle_rtb"
                },
                {
                    text: "Chalk Digital (US) - RTB",
                    val: "chalk_digital_rtb"
                },
                {
                    text: "Eskimi - RTB",
                    val: "eskimi_rtb"
                },
                {
                    text: "Adot - RTB",
                    val: "adot_rtb"
                },
                {
                    text: "Account Insight - RTB",
                    val: "account_insight_rtb"
                },
                {
                    text: "MicroAd (UNIVERSE Ads) - RTB",
                    val: "microad_rtb"
                },
                {
                    text: "TapTap - RTB",
                    val: "taptap_rtb"
                },
                {
                    text: "Opera - RTB",
                    val: "opera_rtb"
                },
                {
                    text: "Knorex - RTB",
                    val: "knorex_rtb"
                },
                {
                    text: "Appier - RTB",
                    val: "appier_rtb"
                },
                {
                    text: "ADMATRIX - RTB",
                    val: "admatrix_rtb"
                },
                {
                    text: "Splicky USD - RTB",
                    val: "splicky_usd_rtb"
                },
                {
                    text: "FreakOut - RTB",
                    val: "freakout_rtb"
                },
                {
                    text: "Supership (ScaleOut) - RTB",
                    val: "supership_rtb"
                },
                {
                    text: "Sift Media - RTB",
                    val: "sift_media_rtb"
                },
                {
                    text: "Mediasmart - RTB",
                    val: "mediasmart_rtb"
                },
                {
                    text: "EASYmedia - RTB",
                    val: "easymedia_rtb"
                },
                {
                    text: "CDK Global - RTB",
                    val: "cdk_global_rtb"
                },
                {
                    text: "Recruit Communications - RTB",
                    val: "recruit_communications_rtb"
                },
                {
                    text: "Bypass - RTB",
                    val: "bypass_rtb"
                },
                {
                    text: "Yeahmobi - RTB",
                    val: "yeahmobi_rtb"
                },
                {
                    text: "Doceree - RTB",
                    val: "doceree_rtb"
                },
                {
                    text: "Cinarra Japan - RTB",
                    val: "cinarra_japan_rtb"
                },
                {
                    text: "Chalk Digital - RTB",
                    val: "chalk_digital_rtb"
                },
                {
                    text: "Axel Mark - RTB",
                    val: "axel_mark_rtb"
                },
                {
                    text: "Hybe.io - RTB",
                    val: "hybe_io_rtb"
                },
                {
                    text: "Leadsmatic - RTB",
                    val: "leadsmatic_rtb"
                },
                {
                    text: "NHN Ace - RTB",
                    val: "nhn_ace_rtb"
                },
                {
                    text: "Znepo DSP - RTB",
                    val: "znepo_dsp_rtb"
                },
                {
                    text: "Smarter Mobi - RTB",
                    val: "smarter_mobi_rtb"
                },
                {
                    text: "Advlion - RTB",
                    val: "advlion_rtb"
                }
            ],
            required: true
        },
        {
            fieldType: "textField",
            fieldName: "buyer_id",
            labelText: "Demand Partner Buyer ID",
            required: true,
        },
        {
            fieldType: "dateRange",
            fieldName: "campaign_range",
            labelText: "Campaign Date Range",
            required: "dateRangeRequired",
        },
        {
            fieldType: "radioGroup",
            labelText: "Planned Usage of this Segment:",
            fieldName: "audience_usage",
            required: true,
            radioButtons: [
                {
                    labelText: "Targeting",
                    fieldName: "use_type_inclusion",
                    value: "I",
                    checked: false,
                },
                {
                    labelText: "Suppression",
                    fieldName: "use_type_exclusion",
                    value: "X",
                    checked: false,
                },
            ],
        },
        {
            fieldType: "selectPickerTag",
            fieldName: "creativeTagIdDropDown",
            labelText: "Creative Tag ID",
            url: "/v2/secure/attributor/creative-tags",
            placeholder: "Select a New or existing Tag for your creative",
            namePropName: "creativeTagId",
            textPropName: "creativeTagId",
            valuePropName: "creativeTagId",
            required: true,
        },
        {
            fieldType: "creativeTagId",
            fieldName: "creativeTagId",
            labelText: "Creative Tag ID",
            required: true,
        },
        {
            fieldType: "creativeTag",
            fieldName: "creativeTag",
            labelText: "Creative Tag",
            required: true,
        },
        {
            fieldType: "pricing",
        },
    ],

    /**
     * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
     */
    savableDataNames: [],

    formik: {
        initialValues: {
            device_type: ["CLIENT_ID", "hashed_emails_sha256"],
            mobile_devices: "top_ios_android",
            campaign_range_start: "",
            campaign_range_end: "",
            audience_usage: "",
            creativeTagIdDropDown: "",
            creativeTagId:"",
            deal_name:"",
            demand_partners: "",
            buyer_id: ""
        },
        // We used Yup here for data validation and condition.
        validationSchema: Yup.object().shape({
            mobile_devices: Yup.string().required("Mobile Devices is required"),
            deal_name: Yup.string().required("Deal Name is required").max(515, "Deal Name should not exceed 515 characters"),
            demand_partners: Yup.string().required("Demand Partner is required").max(80, "Deal Name should not exceed 515 characters"),
            buyer_id: Yup.string().required("Demand Partner Buyer ID is required").max(128, "Demand Partner Buyer ID should not exceed 128 characters"),
            campaign_range_start: Yup.date().required(
                "Campaign Date Range Start Date is required"
            ),
            campaign_range_end: Yup.date().required(
                "Campaign Date Range End Date is required"
            ),
            audience_usage: Yup.string().required("Planned Usage is required"),
            creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
            creativeTagId: Yup.string().required("Creative Tag ID is required")
        }),
        onSubmit: (values) => {},
    },
};
