import {
  semcastAxios,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../Utils/Common";
import {
  AUDIENCE_FILE,
  AUDIENCE_FILE_GEO,
  FILE_TYPE_UNKNOWN,
  FROM_FILE,
  FTP_NAV,
  FTP_SETTINGS, FTP_SETTINGS_NON_MATCHED,
  FTP_STRING,
  GEO,
  GOOGLE_MAPS_API_KEY,
  LOCATION_POINTS,
  SOMETHING_WENT_WRONG,
  SUCCESS_STATUS,
} from "../api/constants";
import axios from "axios";

let cancelTokenSource = null;

export const postAudienceFile = async (formData) => {
  cancelTokenSource = axios.CancelToken.source();
  try {
    let response = await semcastAxios.post(AUDIENCE_FILE, formData, {
      withCredentials: true,
      cancelToken: cancelTokenSource.token,
    });
    if (response.data.status === SUCCESS_STATUS) {
      return response.data;
    }
  } catch ({ response }) {
    if (response?.data.data === FILE_TYPE_UNKNOWN) {
      return response.data.data;
    } else if (response.data) {
      return response.data;
    }
  }
};

export const patchAudienceFile = async (formData, id, test = false) => {
  try {
    let response = await semcastAxios.patch(
      AUDIENCE_FILE + "/" + id + (test ? "?test=true" : ""),
      formData,
      {
        withCredentials: true,
        cancelToken: cancelTokenSource.token,
      }
    );
    if (response.data.status === SUCCESS_STATUS) {
      return response.data || null;
    }
  } catch {
    showErrorToastMessage(SOMETHING_WENT_WRONG);
    return null;
  }
};

export const cancelFileUpload = () => {
  cancelTokenSource.cancel();
};

export const postFromFile = async (payload) => {
  try {
    let response = await semcastAxios.post(FROM_FILE, payload, {
      withCredentials: true,
    });
    if (response.data.status === SUCCESS_STATUS) {
      showSuccessToastMessage("Segment Creation In Progress ...");
      return response.data || null;
    }
  } catch(error) {
    showErrorToastMessage(error.response.data.data);
    return null;
  }
};

export const getFTPSettings = async () => {
  try {
    let response = await semcastAxios.get(FTP_SETTINGS, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.data.status === SUCCESS_STATUS) {
      return response.data || { data: [] };
    }
  } catch {
    return { data: [] };
  }
};

export const getFTPSettingsNonMatched = async () => {
  try {
    let response = await semcastAxios.get(FTP_SETTINGS_NON_MATCHED, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.data.status === SUCCESS_STATUS) {
      return response.data || { data: [] };
    }
  } catch {
    return { data: [] };
  }
};

export const testNewConnection = async (payload,
                                        protocolType = FTP_STRING,
                                        editDialogSubmit = false) => {
  try {
    let response = await semcastAxios.post(
      FTP_SETTINGS + "/test-connection",
      payload,
      {
        withCredentials: true,
      }
    );
    if (response.data.status === SUCCESS_STATUS) {
      if(!editDialogSubmit) showSuccessToastMessage(`Success! Connection to your ${protocolType} server was successful.`);
      return response.data.id;
    }
  } catch (e) {
    if(editDialogSubmit) {
      showErrorToastMessage(`Error: Connection details not saved. Connection to your ${protocolType} server was unsuccessful.`);
    } else {
      showErrorToastMessage(`Error: Connection to your ${protocolType} server was unsuccessful.`);
    }

    // showErrorToastMessage(
    //   translateMessage(e?.response?.data?.data) || SOMETHING_WENT_WRONG
    // );
    return null;
  }
};

export const testExistingConnection = async (payload, selectedRedion,
  protocolType = FTP_STRING,
  editDialogSubmit = false
) => {
  try {
    let response = await semcastAxios.put(
      FTP_SETTINGS + "/test-connection",
      payload,
      {
        withCredentials: true,
      }
    );
    if (response.data.status === SUCCESS_STATUS) {
      if(!editDialogSubmit) showSuccessToastMessage(`Success! Connection to your ${protocolType} server was successful.`);
      return response.data.id;
    }
  } catch (e) {
    if(editDialogSubmit) {
      showErrorToastMessage(`Error: Connection details not saved. Connection to your ${protocolType} server was unsuccessful.`);
    } else {
      showErrorToastMessage(`Error: Connection to your ${protocolType} server was unsuccessful.`);
    }
    return null;
  }
};

export const saveSettings = async (payload, connName="Connection") => {
  try {
    let response = await semcastAxios.post(FTP_SETTINGS, payload, {
      withCredentials: true,
    });
    if (response.data.status === SUCCESS_STATUS) {
      // showSuccessToastMessage("Success! Your details have been updated");
      showSuccessToastMessage(`Success! ${connName} was created`);
      return response.data || null;
    }
  } catch (e) {
    // showErrorToastMessage(
    //   translateMessage(e?.response?.data?.data) || SOMETHING_WENT_WRONG
    // );
    showErrorToastMessage(`Error: ${connName} not created`);
    return null;
  }
};

export const updateSettings = async (payload, id, connName="Connection") => {
  try {
    console.log("UpdateSettingsPayload=", payload);
    let response = await semcastAxios.put(FTP_SETTINGS + "/" + id, payload, {
      withCredentials: true,
    });
    if (response.data.status === SUCCESS_STATUS) {
      // showSuccessToastMessage("Success! Your details have been updated");
      showSuccessToastMessage(`Success! ${connName} was updated`);
      return response.data || null;
    }
  } catch (e) {
    // showErrorToastMessage(
    //   translateMessage(e?.response?.data?.data) || SOMETHING_WENT_WRONG
    // );
    showErrorToastMessage(`Error: ${connName} not updated`);
    return null;
  }
};

export const deleteSettings = async (id, connName="Connection") => {
  try {
    let response = await semcastAxios.delete(FTP_SETTINGS + "/" + id, {
      withCredentials: true,
    });
    if (response.data.status === SUCCESS_STATUS) {
      showSuccessToastMessage(`Success! ${connName} was deleted`);
      return response.data || null;
    }
  } catch {
    showErrorToastMessage(`Error: ${connName} not deleted.`);
    return null;
  }
};

const translateMessage = (msg) => {
  try {
    let lcMessage = msg.toLowerCase();
    if (msg === "Unknown Host") {
      return "Connection Failed! The Host Site is not reachable at the moment.";
    } else if (lcMessage.indexOf("timeout") > -1) {
      return "Connection Failed! Ensure the port number is correct, and try changing between ftp and ftps or between Active and Passive Transfer Modes.";
    } else if (lcMessage.indexOf("authentication") > -1) {
      return "Connection Failed! Invalid User Name or Password.";
    }
    return msg;
  } catch (e) {
    return null;
  }
};
const params = {
  draw: 1,
  columns: [
    {
      data: "weight",
      name: null,
      searchable: true,
      orderable: false,
      search: { value: null, regex: false },
    },
    {
      data: 1,
      name: null,
      searchable: true,
      orderable: true,
      search: { value: null, regex: false },
    },
    {
      data: "name",
      name: null,
      searchable: true,
      orderable: true,
      search: { value: null, regex: false },
    },
    {
      data: "modified_date",
      name: null,
      searchable: true,
      orderable: true,
      search: { value: null, regex: false },
    },
    {
      data: "size",
      name: null,
      searchable: true,
      orderable: true,
      search: { value: null, regex: false },
    },
  ],
  order: [
    { column: 0, dir: "asc" },
    { column: 3, dir: "desc" },
  ],
  start: 0,
  length: -1,
  search: {
    value: null,
    regex: false,
  },
  _: null,
  // path:'/Mediamint'
};
export const drawFileBrowser = async (id, fileBrowserParams) => {
  try {
    let response = await semcastAxios.get(
      `${FTP_NAV}/${id}/list?${fileBrowserParams}`,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (response.data.status === SUCCESS_STATUS) {
      return response.data || { data: {} };
    }
  } catch ({ response }) {
    if (response?.data) {
      return response.data;
    }
    return { data: {} };
  }
};

export const geoCode = async(lat, lng, callback) => {
    try {
        let response = await semcastAxios.get(GEO, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          params: {
            latlng:lat+','+lng,
            result_type:'postal_code',
            key:GOOGLE_MAPS_API_KEY
          },
        });
      if (response.data.status === "OK") {
          if (callback && (typeof callback) === 'function') {
              callback(response.data);
          }
      } else {
          return response;
      }
    } catch {
        return { data: {} };
    }
}

export const postLocationsForPoints = async (formData) => {
  try {
    let response = await semcastAxios.post(LOCATION_POINTS, formData, {
      withCredentials: true,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch {
    return { data: {} };
  }
}

export const postAudienceFileGeo = async (formData) => {
  try {
    let response = await semcastAxios.post(AUDIENCE_FILE_GEO, formData, {
      withCredentials: true,
    });
    if (response.data.status === SUCCESS_STATUS) {
      return response.data;
    }
  } catch {
    return { data: {} };
  }
};

export const deleteAudienceFileGeo = async(id) => {
    try {
        let response = await semcastAxios.delete(AUDIENCE_FILE + "/" + id, {
            withCredentials: true,
        })
        if (response.data.status === SUCCESS_STATUS) {
            return response.data;
        }
    } catch {
        return { data: {} };
    }
}
