import {NavLink} from "react-router-dom";
import React from "react";

import "./LandingPage.css";
import iabLogo from "../assets/img/iab_new.png";
import trustwaveLogo from "../assets/img/trustwave_new.png";
import dmaLogo from "../assets/img/dma_new.png";
// import trusteLogo from "../assets/img/trusteLogo.svg";

const LandingPageFooter = () => {

    return (
        <div className={"landing-page-footer-wrapper"}>

            <div className={"landing-page-footer"}>
                <div className={"footer-links-left-wrapper"}>
                    <div className="footer-links-left">
                        <a href="http://www.semcasting.com/" target="_blank" rel="noreferrer" className={"footer-link left"}> 2024 Semcasting Inc. </a>
                        &nbsp; | &nbsp;
                        {/*<a href="/privacy-policy" id="privacy-policy" className={"footer-link left"}>Privacy Policy</a>*/}
                        {/*&nbsp; | &nbsp;*/}
                        <NavLink className="footer-link left" to="/privacy-policy">
                            Privacy Policy
                        </NavLink>
                        &nbsp; | &nbsp;
                        {/*Hide Contact US for FD#10814 until new API is ready*/}
                        {/*<NavLink className="footer-link left" to="/contact-us">*/}
                        {/*    Contact Us*/}
                        {/*</NavLink>*/}
                        {/*&nbsp; | &nbsp;*/}
                        {/*<NavLink className="footer-link left" to="/">*/}
                        {/*    Login*/}
                        {/*</NavLink>*/}



                        {/*{contactPage ? (*/}
                        {/*    <NavLink className="footer-link left" to="/">*/}
                        {/*        Home*/}
                        {/*    </NavLink>*/}
                        {/*) : (*/}
                        {/*    <NavLink className="footer-link left" to="/contact">*/}
                        {/*        Contact Us*/}
                        {/*    </NavLink>*/}
                        {/*)}*/}




                        {/*{contactPage ? (*/}
                        {/*    <NavLink className="footer-link left" to="/">*/}
                        {/*        Home*/}
                        {/*    </NavLink>*/}
                        {/*) : (*/}
                        {/*    <NavLink className="footer-link left" to="/contact">*/}
                        {/*        Contact Us*/}
                        {/*    </NavLink>*/}
                        {/*)}*/}

                    </div>
                </div>

                <div className={"footer-links-right"}>
                    <a href="https://www.iab.net/" target="_blank" rel="noreferrer" className={"footer-link"}><img src={iabLogo} alt="Semcasting Partner"/></a>
                    <a href="https://sealserver.trustwave.com/cert.php?customerId=w6oj3BWiehnnpWYB1c2daih5HPZgvC" target="_blank" rel="noreferrer" className={"footer-link"}><img src={trustwaveLogo} alt="Semcasting Partner"/></a>
                    <a href="https://thedma.org/" target="_blank" rel="noreferrer" className={"footer-link"}><img src={dmaLogo} alt="Semcasting Partner"/></a>
                    {/*<a href="//privacy.truste.com/privacy-seal/validation?rid=6aec5eb7-6d9f-4a4c-9d6d-46bc9f39c572" target="_blank" rel="noreferrer" className={"footer-link"}>*/}
                    {/*    <img src={trusteLogo}  alt="TRUSTe"/>*/}
                    {/*</a>*/}
                    <a href="//privacy.trustarc.com/privacy-seal/validation?rid=6aec5eb7-6d9f-4a4c-9d6d-46bc9f39c572" target="_blank"><img style={{border: "none"}} src="//hostedseal.trustarc.com/privacy-seal/seal?rid=6aec5eb7-6d9f-4a4c-9d6d-46bc9f39c572" alt="TRUSTe"/></a>
                </div>
            </div>
        </div>
    )

}


export default LandingPageFooter;