export const SegmentTopRightBlockActions = Object.freeze({
    MERGE:   Symbol("MERGE"),
    APPENDREPLACE:   Symbol("APPENDREPLACE"),
    SPLIT:  Symbol("SPLIT"),
    COPY: Symbol("COPY"),
    LOOK_LIKE: Symbol("LOOK_LIKE"),
    DELETE: Symbol("DELETE"),
    DEACTIVATE: Symbol("DEACTIVATE"),
    IDENTITY: Symbol("IDENTITY"),
    SYNCHRONIZE: Symbol("SYNCHRONIZE"),
    IDENTITYIDX: Symbol("IDENTITYIDX"),
});