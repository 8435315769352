import React, {useEffect, useState} from "react";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import {SEGMENT_SPLIT_API, SUCCESS_STATUS} from "../../../api/constants";
import "./SplitSegment.css";
import {Label} from "@progress/kendo-react-labels";

const SplitSegment = (props) => {
    // const split_segment = [...props.data.split_segment];

    const segmentsNumber = [
        {
            text: "2",
            id: 2,
        },
        {
            text: "3",
            id: 3,
        },
        {
            text: "4",
            id: 4,
        },
        {
            text: "5",
            id: 5,
        }
    ];

    // Styles specific to this dialog

    const percentageErrorStyle = {
        color: "#00424d",
        marginRight: "30rem"
    }

    const segmentNameErrorStyle = {
        color:"#00424d",
        paddingTop: ".1rem",
        fontSize: ".8rem"
    }

    const borderColorError = {
        borderColor: "#00424d"
    }

    const borderColorNone = {
        borderColor: ""
    }

    const paddingTop = {
        paddingTop: "1rem"
    }

    const displayNone = {
        display: "none"
    }

    const marginLeft = {
        marginLeft: "1rem"
    }

    const paddingRight = {
        paddingRight: "1rem"
    }

    const labelFontSize = {
        fontSize: "1.1rem"
    }

    // let split_segment = props.splitSegment.profileFormik.values.selectedSegments;
    // const segmentName = split_segment[0].segment_name;
    // const segmentId = split_segment[0].id;
    // const groupId = split_segment[0].group_id;
    const suffixValues = ["SS_1", "SS_2", "SS_3", "SS_4", "Holdout"];
    // const segmentName = props.splitSegment.profileFormik.values.selectedSegments[0].segment_name;
    const segmentName = props.segName;
    // const segmentId = props.splitSegment.profileFormik.values.selectedSegments[0].id;
    const segmentId = props.segId;
    // const groupId = props.splitSegment.profileFormik.values.selectedSegments[0].group_id;
    const groupId = props.groupId;

    // State used to manage styles and values

    const [pageLoader, setPageLoader] = useState(false)
    const [visible, setVisible] = useState(props.visible);
    const [messages, setMessages] = useState('', '', '', '', '');
    const [segmentNameValues, setSegmentNameValues] = useState([segmentName+'_'+suffixValues[0], segmentName+'_'+suffixValues[1], segmentName+'_'+suffixValues[2], segmentName+'_'+suffixValues[3], segmentName+'_'+suffixValues[4]]);
    const [displayValues, setDisplayValues] = useState([{...paddingTop}, {...displayNone}, {...displayNone}, {...displayNone}, {...paddingTop}]);
    const [percentageValues, setPercentageValues] = useState([50, 0, 0, 0, 50]);
    const [numberOfSegments, setNumberOfSegments] = useState({text: "2", id: 2});
    const [percentMsgStyle, setPercentMsgStyle] = useState({...displayNone});
    const [displayBorderErrors, setDisplayBorderErrors] = useState([{...borderColorNone}, {...borderColorNone}, {...borderColorNone}, {...borderColorNone}, {...borderColorNone}])
    const [displayErrors, setDisplayErrors] = useState([{...displayNone}, {...displayNone}, {...displayNone}, {...displayNone}, {...displayNone}]);
    const [disableSave, setDisableSave] = useState(false);
    const [percentHundred, setPercentHundred] = useState(true);

    const regex = RegExp(/^[a-zA-Z0-9_\- ]{3,64}$/);
    const REQUIRED_MESSAGE = "Name must be 3-64 chars containing letters, numbers, hyphens, spaces and underscores."
    const SEGMENT_NAME_UNIQUE_MESSAGE= "Segment name(s) must be unique.";
    const handleSegmentNumberChange = (event) => {
        const newNumberOfSegments = {...event.target.value};
        const percentValue = Math.trunc(100/newNumberOfSegments.id);
        const remainder = 100 % newNumberOfSegments.id;
        const totalSegments = 5;
        const newDisplayValues = [...displayValues];
        const newPercentageValues = [...percentageValues];
        for (let i=0; i<totalSegments; i++) {
            if (i < newNumberOfSegments.id-1) {
                newDisplayValues[i] = {...paddingTop};
                newPercentageValues[i] = percentValue;
            } else if (i === totalSegments-1) {
                newDisplayValues[i] = {...paddingTop};
                newPercentageValues[i] = percentValue + remainder;
            } else {
                newDisplayValues[i] = {...displayNone};
            }
        }
        setNumberOfSegments(newNumberOfSegments);
        setDisplayValues(newDisplayValues);
        setPercentageValues(newPercentageValues);
        setPercentHundred(true);
        setDisableSave(false);
    };

    const handlePercentageChange = (event) => {
        const newPercentageValue = event.target.value;
        const newPercentageValues = [...percentageValues];
        newPercentageValues[event.target.name] = newPercentageValue;
        setPercentageValues(newPercentageValues);
        const sum = newPercentageValues.reduce((curr, next) => curr + next);
        if (sum !== 100) {
            setPercentHundred(false);
            setPercentMsgStyle({...percentageErrorStyle});
            setDisableSave(true);
        } else {
            setPercentHundred(true);
            setPercentMsgStyle({...displayNone});
            if (_segmentNameErrors()) {
                setDisableSave(true);
            } else {
                setDisableSave(false);
            }
        }
    };

    const handleSegmentNameChange = (event) => {
        const value = event.value;
        const newDisplayErrors = [...displayErrors];
        const newDisplayBorderErrors = [...displayBorderErrors];
        const newMessages = [...messages];
        let idx = parseInt(event.target.name.charAt(event.target.name.length-1));
        const newSegmentNameValues = [...segmentNameValues];
        newSegmentNameValues[idx] = value;

        if (!regex.test(value)) {
            _setSegmentNameErrorState(newDisplayErrors, newDisplayBorderErrors, newMessages, REQUIRED_MESSAGE, idx);
            return;
        }

        if (segmentNameValues.includes(value)) {
            _setSegmentNameErrorState(newDisplayErrors, newDisplayBorderErrors, newMessages, SEGMENT_NAME_UNIQUE_MESSAGE, idx);
            setSegmentNameValues(newSegmentNameValues);
            return;
        }
        _clearSegmentNameErrorState(newDisplayErrors, newDisplayBorderErrors, newMessages, idx)
        setSegmentNameValues(newSegmentNameValues);
        if (_percentageError()) {
            setDisableSave(true);
        } else {
            setDisableSave(false);
        }
    };

    const _setSegmentNameErrorState = (newDisplayErrors, newDisplayBorderErrors, newMessages, message, idx) => {
        newDisplayErrors[idx] = {...segmentNameErrorStyle};
        setDisplayErrors(newDisplayErrors);
        newDisplayBorderErrors[idx] = {...borderColorError};
        setDisplayBorderErrors(newDisplayBorderErrors);
        newMessages[idx] = message;
        setMessages(newMessages);
        setDisableSave(true);
    };

    const _clearSegmentNameErrorState = (newDisplayErrors, newDisplayBorderErrors, newMessages, idx) => {
        newDisplayErrors[idx] = {...displayNone};
        setDisplayErrors(newDisplayErrors);
        newDisplayBorderErrors[idx] = {...borderColorNone};
        setDisplayBorderErrors(newDisplayBorderErrors);
        newMessages[idx] = '';
        setMessages(newMessages);
        displayBorderErrors[idx] = {...borderColorNone};
    };

    const _segmentNameErrors = () => {
        const errorCnt = displayErrors.filter(style => style.display !== displayNone.display);
        if (errorCnt.length > 0) return true;
        else return false;
    };

    const _percentageError = () => {
        return percentMsgStyle.display !== displayNone.display;
    };

    useEffect(() => {
    }, [])

    const _createPayload = () => {
        let audienceDetails = [];
        for (let i=0; i<displayValues.length; i++) {
            if (!displayValues[i].hasOwnProperty("paddingTop")) continue;
            let percentSegment = {};
            percentSegment.percentage = percentageValues[i];
            percentSegment.name = segmentNameValues[i];
            audienceDetails.push(percentSegment);
        }

        const payload = {
            segment_id: segmentId,
            segment_name: segmentName,
            group_id: groupId,
            audience_details: audienceDetails
        };
        return payload;
    };

    const splitSegment = async () => {
        setPageLoader(true)
        try {
            const payload = _createPayload();
            let response = await semcastAxios.post(
                SEGMENT_SPLIT_API,
                payload,
                {
                    withCredentials: true,
                }
            );
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Segment Split Request Sent.");
                props.reloadAfterSplit();
            }
        } catch (error) {
            showErrorToastMessage("Segment Split Failed.");
        } finally {
            setPageLoader(false)
        }
        //setVisible(false);
        //props.handleSplit();
    }

    return (
        <>
            <div id="splitSegment">
                {visible && (<Dialog
                    title={"Split Audience"}
                    width={800}
                    closeIcon={false}
                    className={"dialog-standard"}
                >
                    {/*<div style={{paddingTop:"1.2rem"}}>*/}
                    <div>
                        <Label
                            className={"field-label"}
                            style={{paddingRight: "1.8rem"}}
                        >
                            Choose Number of Segments
                            {/*<span className="required-field">*</span>*/}
                        </Label>

                        {/*<label htmlFor="numberOfSegments" className="k-radio-label" style={{...marginLeft, ...paddingRight, ...labelFontSize}}>*/}
                        {/*    <b>Choose Number of Segments</b>*/}
                        {/*    <span class="text-danger">*</span>*/}
                        {/*</label>*/}

                        <DropDownList
                            style={{width: "3.5rem"}}
                            data={segmentsNumber}
                            textField="text"
                            dataItemKey="id"
                            value={numberOfSegments}
                            onChange={handleSegmentNumberChange}
                            className={"dropdown-standard"}
                        />
                    </div>
                    <div
                        style={{
                            paddingTop: "1.2rem",
                            paddingBottom: "0.5rem"
                            // fontSize: "14px"
                    }}
                    >
                        <div className={"split-audience-values-container"}>
                            <div className={"row"}>
                                <div className={"col-md-3"}>

                                    <Label
                                        className={"field-label"}
                                    >
                                        Set %
                                        {/*<span className="required-field">*</span>*/}
                                    </Label>



                                    {/*<label>*/}
                                    {/*    <b>Set %</b>*/}
                                    {/*    <span class="text-danger">*</span>*/}
                                    {/*</label>*/}
                                </div>
                                <div className={"col-md-9"}>
                                    <Label
                                        className={"field-label"}
                                    >
                                        Enter Segment Name
                                        {/*<span className="required-field">*</span>*/}
                                    </Label>


                                    {/*<b>Enter Segment Name</b>*/}
                                    {/*<span class="text-danger">*</span>*/}
                                </div>
                            </div>
                            {displayValues.map((item, index) => {
                                return (
                                    <div key={index} className={"row"} style={displayValues[index]}>
                                        <div className={"col-md-3"}>
                                            <NumericTextBox
                                                name={index}
                                                width={90}
                                                min={1}
                                                max={100}
                                                value={percentageValues[index]}
                                                onChange={handlePercentageChange}
                                            />
                                        </div>
                                        <div className={"col-md-9"}>
                                            <Input name={"SS_"+index} minLength={3} maxLength={64} defaultValue={segmentName+'_'+suffixValues[index]}
                                                    onChange={handleSegmentNameChange} style={displayBorderErrors[index]}/>
                                            <span name={"msg_"+index} style={displayErrors[index]}>{messages[index]}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {!percentHundred && (
                        <span id="msg"
                              style={{
                                  color: "#00424d",
                                  marginTop: "0.5rem",
                                  fontWeight: "600",
                              }}
                        >
                        Percent total must be 100
                    </span>
                    )}

                    {/*<span id="msg"*/}
                    {/*      style={{*/}
                    {/*          color: "#00424d",*/}
                    {/*          marginTop: "0.5rem"*/}
                    {/*      }}*/}
                    {/*>*/}
                    {/*    Percent total must be 100*/}
                    {/*</span>*/}

                    <DialogActionsBar layout={"end"}>
                        {/*<span id="msg" style={{...percentMsgStyle}}>Percent total must be 100</span>*/}
                        <Button
                            className="button-standard button-white"
                            onClick={() => {
                                props.cancelSubscriber(undefined)
                            }}>
                            Cancel
                        </Button>
                        <Button
                            className="button-standard button-submit"
                            disabled={disableSave}
                            onClick={splitSegment}>
                            Save
                        </Button>
                    </DialogActionsBar>
                </Dialog>)}
            </div>
        </>
    );
}

export default SplitSegment;
