import React, {useEffect, useState} from "react";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Label} from "@progress/kendo-react-labels";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import {Form, FormElement, FieldWrapper} from "@progress/kendo-react-form";
import {semcastAxios} from "../../../Utils/Common";
import {SEGMENTS_SUPPRESSION} from "../../../api/constants";

const AudienceSuppressions = (props) => {

    const [segmentOptions, setSegmentOptions] = useState([]);
    const [value, setValue] = useState([]);

    const onChangeSegmentName = (e) => {
        let values = [];
        setValue(e.value);
        e.value.forEach((val) => {
            values.push(val.value);
        });
        props.handleAudienceState(prevState => {
            return {...prevState, exclude_segment_id: values}}
        );
    };

    // Fetches segments to be included in options list for control
    useEffect(() => {
        async function fetchSegments() {
            // console.log("Research FetchSegments");
            await semcastAxios.get(SEGMENTS_SUPPRESSION, {
                withCredentials: true,
            }).then((res) => {
                if (res) {
                    let data = [];
                    res.data['segment-list'].forEach((obj) => {
                        data.push({
                            text: obj.segmentName,
                            value: obj.segment_id,
                        });
                    });
                    setSegmentOptions(data);
                }
            });
        }
        fetchSegments();
    }, []);

    // Reconstructs suppressed segments options from segment ids.
    useEffect(() => {
        let arr = [];
        if (props.audienceState && props.audienceState.exclude_segment_id &&
            props.audienceState.exclude_segment_id.length > 0) {
            props.audienceState.exclude_segment_id.forEach(segId => {
                if (segmentOptions && segmentOptions.length > 0) {
                    let option = segmentOptions.find(opt => {
                        return opt.value === segId;
                    })
                    // console.log(option);
                    arr.push(option);
                }
            });
        }
        setValue(arr);
    }, [props.audienceState, segmentOptions]);

    return (
        <div className={"about-audience-outer-div"}>
            <Card className={"about-audience-card"}>
                <CardTitle>
                    <Label className="section-label">AUDIENCE SUPPRESSIONS</Label>
                </CardTitle>
                <CardBody>
                    {/*<Targeting handleSubmit={handleSubmit} />*/}
                    <Form render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                        <legend className={'k-form-legend spacingLine'}></legend>
                        <div className={"about-audience-container"}>
                                <Form render={formRenderProps => <FormElement style={{
                                    width: "100%"
                                }}>
                                    <FieldWrapper >
                                        <Label className={"field-label"}>Suppression Segments</Label>
                                        <MultiSelect
                                            placeholder="Select Segments"
                                            data={segmentOptions}
                                            autoClose={false}
                                            textField={"text"}
                                            dataItemKey="value"
                                            popupSettings={{height: 150}}
                                            onChange={(e) => onChangeSegmentName(e)}
                                            value={value}
                                            size={"large"}
                                            style={{width: "100%", height: "2.2rem", borderRadius: "0.25rem"}}/>
                                    </FieldWrapper>
                                </FormElement>} />
                            </div>
                    </FormElement>} />
                </CardBody>
            </Card>
        </div>
    )
}

export default AudienceSuppressions;