import React, {useEffect, useState} from "react";
import { filterBy } from "@progress/kendo-data-query";
import {getNaicsCodes} from "../../../actions/AudienceDesignActions";
import {Label} from "@progress/kendo-react-labels";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import "./LegislativeDistricts.css";

const Naics = (props) => {

    const [value, setValue] = useState([]);

    const [naicsOptions, setNaicsOptions] = useState([]);
    const [allData, setAllData] = useState([]);
    const [segmentGroup, setSegmentGroup] = useState("");
    const [segmentCategory, setSegmentCategory] = useState("");
    const [segmentId, setSegmentId] = useState("");

    useEffect(() => {
        const queryParam = {};
        if (segmentCategory !== "") queryParam.q = segmentCategory;
        getNaicsCodes(queryParam).then((res) => {
            if (res) {
                let data = [];
                res.data.forEach((obj) => {
                    data.push({
                        text: obj.title,
                        value: obj.naics,
                    });
                });
                if (data.length > 1) {
                    let newData = data.slice();
                    newData.unshift({text:"Select All", value:"Select All"});
                    data = newData;
                }
                setNaicsOptions(data);
            }
        });
    }, [segmentCategory]);

    // const getNaicsCodes = (filter) => {
    //     const queryParam = {};
    //     if (segmentCategory !== "") queryParam.q = segmentCategory;
    //     getNaicsCodes(queryParam).then((res) => {
    //         if (res) {
    //             let data = [];
    //             res.data.forEach((obj) => {
    //                 data.push({
    //                     label: obj.title,
    //                     value: obj.naics,
    //                 });
    //             });
    //             setNaicsOptions(data);
    //         }
    //     });
    // };

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.naics) {
                props.segmentEditInfo.data.segment_design.primaryData.naics.forEach(optionValue => {
                    naicsOptions.find(option => {
                        if (option.value === optionValue) {
                            arr.push(option);
                        }
                        ;
                    })
                })
                setValue(arr);
            }
        }
    }, [props.segmentEditInfo]);


    const onNaicsFilterChange = (e) => {
        const filter = e.filter;
        if (filter.value.length < 3) return;
        if (filter.value.length === 3) {
            setSegmentCategory(filter.value.toUpperCase());
        } else {
            setSegmentCategory(filter.value.toUpperCase());
        }
        const allData = naicsOptions.slice();
        const newData = filter.value.length >= 1 ? filterBy(allData, filter) : allData;
        setNaicsOptions(newData);
    };

    const onNaicsChange = (e) => {
        let values = [];
        if (e.value.some(e  => e.text === "Select All")) {
            // selectAll();
            let allValues = [];
            let existingValues = e.value.filter(item => item.text !== "Select All");
            naicsOptions.shift();
            allValues = existingValues.concat(naicsOptions);
            const allUniqueValues = allValues.filter((obj, index, self) =>
                    index === self.findIndex((t) => (
                        t["text"] === obj["text"]
                    ))
            )
            setValue(allUniqueValues);
            allUniqueValues.forEach((val) => {
                values.push(val.value);
            });
            props.formik.values.segment_design.primaryData.naics = values;
        } else {
            setValue(e.value)
            e.value.forEach((val) => {
                values.push(val.value);
            });
            props.formik.values.segment_design.primaryData.naics = values;
            if (e.value.length === 0) setNaicsOptions([]);
        }
    };
    const selectAll = () => {
        let values = [];
        naicsOptions.shift();
        setValue(naicsOptions);
        naicsOptions.forEach((val) => {
            values.push(val.value);
        });
        props.formik.values.segment_design.primaryData.naics = values;
    };

    return (
        <div>
            <div className="form-group">
              <MultiSelect
                  className={"fields"}
                  data={naicsOptions}
                  autoClose={false}
                  placeholder="Select..."
                  textField="text"
                  dataItemKey="value"
                  filterable={true}
                  onFilterChange={onNaicsFilterChange}
                  onChange={onNaicsChange}
                  size="large"
                  value={value}
                  // style={{width: "32rem"}}
              />
              <Label className={"hint-standard"}>Search by industry name or NAICS Code.  Enter at least 3 characters.</Label>
            </div>
        </div>
    );
}

export default Naics;
