import React, {useEffect, useState} from "react";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import { ListView } from "@progress/kendo-react-listview";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {
    ACTIVATE_SEGMENT_ROUTE,
    ACTIVATION_DEACTIVATE_API, ACTIVATION_ID_PLACE_HOLDER,
    DEACTIVATE_MODE_SEGMENT,
    DELETE_TRACKING_PIXELS, SEGMENT_DEACTIVATE_API,
    SEGMENT_DELETE_API,
    SEGMENT_ID_PLACE_HOLDER,
    SUCCESS_STATUS
} from "../../../api/constants";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import { Button } from "@progress/kendo-react-buttons";
import "./DeleteSegments.css";
import {Label} from "@progress/kendo-react-labels";
import {NavLink} from "react-router-dom";

const TreeDeactivateSegments = (props) => {

    const deactivate_segments = props.deactivateSegments.profileFormik.values.selectedSegments;

    const [visible, setVisible] = useState(props.visible);

    const [labelText, setLabelText] = useState("");

    const [pageLoader, setPageLoader] = useState(false);

    const [activationNames, setActivationNames] = useState([]);

    const [activationIds, setActivationIds] = useState([]);

    const type = [];


    useEffect(() => {

        let activation_names = [];
        let activation_ids = [];

        deactivate_segments.forEach((item)=>{

            type.push(item.objectType);

                if (item.objectType  === "Activation" || item.objectType  === "Impression-Activation"){
                activation_names.push(item.objectName);
                activation_ids.push(item.id);
            }

        })

        setActivationNames(activation_names);
        setActivationIds(activation_ids);

        if (activation_ids.length === 0) {
            setLabelText("Are you sure you want to deactivate this activation?");
        } else {
            setLabelText("Are you sure you want to deactivate these activation(s)?");
        };


    }, []);

    const listItem = props => {
        console.log(props)
        let item = props.dataItem;
        return (
            <div className="k-listview-item"
                 style={{padding:5, fontSize: "14px"}}
            >
                <i className="fa fa-circle"/> &nbsp; &nbsp; {item}
            </div>
        )
    };

    const deactivatePlatforms = async () => {
        setPageLoader(true);
        try {
            for (const item of activationIds) {
                console.log(item)
                let response = await semcastAxios.patch(ACTIVATION_DEACTIVATE_API.replace(ACTIVATION_ID_PLACE_HOLDER, item), {withCredentials: true});
                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage("Platform(s) deactivated.", 10);
                    props.reloadAfterDelete();
                };
            }
        }  catch (error) {
            if(error.response.status === 401 || error.response.status === 403){
                showErrorToastMessage(error + " You did not have the permission to proceed, please contact support", 15);
            }else if(error.response.status === 500){
                showErrorToastMessage(error.response.data.data + " Please contact support", 15);
            }else{
                showErrorToastMessage("Segment(s) deactivated failed with " + error.response.data.data + " Please contact support", 15);
            }
            props.reloadAfterDelete();
        } finally {
            setPageLoader(false);
            setVisible(false);
        }
    }

    return (
        <>
            <div id="dactivateSegments">
                {visible && (<Dialog
                    title={"Deactivate Segments"}
                    width={720}
                    closeIcon={false}
                    className={"dialog-standard"}
                >
                    <LoadOverlay active={pageLoader}>
                        <div style={{fontSize:"18px"}}>
                            <b>{labelText}</b>
                        </div>
                        <div
                            style={{
                                paddingTop: "0.75rem",
                                fontSize:"14px",
                                maxHeight: "20rem",
                                overflowY: "auto",
                            }}
                        >
                            <ListView data={activationNames} item={listItem} style={{width: "100%", height:"100%"}}/>
                        </div>

                    </LoadOverlay>
                    <DialogActionsBar layout={"end"}>
                        <Button
                            className={"button-standard button-white"}
                            onClick={() => {
                                props.cancelSubscriber(undefined)
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            className={"button-standard button-red"}
                            onClick={deactivatePlatforms}
                        >
                            Deactivate
                        </Button>
                    </DialogActionsBar>
                </Dialog>)}
            </div>
        </>
    );
}

export default TreeDeactivateSegments;
