import * as yup from "yup";
export const schema = yup.object().shape({
  contact_first_name: yup.string().min(1).max(128).required('First name is required.'),
  contact_last_name: yup.string().min(1).max(128).required('Last name is required.'),
  contact_email: yup.string().email("Invalid email format.").max(40).required("Contact email is required."),
  country: yup.string().required('Country is required.'),
  // zip_code: yup.string().required('5 digits zipcode is required.').matches('^\\d{5}(-\\d{4})?$', "Please enter a valid US zip code. It should be in the format ##### or #####-####."),
  zip_code: yup.string()
      .when('country', {
        is: 'US',
        then: yup.string().required('5 digits zipcode is required.')
            .matches(/^\d{5}(-\d{4})?$/, "Please enter a valid US zip code. It should be in the format ##### or #####-####."),
        otherwise: yup.string().notRequired(), // Validation not required for non-US countries
      }),

  ads_type: yup.string().min(1).max(3).required('ADS type is required.'),
  address: yup.string().min(4).max(256).required('Address is required'),
//  city: yup.string().min(4).max(15).required('City name is required.').matches(/^[A-Z][a-z.]+(?:\s[A-Z][a-z.]+)*$/, "Invalid character(s) in the field."),
  city: yup.string()
      .min(3)
      .max(30)
      .required('City name is required.'),
      //.matches(/^[A-Z][a-z.]+(?:\s[A-Z][a-z.]+){0,2}$/, "Invalid character(s) in the field."),

  company: yup.string().min(1).max(35).required('Company name is required.'),
  state: yup.string().min(2).max(20).required('State is required.'),
  billing_contact: yup.string().min(1).max(30).required('Billing contact is required.'),
  billing_email: yup.string().email("Invalid email format.").min(4).max(40).required("Email is required."),
  billing_phone: yup.string().min(10).max(10).required('Billing phone number is required.').matches(/^[0-9]+$/, "Invalid character(s) in the field, please use digits only."),
  // salesforce_id: yup.string().matches(/^[a-zA-Z0-9]{15}$|^[a-zA-Z0-9]{18}$|^$/, "Salesforce ID should be either 15 or 18 characters long, letters and digits only."),
  // intacct_id: yup.string().matches(/^[a-zA-Z0-9]{5}$|^$/, "Intacct ID should be 5 characters long, letters and digits only"),
  salesforce_id: yup.string()
      .matches(/^[a-zA-Z0-9]{15}$|^[a-zA-Z0-9]{18}$|^$/, "Salesforce ID should be either 15 or 18 characters long, letters and digits only.")
      .nullable(true),
  intacct_id: yup.string()
      .matches(/^[a-zA-Z0-9]{5}$|^$/, "Intacct ID should be 5 characters long, letters and digits only")
      .nullable(true)
});
