import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { drawFileBrowser } from "../../../actions/OnBoardingActions";
import LoadOverlay from "./../SharedComponents/LoadOverlay";
import moment from 'moment';
import {Checkbox} from "@progress/kendo-react-inputs";
import directory_up from "../Segmentation/temporaryresources/images/directoryup.png";
import directory from "../Segmentation/temporaryresources/images/directory.png";
import file from "../Segmentation/temporaryresources/images/file.png";
import "./FileBrowser.css";
import {FAIL_STATUS} from "../../../api/constants";
import {showErrorToastMessage} from "../../../Utils/Common";

const FileBrowser = (props) => {
    const [fileData, setFileData] = useState({});
    const [selectedFile, setSelectedFile] = useState({});
    const [pageLoader, setPageLoader] = useState(false);
    const [visible, setVisible] = useState(props.visible);
    const [subMessage, setSubMessage] = useState("Only files with the following extensions are visible:")
    const [currentDirectory, setCurrentDirectory] = useState("");
    const [checkedIndex, setCheckedIndex] = useState(-1);

    const handleFolderActions = (path) => {
        const fileBrowserParams = `path=${path}&_=1635420187770`;
        setSelectedFile({});
        getFileData(fileBrowserParams);
    };

    const handleFileSelect = (item, event) => {
        // console.log("event:", event);
        setSelectedFile(item);
    };

    const getFileData = (fileBrowserParams) => {
        setPageLoader(true);
        drawFileBrowser(props.settings, fileBrowserParams).then((res) => {
            if (res.status === FAIL_STATUS) {
                if (res.data)  {
                    showErrorToastMessage("There was an error using this connection. " + res.data);
                } else {
                    showErrorToastMessage("There was an error using this connection.  Check the credentials for this connection.");
                }
                setPageLoader(false);
                setVisible(false);
                return;
            }
            setFileData(res.data);

            setCurrentDirectory(res.data.currentDirectory);

            setPageLoader(false);
        });
    };

    const formatSize = (num) => {
        let temp = num / 1000;
        return Math.round(temp * 100) / 100;
    };

    useEffect(() => {

      if (props.isOpenModel) {
        setFileData({});
        setSelectedFile({});
        const fileBrowserParams =
          "draw=1&columns%5B0%5D%5Bdata%5D=weight&columns%5B0%5D%5Bname%5D=&columns%5B0%5D%5Bsearchable%5D=true&columns%5B0%5D%5Borderable%5D=false&columns%5B0%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B0%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B1%5D%5Bdata%5D=1&columns%5B1%5D%5Bname%5D=&columns%5B1%5D%5Bsearchable%5D=true&columns%5B1%5D%5Borderable%5D=true&columns%5B1%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B1%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B2%5D%5Bdata%5D=name&columns%5B2%5D%5Bname%5D=&columns%5B2%5D%5Bsearchable%5D=true&columns%5B2%5D%5Borderable%5D=true&columns%5B2%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B2%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B3%5D%5Bdata%5D=modified_date&columns%5B3%5D%5Bname%5D=&columns%5B3%5D%5Bsearchable%5D=true&columns%5B3%5D%5Borderable%5D=true&columns%5B3%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B3%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B4%5D%5Bdata%5D=size&columns%5B4%5D%5Bname%5D=&columns%5B4%5D%5Bsearchable%5D=true&columns%5B4%5D%5Borderable%5D=true&columns%5B4%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B4%5D%5Bsearch%5D%5Bregex%5D=false&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=asc&order%5B1%5D%5Bcolumn%5D=3&order%5B1%5D%5Bdir%5D=desc&start=0&length=-1&search%5Bvalue%5D=&search%5Bregex%5D=false&_=1635410315779";
        getFileData(fileBrowserParams);
        setVisible(props.isOpenModel);
      }
    }, [props.isOpenModel]);

    return (
      <>
          {visible && (
              <Dialog
                  title={props.connectionTitle}
                  width={1000}
                  closeIcon={false}
                  className={"dialog-standard file-browser-dialog"}>
                  <LoadOverlay active={pageLoader}>
                      <div className={"file-browser-header-info"}>
                          <span style={{fontSize:"16px"}} className={"file-browser-header-item"}>Current Directory: <b>{currentDirectory}</b></span>
                      </div>

                      <div style={{paddingBottom:"0.25rem"}}></div>
                      <Table striped bordered hover size="sm">
                          <thead>
                          <tr>
                              <th>#</th>
                              <th>File Name</th>
                              <th>Modified Date</th>
                              <th>Size</th>
                          </tr>
                          </thead>
                          <tbody>
                            {fileData.currentDirectory !== "/" ? (
                                <tr>
                                    <td>{""}</td>
                                    <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            handleFolderActions(fileData.parentDirectory)
                                        }
                                    >
                                        <img src={directory_up} style={{paddingRight:"1rem"}} alt=""/>
                                        Up Directory
                                    </td>
                                </tr>
                            ) : (
                                ""
                            )}
                            {(fileData.files || []).map((item, index) => (
                                (item.type === "file" || item.type === "directory") &&
                                <tr qawkey={index}>
                                    <td>
                                        {item.type === "file" ? (
                                            <Checkbox
                                                className="file-browse-select"
                                                onClick={(e) => {
                                                    if (checkedIndex === index) {
                                                        setCheckedIndex(-1);
                                                        setSelectedFile({});
                                                    } else {
                                                        handleFileSelect(item, e);
                                                        setCheckedIndex(index);
                                                    }
                                                }}
                                                id={index}
                                                checked={checkedIndex === index ? true : false}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </td>
                                    <td
                                        style={{cursor: "pointer"}}
                                        onClick={(e) => {
                                            if (item.type === "file") {
                                                if (checkedIndex === index) {
                                                    setCheckedIndex(-1);
                                                    setSelectedFile({});
                                                } else {
                                                    setCheckedIndex(index);
                                                    handleFileSelect(item, e);
                                                }
                                            } else {
                                                handleFolderActions(item.path);
                                                setCheckedIndex(-1);
                                                setSelectedFile({});
                                            }
                                        }}
                                    >
                                        <img src={item.type === "file" ? file : directory} style={{paddingRight:"1rem"}} alt=""/>
                                        {item.name}
                                    </td>
                                    <td>{item.modified_date ? moment(item.modified_date).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
                                    <td>{formatSize(item.size)} KB</td>
                                </tr>
                            ))}
                          </tbody>
                      </Table>
                      <span style={{fontSize:"16px"}} className={"file-browser-header-item"}>{subMessage} <b>.csv, .tsv, .txt, .log</b></span>
                  </LoadOverlay>
                  <DialogActionsBar layout={"end"}>
                      <Button
                          className="button-standard button-white"
                          onClick={() => {
                              setVisible(!visible)
                              props.setFileBrowserVisible(false);
                          }}>
                          Cancel
                      </Button>
                      <Button
                          className="button-standard button-submit"
                          disabled={!selectedFile.path}
                          onClick={() => {
                              props.handleClose(true, selectedFile)
                              props.setFileBrowserVisible(false);
                          }}
                      >
                          Save
                      </Button>
                  </DialogActionsBar>
              </Dialog>
        )}
      </>
    )
};

export default FileBrowser;
