import $ from 'jquery';
export let UDX = {customerServiceEmail: 'support@semcasting.com',shortDateTimeFormat:'YYYY-MM-DD'};

UDX.Admin = {
    Leads: {},
    Dealers: {},
    Go: {},
    Enterprise: {}
};

UDX.Leads = {};
UDX.Attributor = {
    Admin: {},
    fileTypes: {
        ADDRESS: 'Address',
        HUMANA_ADDRESS: 'H* Address',
        COMPANY_NAME_ADDRESS: 'Company Name and Address',
        EMAIL: 'Email',
        SHA256_EMAIL: 'Sha256 Email',
        SHA1_EMAIL: 'Sha1 Email',
        MD5_EMAIL: 'Md5 Email',
        XANDR_ID: 'Xandr ID',
        DATATRUST_ID: 'DataTrust ID',
        DEVICE_ID: 'Device ID',
        HEALTHWISE_ID: 'HW ID',
        CTVMRM_ID: 'Ctv MRM ID',
        NPI_ID: 'NPI',
        GEO: 'Map',
        IMPRESSIONS: 'IP and Timestamp',
        US_PHONE: 'Phone Number',
        VOTER_ID: 'TargetSmart Voter ID',
        // AFFINITY_ID:'Affinity ID',
        DOMAIN_NAME: 'Domain Name',
        COMPANY_NAME: 'Company Name',
        WEB_LOG: 'Web Log',
        ZIP5: '5 Digit Zip Codes',
        ZIP9: '9 Digit Zip Codes',
        ZIP11: '11 Digit Zip Codes',
        LAT_LONG: 'Coordinates'
    }
};
UDX.Dealers = {};
UDX.Go = {};


UDX.regex = {
    email: /([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})/gi,
    nonDigits: /[^\d]/gi,
    phoneUS: /(\d{3})(\d{3})(\d{4})/gi,
    creditCardUS16: /(\d{4})(\d{4})(\d{4})(\d{4})/gi,
    creditCardUS15: /(\d{4})(\d{6})(\d{5})/gi,
    // domain: /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,24}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,24})$/,
    domain: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/,
    url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:%/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    ipv4: /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/,
    dateFormat: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
    //url:/^((http[s]?):\/)?\/?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/
    //url: /^((https?|ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
};

UDX.formatters = {
    phoneUS: function (phoneNumber) {

        if (!phoneNumber) {
            return phoneNumber
        }
        var newPhoneNumber = phoneNumber.replace(UDX.regex.nonDigits, '');
        if (newPhoneNumber.length === 10) {
            return newPhoneNumber.replace(UDX.regex.phoneUS, "($1) $2-$3");
        }
        return phoneNumber;
    },

    reportDay: function (day) {
        if (!day) {
            return 'Every Day';
        }

        if (isNaN(parseInt(day))) {
            return UDX.utils.formatDayOfWeek(day) + 's';
        }

        return 'The ' + UDX.utils.formatDayOfMonth(day);
    },

    creditCardNumber: function (number) {
        var newNumber = number.replace(UDX.regex.nonDigits, '');
        if (newNumber.length === 16) {
            return newNumber.replace(UDX.regex.creditCardUS16, "$1 $2 $3 $4");
        } else if (newNumber.length === 15) {
            return newNumber.replace(UDX.regex.creditCardUS15, "$1 $2 $3");
        }

        return newNumber;
    }
};

UDX.constants = {
    freeLimit: 1000, //total free leads before charging extra
    freeTrialTimePeriod: 30, //30  days
    alertDismissalTime: 3000,//Time it takes for alerts to automatically dismiss them selves in milliseconds
    planTypes: ['DAILY', 'WEEKLY', 'MONTHLY']
};

UDX.urlValidator = [{
    required: false
}, {
    isUrl: 1,
    msg: 'Must be a valid URL'
}, {
    sameDomain: 1,
    msg: "URLs must have the same domain" //message must be here also to avoid bug in validation.js where the error never goes away
}];


UDX.currencyCodes = {
    USD: '$'
};


UDX.utils = {


    /*removes http://www. from urls*/
    removeUrlPrefix: function (url) {
        if (!url) {
            return url;
        }
        return url.replace(/^https?:\/\//ig, '').replace(/^www\./ig, '');
    },

    didSessionExpire: function () {
        var queryString = window.location.search;
        if (queryString) {
            queryString = decodeURIComponent(queryString);
            queryString = queryString.toLowerCase().substr(1);
            var queryObj = UDX.utils.queryStringToJSON(queryString);

            return queryObj.sessionexp && queryObj.sessionexp === 'true';
        }
    },

    wasPasswordReset: function () {
        var queryStringParams = UDX.utils.queryStringToJSON(window.location.search) || {};
        var reset = queryStringParams.reset || false;
        return reset === 'true';
    },

    parseJsonNoErrors: function (jsonString) {
        try {
            return JSON.parse(jsonString);
        } catch (e) {
            return null;
        }
    },

    queryStringToJSON: function (queryString) {
        if (queryString && queryString[0] === "?") {
            queryString = queryString.substr(1);
        }
        var pairs = queryString.slice(0).split('&');
        var result = {};

        pairs.forEach(function (pair) {
            pair = pair.split('=');
            var key = pair[0];
            var val = decodeURIComponent(pair[1] || '');
            if (result.hasOwnProperty(key)) {
                if (typeof result[key] == 'string') {
                    result[key] = [result[key], val]
                } else {
                    result[key].push(val)
                }
            } else {
                result[key] = val;
            }
        });

        return JSON.parse(JSON.stringify(result));
    },

    jsonToQueryString: function (obj, prefix) {
        if (obj == null || $.isEmptyObject(obj)) {
            return '';
        }

        var str = [], p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
                str.push((v !== null && typeof v === "object") ?
                    UDX.jsonToQueryString(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        return str.join("&");
    },

    formatDayOfWeek: function (day) {
        var daysMap = {
            MON: 'Monday',
            TUE: 'Tuesday',
            WED: 'Wednesday',
            THU: 'Thursday',
            FRI: 'Friday',
            SAT: 'Saturday',
            SUN: 'Sunday'
        };

        if (daysMap.hasOwnProperty(day)) {
            return daysMap[day];
        }

        return day;
    },

    formatDayOfMonth: function (day) {
        day += '';
        var dayNumber = parseInt(day);
        var lastNumber = parseInt(day.substr(-1));
        var suffix = 'th';
        var suffixMap = {
            1: 'st',
            2: 'nd',
            3: 'rd'
        };

        if (dayNumber >= 11 && dayNumber <= 13) {
            suffix = 'th';
        } else if (suffixMap.hasOwnProperty(lastNumber)) {
            suffix = suffixMap[lastNumber];
        }

        return day + '<sup>' + suffix + '</sup>';
    },

    calculateLeadsBill: function (totalLeads, cpm, freeLimit, planCost) {
        var billableLeads = totalLeads - freeLimit;
        billableLeads = billableLeads < 0 ? 0 : billableLeads;
        var billableBlocks = Math.floor(billableLeads / 1000) * cpm;
        var billablePartials = ((billableLeads % 1000) / 1000) * cpm;
        return planCost + billablePartials + billableBlocks;
    },

    parseDomainFromURL: function (url) {
        url = UDX.utils.removeUrlPrefix(url);
        return url.split('?')[0].split('/')[0];
    },

    getUSDateTimeTimezoneControlHtml: function (options) {
        options = options || {};
        var timeZonePicker = UDX.templates.usTimeZonePicker({
            value: options.value || '',
            width: options.width,
            cls: options.cls,
            id: options.id,
            style: options.style,
            name: options.tzName
        });
        return UDX.templates.dateTimeTimezoneControl({
            timeZonePicker: timeZonePicker,
            controlId: options.controlId,
            dateName: options.dateName,
            timeName: options.timeName
        });
    },

    toInt: function (intString) {
        try {
            return parseInt(intString);
        } catch (e) {
            return null;
        }
    },

    isValidDateFormat: function (dateString) {
        return UDX.regex.dateFormat.test(dateString);
    },

    round: function (number, decimalPlaces) {
        decimalPlaces = decimalPlaces || 0;
        if (decimalPlaces === 0) {
            return Math.round(number);
        }

        var multiplier = Math.pow(10, decimalPlaces);
        return Math.round(number * multiplier) / multiplier;
    },

    sortObjectsByParam: function (arrayOfObjects, propName) {
        arrayOfObjects.sort(function (a, b) {
            if (a[propName] > b[propName]) {
                return 1;
            }

            if (a[propName] < b[propName]) {
                return -1;
            }

            return 0;
        });
        return arrayOfObjects
    },

    alphaNumericSortObjectsByParam: function (arrayOfObjects, propName) {
        var reA = /[^a-zA-Z]/g;
        var reN = /[^0-9]/g;
        arrayOfObjects.sort(function (a, b) {
            var aProp = a[propName];
            var bProp = b[propName];
            var aA = aProp.replace(reA, "");
            var bA = bProp.replace(reA, "");

            if (aA === bA) {
                var aN = parseInt(aProp.replace(reN, ""), 10);
                var bN = parseInt(bProp.replace(reN, ""), 10);
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        });
        return arrayOfObjects
    },

    alphaSort: function (arrayOfStrings) {
        return arrayOfStrings.sort(function (a, b) {
            if (a.toLowerCase() < b.toLowerCase()) return -1;
            if (a.toLowerCase() > b.toLowerCase()) return 1;
            return 0;
        });
    },

    alphaNumericSort: function (arrayOfStrings) {
        var reA = /[^a-zA-Z]/g;
        var reN = /[^0-9]/g;
        return arrayOfStrings.sort(function (a, b) {
            var aA = a.replace(reA, "");
            var bA = b.replace(reA, "");
            if (aA === bA) {
                var aN = parseInt(a.replace(reN, ""), 10);
                var bN = parseInt(b.replace(reN, ""), 10);
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        });
    },

    /**
     * removes: nulls, empty strings, empty objects, and empty arrays from obj
     * this is a recursive function if obj[propName] is an object this will
     * @param obj {Object} any object
     * @returns {Object} object with empty values removed
     */
    stripEmptyValuesFromObject: function (obj) {
        for (var propName in obj) {
            if ($.isPlainObject(obj[propName])) {
                obj[propName] = UDX.utils.stripEmptyValuesFromObject(obj[propName])
            }
            if (obj[propName] === null || obj[propName] === '' || $.isEmptyObject(obj[propName]) || ($.isArray(obj[propName]) && !obj[propName].length)) {
                delete obj[propName];
            }
        }
        return obj;
    }
};

/**
 * Used to display alerts on the next page visited.
 * For example deleting a url from the ManageUrl Page
 * */
UDX.MessageCookie = {
    set: function (msg, type) {
        if (!msg) {
            return;
        }

        $.cookie('message', {
            msg: msg,
            type: type || 'info'
        }, {
            expires: .000694, path: '/'
        }); //.000694 Days = 1 minute
    },

    get: function () {
        return $.cookie('message') || null;
    },

    //returns true if cookie was deleted
    delete: function () {
        return $.removeCookie('message', {path: '/'})
    }
};

UDX.templates = {

    deleteUrlSuccess: '<strong>Success!</strong> <a href="javascript:void(0)" class="alert-link"><%=url%></a> was deleted',
    deleteUserSuccess: '<strong>Success!</strong> <a href="javascript:void(0)" class="alert-link"><%=firstName%> <%=firstName%></a> was deleted',

    loadingScreenTemplate: '<div class="loading-screen"><div class="loading-message"><div class="loader">Loading...</div></div></div>',
    subscriptionPlanPickerTemplate:
        '<select <% if(typeof width != "undefined"){%>data-width="<%=width%>"<% }%> <%if (typeof name != "undefined") {%> name="<%=name%>" <% }%> <%if (typeof id != "undefined") {%> id="<%=id%>" <% }%> class="selectpicker <%if (typeof cls != "undefined") {%> <%=cls%> <% }%>">' +
        // '<% if(typeof plan == "undefined" || plan ==  "DAILY") { %>' +
        '<option <% if (typeof currentFrequency != "undefined" && currentFrequency == "DAILY") {%> selected="selected" <% } %> value="DAILY">Daily</option>' +
        // '<% } %>' +
        //  '<% if(typeof plan == "undefined" || (plan ==  "DAILY" || plan ==  "WEEKLY")) { %>' +
        '<option <% if (typeof currentFrequency != "undefined" && currentFrequency == "WEEKLY") {%> selected="selected" <% } %> value="WEEKLY">Weekly</option>' +
        //   '<% } %>' +
        '<option <% if (typeof currentFrequency != "undefined" && currentFrequency == "MONTHLY") {%> selected="selected" <% } %> value="MONTHLY">Monthly</option>' +

        '<option <% if (typeof currentFrequency != "undefined" && currentFrequency == "ON_DEMAND") {%> selected="selected" <% } %> value="ON_DEMAND">On Demand</option>' +
        '</select>',

    dayOfWeekPickerTemplate:
        '<select name="day_of_week" <% if(typeof width != "undefined"){%>data-width="<%=width%>"<% }%> <%if (typeof id != "undefined") {%> id="<%=id%>" <% }%> style="<%if (typeof style != "undefined") {%><%=style%> <% }%>" class="selectpicker day_of_week <%if (typeof cls != "undefined") {%> <%=cls%> <% }%>">' +
        '<option <% if (typeof day_of_week != "undefined" && day_of_week == "MON") {%> selected="selected" <% } %> value="MON">Monday</option>' +
        '<option <% if (typeof day_of_week != "undefined" && day_of_week == "TUE") {%> selected="selected" <% } %>  value="TUE">Tuesday</option>' +
        '<option <% if (typeof day_of_week != "undefined" && day_of_week == "WED") {%> selected="selected" <% } %>  value="WED">Wednesday</option>' +
        '<option <% if (typeof day_of_week != "undefined" && day_of_week == "THU") {%> selected="selected" <% } %>  value="THU">Thursday</option>' +
        '<option <% if (typeof day_of_week != "undefined" && day_of_week == "FRI") {%> selected="selected" <% } %>  value="FRI">Friday</option>' +
        '<option <% if (typeof day_of_week != "undefined" && day_of_week == "SAT") {%> selected="selected" <% } %> value="SAT">Saturday</option>' +
        '<option <% if (typeof day_of_week != "undefined" && day_of_week == "SUN") {%> selected="selected" <% } %> value="SUN">Sunday</option>' +
        '</select>',

    dayOfMonthPickerTemplate:
        '<select name="day_of_month"  <% if(typeof width != "undefined"){%>data-width="<%=width%>"<% }%> <%if (typeof id != "undefined") {%> id="<%=id%>" <% }%> style="<%if (typeof style != "undefined") {%><%=style%> <% }%>" data-size="5" class="selectpicker day_of_month <%if (typeof cls != "undefined") {%> <%=cls%> <% }%>">' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 1) {%> selected="selected" <% } %> value="1">1</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 2) {%> selected="selected" <% } %> value="2">2</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 3) {%> selected="selected" <% } %> value="3">3</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 4) {%> selected="selected" <% } %> value="4">4</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 5) {%> selected="selected" <% } %> value="5">5</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 6) {%> selected="selected" <% } %> value="6">6</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 7) {%> selected="selected" <% } %> value="7">7</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 8) {%> selected="selected" <% } %> value="8">8</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 9) {%> selected="selected" <% } %> value="9">9</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 10) {%> selected="selected" <% } %> value="10">10</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 11) {%> selected="selected" <% } %> value="11">11</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 12) {%> selected="selected" <% } %> value="12">12</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 13) {%> selected="selected" <% } %> value="13">13</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 14) {%> selected="selected" <% } %> value="14">14</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 15) {%> selected="selected" <% } %> value="15">15</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 16) {%> selected="selected" <% } %> value="16">16</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 17) {%> selected="selected" <% } %> value="17">17</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 18) {%> selected="selected" <% } %> value="18">18</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 19) {%> selected="selected" <% } %> value="19">19</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 20) {%> selected="selected" <% } %> value="20">20</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 21) {%> selected="selected" <% } %> value="21">21</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 22) {%> selected="selected" <% } %> value="22">22</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 23) {%> selected="selected" <% } %> value="23">23</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 24) {%> selected="selected" <% } %> value="24">24</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 25) {%> selected="selected" <% } %> value="25">25</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 26) {%> selected="selected" <% } %> value="26">26</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 27) {%> selected="selected" <% } %> value="27">27</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 28) {%> selected="selected" <% } %> value="28">28</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 29) {%> selected="selected" <% } %> value="29">29</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 30) {%> selected="selected" <% } %> value="30">30</option>' +
        '<option <% if (typeof day_of_month != "undefined" && day_of_month == 31) {%> selected="selected" <% } %> value="31">31</option>' +
        '</select>'
    ,

    /* usTimeZonePicker:_.template(
         '<select <% if(typeof name != "undefined"){%>name="<%=name%>"<% }%> <% if(typeof width != "undefined"){%>data-width="<%=width%>"<% }%> <%if (typeof id != "undefined") {%> id="<%=id%>" <% }%> style="<%if (typeof style != "undefined") {%><%=style%> <% }%>" class="selectpicker <%if (typeof cls != "undefined") {%> <%=cls%> <% }%>">' +
             '<option <% if(typeof value != "undefined" && value=="EST"){%>selected="selected"<% }%> value="EST">EST</option>' +
             '<option <% if(typeof value != "undefined" && value=="CST"){%>selected="selected"<% }%> value="CST">CST</option>' +
             '<option <% if(typeof value != "undefined" && value=="MST"){%>selected="selected"<% }%> value="MST">MST</option>' +
             '<option <% if(typeof value != "undefined" && value=="PST"){%>selected="selected"<% }%> value="PST">PST</option>' +
             '<option data-divider="true"></option>' +
             '<option <% if(typeof value != "undefined" && value=="AKST"){%>selected="selected"<% }%> value="AKST">AKST</option>' +
             '<option <% if(typeof value != "undefined" && value=="HST"){%>selected="selected"<% }%> value="HST">HST</option>' +
             '<option data-divider="true"></option>' +
             '<option <% if(typeof value != "undefined" && value=="ATC"){%>selected="selected"<% }%> value="ATC">ATC</option>' +
             '<option <% if(typeof value != "undefined" && value=="CHST"){%>selected="selected"<% }%> value="CHST">CHST</option>' +
             '<option <% if(typeof value != "undefined" && value=="SST"){%>selected="selected"<% }%> value="SST">SST</option>'+
         '</select>'
     ),*/

    usTimeZonePicker:
        '<select <% if(typeof name != "undefined"){%>name="<%=name%>"<% }%> <% if(typeof width != "undefined"){%>data-width="<%=width%>"<% }%> <%if (typeof id != "undefined") {%> id="<%=id%>" <% }%> style="<%if (typeof style != "undefined") {%><%=style%> <% }%>" class="selectpicker <%if (typeof cls != "undefined") {%> <%=cls%> <% }%>">' +
        '<option <% if(typeof value != "undefined" && value=="America/New_York"){%>selected="selected"<% }%> value="America/New_York">EST</option>' +
        '<option <% if(typeof value != "undefined" && value=="America/Chicago"){%>selected="selected"<% }%> value="America/Chicago">CST</option>' +
        '<option <% if(typeof value != "undefined" && value=="America/Denver"){%>selected="selected"<% }%> value="America/Denver">MST</option>' +
        '<option <% if(typeof value != "undefined" && value=="America/Los_Angeles"){%>selected="selected"<% }%> value="America/Los_Angeles">PST</option>' +
        '</select>'
    ,

    dateTimeTimezoneControl:
        '<div class="input-group date" <% if(typeof controlId != "undefined"){%>id="<%=controlId%>"<% }%>>' +
        '<div class="input-group-addon">' +
        '<i class="fa fa-calendar" aria-hidden="true"></i>' +
        '</div>' +
        '<input type="text" <% if(typeof dateName != "undefined"){%>name="<%=dateName%>"<% }%> class="form-control datetimepicker"/>' +
        '<div class="input-group-addon" style="border-left:none; border-right:none;">' +
        '<i class="fa fa-clock-o" aria-hidden="true"></i>' +
        '</div>' +
        '<input type="text" <% if(typeof timeName != "undefined"){%>name="<%=timeName%>"<% }%> class="form-control timepicker"/>' +
        '<%=timeZonePicker%>' +
        '</div>'
    ,


    numericPickerOptions:
        '<% for(var i=min; i<=max; i++){ %>' +
        '<option <% if(typeof value != "undefined" && value==i){%>selected="selected"<% }%> value="<%=i%>"><%=i%></option>' +
        '<% } %>'
    ,

    /*
     * Possible params for template. Strings or Ints Only
     * name, size, width, id, style, cls, attrs, options,
     * */
    selectpicker:
        '<select <% if(typeof attrs != "undefined"){%><%=attrs%><% }%> <% if(typeof name != "undefined"){%>name="<%=name%>"<% }%> <% if(typeof size != "undefined"){%>data-size="<%=size%>"<% }%> <% if(typeof width != "undefined"){%>data-width="<%=width%>"<% }%> <%if (typeof id != "undefined") {%> id="<%=id%>" <% }%> style="<%if (typeof style != "undefined") {%><%=style%> <% }%>" class="selectpicker <%if (typeof cls != "undefined") {%> <%=cls%> <% }%>">' +
        '<%=options%>' +
        '</select>'

};
