import React, {useEffect, useState} from "react";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import {Label} from "@progress/kendo-react-labels";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";
import {Grid} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Input} from "@progress/kendo-react-inputs";
import "./GroupsPermissionsDashboard.css"
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {loadAccessibleGroups, loadAccessibleRoles, loadGroupEditUserRoles} from "../../../actions/UserActions";
import {
    ASCENDING_ORDER, DESCENDING_ORDER,
    GROUPS_CREATE_API,
    GROUPS_DELETE_API,
    GROUPS_UPDATE_PATCH_API,
    NOT_A_MEMBER,
    SUCCESS_STATUS,
    USERS_GROUPS_EDIT_PUT_API
} from "../../../api/constants";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage, sortItemsByField} from "../../../Utils/Common";


const valueRender = (element, value) => {
    if(value && value.role_name && value.role_name === "Not a Member") {
        return React.cloneElement(element, {
            ...element.props,
            class:`${element.props.className} not-a-member-dropdown-option`
        })
    } else {
        return React.cloneElement(element, {
            ...element.props,
            class:`${element.props.className}`
        })
    }
}

const PermissionsEditGroup = (props) => {

    //console.log("PermissionsEditGroupProps=", props);
    const {dialogMode, openEditCreateDialog, setOpenEditCreateDialog, editGroupObj,
        groupEditFormik, userHelpers, allUsersRoles, refreshTable, usersLoad, onGroupCreated} = props;
    const [dialogOverlayActive, setDialogOverlayActive] = useState(true);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [accessibleRoles, setAccessibleRoles] = useState([]);
    const [roleIdNameDict, setRoleIdNameDict] = useState({});
    const [userIdRoleIdDict, setUserIdRoleIdDict] = useState({});

    const [rolesDropdownData, setRolesDropdownData] = useState([]);
    const [allGroupDataObj, setAllGroupDataObj] = useState({});


    const [groupInputTouched, setGroupInputTouched] = useState(false);
    const [groupName, setGroupName] = useState(editGroupObj.group_name || "");

    const handleNameChange = (e) => {
        setGroupName(e.target.value);
    }

    const handleBlur = () => {
        setGroupInputTouched(true);
    }

    const deleteConfirmed = async (e) => {
        await deleteGroup(e, editGroupObj).then(() => {
            setShowDeleteConfirmationDialog(false);
            closeDialog();
            refreshTable();
        });
    }

    const deleteDenied = () => {
        setShowDeleteConfirmationDialog(false);
    }

    const closeDialog = () => {
        setOpenEditCreateDialog(false);
    }

    const loadEditGroupData = async (group, roleIdNameDictTemp) => {
        if(group) {
            // await setGroupEditFormikData(group)
            const userNRolesIds = await loadGroupEditUserRoles(group.id);

            if (userNRolesIds) {
                try {
                    let userIdRoleIdDictTemp = userNRolesIds.reduce((a, x) => ({...a, [x.user_id]: x}), {})
                    setUserIdRoleIdDict(userIdRoleIdDictTemp);

                    let userNRoles = [...allUsersRoles].map((obj) => ({
                        ...obj,
                        role_id: userIdRoleIdDictTemp[obj.user_id]?.role_id || '',
                        user_id: obj.user_id || '',
                        group_id: group.id,
                        role_name: roleIdNameDict[userIdRoleIdDictTemp[obj.user_id]?.role_id || "Not a Member"]
                    }))

                    let allGroupDataObjTemp = {};

                    userNRoles.forEach((user) => {
                        allGroupDataObjTemp[user.user_id] = user;
                        allGroupDataObjTemp[user.user_id]["role_name"] = roleIdNameDictTemp[user.role_id];
                    })
                    setAllGroupDataObj(allGroupDataObjTemp);
                    return allGroupDataObjTemp;

                } catch (e) {
                    // console.log("error occurred while while pre populating existing groups & roles of  users in group edit " + e)
                }
            }
        }
    }

    const accessibleRolesLoad = async () => {
        const rolesRes = await Promise.allSettled([loadAccessibleRoles()]);
        const roles = rolesRes[0].value;
        const roleIdNameDictTemp = {};

        if (roles) {
            roles.splice(0, 0, {id: '', role_name: NOT_A_MEMBER});
        }

        roles.forEach((role) => {
            roleIdNameDictTemp[role.id] = role.role_name;
        })

        let rolesDropdownDataTemp = [{
            role_id: "",
            role_name: "Not a Member",
            className: "not-a-member-dropdown-option"
        }];

        roles.forEach((item) => {
            let addOption = userHelpers.isAdministrator(item)
                || userHelpers.isDefaultRole(item)
                || userHelpers.roleBelongsToGroup(item, editGroupObj.id)

            if(addOption) rolesDropdownDataTemp.push({
                role_id: item.id,
                role_name: item.role_name
            })
        })

        setRolesDropdownData(rolesDropdownDataTemp);
        setRoleIdNameDict(roleIdNameDictTemp);
        setAccessibleRoles(roles);

        return roleIdNameDictTemp;
    }

    const createNewGroup = async () => {

        try {

            setDialogOverlayActive(true);

            let payload = {
                id: null,
                group_name: groupName
            }

            let createGroupResponse = await semcastAxios.post(GROUPS_CREATE_API, payload, {
                withCredentials: true,
            }).then(async (res) => {
                let newGroupID = res["data"]["data"]["id"];

                const groupNameIdPayload = {
                    id: newGroupID,
                    group_name: groupName,
                };

                let newGroupPayload = Object.values(allGroupDataObj);

                newGroupPayload.forEach((userObj) => {
                    userObj.group_id = newGroupID;
                })

                const assignmentPayload = {
                    group: groupNameIdPayload,
                    assignments: newGroupPayload
                }

                let assignResponse = await semcastAxios.put(USERS_GROUPS_EDIT_PUT_API, assignmentPayload, {
                    withCredentials: true,
                }).then(async (assignRes) => {
                    if (assignRes.data.status === SUCCESS_STATUS) {
                        showSuccessToastMessage(groupName + " updated.");
                        await refreshTable().then(() => {
                            setOpenEditCreateDialog(false);
                            onGroupCreated?.(groupName);
                        });
                    }
                });
            })
        } catch (error) {
            showErrorToastMessage("Error occurred");
        } finally {
            setDialogOverlayActive(false);
        }
    }

    const deleteGroup = async (e, group) => {
        try {
            setDialogOverlayActive(true);
            e.stopPropagation();
            if (group.id) {
                let response = await semcastAxios.delete(GROUPS_DELETE_API + group.id, {
                    withCredentials: true,
                });

                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage(`${group.group_name} deleted`);
                }
            }
        } catch (error) {
            // console.log(error)
            showErrorToastMessage(`Error occurred: ${group.group_name} deleted`);
        } finally {
            setDialogOverlayActive(false);
            closeDialog();
        }
    }

    const EditCommandCell = (props) => {
        const {allGroupDataObj} = props;
        const userId = props.dataItem["user_id"];
        const [dropdownValue, setDropdownValue] = useState(allGroupDataObj[userId]);

        const handleChange = (event) => {

            let allGroupDataObjTemp = allGroupDataObj;
            allGroupDataObjTemp[userId]["role_id"] = event.target.value["role_id"];
            allGroupDataObjTemp[userId]["role_name"] = event.target.value["role_name"];
            setAllGroupDataObj(allGroupDataObjTemp);
            setDropdownValue(event.target.value);
        }

        return (
            <td>
                <DropDownList
                    className={"group-permissions-dialog-roles-dropdown"}
                    data={rolesDropdownData}
                    textField={"role_name"}
                    dataItemKey={"role_id"}
                    onChange={handleChange}
                    value={dropdownValue}
                    valueRender={valueRender}
                >


                </DropDownList>
            </td>
        )
    }

    const MyEditCommandCell = (props) => {


        return (
            <EditCommandCell {...props} rolesDropdownData={rolesDropdownData} roleIdNameDict={roleIdNameDict}
                allGroupDataObj={allGroupDataObj} />
        )

    }

    useEffect(async () => {
        setDialogOverlayActive(true);

        if(dialogMode === "edit") {

            accessibleRolesLoad().then(res => {
                let roleIdNameDictTemp = res;
                loadEditGroupData(editGroupObj, roleIdNameDictTemp).then((res) => {

                }).then(() => {
                    setDialogOverlayActive(false);
                });
            });
        } else {
            setGroupName("");
            setGroupInputTouched(false);
            const rolesRes = await Promise.allSettled([loadAccessibleRoles()]);
            const roles = rolesRes[0].value;
            const roleIdNameDictTemp = {};

            if (roles) {
                roles.splice(0, 0, {id: '', role_name: NOT_A_MEMBER});
            }

            roles.forEach((role) => {
                roleIdNameDictTemp[role.id] = role.role_name;
            })

            let allGroupDataObjTemp = {};
            // console.log("PermissionEditGroupAllUsersRoles=", allUsersRoles);
            allUsersRoles.forEach((user) => {
                allGroupDataObjTemp[user.user_id] = user;
                allGroupDataObjTemp[user.user_id]["role_name"] = roleIdNameDictTemp[user.role_id];
            })

            const newGroupRoles = [
                {
                    role_id: "",
                    role_name: "Not a Member"
                }, {
                    role_id: 0,
                    role_name: "Administrator"
                }, {
                    role_id: 5,
                    role_name: "Group Manager"
                }, {
                    role_id: 1,
                    role_name: "Group User"
                }
            ]

            setRoleIdNameDict(roleIdNameDictTemp);
            setRolesDropdownData(newGroupRoles);
            setAllGroupDataObj(allGroupDataObjTemp);
            setDialogOverlayActive(false);
        }

    }, [dialogMode]);

    return (
        <div>
            <Dialog
                className={"dialog-standard perm-groups-dialog"}
                title={dialogMode === "edit" ? "Edit Group" : "Create New Group"}
                closeIcon={false}
                width={750}
            >
                <LoadOverlay active={dialogOverlayActive} height={50} width={50}>

                    {showDeleteConfirmationDialog && (
                        <div className={"admin-users-delete-dialog-container"}>
                            <Dialog title={"Delete Group"} onClose={deleteDenied} className={"admin-users-delete-dialog dialog-standard"}>
                                <p className={"admin-users-delete-dialog-message-text"}>Are you sure you want to delete <strong>{editGroupObj.group_name}</strong>?</p>

                                <DialogActionsBar>
                                    <Button className="button-standard button-white" onClick={deleteDenied}>No</Button>
                                    <Button className="button-standard button-submit" onClick={deleteConfirmed}>Yes</Button>
                                </DialogActionsBar>
                            </Dialog>
                        </div>

                    )}

                    <div className={"perm-groups-dialog-container"}>
                        <div className={"perm-groups-dialog-form-container"}>
                            <Label
                                className={"field-label"}
                            >
                                Group Name
                            </Label>
                            <Input
                                name="group_name"
                                value={groupName}
                                onChange={handleNameChange}
                                onBlur={handleBlur}
                            >
                            </Input>
                            {!groupName.length && ((dialogMode === 'new' && groupInputTouched) || dialogMode === 'edit') && (
                                <span style={{color: "red"}}>{"Group Name is required"}</span>
                            )}

                        </div>

                        <div className={"perm-users-form-table-section"}>
                            <Grid
                                data={allUsersRoles}
                            >
                                <Column field="user_name" title="User" />
                                <Column cell={MyEditCommandCell} filterable={false}  title="Role"/>

                            </Grid>
                        </div>
                    </div>

                    <DialogActionsBar>
                        <div className={"perm-groups-dialog-buttons-container"}>
                            <div>
                                <Button
                                    className={"button-standard button-red perm-groups-dialog-delete-button"}
                                    type={'button'}
                                    disabled={false}
                                    onClick={(e) => {
                                        setShowDeleteConfirmationDialog(true);
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>

                            <div>
                                <Button
                                    className={"button-standard button-white"}
                                    type={'button'}
                                    disabled={false}
                                    onClick={() => {
                                        closeDialog();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={"button-standard button-submit"}
                                    type={'button'}
                                    disabled={!groupName.length}
                                    onClick={async () => {

                                        if(dialogMode === 'edit') {
                                            setDialogOverlayActive(true);
                                            let groupID = editGroupObj.id;

                                            const groupNameIdPayload = {
                                                id: groupID,
                                                group_name: groupName,
                                            };

                                            let updateGroupNameResponse = await semcastAxios.patch(GROUPS_UPDATE_PATCH_API + groupID, groupNameIdPayload, {
                                                withCredentials: true,
                                            });

                                            const assignmentPayload = {
                                                group: groupNameIdPayload,
                                                assignments: Object.values(allGroupDataObj)
                                            }

                                            let assignResponse = await semcastAxios.put(USERS_GROUPS_EDIT_PUT_API, assignmentPayload, {
                                                withCredentials: true,
                                            });

                                            if (assignResponse.data.status === SUCCESS_STATUS) {
                                                showSuccessToastMessage(groupName + " updated.");

                                                await refreshTable().then(() => {
                                                    setDialogOverlayActive(false);
                                                    setOpenEditCreateDialog(false);
                                                });
                                            }
                                        } else {
                                            await createNewGroup();

                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </DialogActionsBar>



                </LoadOverlay>
            </Dialog>
        </div>
    )
}

export default PermissionsEditGroup;