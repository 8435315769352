import { semcastAxios } from "../../../Utils/Common";
import { schema } from "../../../helpers/AccountSchema";
import { ADMIN_ACCOUNT_DETAILS_API } from "../../../api/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import Loader from "../../Components/Loader";
import DynamicAlert from "../../Components/SharedComponents/DynamicAlert";
import {UserContext} from "../../../contexts/UserContext";
function CreateAccount() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { user, setUser } = React.useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [msg, setMsg] = useState("");
  const [type, setType] = useState("");
  const createAccount = async (data) => {
    setLoading(true);
    await semcastAxios
      .post(ADMIN_ACCOUNT_DETAILS_API, data, {
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        setType("Success");
        setAlert(true);
        setMsg(res.data.data.warning);
      })
      .catch((err) => {
        setType("Error!");
        setLoading(false);
        setAlert(true);
        setMsg(err.response.data.data);
      });
  };
  return (
    <>
      {user?.isAdmin &&
      <div className="content-body">
        {/* row */}
        <div className="container-fluid">
          <div className="row">
            {loading ? (
                <Loader height="100%"/>
            ) : (
                <form onSubmit={handleSubmit((data) => createAccount(data))}>
                  <div className="col-12 col-md-12 col-lg-12">
                    {alert ? <DynamicAlert type={type} message={msg}/> : null}
                    <div className="tab-content mt-3">
                      <div className="card mt-3 p-3">
                        <div className="card-body">
                          <h4>User Info</h4>
                          <div className="col-md-12 mt-3 p-0 mb-3">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">Contact First Name</label>
                                  <input
                                      type="text"
                                      required
                                      className="form-control input-rounded"
                                      placeholder=""
                                      {...register("contact_first_name")}
                                  />
                                  <span className="validation-error">
                                  {errors.contact_first_name
                                      ? errors.contact_first_name.message
                                      : null}
                                </span>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">Contact Last Name</label>
                                  <input
                                      type="text"
                                      required
                                      className="form-control input-rounded"
                                      placeholder=""
                                      {...register("contact_last_name")}
                                  />
                                  <span className="validation-error">
                                  {errors.contact_last_name
                                      ? errors.contact_last_name.message
                                      : null}
                                </span>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">Contact Email</label>
                                  <input
                                      type="text"
                                      required
                                      className="form-control input-rounded"
                                      placeholder=""
                                      {...register("contact_email")}
                                  />
                                  <span className="validation-error">
                                  {errors.contact_email
                                      ? errors.contact_email.message
                                      : null}
                                </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-3 p-3">
                        <div className="card-body">
                          <h4>Company Info</h4>
                          <div className="col-md-12 mt-3 p-0 mb-3">
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">Company Name</label>
                                  <input
                                      type="text"
                                      required
                                      className="form-control input-rounded"
                                      placeholder=""
                                      {...register("company")}
                                  />
                                  <span className="validation-error">
                                  {errors.company
                                      ? errors.company.message
                                      : null}
                                </span>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">Address</label>
                                  <input
                                      type="text"
                                      required
                                      className="form-control input-rounded"
                                      placeholder=""
                                      {...register("address")}
                                  />
                                  <span className="validation-error">
                                  {errors.address
                                      ? errors.address.message
                                      : null}
                                </span>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">City</label>
                                  <input
                                      type="text"
                                      required
                                      className="form-control input-rounded"
                                      placeholder=""
                                      {...register("city")}
                                  />
                                  <span className="validation-error">
                                  {errors.city ? errors.city.message : null}
                                </span>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">State</label>
                                  <input
                                      type="text"
                                      required
                                      className="form-control input-rounded"
                                      placeholder=""
                                      {...register("state")}
                                  />
                                  <span className="validation-error">
                                  {errors.state ? errors.state.message : null}
                                </span>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">Zip Code</label>
                                  <input
                                      type="text"
                                      required
                                      className="form-control input-rounded"
                                      placeholder=""
                                      {...register("zip_code")}
                                  />
                                  <span className="validation-error">
                                  {errors.zip_code
                                      ? errors.zip_code.message
                                      : null}
                                </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-3 p-3">
                        <div className="card-body">
                          <h4>Billing Info</h4>
                          <div className="col-md-12 mt-3 p-0 mb-3">
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">Billing Contact</label>
                                  <input
                                      type="text"
                                      required
                                      className="form-control input-rounded"
                                      placeholder=""
                                      {...register("billing_contact")}
                                  />
                                  <span className="validation-error">
                                  {errors.billing_contact
                                      ? errors.billing_contact.message
                                      : null}
                                </span>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">Billing Email</label>
                                  <input
                                      type="text"
                                      required
                                      className="form-control input-rounded"
                                      placeholder=""
                                      {...register("billing_email")}
                                  />
                                  <span className="validation-error">
                                  {errors.billing_email
                                      ? errors.billing_email.message
                                      : null}
                                </span>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-label required-asterisk">Billing Phone Number</label>
                                  <input
                                      type="text"
                                      required
                                      className="form-control input-rounded"
                                      placeholder=""
                                      {...register("billing_phone")}
                                  />
                                  <span className="validation-error">
                                  {errors.billing_phone
                                      ? errors.billing_phone.message
                                      : null}
                                </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-right mb-4">

                    <button
                        type="submit"
                        className="btn btn-rounded btn-primary bnt-block "
                    >
                      Create Account
                    </button>
                  </div>
                </form>
            )}
          </div>
        </div>
        {/***********************************
         Content body end
         ************************************/}
      </div>
      }
    </>
  );
}

export default CreateAccount;
