import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const googleConfig = {
  ...COMPANY_BASE_PROPERTIES,
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With DV360",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "google",

  /**
   * HTML that explains how to use the form
   */
  instructions:
    "Please provide the Start/End Date Range and the Advertiser ID. All fields must be completed " +
    "to push an audience to your seat. Please note that the audience may take 3 to 5 days " +
    "to show up in your platform. This runs through Audiencerate.",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "google",
    text: "Google",
    img: "dv360-logo.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink: "",

  layout: [
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType:'selectPickerG',
      fieldName:'google_product',
      labelText:'Google Product',
      defaultOption:'Select Product',
      options:[{
          text:'DV360 Advertiser',
          val:'dv360_advertiser'
      },{
          text:'DV360 Partner',
          val:'dv360_partner'
      },{
          text:'DV360 MarketPlace',
          val:'dv360_marketplace'
      },{
          text:'DoubleClick for Publishers',
          val:'doubleclick_publishers'
      },{
          text:'DoubleClick for Publishers Global',
          val:'doubleclick_global'
      },],
      required: true
  },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType:'searchSelect',
          fieldName:'advertiser_id',
          labelText:'Advertiser ID',
          url:'/v2/secure/attributor/activate-credentials/google/advertiser_id',
          placeholder:'Select an Advertiser ID or add a new one',
          namePropName:'value',
          textPropName:'value',
          valuePropName:'id',
          required:true
      },{
          fieldType:'searchSelect',
          fieldName:'seat_id',
          labelText:'Seat ID',
          url:'/v2/secure/attributor/activate-credentials/google/seat_id',
          placeholder:'Select a Seat ID or add a new one',
          namePropName:'value',
          textPropName:'value',
          valuePropName:'id',
          required:true,
          // validation: [{
          //     required: function (value, attr, computed) {
          //         if (['doubleclick_publishers', 'dv360_partner', 'dv360_advertiser'].includes(computed.google_product) && !value) {
          //             return 'Seat ID is required'
          //         }
          //     }
          // }]
      },{
          fieldType:'checkbox',
          fieldName:'save_credentials',
          labelText:'Save Advertiser ID <span class="save-credentials-optional">and Seat ID</span> for future use',
          checked: false,
      }
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },
    {
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
    {
      fieldType: "pricing",
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["advertiser_id","seat_id"],
  formik: {
    initialValues: {
      device_type: ["ios_android"],
      campaign_range_start: "",
      campaign_range_end: "",
      save_credentials: false,
      advertiser_id: "",
      audience_usage:"",
      seat_id:"",
      google_product:"",
      creativeTagIdDropDown: "",
      creativeTagId:"",
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      google_product: Yup.string().required("Google Product is required"),
      advertiser_id: Yup.string().required("Advertiser ID is required"),
      seat_id: Yup.string().required("Seat ID is required"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
    }),
    onSubmit: (values) => {},
  },
};
