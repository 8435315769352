import {semcastAxios, showErrorToastMessage} from "../Utils/Common";
import {
    SUCCESS_STATUS,
    SEGMENTS_ACCESSIBLE_API,
    SEGMENT_DELETE_API,
    SEGMENT_DEACTIVATE_API,
    SEGMENT_REACTIVATE_API,
    SEGMENT_COPY_API,
    SEGMENT_LOCATIONS_API,
    DASHBOARD_TREE, SEGMENT_DETAILS_API, SEGMENT_ID_PLACE_HOLDER
} from "../api/constants";

export const loadSegments = async (user_id, segmentName, rightTopSelectionObj, sortConfig) => {
    // const headingConfig = {
    //     "Segment":"segment_name",
    //     "Records":"total_unique_addresses",
    //     "Last Updated":"updated_at",
    //     "On Board File Deleted At":"onboard_file_deleted_at",
    //     "Auto Generated":"auto_generated_by_type",
    //     "Percent Served":"pct_served",
    //     "Platforms":"company_name",
    //     "Reach":"billing_audience_size",
    //     "CPM":"billing_rate",
    //     "Status":"status"
    // };
    // let segmentsUrl2=SEGMENTS_ACCESSIBLE_API;
    let segmentsUrl=DASHBOARD_TREE;
    /*if(user_id)
    segmentsUrl+='&user_id='+user_id;*/
    // console.log(rightTopSelectionObj?.status)
    // if (rightTopSelectionObj?.status) {
    //     if (rightTopSelectionObj.status === "In Progress") {
    //         segmentsUrl2 += "&status=CONSTRUCTING,COUNTING,LOADING,MERGING,CONVERTING,PROFILING";
    //     } else if (rightTopSelectionObj.status === "Error") {
    //         segmentsUrl2 += "&status=ERROR";
    //     } else if (rightTopSelectionObj.status === "Available") {
    //         segmentsUrl2 += "&status=AVAILABLE";
    //     }
        //     segmentsUrl += "&status=" + rightTopSelectionObj.status;
        // }
    // }
    // if (rightTopSelectionObj?.group) {
    //     segmentsUrl2+='&group_id='+rightTopSelectionObj.group;
    // }
    // if (rightTopSelectionObj?.user) {
    //     segmentsUrl2+='&user_id='+rightTopSelectionObj.user;
    // }
    // if (sortConfig != null) {
    //     if(headingConfig[sortConfig["key"]]!==""){
    //         segmentsUrl2+='&orderby='+headingConfig[sortConfig["key"]]+'&orderdir='+sortConfig.direction;
    //     }
    // } else {
    //     segmentsUrl2+="&orderby=created_at&orderdir=desc";
    // }
    // segmentsUrl2+="&aq_data=true";
    // if (segmentName != null) {
    //     segmentsUrl2+='&segment_name=' + segmentName;
    // }
    // if (rightTopSelectionObj?.group) {
    //     segmentsUrl2+='&groupId='+rightTopSelectionObj.group;
    // }
    // if (rightTopSelectionObj?.user) {
    //     segmentsUrl2+='&userId='+rightTopSelectionObj.user;
    // }
    // let response2 = await semcastAxios.get(segmentsUrl2, {withCredentials: true});
    let response = await semcastAxios.get(segmentsUrl, {withCredentials: true});
    // console.log(response.data)
    // console.log(response2.data.data)

    // if (response.data.status === SUCCESS_STATUS) {
    //     try {
    //         return response?.data?.data
    //     } catch (e) {
    //         return []
    //     }
    // }
    if (response.status === 200) {
        try {
            return response?.data?.dashboardObjectTree
        } catch (e) {
            return []
        }
    }
}

export const loadSegmentDetails = async (segment_id) => {

    let response = await semcastAxios.get(SEGMENT_DETAILS_API.replace(SEGMENT_ID_PLACE_HOLDER, segment_id), {withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.data
        } catch (e) {
            console.log(e)
            return []
        }
    }
}

export const deleteSegment = async (segment_id) => {
    var idValues=segment_id;
    if(!Array.isArray(segment_id)){
        idValues=[segment_id];
    }
    let response = await semcastAxios.delete(SEGMENT_DELETE_API, {data: {'semcastingNumericSegmentIds':idValues}, withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.data
        } catch (e) {
            return []
        }
    }
}

export const reactivateSegment = async (segment_id) => {
    let response = await semcastAxios.patch(SEGMENT_REACTIVATE_API+segment_id+'/reactivate',{}, { withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.data
        } catch (e) {
            return []
        }
    }
}

export const deactivateSegment = async (segment_id) => {
    let response = await semcastAxios.patch(SEGMENT_DEACTIVATE_API+segment_id+'/deactivate',{}, {withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.data
        } catch (e) {
            return []
        }
    }
}

export const copySegment = async (segment_id, segment_name, group_id) => {
    let response = await semcastAxios.put(SEGMENT_COPY_API+segment_id+'/copy',{'segment_name':segment_name,'group_id':group_id}, {withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.status
        } catch (e) {
            return []
        }
    }
}

export const segmentLocations = async () => {
    let response = await semcastAxios.get(SEGMENT_LOCATIONS_API, {withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.data
        } catch (e) {
            return []
        }
    }
}