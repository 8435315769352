import LoadingOverlay from "react-loading-overlay";
import Loader from "react-loader-spinner";

export default function LoadOverlay({ active, children, height=200, width=200 }) {
  return (
    <LoadingOverlay
      active={active}
      classNamePrefix="MyLoader_overlay "
      spinner={<Loader type="Rings" color="#00BFFF" height={height} width={width} />}
    >
      {children}
    </LoadingOverlay>
  );
}
