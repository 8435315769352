import * as Yup from "yup";

export const iqmConfig = {
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With IQM",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "iqm",

  /**
   * HTML that explains how to use the form
   */
   instructions:
   "You have selected a platform that automatically reports usage for targeting. " +
   "You will be billed for data usage by the platform unless the segment is applied for suppression purposes." +
   "Suppression segments are billed by Semcasting directly based on the discounted deployed record formula. "+
   "Activating this audience means you agree to this billing method.",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "iqm",
    text: "IQM",
    img: "iqm-logo.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink:
    '<a href="https://platform.iqm.com/login" target="_blank">Log in to IQM</a>',

  layout: [
    {
      fieldType: "checkboxGroup",
      labelText: "Devices to Activate",
      fieldName: "device_type",
      required: true,
      checkboxes: [
        {
          labelText: "IQM ID",
          fieldName: "CLIENT_ID",
          value: "CLIENT_ID",
          checked: true,
          disabled: true,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Mobile Devices",
      fieldName: "mobile_devices",
      required: false,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: true,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },
      ],
    },
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelect",
          fieldName: "seatId",
          labelText: "Seat ID",
          url: "/v2/secure/attributor/activate-credentials/iqm/seatId",
          placeholder: "Select a Seat ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType: 'checkbox',
          fieldName: "save_credentials",
          labelText: 'Save Seat ID for future use',
          checked: false,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },
    {
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
    {
      fieldType: "pricing",
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["seatId"],
  formik: {
    initialValues: {
      device_type: ["CLIENT_ID"],
      mobile_devices: "",
      campaign_range_start: "",
      campaign_range_end: "",
      save_credentials: false,
      seatId: "",
      audience_usage:"",
      creativeTagId:"",
      creativeTagIdDropDown: "",
    },
    // We used Yup here for data validation and condition.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      //save fields
      seatId: Yup.string().required("Seat ID is required"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
    }),
    onSubmit: (values) => {},
  },
};
