import {useEffect, useState} from "react";
import {
    boostrap2TableSearchOptions,
    ExportCSV, exportCSVOptions,
    noDataIndication,
    paginationOptions,
    semcastAxios
} from "../../../../Utils/Common";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {
    ACTIVATED_SEGMENTS_TOTALS_BY_PARTY_API,
    SUCCESS_STATUS,
    YYYY_MM_DD_DATEFORMAT
} from "../../../../api/constants";
import LoadOverlay from "../../../Components/SharedComponents/LoadOverlay";
// es6
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css'
import filterFactory from 'react-bootstrap-table2-filter';
import moment from "moment";
import "../../Pages/AppStatus.css"
function ActivatedSegmentsTotalsByParty(props) {
    const propsToPass = props.data.propsToPass
    const [loader, setLoader] = useState(false)
    const [rows, setRows] = useState([]);
    const { SearchBar } = Search;
    const partyFormatter = (cell, row) => {
        if (row?.party) {
            return (
                <span>{cell === 1 ? '1st Party' : (cell === 3 ? '3rd Party' : cell)}</span>
            );
        }
        return (
            <span></span>
        );
    }
    const defaultSorted = [{
        dataField: 'party',
        order: 'desc'
    }];
    const columns = [{
        dataField: 'party',
        text: 'Party',
        sort: true,
        formatter: partyFormatter

    }, {
        dataField: 'total',
        text: 'Total',
        sort: true
    }];

    useEffect(async () => {
      try {
            setLoader(true)
            await loadRows();
        } catch (e) {
            setRows([])
        } finally {
            setLoader(false)
        }
    }, [propsToPass]);

    const loadRows = async () => {
        const res = await semcastAxios.get(ACTIVATED_SEGMENTS_TOTALS_BY_PARTY_API +
            `?completed_at_start=${moment(
                propsToPass.startDate
            ).format(YYYY_MM_DD_DATEFORMAT)}&completed_at_end=${moment(
                propsToPass.endDate
            ).format(
                YYYY_MM_DD_DATEFORMAT
            )}`, {
            withCredentials: true,
        });
        if (res.data.status === SUCCESS_STATUS) {
            if (res?.data?.data?.length) {
                res?.data?.data.forEach((obj, index) => {
                    obj['id'] = index
                })
                setRows(res.data.data)
            }
        }
    };

    return (
        <>
            <LoadOverlay active={loader}>
               <ToolkitProvider
                    keyField="id"
                    bootstrap4={true}
                    data={rows}
                    columns={columns}
                    search={boostrap2TableSearchOptions()}
                    exportCSV={exportCSVOptions()}
                >
                    {
                        props => (
                            <div>
                                <SearchBar { ...props.searchProps }
                                           className="form-control form-control-custom" />
                                <ExportCSV {...props.csvProps}/>
                                <BootstrapTable {...props.baseProps}
                                                pagination={paginationFactory(paginationOptions(rows))}
                                                filter={filterFactory()}
                                                defaultSorted={ defaultSorted }
                                                bordered={false}
                                                noDataIndication={noDataIndication}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </LoadOverlay>
        </>
    )
}

export default ActivatedSegmentsTotalsByParty