import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// <script src="assets/vendor/purecounter/purecounter.js"></script>;
import '@progress/kendo-theme-default/dist/all.css';
import reportWebVitals from "./reportWebVitals";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../node_modules/boxicons/css/boxicons.min.css";
import "remixicon/fonts/remixicon.css";
// import "aos/dist/aos.css";
import "../node_modules/aos/dist/aos.css";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { UserProvider } from "./contexts/UserContext";
import {ToastContainer} from "react-toastify";
import {ErrorBoundary} from "react-error-boundary";
import ErrorPage from "./components/Pages/ErrorPage";
// Error logging function
function logErrorToService(error, info) {
    // Use your preferred error logging service
    console.error("Error Boundary Caught an error:", error, info);
}

ReactDOM.render(
  <BrowserRouter>
      <ToastContainer />
    <React.StrictMode>
      <UserProvider>
          <ErrorBoundary FallbackComponent={(props) => <ErrorPage props={props}/>} onError={logErrorToService} >
        <App />
          </ErrorBoundary>
      </UserProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
