import React, {useEffect, useState} from "react";
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import "./GenderButtonGroup.css";

const GenderButtonGroup = (props) => {
    const determineActive = (flag) => {
        const arr = props.audienceState.gender;
        if (arr) {
            if (flag === 'M' && arr.includes('M')) return true;
            if (flag === 'F' && arr.includes('F')) return true;
        }
    };
    const [maleGenderActive, setMaleGenderActive] = useState(props.audienceState ?
        determineActive('M') :false);
    const [femaleGenderActive, setFemaleGenderActive] = useState(props.audienceState ?
        determineActive('F') :false);

    useEffect(() => {
        let values = [];
        let arr = [];
        if (maleGenderActive) {
            arr.push('M');
        }
        if (femaleGenderActive) {
            arr.push('F');
        }
        // props.audienceState.gender = arr;
        props.handleAudienceState(prevState => {
            return {...prevState, gender: arr}}
        );
    }, [maleGenderActive, femaleGenderActive]);

    useEffect(() => {
        let arr = [];
                props.audienceState.gender.forEach(optionValue => {
                    if (optionValue === "M") setMaleGenderActive(true);
                    if (optionValue === "F") setFemaleGenderActive(true);
                })
    }, [props.audienceState]);

    const onGenderMaleChange = (e) => {
        e.preventDefault();
        setMaleGenderActive(!maleGenderActive);
        setFemaleGenderActive(false);
        let arr = [];
        if (maleGenderActive) arr.push('M');
        // props.audienceState.gender = arr;
        // props.handleAudienceState(prevState => {
        //     return {...prevState, gender: arr}}
        // );
        // if (props.handleControlsState) {
        //     props.handleControlsState(new Map(props.controlsState.set("gender", arr)));
        // }
    };

    const onGenderFemaleChange = (e) => {
        e.preventDefault();
        setMaleGenderActive(false);
        setFemaleGenderActive(!femaleGenderActive);
        let arr = [];
        if (femaleGenderActive) arr.push('F');
        // props.audienceState.gender = arr;
        // props.handleAudienceState(prevState => {
        //     return {...prevState, gender: arr}}
        // );
        // if (props.handleControlsState) {
        //     props.handleControlsState(new Map(props.controlsState.set("gender", arr)));
        // }
    };

    return (
        <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 mb-3 pr-0">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <ButtonGroup className={"targeting-full-onboarding-button-group"}>
                    <Button
                        className={maleGenderActive ? ".selected-gender" : ""}
                        id="men"
                        togglable={true}
                        iconClass="fas fa-male"
                        onClick={(e) => onGenderMaleChange(e)}
                        selected={maleGenderActive}>
                        Men
                    </Button>
                    <Button
                        className={femaleGenderActive ? ".selected-gender" : ""}
                        id="women"
                        togglable={true}
                        iconClass="fas fa-female"
                        onClick={(e) => onGenderFemaleChange(e)}
                        selected={femaleGenderActive}>
                        Women
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default GenderButtonGroup;
