import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const rokuConfig = {
  ...COMPANY_BASE_PROPERTIES,
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With OneView",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "roku",
/**
   * HTML that explains how to use the form
   */
 instructions:
  "This Platform does not support clearing and billing for data usage. Per our License Agreement you will be charged for 50% of the number of Deployed Records you are about to activate. To proceed 1) Enter the email of your Billing Contact for this campaign. 2) Confirm your acceptance of these charges by checking the box below.",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "roku",
    text: "Roku",
    img: "roku-oneview-formerly-dataxu.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink:
    '<a href="https://advertisers.dataxu.com/sso/login" target="_blank">Log in to Roku</a>',

  layout: [
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
            {
              fieldType: "textField",
              fieldName: "segment_name",
              labelText: "Audience Name",
              prepopulateWith: "text",
              required: true,
              disabled: true,
            },
            {
              fieldType: "textField",
              fieldName: "pixel_id",
              labelText: "Pixel ID",
              required: true,
            },{
            fieldType:'textField',
            fieldName:'billing_contact_email',
            labelText:'Billing Contact Email',
            required: true,
            validation:[{
              pattern: 'email',
              msg: 'Please enter a valid email'
            }]
        },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelect",
          fieldName: "io_number",
          labelText: "Reference Number, PO, IO, Work Order or Project Code",
          url: "/v2/secure/attributor/activate-credentials/roku/io_number",
          placeholder:
            "Select a Reference Number, PO, IO, Work Order or Project Code or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType: 'checkbox',
          fieldName: "save_credentials",
          labelText: 'Save Reference Number, PO, IO, Work Order or Project Code for future use',
          checked: false,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },{
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
    {
      fieldType: "pricing",
    },{
      fieldType:'checkbox',
      fieldName:'client_agreement',
      required:true,
      labelText:'Please Confirm: By Checking this Box and Activating, you agree to all associated costs and fees described above.',
      value:true,
      checked:false
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["io_number"],

  formik: {
    initialValues: {
      device_type: ["CLIENT_ID"],
      pixel_id: "",
      campaign_range_start: "",
      campaign_range_end: "",
      save_credentials: false,
      io_number: "",
      audience_usage:"",
      billing_contact_email:"",
      client_agreement:false,
      creativeTagIdDropDown: "",
      creativeTagId:"",
    },
    // We used Yup here for input format validation.
    validationSchema: Yup.object().shape({
      segment_name: Yup.string().required("Audience Name is required"),
      pixel_id: Yup.string().required("Pixel ID is required"),
      billing_contact_email: Yup.string().email("Please Enter Valid Email Format").required("Contact Email is required"),
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      //save fields
      io_number: Yup.string()
        .matches(/^\d{1,7}$/, "Up to 7 digits number only")
        .required(
          "Up to 7 digits number only. No spaces, special characters, etc"
        ),
      audience_usage: Yup.string().required("Planned Usage is required"),
      client_agreement: Yup.boolean().oneOf([true], 'Please confirm before submit'),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
    }),
    onSubmit: (values) => {},
  },
};
