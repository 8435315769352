import {useHistory, useLocation} from "react-router-dom";
import React, {useState} from "react";
import {Card, CardBody} from "@progress/kendo-react-layout";
import {Button} from "@progress/kendo-react-buttons";
import "./ErrorPage.css";



const ErrorPage = (props) => {
// console.log(props)
    const history = useHistory();
    const location = useLocation();
    const backToDashboard = () => {
        history.push("/segmentation-dashboard");
        window.location.reload();
    }
    const refreshPage = () => {
        window.location.reload();
    }
    const goToHelpCenter = () => {
        history.push("/help-center");
        window.location.reload();
        sessionStorage.setItem('helpCenterTabSelected', 3);
    }
    const [errorLocation] = useState(location.pathname);
    const [onDashboard] = useState(errorLocation === "/segmentation-dashboard");
    const [errorMessage] = useState(props.props.error.message);


    return (
        <div className={"not-found-page-wrapper"}>
            <Card className={"not-found-page-card"}>
                  <CardBody>
                        <div className="box error-message">
                            <h1 className={"error-header"}>Something's wrong...</h1>
                            <div className={"error-message-text"}>We have encountered an unexpected error. Checkout our Help Center for further assistance or head back to home.</div>
                            <div className={"box"} style={{textAlign:"left"}}>Error Message: {errorMessage}</div>
                            <div className={"button-wrapper"} style={{textAlign:"center"}}>
                                <Button className={"btn btn-rounded btn-primary"} onClick={refreshPage}>Refresh Page</Button>
                                <Button className={"btn btn-rounded btn-primary"} onClick={goToHelpCenter}>Help Center</Button>
                                {onDashboard ? {} : <Button className={"btn btn-rounded btn-primary"} onClick={backToDashboard}>Back To Dashboard</Button>}
                            </div>
                        </div>
                    </CardBody>
            </Card>
        </div>
    )

}


export default ErrorPage;