import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const tiktokConfig = {
    ...COMPANY_BASE_PROPERTIES,
    /**
     * User Readable name of company the user is onBoarding with
     */
    companyText: "Onboarding With TikTok",

    /**
     * lowercase no spaces or underscores name of company
     * This will be passed to the activate model and
     * used when saving to create the correct submission url
     */
    company: "tiktok",
    /**
     * HTML that explains how to use the form
     */
    instructions:'You have selected a platform that automatically reports usage for targeting.' +
        'You will be billed for data usage by the platform unless the segment is applied for suppression purposes.' +
        'Suppression segments are billed by Semcasting directly based on the discounted deployed record formula.' +
        'Activating this audience means you agree to this billing method. ' +
        'This Platform does not support clearing and billing for data usage.   Segments will be billed on the CPM Rate expressed at the bottom of this page and is based on the number of deployed records.',

    /**
     * data used to create a company activation block
     */
    companyBlockOptions: {
        val:'tiktok',
        text:'TikTok',
        img:'tiktok-logo.png'
    },

    /**
     * anchor tag used to login to a specific platform
     */
    loginLink:
        '',

    layout: [
        {
            fieldType: "radioGroup",
            labelText: "Mobile Devices",
            fieldName: "mobile_devices",
            required: true,
            radioButtons: [
                {
                    labelText: "Top Devices",
                    fieldName: "top_devices",
                    value: "top_ios_android",
                    checked: false,
                },
                {
                    labelText: "All Devices",
                    fieldName: "all_devices",
                    value: "ios_android",
                    checked: false,
                },
            ],
        },
        {
            fieldType:'dateRange',
            fieldName:'campaign_range',
            labelText:'Campaign Date Range',
            required:'dateRangeRequired'
        },{
            fieldType:'savableFieldsWrapper',
            layout:[
                {
                    fieldType:'searchSelect',
                    fieldName:'seat_id',
                    labelText:'TikTok Account ID',
                    url:'/v2/secure/attributor/activate-credentials/tiktok/seat_id',
                    placeholder:'Select a TikTok Account ID or add a new one',
                    namePropName:'value',
                    textPropName:'value',
                    valuePropName:'id',
                    required:true,
                    validation: [{
                        required: function (value, attr, computed) {
                            if (!value) {
                                return 'TikTok Account ID is required'
                            }
                        }
                    }]
                },{
                    fieldType:'checkbox',
                    fieldName:'save_credentials',
                    labelText:'Save TikTok Account ID</span> for future use',
                    checked: false,
                }
            ]
        }, {
            fieldType:'pricing'
        },{
            fieldType:'radioGroup',
            labelText:'Planned Usage of this Segment:',
            fieldName:'audience_usage',
            required:true,
            radioButtons:[
                {
                    labelText:'Targeting',
                    fieldName:'use_type_inclusion',
                    value:'I',
                    checked:false
                }, {
                    labelText:'Suppression',
                    fieldName:'use_type_exclusion',
                    value:'X',
                    checked:false
                }
            ]
        },
        {
            fieldType: "selectPickerTag",
            fieldName: "creativeTagIdDropDown",
            labelText: "Creative Tag ID",
            url: "/v2/secure/attributor/creative-tags",
            placeholder: "Select a New or existing Tag for your creative",
            namePropName: "creativeTagId",
            textPropName: "creativeTagId",
            valuePropName: "creativeTagId",
            required: true,
        },
        {
            fieldType: "creativeTagId",
            fieldName: "creativeTagId",
            labelText: "Creative Tag ID",
            required: true,
        },
        {
            fieldType: "creativeTag",
            fieldName: "creativeTag",
            labelText: "Creative Tag",
            required: true,
        },
        // TikTok Grant Access button
        {
            fieldType:'accessCode',
            fieldName:'access_code',
            labelText:'Before activating the segment, you must first grant Semcasting' +
                ' Audience Designer temporary access to your TikTok Advertising account. Once you have authenticated to' +
                ' TikTok and authorized access, wait for the popup dialog to disappear. Then you can activate the segment.',
            required:true, //true,
            placeholder: "...",
            disabled:false,
        },
    ],

    /**
     * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
     */
    savableDataNames: ["seat_id"],
    formik: {
        initialValues: {
            device_type: ["ios_android"],
            mobile_devices:"",
            campaign_range_start: "",
            campaign_range_end: "",
            save_credentials: false,
            audience_usage:"",
            seat_id: "",
            creativeTagIdDropDown: "",
            creativeTagId:"",
        },
        // We used Yup here.
        validationSchema: Yup.object().shape({
            campaign_range_start: Yup.date().required(
                "Campaign Date Range is required"
            ),
            campaign_range_end: Yup.date().required(
                "Campaign Date Range is required"
            ),
            mobile_devices: Yup.string().required("Selection is required"),
            audience_usage: Yup.string().required("Planned Usage is required"),
            seat_id: Yup.string().required("Please enter TikTok account ID"),
            creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
            creativeTagId: Yup.string().required("Creative Tag ID is required")
        }),
        onSubmit: (values) => {},
    },
};
