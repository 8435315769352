import React, {useEffect, useState} from "react";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Label} from "@progress/kendo-react-labels";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import {Form, FormElement, FieldWrapper} from "@progress/kendo-react-form";
import {Tooltip} from "@progress/kendo-react-tooltip";
import "./KeywordsSites.css";

const KeywordsSites = (props) => {

    const FILE_INFO_MESSAGE = "File extension must be .txt.  Place each entry on a new line.";
    const [keywords, setKeywords] = useState([]);
    const [keywordsValue, setKeywordsValue]= useState([]);
    const [sitesValue, setSitesValue]= useState([]);
    const [keywordsUploadMessage, setKeywordsUploadMessage] = useState("");
    const [sitesUploadMessage, setSitesUploadMessage] = useState("");
    const [isKeywordsDataSetFromFile, setIsKeywordsDataSetFromFile] = useState(false);
    const [isSitesDataSetFromFile, setIsSitesDataSetFromFile] = useState(false);
    const MAX_FILE_SIZE = 10000;

    const onChangeKeyword = (event) => {
        const values = event.target.value;
        const lastItem = values[values.length - 1];
        if (lastItem && isCustom(lastItem)) {
            values.pop();
            const sameItem = values.find((v) => v.text === lastItem.text);
            if (sameItem === undefined) {
                addKey(lastItem);
                values.push(lastItem);
            }
        }
        setKeywordsValue(values);
        setKeywords(values);
        // Backend expects a '\n' delimited string of values
        let submitValues = values.filter((val) => {return val.text}).map((obj) => {return obj.text}).join('\n');
        if (props.formik) props.formik.values.keywords = submitValues;
        if (props.audienceState) {
            props.handleAudienceState(prevState => {
                return {...prevState, keywords: submitValues}}
            );
        }
    };

    const onChangeSite = (event) => {
        const values = event.target.value;
        const lastItem = values[values.length - 1];
        if (lastItem && isCustom(lastItem)) {
            values.pop();
            const sameItem = values.find((v) => v.text === lastItem.text);
            if (sameItem === undefined) {
                addKey(lastItem);
                values.push(lastItem);
            }
        }
        setSitesValue(values);
        // setSites(values);
        // Backend expects a '\n' delimited string of values
        let submitValues = values.filter((val) => {return val.text}).map((obj) => {return obj.text}).join('\n');
        if (props.formik) props.formik.values.sites = submitValues;
        if (props.audienceState) {
            props.handleAudienceState(prevState => {
                return {...prevState, sites: submitValues}}
            );
        }
    };

    useEffect(() => {
        if (props.audienceState && props.audienceState.keywords) {
            displayKeywords();
        }
        if (props.audienceState && props.audienceState.sites) {
            displaySites();
        }
    }, [props.audienceState])

    useEffect(() => {
        if (isKeywordsDataSetFromFile && keywordsValue.length > 0) {
            // console.log(keywordsValue);
            let submitValues = keywordsValue.filter((val) => {return val.text}).map((obj) => {return obj.text}).join('\n');
            if (props.formik) props.formik.values.keywords = submitValues;
            if (props.audienceState) {
                props.handleAudienceState(prevState => {
                    return {...prevState, keywords: submitValues}}
                );
            }
            setIsKeywordsDataSetFromFile(false);
        }
    }, [isKeywordsDataSetFromFile])

    useEffect(() => {
        if (isSitesDataSetFromFile && sitesValue.length > 0) {
            // console.log(sitesValue);
            let submitValues = sitesValue.filter((val) => {return val.text}).map((obj) => {return obj.text}).join('\n');
            if (props.formik) props.formik.values.sites = submitValues;
            if (props.audienceState) {
                props.handleAudienceState(prevState => {
                    return {...prevState, sites: submitValues}}
                );
            }
            setIsSitesDataSetFromFile(false);
        }
    }, [isSitesDataSetFromFile])

    const onKeywordsFileSelected = async (event) => {
        const fileInput = event.currentTarget;
        if (!fileInput.files || !fileInput.files.length) {
            return;
        }
        let file = fileInput.files[0];
        if (file.type !== 'text/plain' || !file.name.endsWith('.txt')) {
            const fileExt = file.name.substr(file.name.lastIndexOf('.'));
            setKeywordsUploadMessage("The file " + file.name + " has an invalid extension of " + fileExt)
            fileInput.value = '';
            return;
        } else {
            setKeywordsUploadMessage("");
        }
        if (file.size > MAX_FILE_SIZE) {
            const maxFileSizeInKb = (MAX_FILE_SIZE / 1000) + 'KB';
            const fileSizeInKb = Math.round(file.size / 1000) + 'KB';
            setKeywordsUploadMessage("File size must be under " + maxFileSizeInKb + ".  This file has a size of " + fileSizeInKb + ".");
            fileInput.value = '';
            return;
        } else {
            setKeywordsUploadMessage("");
        }
        const reader = new FileReader();
        reader.onload = handleKeywordsFileLoad;
        reader.readAsText(event.target.files[0]);
    };

    const handleKeywordsFileLoad = (event) => {
        // console.log(event.target.result);
        let values = event.target.result ? event.target.result.split('\n') : [];
        let objs = [];
        values.forEach(val => {
            let obj = {};
            if (val !== "") {
                obj.text = val;
                objs.push(obj);
            }
        });
        const additionalValues = keywordsValue.concat(objs);
        const uniqueValues = [];
        additionalValues.map(x => uniqueValues.filter(a => a.text === x.text).length > 0 ? null : uniqueValues.push(x));
        const newValues = Array.from(uniqueValues);
        setKeywordsValue([...newValues]);
        setIsKeywordsDataSetFromFile(true);
    };

    const onSitesFileSelected = async (event) => {
        const fileInput = event.currentTarget;
        if (!fileInput.files || !fileInput.files.length) {
            return;
        }
        let file = fileInput.files[0];
        if (file.type !== 'text/plain' || !file.name.endsWith('.txt')) {
            const fileExt = file.name.substr(file.name.lastIndexOf('.'));
            setSitesUploadMessage("The file " + file.name + " has an invalid extension of " + fileExt)
            fileInput.value = '';
            return;
        } else {
            setSitesUploadMessage("");
        }
        if (file.size > MAX_FILE_SIZE) {
            const maxFileSizeInKb = (MAX_FILE_SIZE / 1000) + 'KB';
            const fileSizeInKb = Math.round(file.size / 1000) + 'KB';
            setSitesUploadMessage("File size must be under " + maxFileSizeInKb + ".  This file has a size of " + fileSizeInKb + ".");
            fileInput.value = '';
            return;
        } else {
            setSitesUploadMessage("");
        }
        const reader = new FileReader();
        reader.onload = handleSitesFileLoad;
        reader.readAsText(event.target.files[0]);
    };

    const handleSitesFileLoad = (event) => {
        let values = event.target.result ? event.target.result.split('\n') : [];
        let objs = [];
        values.forEach(val => {
            let obj = {};
            if (val !== "") {
                obj.text = val;
                objs.push(obj);
            }
        });
        const additionalValues = sitesValue.concat(objs);
        const uniqueValues = [];
        additionalValues.map(x => uniqueValues.filter(a => a.text === x.text).length > 0 ? null : uniqueValues.push(x));
        const newValues = Array.from(uniqueValues);
        setSitesValue([...newValues]);
        setIsSitesDataSetFromFile(true);
    };

    const displayKeywords = () => {
        let values = props.audienceState.keywords ? props.audienceState.keywords.split('\n') : [];
        let objs = [];
        values.forEach(val => {
            let obj = {};
            if (val !== "") {
                obj.text = val;
                objs.push(obj);
            }
        });
        setKeywordsValue(objs);
    };

    const displaySites = () => {
        let values = props.audienceState.sites ? props.audienceState.sites.split('\n') : [];
        let objs = [];
        values.forEach(val => {
            let obj = {};
            if (val !== "") {
                obj.text = val;
                objs.push(obj);
            }
        });
        setSitesValue(objs);
    };

    const onKeywordsClick = (event) => {
        const { target = {} } = event || {};
        target.value = "";
    };

    const onSitesClick = (event) => {
        const { target = {} } = event || {};
        target.value = "";
    };

    const isCustom = (item) => {
        return item.id === undefined;
    };

    const addKey = (item) => {
        item.id = new Date().getTime();
    };

    return (
        <div className={"keywords-sites-outer-div"}>
            <Card className={"keywords-sites-card"}>
                <CardTitle>
                    <Label className="section-label">KEYWORDS / SITES</Label>
                </CardTitle>
                <CardBody>
                    {/*<Targeting handleSubmit={handleSubmit} />*/}
                    <Form render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                        <legend className={'k-form-legend spacingLine'}></legend>
                        <div className={"cost-msg-div"}>
                            <Label className={"optional-msg-label"}>
                                <span>(Optional) The following contextual targeting will modify this segment.</span>
                            </Label>
                        </div>
                        <div className={"keywords-sites-container"}>
                            <div className={"keywords-sites-column-1"}>
                                <Form render={formRenderProps => <FormElement style={{
                                    width: "100%"
                                }}>
                                    <FieldWrapper >
                                        <span className={"tooltip-label-and-icon-wrapper"}>
                                        <Label className={"field-label"}>Apply Keywords</Label>
                                            <Tooltip
                                                position="right"
                                                anchorElement="target"
                                                tooltipStyle={{
                                                    width: "15rem",
                                                    borderRadius: "0.25rem",
                                                }}
                                            >
                                                <i className="fa fa-question-circle-o tooltip-question-icon"
                                                   aria-hidden="true" title={"Specified keywords (e.g. Music) will affect the targeting for the current segment."}></i>
                                            </Tooltip>
                                        </span>
                                        <div className={"form-group"}>
                                            <MultiSelect
                                                className={"fields"}
                                                placeholder="Enter / select keywords or upload file..."
                                                data={keywords}
                                                autoClose={false}
                                                textField={"text"}
                                                dataItemKey="text"
                                                popupSettings={{height: 150}}
                                                onChange={(e) => onChangeKeyword(e)}
                                                value={keywordsValue}
                                                allowCustom={true}
                                                style={{width: "80%"}}/>
                                            <input
                                                id={"keywords-file-upload"}
                                                className={"file-input"}
                                                onChange={onKeywordsFileSelected}
                                                onClick={onKeywordsClick}
                                                multiple={false}
                                                type="file"
                                                hidden
                                            />
                                            <label className="file-upload" htmlFor="keywords-file-upload">
                                                <i className="fas fa-upload" style={{paddingRight: ".3rem"}}></i>
                                                Upload File
                                            </label>
                                            <Label className={"hint-standard"}>{FILE_INFO_MESSAGE}</Label>
                                            <Label className={"keywords-sites-upload-error"}>{keywordsUploadMessage}</Label>
                                        </div>
                                    </FieldWrapper>
                                </FormElement>} />
                            </div>
                            <div className={"keywords-sites-column-2"}>
                                <Form render={formRenderProps => <FormElement style={{
                                    width: "100%"
                                }}>
                                    <FieldWrapper >
                                        <span className={"tooltip-label-and-icon-wrapper"}>
                                        <Label className={"field-label"}>Apply Sites</Label>
                                            <Tooltip
                                                position="right"
                                                anchorElement="target"
                                                tooltipStyle={{
                                                    width: "15rem",
                                                    borderRadius: "0.25rem",
                                                }}
                                            >
                                                <i className="fa fa-question-circle-o tooltip-question-icon"
                                                   aria-hidden="true" title={"Specified site references (e.g. apple.com) will affect the targeting for the current segment."}></i>
                                            </Tooltip>
                                        </span>
                                        <div className={"form-group"}>
                                            <MultiSelect
                                                className={"fields"}
                                                placeholder="Enter / select sites or upload file..."
                                                // data={segmentOptions}
                                                autoClose={false}
                                                textField={"text"}
                                                dataItemKey="text"
                                                popupSettings={{height: 150}}
                                                onChange={(e) => onChangeSite(e)}
                                                value={sitesValue}
                                                allowCustom={true}
                                                style={{width: "83.5%"}}/>
                                            <input
                                                id={"sites-file-upload"}
                                                className={"file-input"}
                                                onChange={onSitesFileSelected}
                                                onClick={onSitesClick}
                                                multiple={false}
                                                type="file"
                                                hidden
                                            />
                                            <label className="file-upload" htmlFor="sites-file-upload">
                                                <i className="fas fa-upload" style={{paddingRight: ".3rem"}}></i>
                                                Upload File
                                            </label>
                                            <Label className={"hint-standard"}>{FILE_INFO_MESSAGE}</Label>
                                            <Label className={"keywords-sites-upload-error"}>{sitesUploadMessage}</Label>
                                        </div>
                                    </FieldWrapper>
                                </FormElement>} />
                            </div>
                        </div>
                    </FormElement>} />
                </CardBody>
            </Card>
        </div>
    )
}

export default KeywordsSites;