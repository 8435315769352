import * as Yup from "yup";
import {COMPANY_BASE_PROPERTIES} from "../../../../api/constants";

export const anzuConfig = {
    /**
     * User Readable name of company the user is onBoarding with
     */
    companyText: "Onboarding With Anzu",

    /**
     * lowercase no spaces or underscores name of company
     * This will be passed to the activate model and
     * used when saving to create the correct submission url
     */
    company: "anzu",

    /**
     * HTML that explains how to use the form
     */
    instructions:
        "Please provide the the Start/End date. All fields must be completed to push an audience.",

    /**
     * data used to create a company activation block
     */
    companyBlockOptions: {
        val: "anzu",
        text: "Anzu",
        img: "anzu-logo.png",
    },

    /**
     * anchor tag used to login to a specific platform
     */
    loginLink: "",

    layout: [
        {
            fieldType: "checkboxGroup",
            labelText: "ID's to Activate",
            fieldName: "device_type",
            required: true,
            checkboxes: [
                {
                    labelText: "Anzu Id",
                    fieldName: "ANZU_ID",
                    value: "anzu_id",
                    checked: true,
                },
            ],
        },
        {
            fieldType: "dateRange",
            fieldName: "campaign_range",
            labelText: "Campaign Date Range",
            required: "dateRangeRequired",
        },
        {
            fieldType: "savableFieldsWrapper",
            layout: [
                // {
                //     fieldType: "searchSelect",
                //     fieldName: "networkId",
                //     labelText: "Network ID",
                //     url: "/v2/secure/attributor/activate-credentials/anzu/networkId",
                //     placeholder: "Select an Network ID or add a new one",
                //     namePropName: "value",
                //     textPropName: "value",
                //     valuePropName: "id",
                //     required: false,
                // },
                // {
                //     fieldType: "searchSelect",
                //     fieldName: "dataProviderId",
                //     labelText: "Data Provider ID",
                //     url: "/v2/secure/attributor/activate-credentials/anzu/dataProviderId",
                //     placeholder: "Select an Data Provider ID or add a new one",
                //     namePropName: "value",
                //     textPropName: "value",
                //     valuePropName: "dpid",
                //     required: false,
                // },{
                //     fieldType: 'checkbox',
                //     fieldName: "save_credentials",
                //     labelText: 'Save Network and Data Provider ID for future use',
                //     checked: false,
                // },
            ],
        },
        {
            fieldType: "radioGroup",
            labelText: "Planned Usage of this Segment:",
            fieldName: "audience_usage",
            required: true,
            radioButtons: [
                {
                    labelText: "Targeting",
                    fieldName: "use_type_inclusion",
                    value: "I",
                    checked: false,
                },
                {
                    labelText: "Suppression",
                    fieldName: "use_type_exclusion",
                    value: "X",
                    checked: false,
                },
            ],
        },
        {
            fieldType: "selectPickerTag",
            fieldName: "creativeTagIdDropDown",
            labelText: "Creative Tag ID",
            url: "/v2/secure/attributor/creative-tags",
            placeholder: "Select a New or existing Tag for your creative",
            namePropName: "creativeTagId",
            textPropName: "creativeTagId",
            valuePropName: "creativeTagId",
            required: true,
        },
        {
            fieldType: "creativeTagId",
            fieldName: "creativeTagId",
            labelText: "Creative Tag ID",
            required: true,
        },
        {
            fieldType: "creativeTag",
            fieldName: "creativeTag",
            labelText: "Creative Tag",
            required: true,
        },
        {
            fieldType: "pricing",
        },
    ],

    /**
     * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
     */
    savableDataNames: ["networkId","dataProviderId"],
    formik: {
        initialValues: {
            device_type: ["anzu_id"],
            campaign_range_start: "",
            mobile_devices: "",
            campaign_range_end: "",
            networkId: "",
            dataProviderId: "",
            save_credentials: false,
            audience_usage: "",
            creativeTagIdDropDown: "",
            creativeTagId: "0"
        },
        // We used Yup here for input format validation.
        validationSchema: Yup.object().shape({
            campaign_range_start: Yup.date().required(
                "Campaign Date Range is required"
            ),
            campaign_range_end: Yup.date().required(
                "Campaign Date Range is required"
            ),
            // networkId: Yup.string().required("Network ID is required"),
            // dataProviderId: Yup.string().required("Data Provider ID is required"),
            audience_usage: Yup.string().required("Planned Usage is required"),
            creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
            creativeTagId: Yup.string().required("Creative Tag ID is required"),
        }),
        onSubmit: (values) => {
        },
    },
};