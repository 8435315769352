import React, {useEffect, useState} from "react";
//import PageTemplate from "./pageTemplate";
import "./BusinessDashboard.css";
import {PDFExport, PDFMargin} from "@progress/kendo-react-pdf";
import {useHistory, useLocation} from "react-router-dom";
// import * as chartData from "./business.json";
import ActivateSegmentSummary from "../Components/BusinessReport/ActivateSegmentSummary";
import TopBusinessNAICs from "../Components/BusinessReport/TopBusinessNAICs";
import Employees from "../Components/BusinessReport/Employees";
import Revenue from "../Components/BusinessReport/Revenue";
import TopBusinessOrganizations from "../Components/BusinessReport/TopBusinessOrganizations";
import BusinessNotes from "../Components/BusinessReport/BusinessNotes";
import AudienceSupplyPathOpt from "../Components/BusinessReport/AudienceSupplyPathOptimization";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Sortable} from "@progress/kendo-react-sortable";
import * as chartInfo from "./ChartData";
import {Slider, SliderLabel} from "@progress/kendo-react-inputs";
import TopContextualSamples from "../Components/BusinessReport/TopContextualSamples";
import TopEmployeeTitles from "../Components/BusinessReport/TopEmployeeTitles";
import BusinessMap from "../Components/BusinessReport/BusinessMap";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";
import {
    BUBBLE_MAP,
    DOWNLOAD_DELIMITED_DATA,
    DOWNLOAD_PROFILE,
    MARKER_MAP,
    SEGMENT_ID_PLACE_HOLDER, SEGMENT_REPORT_REGENERATE_API, SUCCESS_STATUS
} from "../../api/constants";
import TopContextualSampleCounts from "../Components/BusinessReport/TopContextualSampleCounts";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Label} from "@progress/kendo-react-labels";
import Loader from "../Components/Loader";
import {Button} from "@progress/kendo-react-buttons";
import {getFTPSettings} from "../../actions/OnBoardingActions";

export default function BusinessDashboard() {
    const [margin, setMargin] = useState('');
    const [toggleDialogOpen, setToggleDialogOpen] = useState(false);
    const [currentConnection, setCurrentConnection] = useState({text: '', id: 0});
    const [allSavedSettings, setAllSavedSettings] = useState([]);
    const [currentFormat, setCurrentFormat] = useState({text:"CSV", id: 0});
    const [isLoaded, setIsLoaded] = useState(false);
    const PageTemplate = (props) => {
        return (
            <div className="page-template business-dashboard">
                <div className="header">
                    <div className="report-logo" style={{marginLeft:margin}}>
                        {selectedFile == null && historyLogoImage == null &&(
                            <img
                                src="/images/legacy_audience_images/logo-black.png"
                                width="auto"
                                height="40"
                                alt=""
                            />
                        )}
                        {selectedFile != null && historyLogoImage == null &&(
                            <img src={selectedFile} width="auto" height="40" alt=""/>
                        )}
                        {selectedFile == null && historyLogoImage != null &&(
                            <img src={historyLogoImage} width="auto" height="40" alt=""/>
                        )}
                    </div>
                    <div className="report-information aside">
                            <div className="report-title">{reportTitle}</div>
                            <div className="report-date">
                                {startDate.toLocaleString("en-us", {month: "long"})}{" "}
                                {startDate.getDate()}, {startDate.getFullYear()}
                            </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="copyright">Copyright(c) Semcasting, Inc.</div>
                    <div className="page-number-wrapper">
                        Page {props.pageNum} of {props.totalPages}
                    </div>
                </div>
                {/*<div className="footer justify-content-start">*/}
                    {/*<div className="row d-flex inner-footer align-items-center">*/}
                        {/*<div className="col-5 text-nowrap copyright-wrap">*/}
                        {/*    <div className="copyright">Copyright(c) Semcasting, Inc.</div>*/}
                        {/*</div>*/}
                        {/*<div className="col-3 right">*/}
                        {/*    {reportFooter !== null && reportFooter !== "" ? (*/}
                        {/*        <div className="footer-container right">{reportFooter} </div>*/}
                        {/*    ) : (*/}
                        {/*        <div className="footer-container right">*/}
                        {/*            Page {props.pageNum} of {props.totalPages}*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        )
    };
    useEffect(()=>{
        let source = document.querySelector('div.nav-header');

        const resizeObserver = new ResizeObserver((entries) => {

            for (const entry of entries) {
                if(entry.target.style.width === '65px'){
                    console.log("Yes")
                    setMargin('-70px')
                }else{
                    setMargin('-300px')
                }

            }
        });
        resizeObserver.observe(source);


    },[])
    const history = useHistory();

    const pdfExportComponent = React.useRef(null);
    const location = useLocation();
    const [chartRelatedData, setChartRelatedData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [reportTitle, setReportTitle] = useState(null);
    const [reportFooter, setReportFooter] = useState(null);
    const [reportLogo, setReportLogo] = useState(null);
    const [categories, setCategories] = useState([]);
    const [defaultFile, setDefaultFile] = useState(
        "/images/legacy_audience_images/logo-black.png"
    );
    let groups = useState([]);
    const [reportNotes, setReportNotes] = useState(null);
    const [sortable, setSortable] = useState(null);
    const [parentNode, setParentNode] = useState(null);
    const [selectAll, setSelectAll] = useState("checked");

    const [convertedMarkerLocations, setConvertedMarkerLocations] = useState([]);
    const [convertedBubbleLocations, setConvertedBubbleLocations] = useState([]);
    const segment_id = location.state.segment_id;
    const imageHistoryData = JSON.parse(localStorage.getItem('imgData'));
    const profile_data_obsolete = location.state.profile_data_obsolete;

    let notestring = [];
    let sections = [];
    let order;
    let result;
    if (location.state) {
        let chartData = JSON.parse(location.state.data);
        //Remove Map chart until loading issues is resolved
        // chartData["report-detail"] = chartData["report-detail"].filter(obj => {
        //     return obj.reportTitle !== "Map";
        // })

    chartData["report-detail"].forEach((item) => {
        if (sections.indexOf(item["reportTitle"]) < 0) {
            sections.push(item["reportTitle"]);
        }
    });

    chartData["report-detail"].push({reportTitle: "Notes", rows: notestring});

    chartData["report-detail"]
        .map((e, i) => {
            if (sections.indexOf(e["reportTitle"]) < 0) {
                sections.push(e["reportTitle"]);
            }
            return i % 3 === 0 ? chartData["report-detail"].slice(i, i + 3) : null;
        })
        .filter((e) => {
            return e;
        });

        let numOfCharts = [...new Set(chartData["report-detail"].map(item => item.reportTitle))];

    groups = sections
        .map((e, i) => {
            return i % numOfCharts.length === 0 ? sections.slice(i, i + numOfCharts.length) : null;
        })
        .filter((e) => {
            return e;
        });
    result = sections.map((title) => ({title}));
    for (let i = 0; i < sections.length; i++) {
        result[i].id = i + 1;
    }
    }
    //Get the Logo Image List From LocalStorage
    let logoHistoryList = JSON.parse(localStorage.getItem("imgData")) === null ? []:JSON.parse(localStorage.getItem("imgData"));

    function getBase64Image(img) {
        let canvas = document.createElement("canvas");
        const newLogoimage = new Image(180,30);
        newLogoimage.src = img;
        canvas.width = img.width;
        canvas.height = img.height;

        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0,0, newLogoimage.width, newLogoimage.height);

        let dataURL = canvas.toDataURL();

        return dataURL.replace(/^data:image\/(png|jpg|jpeg|svg);base64,/, "");
    }

    const [logoFileName, setLogoFileName] = useState(null);

    useEffect(() => {
        setParentNode(document.getElementById("container").parentNode);
        setSortable(result);
        if (location.state) {
            setCategories(sections);
            let chartJsonData = JSON.parse(location.state.data);
            if (location.state.notes) {
                notestring.push(location.state.notes);
            } else {
                notestring.push("");
            }

            chartJsonData["report-detail"].push({
                reportTitle: "Notes",
                rows: notestring,
            });
            chartJsonData = chartJsonData["report-detail"];

            // console.log(chartJsonData);
            let chartData = {};
            /* Activate Segment Summary */
            let activateSegmentSummaryData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Activate Segment Summary";
            });
            let labels = [];
            let values = [];
            let rows = [];

            const removeObjectsWithValueZero = (obj) => {
                for (const [key, value] of Object.entries(obj)) {
                    if (value === 0) {
                        delete obj[key];
                    }
                }
                return obj;
            };

            if (activateSegmentSummaryData) {
                chartData["Activate Segment Summary"] = [];
                chartData["Activate Segment Summary"][0] = {
                    rows: removeObjectsWithValueZero(activateSegmentSummaryData[0].rows[0]),
                };
                chartData["Activate Segment Summary"]["active"] = true;
                chartData["Activate Segment Summary"][1] = {
                    rows: removeObjectsWithValueZero(activateSegmentSummaryData[1].rows[0]),
                };
            }
            labels = [];
            values = [];

            const headers = [
                "NAICS",
                "Industry",
                "Count",
                "Value",
                "Match",
                "Organization",
                "Revenue in millions",
                "Rank",
                "Category",
                "Title",
                "Platform",
                "Locations",

            ]; /*************** Headers ****************/

            /************************************ BusinessNAICS Data ************************************/
            let topBusinessNAICSData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Top Business NAICS";
            })[0];
            if (topBusinessNAICSData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testNAICS = headers.some((e) =>
                    Object.values(topBusinessNAICSData.rows[0]).includes(e)
                );

                if (testNAICS) {
                    rows = topBusinessNAICSData.rows.slice(1);
                } else {
                    rows = topBusinessNAICSData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["NAICS"]);
                    values.push(item["Count"]);
                });
                chartData["Top Business NAICS"] = {
                    headers: topBusinessNAICSData.header,
                    rows: rows,
                    active: true,
                };
                chartData["Top Business NAICS"].labels = labels;
                chartData["Top Business NAICS"].tableHeaders = [
                    "NAICS",
                    "Industry",
                    "Count",
                ];
                chartData["Top Business NAICS"].values = values;
                chartData["Top Business NAICS"].backgroundColor = chartInfo.BusinessThemeDictionary[0].barColor1;
            }
            labels = [];
            values = [];

            /************************************ Employees Data ************************************/
            let employeesData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Employees";
            })[0];
            if (employeesData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testEmployees = headers.some((e) =>
                    Object.values(employeesData.rows[0]).includes(e)
                );

                if (testEmployees) {
                    rows = employeesData.rows.slice(1);
                } else {
                    rows = employeesData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["Match %"]);
                });
                chartData["Employees"] = {
                    headers: employeesData.header,
                    rows: rows,
                    active: true,
                };
                chartData["Employees"].labels = labels;
                chartData["Employees"].tableHeaders = ["Value", "Match %"];
                chartData["Employees"].values = values;
                chartData["Employees"].backgroundColor = chartInfo.BusinessThemeDictionary[0].barColor2;
            }
            labels = [];
            values = [];

            /************************************* Revenue Data ************************************/
            let revenueData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Revenue";
            })[0];
            if (revenueData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testRevenue = headers.some((e) =>
                    Object.values(revenueData.rows[0]).includes(e)
                );

                if (testRevenue) {
                    rows = revenueData.rows.slice(1);
                } else {
                    rows = revenueData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Value"]);
                    values.push(item["Match %"]);
                });
                chartData["Revenue"] = {
                    headers: revenueData.header,
                    rows: rows,
                    active: true,
                };
                chartData["Revenue"].labels = labels;
                chartData["Revenue"].tableHeaders = ["Value", "Match %"];
                chartData["Revenue"].values = values;
                chartData["Revenue"].backgroundColor = chartInfo.BusinessThemeDictionary[0].barColor3;
            }
            labels = [];
            values = [];

            /************************************* Business Organization Data *************************************/
            let topBusinessOrganizationsData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Top Business Organizations";
            })[0];
            if (topBusinessOrganizationsData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testBusinessOrg = headers.some((e) =>
                    Object.values(topBusinessOrganizationsData.rows[0]).includes(e)
                );

                if (testBusinessOrg) {
                    rows = topBusinessOrganizationsData.rows.slice(1);
                    // rows.forEach((v,i) => {return v.Organization = v.Organization.split(0,1)})
                    // rows.forEach((v,i) => {console.log(v)})
                } else {
                    rows = topBusinessOrganizationsData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Organization"]);
                    values.push(item["Revenue in millions"]);
                });
                chartData["Top Business Organizations"] = {
                    headers: topBusinessOrganizationsData.header,
                    rows: rows,
                    active: true,
                };
                chartData["Top Business Organizations"].labels = labels;
                chartData["Top Business Organizations"].tableHeaders = [
                    "Organization",
                    "Revenue in millions",
                ];
                chartData["Top Business Organizations"].values = values;
                chartData["Top Business Organizations"].backgroundColor = chartInfo.BusinessThemeDictionary[0].barColor4;
            }
            /********************************* Top Contextual Samples Data *********************************/
            // let topContextualData = chartJsonData.filter((obj) => {
            //     return obj.reportTitle === "Top Contextual Samples";
            // })[0];

            let topContextualData;
            let topContextualCountsData;
            //Table Names Changed In Some Segments
            if(chartJsonData.find((obj) => obj.reportTitle === "Top Contextual Samples") !== undefined){
                topContextualData = chartJsonData.filter((obj) => {
                    return obj.reportTitle === "Top Contextual Samples";
                })[0];
            }else if(chartJsonData.find((obj) => obj.reportTitle === "Top Contextual Sample Counts") !== undefined){
                topContextualCountsData = chartJsonData.filter((obj) => {
                    return obj.reportTitle === "Top Contextual Sample Counts";
                })[0];
            }
            labels = [];
            values = [];
            rows = [];

            /*** Segment Includes Top Contextual Data/Not ***/
            if (topContextualData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testTopContextual = headers.some((e) =>
                    Object.values(topContextualData.rows[0]).includes(e)
                );

                if (testTopContextual) {
                    rows = topContextualData.rows.slice(1);
                } else {
                    rows = topContextualData.rows;
                }

                chartData["Top Contextual Samples"] = {
                    headers: topContextualData.header,
                    rows: rows,
                    active: true,
                };
            } else {
                console.log("No Top Contextual data included");
            }
            if (topContextualCountsData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testTopContextualCounts = headers.some((e) =>
                    Object.values(topContextualCountsData.rows[0]).includes(e)
                );

                if (testTopContextualCounts) {
                    rows = topContextualCountsData.rows.slice(1);
                } else {
                    rows = topContextualCountsData.rows;
                }

                chartData["Top Contextual Sample Counts"] = {
                    headers: topContextualCountsData.header,
                    rows: rows,
                    active: true,
                };
            } else {
                console.log("No Top Contextual Sample Counts data included");
            }
            /********************************* Top 20 Employees Title Data *********************************/
            let topEmployeeTitlesData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Top 20 Employee Titles";
            })[0];
            labels = [];
            values = [];
            rows = [];

            /*** Segment Includes Top Contextual Data/Not ***/
            if (topEmployeeTitlesData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testTopEmployeeTitles = headers.some((e) =>
                    Object.values(topEmployeeTitlesData.rows[0]).includes(e)
                );

                if (testTopEmployeeTitles) {
                    rows = topEmployeeTitlesData.rows.slice(1);
                } else {
                    rows = topEmployeeTitlesData.rows;
                }

                chartData["Top 20 Employee Titles"] = {
                    headers: topEmployeeTitlesData.header,
                    rows: rows,
                    active: true,
                };
            } else {
                console.log("No Top Employee Titles data included");
            }
            /********************************* Audience Supply Path Optimization Data *********************************/
            let audienceSupplyPathOptData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Audience Supply Path Optimization";
            })[0];
            labels = [];
            values = [];


            /*** Segment Includes Audience Supply Path Optimization Data/Not ***/
            if (audienceSupplyPathOptData) {
                /*** Remove Extra Header From Json File If Exist ***/
                const testAudienceSupPathOpt = headers.some((e) =>
                    Object.values(audienceSupplyPathOptData.rows[0]).includes(e)
                );

                if (testAudienceSupPathOpt) {
                    rows = audienceSupplyPathOptData.rows.slice(1);
                } else {
                    rows = audienceSupplyPathOptData.rows;
                }

                rows.forEach((item, i) => {
                    labels.push(item["Platform"]);
                    values.push(item["Match %"]);
                });
                chartData["Audience Supply Path Optimization"] = {
                    headers: audienceSupplyPathOptData.header,
                    rows: rows,
                    active: true,
                };
            } else {
                console.log("No Top Contextual data included");
            }
            chartInfo.audienceSupplyPathOptData.labels = labels;
            chartInfo.audienceSupplyPathOptData.datasets[0].data = values;
            chartInfo.audienceSupplyPathOptData.datasets[0].backgroundColor = chartInfo.BusinessThemeDictionary[0].pieColor;
            /********************************* Map Chart Info Start *********************************/
            let mapData = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Map";
            })[0];


            labels = [];
            values = [];
            rows = [];

            if(mapData && mapData.rows){
                if(mapData?.rows[0]?.markers.length > 0 || mapData?.rows[1]?.bubbles.length > 0){
                    setConvertedMarkerLocations(mapData?.rows[0]?.markers);
                    setConvertedBubbleLocations(mapData?.rows[1]?.bubbles);
                    chartData["Map"] = {
                        headers: mapData.header,
                        rows: rows,
                        active: true,
                    };
                }
            }



            /********************************* Marker Map Data Start *********************************/
            // const fetchMarkerMapData = async () => {
            //     const res = await semcastAxios.get(MARKER_MAP + segment_id,{withCredentials:true});
            //     // console.log(res);
            //     setConvertedMarkerLocations(res.data.data);
            // };
            // fetchMarkerMapData().catch((err) => console.error(err));



            /********************************* Marker Map Data End *********************************/
            /********************************* Bubble Map Data Start *********************************/
            // const fetchBubbleMapData = async () => {
            //     const res = await semcastAxios.get(BUBBLE_MAP + segment_id,{withCredentials:true});
            //     // console.log(res);
            //     setConvertedBubbleLocations(res.data.data);
            // };
            // fetchBubbleMapData().catch((err) => console.error(err));


            /********************************* Bubble Map Data End *********************************/

            /************************************* Notes Data ************************************/

            let noteContent = chartJsonData.filter((obj) => {
                return obj.reportTitle === "Notes";
            })[0];
            rows = [];

            /*** Segment Includes Top Contextual Data/Not ***/
            if (noteContent) {
                /*** Remove Extra Header From Json File If Exist ***/
                // const testNotes = headers.some((e) =>
                //     Object.values(noteContent.rows[0]).includes(e)
                // );
                //
                // if (testNotes) {
                //     rows = noteContent.rows.slice(1);
                // } else {
                    rows = noteContent.rows;
                // }
                chartData["Notes"] = {
                    headers: noteContent.header,
                    rows: rows,
                    active: true,
                };
            } else {
                console.log("No Notes Were Included");
            }

            try {
                if (location.state.notes) {
                    setReportNotes(location.state.notes);
                } else {
                    setReportNotes("");
                }
            } catch (e) {
                setReportNotes(e);
            }

            //////////////////////////////////////////////////////////////////////////////////////////////////////
            setChartRelatedData(chartData);
            // console.log(chartData)
            setReportTitle(location.state.segment_name);
        }
    }, []);
    /*******************************Sortable Item UI Start**********************************/
    console.log(chartRelatedData)
    const SortableItemUI = (props) => {
        const {isDisabled, isActive, style, attributes, dataItem, forwardRef} =
            props;
        const classNames = ["col-xs-6 col-sm-3"];
        if (isDisabled) {
            classNames.push("k-disabled");
        }
        return (
            <div
                ref={forwardRef}
                {...attributes}
                style={{
                    ...getBaseItemStyle(isActive),
                    ...style,
                }}
                className={classNames.join(" ")}
            >
                {dataItem.title}
            </div>
        );
    };

    const getBaseItemStyle = (isActive) => ({
        height: 30,
        lineHeight: "30px",
        fontSize: "16px",
        textAlign: "center",
        outline: "none",
        border: "1px solid",
        cursor: "move",
        display: "block",
        width: 300,
        background: isActive ? "#fff" : "#00424D",
        color: isActive ? "#00424D" : "white",
        borderColor: isActive ? "#00424D" : "white",
    });

    const onDragOver = (event) => {
        setSortable(event.newState);
    };

    /*******************************Sortable Item UI End**********************************/
    /*******************************Color Palette UI Start**********************************/
    const naicsRef = React.createRef();
    const organizationsRef = React.createRef();
    const revenueRef = React.createRef();
    const employeesRef = React.createRef();
    const audiencePieRef = React.createRef();
    const handleAllChartThemeChange = (event) => {
        try {
            naicsRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor1;
            naicsRef.current.update();

            employeesRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor2;
            employeesRef.current.update();

            revenueRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor3;
            revenueRef.current.update();

            organizationsRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.barColor4;
            organizationsRef.current.update();

            audiencePieRef.current.config._config.data.datasets[0].backgroundColor = event.target.value.pieColor;
            audiencePieRef.current.update();
        }catch (e) {
            console.log("handleAllChartThemeChange: " + e)
        }
    }
    /*******************************Color Palette UI End**********************************/
    const backToDashboard = (e) => {
        history.push("/segmentation-dashboard");
    }

    const handleRegenerateReport = async (segmentId) => {
        try {
            // setDisabled(true);
            let response = await semcastAxios.post(SEGMENT_REPORT_REGENERATE_API + segmentId + '/regenerate' , {
                withCredentials: true,
            });
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Regenerating Report. Please try again after regenerate process completes.", 30);
                // setDisabled(false);
                return response.data;
            }
        } catch (error) {
            if(error.response.status === 401 || error.response.status === 403){
                showErrorToastMessage(error + " You did not have the permission to proceed, please contact support", 15);
            }else{
                showErrorToastMessage(error + " Please contact support.", 15);
            }
            // setDisabled(false);
        }
    }
    const pdfFileDownload = async () => {
        try{
            let response = await semcastAxios.get(DOWNLOAD_PROFILE.replace(SEGMENT_ID_PLACE_HOLDER, segment_id), {withCredentials: true, responseType: 'blob'}) //Specify blob file type
            if (response.status === 200) {
                showSuccessToastMessage("Downloading", 1);
                const url = window.URL.createObjectURL(response.data);
                const a = document.createElement('a');
                a.href = url;
                a.download = reportTitle + "_" + new Date().getFullYear() + (new Date().getMonth() + 1) + new Date().getDate() + "_" + Math.floor(Date.now() / 1000) + ".pdf" //Give File Name
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log(error.response)
            if(error.response.status === 404){
                showErrorToastMessage("404: Missing mandatory JSON object: The file is out of date, please try again after your Profile Report completes.", 5);
            }else if (error.response.status === 500){
                showErrorToastMessage("500: Internal error, please try again later", 5);
            }
        }
    };

    const jsonFileDownload = () => {
        const json_data = location.state.data;
        const fileName = reportTitle + "_" + new Date().getFullYear() + (new Date().getMonth() + 1) + new Date().getDate() + "_" + Math.floor(Date.now() / 1000) + ".json";
        const data = new Blob([(json_data)], { type: "text/json" });
        const jsonURL = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = jsonURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
    };

    const ref = React.createRef();

    const [selectedFile, setSelectedFile] = useState(null);
    const onFileChange = (event) => {
        setHistoryLogoImage(null);
        setLogoFileName(event.target.files[0].name);
        const fileType = event.target.files[0].name.split(".")[1];
        if (
            fileType === "png" ||
            fileType === "jpeg" ||
            fileType === "jpg" ||
            fileType === "svg"
        ) {
            const totalBytes = event.target.files[0].size;
            const sizeInMB = totalBytes / 1000000;
            if (sizeInMB < 5) {
                setSelectedFile(URL.createObjectURL(event.target.files[0]));
                document.getElementById("defaultlogo").checked = false;
            } else {
                event.target.value = null;
                alert("File size should be less than 5MB");
            }
        } else {
            event.target.value = null;
            alert("File type should be in png, jpeg, jpg, svg formats.");
        }
    };
    const handleLogoCheckbox = (e) => {
        if (e.target.checked === true) {
            setDefaultFile(defaultFile);
            setSelectedFile(null);
            document.getElementById("uploadedlogo").value = "";
            setHistoryLogoImage(null);
        } else {
            return;
        }
    };

    let container = document.getElementById("container");

    const handleSelectAllFn = (e) => {
        if (e.target.checked === true) {
            for (let i = 0; i < sections.length; i++) {
                chartRelatedData[sections[i]]["active"] = true;
            }
            for (let j = 0; j < groups.length; j++) {
                for (let k = 0; k < groups[j].length; k++) {
                    document.getElementById("check" + j + k).checked = true;
                }
            }
        } else {
            for (let i = 0; i < sections.length; i++) {
                chartRelatedData[sections[i]]["active"] = false;
                console.log(chartRelatedData[sections[i]]["active"])
            }
            for (let j = 0; j < groups.length; j++) {
                for (let k = 0; k < groups[j].length; k++) {
                    document.getElementById("check" + j + k).checked = false;
                }
            }
        }
        const memo1 = sections.filter((e) => chartRelatedData[e]["active"] === true);
        setSortable(result.filter((obj) => memo1.includes(obj.title)));
    };

    const handleSelectFn = (e) => {
        if (e.target.checked === false) {
            chartRelatedData[e.target.value]["active"] = false;
            const index = sections.indexOf(e.target.value);

            if (index !== -1) {
                sections.splice(index, 1);
            }

            document.getElementById("selectAll").checked = false;
            document.getElementById("selectAll").indeterminate = true;

            let lengthUnchecked = Object.keys(
                sections.filter((e) => chartRelatedData[e]["active"] === false)
            ).length + 1;

            if (sortable) {
                document.getElementById("selectAll").checked = false;
                document.getElementById("selectAll").indeterminate = true;
            } else if (lengthUnchecked === Object.keys(chartRelatedData).length) {
                document.getElementById("selectAll").checked = false;
            }
        } else {
            document.getElementById("selectAll").indeterminate = true;
            chartRelatedData[e.target.value]["active"] = true;
            sections.push(e.target.value);

            if (sortable.length === Object.keys(chartRelatedData).length - 1) {
                document.getElementById("selectAll").checked = true;
                document.getElementById("selectAll").indeterminate = false;
            }
        }

        const memo2 = sections.filter((e) => chartRelatedData[e]["active"] === true);
        setSortable(result.filter((obj) => memo2.includes(obj.title)));

        if (
            Object.keys(
                sections.filter((e) => chartRelatedData[e]["active"] === true)
            ).length === 0
        ) {
            document.getElementById("selectAll").checked = false;
            document.getElementById("selectAll").indeterminate = false;
        }
    };
    const handleResetFn = () => {
        const memo3 = sections.filter((e) => chartRelatedData[e]["active"] === true);
        setSortable(result.filter((obj) => memo3.includes(obj.title)));
    };

    const idFinder = (chartTitle) => {
        const chart = sortable.find((chart) => chart.title === chartTitle);
        return chart ? chart.id : null;
    }

    const logoHistDefaultItem = {text: "Choose From History ...", file_base64:null};
    const [historyLogoImage, setHistoryLogoImage] = useState(null);
    useEffect(()=>{

    },[historyLogoImage, logoFileName])

    const onImgHistListChange = (e) => {
        try{
            document.getElementById("defaultlogo").checked = false;
            document.getElementById("uploadedlogo").value="";
            setSelectedFile(null);
            if(e.target.value.text === "Choose From History ..."){
                setHistoryLogoImage( e.target.value.file_base64);
            }else{
                setHistoryLogoImage("data:image/png/svg/jpg;base64," + e.target.value.file_base64);
            }
        }catch (e) {
            console.log(e)
        }
    }

    const saveChanges = () => {
        try {
            setCategories(
                sections.filter((e) => chartRelatedData[e]["active"] === true)
            );

            order = [sortable.map((e) => e.id)];

            for (let i = 0; i < order[0].length; i++) {
                console.log(typeof document.getElementById("div" + order[0][i]))
                container.appendChild(document.getElementById("div" + order[0][i]));
            }
            setChartRelatedData(chartRelatedData);
            let bannerImage = document.getElementById('uploaded-logo-img');
            let imgData = getBase64Image(bannerImage);
            let imageObj = {
                text: "" + logoFileName,
                file_base64: imgData
            };
            logoHistoryList.unshift(imageObj)
            localStorage.setItem("imgData", JSON.stringify(logoHistoryList.slice(0,5)));
        } catch (e) {
            console.log(e);
        }
    };
    const cancelChanges = () => {
        window.location.reload();
    };
    /******************************* PDF Font Size **********************************/
    const initialStyles = `
        .k-pdf-export .table td,
                              .k-pdf-export .table p{
                                font-size: 20px !important;}
                              .k-pdf-export .table th {
                               font-size: 20px !important;}
                              .k-pdf-export .card h4{
                              font-size:26px !important;}
          `;
    const [styles, setStyles] = React.useState(initialStyles);

    const changeStyle = (e) => {
        let currStyle = '';

        switch (e.value) {
            case 7:
                currStyle = `.k-pdf-export .row.pdf-data.reports-data-content h4{
                                font-size: 24px !important;
                              }
                             .k-pdf-export .row.pdf-data.reports-data-content .table thead{
                              background-color: #ffffff;
                              font-size: 18px !important;
                            }
                            .k-pdf-export .row.pdf-data.reports-data-content td,
                            .k-pdf-export .row.pdf-data.reports-data-content strong,
                            .k-pdf-export .row.pdf-data.reports-data-content th,
                            .k-pdf-export .row.pdf-data.reports-data-content p {
                              font-size: 18px !important;
                              font-family:Helvetica, Arial, sans-serif !important;
                            }`;
                break;
            case 14:
                currStyle = `.k-pdf-export .row.pdf-data.reports-data-content h4{
                                font-size: 26px !important;
                                }
                             .k-pdf-export .row.pdf-data.reports-data-content .table thead{
                              background-color: #ffffff;
                              font-size: 20px !important;
                            }
                            .k-pdf-export .row.pdf-data.reports-data-content td,
                            .k-pdf-export .row.pdf-data.reports-data-content strong,
                            .k-pdf-export .row.pdf-data.reports-data-content th,
                            .k-pdf-export .row.pdf-data.reports-data-content p {
                              font-size: 20px !important;
                              font-family:Helvetica, Arial, sans-serif !important;
                            }`;
                break;
            case 21:
                currStyle = `.k-pdf-export .reports-data-content h4{
                                font-size: 28px !important;
                                }
                             .k-pdf-export .row.pdf-data.reports-data-content .table thead{
                              background-color: #ffffff;
                              font-size: 22px !important;
                            }
                            .k-pdf-export .row.pdf-data.reports-data-content td,
                            .k-pdf-export .row.pdf-data.reports-data-content strong,
                            .k-pdf-export .row.pdf-data.reports-data-content th,
                            .k-pdf-export .row.pdf-data.reports-data-content p {
                              font-size: 22px !important;
                              font-family:Helvetica, Arial, sans-serif !important;
                            }`;
                break;
            default:
        }
        setStyles(currStyle);
    };

    const center = [42.68, -71.11];
    let centerOfMap;
    // console.log(convertedMarkerLocations)
    // convertedMarkerLocations ? const centerOfMap = convertedMarkerLocations[0].location : [37.0902, -95.7129];
    // if(convertedMarkerLocations[0]){
    //     centerOfMap = convertedMarkerLocations[0].location;
        // console.log(centerOfMap)
    // }else{
        centerOfMap = [37.0902, -95.7129];
    // }

    //get FTP connections for download data
    const getSettings = async() => {
        try {
            const settings = await getFTPSettings();
            if (settings && settings.data.length) {
                setAllSavedSettings(settings.data);
                const setting = settings.data[settings.data.length - 1];
                if (setting) {
                    setCurrentConnection({
                        ...currentConnection,
                        ['text']: setting.connection_name,
                        ['id']: setting.id,
                    });
                } else {
                    setCurrentConnection({text: '', id: 0});
                }
            }
        } catch {
            showErrorToastMessage("Could not access Connection information.");
        } finally {
            setIsLoaded(true);
        }
    }
    useEffect(() => {
        getSettings();
    }, []);

    //Download data dialogue
    const toggleFileFormatDownloadDialogue = () => {
        setToggleDialogOpen(true);
    };

    const sendDataFile = async (segmentId, profileDataObsolete) => {
        const userId = JSON.parse(sessionStorage.getItem('user')).id;
        let getDataPayload = {
            user_id: userId,
            segment_id: segmentId,
            connection_id: currentConnection.id,
            data_format: currentFormat.text,
        }
        console.log(getDataPayload);
        if (profileDataObsolete){
            await handleRegenerateReport(segmentId);
        }else{
            let response = await semcastAxios.get(DOWNLOAD_PROFILE.replace(SEGMENT_ID_PLACE_HOLDER, segmentId), {withCredentials: true})
            // if profile json data is not null
            if (response.status === 200) {
                try{
                    let response = await semcastAxios.get(DOWNLOAD_DELIMITED_DATA.replace(SEGMENT_ID_PLACE_HOLDER, segment_id),{params: getDataPayload, withCredentials: true})
                    if (response.status === 200) {
                        showSuccessToastMessage("File Sent", 5);
                    }
                } catch (error) {
                    // console.log(error.response)
                    if(error.response.status === 404){
                        showErrorToastMessage("404: Missing mandatory JSON object: The file is out of date, please try again after your profile report regenerate completes.", 15);
                    }else if (error.response.status === 500){
                        showErrorToastMessage("500: Internal error, please try again later", 15);
                    }
                }
            }
        }
    }
    const connections = allSavedSettings?.map((item, i) => {
        if (allSavedSettings.length > 0) {
            return (
                {text: item.connection_name, id: item.id}
            )
        } else {
            return (
                {text: "No Saved Connections", id: 0}
            )
        }
    });
    const handleDataFormatSelectionChange = (e) => {
        console.log(e)
        setCurrentFormat(e.value);
    }
    const handleConnectionChange = (e) => {
        console.log(e.value);
        setCurrentConnection({
            ...currentConnection,
            ['text']: e.value.text,
            ['id']: e.value.id
        });
    };
    const dataFormats = [
        {
            text: "CSV",
            id: 1,
        },
        {
            text: "Tab",
            id: 2,
        },
        {
            text: "Pipe",
            id: 3,
        }];


    return (
        <>
            {toggleDialogOpen && (
                <Dialog title={"Download Data - " + location.state.segment_name}
                        width={450}
                        closeIcon={false}
                        className={"dialog-standard download-data"}>
                    <div>
                        <Label className={"data-format-dropdown required-asterisk"}>Delimited Format</Label>
                    </div>
                    <div className={"pb-3"}>
                        <DropDownList
                            data={dataFormats}
                            textField="text"
                            dataItemKey="id"
                            onChange={(e) => handleDataFormatSelectionChange(e)}
                            value={currentFormat}
                            fillMode={"outline"}
                            className="connection-dialogue"
                            disabled={allSavedSettings.length === 0}
                            style={{fontSize: "14px"}}
                        />
                    </div>
                    <div>
                        <Label className={"connection-label required-asterisk"}>Direct Deliver To
                            Connection</Label>
                    </div>
                    <div>
                        {isLoaded ? (
                            <DropDownList
                                data={connections}
                                // itemRender={itemRender}
                                textField="text"
                                dataItemKey="id"
                                onChange={(e) => handleConnectionChange(e)}
                                value={currentConnection}
                                fillMode={"outline"}
                                className="connection-dialogue"
                                disabled={allSavedSettings.length === 0}
                                style={{fontSize: "14px"}}
                            />
                        ) : (
                            <Loader height={"100%"}/>
                        )}
                    </div>
                    <DialogActionsBar layout={"end"}>
                        <Button
                            className={"button-standard button-white"}
                            onClick={() => {
                                setToggleDialogOpen(false);
                            }}>
                            Cancel
                        </Button>
                        <Button
                            // disabled={disableSubmit}
                            className={"button-standard button-submit"}
                            onClick={()=> sendDataFile(segment_id, profile_data_obsolete)}>
                            Submit
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
            <div className="business-report-dashboard sticky">
                <div className="report-dashboard segment-name">
                    <button
                        onClick={backToDashboard}
                        type="button"
                        className="generateBtn mr-1 btn btn-rounded btn-primary bnt-block custom-btn"
                    >
                        Back
                    </button>
                    {location.state.segment_name}
                </div>
                <div className="color-dropdown prevent-split">
                    <span>Change Theme:&nbsp;</span>
                    <DropDownList
                        className={"color-dropdown-component"}
                        data={chartInfo.BusinessThemeDictionary}
                        textField="name"
                        dataItemKey="id"
                        defaultValue={chartInfo.BusinessThemeDictionary[0]}
                        onChange={handleAllChartThemeChange}
                        style={{width: "160px"}}
                    />
                </div>
                <div className="report button-group">
                    <button
                        onClick={pdfFileDownload}
                        type="button"
                        className="generateBtn mr-1 btn btn-rounded btn-primary bnt-block custom-btn"
                    >
                        Generate PDF
                    </button>
                    <button
                        type="button"
                        className="editBtn btn btn-rounded btn-primary bnt-block custom-btn"
                        data-bs-toggle="modal"
                        data-bs-target=".bd-example-modal-lg"
                    >
                        Edit Profile
                    </button>
                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    className="editBtn btn btn-rounded btn-primary bnt-block custom-btn"*/}
                    {/*    onClick={jsonFileDownload}*/}
                    {/*>*/}
                    {/*    <i className="fa fa-arrow-down"></i> JSON*/}
                    {/*</button>*/}
                    <button
                        type="button"
                        className="editBtn btn btn-rounded btn-primary bnt-block custom-btn"
                        onClick={toggleFileFormatDownloadDialogue}
                    >
                        <i className="fa fa-arrow-down"></i> Data
                    </button>
                </div>
            </div>
            <div className="reports-display-content">
                {location.state && (
                    <>
                        {/*************** Pop Up Dialog ********************/}
                        <div
                            className="modal fade bd-example-modal-lg"
                            tabindex="-1"
                            role="dialog"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Edit Profile</h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-bs-dismiss="modal"
                                        >
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            {/*<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3 logo-section">*/}
                                            {/*    <div className="edit-dialogue-labels">Customize Report Logo</div>*/}
                                            {/*    <div className="indent-wrapper-logo">*/}
                                            {/*        <div className="form-check mb-2">*/}
                                            {/*            <input*/}
                                            {/*                type="checkbox"*/}
                                            {/*                id="defaultlogo"*/}
                                            {/*                className="form-check-input k-checkbox"*/}
                                            {/*                defaultChecked="true"*/}
                                            {/*                onChange={(e) => handleLogoCheckbox(e)}*/}
                                            {/*            />*/}
                                            {/*            <label className="form-check-label">*/}
                                            {/*                Use Default Logo*/}
                                            {/*            </label>*/}
                                            {/*            <img*/}
                                            {/*                className="logo-default-display"*/}
                                            {/*                src={defaultFile}*/}
                                            {/*                alt=""*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*        <div className="input-group" style={{width:"auto"}}>*/}
                                            {/*            <input*/}
                                            {/*                type="file"*/}
                                            {/*                id="uploadedlogo"*/}
                                            {/*                className="form-control  input-rounded"*/}
                                            {/*                placeholder="file name"*/}
                                            {/*                aria-label="Upload Logo"*/}
                                            {/*                aria-describedby="basic-addon2"*/}
                                            {/*                onChange={onFileChange}*/}
                                            {/*            />*/}
                                            {/*            {selectedFile != null && (*/}
                                            {/*                <img*/}
                                            {/*                    id={"uploaded-logo-img"}*/}
                                            {/*                    className="custom-logo-display"*/}
                                            {/*                    src={selectedFile}*/}
                                            {/*                    alt=""*/}
                                            {/*                />*/}
                                            {/*            )}*/}
                                            {/*        </div>*/}
                                            {/*        <div className="logo-tooltip"  style={{paddingBottom: "10px"}}>*/}
                                            {/*            For best result, use picture that is width:height = 8:1.*/}
                                            {/*            Max File Size is 5 MB. Allowed File Types are jpeg, jpg,*/}
                                            {/*            png, svg. Logos saved in this edit dialogue can be found in the dropdown list in the future. Last 5 items will be saved.*/}
                                            {/*        </div>*/}
                                            {/*        <div className="input-group">*/}
                                            {/*            <DropDownList*/}
                                            {/*                style={{*/}
                                            {/*                    width: "330px",*/}
                                            {/*                }}*/}
                                            {/*                id={"logo-history-dropdown"}*/}
                                            {/*                data={imageHistoryData === null ? []:imageHistoryData}*/}
                                            {/*                defaultItem={logoHistDefaultItem}*/}
                                            {/*                onChange={onImgHistListChange}*/}
                                            {/*                textField="text"*/}
                                            {/*            />*/}
                                            {/*            {historyLogoImage != null &&*/}
                                            {/*                <img*/}
                                            {/*                    id={"history-logo-img"}*/}
                                            {/*                    className="custom-logo-display"*/}
                                            {/*                    src={historyLogoImage}*/}
                                            {/*                    alt=""*/}
                                            {/*                    style={{height: "30px"}}*/}
                                            {/*                />}*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div*/}
                                            {/*    className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-2 col-xs-12">*/}
                                            {/*    <div className="edit-dialogue-labels">Profile Report Title</div>*/}
                                            {/*    <div className="indent-wrapper">*/}
                                            {/*        <div className="form-group">*/}
                                            {/*            <input*/}
                                            {/*                type="text"*/}
                                            {/*                className="form-control input-rounded"*/}
                                            {/*                placeholder="Report Title"*/}
                                            {/*                value={reportTitle}*/}
                                            {/*                onChange={(e) => setReportTitle(e.target.value)}*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div*/}
                                            {/*    className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-2 col-xs-12">*/}
                                            {/*    <div className="edit-dialogue-labels">Report Footer</div>*/}
                                            {/*    <div className="indent-wrapper">*/}
                                            {/*        <div className="form-group">*/}
                                            {/*            <input*/}
                                            {/*                type="text"*/}
                                            {/*                className="form-control input-rounded"*/}
                                            {/*                placeholder=""*/}
                                            {/*                value={reportFooter}*/}
                                            {/*                onChange={(e) => setReportFooter(e.target.value)}*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div*/}
                                            {/*    className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-2 col-xs-12">*/}
                                            {/*    <div className="edit-dialogue-labels">Profile Date</div>*/}
                                            {/*    <div className="indent-wrapper">*/}
                                            {/*        <div className="form-group">*/}
                                            {/*            <DatePicker*/}
                                            {/*                selected={startDate}*/}
                                            {/*                onChange={(date) => setStartDate(date)}*/}
                                            {/*                className="form-control input-rounded report-date"*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="include-exclude-outerwrapper">
                                                <div className="include-exclude-section-wrapper">
                                                    <div className="include-exclude-header edit-dialogue-labels">
                                                        <div className="label-option">
                                                            <label>Include/Exclude Items</label>
                                                        </div>
                                                        <div className="label-select-all">
                                                            <input
                                                                type="checkbox"
                                                                className="selectall-checkbox form-check-input k-checkbox"
                                                                id={"selectAll"}
                                                                value={selectAll}
                                                                onClick={(e) => {
                                                                    handleSelectAllFn(e);
                                                                }}
                                                                defaultChecked="true"
                                                            />
                                                            <label>Select All</label>
                                                        </div>
                                                    </div>
                                                    <div className="indent-wrapper">
                                                        <div className="include-exclude-wrapper">
                                                            {groups.length > 0 &&
                                                                groups?.map((items, groupIndex) => {
                                                                    return (
                                                                        <div className="form-group">
                                                                            {items?.map((item, itemIndex) => {
                                                                                return (
                                                                                    <div className="form-check mb-2">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input k-checkbox"
                                                                                            id={"check" + groupIndex + itemIndex}
                                                                                            value={item}
                                                                                            onClick={(e) => {
                                                                                                handleSelectFn(e);
                                                                                            }}
                                                                                            defaultChecked="true"
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            for={"check" + groupIndex + itemIndex}
                                                                                        >
                                                                                            {item}
                                                                                        </label>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="include-exclude-section-wrapper">
                                                    <div className="edit-dialogue-labels">
                                                        Rearrange Chart Order
                                                        <button
                                                            type="button"
                                                            className="reset-btn btn"
                                                            onClick={handleResetFn}
                                                        >
                                                            <i className="fa fa-solid fa-rotate-right"/>
                                                        </button>
                                                    </div>
                                                    <div className="rearrange-wrapper pb-3">
                                                        <div className="indent-wrapper">
                                                            <div className="sortable-list">
                                                                <Sortable
                                                                    idField={"id"}
                                                                    data={sortable}
                                                                    itemUI={SortableItemUI}
                                                                    onDragOver={onDragOver}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-3">*/}
                                            {/*    <div className="edit-dialogue-labels">Change PDF Font Size</div>*/}
                                            {/*    <div className="indent-wrapper">*/}
                                            {/*        <Slider*/}
                                            {/*            buttons={true}*/}
                                            {/*            step={7}*/}
                                            {/*            defaultValue={14}*/}
                                            {/*            min={7}*/}
                                            {/*            max={21}*/}
                                            {/*            width={220}*/}
                                            {/*            onChange={changeStyle}*/}
                                            {/*        >*/}
                                            {/*            <SliderLabel position={7}>Small</SliderLabel>*/}
                                            {/*            <SliderLabel position={14}>Medium</SliderLabel>*/}
                                            {/*            <SliderLabel position={21}>Large</SliderLabel>*/}
                                            {/*        </Slider>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div
                                                className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-3">
                                                <div className="edit-dialogue-labels">Notes</div>
                                                <div className="indent-wrapper">
                                                <textarea
                                                    className="form-control input-rounded notes"
                                                    placeholder="Report Notes"
                                                    rows={7}
                                                    cols={40}
                                                    value={reportNotes}
                                                    onChange={(e) => setReportNotes(e.target.value)}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn light btn-primary btn-rounded "
                                            data-bs-dismiss="modal"
                                            onClick={cancelChanges}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-rounded btn-primary bnt-block"
                                            data-bs-dismiss="modal"
                                            onClick={saveChanges}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid" ref={ref}>
                            <PDFExport
                                ref={pdfExportComponent}
                                scale={0.43}
                                paperSize="A4"
                                multiPage={true}
                                // margin={{top: 35, left: 0, right: 0, bottom: 50}}
                                keepTogether=".prevent-split"
                                forcePageBreak=".page-break"
                                title="The title of the pdf"
                                reportTitle="The business report"
                                reportLogo={reportLogo}
                                startDate={startDate}
                                pageTemplate={PageTemplate}
                                fileName={
                                    location.state.segment_name +
                                    "_" +
                                    new Date().getFullYear() +
                                    (new Date().getMonth() + 1) +
                                    new Date().getDate() +
                                    "_" +
                                    Math.floor(Date.now() / 1000)
                                }
                            >
                                <PDFMargin top="15mm" bottom="15mm"/>
                                <div
                                    className="row pdf-data reports-data-content"
                                    id="container"
                                >
                                    <div id="stylesHolder">
                                        <style>{styles}</style>
                                    </div>
                                    {chartRelatedData["Activate Segment Summary"] &&
                                        chartRelatedData["Activate Segment Summary"].active && (
                                            <ActivateSegmentSummary
                                                leftData={
                                                    chartRelatedData["Activate Segment Summary"][0].rows
                                                }
                                                rightData={
                                                    chartRelatedData["Activate Segment Summary"][1].rows
                                                }
                                                chartId={"div" + idFinder("Activate Segment Summary")}
                                            />
                                        )}
                                    {chartRelatedData["Top Business NAICS"] &&
                                        chartRelatedData["Top Business NAICS"].active && (
                                            <TopBusinessNAICs
                                                data={chartRelatedData["Top Business NAICS"]}
                                                chartId={"div" + idFinder("Top Business NAICS")}
                                                naicsRef={naicsRef}
                                            />
                                        )}
                                    {chartRelatedData["Employees"] &&
                                        chartRelatedData["Employees"].active && (
                                            <Employees
                                                data={chartRelatedData["Employees"]}
                                                chartId={"div" + idFinder("Employees")}
                                                employeesRef={employeesRef}
                                            />
                                        )}
                                    {chartRelatedData["Revenue"] &&
                                        chartRelatedData["Revenue"].active && (
                                            <Revenue
                                                data={chartRelatedData["Revenue"]}
                                                chartId={"div" + idFinder("Revenue")}
                                                revenueRef={revenueRef}
                                            />
                                        )}
                                    {chartRelatedData["Top Business Organizations"] &&
                                        chartRelatedData["Top Business Organizations"].active && (
                                            <TopBusinessOrganizations
                                                data={chartRelatedData["Top Business Organizations"]}
                                                chartId={"div" + idFinder("Top Business Organizations")}
                                                organizationsRef={organizationsRef}
                                            />
                                        )}
                                    {chartRelatedData["Top Contextual Samples"] &&
                                        chartRelatedData["Top Contextual Samples"].active && (
                                            <div className=" prevent-split">
                                            <TopContextualSamples
                                                data={chartRelatedData["Top Contextual Samples"]}
                                                chartId={"div" + idFinder("Top Contextual Samples")}
                                            />
                                            </div>
                                        )}
                                    {chartRelatedData["Top Contextual Sample Counts"] &&
                                        chartRelatedData["Top Contextual Sample Counts"].active && (
                                            <div className=" prevent-split">
                                                <TopContextualSampleCounts
                                                    data={chartRelatedData["Top Contextual Sample Counts"]}
                                                    chartId={"div" + idFinder("Top Contextual Sample Counts")}
                                                />
                                            </div>
                                        )}
                                    {chartRelatedData["Top 20 Employee Titles"] &&
                                        chartRelatedData["Top 20 Employee Titles"].active && (
                                            <TopEmployeeTitles
                                                data={chartRelatedData["Top 20 Employee Titles"]}
                                                chartId={"div" + idFinder("Top 20 Employee Titles")}
                                            />
                                        )}
                                    {chartRelatedData["Audience Supply Path Optimization"] &&
                                        chartRelatedData["Audience Supply Path Optimization"].active && (
                                            <AudienceSupplyPathOpt
                                                data={chartRelatedData["Audience Supply Path Optimization"]}
                                                chartId={"div" + idFinder("Audience Supply Path Optimization")}
                                                audiencePieRef={audiencePieRef}
                                            />
                                        )}
                                    {/*{chartRelatedData["Bubble Map"] &&*/}
                                    {/*    chartRelatedData["Bubble Map"].active && (*/}
                                    {/*        <BusinessBubbleMap*/}
                                    {/*            data={convertedBubbleLocations}*/}
                                    {/*            chartId={"div" + idFinder("Bubble Map")}*/}
                                    {/*        />*/}
                                    {/*    )}*/}
                                    {/*{chartRelatedData["Map"] &&*/}
                                    {/*    chartRelatedData["Map"].active && (*/}
                                    {/*        <BusinessMap*/}
                                    {/*            markerData={convertedMarkerLocations}*/}
                                    {/*            bubbleData={convertedBubbleLocations}*/}
                                    {/*            center={centerOfMap}*/}
                                    {/*            chartId={"div" + idFinder("Map")}*/}
                                    {/*        />*/}
                                    {/*    )}*/}
                                    {chartRelatedData["Notes"] &&
                                        chartRelatedData["Notes"].active && (
                                            <BusinessNotes
                                                data={[chartRelatedData["Notes"], reportNotes]}
                                                chartId={"div" + idFinder("Notes")}
                                            />
                                        )}
                                </div>
                            </PDFExport>
                        </div>
                    </>
                )}
            </div>
            {/***********************************
             Content body end
             ************************************/}
            {/* </div> */}
            {/***********************************
             Main wrapper end
             ************************************/}
        </>
    );
}
