import React, {useEffect, useState} from "react";
import {FieldWrapper, Form, FormElement} from "@progress/kendo-react-form";
import {Input} from "@progress/kendo-react-inputs";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import {filterBy} from "@progress/kendo-data-query";
import "./Segments.css";

const SegmentsEdit = (props) => {

    const [controlsData, setControlsData] = useState([]);
    const [filteredSegments, setFilteredSegments] = useState([]);
    const [value, setValue] = useState();
    const [values, setValues] = useState(props.segmentsState ? props.segmentsState: []);
    const [newValues, setNewValues] = useState(props.newSegmentsState ? props.newSegmentsState:[]);
    const [opened, setOpened] = useState([]);

    const onSegmentDataChange = (e) => {
        // console.log(e);
        const searchValue = e.value;
        if (searchValue.length === 0) {
            setFilteredSegments([]);
            setOpened([]);
            return;
        }
        if (searchValue.length < 3) return;
        const filtered = props.selectedTile.filter((seg) => {
            return seg.description !== "All" &&
                seg.description.toLowerCase().includes(searchValue.toLowerCase());
        });
        setFilteredSegments(filtered);

    };
    function sortByCategory(objects) {
        return objects.sort((a, b) => {
            const categoryA = a.category.toLowerCase();
            const categoryB = b.category.toLowerCase();
            if (categoryA < categoryB) {
                return -1;
            }
            if (categoryA > categoryB) {
                return 1;
            }
            return 0;
        });
    }
    const buildControls = () => {
        let data = [];
        let categories = null;
        if (filteredSegments.length > 0) {
            categories = Array.from(new Set(filteredSegments.map((item) => item.segment_category)));
        } else {
            // categories = Array.from(new Set(props.data.map((item) => item.segment_category)));
            categories = Array.from(new Set(props.selectedTile.map((item) => item.segment_category)));
        }
        categories.forEach((category) => {
            let segmentOptions = [];
            let segments = null;
            if (filteredSegments.length > 0) {
                segments = filteredSegments.filter((el) => el.segment_category === category);
            } else {
                segments = props.selectedTile.filter((el) => el.segment_category === category);
            }
            const all = segments.filter((s) => {
                return s.description === "All";
            });
            // if (all.length > 0) segmentOptions.push({label:all[0].description, value:all[0].segment_id});
            // include 'All' option as first option for all controls.
            segmentOptions.push({label: "All", value: "All"});
            segments.forEach((seg) => {
                if (seg.description !== "All" && seg.segment_name !== "") {
                    // if (props.selectedTileConfig.groupType === "iqvia" || props.selectedTileConfig.groupType === "acxiomLifestyle" ||
                    //     props.selectedTileConfig.groupType === "purpleLabHCP" || props.selectedTileConfig.groupType === "spectrumMedia") {
                    //     segmentOptions.push({label: seg.segment_name, value: seg.segment_id, checked: false});
                    // } else {
                    //     segmentOptions.push({label: seg.description, value: seg.segment_id, checked: false});
                    // }
                    let s = !(seg.segment_name.includes(":")) ? seg.segment_name : seg.description;
                    segmentOptions.push({label: s, value: seg.segment_id, checked: false});
                    // segmentOptions.push({label: seg.segment_name, value: seg.segment_id, checked: false});
                }
            })
            data.push({category:category, options:segmentOptions});
        })
        data = sortByCategory(data);
        setControlsData(data);
        // console.log(controlsData);
    }

    const handleChange = (groupType, category, event) => {
        // console.log(category);
        if (event.value.length === 0) {
            delete values[category];
        } else {
            const idx = event.value.findIndex(e => e.label === "All");
            if (idx > -1) {
                const segments = event.target.props.data;
                const vals = segments.filter((e) => {
                    return e.label !== "All";
                });
                values[category] = vals;
                newValues[category] = vals;
            } else {
                values[category] = event.target.value;
                newValues[category] = event.target.value;
            }
            values[category].groupType = groupType;
            newValues[category].groupType = groupType;
            setValues(values);
            setNewValues(newValues);
        }
        let segments = [];
        Object.keys(values).forEach((category) => {
            (values[category]).forEach((segment) => {
                if (values[category].groupType === groupType) {
                    segments.push(segment.value);
                }
                values[category].targetType = props.selectedTileConfig.targetType;
            });
        });
        // props.formik.values.segment_design[props.selectedTileConfig.groupType] = segments;
        // console.log(props.selectedTileConfig.groupType);
        props.handleAudienceState(prevState => {
            return {...prevState, [props.selectedTileConfig.groupType]: segments}}
        );
        props.handleNewSegmentsState(newValues);
        props.handleSegmentsState(values, newValues);
        buildControls();
    };

    useEffect(() => {
        buildControls();
    }, []);

    useEffect(() => {
        buildControls();
    }, [filteredSegments]);

    return (
        <>
            <div className="segments-container">
                <Form render={formRenderProps => <FormElement style={{
                    width: "100%"
                }}>
                    <FieldWrapper style={{paddingBottom: "1.5rem"}}>
                        <Input
                            className={"fields"}
                            placeholder="Search / Filter Segments"
                            filterable={true}
                            onChange={onSegmentDataChange}
                            value={value}
                            size="large"
                            style={{width: "100%"}}
                        />
                    </FieldWrapper>
                </FormElement>}/>
            </div>

            <div className={"segments-group segments-content"}>
                {controlsData.map((data, index) => {
                    return (
                        <>
                        {filteredSegments.length === 0  ||
                            (filteredSegments.length > 0 && filteredSegments.filter((filtered) => {
                                if (filtered.segment_category.toLowerCase() === data.category.toLowerCase()) {
                                    opened[data.category] = true;
                                    return true;
                                }
                                // } else {
                                //     opened[data.category] = false;
                                // }
                            })) ? (
                            <MultiSelect
                                // className={"k-floating-label-container.k-label"}
                                label={data.category}
                                data={data.options}
                                // opened={opened[data.category]}
                                autoClose={false}
                                textField={"label"}
                                dataItemKey="value"
                                popupSettings={{height: 150}}
                                onChange={(e) => handleChange(props.selectedTileConfig.groupType, data.category, e)}
                                value={values[data.category] ? values[data.category] : []}
                                size={"large"}/>)
                            : ""
                        }
                        </>
                    )
                })}
            </div>
        </>
    )
}

export default SegmentsEdit;