import React, {useEffect, useState} from "react";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from "@progress/kendo-react-buttons";
import {RadioGroup} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";

import {
    semcastAxios,
    showErrorToastMessage,
    showSuccessToastMessage
} from "../../../Utils/Common";
import {
    ACTIVATION_ID_PLACE_HOLDER,
    SUCCESS_STATUS, ACTIVATION_SYNCHRONIZE
} from "../../../api/constants";
import "./Synchronize.css";
import {DropDownList} from "@progress/kendo-react-dropdowns";

const Synchronize = (props) => {
    const [optionType, setOptionType] = useState("");
    const [visible, setVisible] = useState(props.visible);
    const [disableSubmit, setDisableSubmit] = useState(true);

    const segment1ObjectType = props.synchronize.profileFormik?.values?.selectedSegments[0]?.objectType;
    const dateString = props.synchronize.profileFormik?.values?.selectedSegments[0].updatedAt;
    const completedDate = new Date(dateString);
    const dateOnly = completedDate.toLocaleDateString();
    const aqId = props.synchronize.profileFormik?.values?.selectedSegments[0]?.id;
    const dayList = [0,1,2,3,4,5,6,7];
    const [dayOption, setDayOption] = useState(dayList[0]);

    useEffect(() => {
        if(optionType.length > 0) {
            setDisableSubmit(false)
        }
    },[optionType])


    const handleOptionChange = (event) => {
        setOptionType(event.value);
    };

    const optionData = [
        {
            label: "Append ",
            value: "A",
        },
        {
            label: "Replace",
            value: "R",
        },
    ];
    const userId = props.synchronize.profileFormik?.values?.selectedSegments[0]?.userId;
    const synchronizeActivation = async () => {
        setDisableSubmit(true);
        try {
            const payload = {
                synchType: optionType,
                userId: userId,
                repeatEvery:dayOption
            };

            let response = await semcastAxios.post(ACTIVATION_SYNCHRONIZE.replace(ACTIVATION_ID_PLACE_HOLDER, aqId),
                payload,
                {withCredentials: true})
                .then(async (res) => {

                    if (res.data.status === SUCCESS_STATUS) {
                        showSuccessToastMessage("Synchronize Request Sent Successfully.",10);
                        setVisible(false);
                        props.reloadAfterSynchronize();
                    }else{
                        showErrorToastMessage(response?.data?.data || "Unknown Error");
                        props.reloadAfterSynchronize();
                    }
                }).then( (res) => {
                    props.reloadAfterSynchronize();
                });
            props.reloadAfterSynchronize();
        } catch (error) {
            showErrorToastMessage(error.response || "Unknown Error");
            console.log(error.response);
            props.reloadAfterSynchronize();
        } finally {
            setVisible(false);
            setDisableSubmit(false);
        }
    }


    const handleRepeatOptionChange = (e) =>{
       setDayOption(e.target.value);
    }

    return (
        <>
            <div id="synchronize-activation">
                {visible && (
                    <Dialog
                        title={"Synchronize - "+props.segName}
                        width={350}
                        closeIcon={false}
                        className={"dialog-standard synchronize-activation"}
                    >

                        <div style={{paddingLeft:"20px"}}>
                            <div>
                                <Label className={""}>Since: {dateOnly}</Label>
                            </div>
                            <br/>
                            <div>
                                <Label className={"required-asterisk"}>Select Option</Label>
                                <RadioGroup
                                    data={optionData}
                                    value={optionType}
                                    onChange={handleOptionChange}
                                />
                            </div>
                            <br/>
                            <div>
                                <Label className={"required-asterisk"}>Repeat every <DropDownList
                                    className={"day-dropdown"}
                                    defaultItem={dayList[0]}
                                    value={dayOption}
                                    onChange={(e) => handleRepeatOptionChange(e)}
                                    data={dayList}/> day</Label>{dayOption === 0 && <Label>(Will not be repeated)</Label>}
                            </div>
                            <br/>
                        </div>
                        <DialogActionsBar layout={"end"}>
                            <Button
                                className={"button-standard button-white"}
                                onClick={() => {
                                    props.cancelSubscriber(undefined)
                                }}>
                                Cancel
                            </Button>
                            <Button
                                disabled={disableSubmit}
                                className={"button-standard button-submit"}
                                onClick={synchronizeActivation}>
                                Submit
                            </Button>
                        </DialogActionsBar>
                    </Dialog>)}
            </div>
        </>
    );
}

export default Synchronize;
