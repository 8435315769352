import React, {useEffect, useRef, useState} from "react";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {DropDownList, MultiSelect} from "@progress/kendo-react-dropdowns";
import {Input, TextArea} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import {loadAccessibleGroups} from "../../../actions/UserActions";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {
    FILE_VALIDATE_API,
    SEGMENT_LOCATIONS_ZIP3_API,
    SEGMENT_LOCATIONS_ZIP5_API,
    SEGMENT_LOCATIONS_STATES_API,
    SEGMENT_LOCATIONS_CITIES_API,
    SEGMENT_LOOKALIKE_API,
    SUCCESS_STATUS
} from "../../../api/constants";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import "./LookalikeSegment.css";
import {Label} from "@progress/kendo-react-labels";

const LookalikeSegment = (props) => {
    // let lookalike_segment = props.lookalikeSegment.profileFormik.values.selectedSegments;
    // const defaultSegmentName = lookalike_segment[0].segment_name + " Lookalike";
    // const lookalike_segment_id = lookalike_segment[0].id;
    const defaultSegmentName = props.segName + " LOOKALIKE";
    const lookalike_segment_id = props.segId;

    const [visible, setVisible] = useState(props.visible);
    const [accessibleGroups, setAccessibleGroups] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);
    const [segmentName, setSegmentName] = useState(defaultSegmentName);
    const [groupValue, setGroupValue] = useState({});
    const [notes, setNotes] = useState("");
    const [uploadMessage, setUploadMessage] = useState("");
    const [isNameValid, setIsNameValid] = useState(true);
    const [isLocationValid, setIsLocationValid] = useState(false);
    const [isFileValid, setIsFileValid] = useState(true);
    const [locations, setLocations] = useState([]);
    const [unknownLocations, setUnknownLocations] = useState([]);
    const [value, setValue] = React.useState([]);

    const regex = RegExp(/^[a-zA-Z0-9_\- ]{3,64}$/);
    const REQUIRED_MESSAGE = "Name must be 3-64 chars containing letters, numbers, hyphens, spaces and underscores.";
    const NOTES_OPTIONAL_MESSAGE = "(Optional) Use Notes to write a brief description about this segment.";
    // const FILE_INFO_MESSAGE = "File extension must be .txt.  Place each entry on a new line.  Cities must be in this format - Boston, MA";
    const FILE_INFO_MESSAGE = "Upload file extension must be .txt. Cities must be in this format: Boston, MA. One entry per line. The target Look-a-like criteria can be no larger than a single state."

    const MAX_FILE_SIZE = 10000;

    useEffect(() => {
        setPageLoader(true);
        loadAccessibleGroups().then((groups) => {
            try {
                setAccessibleGroups(groups)
                setGroupValue(groups[0]);
            } catch (e) {
            } finally {
                setPageLoader(false)
            }
        })
    }, [])

    useEffect(() => {
    }, [props.segName])

    const lookalikeSegment = async () => {
        const LOOKALIKE_URL = SEGMENT_LOOKALIKE_API + lookalike_segment_id + '/lookalike';
        setPageLoader(true);
        let responseErrorMessage;
        try {
            const locationValues = getItemizedValues();
            const payload = {
                segment_name: segmentName,
                group_id: groupValue.id
            }
            if (notes) {
                payload.notes = notes
            }
            payload.segment_design = {
                // 09-21-23 RWK: John Change In 1x - FD:9688
                merge_type: "DISTINCT", // FD:9688 set on the server to avoid having to validate it
                target_type: "CONSUMER", //FD:9688 target_type should always be CONSUMER because lookalike segments only apply to CONSUMER segments
                primaryData: locationValues
            }
            try {
                let response = await semcastAxios.put(
                    LOOKALIKE_URL,
                    payload,
                    {
                        withCredentials: true,
                    }
                );
                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage("Segments Lookalike Request Sent Successfully.");
                    props.reloadAfterLookalike();
                }
            }catch(error){
                showErrorToastMessage(error.response.data.data, 5);
            }finally{
                props.reloadAfterLookalike();
                setPageLoader(true);
                setVisible(true);
            }

        } catch (error) {
            showErrorToastMessage("Segments Lookalike Failed: " + error, 5);
        } finally {
            props.reloadAfterLookalike();
            setPageLoader(true);
            setVisible(true);
            setPageLoader(false);
            setVisible(false);
        }
    };

    const handleSegmentNameChange = (event) => {
        const value = event.value;
        if (!regex.test(value)) {
            setIsNameValid(false);
        } else {
            setIsNameValid(true);
        }
        setSegmentName(value);
    };

    const handleLocationsChange = (event) => {
        setValue([...event.value]);
        if (event.value.length === 0) {
            setIsLocationValid(false);
        } else {
            setIsLocationValid(true);
        }
    };

    const buildOptionsList = (data, type) => {
        const optionsList = [];
        data.forEach((item) => {
            if (type === "ZIP3" || type === "ZIP5" || type === "STATE") {
                optionsList.push(item.location);
            } else if (type === "CITY") {
                optionsList.push(item.location+", "+item.state);
            }
        });
        setLocations([...optionsList]);
    };

    const filterChange = async (event) => {
        const searchTerm = (event.filter.value.toUpperCase());
        if (searchTerm.match(/^[0-9]{3}$/g)) { //if zip3
            let url = SEGMENT_LOCATIONS_ZIP3_API + "?location=" + searchTerm + "&orderby=location";
            let response = await semcastAxios.get(url, {withCredentials: true});
            // buildOptionsList(filterBy(response.data.data), event.filter);
            buildOptionsList(response.data.data, "ZIP3");
        } else if (searchTerm.match(/^[0-9]{4,5}$/g)) { //if full or partial zip code
            let url = SEGMENT_LOCATIONS_ZIP5_API + "?location=" + searchTerm + "&orderby=location";
            let response = await semcastAxios.get(url, {withCredentials: true});
            buildOptionsList(response.data.data, "ZIP5");
        } else if (searchTerm.match(/^[a-zA-Z]{2}$/gi)) { //if 2 letters
            let url = SEGMENT_LOCATIONS_STATES_API + "?location=" + searchTerm;
            let response = await semcastAxios.get(url, {withCredentials: true});
            buildOptionsList(response.data.data, "STATE");
        } else if (searchTerm.match(/[a-zA-Z]{3,}/gi)) {
            let url = SEGMENT_LOCATIONS_CITIES_API + "?location=" + searchTerm + "&orderby=state_name,location";
            let response = await semcastAxios.get(url, {withCredentials: true});
            buildOptionsList(response.data.data, "CITY");
        }

    };

    const fileRef = useRef();

    const onFileSelected = async (event) => {
        const fileInput = event.currentTarget;
        if (!fileInput.files || !fileInput.files.length) {
            return;
        }
        var file = fileInput.files[0];
        if (file.type !== 'text/plain' || !file.name.endsWith('.txt')) {
            const fileExt = file.name.substr(file.name.lastIndexOf('.'));
            setIsFileValid(false);
            setUploadMessage("File extension must be  .txt .  This file has an extension of " + fileExt)
            fileInput.value = '';
            return;
        } else {
            setUploadMessage("");
            setIsFileValid(true);
        }
        if (file.size > MAX_FILE_SIZE) {
            const maxFileSizeInKb = (MAX_FILE_SIZE / 1000) + 'KB';
            const fileSizeInKb = Math.round(file.size / 1000) + 'KB';
            setIsFileValid(false);
            setUploadMessage("File size must be under " + maxFileSizeInKb + ".  This file has a size of " + fileSizeInKb + ".");
            fileInput.value = '';
            return;
        } else {
            setIsFileValid(true);
        }
        const response = await validateFile(file);
        if (response.data.status === SUCCESS_STATUS) {
            const {data} = response.data;
            let values = [];
            let unknownLocs = [];
            for (let key in data) {
                const obj = data[key];
                const {unknowns} = obj;
                if (unknowns) {
                    unknownLocs = unknownLocs.concat(unknowns);
                }
                const {exists} = obj;
                if (exists) {
                    values = values.concat(exists);
                }
            }
            const newValues = value.concat(values);
            setValue([...newValues]);
            const newUnknownLocations = unknownLocations.concat(unknownLocs);
            setUnknownLocations([...newUnknownLocations]);
            setIsLocationValid(true);
        }
        console.log(response);
    };

    const validateFile = async (file) => {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append("file", file, file.name);
            const response = await semcastAxios.post(FILE_VALIDATE_API, bodyFormData, {
                withCredentials: true,
                headers: {"Content-Type": "multipart/form-data"},

            });
            return response;
        } catch (e) {
            console.log(e)
        }
    };

    const getItemizedValues = () => {
        const currentValues = value;

        if (!currentValues) {
            return null;
        }

        let itemizedValues = {
            city: [],
            zip3: [],
            zip5: [],
            state: []
        };

        currentValues.forEach((item, index) => {
            if (item.length === 2) {
                itemizedValues.state.push(item);
            } else if (/^\d{3}$/gi.test(item)) {
                itemizedValues.zip3.push(item);
            } else if (/^\d{5}$/gi.test(item)) {
                itemizedValues.zip5.push(item);
            } else {
                itemizedValues.city.push(item);
            }
        });
        return itemizedValues;
    };

    const handleGroupChange = (event) => {
        setGroupValue(event.target.value);
    };

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    };

    const unknowns = unknownLocations?.length > 0
        && unknownLocations.map((item, i) => {

            return (
                <li
                    style={{
                    paddingBottom: ".2rem",
                    // fontSize: ".9rem",
                    fontSize: "12px",
                    display: "block",
                    float: "left",
                    paddingRight: "1rem"
                }}
                    key={i}
                >
                    {/*{item} &nbsp; &#9632;*/}
                    &#9632; &nbsp; {item}
                </li>
            )


            // if (i === unknownLocations.length - 1) {
            //     return (
            //         <li style={{
            //             paddingBottom: ".2rem",
            //             // fontSize: ".9rem",
            //             fontSize: "12px",
            //             display: "block",
            //             float: "left",
            //             paddingRight: "1rem"
            //         }} key={i}>
            //             {item}
            //         </li>
            //     )
            // } else {
            //     return (
            //         <li style={{
            //             paddingBottom: ".2rem",
            //             // fontSize: ".9rem",
            //             fontSize: "12px",
            //             display: "block",
            //             float: "left",
            //             paddingRight: "1rem"
            //         }} key={i}>
            //             {item} &nbsp; &#9632;
            //         </li>
            //     )
            // }




        }, this);
    return (
        <>
            <div id="lookalikeSegment">
                {visible && (
                    <Dialog
                        title={"Create Lookalike Segment"}
                        width={740}
                        closeIcon={false}
                        className={"dialog-standard"}
                    >
                        <LoadOverlay active={pageLoader}>
                            {/*<div style={{paddingTop: "1.8rem"}}>*/}
                            <div>
                                <div>
                                    <Label
                                        className={"field-label"}
                                    >
                                        New Segment Name
                                        {/*<span className="required-field">*</span>*/}
                                    </Label>


                                    {/*<label style={{*/}
                                    {/*    marginLeft: "2rem",*/}
                                    {/*    fontSize: "1.1rem",*/}
                                    {/*    paddingBottom: ".5rem",*/}
                                    {/*    color: !isNameValid ? "red" : "black"*/}
                                    {/*}}>*/}
                                    {/*    <b>New Segment Name</b>*/}
                                    {/*    <span className="text-danger">*</span>*/}
                                    {/*</label>*/}
                                </div>
                                <Input name="newSegmentName" minLength={3} maxLength={64} value={segmentName}
                                       onChange={handleSegmentNameChange}
                                       required={true}
                                       placeholder={"myNewMergedSegment"}
                                       // style={{
                                       //     marginLeft: "2rem",
                                       //     width: "85%",
                                       //     borderColor: !isNameValid ? "red" : ""
                                       // }}
                                       style={{
                                           borderColor: !isNameValid ? "#00424d" : "",
                                       }}
                                       className={"text-field"}
                                />
                                <div style={{display: !isNameValid ? '' : "none"}}>
                                    <span name={"msg"}
                                          style={{
                                              // marginLeft: "2rem",
                                              color: !isNameValid ? "#00424d" : "none"
                                          }}>
                                            {REQUIRED_MESSAGE}
                                    </span>
                                </div>
                            </div>
                            <div style={{paddingTop: "1.8rem"}}>
                                <div>
                                    <Label
                                        className={"field-label"}
                                    >
                                        Locations
                                        {/*<span className="required-field">*</span>*/}
                                    </Label>




                                    {/*<label style={{*/}
                                    {/*    marginLeft: "2rem",*/}
                                    {/*    fontSize: "1.1rem",*/}
                                    {/*    paddingBottom: ".5rem",*/}
                                    {/*    color: !isLocationValid ? "red" : "black"*/}
                                    {/*}}>*/}
                                    {/*    <b>Locations</b>*/}
                                    {/*    <span className="text-danger">*</span>*/}
                                    {/*</label>*/}
                                </div>
                                <div>
                                    <MultiSelect data={locations}
                                                 filterable={true}
                                                 onFilterChange={filterChange}
                                                 onChange={handleLocationsChange}
                                                 value={value}
                                                 required={true}
                                                 style={{
                                                     // marginLeft: "2rem",
                                                     width: "83.6%",
                                                     borderColor: !isLocationValid ? "#00424d" : "",
                                                     minHeight: "2.2rem",
                                                 }}
                                    />
                                    <Button icon="folder"
                                            style={{
                                                borderColor: !isFileValid ? "#00424d" : "",
                                                height: "2.2rem",
                                            }}
                                            onClick={() => fileRef.current.click()}
                                            // className={}
                                    >
                                        Upload File
                                    </Button>

                                    <input
                                        ref={fileRef}
                                        onChange={onFileSelected}
                                        multiple={false}
                                        type="file"
                                        hidden
                                    />
                                    <div>
                                        <span
                                            // style={{marginLeft: "2rem", paddingTop: ".8rem"}}
                                            className={"hint-standard"}
                                        >

                                            {FILE_INFO_MESSAGE}
                                        </span>
                                    </div>
                                    <div style={{display: !isFileValid ? '' : "none"}}>
                                        <span name={"msg"}
                                              style={{
                                                  // marginLeft: "2rem",
                                                  color: !isFileValid ? "#00424d" : "none"
                                              }}>
                                                {uploadMessage}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style={{paddingTop: "1.8rem", display: unknownLocations.length > 0 ? '' : "none"}}>
                            {/*<div style={{paddingTop: "1.8rem", display: ''}}>*/}
                                <div style={{
                                    // marginLeft: "2rem",
                                    paddingBottom: ".5rem",
                                    fontSize: "1.1rem"
                                }}>
                                    {/*<span><b>Unknown Locations</b></span>*/}
                                    <Label
                                        className={"field-label"}
                                    >
                                        Unknown Locations
                                        {/*<span className="required-field">*</span>*/}
                                    </Label>
                                </div>
                                <ul
                                    // style={{marginLeft: ".2rem", width: "85%", height: "4rem", overflow: "auto"}}
                                    style={{
                                        // width: "85%",
                                        // height: "4rem",
                                        paddingLeft: "1rem",
                                        maxHeight: "6rem",
                                        overflow: "auto",
                                        margin: "0rem",
                                    }}
                                >
                                    {unknowns}
                                </ul>
                            </div>
                            {/*<div style={{paddingTop: "1.5rem"}}>*/}
                            <div style={{paddingTop: "1.8rem"}}>
                                <div>
                                    <Label
                                        className={"field-label"}
                                    >
                                        Save to Group
                                        {/*<span className="required-field">*</span>*/}
                                    </Label>

                                    {/*<label style={{marginLeft: "2rem", fontSize: "1.1rem", paddingBottom: ".5rem"}}>*/}
                                    {/*    <b>Save to Group</b>*/}
                                    {/*    <span className="text-danger">*</span>*/}
                                    {/*</label>*/}
                                </div>
                                <DropDownList
                                    // style={{marginLeft: "2rem", width: "85%"}}
                                    data={accessibleGroups}
                                    textField="group_name"
                                    value={groupValue}
                                    onChange={handleGroupChange}
                                    className={"dropdown-standard"}
                                />
                            </div>
                            <div style={{paddingTop: "1.8rem"}}>
                                <div>
                                    <Label
                                        className={"field-label"}
                                    >
                                        Notes
                                        {/*<span className="required-field">*</span>*/}
                                    </Label>

                                    {/*<label style={{marginLeft: "2rem", fontSize: "1.1rem", paddingBottom: ".5rem"}}>*/}
                                    {/*    <b>Notes</b>*/}
                                    {/*</label>*/}
                                </div>
                                <TextArea
                                    value={notes}
                                    // style={{marginLeft: "2rem", width: "85%"}}
                                    maxLength={5000}
                                    placeholder={"Targeting new customers from mailing list..."}
                                    rows={5}
                                    onChange={handleNotesChange}
                                />
                                {/*<span style={{marginLeft: "2rem", paddingTop: ".8rem"}}>{NOTES_OPTIONAL_MESSAGE}</span>*/}
                                <span className={"hint-standard"}>{NOTES_OPTIONAL_MESSAGE}</span>
                            </div>

                        </LoadOverlay>
                        <DialogActionsBar layout={"end"}>
                            <Button
                                // className="dialog-cancel"
                                className={"button-standard button-white"}
                                onClick={() => {
                                    props.cancelSubscriber(undefined)
                                }}>
                                Cancel
                            </Button>
                            <Button
                                // className="dialog-save"
                                className={"button-standard button-submit create-lookalike-button"}
                                disabled={!isNameValid || !isLocationValid}
                                onClick={lookalikeSegment}>
                                Create Lookalike Segment
                            </Button>
                        </DialogActionsBar>
                    </Dialog>)}
            </div>
        </>
    );
}

export default LookalikeSegment;
