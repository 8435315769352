import Loader from "react-loader-spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";

function Loading({ height, loaderHeight, loaderWidth } ) {
  return (
    // <Container>
    //   <Row style={{ height: { height }, backgroundColor: "white" }}>
    //     <Col className="d-flex justify-content-center align-items-center">
          <Loader type="Rings" color="#00BFFF" height={loaderHeight} width={loaderWidth} style={{marginLeft:"50%"}}/>
    //     </Col>
    //   </Row>
    // </Container>
  );
}

export default Loading;
