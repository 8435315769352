import React, {useEffect, useState} from "react";

import {Button} from "@progress/kendo-react-buttons";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Label} from "@progress/kendo-react-labels";
import "./AdvancedTargeting.css";
import {FieldWrapper, Form, FormElement} from "@progress/kendo-react-form";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import {RadioGroup} from "@progress/kendo-react-inputs";
import audienceDesignConfig from "../../../config/audienceDesignConfig";
import {getPpkSegments} from "../../../actions/AudienceDesignActions";
import {filterBy} from "@progress/kendo-data-query";
import CategorySegmentsEdit from "./CategorySegmentsEdit";
import LoadOverlay from "../SharedComponents/LoadOverlay";

const AdvancedTargetingEdit = (props) => {

    const [options, setOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [targetingData, setTargetingData] = useState({});
    const [activeTiles, setActiveTiles] = useState([]);
    const [value, setValue] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedSegmentTitle, setSelectedSegmentTitle] = useState("");
    const [segmentGroupTitles, setSegmentGroupTitles] = useState({});
    const [segmentsState, setSegmentsState] = useState({});
    const [selectedTile, setSelectedTile] = useState({});
    const [selectedTileConfig, setSelectedTileConfig] = useState({});
    const [selectionType, setSelectionType] = useState( "DISTINCT");
    const selectionTypes = [
        {label: "Chosen value can be in ANY selection (OR statement)", value: "DISTINCT"},
        {label: "Chosen value can be in ALL selections (AND statement)", value: "COMMON"}
    ]
    const [consumerList, setConsumerList] = useState([]);
    const [businessList, setBusinessList] = useState([]);
    const [loadOverlay, setLoadOverlay] = useState(true);
    const [valueLength, setValueLength] = useState(null);
    const [memorizeState, setMemorizeState] = useState({});
    let tempState = {};

    const handleSelectionType = (e) => {
        setSelectionType(e.value);
        props.handleAudienceState(prevState => {
            return {...prevState, merge_type: e.value}}
        );
    };

    const handleVisible = () => {
        setVisible(true);
    }

    const handleSegmentsState = (state) => {
        setSegmentsState(state);
    }

    const saveSegmentSelections = (state, e) => {
        if (props.originalType === "BUSINESS" &&
            props.targetType === "BUSINESS" &&
            props.audienceState["titleMatching"].length > 0) {
            props.handleTargetType("CONSUMER")
        }
        setVisible(false);
    }

    const onFilterChange = (e) => {
        const filter = e.filter;
        const allData = options.slice();
        if (filter.value.length < 3) return;
        const newData = filter.value.length >= 3 ? filterBy(allData, filter) : allData;
        setFilteredOptions(newData);
    };
    const getSegmentByGroupName= (val) => {
        let combinedResult = {};
        for (let  value of Object.entries(val)) {
            const groupType = value[1].groupType;

            if (!groupType) continue;
            if (!combinedResult[groupType]) {
                combinedResult[groupType] = [];
            }
            combinedResult[groupType].push(
                ...value[1].filter(item => item.groupType === undefined)
            );
        }
        Object.keys(combinedResult).forEach(key => {
            props.handleAudienceState(prevState => {
                return {...prevState, [key]: combinedResult[key].map(item => item.value)}}
            );
        })

    }
    function getGroupTypeByGroupName(arrayA, arrayB, b) {
        const array = arrayA.concat(arrayB);
        // Loop through each object in array a
        for (let i = 0; i < array.length; i++) {
            // Check if the groupName matches the string b
            if (array[i].groupName === b) {
                // If a match is found, return the groupType
                return array[i].groupType;
            }
        }
        // If no match is found, return null or undefined
        return null;
    }
    const generateTempState = (a, prevTempState) => {

        const newState = { ...prevTempState };

        if (a.length > 0) {
            const lastItem = a[a.length - 1];
            const { segment_category, segment_id, groupName } = lastItem.value;

            if (!newState[segment_category]) {
                newState[segment_category] = [];
            }

            // Check if the item with the same value already exists
            const exists = newState[segment_category].some(item => item.value === segment_id);
            const groupType = getGroupTypeByGroupName(consumerList,businessList,groupName);
            // If it doesn't exist, push the new item
            if (!exists) {
                newState[segment_category].push({
                    label: lastItem.label,
                    value: segment_id,
                    checked: false,
                });
                newState[segment_category].groupType = groupType;
                newState[segment_category].targetType = props.audienceState.target_type;
            }
        }
        return newState;
    };
    const onSegmentDataChange = (e) => {
        setValueLength(e.value.length);
        if (e.value.length === 0) {
            setActiveTiles([]);
            setValue(e.value);
            return;
        }
        let currentActiveTiles = new Set();
        e.value.forEach((info) => {
            // let tile = props.formik.values.target_type === "CONSUMER" ?
            let tile = props.targetType === "CONSUMER" ?
                consumerList.find(obj => obj.groupName === info.value.groupName) :
                businessList.find(obj => obj.groupName === info.value.groupName)
            // currentActiveTiles.add(info.value.groupName);
            if (tile) currentActiveTiles.add(tile);
        });
        if (currentActiveTiles.size > 0) {
            const arr = Array.from(currentActiveTiles).sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
            setActiveTiles(arr);
        }
        setValue(e.value);


        if(valueLength < e.value.length || valueLength === null) {
            tempState = generateTempState(e.value, {...segmentsState, ...memorizeState});
            setMemorizeState(tempState);
        }
    };
    useEffect(() => {
        handleSegmentsState(memorizeState);
        getSegmentByGroupName(memorizeState);
    }, [memorizeState]);


    useEffect(() => {
        options.length = 0;
        filteredOptions.length = 0;
        value.length = 0;
        setActiveTiles([]);
        fetchData();
    }, [props.targetType]);

    useEffect(() => {
        for (let property in targetingData) {
            // console.log(property);
            targetingData[property].map((obj) => {
                obj["groupName"] = targetingData[property].groupName;
                // NOTE: this special case logic will go away once we clean up prepackaged_segment_descriptor table..
                // if (targetingData[property].groupName === "Retail" ||
                //     targetingData[property].groupName === "Personal Finance" ||
                //     targetingData[property].groupName === "Sports Attendees") {
                //     label = obj.segment_category + " : " + obj.description;
                // } else {
                //     if (obj.segment_name !== '') label = obj.segment_category + " : " + obj.segment_name;
                // }
                // console.log(obj)
                let s = !(obj.segment_name.includes(":")) ? obj.segment_name : obj.description;
                options.push({label:s, value:obj});
            })
        }

        // console.log(options);
        options.sort();
        // determineTargetedSegments();
    }, [targetingData])

    useEffect(() => {
        if ((targetingData && Object.keys(targetingData).length > 0) &&
            (props.audienceState && Object.keys(props.audienceState).length > 0)) {
            determineTargetedSegments();
        }
    }, [targetingData, props.audienceState])

    const fetchData = async () => {
        setLoadOverlay(true);
        const allReq = [];
        let cList = [];
        let bList = [];
        for (const key in audienceDesignConfig) {
            allReq.push(
               await getPpkSegments(
                    audienceDesignConfig[key].endPoint,
                    audienceDesignConfig[key].groupType
                )
            );
            // console.log(audienceDesignConfig[key]);
            if ((audienceDesignConfig[key].targetType === "CONSUMER" ||
                audienceDesignConfig[key].targetType === "BOTH")) {
                cList.push(audienceDesignConfig[key]);
            }
            if ((audienceDesignConfig[key].targetType === "BUSINESS" ||
                    audienceDesignConfig[key].targetType === "BOTH")) {
                bList.push(audienceDesignConfig[key]);
            }
        }
        // Sort for display order
        cList.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        bList.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        // FD#11981
        cList = cList.filter((key)=>{return key.groupType !== 'smllearning'})
        setConsumerList(cList);
        setBusinessList(bList);

        await Promise.all(allReq).then((res) => {
            const allData = {};
            // console.log(res);
            res.forEach((reqData) => {
                const {data, groupType} = reqData;
                data.sort((a,b) => a.segment_name.localeCompare(b.segment_name, 'en', {numeric: true}));
                // It appears in 1.x that targeting data (ex: Travel & Entertainment) can
                // appear when we switch to CONSUMER - with a message informing the user that
                // the target would be consumers visiting the businesses.
                // if (audienceDesignConfig[groupType].targetType === "BOTH" ||
                //     audienceDesignConfig[groupType].targetType === props.targetType) {
                //FD#12181 A Business segment should only have BUSINESS options, when switching to CONSUMER, you can have all options
                if (audienceDesignConfig[groupType].targetType === "BOTH" ||
                    audienceDesignConfig[groupType].targetType === "BUSINESS" ||
                    audienceDesignConfig[groupType].groupName === "Net Worth" ||
                    audienceDesignConfig[groupType].groupName === "Discretionary Income")
                {
                    allData[groupType] = data;
                    allData[groupType].groupName = audienceDesignConfig[groupType].groupName;
                    allData[groupType].targetType = audienceDesignConfig[groupType].targetType;
                }
                if (audienceDesignConfig[groupType].targetType === "BOTH" ||
                    audienceDesignConfig[groupType].targetType === props.targetType ||
                    audienceDesignConfig[groupType].groupName === "Net Worth" ||
                    audienceDesignConfig[groupType].groupName === "Discretionary Income")
                {
                    allData[groupType] = data;
                    allData[groupType].groupName = audienceDesignConfig[groupType].groupName;
                    allData[groupType].targetType = audienceDesignConfig[groupType].targetType;
                }
                // }
            });
            if (allData['personalFinance']) {
                handleDeciles(allData);
            }
            //FD#12181 Removing these 2 groups because tiles not found under Business Target Type. Same group can still be found under Consumer-personalFinance.
            delete allData["discretionaryIncome"];
            delete allData["netWorth"];
            //FD#11981
            delete allData["smllearning"];
            setTargetingData(allData);
            setLoadOverlay(false);
            // setPageLoader(false);
            // setIsDataLoaded(true);
        });
    };

    //FD:10151 - Special case for decile categories
    const handleDeciles = (data) => {
        // console.log(data);
        let personalFinance = data['personalFinance'];
        if (personalFinance  === undefined) return;
        let netWorth = data['netWorth'];
        let discretionaryIncome = data['discretionaryIncome'];
        netWorth.map((obj) => {
            obj.segment_group = 'Personal Finance';
            obj.segment_name = obj.description;
        })
        discretionaryIncome.map((obj) => {
            obj.segment_group = 'Personal Finance';
            obj.segment_name = obj.description;
        })
        discretionaryIncome.sort((a,b) => a.segment_id.localeCompare(b.segment_id, 'en', {numeric: true}))
        data['personalFinance'] = personalFinance.concat(netWorth).concat(discretionaryIncome);
        // console.log(data['personalFinance']);
    }


    const displayTile = (tileName) => {
        // if (activeTiles.length === 0 || Array.from(activeTiles).includes(tileName)) return true;
        if (activeTiles.length === 0 || Array.from(activeTiles).some(entry => entry.groupName === tileName)) return true;
        return false;
    };

    const clickTileHandler = (e, tile) => {
        // This is click handler for tiles.
        const groupName = e.currentTarget.id;
        const title = tile.dialogTitle;
        const groupType = tile.groupType;
        setSelectedSegmentTitle(title);
        setSelectedTile(targetingData[groupType]);
        setSelectedTileConfig(tile);

        handleVisible();
    };

    const clickInfoLinkHandler = (e, tile)  => {
        // This is click handler for 'Info' link on each tile.
        const pdfWindow = window.open(tile.dataSheet, "pdf show", "width=800, height=600");
        pdfWindow.focus();
        e.stopPropagation();
    };

    const clickEditCategoryHandler = (group, state, event) => {
        const groupName = group;
        const title = segmentGroupTitles[groupName];
        setSelectedSegmentTitle(title);
        handleVisible();
    };

    const selectDataSheet = (dataSheetKey) => {

    };
useEffect(()=>{
// console.log("segmentsState", segmentsState);
// console.log("props.audienceState", props.audienceState);
},[segmentsState, props.audienceState]);

    function findMissingValues(obj, newObj) {
        const missingValues = [];

        // Iterate over each object in Obj
        obj.forEach(objItem => {
            // Check if the object exists in newObj
            const found = newObj.some(newObjItem => {
                return newObjItem.label === objItem.label && newObjItem.value === objItem.value;
            });

            // If not found, add it to missingValues
            if (!found) {
                missingValues.push(objItem);
            }
        });

        return missingValues;
    }

    function removeAttributeByKey(obj, keyToRemove) {
        if (obj.hasOwnProperty(keyToRemove)) {
            delete obj[keyToRemove];
        }
    }
    const handleTargetedChange = (e, key) => {

        const savedGroupType = segmentsState[key].groupType;
        removeAttributeByKey(segmentsState[key], "groupType");
        removeAttributeByKey(segmentsState[key], "targetType");


        const deletedValue = findMissingValues(segmentsState[key], e.value);

        let deletedValueArray = [];
        for (let i =0; i < deletedValue.length; i++) {
            deletedValueArray.push(deletedValue[i].value);
        }

        if (e.value.length === 0) {
            delete segmentsState[key];
            setSegmentsState({...segmentsState});

            props.handleAudienceState(prevState => {
                return {...prevState, [savedGroupType]: props.audienceState[savedGroupType].filter(value => !deletedValueArray.includes(value))}}
            );
        } else {
            segmentsState[key] = e.value;
            setSegmentsState({...segmentsState});
            props.handleAudienceState(prevState => {
                return {...prevState, [savedGroupType]: props.audienceState[savedGroupType].filter(value => value !== deletedValue[0].value)}}
            );
        }
    };

    const displayTiles = () => {
        let displayTypes = [];
        if (props.targetType === "CONSUMER") {
            displayTypes = activeTiles.length > 0 ?
                [...new Set(activeTiles.map(item => item.displayType))] :
                [...new Set(consumerList.map(item => item.displayType))];
            const renderList = displayTypes.map((type, index) =>
                displayTileGroup(type, consumerList, index)
            )
            return (
                <>
                    <div>{renderList}</div>
                </>
            );
        } else if (props.targetType === "BUSINESS") {
            displayTypes = activeTiles.length > 0 ?
                [...new Set(activeTiles.map(item => item.displayType))] :
                [...new Set(businessList.map(item => item.displayType))];
            const renderList = displayTypes.map((type, index) =>
                displayTileGroup(type, businessList, index)
            )
            return (
                <>
                    <div>{renderList}</div>
                </>
            );
        }
    };
    const infoSheetAvailable = ['Analytics IQ', 'Meds & Treatment', 'HealthPlan', 'PurpleLabHCP', 'SalesIntel Techno.', 'SalesIntel Dept.']; //FD#11854
    const displayTileGroup = (type, tilesList, index) => {
        const header = <Label className={"sub-section-label"}>{type}</Label>;
        const renderList = tilesList
            .filter(tile => tile.displayType === type)
            .map((tile, index) => <Button key={index}
                                          id={tile.groupType}
                                          className={"tile-buttons"}
                                          style={displayTile(tile.groupName) ? {} : {display: "none"}}
                                          onClick={(e) => clickTileHandler(e, tile)}>
                <Label className={"tile-button-group-name"}>{tile.title}</Label>
                <div className="image-container image">
                    <img src={tile.icon} alt={""} style={{width: tile.iconWidth}}/>
                </div>
                <div className={"info-link-container"}>
                    <label className={"price-container"}>{tile.price}</label>
                    {/*FD#11854*/}
                    {infoSheetAvailable.includes(tile.title) &&  <a href={"#"} onClick={(e) => clickInfoLinkHandler(e, tile)} style={{
                        fontSize: "13px",
                        marginBottom: "1rem"
                    }}>Info</a>}
                </div>
            </Button>);

        return (
            <>
                {renderList.length > 0 && header}
                <div className={"advanced-targeting-button-grp"}>{renderList}</div>

            </>
        );
    }

    // Since we only have the segment_id (ex: 'CES5') in segmentEditInfo -
    // we need to reconstruct the entries that will be displayed in
    // the 'Targeted Segments' section.
    // const determineTargetedSegments = () => {
    //     let targetedData = {};
    //     const keys = Object.keys(props.audienceState);
    //     keys.forEach(key => {
    //         if (targetingData[key]) {
    //             // const existing = props.audienceState[key];
    //             const existing = targetingData[key];
    //                 let arr = [];
    //             let category = "";
    //             existing.forEach(ex => {
    //                 const targetedEntry = targetingData[key].find(obj => {return obj.segment_id === ex});
    //                 let label = "";
    //                 category = targetedEntry.segment_category;
    //                 if (!targetedData[category]) {
    //                     targetedData[category] = {};
    //                 }
    //                 if (targetedEntry.groupName === "Retail" ||
    //                     targetedEntry.groupName === "Personal Finance" ||
    //                     targetedEntry.groupName === "Sports Attendees") {
    //                     label = targetedEntry.description;
    //                 } else {
    //                     label = targetedEntry.segment_name;
    //                 }
    //                 arr.push({label: label, value: ex});
    //             });
    //             targetedData[category] = arr;
    //             targetedData[category].groupType = key;
    //         }
    //     });
    //     setSegmentsState(targetedData);
    // };

    const determineTargetedSegments = () => {
        let targetedData = {};
        const keys = Object.keys(props.audienceState);
        // console.log(props.audienceState);
        // console.log(keys)
        for (const key of keys) {
            if (targetingData[key]) {
                // console.log(key, props.audienceState[key]);
                const existing = props.audienceState[key];
                if (existing.length === 0) continue;
                let targetedEntry;
                let arr = [];
                let category = "";
                for (const segId of existing) {
                    targetedEntry = targetingData[key].find(obj => {
                        // console.log(targetingData[key]);
                        return (typeof segId === "string" ? (obj.segment_id === segId) : (obj.segment_id === segId.value));
                    });
                    // console.log(existing)
                    let label = "";
                    category = targetedEntry?.segment_category;
                    if (!targetedData[category]) {
                        targetedData[category] = {};
                        arr = [];
                    }
                    if (targetedEntry?.groupName === "Retail" ||
                        targetedEntry?.groupName === "Personal Finance" ||
                        targetedEntry?.groupName === "Sports Attendees") {
                        label = targetedEntry?.description;
                    } else {
                        label = targetedEntry?.segment_name;
                    }
                    if(typeof segId === "string") {
                        arr.push({label: label, value: segId});
                    }else{
                        arr.push({label: label, value: segId.value});
                    }

                    targetedData[category] = arr;
                    targetedData[category].groupType = key;
                    // Get the target type - could be from 'consumer' config list
                    // or 'business' config list.
                    let configEntry = consumerList.find(config => {
                        return config.groupType === key;
                    });
                    if (!configEntry) {
                        configEntry = businessList.find(config => {
                            return config.groupType === key;
                        })
                    }
                    targetedData[category].targetType = configEntry.targetType;
                }
            }
        }
        // console.log(targetedData);
        setSegmentsState(targetedData);

    };

    const displayTargetedSegments = () => {
        // console.log(segmentsState);
        let renderList = null;
        let arr = Object.keys(segmentsState);
        renderList = arr.map((item, index) => (
            <MultiSelect
                key={index}
                label={arr[index]}
                data={segmentsState[arr[index]]}
                autoClose={false}
                textField={"label"}
                dataItemKey="value"
                popupSettings={{height: 150}}
                onChange={(e) => handleTargetedChange(e, arr[index])}
                value={segmentsState[arr[index]] ? segmentsState[arr[index]] : []}
                size={"large"}/>
            // segmentsState[arr[index]].targetType === props.targetType ||
            // segmentsState[arr[index]].targetType === "BOTH" ?
            //     <MultiSelect
            //         key={index}
            //         label={arr[index]}
            //         data={segmentsState[arr[index]]}
            //         autoClose={false}
            //         textField={"label"}
            //         dataItemKey="value"
            //         popupSettings={{height: 150}}
            //         onChange={(e) => handleTargetedChange(e, arr[index])}
            //         value={segmentsState[arr[index]] ? segmentsState[arr[index]] : []}
            //         size={"large"}/> : ""
        ));

        return (
            <>
                {renderList}
            </>
        )
    };


    return (
        <>
            <Card>
                <CardTitle>
                    <Label className="section-label">ADVANCED TARGETING</Label>
                </CardTitle>
                <CardBody>
                    <Form render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                        <legend className={'k-form-legend spacingLine'}></legend>
                        <div className={"cost-msg-div"}>
                            <Label className={"cost-msg-label"}>
                                <span>Cost is capped at the highest listed CPM value. When multiple segments are used the CPM value is proportionately applied.</span>
                            </Label>
                        </div>
                        <div className="advanced-targeting-container">
                            <Form render={formRenderProps => <FormElement style={{
                                width: "100%"
                            }}>
                                <FieldWrapper >
                                    <Label className={"field-label targeting-selection-type-label"}>Selection Type</Label>
                                    {/*Moved here - demo feedback - 1/27/23*/}
                                    <RadioGroup
                                        data={selectionTypes}
                                        value={selectionType}
                                        onChange={handleSelectionType}
                                        layout="vertical"
                                        style={{width:"100%", fontSize:".9rem"}}
                                    />
                                </FieldWrapper>
                                <FieldWrapper style={{paddingBottom: "1.5rem"}}>
                                    <MultiSelect
                                        className={"fields advance-targeting-search-bar"}
                                        data={filteredOptions.length > 0 ? filteredOptions.filter((i)=>{return i.label !== "All"}) : options.filter((i)=>{return i.label !== "All"})}
                                        placeholder="Search / Filter Segments"
                                        autoClose={false}
                                        textField="label"
                                        dataItemKey="value"
                                        filterable={true}
                                        onFilterChange={onFilterChange}
                                        onChange={onSegmentDataChange}
                                        value={value}
                                        size="large"
                                        style={{width: "100%", borderRadius: "0.25rem"}}
                                    />
                                </FieldWrapper>
                            </FormElement>}/>
                        </div>
                    </FormElement>}/>

                    <LoadOverlay active={loadOverlay} width={'50%'} height={'100px'}>
                       {displayTiles()}
                    </LoadOverlay>

                    <Card>
                        <CardBody>
                            <Label className={"sub-section-label"}>Targeted Segments</Label>
                            <div className={"targeted-segments-group"}>
                                {displayTargetedSegments()}
                            </div>
                        </CardBody>
                    </Card>
                </CardBody>
            </Card>

            {visible && (
                <div>
                    <CategorySegmentsEdit
                        // formik={props.formik}
                        audienceState={props.audienceState}
                        handleAudienceState={props.handleAudienceState}
                        visible={true}
                        setVisible={setVisible}
                        allData={targetingData}
                        selectedTile={selectedTile}
                        selectedTileConfig={selectedTileConfig}
                        filteredData={value}
                        segmentsState={segmentsState}
                        handleSegmentsState={handleSegmentsState}
                        selectedSegmentTitle={selectedSegmentTitle}
                        saveSegmentSelections={saveSegmentSelections}
                        displayTargetedSegments={displayTargetedSegments}/>
                </div>
            )}

        </>
    )
}

export default AdvancedTargetingEdit;