import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const semcastingxandrConfig = {
  ...COMPANY_BASE_PROPERTIES,

  className: "semcasting-xandr-form onboarding-form",

  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With Xandr via Semcasting",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "semcasting_xandr",

  /**
   * HTML that explains how to use the form
   */
  instructions:
  "You have selected a platform that automatically reports usage for targeting."
  +"You will be billed for data usage by the platform unless the segment is applied for suppression purposes."
  +"Suppression segments are billed by Semcasting directly based on the discounted deployed record formula."
  +"Activating this audience means you agree to this billing method.",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "semcasting_xandr",
    text: "Xandr via Semcasting",
    img: "semcasting-xandr-logo.png",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink:
    '<a href="https://platform.xandr.com/login" target="_blank">Log in to Xandr</a>',

  layout: [
    {
      fieldType: "checkboxGroup",
      labelText: "Devices to Activate",
      fieldName: "device_type",
      required: true,
      checkboxes: [
        {
          labelText: "Xandr ID",
          fieldName: "CLIENT_ID",
          value: "CLIENT_ID",
          checked: true,
          disabled: true,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Mobile Devices",
      fieldName: "mobile_devices",
      required: true,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: false,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },{
          labelText: "No Devices",
          fieldName: "no_devices",
          value: "no_devices",
          checked: false,
        },
      ],
    },
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType: "selectPickerSX",
      fieldName: "price_cpm",
      labelText: "Price CPM",
      defaultOption: "Select CPM",
      options: [{id:9516,name:"Custom Segment - $0.00",val:0.0},
      {id:9432,name:"Custom Segment - $0.50",val:0.5},
      {id:9514,name:"Custom Segment - $0.55",val:0.55},
      {id:9515,name:"Custom Segment - $0.65",val:0.65},
      {id:9433,name:"Custom Segment - $0.75",val:0.75},
      {id:9434,name:"Custom Segment - $1.00",val:1.0},
      {id:9435,name:"Custom Segment - $1.25",val:1.25},
      {id:9436,name:"Custom Segment - $1.50",val:1.5},
      {id:9437,name:"Custom Segment - $1.75",val:1.75},
      {id:12456,name:"Custom Segment - $10.00",val:10.0},
      {id:12457,name:"Custom Segment - $12.00",val:12.0},
      {id:12428,name:"Custom Segment - $2.00",val:2.0},
      {id:12429,name:"Custom Segment - $2.25",val:2.25},
      {id:12430,name:"Custom Segment - $2.50",val:2.5},
      {id:12431,name:"Custom Segment - $2.75",val:2.75},
      {id:12432,name:"Custom Segment - $3.00",val:3.0},
      {id:12451,name:"Custom Segment - $5.00",val:5.0},
      {id:12452,name:"Custom Segment - $6.00",val:6.0},
      {id:12453,name:"Custom Segment - $7.00",val:7.0},
      {id:12454,name:"Custom Segment - $8.00",val:8.0},
      {id:12455,name:"Custom Segment - $9.00",val:9.0}],
      required: true,
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelect",
          fieldName: "memberId",
          labelText: "Member ID",
          url: "/v2/secure/attributor/activate-credentials/semcasting_xandr/memberId",
          placeholder: "Select a Member ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType:'checkbox',
          fieldName: "save_credentials",
          labelText:'Save Member ID for future use',
          checked: false,
        },
      ],
    },
    {
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    }
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["memberId"],

  formik: {
    initialValues: {
      device_type: ["CLIENT_ID"],
      mobile_devices: "no_devices",
      campaign_range_start: "",
      campaign_range_end: "",
      save_credentials: false,
      memberId: "",
      audience_usage:"",
      price_cpm:"",
      creativeTagIdDropDown: "",
      creativeTagId:""
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      memberId: Yup.string().required("Member ID is required"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      price_cpm: Yup.string().required("CPM is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
       
    }),
    onSubmit: (values) => {},
  },
};
