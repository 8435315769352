import React, {useEffect, useRef, useState} from "react";
import {useParams} from 'react-router-dom'
import {ActivateView, AllInOneTiles} from "../../helpers/ActivateSegmentHelpers";
import LoadOverlay from "../Components/SharedComponents/LoadOverlay";
import {Window} from "@progress/kendo-react-dialogs";
import axios from "axios";
import "./ActivateMedia.css"
import {showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";

const ActivateMedia = () => {
console.log(localStorage);
    const [activateView, setActivateView] = useState(new ActivateView());
    const [hiddenOnBoardingCompanies, setHiddenOnBoardingCompanies] = useState([]);
    const [companiesLoader, setCompaniesLoader] = useState(false);
    const [companyFormLoader, setCompanyFormLoader] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(undefined)
    const [allInOneVisible, setAllInOneVisible] = useState(false);
    const [clearLineAllInOneVisible, setClearLineAllInOneVisible] = useState(false);
    const allinOneRef = useRef(null)

    useEffect(() => {
        login("https://api.audiencedesigner.com/auth/login");
    }, []);

    useEffect(() => {
    }, [activateView.companyTileColorTextData]);

    const selectedTile = (e, company) => {
        setSelectedCompany(AllInOneTiles[company]);
        if (company === "adsallinone") {
            setAllInOneVisible(true);
        } else if (company === "clearlineadsallinone" )  {
            setClearLineAllInOneVisible(true);
        }
    }

    const allInOneLoaded = () => {
        console.log("Loaded");
        // console.log(allinOneRef.current.contentWindow.document);
    };

    const closeWindow = (e, company)  => {
        console.log(e);
        if (company.company === "epom") {
            setAllInOneVisible(false);
        } else if (company.company === "clearlineads") {
            setClearLineAllInOneVisible(false);
        }
    }

    const login  = (url)  => {
        axios
            .post("https://api.audiencedesigner.com/auth/login", {
                login: "rkitain@semcasting.com",
                password: "Temporary1@",
                rememberMe: false
            }, {accept: 'application/json', 'content-type': 'application/json'})
            .then(function (response) {
                console.log(response);
                if (response.data.status === "AUTHENTICATED") {
                    console.log("success");
                    // autoLogin("https://api.audiencedesigner.com/auth/autologin", response.data.rememberMeToken)
                }
            })
            .catch(function (error) {
                console.log("error");
            })
    };

    const autoLogin  = (url, token)  => {
        axios
            .post("https://api.audiencedesigner.com/auth/autologin", {
            }, {headers: {
                    "edsp-auth-token-remember": token
        }},)
            .then(function (response) {
                console.log(response);
                if (response.data.status === "success") {
                    console.log("success");
                }
            })
            .catch(function (error) {
                console.log("error");
            })
    };

    const allInOneTileList = activateView.companyTileColorTextData && Object.keys({...AllInOneTiles}).map((company, index) => {
        return (
            <>
                {!hiddenOnBoardingCompanies.includes(company) &&
                    <div className="tile-container">
                        <button type="button" onClick={(e) => selectedTile(e, company)}>
                            <div className={`activate-media-tile ${activateView.companyTileColorTextData[company]?.markerClass}`}>
                                {activateView.companyTileColorTextData[company]?.faIconClass &&
                                    <i className={`status-icon ${activateView.companyTileColorTextData[company]?.faIconColorClass} fa ${activateView.companyTileColorTextData[company]?.faIconClass}`}/>}
                                <div className="all-in-one-tiles">
                                    <div className="company-img">
                                        <img src={`/images/legacy_audience_images/${AllInOneTiles[company]?.companyBlockOptions?.img}`} className=""/>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                }
            </>
        )
    }, this);

    return (
        <div className="activate-media-content">
            <div className="container-fluid">
                {/*<LoadOverlay active={companiesLoader}>*/}
                    <div className="row nav-pills samcast-menu">
                        <div className="col-md-12 col-lg-12">
                            <div className="tab-content">
                                <div className="card p-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12"><h3
                                                className="w-100">{activateView?.segment?.segment_name}</h3>
                                            </div>
                                        </div>
                                        <div className="company-list">
                                            <legend className={'k-form-legend spacingLine'}>All In One</legend>
                                            {allInOneTileList}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                {/*</LoadOverlay>*/}
                {allInOneVisible && (<Window title={"ADS All In One"} height={800} width={900} minimizeButton="false" maximizeButton="false" onClose={(e) => closeWindow(e, selectedCompany)}>
                    <iframe ref={allinOneRef} src={"https://dsp.audiencedesigner.com/"} height={800} width={800} onLoad={allInOneLoaded}/>
                </Window>)}
                {clearLineAllInOneVisible && (<Window title={"Clearline-ADS All In One"} height={800} width={900} minimizeButton="false" maximizeButton="false" onClose={(e) => closeWindow(e, selectedCompany)}>
                    <iframe src={"https://dsp.audiencedesigner.com/login"} height={800} width={800}/>
                </Window>)}

            </div>
        </div>
    );
}

export default ActivateMedia;
