import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const instepmeasurementConfig = {
    ...COMPANY_BASE_PROPERTIES,
    /**
     * User Readable name of company the user is onBoarding with
     */
    companyText: "InStep Measurement",

    /**
     * lowercase no spaces or underscores name of company
     * This will be passed to the activate model and
     * used when saving to create the correct submission url
     */
    company: "instepmeasurement",
    isConsumer: null,
    isThirdPartySegment: null,
    /**
     * HTML that explains how to use the form
     */
    instructions:"",
        // "Select required fields from Location Properties followed by any listed platform identifiers, email types, devices etc. For every selected Platform ID a sample of up to 5 are provided. Consumer Segments created or edited from Audience Design will not be eligible for any PII options.",
    /**
     * data used to create a company activation block
     */
    companyBlockOptions: {
        val: 'instepmeasurement',
        text: 'InStep-Measurement',
        img: 'InStep-Health-logo-stacked.svg'
    },

    /**
     * anchor tag used to login to a specific platform
     */
    loginLink:
        '',

    layout: [
        {
            fieldType: 'row',
            layout: [
                {
                    fieldType: 'column',
                    columnClass: 'content-wrapper',
                    layout: [{
                        fieldType: 'checkboxGroup',
                        labelText: 'Location Properties',
                        fieldName: 'location_properties',
                        required: true,
                        checkboxes: [
                            {
                                labelText: 'Consumer Basics: City, State, Zip5, Home Owner, Home Value, Social Matrix Category, Digital Activity Score',
                                fieldName: 'consumer_basics',
                                value: 'consumer_basics',
                                disabled:false,
                                checked: false
                            }, {
                                labelText: 'Consumer Demographics: Age, Income, Gender, Marital Status, Race, Area, Net Worth, Political Party, Home Owner, Home Value, Social Matrix Category, Digital Activity Score',
                                fieldName: 'consumer_demographics',
                                value: 'consumer_demographics',
                                disabled:false,
                                checked: false
                            }, {
                                labelText: 'Business Basics: Address, Coordinates, Company_name, Phone, Naics, Domain',
                                fieldName: 'business_basics',
                                value: 'business_basics',
                                disabled:false,
                                checked: false
                            }, {
                                labelText: 'Semcasting ID',
                                fieldName: 'semcasting_id',
                                value: 'semcasting_id',
                                disabled:false,
                                checked: false,
                                defaultValue:false,
                            }, {
                                labelText: 'IP Address',
                                fieldName: 'ip_sample',
                                value: 'ip_sample',
                                checked: false,
                                disabled:false
                            }, {
                                labelText: 'UID2',
                                fieldName: 'uid2_id',
                                value: 'uid2_id',
                                disabled:false,
                                checked: false
                            }, {
                                labelText: 'Devices',
                                fieldName: 'mobile_devices',
                                value: 'mobile_devices',
                                disabled:false,
                                checked: false
                            }
                        ]
                    },
                        {
                            fieldType: 'checkboxGroup',
                            labelText: 'Platform ID Sampling',
                            fieldName: 'platform_id_sampling',
                            required: false,
                            checkboxes: [
                                {
                                    labelText: 'Hashed Semcasting ID',
                                    fieldName: 'hashed_semcasting_id',
                                    value: 'hashed_semcasting_id',
                                    disabled:false,
                                    checked: false,
                                    defaultValue:false
                                }, {
                                    labelText: 'NPI',
                                    fieldName: 'npi_id',
                                    value: 'npi_id',
                                    disabled:false,
                                    checked: false
                                }, {
                                    labelText: 'PurpleLab',
                                    fieldName: 'purplelabs_id',
                                    value: 'purplelabs_id',
                                    disabled:false,
                                    checked: false
                                }, {
                                    labelText: 'PurpleLab Individual',
                                    fieldName: 'purplelab_individual_id',
                                    value: 'purplelab_individual_id',
                                    disabled: false,
                                    checked: false
                                }, {
                                    labelText: 'Roku',
                                    fieldName: 'roku_id',
                                    value: 'roku_id',
                                    disabled:false,
                                    checked: false
                                },
                            ]
                        }]
                }
            ]
        },
        {
            fieldType: 'savableFieldsWrapper',
            layout: [
                {
                    fieldType: 'searchSelect',
                    fieldName: 'path',
                    labelText: 'S3 File Path',
                    url: '/v2/secure/attributor/activate-credentials/semcasting/path',
                    placeholder: 'Select a File Path or add a new one',
                    namePropName: 'value',
                    textPropName: 'value',
                    valuePropName: 'id',
                    required: true

                }, {
                    fieldType: 'searchSelect',
                    fieldName: 'account_email',
                    labelText: 'Account Email',
                    url: '/v2/secure/attributor/activate-credentials/semcasting/account_email',
                    placeholder: 'Select an Account Email or add a new one',
                    namePropName: 'value',
                    textPropName: 'value',
                    valuePropName: 'id',
                    required: true,
                    // validation: [{
                    //     required: function (value, attr, computed) {
                    //         return !parseInt(value);
                    //     }
                    // }, {
                    //     pattern: 'email',
                    //     msg: 'Please enter a valid email'
                    // }]
                }, {
                    fieldType: 'checkbox',
                    fieldName: 'save_credentials',
                    labelText: 'Save File Path and Account Email for future use',
                    checked: false
                }
            ]
        }, {
            fieldType: 'pricing'
        }
    ],

    /**
     * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
     */
    savableDataNames: ['path', 'account_email'],

    formik: {
        initialValues: {
            platform_id_sampling:[],
            location_properties:[],
            path:"",
            account_email:"",
            save_credentials: false,

        },
        // We used Yup here for input format validation.
        validationSchema: Yup.object().shape({
            location_properties: Yup.array().min(1, "Must choose at least one"),
            path:Yup.string().required("S3 File Path is required"),
            account_email:Yup.string().required("Email is required").email("Email format is incorrect"),
        }),
        onSubmit: (values) => {},
    },
};
