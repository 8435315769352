import React, {useEffect, useState} from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import "./InflationSensitivityIndex.css";

const InflationSensitivityIndex = (props) => {

    const [value, setValue] = useState(props.controlsState ?
        props.controlsState.get("inflation_sensitivity_index") : []);

    const inflationOptions = [
        {text: "Least Sensitivity", value: "least_sensitivity", num: ['09','10']},
        {text: "Below Average", value: "below_average", num: ['07','08']},
        {text: "Average", value: "average", num: ['05','06']},
        {text: "Above Average", value: "above_average", num: ['03','04']},
        {text: "Most Sensitivity", value: "most_sensitivity", num: ['01','02']}
    ];

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.inflation_sensitivity_index) {
                props.segmentEditInfo.data.segment_design.primaryData.inflation_sensitivity_index.forEach(optionValue => {
                    inflationOptions.find(option => {
                        if (option.num.includes(optionValue)) {
                            if (!arr.includes(option) ) arr.push(option);
                        }
                        ;
                    })
                })
                setValue(arr);
            }
        }
    }, [props.segmentEditInfo]);

    const onInflationSensitivityChange = (e) => {
        let values = [];
        let controlValues = [];
        e.value.forEach((val) => {
            values = values.concat(val.num);
            controlValues.push(val);
        });
        setValue(controlValues);
        props.formik.values.segment_design.primaryData.inflation_sensitivity_index = values;
        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("inflation_sensitivity_index", controlValues)));
        }
    };

    return (
        <div>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={inflationOptions}
                    autoClose={false}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    onChange={onInflationSensitivityChange}
                    size="large"
                    value={value}
                    // style={{width:"32rem"}}
                    // value={socialMatrixOptions}
                />
            </div>
        </div>
    );
}
export default InflationSensitivityIndex;
