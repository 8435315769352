import React, {useEffect, useState} from "react";
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import "./PoliticalPartyButtonGroup.css";

const PoliticalPartyButtonGroup = (props) => {
    const determineActive = (party) => {
        const arr = props.controlsState.get("reg_party_cd");
        console.log(arr);
        if (arr) {
            if (party === 'D' && arr.includes('1')) return true;
            if (party === 'R' && arr.includes('2')) return true;
            if (party === 'I' && arr.includes('3')) return true;
        }
    };
    const [democratActive, setDemocratActive] = useState(props.controlsState ?
        determineActive('D') : false);
    const [independentActive, setIndependentActive] = useState(props.controlsState ?
        determineActive('I') : false);
    const [republicanActive, setRepublicanActive] = useState(props.controlsState ?
        determineActive('R'): false);

    useEffect(() => {
        let arr = [];
        if (democratActive) arr.push("1");
        if (republicanActive) arr.push("2");
        if (independentActive) arr.push("3");
        props.formik.values.segment_design.primaryData.reg_party_cd = arr;
        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("reg_party_cd", arr)));
        }
    }, [democratActive, independentActive, republicanActive]);

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.reg_party_cd) {
                props.segmentEditInfo.data.segment_design.primaryData.reg_party_cd.forEach(optionValue => {
                    if (optionValue === "1") setDemocratActive(true);
                    if (optionValue === "3") setIndependentActive(true);
                    if (optionValue === "2") setRepublicanActive(true);
                })
            }
        }
    }, [props.segmentEditInfo]);

    const onDemocratChange = (e) => {
        e.preventDefault();
        setDemocratActive(!democratActive);
        let arr = [];
        if (democratActive) arr.push("1");
        if (republicanActive) arr.push("2");
        if (independentActive) arr.push("3");
        props.formik.values.segment_design.primaryData.reg_party_cd = arr;

        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("reg_party_cd", arr)));
        }
    };

    const onIndependentChange = (e) => {
        e.preventDefault();
        setIndependentActive(!independentActive);
        let arr = [];
        if (democratActive) arr.push("1");
        if (republicanActive) arr.push("2");
        if (independentActive) arr.push("3");
        props.formik.values.segment_design.primaryData.reg_party_cd = arr;

        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("reg_party_cd", arr)));
        }
    };

    const onRepublicanChange = (e) => {
        e.preventDefault();
        setRepublicanActive(!republicanActive);
        let arr = [];
        if (democratActive) arr.push("1");
        if (republicanActive) arr.push("2");
        if (independentActive) arr.push("3");
        props.formik.values.segment_design.primaryData.reg_party_cd = arr;

        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("reg_party_cd", arr)));
        }
    };

    return (
        <div className="col-xl-3 col-xxl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <ButtonGroup className={"political-party-button-group"}>
                    <Button
                        className={democratActive ? "selected-political-party" : "default-political-party"}
                        togglable={true}
                        iconClass="fas fa-democrat"
                        onClick={(e) => onDemocratChange(e)}
                        selected={democratActive}
                    >
                        Democrat
                    </Button>
                    <Button
                        className={independentActive ? "selected-political-party" : "default-political-party"}
                        togglable={true}
                        iconClass="fas fa-star"
                        onClick={(e) => onIndependentChange(e)}
                        selected={independentActive}
                    >
                        Independent
                    </Button>
                    <Button
                        className={republicanActive ? "selected-political-party" : "default-political-party"}
                        togglable={true}
                        iconClass="fas fa-republican"
                        onClick={(e) => onRepublicanChange(e)}
                        selected={republicanActive}
                    >
                        Republican
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default PoliticalPartyButtonGroup;
