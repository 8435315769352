import {semcastAxios} from "../Utils/Common";
import {
    SEGMENT_ID_PLACE_HOLDER,
    SUCCESS_STATUS,
    SEGMENT_UPLOADED_RECORDS_API,
    ACCOUNT_PERMISSIONS,
    ACCOUNT_PRICING,
    SEGMENT_ACTIVATION_DATA_API,
    COMPANY_ID_PLACE_HOLDER,
    COMPANY_FIELDS_PREFILLED_DATA, FIELD_ID_PLACE_HOLDER, ACCOUNT_PRICING_WITH_SEGMENT_ID
} from "../api/constants";


export const loadUploadedRecordsData = async (id) => {
    let response = await semcastAxios.get(SEGMENT_UPLOADED_RECORDS_API.replace(SEGMENT_ID_PLACE_HOLDER, id), {withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.data;
        } catch (e) {
            return null
        }
    }
}
export const accountPermissions = async () => {
    let response = await semcastAxios.get(ACCOUNT_PERMISSIONS, {withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            // console.log("AccountPermissions=", response?.data?.data);
            return response?.data?.data;
        } catch (e) {
            return null
        }
    }
}

export const accountPricing = async (segmentId) => {
    let response = await semcastAxios.get(ACCOUNT_PRICING_WITH_SEGMENT_ID.replace(SEGMENT_ID_PLACE_HOLDER, segmentId), {withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.data;
        } catch (e) {
            return null
        }
    }
}


export const fetchAlreadyActivatedData = async (segmentId) => {
    let response = await semcastAxios.get(SEGMENT_ACTIVATION_DATA_API + segmentId, {withCredentials: true});
    if (response.data.status === SUCCESS_STATUS) {
        try {
            return response?.data?.data;
        } catch (e) {
            return null;
        }
    }
}

export const fetchSavableFieldsPreFillData = async (company, fieldName) => {
    try {
        let response = await semcastAxios.get(COMPANY_FIELDS_PREFILLED_DATA.replace(COMPANY_ID_PLACE_HOLDER, company)
                .replace(FIELD_ID_PLACE_HOLDER, fieldName),
            {withCredentials: true});
        if (response.data.status === SUCCESS_STATUS) {
            try {
                return response?.data?.data;
            } catch (e) {
                return null
            }
        }
    } catch (e) {
        return null
    }
}