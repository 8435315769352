import axios from "axios";
import {NOTIFICATION_API, NOTIFICATION_OUTSTANDING_API} from "../api/constants";

import {
    semcastAxios,
    showErrorToastMessage,
    showSuccessToastMessage,
} from "../Utils/Common";

export const loadNotificationsOutstanding = async () => {
    let response = null
    response = await axios.get(NOTIFICATION_OUTSTANDING_API, {withCredentials: true});
    // console.log("loadNotificationsResponse=", response);

    if (response.data.status === 'success') {
        try {
            return response?.data?.data
        } catch (e) {
            return []
        }
    }
}

export const loadNotifications = async () => {
    let response = null
    response = await axios.get(NOTIFICATION_API, {withCredentials: true});
    // console.log("loadNotificationsResponse=", response);

    if (response.data.status === 'success') {
        try {
            return response?.data?.data
        } catch (e) {
            return []
        }
    }
}

export const updateNotifications = async (item) => {
    // console.log("updateNotificationsItem=", item);
    //let response = await axios.patch(`${NOTIFICATION_API}/${item.id}`, item, {withCredentials: true});
    let response = await semcastAxios.patch(`${NOTIFICATION_API}/${item.id}`, item, {withCredentials: true});
console.log(response);
    if (response.data.status === 'success') {
        try {
            return response?.data?.data
        } catch (e) {
            return []
        }
    }
}