import React, {useState, useEffect} from "react";
import {loadSegments} from "../../actions/TreeDashboardActions";
import {segmentLocations} from "../../actions/DashboardActions";
// import SegmentsTable from "../Components/Segmentation/SegmentsTable";
import TreeSegmentDashboardAppBar from "../Components/Segmentation/TreeSegmentDashboardAppBar";
import {Card, CardHeader, CardBody} from "@progress/kendo-react-layout";
import {UserContext} from "../../contexts/UserContext";
import {Formik, useFormik} from "formik";
import * as Yup from "yup";
import LookalikeSegment from "../Components/Segmentation/LookalikeSegment";
import Identity from "../Components/Segmentation/Identity";
import TreeAppendReplace from "../Components/Segmentation/TreeAppendRelace";
import TreeMergeSegments from "../Components/Segmentation/TreeMergeSegments";
import SplitSegment from "../Components/Segmentation/SplitSegment";
import CopySegment from "../Components/Segmentation/CopySegment";
import TreeDeleteSegments from "../Components/Segmentation/TreeDeleteSegments";
import {useHistory} from "react-router-dom";
import {SegmentTopRightBlockActions} from "../../helpers/SegmentationHelpers";
import Loader from "../Components/Loader";
import '../css/segmentationDashboard.css';
import TreeSegmentsTable from "../Components/Segmentation/TreeSegmentsTable.js";
import TreeDeactivateSegments from "../Components/Segmentation/TreeDeactivateSegments";
import {useShepherdTour} from "react-shepherd";
import {dashboardTourSteps, tourOptions} from "./GuidedTour";
import Synchronize from "../Components/Segmentation/Synchronize";
import IdentityActivation from "../Components/Segmentation/IdentityActivation";



const TreeSegmentationDashboard = () => {

    let history = useHistory();
    const {user, setUser} = React.useContext(UserContext);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSortLoaded, setIsSortLoaded] = useState(true);
    const [items, setItems] = useState([]);
    const [itemId, setItemId] = useState(null);
    const [openSection, setOpenSection] = useState(null);
    const [segmentName, setSegmentName] = useState([]);
    const [groupId, setGroupId] = useState();
    const [sortedConfig, setSortedConfig] = React.useState({key: "", direction: ""});
    const [deleteMode, setDeleteMode] = useState("");
    const handleClose = () => {
        setItemId(null);
    };
    const [accessibleGroups, setAccessibleGroups] = useState([]);
    const [accessibleUsers, setAccessibleUsers] = useState([]);
    const [locations, setLocations] = useState([]);
    const [rightTopSelection, setRightTopSelection] = useState({status:sessionStorage.getItem("dropDownStatus"),group:sessionStorage.getItem("dropDownGroup"),user:sessionStorage.getItem("dropDownUser")});
    const [searchSegmentName, setSearchSegmentName] = useState(sessionStorage.getItem("searchSegmentName"));
    const [statusValue, setStatusValue] = useState("All");
    const [result, setResult] = useState([]);

    const showSortLoaded = (mode) => {
        setIsSortLoaded(mode);
    };

    const identifyDeleteMode = (mode) => {
        setDeleteMode(mode);
    };

    const setSelectedSegmentName = (segment_name) => {
        setSegmentName(segment_name);
    }
    const reloadAfterAction = async () => {
        setOpenSection(undefined);
        setIsLoaded(false);
        await profileFormik.setFieldValue("selectedSegments", []);
        setItems([]);
        await loadItems();
    }


    const actionSubscriberOfTopRightBlock = (action: SegmentTopRightBlockActions) => {
        setOpenSection(action)
    }
    const loadItems = async () => {
        // console.log("loadItems")
        // setRightTopSelection({status:sessionStorage.getItem("dropDownStatus"),group:sessionStorage.getItem("dropDownGroup"),user:sessionStorage.getItem("dropDownUser")})
        try {
            await loadSegments().then((result) => {
 //               console.log("loadItemsResult=", result);
//                let filteredResult = result.filter((=>)
                setItems(reloadCheckbox(result.map((dataItem) => Object.assign({selected: false}, dataItem))));
            });
        } catch {
            setError(true);
        } finally {
            setIsLoaded(true);
        }
    };


    const reloadCheckbox =  (items) => {
        let selectedSegment = profileFormik.values.selectedSegments;
        items = items.map((dataItem) => Object.assign({selected: false}, dataItem));
        if(selectedSegment.length > 0){
            for (let i = 0; i < selectedSegment.length; i++) {
                const idA = selectedSegment[i].id;
                for (let j = 0; j < items.length; j++) {
                    const idB = items[j].id;
                    if (idA === idB) {
                        items[j].selected = true;
                        break;
                    }
                }
            }
        }
        return items;
    }

    // const liveLoadItems =  async (segmentName, rightTopSelectionObj, sortConfig) => {
    //     try {
    //          await loadSegments(user?.id, segmentName, rightTopSelection, sortConfig).then((result) => {
    //              setItems(reloadCheckbox(result));
    //         });
    //         // setIsLoaded(false);
    //         setIsLoaded(true);
    //         console.log("live-loading-dashboard")
    //         console.log(isLoaded)
    //     } catch (e) {
    //         console.log(e)
    //         setError(true);
    //     }
    // };


    const profileFormik = useFormik({
        initialValues: {
            segment_name: "",
            user_group: user.defaultGroupId,
            segment_notes: "",
            segment_location: [],
            classNameForThirdInput: "note-popover",
            classNameForFourthInput: "note-popover",
            classNameForFifthInput: "note-popover",
            selectedSegments: [],
            selectedAll: false
        },
        // We used Yup here.
        validationSchema: Yup.object().shape({
            segment_name: Yup.string().required("Segment Name is required"),
            user_group: Yup.string().required("Group Name is required"),
            //segment_location: Yup.string().required("Location is required"),
        }),
        onSubmit: (values) => {
        },
    });



    useEffect(async () => {

        segmentLocations().then((locations) => {

            setLocations(locations)
        });

        await profileFormik.setFieldValue("selectedSegments", []);

                await loadItems();

    }, []);
    const dashboardTour = useShepherdTour({ tourOptions, steps: dashboardTourSteps });
    useEffect(()=>{

    },[items])
    useEffect(() => {
       sessionStorage.setItem('guided_tour',user.initial_guided_tour_completed);
        if(isLoaded && !user.initial_guided_tour_completed){
            // console.log("dashboardTour start");
            dashboardTour.start();
        }
    }, [isLoaded]);
    return (
        <div className="segment-dashboard-content">
            <Card className={"segmentation-dashboard-card"}>
                {/*<CardHeader>*/}
                <div id={'dashboard-app-bar'}>
                    <TreeSegmentDashboardAppBar
                        actionSubscriberOfTopRightBlock={actionSubscriberOfTopRightBlock}
                        loadItems={loadItems}
                        // onChangeStatus={onChangeStatus}
                        profileFormik={profileFormik}
                        segmentName={segmentName}
                        accessibleGroups={accessibleGroups}
                        accessibleUsers={accessibleUsers}
                        identifyDeleteMode={identifyDeleteMode}
                        reloadAfterAction={reloadAfterAction}
                        items={items}
                    />
                </div>
                {/*</CardHeader>*/}
                <CardBody>
                    <div>
                        {(openSection === SegmentTopRightBlockActions.MERGE && profileFormik.values.selectedSegments.length > 1) &&
                            <div className="col-xl-12 col-lg-12 col-md-12 "
                                 id="collapseMerge">
                                <TreeMergeSegments cancelSubscriber={() => {
                                    setOpenSection(undefined)
                                }} reloadAfterMerge={reloadAfterAction} visible={true} mergeSegments={{profileFormik}}/>
                            </div>
                        }
                        {(openSection === SegmentTopRightBlockActions.APPENDREPLACE && profileFormik.values.selectedSegments.length === 2) &&
                            <div className="col-xl-12 col-lg-12 col-md-12 "
                                 id="collapseAppendReplace">
                                <TreeAppendReplace cancelSubscriber={() => {
                                    setOpenSection(undefined)
                                }} reloadAfterAppendReplace={reloadAfterAction} visible={true} appendReplace={{profileFormik}}/>
                            </div>
                        }
                        {openSection === SegmentTopRightBlockActions.SPLIT && <div
                            className="col-xl-12 col-lg-12 col-md-12 "
                            id="collapseSplit">
                            <SplitSegment cancelSubscriber={() => {
                                setOpenSection(undefined)
                            }} reloadAfterSplit={reloadAfterAction}
                                          visible={true}
                                          segId={profileFormik.values.selectedSegments[0].id}
                                          segName={profileFormik.values.selectedSegments[0].objectName}
                                          groupId={profileFormik.values.selectedSegments[0].groupId}
                                          splitSegment={{profileFormik}}/>

                        </div>
                        }
                        {openSection === SegmentTopRightBlockActions.COPY && <div
                            className="col-xl-12 col-lg-12 col-md-12 collapseCopy"
                            id="collapseCopy">
                            <CopySegment cancelSubscriber={() => {
                                setOpenSection(undefined)
                            }} reloadAfterCopy={reloadAfterAction}
                                         visible={true}
                                         segId={profileFormik.values.selectedSegments[0].id}
                                         segName={profileFormik.values.selectedSegments[0].objectName}
                                         groupId={profileFormik.values.selectedSegments[0].groupId}
                                         copySegment={{profileFormik}}/>

                        </div>
                        }
                        {openSection === SegmentTopRightBlockActions.LOOK_LIKE && <div
                            className="col-xl-12 col-lg-12 col-md-12"
                            id="collapseLookalike">
                            <LookalikeSegment cancelSubscriber={() => {
                                setOpenSection(undefined)
                            }} reloadAfterLookalike={reloadAfterAction}
                                              visible={true}
                                              segName={profileFormik.values.selectedSegments[0].objectName}
                                              segId={profileFormik.values.selectedSegments[0].id}
                                              lookalikeSegment={{profileFormik}}/>

                        </div>
                        }
                        {openSection === SegmentTopRightBlockActions.DELETE && <div
                            className="col-xl-12 col-lg-12 col-md-12"
                            id="collapseDelete">
                            <TreeDeleteSegments cancelSubscriber={() => {
                                setOpenSection(undefined)
                            }} reloadAfterDelete={reloadAfterAction} visible={true} deleteMode={{deleteMode}} segName={profileFormik.values.selectedSegments[0].objectName}
                                            segId={profileFormik.values.selectedSegments[0].id} segType={profileFormik.values.selectedSegments[0].objectType} deleteSegments={{profileFormik}}/>
                        </div>
                        }
                        {openSection === SegmentTopRightBlockActions.DEACTIVATE && <div
                            className="col-xl-12 col-lg-12 col-md-12"
                            id="collapseDeactivate">
                            <TreeDeactivateSegments cancelSubscriber={() => {
                                setOpenSection(undefined)
                            }} reloadAfterDelete={reloadAfterAction} visible={true} segName={profileFormik.values.selectedSegments[0].objectName}
                                                segId={profileFormik.values.selectedSegments[0].id} segType={profileFormik.values.selectedSegments[0].objectType} deactivateSegments={{profileFormik}}/>
                        </div>
                        }
                        {openSection === SegmentTopRightBlockActions.IDENTITY && <div
                            className="col-xl-12 col-lg-12 col-md-12"
                            id="collapseLookalike">
                            <Identity cancelSubscriber={() => {
                                setOpenSection(undefined)
                            }} reloadAfterIdentity={reloadAfterAction}
                                              visible={true}
                                              segName={profileFormik.values.selectedSegments[0].objectName}
                                              segId={profileFormik.values.selectedSegments[0].id}
                                              Identity={{profileFormik}}/>

                        </div>
                        }
                        {openSection === SegmentTopRightBlockActions.IDENTITYIDX && <div
                            className="col-xl-12 col-lg-12 col-md-12"
                            id="collapseLookalike">
                            <IdentityActivation cancelSubscriber={() => {
                                setOpenSection(undefined)
                            }} reloadAfterIdentity={reloadAfterAction}
                                      visible={true}
                                      segName={profileFormik.values.selectedSegments[0].objectName}
                                      segId={profileFormik.values.selectedSegments[0].id}
                                      Identity={{profileFormik}}
                                      items={items}/>

                        </div>
                        }
                        {openSection === SegmentTopRightBlockActions.SYNCHRONIZE && <div
                            className="col-xl-12 col-lg-12 col-md-12"
                            id="collapseSynchronize">
                            <Synchronize cancelSubscriber={() => {
                                setOpenSection(undefined)
                            }} reloadAfterSynchronize={reloadAfterAction}
                                      visible={true}
                                      segName={profileFormik.values.selectedSegments[0].objectName}
                                      segId={profileFormik.values.selectedSegments[0].id}
                                      synchronize={{profileFormik}}
                            />

                        </div>
                        }
                        {isLoaded ? (
                            <TreeSegmentsTable
                                sortedConfig={sortedConfig}
                                items={items}
                                loadItems={loadItems}
                                reloadCheckbox={reloadCheckbox}
                                profileFormik={profileFormik}
                                history={history}
                                setSelectedSegmentName={setSelectedSegmentName}
                                actionSubscriberOfTopRightBlock={actionSubscriberOfTopRightBlock}
                                identifyDeleteMode={identifyDeleteMode}
                                openSection={openSection}
                                isSortLoaded={isSortLoaded}
                            />
                        ) : (
                            <Loader height={"100%"}/>
                        )}
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default TreeSegmentationDashboard;
