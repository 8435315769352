 import {
    aicpaSoc,
    accessSupportMd,
    activateLogoBlack,
    activateLogoBlackOLD,
    activateLogoWhite,
    activateLogoWhiteOLD,
    activation,
    acxiomPng,
    acxiomSvg,
    adformIconPng,
    adformLogoSvg,
    adobeIconPng,
    adobeLogoSvg,
    advContextualIconPng,
    advContextualIconSvg,
    advContextualLogoDarkWebP,
    advContextualLogoVerticalPng,
    advContextualLogoVerticalSvg,
    advContextualLogoDarkSvg,
    affinityRev1Svg,
    affinityRev2Svg,
    affinityRev3Svg,
    affinityRev4Svg,
    affinitySolutionsPng,
    affinitySolutionsSvg,
    amazonIconPng,
    amazonLogoSvg,
    ambioIconSvg,
    ambioLogoSvg,
    amnetLogoSvg,
    amnetIconPng,
    amobeeIconPng,
    amobeeLogoSvg,
    aolIconPng,
    aolLogoSvg,
    attributionPng,
    audienceDesignerLogoPng,
    audienceBuilderPng,
    barChartSvg,
    beeswaxIconPng,
    beeswaxLogoSvg,
    chartPng,
    cookieFreePng,
    datastreamPng,
    datastreamPSvg,
    dataxuLogoSvg,
    directoryPng,
    directoryupPng,
    drumLogoPng,
    dv360IconPng,
    dv360LogoPng,
    dv360LogoSvg,
    expandCollapsePng,
    expandCollapseSvg,
    facebookIconPng,
    facebookLogoSvg,
    filePng,
    googleIconPng,
    googleLogoSvg,
    googleadIconPng,
    googleadLogoSvg,
    HealthwiseDataLogoSansBkgrndSvg,
    healthwiseJpg,
    healthwisePng,
    healthwiseSvg,
    heroHomeJpg,
    huluIconPng,
    huluLogoSvg,
    iconFile2Png,
    iconFolderUp2Png,
    instephealthlogostackedSvg,
    iqmPng,
    killiSvg,
    knowWhoPng,
    knowWhoSvg,
    liverampIconPng,
    liverampLogoPng,
    logoBlackPng,
    logoBlackOldPng,
    logoBlackOldSvg,
    logoWhitePng,
    logoWhiteSvg,
    logoWhiteOldSvg,
    logoPng,
    logoSvg,
    logoWhiteTwoSvg,
    mappingTypesPng,
    mapScreenJpg,
    matchRatesPng,
    mediamathIconPng,
    mediamathLogoSvg,
    moneyTrashSvg,
    ntHomeHeroJpg,
    omniChannelPng,
    poweredBySemcastingJpg,
    privacyPng,
    purplelabSvg,
    rebelaiIconPng,
    rebelaiLogoPng,
    resetdigitalIconPng,
    resetdigitalLogoPng,
    rokuIconPng,
    rokuLogoSvg,
    rokuOneviewFormerDataxuPng,
    rokuOneviewFormerDataxuSvg,
    scheduleSvg,
    semcastingAdobeIconPng,
    semcastingAdobeSvg,
    semcastingAmobeeIconPng,
    semcastingAmobeeLogoSvg,
    semcastingIcon00a9c5Svg,
    semcastingLogo00a9c5Svg,
    semcastingTradedeskIconPng,
    semcastingTradedeskLogoSvg,
    semcastingTradedeskThirdPartyLogoSvg,
    semcastingXandrIconPng,
    semcastingXandrLogoPng,
    spectrumIconBluePng,
    spectrumIconWhitePng,
    speedPng,
    springserveIconPng,
    springserveLogoPng,
    surveyMdPng,
    titleBasedTargeting5Png,
    titleBasedTargeting5Svg,
    tradedeskIconPng,
    tradedeskLogoSvg,
    training2MdPng,
    trainingMdPng,
    transparencyPng,
    tvsciIconPng,
    tvsciLogoPng,
    tvscientificIconPng,
    tvscientificLogoPng,
    twitterIconPng,
    twitterLogoSvg,
    VerizonMediaLogoWinePng,
    VerizonMediaLogoWineSvg,
    verizonIconPng,
    womanThinkingSvg,
    xandrIconPng,
    xandrLogoSvg,
    yahooIconJpg,
    yahooLogoSvg,
    zetaIconPng,
    zetaLogoPng,
    basisIconPng,
    basisLogoPng,
    stackadaptLogoPng,
    stackadaptIconPng,
    xandrttdIconPng,
    xandrttdLogoSvg,
    digitalremedyIconPng,
    digitalremedyLogoPng,
    fundprogressIconPng,
    fundprogressLogoPng,
    pubmaticIconPng,
    pubmaticLogoPng, pinterestIconPng, pinterestLogoPng, tiktokIconPng, tiktokLogoPng

} from './index.js'
 import InStepHealthlogostacked from "react-to-pdf";


const car = {type:"Fiat", model:"500", color:"white"};

export const imagesObjectMap = {

    adform: {
        icon: adformIconPng.default,
        logo: adformLogoSvg.default,
    },

    adobe: {
        icon: adobeIconPng.default,
        logo: adobeLogoSvg.default,
    },

    amazon: {
        icon: amazonIconPng.default,
        logo: amazonLogoSvg.default,
    },

    amobee: {
        icon: amobeeIconPng.default,
        logo: amobeeLogoSvg.default,
    },

    basis: {
        icon: basisIconPng.default,
        logo: basisLogoPng.default,
    },

    digitalremedy: {
        icon: digitalremedyIconPng.default,
        logo: digitalremedyLogoPng.default,
    },

    facebook: {
        icon: facebookIconPng.default,
        logo: facebookLogoSvg.default,
    },

    fundprogress: {
        icon: fundprogressIconPng.default,
        logo: fundprogressLogoPng.default,
    },

    google: {
        icon: googleIconPng.default,
        logo: googleLogoSvg.default,
    },

    googleads: {
        icon: googleadIconPng.default,
        logo: googleadLogoSvg.default,
    },

    iqm: {
        icon: iqmPng.default,
        logo: iqmPng.default
    },

    instepmeasurement: {
        icon: instephealthlogostackedSvg.default,
        logo: instephealthlogostackedSvg.default,
    },

    liveramp: {
        icon: liverampIconPng.default,
        logo: liverampLogoPng.default,
    },

    liverampbeeswax: {
      icon: beeswaxIconPng.default,
      logo: beeswaxLogoSvg.default,
    },

    mediamath: {
        icon: mediamathIconPng.default,
        logo: mediamathLogoSvg.default,
    },

    oneview: {
        icon: rokuIconPng.default,
        logo: rokuLogoSvg.default,
    },

    pinterest: {
        icon: pinterestIconPng.default,
        logo: pinterestLogoPng.default,
    },

    pubmatic: {
        icon: pubmaticIconPng.default,
        logo: pubmaticLogoPng.default,
    },

    rebelai: {
        icon: rebelaiIconPng.default,
        logo: rebelaiLogoPng.default,
    },

    roku: {
        icon: rokuIconPng.default,
        logo: rokuLogoSvg.default,
    },

    semcasting: {
        icon: semcastingIcon00a9c5Svg.default,
        logo: semcastingLogo00a9c5Svg.default,
    },

    semcasting_adobe: {
        icon: semcastingAdobeIconPng.default,
        logo: semcastingAdobeSvg.default,
    },

    semcasting_amobee: {
        icon: semcastingAmobeeIconPng.default,
        logo: semcastingAmobeeLogoSvg.default,
    },

    semcasting_ttd: {
        icon: semcastingTradedeskIconPng.default,
        logo: semcastingTradedeskLogoSvg.default,
    },

    semcasting_ttd_third_party: {
        icon: semcastingTradedeskIconPng.default,
        logo: semcastingTradedeskThirdPartyLogoSvg.default,
    },

    semcasting_xandr: {
        icon: semcastingXandrIconPng.default,
        logo: semcastingXandrLogoPng.default,
    },

    semcastingmeasurement:{
        icon: semcastingIcon00a9c5Svg.default,
        logo: semcastingLogo00a9c5Svg.default,
    },

    springserve: {
        icon: springserveIconPng.default,
        logo: springserveLogoPng.default,
    },

    stackadapt: {
        icon: stackadaptIconPng.default,
        logo: stackadaptLogoPng.default,
    },

    tiktok: {
        icon: tiktokIconPng.default,
        logo: tiktokLogoPng.default,
    },

    tradedesk: {
        icon: tradedeskIconPng.default,
        logo: tradedeskLogoSvg.default,
    },

    tvscientific: {
        icon: tvscientificIconPng.default,
        logo: tvscientificLogoPng.default,
    },

    xandr: {
        icon: xandrIconPng.default,
        logo: xandrLogoSvg.default,
    },

    xandrdv360: {
        icon: dv360IconPng.default,
        logo: dv360LogoSvg.default,
    },

    yahoo: {
        icon: yahooIconJpg.default,
        logo: yahooLogoSvg.default,
    }
}