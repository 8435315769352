import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const semcastingttd3rdpartyConfig = {
  ...COMPANY_BASE_PROPERTIES,

  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "3rd-Party Onboarding With TTD via Semcasting",

  /**
   * HTML that explains how to use the form
   */
  instructions:
  "You have selected a platform that automatically reports usage for targeting."
  +"You will be billed for data usage by the platform unless the segment is applied for suppression purposes."
  +"Suppression segments are billed by Semcasting directly based on the discounted deployed record formula."
  +"Activating this audience means you agree to this billing method.",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "semcasting_ttd_third_party",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "semcasting_ttd_third_party",
    text: "TTD 3rd Party via Semcasting",
    img: "semcasting-tradedesk-third-party-logo.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink: "", //'<a href="https://auth.thetradedesk.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fresponse_type%3Did_token%26response_mode%3Dform_post%26scope%3Dopenid%2520profile%26client_id%3Ddesk%26nonce%3D733520271c7af38289e092f210a2bfd4%26state%3DGBUGoL1Q3aLFIbvGDJPQZDFg7rpc4lEv79%252F6rS%252Fdj0RMq4rZdShJ%252BQ81%252FnKkL0EWkzkB10V8Ct0mARYRI9uwzDngzyJTZNEoHJYTWCOyqrCmpJMz14ibuqfx7R8X9UEF5pTLPiUtSDjIu7eT6MC6A9GMoTXpXYXZ8jWxyPcs1sP5W%252BZilcMPv0knZJOCqd7d7Pq0hb4fRN4eOl0DfKLc%252Fxa2ZUPTiIh1xBohGqAv5yE%253D%26redirect_uri%3Dhttps%253A%252F%252Fdesk.thetradedesk.com%252Fsignin-oidc%26appReturnUrl%3Dhttps%25253A%25252F%25252Fdesk.thetradedesk.com%25252F%26appUrl%3Dhttps%25253A%25252F%25252Fdesk.thetradedesk.com%25252FAccount%25252FLogOn" target="_blank">Log in to The Trade Desk</a>',

  layout: [
    {
      fieldType: "radioGroup",
      labelText: "Mobile Devices",
      fieldName: "mobile_devices",
      required: true,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: true,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },
      ],
    },
    {
      fieldType: "checkboxGroup",
      labelText: "UID2",
      fieldName: "device_type",
      required: false,
      checkboxes: [
        {
          labelText: "UID2",
          fieldName: "UID2", // FD#2963
          value: "UID2", // FD#2963
          checked: false,
        },
      ],
    },
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType: "radioGroup",
      labelText: "Pricing Method",
      fieldName: "pricing_method",
      required: true,
      radioButtons: [
        {
          labelText: "Hybrid",
          fieldName: "hybrid_pricing",
          value: "hybrid_pricing",
          checked: true,
        },
        {
          labelText: "CPM",
          fieldName: "cpm_pricing",
          value: "cpm_pricing",
          checked: false,
        },
      ],
    },
    {
      fieldType: "percentOfMediaCostRate",
      fieldName: "percent_of_media_cost_rate",
      labelText: "Percent of Media Cost Rate",
      url: "/v2/secure/attributor/activate-credentials/semcasting_ttd_third_party/percent_of_media_cost_rate",
      namePropName: "value",
      textPropName: "value",
      valuePropName: "val",
      show:true,
      required: true,
      value: "0.20",
      validation: [
        {
          required: function (value, attr, computed) {
            var val = !isNaN(value) ? parseFloat(value) : 0;
            return val <= 0
              ? "Percent of Media Cost must be greater than 0"
              : "";
          },
        },
      ],
    },
    {
      fieldType: "cpmCap",
      fieldName: "cpm_cap",
      labelText: "CPM Cap",
      url: "/v2/secure/attributor/activate-credentials/semcasting_ttd_third_party/cpm_cap",
      namePropName: "value",
      textPropName: "value",
      valuePropName: "val",
      show:true,
      required: true,
      value: "6.00",
      validation: [
        {
          required: function (value, attr, computed) {
            var val = !isNaN(value) ? parseFloat(value) : 0;
            return val <= 0 ? "CPM Cap must be greater than 0" : "";
          },
        },
      ],
    },
    {
      fieldType:'cpmField',
      fieldName:'cpm',
      labelText:'CPM',
      url: '/v2/secure/attributor/activate-credentials/semcasting_ttd_third_party/cpm',
      namePropName: 'value',
      textPropName: 'value',
      valuePropName: 'val',
      show:true,
      required:true,
      value:'5.00',
      validation:[{
        required:function(value, attr, computed){
          var val = !isNaN(value) ? parseFloat(value) : 0;
          return val < 5 ? 'CPM must be at least $5.00' : '';
        }
      }]
    },{
      fieldType: 'textField',
      fieldName: 'segment_description',
      labelText: 'Segment Description (Optional)',
      prepopulateWith: 'text',
      required: false
    },{
      fieldType: 'radioGroup',
      labelText: 'Audience Availability',
      fieldName: 'audience_availability',
      required: true,
      radioButtons: [
        {
          labelText: 'System-Wide',
          fieldName: 'system',
          value: 'system',
          subtext:'This will make the audience available system-wide',
          checked: true
        }, {
          labelText: 'Partner ID',
          fieldName: 'partner',
          value: 'partner',
          subtext:'This will make the audience available across all Advertisers',
          checked: true
        }, {
          labelText: 'Advertiser ID',
          fieldName: 'advertiser',
          value: 'advertiser',
          subtext:'This will make the audience available to a specific Advertiser',
          checked: false
        }
      ]
    },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelectTTDPartner",
          fieldName: "partner_id",
          labelText: "Partner ID",
          url: "/v2/secure/attributor/activate-credentials/semcasting_ttd_third_party/partner_id",
          placeholder: "Select an Partner ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
          show: true,
        },
        {
          fieldType: "searchSelectTTDAdvertiser",
          fieldName: "advertiser_id",
          labelText: "Advertiser ID",
          url: "/v2/secure/attributor/activate-credentials/semcasting_ttd_third_party/advertiser_id",
          placeholder: "Select an Advertiser ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
          show: true,
        },
        {
          fieldType:'checkbox',
          fieldName: "save_credentials",
          labelText:'Save TTD Partner/Advertiser ID for future use',
          checked: false,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },{
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
    {
      fieldType: "pricing",
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["partner_id", "advertiser_id"],
  formik: {
    initialValues: {
      device_type: [],
      mobile_devices: "top_ios_android",
      pricing_method: "hybrid_pricing",
      partner_id:"",
      advertiser_id: "",
      campaign_range_start: "",
      campaign_range_end: "",
      audience_availability: "partner",
      save_credentials: false,
      cpm_cap: "6.00",
      cpm:"5.00",
      percent_of_media_cost_rate: "0.20",
      audience_usage:"",
      creativeTagIdDropDown: "",
      creativeTagId:"",
      extraDataForFormOps: {
        partner_id: {
          show: true,
        },
        advertiser_id: {
          show: true,
        },
        percent_of_media_cost_rate: {
          show: true,
        },
        cpm: {
          show: true,
        },
        cpm_cap: {
          show: true,
        },
      },
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      audience_availability: Yup.string().required(
        "Audience Availability is required"
      ),
      partner_id: Yup.string().when("audience_availability", {
        is: "partner",
        then: Yup.string().required("Partner ID is required"),
      }),
      advertiser_id: Yup.string().when("audience_availability", {
        is: "advertiser",
        then: Yup.string().required("Advertiser ID is required"),
      }),
      cpm_cap: Yup.string().when("pricing_method", {
        is: "hybrid_pricing",
        then: Yup.string().required("CPM Cap is required"),
      }),
      percent_of_media_cost_rate: Yup.string().when("pricing_method", {
        is: "hybrid_pricing",
        then: Yup.string().required("Percent of Media Cost Rate is required"),
      }),
      cpm: Yup.string().when("pricing_method", {
        is: "cpm_pricing",
        then: Yup.string().required("CPM is required"),
      }),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
    }),
    onSubmit: (values) => {},
  },
};
