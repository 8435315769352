export const mappingTypes = (type) => {
  switch (type) {
    case 'MRI_ID':
      return {
        mriIdIndex:{
          name:'mriId',
          columnIndex:null,
          optional:false
        }
      }
    case 'ADDRESS':
      return {
        address1Index: {
          name: 'Address 1',
          value: 'address1Index',
          columnIndex: null,
          optional: false
        },
        address2Index: {
          name: 'Address 2',
          value: 'address2Index',
          columnIndex: null,
          optional: true
        },
        cityIndex: {
          name: 'City',
          value: 'cityIndex',
          columnIndex: null,
          optional: false
        },
        stateIndex: {
          name: 'State',
          value: 'stateIndex',
          columnIndex: null,
          optional: false
        },
        zip5Index: {
          name: 'Zip 5',
          value: 'zip5Index',
          columnIndex: null,
          optional: false
        },
        zip9Index: {
          name: 'Zip 9',
          value: 'zip9Index',
          columnIndex: null,
          optional: true
        }
      }
    case 'AFFINITY_ID':
      return {
        affinityIdIndex: {
          name: 'Affinity Id',
          columnIndex: null,
          optional: false
        }
      }
    case 'COMPANY_NAME_ADDRESS':
      return {
        companyNameIndex:{
          name:'Company Name',
          columnIndex:null,
          optional:false
        },
        address1Index:{
          name:'Address 1', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        },
        address2Index:{
          name:'Address 2',
          columnIndex:null,
          optional:true
        },
        cityIndex:{
          name:'City',
          columnIndex:null,
          optional:false
        },
        stateIndex:{
          name:'State',
          columnIndex:null,
          optional:false
        },
        zip5Index:{
          name:'Zip 5',
          columnIndex:null,
          optional:false
        }
      }
    case 'COMPANY_NAME':
      return {
        companyNameIndex:{
          name:'Company Name', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'CTVMRM_ID':
      return {
        ctvmrmIdIndex:{
          name:'Ctv MRM ID', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'DATATRUST_ID':
      return {
        dataTrustIdIndex:{
          name:'DataTrust Id', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'DEVICE_ID':
      return {
        deviceIdIndex:{
          name:'Device Id', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'DOMAIN_NAME':
      return {
        domainNameIndex:{
          name:'Domain Name', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'EMAIL':
      return {
        emailIndex:{
          name:'Email',
          columnIndex:null,
          optional:false
        }
      }
    // case 'HEALTHWISE_ID':
    //   return {
    //     zip5Index:{
    //       name:'zip5', //name is the column header name in the table
    //       columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
    //       optional:false
    //     },
    //     hhIdIndex:{
    //       name:'hhId', //name is the column header name in the table
    //       columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
    //       optional:false
    //     },
    //     indvIdIndex:{
    //       name:'indvId', //name is the column header name in the table
    //       columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
    //       optional:false
    //     }
    //   }
    case 'HUMANA_ADDRESS':
      return {
        address1Index:{
          name:'Address 1', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        },
        address2Index:{
          name:'Address 2',
          columnIndex:null,
          optional:true
        },
        cityIndex:{
          name:'City',
          columnIndex:null,
          optional:false
        },
        stateIndex:{
          name:'State',
          columnIndex:null,
          optional:false
        },
        zip5Index:{
          name:'Zip 5',
          columnIndex:null,
          optional:true
        },
        humanaaIdIndex:{
          name:'sdr',
          columnIndex:null,
          optional:false
        }
      }
    case 'IMPRESSIONS':
      return {
        ipIndex: {
          name: 'IP Number',
          columnIndex: null,
          optional:false
        },
        timeStampIndex: {
          name: 'Time Stamp',
          columnIndex: null,
          optional:false
        },
        idIndex: {
          name: 'Impression ID', //name of the column
          columnIndex:null,
          optional:true
        }
      }
    case 'LAT_LONG':
      return {
        latitudeIndex:{
          name:'Latitude', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        },
        longitudeIndex:{
          name:'Longitude', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'MD5_EMAIL':
      return {
        md5EmailIndex:{
          name:'MD5 Email', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'SHA1_EMAIL':
      return {
        sha1EmailIndex:{
          name:'SHA1 Email', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'SHA256_EMAIL':
      return {
        sha256EmailIndex:{
          name:'SHA256 Email', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'UNKNOWN':
      return {
        ipIndex: {
          name: 'IP Number',
          columnIndex: null,
          optional:false
        },
        timeStampIndex: {
          name: 'Time Stamp',
          columnIndex: null,
          optional:false
        },
        idIndex: {
          name: 'Impression ID',
          columnIndex:null,
          optional:true
        },
        //address
        address1Index:{
          name:'Address 1',
          columnIndex:null,
          optional:false
        },
        address2Index:{
          name:'Address 2',
          columnIndex:null,
          optional:true
        },
        cityIndex:{
          name:'City',
          columnIndex:null,
          optional:false
        },
        stateIndex:{
          name:'State',
          columnIndex:null,
          optional:false
        },
        zip5Index:{
          name:'Zip 5',
          columnIndex:null,
          optional:true
        },
        zip9Index:{
          name:'Zip 9',
          columnIndex:null,
          optional:true
        },

        //email
        emailIndex:{
          name:'Email',
          columnIndex:null,
          optional:false
        },
        //usphone
        USPhoneIndex:{
          name:'Phone Number',
          columnIndex:null,
          optional:false
        },

        //web_log
        userAgentIndex: {
          name: 'User Agent',
          columnIndex: null,
          optional:true
        }
      }
    case 'US_PHONE':
      return {
        USPhoneIndex:{
          name:'Phone Number',
          columnIndex:null,
          optional:false
        }
      }
    case 'VOTER_ID':
      return {
        voterIdIndex:{
          name:'Voter Id', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'WEB_LOG':
      return {
        ipIndex: {
          name: 'IP Number', //name of the column
          columnIndex: null, //default index of the values in columnValues[i]
          optional:false
        },
        timeStampIndex: {
          name: 'Time Stamp',
          columnIndex: null,
          optional:false
        },
        userAgentIndex: {
          name: 'User Agent',
          columnIndex: null,
          optional:true
        }
      }
    case 'XANDR_ID':
      return {
        xandrIdIndex:{
          name:'Xandr Id', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'ZIP5':
      return {
        zip5Index:{
          name:'5 Digit Zip Code',
          columnIndex:null,
          optional:false
        }
      }
    case 'ZIP9':
      return {
        zip9Index:{
          name:'9 Digit Zip Code',
          columnIndex:null,
          optional:false
        }
      }
    case 'ZIP11':
      return {
        zip11Index:{
          name:'11 Digit Zip Code',
          columnIndex:null,
          optional:false
        }
      }
    case 'DMA_ID':
      return {
        dmaIndex:{
          name:'3 Digit DMA Code',
          columnIndex:null,
          optional:false
        }
      }
    case 'PUBMATIC_ID':
      return {
        pubMaticIdIndex:{
          name:'pubmatic Id',
          columnIndex:null,
          optional:false
        }
      }
    case 'ACXIOM_ID':
      return {
        acxiomIdIndex:{
          name:'acxiom Id',
          columnIndex:null,
          optional:false
        }
      }
    case 'AUDIENCE_ACUITY_ID':
      return {
        audienceAcuityIdIndex:{
          name:'Audience Acuity Id',
          columnIndex:null,
          optional:false
        }
      }
    case 'KEYWORD':
      return {
        keywordIndex:{
          name:'Keyword',
          columnIndex:null,
          optional:false
        }
      }
    case 'SITES':
      return {
        siteIndex:{
          name:'Site Name',
          columnIndex:null,
          optional:false
        }
      }
    case 'FREEWHEEL_ID':
      return {
        freeWheelIdIndex:{
          name:'FreeWheel ID',
          columnIndex:null,
          optional:false
        }
      }
    case 'PURPLE_ID':
      return {
        purpleIndividualIdIndex:{
          name:'individual_id',
          columnIndex:null,
          optional:false
        },
        purpleHouseholdIdIndex:{
          name:'household_id', //name is the column header name in the table
          columnIndex:null,  //val is the value of the column picker.  All val's are empty strings by default so they select the 'Not Present' in the column picker
          optional:false
        }
      }
    case 'NPI_ID':
      return {
        npiIdIndex:{
          name:'npiId',
          columnIndex:null,
          optional:false
        }
      }
    case 'I360_ID':
      return {
        i360IdIndex:{
          name:'i360 Id',
          columnIndex:null,
          optional:false
        }
      }
    case 'HEALTHWISE_ID':
      return {
        healthwiseIdIndex:{
          name:'healtwiseId',
          columnIndex:null,
          optional:false
        }
      }
    case 'PDI_ID':
      return {
        pdiIdIndex:{
          name:'PDI Id',
          columnIndex:null,
          optional:false
        }
      }
    case 'L2DATA_VOTER_ID':
      return {
        l2DataVoterIdIndex:{
          name:'L2Data Voter Id',
          columnIndex:null,
          optional:false
        }
      }
    case 'PERSISTENT_ID':
      return {
        persistentIdIndex:{
          name:'Semcasting Id',
          columnIndex:null,
          optional:false
        }
      }
    case 'SHA256_PERSISTENT_ID':
      return {
        sha256PersistentIdIndex:{
          name:'Sha256 Semcasting Id',
          columnIndex:null,
          optional:false
        }
      }
    case 'BLOCKGRAPH_ID':
      return {
        blockGraphIdIndex:{
          name:'Blockgraph Id',
          columnIndex:null,
          optional:false
        }
      }
    case 'ADSTRA_ID':
      return {
        adstraIdIndex:{
          name:'Adstra Id',
          columnIndex:null,
          optional:false
        }
      }
    case 'FIRST_LAST_ZIP5':
      return {
        firstNameIndex:{
          name:'first_name',
          columnIndex:null,
          optional:false
        },
        lastNameIndex:{
          name:'last_name',
          columnIndex:null,
          optional:false
        },
        zip5Index:{
          name:'zip5',
          columnIndex:null,
          optional:false
        }
      }
    default:
      return {}
  }
}