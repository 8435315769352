import React, {useState} from "react";

function DynamicAlert({ type, message }) {
  const [showAlert, setShowAlert] = useState(true);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      {showAlert && (<div className={`alert alert-danger  alert-${type} alert-dismissible fade show`}>
        <svg
          viewBox="0 0 24 24"
          width={24}
          height={24}
          stroke="currentColor"
          strokeWidth={2}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="mr-2"
        >
          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
          <line x1={15} y1={9} x2={9} y2={15} />
          <line x1={9} y1={9} x2={15} y2={15} />
        </svg>
        <strong>{type}</strong> &nbsp; &nbsp;{message}
        <button
          type="button"
          className="close h-100"
          data-dismiss="alert"
          onClick={handleCloseAlert}
          aria-label="Close"
        >
          <span>
            <i className="mdi mdi-close" />
          </span>
        </button>
      </div>
          )}
    </>
  );
}

export default DynamicAlert;
