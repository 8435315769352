import * as React from 'react';
import {DropDownList, MultiSelect} from '@progress/kendo-react-dropdowns';
import { filterBy } from "@progress/kendo-data-query";
import {Filter, Operators, TextFilter} from "@progress/kendo-react-data-tools";
import {Input} from "@progress/kendo-react-inputs";
import "./TreeSegmentsTable.css";

export const TreeNameSearchFilter = props => {

    const storedFilter = JSON.parse(localStorage.getItem("filterState"));

    const [stateValue, setStateValue] = React.useState(null);
    React.useEffect(() => {
        if(storedFilter !== undefined && storedFilter !== null && storedFilter.length > 0){
            storedFilter.forEach(e => {
                if(e.field === 'objectName'){
                    setStateValue(e.value)
                }
            })
        }else if (props.value){
            setStateValue([props.value])
        }
    }, []);
    React.useEffect(() => {

    },[stateValue])

    const handleChange = React.useCallback(event => {
        const value = event.target.value;

        setStateValue(value);

        const field = props.field;
        const filter = storedFilter ? [...storedFilter] : [...props.filter];

        const currentFilterIndex = filter.findIndex((f) => f.field === field);
        if (currentFilterIndex !== -1) {
            filter.splice(currentFilterIndex, 1);
        }

        filter.push({
            value,
            field: props.field,
            operator: "contains",
        });

        let currentFilterState = JSON.stringify(filter);
        props.onFilterChange({
            filter,
            field: props.field,
            syntheticEvent: event.syntheticEvent,
        });
        localStorage.setItem('filterState', currentFilterState);
    },[props.filter, props.onFilterChange]);


    return(
        <Input
            onChange={handleChange}
            value={stateValue}
            filterable={true}
            autoClose={false}
            placeholder={"Filter Name"}
        />
            )
}
export const TreeTypeMultiSelectFilter = props => {
    const data = [...props.data];
    const storedFilter = JSON.parse(localStorage.getItem("filterState"));
    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }
    const dropDownFilterOperator = (value, current) => {
        let newValue =
            !value ||
            !value.length ||
            value.find((val) =>
                val === 'Not Selected' && current === null
                    ? 'isnull'
                    : value.find((val) => val === current)
            );
        return newValue;
    };
    const [stateValue, setStateValue] = React.useState(null);

    React.useEffect(() => {
        if(storedFilter !== undefined && storedFilter !== null && storedFilter.length > 0){
            storedFilter.forEach(e => {
                if(e.field === 'subType'){
                    setStateValue(e.value)
                }
            })
        }else if (props.value){
            setStateValue([props.value])
        }
    }, []);

    React.useEffect(() => {
    },[stateValue])
    const onFocus = (event) => {
        // console.log(event)
    };
    const handleChange = React.useCallback(event => {
        // console.log(event);
        let value = event.target.value;
        const visitorTypes = ['Consumer-Visitor', 'Business-Visitor'];
        const idxTypes = ['IDX-Consumer', 'IDX-Business'];
        const measurementTypes = ['Consumer-Served', 'Business-Served', 'Consumer-Remaining', 'Business-Remaining'];

        if(value.includes('Site-Visitor-Tag')){
            //To display Consumer-Visitor and Business-Visitor along with Site-Visitor-Tag
            value = [...visitorTypes,...value].filter(onlyUnique);
        }
        if(value.includes('IDX-Tag')){
            //To display Consumer Business segments along with IDX-Tag
            value = [...idxTypes,...value].filter(onlyUnique);
        }
        if(value.includes('Activation')){
            //To display items along with Activation
            value = [...measurementTypes,...value].filter(onlyUnique);
        }
        // else{
        //     value = event.target.value
        // }

        // setStateValue(targetValue.concat([...value]));
        setStateValue([...value]);

        const field = props.field;
        const filter = storedFilter ? [...storedFilter] : [...props.filter];


        const currentFilterIndex = filter.findIndex((f) => f.field === field);
        if (currentFilterIndex !== -1) {
            filter.splice(currentFilterIndex, 1);
        }

        filter.push({
            value,
            field: props.field,
            operator: dropDownFilterOperator.bind(undefined, value),
        });

        let currentFilterState = JSON.stringify(filter);
        props.onFilterChange({
            filter,
            field: props.field,
            syntheticEvent: event.syntheticEvent,
        });

        localStorage.setItem('filterState', currentFilterState);
    },[props.filter, props.onFilterChange]);

    const [val, setVal] = React.useState(data);

    const handleFilterChange = (event => {
        setVal(filterBy(data.slice(), event.filter));
    });
    const focusedItemIndex = (data, inputText, textField) => {
        let text = inputText.toLowerCase();
        return data.findIndex(item =>
            String(textField ? item[textField] : item).toLowerCase().includes(text));
    };

    return (
        <div className="k-filtercell">
            <MultiSelect
                data={val}
                onChange={handleChange}
                onFocus={onFocus}
                focusedItemIndex={focusedItemIndex}
                onFilterChange={handleFilterChange}
                filterable={true}
                value={stateValue}
                autoClose={true}
                placeholder={"Filter Type"}
            />
        </div>
    );
};
export const TreeGroupMultiSelectFilter = props => {
    const data = [...props.data];
    const storedFilter = JSON.parse(localStorage.getItem("filterState"));
    const dropDownFilterOperator = (value, current) => {
        let newValue =
            !value ||
            !value.length ||
            value.find((val) =>
                val === 'Not Selected' && current === null
                    ? 'isnull'
                    : value.find((val) => val === current)
            );
        return newValue;
    };
    const [stateValue, setStateValue] = React.useState(null);

    React.useEffect(() => {
        if(storedFilter !== undefined && storedFilter !== null && storedFilter.length > 0){
            storedFilter.forEach(e => {
                if(e.field === 'groupName'){
                    setStateValue(e.value)
                }
            })
        }else if (props.value){
            setStateValue([props.value])
        }
    }, []);

    React.useEffect(() => {
    },[stateValue])


    const handleChange = React.useCallback(event => {
        const value = event.target.value;
        setStateValue([...value]);

        const field = props.field;
        const filter = storedFilter ? [...storedFilter] : [...props.filter];

        const currentFilterIndex = filter.findIndex((f) => f.field === field);
        if (currentFilterIndex !== -1) {
            filter.splice(currentFilterIndex, 1);
        }
        filter.push({
            value,
            field: props.field,
            operator: dropDownFilterOperator.bind(undefined, value),
        });

        let currentFilterState = JSON.stringify(filter);
        localStorage.setItem('filterState', currentFilterState);
        props.onFilterChange({
            filter,
            field: props.field,
            syntheticEvent: event.syntheticEvent,
        });

    },[props.filter, props.onFilterChange]);

    const [val, setVal] = React.useState(data);

    const handleFilterChange = (event => {
        setVal(filterBy(data.slice(), event.filter));
    });

    return (
        <div className="k-filtercell">
            <MultiSelect
                data={val}
                onChange={handleChange}
                onFilterChange={handleFilterChange}
                filterable={true}
                value={stateValue}
                autoClose={false}
                placeholder={"Filter Group"}
            />
        </div>
    );
};
export const TreeUserMultiSelectFilter = props => {
    const data = [...props.data];
    const storedFilter = JSON.parse(localStorage.getItem("filterState"));
    const dropDownFilterOperator = (value, current) => {
        let newValue =
            !value ||
            !value.length ||
            value.find((val) =>
                val === 'Not Selected' && current === null
                    ? 'isnull'
                    : value.find((val) => val === current)
            );
        return newValue;
    };
    const [stateValue, setStateValue] = React.useState(null);

    React.useEffect(() => {
        if(storedFilter !== undefined && storedFilter !== null && storedFilter.length > 0){
            storedFilter.forEach(e => {
                if(e.field === 'userName'){
                    setStateValue(e.value)
                }
            })
        }else if (props.value){
            setStateValue([props.value])
        }
    }, []);

    React.useEffect(() => {
    },[stateValue])


    const handleChange = React.useCallback(event => {
        const value = event.target.value;
        setStateValue([...value]);

        const field = props.field;
        const filter = storedFilter ? [...storedFilter] : [...props.filter];
        const currentFilterIndex = filter.findIndex((f) => f.field === field);
        if (currentFilterIndex !== -1) {
            filter.splice(currentFilterIndex, 1);
        }
        filter.push({
            value,
            field: props.field,
            operator: dropDownFilterOperator.bind(undefined, value),
        });
        let currentFilterState = JSON.stringify(filter);
        props.onFilterChange({
            filter,
            field: props.field,
            syntheticEvent: event.syntheticEvent,
        });
        localStorage.setItem('filterState', currentFilterState);
    },[props.filter, props.onFilterChange]);

    const [val, setVal] = React.useState(data);

    const handleFilterChange = (event => {
        setVal(filterBy(data.slice(), event.filter));
    });

    return (
        <div className="k-filtercell">
            <MultiSelect
                data={val}
                onChange={handleChange}
                onFilterChange={handleFilterChange}
                filterable={true}
                value={stateValue}
                autoClose={false}
                placeholder={"Filter User"}
            />
        </div>
    );
};

export const TreeStatusMultiSelectFilter = props => {
const data = [...props.data];
    const storedFilter = JSON.parse(localStorage.getItem("filterState"));
const dropDownFilterOperator = (value, current) => {
    let newValue =
        !value ||
        !value.length ||
        value.find((val) =>
            val === 'Not Selected' && current === null
                ? 'isnull'
                : value.find((val) => val === current)
        );
    return newValue;
};
    const [stateValue, setStateValue] = React.useState(null);

    React.useEffect(() => {
        if(storedFilter !== undefined && storedFilter !== null && storedFilter.length > 0){
            storedFilter.forEach(e => {
                if(e.field === 'status'){
                    //Convert status value to camel case for filter filed
                    let toCamelCase = e.value.map(w=>w.toLowerCase());
                    for(let i = 0; i < toCamelCase.length ; i++){
                        toCamelCase[i] = toCamelCase[i].charAt(0).toUpperCase() + toCamelCase[i].substr(1);
                    }
                    setStateValue(toCamelCase)
                }
            })
        }else if (props.value){
            //Convert status value to camel case for filter filed
            let toCamelCase = Array.from([...props.value]).map(w=>w.toLowerCase()).sort();
            for(let i = 0; i < toCamelCase.length ; i++){
                toCamelCase[i] = toCamelCase[i].charAt(0).toUpperCase() + toCamelCase[i].substr(1);
            }
            setStateValue(toCamelCase)
        }
    }, []);

    React.useEffect(() => {
    },[stateValue])

    const handleChange = React.useCallback(event => {
    const value = event.target.value.map(w=>w.toUpperCase());

        //Convert status value to camel case for filter filed
        let toCamelCase = Array.from([...value]).map(w=>w.toLowerCase()).sort();
        for(let i = 0; i < toCamelCase.length ; i++){
            toCamelCase[i] = toCamelCase[i].charAt(0).toUpperCase() + toCamelCase[i].substr(1);
        }
        setStateValue(toCamelCase)
        // setStateValue([...value]);

    const field = props.field;
    const filter = storedFilter ? [...storedFilter] : [...props.filter];

    const currentFilterIndex = filter.findIndex((f) => f.field === field);
    if (currentFilterIndex !== -1) {
        filter.splice(currentFilterIndex, 1);
    }

    filter.push({
        value,
        field: props.field,
        operator: dropDownFilterOperator.bind(undefined, value),
    });

    let currentFilterState = JSON.stringify(filter);
    props.onFilterChange({
        filter,
        field: props.field,
        syntheticEvent: event.syntheticEvent,
    });
        localStorage.setItem('filterState', currentFilterState);
},[props.filter, props.onFilterChange]);

    const [val, setVal] = React.useState(data);

    const handleFilterChange = (event => {
        setVal(filterBy(data.slice(), event.filter));
    });

return (
    <div className="k-filtercell">
        <MultiSelect
            data={val}
            onChange={handleChange}
            onFilterChange={handleFilterChange}
            filterable={true}
            value={stateValue}
            autoClose={false}
            placeholder={"Filter Status"}
        />
    </div>
);
};