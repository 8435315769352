import React, {useEffect, useState} from "react";
import {Label} from "@progress/kendo-react-labels";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";
import {Input} from "@progress/kendo-react-inputs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import LoadOverlay from "../Components/SharedComponents/LoadOverlay";
import { Checkbox } from '@progress/kendo-react-inputs';
import "./AdxTagManager.css";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";
import {SUCCESS_STATUS} from "../../api/constants";


const NewAdxTagDialog = (props) => {

    const {dialogOpen, setDialogOpen, groupsArr,
        refreshTable, setShowTagCode, setPixelTagId, setPixelDomain} = props;

    const [domain, setDomain] = useState("");
    const [tag, setTag] = useState("");
    const [groupObj, setGroupObj] = useState({group_name: "", id:"-1"});
    const [confirmed, setConfirmed] = useState("");
    const [checked, setChecked] = React.useState(false);

    useEffect(() => {

    }, [])

    const closeDialog = () => {
        setDialogOpen(false);
    }

    const onChangeDropDown = (e) => {
        if(e.target.value.id) setGroupObj(e.target.value);
    }

    const createNewAdxTag = async (domainName, groupID, checkedBool) => {
        try {
            let response = await semcastAxios.post("/v2/secure/attributor/tracking-pixels",
                {
                    domain: domainName,
                    ftp_settings_id: null,
                    group_id: groupID,
                    client_agreement: checkedBool,
                },
                {withCredentials: true})
                .then(async (res) => {
                    console.log("createNewAdxTag res:", res);


                    if (res.data.code === 200) {
                        console.log(`ADX Tag for ${res.data.data.domain} created`);

                        setPixelTagId(res.data.data.id);
                        setPixelDomain(res.data.data.domain);

                        await refreshTable().then(() => {
                            showSuccessToastMessage(`ADX Tag for ${res.data.data.domain} created`);
                            closeDialog();
                            setShowTagCode(true);
                        })
                    } else {
                        showErrorToastMessage(`Error occurred: ADX Tag not created`);
                    }

                });

            console.log("createNewAdxTag response:", response);
        } catch(e) {
            console.log(e);
            showErrorToastMessage(`Error occurred: ADX Tag not created.  Check permissions or that the domain was not already registered.`);
        }

    }

    const handleClick = () => {
        setChecked(!checked);
    };
    const handleChange = event => {
        setChecked(event.value);
    };

    const isValidDomain = (domain) => {
        const regex = /^(?!:\/\/)(?!www\.)(?!:\/\/www\.)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
        return regex.test(domain);
    }

    return (
        <div>
            <Dialog
                className={"adx-dialog dialog-standard"}
                title={"New ADX Tag"}
                closeIcon={false}
                width={775}
            >

                <div className={"adx-dialog-container"}>

                    <div className={"adx-disclaimers-div"}>
                        <div className={"adx-disclaimer-1 section-card"}>
                            <Label className={"field-label"}>Visitor identity resolution for websites and landing pages:</Label>
                            <ul>
                                <li>
                                    ADX creates the tag for you to place in Google Tag Manager - GTM or in the header of individual pages.
                                </li>
                                <li>
                                    Website traffic is converted into audience segments and refreshed daily
                                </li>
                                <li>
                                    Segments can be edited, profiled, deployed to a DSP, or used for suppression.
                                </li>
                            </ul>
                        </div>
                        <div className={"adx-disclaimer-2 section-card"}>
                            <Label className={"field-label"}>Required Terms of Use:</Label>
                            <ul>
                                <li>
                                    ADX requires that a website being tagged include opt-in and opt-out language in the privacy policy and a clear option for users to accept or block the use of cookies.
                                </li>
                                <li>
                                    The <strong>ADX FEE for tagging is $500.00 for each month</strong> the tag is active. Remove the tag to terminate billing.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className={"adx-domain-name adx-form-field"}>
                        <Label
                            className={"field-label"}
                        >
                            Domain Name
                        </Label>
                        <Input
                            name="domain_name"
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)}
                            placeholder={"ex. mywebsite.com"}
                        >
                        </Input>
                    </div>

                    <div className={"adx-save-to-group-div adx-form-field"}>
                        <Label className={"field-label"}>Save to Group</Label>
                        {/*<LoadOverlay active={defaultGroupLoading} height={25} width={25}>*/}
                        <DropDownList
                            className={"dropdown-standard"}
                            textField={"group_name"}
                            value={groupObj}
                            onChange={onChangeDropDown}
                            data={groupsArr}
                            // defaultValue={""}
                            dataItemKey={"id"}
                        />

                        <div className={"adx-dialog-save-group-hint"}>You must be a member of a group with permission to create a pixel.</div>

                    </div>

                    <div className={"adx-please-confirm-div"}>
                        <Checkbox checked={checked} onChange={handleChange} label={'Please Confirm: By checking this box and creating an ADX Tag, you agree to the terms and fees described above.'} />
                    </div>

                </div>


                <DialogActionsBar>
                    <div className={"adx-dialog-submit-button-div"}>
                        <Button
                            className={"button-standard button-white"}
                            type={'button'}
                            disabled={false}
                            onClick={() => {
                                closeDialog();
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            className={"button-standard button-submit"}
                            type={'button'}
                            disabled={!domain.length || !groupsArr.length || !checked || !isValidDomain(domain)}
                            onClick={async () => {
                                // console.log("domain:", domain);
                                // console.log("groupObj:", groupObj);
                                // console.log("checked:", checked);

                                await createNewAdxTag(domain, groupObj.id, checked);
                            }}
                        >
                            Create ADX Tag
                        </Button>
                    </div>
                </DialogActionsBar>
            </Dialog>
        </div>
    )
}

export default NewAdxTagDialog;