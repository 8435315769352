import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const googleadsConfig = {
    ...COMPANY_BASE_PROPERTIES,
    /**
     * User Readable name of company the user is onBoarding with
     */
    companyText: "Onboarding With GoogleAds",

    /**
     * lowercase no spaces or underscores name of company
     * This will be passed to the activate model and
     * used when saving to create the correct submission url
     */
    company: "googleads",
    /**
     * HTML that explains how to use the form
     */
    instructions:'<p style="color:blue;font-size:18px;">' +
        'Licensee is required to provide administrative access to their DSP, SSP or Social platform to monitor impressions served, ' +
        'or suppressions against, the onboarded audience. Semcasting does not permit self-reported usage. ' +
        'If the license holder cannot support administrative access, segments will be billed on the CPM Rate expressed at the bottom of this page ' +
        'and is based on the number of deployed records reported through the DSP, SSP or Social platform or through ADS. ' +
        'This cost is incurred on a per month basis. Please refer to the ADS licensing agreement for any further details.</strong></p>' +

        '<p style="color:red;font-size:18px;"><Strong>NOTE:</Strong> Before activating a segment to Google Ads the first time,' +
        ' users must provide Semcasting with the email address of the Google Ads user who will be granting access' +
        ' in order for Semcasting to add that user to the list of permitted users. That Google Ads user must have full access to' +
        ' the account specified by the Account ID where the segment will be written.</p>',

    /**
     * data used to create a company activation block
     */
    companyBlockOptions: {
        val: "googleads",
        text: "GoogleAds",
        img: "googleads-logo.jpeg",
    },

    /**
     * anchor tag used to login to a specific platform
     */
    loginLink:
        '',

    layout: [
        {
            fieldType:'checkboxGroup',
            labelText:"Confirm that this segment abides by ",
            navLink:<a href='https://support.google.com/adspolicy/answer/6008942?hl=en' target='_blank'>Google Ads Policies</a>,
            fieldName:'google_confirm',
            required:true,
            //disabled:false,
            checkboxes:[
                {
                    labelText:'Confirm',
                    fieldName:'confirmed',
                    value:'confirmed',
                    checked:false
                }
            ]
        },
        {
            fieldType: "checkboxGroup",
            labelText: "IDs to Activate",
            fieldName: "device_type",
            required: true,
            checkboxes: [
                {
                    labelText: "Customer ID",
                    fieldName: "CLIENT_ID",
                    value: "CLIENT_ID",
                    checked: true,
                    disabled:true,
                },
            ],
        },
        {
            fieldType: "dateRange",
            fieldName: "campaign_range",
            labelText: "Campaign Date Range",
            required: "dateRangeRequired",
        },
        {
            fieldType: 'textField',
            fieldName: 'segment_description',
            labelText: 'Segment Description (Optional)',
            prepopulateWith: 'text',
            required: false
        },
        {
            fieldType: "savableFieldsWrapper",
            layout: [
                {
                    fieldType: "searchSelect",
                    fieldName: "seat_id",
                    labelText: "GoogleAds Account ID",
                    url: "/v2/secure/attributor/activate-credentials/googleads/memberId",
                    placeholder: "Select a GoogleAds Account ID or add a new one",
                    namePropName: "value",
                    textPropName: "value",
                    valuePropName: "id",
                    required: true,
                },
                // {
                //     fieldType: 'checkbox',
                //     fieldName: "save_credentials",
                //     labelText: 'Save GoogleAds Account ID for future use',
                //     checked: false,
                // },
            ],
        },
        // FD#12518
        {
          fieldType: "savableFieldsWrapper",
          layout: [
              {
                  fieldType: "searchSelect",
                  fieldName: "manager_id",
                  labelText: "GoogleAds Manager Account ID: Note this is only required if your access to the customer account is through a manager account.",
                  url: "/v2/secure/attributor/activate-credentials/googleads/managerAccountId",
                  placeholder: "Select a Manager Account ID or add a new one",
                  namePropName: "value",
                  textPropName: "value",
                  valuePropName: "id",
                  required: false,
              },
              {
                fieldType: 'checkbox',
                fieldName: "save_credentials",
                labelText: 'Save GoogleAds Account ID and Manager Account ID for future use',
                checked: false,
            },
          ],
        },
        {
            fieldType: "radioGroup",
            labelText: "Planned Usage of this Segment:",
            fieldName: "audience_usage",
            required: true,
            radioButtons: [
                {
                    labelText: "Targeting",
                    fieldName: "use_type_inclusion",
                    value: "I",
                    checked: false,
                },
                {
                    labelText: "Suppression",
                    fieldName: "use_type_exclusion",
                    value: "X",
                    checked: false,
                },
            ],
        },
        {
            fieldType: "pricing",
        },{
            fieldType: "selectPickerTag",
            fieldName: "creativeTagIdDropDown",
            labelText: "Creative Tag ID",
            url: "/v2/secure/attributor/creative-tags",
            placeholder: "Select a New or existing Tag for your creative",
            namePropName: "creativeTagId",
            textPropName: "creativeTagId",
            valuePropName: "creativeTagId",
            required: true,
        },
        {
            fieldType: "creativeTagId",
            fieldName: "creativeTagId",
            labelText: "Creative Tag ID",
            required: true,
        },
        {
            fieldType: "creativeTag",
            fieldName: "creativeTag",
            labelText: "Creative Tag",
            required: true,
        },
        // GoogleAds Grant Access button
        {
            fieldType:'accessCode',
            fieldName:'access_code',
            labelText:'Before activating the segment, you must first grant Semcasting' +
                ' Audience Designer temporary access to your Google Ads Advertising account. Once you have authenticated to' +
                ' Google Ads and authorized access, wait for the popup dialog to disappear. Then you can activate the segment.',
            required:false, //true,
            placeholder: "...",
            disabled:false,
        },
    ],

    /**
     * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
     */
    savableDataNames: ["seat_id"],
    formik: {
        initialValues: {
            google_confirm: "",
            device_type:["CLIENT_ID"],
            campaign_range_start: "",
            campaign_range_end: "",
            save_credentials: false,
            audience_usage:"",
            access_code:"",
            seat_id:"",
            segment_description:"",
            creativeTagIdDropDown: "",
            creativeTagId:"",

        },
        // We used Yup here.
        validationSchema: Yup.object().shape({
            device_type: Yup.array().min(1, "Devices to Activate is required"),
            campaign_range_start: Yup.date().required(
                "Campaign Date Range is required"
            ),
            campaign_range_end: Yup.date().required(
                "Campaign Date Range is required"
            ),
            seat_id: Yup.string().matches(/^[0-9]{10}$/, 'GoogleAds Account ID must be exactly 10 digits and numbers only').required("GoogleAds Account ID is required."),
            // FD#12518
            manager_id: Yup.string().matches(/^[0-9]{10}$/, 'GoogleAds Manager Account ID must be exactly 10 digits and numbers only'),
            audience_usage: Yup.string().required("Planned Usage is required"),
            google_confirm: Yup.array().required("Please Confirm that this segment abides by Google Ads Policies"),
            creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
            creativeTagId: Yup.string().required("Creative Tag ID is required"),
        }),
        onSubmit: (values) => {},
    },
};
