import React, {useContext, useEffect, useState} from "react";
import {getFTPSettings, getFTPSettingsNonMatched} from "../../../actions/OnBoardingActions";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import "./FTPSS3.css";
import {props} from "react-csv/lib/metaProps";
import {NavLink} from "react-router-dom";
import {Button} from "@progress/kendo-react-buttons";

const NONMATCHED_FTPSS3 = (props) => {
    const [pageLoader, setPageLoader] = useState(false);
    const [allNonMatchedSettings, setAllNonMatchedSettings] = useState([]);
    const [currentNonMatchConnection, setCurrentNonMatchConnection] = useState(
        {text: '', id: 0}
    );

    // current Non Match connection (default / chosen) details
    const [nonMatchedConnection, setNonMatchedConnection] = useState(
        {
            id: 0,
            connectionName: '',
            protocol: 'ftps',
            host: '',
            port: '',
            destinationDir: '',
            delimiter: '',
            transferMode: 'Passive',
            username: '',
            password: '',
        }
    );

    const getAllSavedSettings = () => {
        setPageLoader(true);
        getFTPSettingsNonMatched().then((res) => {
            if (res && res.data.length) {

                setAllNonMatchedSettings(res.data);

                const setting = res.data[0];

                if(setting) {
                    setCurrentNonMatchConnection({
                        ...currentNonMatchConnection,
                        ['text']: setting.connection_name,
                        ['id']: setting.id,
                        ['protocol']: setting.transfer_protocol
                    })
                } else {
                    setCurrentNonMatchConnection({text: '', id: 0});
                }

                setNonMatchedConnection(setting);
            }
            setPageLoader(false);
        });
    };

    const nonMatchedConnections = allNonMatchedSettings?.map((item, i) => {
        if (allNonMatchedSettings.length > 0) {
            return (
                {text: item.connection_name, id: item.id, protocol: item.transfer_protocol}
            )
        } else {
            return (
                {text: "No Saved Connections", id: 0}
            )
        }
    });

    const handleConnectionChange = (e) => {
        console.log(e.value);
        const setting = allNonMatchedSettings.filter(ftpSetting => ftpSetting.id === e.value.id);
        setNonMatchedConnection(setting[0]);
        setCurrentNonMatchConnection({
            ...currentNonMatchConnection,
            ['text']: e.value.text,
            ['id']: e.value.id
        });
        props.formik.values.generate_nomatch_connection_id = e.value.id;
    };


    const itemRender = (li, itemProps) => {
        const itemChildren = (
            <span
                style={{
                    // color: itemProps.dataItem.protocol === 's3' ? "blue" : "red",
                    fontSize: "14px"
                }}
            >
                {li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }

    useEffect( () => {
        async function fetchAllSavedSettings() {
            await getAllSavedSettings();
        }
        fetchAllSavedSettings();
    }, []);

    return (
        <div className={"connections-container-div"}>
            <div className={"ftp-settings-controls-container"}>
                <div className={"ftp-settings-fts-row"}>
                    <div className={"fts-controls"}>
                        <div className={"existing-connections-dropdown-div"}>
                            <DropDownList
                                data={nonMatchedConnections}
                                itemRender={itemRender}
                                textField="text"
                                dataItemKey="id"
                                onChange={(e) => handleConnectionChange(e)}
                                value={currentNonMatchConnection}
                                fillMode={"outline"}
                                className="connection-settings"
                                disabled={allNonMatchedSettings.length===0}
                                style={{fontSize:"14px"}}
                            />
                        </div>
                    </div>
                </div>
                <div className={"non-matched-ftp-s3-button-outer-div"}>
                    <div className={"manage-connections-button-div"}>
                        <NavLink to={"/admin-settings"} className={"nav-link-to-admin"}
                                 onClick={() => sessionStorage.setItem('adminTabSelected', 2)}>
                            <Button
                                className={"button-submit non-match-ftps3-tab-button manage-conn-button"}
                                iconClass="k-icon k-i-track-changes-enable"
                            >
                                Manage Connections
                            </Button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NONMATCHED_FTPSS3;