import React, { useEffect, useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import "./HelpCenter.css";
import TrainingGuidePage from "./TrainingGuidePage";
import ResourcesPage from "./ResourcesPage"
import ContactUsPage from "./ContactUsPage";
import FAQPage from "./FAQPage";

const HelpCenterPage = (loggedIn) => {
  const [selected, setSelected] = React.useState(sessionStorage.getItem('helpCenterTabSelected') || 0);

  const handleSelect = (e) => {
    sessionStorage.setItem('helpCenterTabSelected', e.selected);

    setSelected(e.selected);
  };

  useEffect(() => {

    let tabSelected = parseInt(sessionStorage.getItem('helpCenterTabSelected'));

    if(typeof tabSelected === 'number' && tabSelected >= 0 && tabSelected <= 3) setSelected(tabSelected);
    else setSelected(0);

    document.body.style.zoom = "100%";
  });

  return (
    <div className={"help-center-container k-card box-shadow-standard section-card"}>
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title="FAQs">
          <FAQPage />
        </TabStripTab>
        <TabStripTab
          title="Training Guide"
          contentClassName={"getting-started"}
        >
          <TrainingGuidePage />
        </TabStripTab>
        <TabStripTab title="Resources">
          <ResourcesPage />
        </TabStripTab>
        <TabStripTab title="Get Support">
          <ContactUsPage />
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default HelpCenterPage;
