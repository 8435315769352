import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const semcastingadobeConfig = {
  ...COMPANY_BASE_PROPERTIES,
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With Adobe via Semcasting",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "semcasting_adobe",

    /**
   * HTML that explains how to use the form
   */
     instructions:
     "You have selected a platform that automatically reports usage for targeting."
     +"You will be billed for data usage by the platform unless the segment is applied for suppression purposes."
     +"Suppression segments are billed by Semcasting directly based on the discounted deployed record formula."
     +"Activating this audience means you agree to this billing method.",
     
  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "semcasting_adobe",
    text: "Adobe via Semcasting",
    img: "semcasting-adobe.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink:
    '<a href="https://experiencecloud.adobe.com/exc-content/login.html" target="_blank">Log in to Adobe</a>',

  layout: [
    {
      fieldType:'checkboxGroup',
      labelText:'Devices to Activate',
      fieldName:'device_type',
      required:true,
      checkboxes:[
        {
          labelText:'Adobe ID',
          fieldName:'CLIENT_ID',
          value:'CLIENT_ID',
          checked:true,
          disabled:true
        },
        {
          labelText: "UID2",
          fieldName: "UID2",
          value: "UID2",
          checked: true,
          disabled: true,
        },
      ]
    },{
      fieldType:'radioGroup',
      labelText:'Mobile Devices',
      fieldName:'mobile_devices',
      required:false,
      radioButtons:[
        {
          labelText:'Top Devices',
          fieldName:'top_devices',
          value:'top_ios_android',
          checked:false
        }, {
          labelText:'All Devices',
          fieldName:'all_devices',
          value:'ios_android',
          checked:false
        }
      ]
    },{
      fieldType:'dateRange',
      fieldName:'campaign_range',
      labelText:'Campaign Date Range',
      required:'dateRangeRequired'
    },{
      fieldType:'textField',
      fieldName:'cpm',
      labelText:'CPM (Number)',
      required:true,
      value:'0.00',
      validation:[{
        positiveNumber: 'CPM'
      }]
    },{
      fieldType:'radioGroup',
      labelText:'Planned Usage of this Segment:',
      fieldName:'audience_usage',
      required:true,
      radioButtons:[
        {
          labelText:'Targeting',
          fieldName:'use_type_inclusion',
          value:'I',
          checked:true
        }, {
          labelText:'Suppression',
          fieldName:'use_type_exclusion',
          value:'X',
          checked:false
        }
      ]
    },{
      fieldType:'savableFieldsWrapper',
      layout:[
        {
          fieldType:'searchSelect',
          fieldName:'account_manager_email',
          labelText:'Account Manager Email',
          url:'/v2/secure/attributor/activate-credentials/semcasting_adobe/account_manager_email',
          placeholder:'Select an Account Manager or add a new one',
          required: true,
          namePropName:'value',
          textPropName:'value',
          valuePropName:'id',
          validation:[{
            required:function(value, attr, computed){
              return !parseInt(value);
            }
          },{
            pattern: 'email',
            msg: 'Please enter a valid email'
          }]
        },{
          fieldType:'searchSelect',
          fieldName:'account_id',
          labelText:'Account ID',
          url:'/v2/secure/attributor/activate-credentials/semcasting_adobe/account_id',
          placeholder:'Select an Account ID or add a new one',
          namePropName:'value',
          textPropName:'value',
          valuePropName:'id',
          required:true
        },{
          fieldType:'searchSelect',
          fieldName:'account_code',
          labelText:'Account Code',
          url:'/v2/secure/attributor/activate-credentials/semcasting_adobe/account_code',
          placeholder:'Select an Account Code Name or add a new one',
          namePropName:'value',
          textPropName:'value',
          valuePropName:'id',
          required:true
        },{
          fieldType:'searchSelect',
          fieldName:'partner_name',
          labelText:'Partner Name',
          url:'/v2/secure/attributor/activate-credentials/semcasting_adobe/partner_name',
          placeholder:'Select an Partner Name or add a new one',
          namePropName:'value',
          textPropName:'value',
          valuePropName:'id',
          required:true
        },{
          fieldType:'searchSelect',
          fieldName:'api_key',
          labelText:'API Key',
          url:'/v2/secure/attributor/activate-credentials/semcasting_adobe/api_key',
          placeholder:'Select an API Key or add a new one',
          namePropName:'value',
          textPropName:'value',
          valuePropName:'id',
          required:true
        },{
          fieldType:'checkbox',
          fieldName:'save_credentials',
          labelText:'Save Account Manager Email, Account Code, Partner Name and API Key for future use',
          checked:false
        }
      ]
    },{
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: [
    "account_manager_email",
    "account_id",
    "account_code",
    "partner_name",
    "api_key",
  ],
  formik: {
    initialValues: {
      campaign_range_start: "",
      campaign_range_end: "",
      device_type: ["CLIENT_ID", "UID2"],
      save_credentials: true,
      cpm: "0.00",
      audience_usage:"",
      account_manager_email:"",
      account_id:"",
      account_code:"",
      partner_name:"",
      api_key:"",
      creativeTagIdDropDown: "",
      creativeTagId:"",
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      account_manager_email: Yup.string().required("Account Manager Email is required").email("Please enter a valid email format"),
      account_id: Yup.string().required("Account ID is required"),
      account_code: Yup.string().required("Account Code is required"),
      partner_name: Yup.string().required("Partner Name is required"),
      api_key: Yup.string().required("API Key is required"),
      cpm: Yup.number().min(0).required("CPM must be greater or equal to 0.00"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
    }),
    onSubmit: (values) => {},
  },
  /**
   * formats a file name by trimming the fileName
   * replacing spaces with underscores and
   * removing anything not alphanumeric an underscore or a hyphen
   * Also shortens fileName to 10 chars or less
   * @param fileName {String} fileName to format
   * @returns {string} formatted file name
   * @example input Pixel ID for Income $50K-$100K
   * @example output audience_id_for_income_50K-100K
   */
  //   formatFileName: function (fileName) {
  //     fileName = UDX.Attributor.ActivateFormBase.prototype.formatFileName.apply(
  //       this,
  //       arguments
  //     );
  //     return fileName.substr(0, 10);
  //   },
};
