import { Pie } from "test-react-chartjs-2";
import { Chart as ChartJS, Legend } from "chart.js";
import "./AudienceSupplyPathOptimization.css";
import React from "react";
import * as chartInfo from "../../Pages/ChartData";
import NumberFormat from "react-number-format";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const AudienceSupplyPathOpt = ({ data, chartId, audiencePieRef, supplyPathOptimizationHCOptions }) => {

    ChartJS.defaults.font.size = 17;
    ChartJS.defaults.color = "#000000";

    return (
        <>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split" id={chartId}>
                <div className="card">
                    <div className="text-center">
                        <h4>Audience Supply Path Optimization</h4>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="report-card">
                                <div className="card-body">
                                    <div className="table-responsive business-employees">
                                        <table className="table table-responsive-md table-striped">
                                            <thead>
                                            <tr>
                                                <th className="col-xl-2 col-lg-2 col-md-2">
                                                    <strong>Platform</strong>
                                                </th>
                                                <th className="right col-xl-2 col-lg-2 col-md-2">
                                                    <strong>Match %</strong>
                                                </th>
                                                {/* <th className="col-xl-2 col-lg-2 col-md-2"></th> */}
                                                <th className="right col-xl-2 col-lg-2 col-md-2">
                                                    <strong>Locations</strong>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.rows.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{item["Platform"]}</td>
                                                        <td className="right">
                                                            {item["Match %"]} %
                                                        </td>
                                                        <td className="right">
                                                            <NumberFormat
                                                                thousandsGroupStyle="thousand"
                                                                value={item["Locations"]}
                                                                decimalSeparator="."
                                                                displayType="text"
                                                                type="text"
                                                                thousandSeparator={true}
                                                                allowNegative={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="card">
                                <div className="card-body-businessgraphs">
                                    <Pie
                                        ref={audiencePieRef}
                                        id="audiencePie"
                                        data={chartInfo.audienceSupplyPathOptData}
                                        width={400}
                                        height={400}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            radius: "70%",
                                            spacing: 0,
                                            plugins: {
                                                legend: {
                                                    display: true,
                                                    labels: {
                                                        font: {
                                                            size: 18,
                                                            weight: "bold",
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                    {/*<HighchartsReact*/}
                                    {/*    constructorType={"chart"}*/}
                                    {/*    options={supplyPathOptimizationHCOptions}*/}
                                    {/*    highcharts={Highcharts}*/}
                                    {/*    id="audiencePie"*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AudienceSupplyPathOpt;
