import React from "react";
import "./TrainingGuidePage.css";
import trainingPdf from "../Components/Segmentation/temporaryresources/pdf/AudienceDesigner_TrainingGuide_V2.pdf"


const TrainingGuidePage = (loggedIn) => {


    return (
        <div className={"getting-started-container"}>
            <div className={"pdf-wrapper"}>
                <iframe src={`${trainingPdf}#zoom=100`}
                        className="user-manual-pdf-viewer" allowFullScreen frameBorder="0">
                </iframe>
            </div>
        </div>
    );
};

export default TrainingGuidePage;