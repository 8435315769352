import React, {useEffect, useState} from "react";
import {DropDownList, MultiSelect} from "@progress/kendo-react-dropdowns";
import "./AnnualRevenue.css";

const AnnualRevenue = (props) => {

    const [value, setValue] = useState([]);

    const [annualRevenueRangeOptions, setAnnualRevenueRqangeOptions] = useState([
        {text: "50K or less", value: "A"},
        {text: "50K - 100K", value: "B"},
        {text: "100K - 200K", value: "C"},
        {text: "200K - 500K", value: "D"},
        {text: "500K - 1000K", value: "E"},
        {text: "1M - 5M", value: "F"},
        {text: "5M - 10M", value: "G"},
        {text: "10M+", value: "H"}
    ]);

    // const rangeValues = [
    //     {
    //         "lowValue": "Any",
    //         "highValue": "Any",
    //         "id": 0
    //     },
    //     {
    //         "lowValue": 50,
    //         "highValue": 50,
    //         "id": "A"
    //     },
    //     {
    //         "lowValue": 50,
    //         "highValue": 100,
    //         "id": "B"
    //     },
    //     {
    //         "lowValue": 100,
    //         "highValue": 200,
    //         "id": "C"
    //     },
    //     {
    //         "lowValue": 200,
    //         "highValue": 500,
    //         "id": "D"
    //     },
    //     {
    //         "lowValue": 500,
    //         "highValue": 1000,
    //         "id": "E"
    //     },
    //     {
    //         "lowValue": 1000,
    //         "highValue": 5000,
    //         "id": "F"
    //     },
    //     {
    //         "lowValue": 5000,
    //         "highValue": 10000,
    //         "id": "G"
    //     },
    //     {
    //         "lowValue": 10001,
    //         "highValue": 10001,
    //         "id": "H"
    //     }
    // ];

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.revenue_cd) {
                props.segmentEditInfo.data.segment_design.primaryData.revenue_cd.forEach(rangeValue => {
                    annualRevenueRangeOptions.find(option => {
                        if (option.value === rangeValue) {
                            arr.push(option);
                        }
                        ;
                    })
                })
                setValue(arr);
            }
        }
    }, [props.segmentEditInfo]);

    const onAnnualRevenueRangeChange = (e) => {
        let values = [];
        setValue(e.value);
        e.value.forEach((val) => {
            values.push(val.value);
        });
        props.formik.values.segment_design.primaryData.revenue_cd = values;
    };

    return (
        <>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={annualRevenueRangeOptions}
                    autoClose={false}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    onChange={onAnnualRevenueRangeChange}
                    size="large"
                    value={value}
                    // style={{width: "32rem"}}
                />

            </div>
        </>
    );

}

export default AnnualRevenue;
