import React, {useEffect, useState} from "react";
import { Button } from "@progress/kendo-react-buttons";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Input, TextArea} from "@progress/kendo-react-inputs";
import {loadAccessibleGroups} from "../../../actions/UserActions";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {SEGMENT_COPY_API, SUCCESS_STATUS} from "../../../api/constants";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import "./CopySegment.css";
import {Label} from "@progress/kendo-react-labels";

const CopySegment = (props) => {
    // let copy_segment = props.copySegment.profileFormik.values.selectedSegments;
    // const defaultSegmentName = copy_segment[0].segment_name;
    // const segmentId = copy_segment[0].id;
    // const groupId = copy_segment[0].group_id;
    // const defaultSegmentName = props.copySegment.profileFormik.values.selectedSegments[0].segment_name + " COPY";
    const defaultSegmentName = props.segName + " COPY";
    // const segmentId = props.copySegment.profileFormik.values.selectedSegments[0].id;
    const segmentId = props.segId;
    // const groupId = props.copySegment.profileFormik.values.selectedSegments[0].group_id;
    const groupId = props.groupId;

    const [visible, setVisible] = useState(props.visible);

    const [accessibleGroups, setAccessibleGroups] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);
    const [segmentName, setSegmentName] = useState(defaultSegmentName);
    const [groupValue, setGroupValue] = useState(groupId);
    const [notes, setNotes] = useState("");
    const regex = RegExp(/^[a-zA-Z0-9_\- ]{3,64}$/);
    const REQUIRED_MESSAGE = "Name must be 3-64 chars containing letters, numbers, hyphens, spaces and underscores."
    const NOTES_OPTIONAL_MESSAGE = "(Optional) Use Notes to write a brief description about this segment.";
    const [isNameValid, setIsNameValid] = useState(true);

    useEffect(() => {
        setPageLoader(true)
        loadAccessibleGroups().then((groups) => {
            try {
                setAccessibleGroups(groups)
                setGroupValue(groups[0]);
            } catch (e) {
            } finally {
                setPageLoader(false)
            }
        })
    }, [])

    const copySegment = async () => {
        setPageLoader(true)
        try {
            const payload = {
                segment_name: segmentName,
                group_id: groupValue.id
            }
            if (notes) {
                payload.notes = notes
            }
            let response = await semcastAxios.put(
                SEGMENT_COPY_API + segmentId + '/copy',
                payload,
                {
                    withCredentials: true,
                }
            );
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Segment Copy Request Sent Successfully.");
                props.reloadAfterCopy();
            }
            ;
        } catch (error) {
            showErrorToastMessage("Segment Copy Failed.");
        } finally {
            setPageLoader(false);
            setVisible(false);
        }
    };

    const handleSegmentNameChange = (event) => {
        const value = event.value;
        if (!regex.test(value)) {
            setIsNameValid(false);
        } else {
            setIsNameValid(true);
        }
        setSegmentName(value);
    };

    const handleGroupChange = (event) => {
        setGroupValue(event.target.value);
    };

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    };

    return (
        <>
            <div id="copySegment">
                {visible && (<Dialog
                    title={"Copy Segment"}
                    width={720}
                    closeIcon={false}
                    className={"dialog-standard"}>
                    <LoadOverlay active={pageLoader}>
                        <div>
                            <div>
                                <Label
                                    className={"field-label"}
                                >
                                    New Segment Name
                                    {/*<span className="required-field">*</span>*/}
                                </Label>


                                {/*<label style={{*/}
                                {/*    marginLeft: "2rem",*/}
                                {/*    fontSize: "1.1rem",*/}
                                {/*    paddingBottom: ".5rem",*/}
                                {/*    color: !isNameValid ? "red" : "black"*/}
                                {/*}}>*/}
                                {/*    <b>New Segment Name</b>*/}
                                {/*    <span class="text-danger">*</span>*/}
                                {/*</label>*/}
                            </div>
                            <Input name="newSegmentName"
                                   minLength={3}
                                   maxLength={64}
                                   value={segmentName}
                                   onChange={handleSegmentNameChange}
                                   placeholder={"myNewCopiedSegment..."}
                                   // style={{
                                   //     marginLeft: "2rem",
                                   //     width: "85%",
                                   //     borderColor: !isNameValid ? "red" : ""
                                   // }}
                                    className={"text-field"}
                                   style={{
                                       borderColor: !isNameValid ? "#00424d" : "",
                                   }}
                            />
                            <div style={{display: !isNameValid ? '' : "none"}}>
                            <span name={"msg"}
                                  // style={{
                                  //     marginLeft: "2rem",
                                  //     color: !isNameValid ? "red" : "none"
                                  // }}
                                style={{
                                    color: !isNameValid ? "#00424d" : "",
                                }}
                            >
                                    {REQUIRED_MESSAGE}
                            </span>
                            </div>
                        </div>
                        <div style={{paddingTop: "1.8rem"}}>
                        {/*<div>*/}
                            <div>

                                <Label
                                    className={"field-label"}
                                >
                                    Save to Group
                                    {/*<span className="required-field">*</span>*/}
                                </Label>

                                {/*<label style={{marginLeft: "2rem", fontSize: "1.1rem", paddingBottom: ".5rem"}}>*/}
                                {/*    <b>Save to Group</b>*/}
                                {/*    <span class="text-danger">*</span>*/}
                                {/*</label>*/}
                            </div>
                            <DropDownList
                                // style={{marginLeft: "2rem", width: "85%"}}
                                data={accessibleGroups}
                                textField="group_name"
                                value={groupValue}
                                onChange={handleGroupChange}
                                className={"dropdown-standard"}
                            />
                        </div>
                        <div style={{paddingTop: "1.8rem"}}>
                            <div>
                                <Label
                                    className={"field-label"}
                                >
                                    Notes
                                    {/*<span className="required-field">*</span>*/}
                                </Label>



                                {/*<label style={{marginLeft: "2rem", fontSize: "1.1rem", paddingBottom: ".5rem"}}>*/}
                                {/*    <b>Notes</b>*/}
                                {/*</label>*/}
                            </div>
                            <TextArea
                                value={notes}
                                // style={{marginLeft: "2rem", width: "85%"}}
                                maxLength={5000}
                                placeholder={"Targeting new customers from mailing list..."}
                                rows={5}
                                onChange={handleNotesChange}
                            />
                            {/*<span style={{marginLeft: "2rem", paddingTop: ".8rem"}}>{NOTES_OPTIONAL_MESSAGE}</span>*/}
                            <span className={"hint-standard"}>{NOTES_OPTIONAL_MESSAGE}</span>
                        </div>
                    </LoadOverlay>
                    <DialogActionsBar layout={"end"}>
                        <Button
                            // className="dialog-cancel"
                            className={"button-standard button-white"}
                            onClick={() => {
                                props.cancelSubscriber(undefined)
                            }}>
                            Cancel
                        </Button>
                        <Button
                            // className="dialog-save"
                            className={"button-standard button-submit create-copy"}
                            disabled={!isNameValid}
                            onClick={copySegment}>
                            Create Copy
                        </Button>
                    </DialogActionsBar>
                </Dialog>)}
            </div>
        </>
    );
}

export default CopySegment;
