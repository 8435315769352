import "../../Pages/Dashboard.css";
import React, {useEffect, useState} from "react";
import {loadAccessibleGroups, loadAccessibleRoles, loadPermissions, loadRoleEdit} from "../../../actions/UserActions";
import {useFormik} from "formik";
import * as Yup from "yup";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {ROLE_CREATE_API, ROLE_DELETE_API, ROLE_UPDATE_PATCH_API, SUCCESS_STATUS} from "../../../api/constants";
import LoadOverlay from "../SharedComponents/LoadOverlay";

import "./materialUI.css"
import {ComboBox, DropDownList} from "@progress/kendo-react-dropdowns";
import {Button} from "@progress/kendo-react-buttons";
import "./UsersPage.css"
import {Label} from "@progress/kendo-react-labels";
import {filterBy} from "@progress/kendo-data-query";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";

const RolesPage = () => {

    // const [showUserSection, setShowUserSection] = useState(false);
    const [showUserSection, setShowUserSection] = useState(true);
    const [roleEditSectionData, setRoleEditSectionData] = useState(null);
    const [accessibleGroups, setAccessibleGroups] = useState([]);
    const [accessibleRoles, setAccessibleRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [sectionLoader, setSectionLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [showHideActions, setShowHideActions] = useState({});

    const [showDelete, setShowDelete] = useState(true);
    const [dropdownRoles, setDropdownRoles] = useState([]);
    const [dropdownRolesValueObj, setDropdownRolesValueObj] = useState({index: -1, role: "Select role", roleObj: {}});
    const [dropdownLoading, setDropdownLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState("");

    const onChangeRolesDropdown = (e) => {

        if(e.target.value) {

            // console.log("E TARGET VALUE DROPDOWN SELECT:", e.target.value);

            setDropdownRolesValueObj(e.target.value);

            setShowDelete(true);

            setSelectedRole(e.target.value.role);

            loadEditRoleData(e.target.value.roleObj);
        }
    }

    const roleEditFormik = useFormik({
        initialValues: {
            role_name: '',
            id: '',
            owner_group_id: '',
            rolePermissions: []
        },
        // We used Yup here.
        validationSchema: Yup.object().shape({
            role_name: Yup.string().required('Role Name is required and must be at least 3 characters')
                .min(3, "Role Name is required and must be at least 3 characters"),
            owner_group_id: Yup.string().required('Group Name is required'),
            rolePermissions: Yup.array().of(
                Yup.object().shape({
                    id: Yup.string(),
                })
            )
        }),
        onSubmit: values => {
        },
    });

    async function groupsRolesPermissions() {
        setPageLoader(true);
        setDropdownLoading(true);
        try {
            const values = await Promise.allSettled([loadAccessibleGroups(), loadAccessibleRoles(), loadPermissions()])

            try {
                ((groups) => {
                    setAccessibleGroups(groups)
                })(values[0].value)
            } catch (e) {
            }
            try {
                ((roles) => {

                    let tempArr = [];

                    if(roles.length) {
                        roles.forEach((role, index) => {
                            tempArr.push({index: index, role: role.role_name, roleObj: role})
                        })
                    }

                    // console.log("value of temp arr", tempArr);
                    // console.log("Roles length:", roles.length);
                    // console.log("Roles from RolesPage:", roles);
                    // console.log("Roles from RolesPage first role:", roles[0]);

                    if(dropdownRolesValueObj.role === "") {
                        setDropdownRolesValueObj({index: -1, role: "Select role", roleObj: {}});
                    }

                    setDropdownRoles(tempArr);

                    setAccessibleRoles(roles);

                    setDropdownLoading(false);
                })(values[1].value)
            } catch (e) {
            }
            try {
                await (async permissionsList => {
                    await setPermissions(permissionsList)
                    await resetRolesPermissionsForm(permissionsList)
                })(values[2].value)
            } catch (e) {
            }
        } catch (e) {
            console.log(e)
        } finally {
            setPageLoader(false)
        }
    };

    useEffect( () => {
        console.log("Inside the single use useEffect");
        defaultValuesForDropdowns();
        groupsRolesPermissions().then(r => "");
    }, []);

    const resetRolesPermissionsForm = async (permissionList = permissions) => {
        let permissions = permissionList
        if (permissions) {
            let rolePermissions = permissions.map(role => {
                return {...role}
            });
            await roleEditFormik.setValues({
                role_name: '',
                id: '',
                owner_group_id: '',
                rolePermissions
            })
        }
    };

    const saveRole = async () => {
        try {
            await roleEditFormik.submitForm()
            if (roleEditFormik.isValid) {
                setSectionLoader(true)
                // role data updating
                const permissions = {}
                if (roleEditFormik?.values?.rolePermissions) {
                    roleEditFormik?.values?.rolePermissions.forEach((p) => {
                        permissions[p.id] = p?.checked ? p.checked : false;
                    })
                }
                let roleUpdatePayload = {
                    role_name: roleEditFormik.values.role_name,
                    owner_group_id: roleEditFormik.values.owner_group_id,
                    id: roleEditFormik.values.id,
                    permissions
                }
                let response;
                if (roleEditFormik?.values?.id) {
                    //role update
                    response = await semcastAxios.patch(`${ROLE_UPDATE_PATCH_API}${roleEditFormik.values.id}`, roleUpdatePayload, {
                        withCredentials: true,
                    });
                } else {
                    response = await semcastAxios.post(ROLE_CREATE_API, roleUpdatePayload, {
                        withCredentials: true,
                    });
                    let roleId = response?.data?.data?.id;
                    await roleEditFormik.setFieldValue('id', roleId);
                    setShowUserSection(false);
                }

                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage(roleEditFormik.values.role_name + " has been updated.");
                    loadAccessibleRoles().then(roles => setAccessibleRoles(roles));
                    groupsRolesPermissions().then(r => "");
                }
            }
        } catch (error) {
            // console.log(error)
            showErrorToastMessage(roleEditFormik.values.role_name + " has not updated.")
        } finally {
            setSectionLoader(false)
        }
    }
    const loadEditRoleData = async (role) => {
        if (role && role.resource_group_id !== 0) { // no edi feature, edit disabled at UI level, here we are doing Extra check
            setSectionLoader(true)
            //pre-populate data
            await setRoleEditFormikData(role)
            let roleData = await loadRoleEdit(role.id);
            try {
                if (roleData) {
                    const rolePermissions = getRolePermissionsFromRoleData(roleData)
                    await roleEditFormik.setValues({
                        owner_group_id: roleData.owner_group_id,
                        role_name: roleData.role_name,
                        id: role.id,
                        rolePermissions
                    })
                }
            } catch (e) {
                console.log("error occurred while while pre populating existing roles & permissions of a role in role edit ", e)
            } finally {
                setSectionLoader(false)
            }
        }
    }

    const getRolePermissionsFromRoleData = (roleData) => {
        try {
            let rolePermissionsDict = roleData.permissions.reduce((a, x) => ({...a, [x.id]: x}), {})
            let rolePermissions = [...roleEditFormik.values.rolePermissions].map((obj) => ({
                ...obj,
                checked: !!rolePermissionsDict[obj.id]
            }))
            return rolePermissions
        } catch (e) {
            console.log("Exception in getRolePermissionsFromRoleData :: ", e)
        }
    }
    const populatePermissionsOfSelectedRole = async (selectedRole) => {
        try {
            setSectionLoader(true)
            //pre-populate data
            let roleData = await loadRoleEdit(selectedRole.id);
            if (roleData) {
                const rolePermissions = getRolePermissionsFromRoleData(roleData)
                await roleEditFormik.setValues({
                    ...roleEditFormik.values,  rolePermissions
                })
            }
        } catch (e) {
            console.log("Exception in populatePermissionsOfSelectedRole occurred while while pre populating existing roles & permissions of a role in role edit :: " + e)
        } finally {
            setSectionLoader(false)
        }
    }


    const deleteRole = async (e, role) => {
        try {
            setPageLoader(true)
            e.stopPropagation()
            if (role) {
                let response = await semcastAxios.delete(ROLE_DELETE_API + role.id, {
                    withCredentials: true,
                });
                if (response.data.status === SUCCESS_STATUS) {
                    let roleIndex = accessibleRoles.findIndex(r => r.id === role.id);
                    if (roleIndex > -1) {
                        accessibleRoles.splice(roleIndex, 1)
                        setAccessibleRoles(accessibleRoles)
                    }
                    showSuccessToastMessage(`${role.role_name} has been Deleted`)
                }
            }
        } catch (error) {
            // console.log(error)

            showErrorToastMessage(`${role.role_name} has not Deleted`)
        } finally {
            setPageLoader(false);
        }
    }
    const setRoleEditFormikData = async (role) => {
        setShowUserSection(true);
        if (role) {
            setRoleEditSectionData(role);
            await roleEditFormik.setValues({...roleEditFormik.values, ...role});
        } else {
            setRoleEditSectionData({})
            await resetRolesPermissionsForm();
            defaultValuesForDropdowns();
        }
    }
    const rolesList = accessibleRoles?.length > 0
        && accessibleRoles.map((role, i) => {
            return (
                <li className={`dz-chat-user nav-link ${role.id === roleEditSectionData?.id ? "active" : null}`}
                    key={i}
                    onClick={() => loadEditRoleData(role)}>
                    <div className="d-flex bd-highlight" onMouseOver={(e) => {
                        setShowHideActions({[role?.id]: true})
                    }} onMouseLeave={() => {
                        setShowHideActions({[role?.id]: false})
                    }}>
                        <div className="user_info">
                            <span>{role?.role_name}</span>
                        </div>
                        {(showHideActions[role?.id]) && <div className="actions">
                            {
                                role.resource_group_id !== 0 ?
                                    <i title="Delete Role" className="fa fa-trash" aria-hidden="true"
                                       onClick={(e) => deleteRole(e, role)}></i> :
                                    <i title="Locked" className="fa fa-lock disabled" aria-hidden="true"></i>
                            }

                        </div>
                        }
                    </div>
                </li>
            )
        }, this);

    const groupsList = accessibleGroups?.length > 0
        && accessibleGroups.map((item, i) => {
            return (
                <option key={i} value={item.id}>{item.group_name}</option>
            )
        }, this);

    const handleRoleCheckBoxChange = (e) => {
        try {
            const {checked, name} = e.target
            roleEditFormik.values.rolePermissions[parseInt(name)].checked = checked
            roleEditFormik.setFieldValue('rolePermissions', [...roleEditFormik.values['rolePermissions']])
        } catch (e) {
        }
    }
    const permissionsList = roleEditFormik.values?.rolePermissions?.length > 0
        && roleEditFormik.values?.rolePermissions.map((item, i) => {
            return (
                <div className="col-3">
                    <div className="form-group">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name={i}
                                    checked={roleEditFormik.values.rolePermissions[i].checked}
                                    value={roleEditFormik.values.rolePermissions[i].permission_value}
                                    onChange={(e) => handleRoleCheckBoxChange(e)
                                    }
                                />
                                {roleEditFormik.values.rolePermissions[i].permission_name}
                            </label>
                        </div>
                    </div>
                </div>
            )
        }, this);


    const defaultValuesForDropdowns = (setDefaultGroup = true) => {
        // console.log("accessibleGroups:", accessibleGroups);
        try {
            if (setDefaultGroup && accessibleGroups?.length)
                roleEditFormik.setFieldValue('owner_group_id', '' + accessibleGroups[0].id)
            else {
                console.log("must not have accessibleGroups. accessibleGroups?.length:", accessibleGroups?.length);
            }

        } catch (e) {
        }
    }

    useEffect(() => {
        setComboBoxData(dropdownRoles.slice());

        // console.log("dropdownrolesvalueobj.roleObj", dropdownRolesValueObj?.roleObj.role_name);
    }, [dropdownRoles])


    const filterData = filter => {
        const data = dropdownRoles.slice();
        return filterBy(data, filter);
    };

    const filterChange = event => {
        setComboBoxData(filterData(event.filter));
    };

    const [comboBoxData, setComboBoxData] = useState(dropdownRoles.slice());


    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

    const deleteConfirmed = (e) => {
        setShowDeleteConfirmationDialog(false);
        deleteRole(e, dropdownRolesValueObj.roleObj);
    }

    const deleteDenied = () => {
        setShowDeleteConfirmationDialog(false);
    }


    return (
        <div>
            <LoadOverlay active={dropdownLoading} height={50} width={50}>

            {showDeleteConfirmationDialog && (
                <div className={"admin-users-delete-dialog-container"}>
                    <Dialog title={"Delete Role"} onClose={deleteDenied} className={"admin-users-delete-dialog dialog-standard"}>
                        <p className={"admin-users-delete-dialog-message-text"}>Are you sure you want to delete role <strong>{dropdownRolesValueObj?.roleObj.role_name}</strong>?</p>
                        <DialogActionsBar>
                            <Button className="button-standard button-white" onClick={deleteDenied}>No</Button>
                            <Button className="button-standard button-submit" onClick={deleteConfirmed}>Yes</Button>
                        </DialogActionsBar>
                    </Dialog>
                </div>
            )}

            <div className={"userHeaderDiv groupHeaderDiv section-card section-card-inner-margins"}>


                <div className={"userDropdownDiv groupDropdownDiv"}>
                    {/*<h4 className={"header-secondary"}>Roles</h4>*/}
                    {/*<LoadOverlay active={dropdownLoading} height={50} width={50}>*/}
                    {/*<DropDownList className={"dropdown-standard"}  textField={"role"} value={dropdownRolesValueObj}*/}
                    {/*              onChange={onChangeRolesDropdown} data={dropdownRoles} />*/}
                    {/*</LoadOverlay>*/}

                    <ComboBox className={"combobox-standard"} textField={"role"} data={comboBoxData} placeholder={"Search or Select Role"}
                              filterable={true} onFilterChange={filterChange} onChange={onChangeRolesDropdown}>
                    </ComboBox>
                </div>


                {/*<div className={"userManagerHeaderDiv"}>*/}
                {/*    <h3 className={"userManagerText header-primary"}>Role Manager</h3>*/}
                {/*    <legend className={'k-form-legend spacingLine'}></legend>*/}

                {/*    <h6 className={"userManagerSecondaryText admin-permissions-below-manager-text"}>Select a role to edit or create a new role</h6>*/}

                {/*    /!*<p>Select a user to edit or create a new user*!/*/}
                {/*    /!*    /!*<a href="javascript:void(0)" className="create-new"*!/*!/*/}
                {/*    /!*    /!*   onClick={() => setUserEditFormikData(null)}> Create*!/*!/*/}
                {/*    /!*    /!*    a New User</a>*!/*!/*/}
                {/*    /!*</p>*!/*/}
                {/*</div>*/}

                <div className={"newUserButtonDiv"}>
                    <Button className={"button-standard button-submit"} type={"button"} onClick={async () => {
                        setDropdownRolesValueObj({index: -1, role: "Select role", roleObj: {}});
                        setSelectedRole("");
                        setShowDelete(false);
                        await setRoleEditFormikData(null);
                    }}>
                        {/*<Button className={"newUserButton"} type={"button"} onClick={() => null}>*/}
                        New Role
                    </Button>
                </div>

            </div>
            </LoadOverlay>


            {showUserSection && <div
                className="tab-pane rolesPageEditSection"
                id="ostrich"
                role="tabpanel"
                aria-labelledby="ostrich-tab">
                <div className="">
                    <LoadOverlay active={pageLoader}>
                        <div className="section-card">
                            <div className="row">
                                {/*<div className="col-md-3">*/}
                                {/*    <button*/}
                                {/*        type="button"*/}
                                {/*        className="mb-3 btn btn-rounded btn-primary bnt-block  custom-btn"*/}
                                {/*        onClick={async () => {*/}
                                {/*            await setRoleEditFormikData(null)*/}
                                {/*        }}>*/}
                                {/*        New Role*/}
                                {/*    </button>*/}
                                {/*    <h4>Roles</h4>*/}
                                {/*    <ul className="contacts">*/}
                                {/*        {rolesList}*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                                {/*{!showUserSection &&*/}
                                {/*    <div className="col-md-9 p-0">*/}
                                {/*        <h3>Role Manager</h3>*/}
                                {/*        <p>Click a Rol in the left hand column to edit or*/}
                                {/*            <a href="javascript:void(0)" className="create-new"*/}
                                {/*               onClick={() => setRoleEditFormikData(null)}> Create*/}
                                {/*                a New Role</a></p>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                {showUserSection &&
                                    <div className="">
                                        {/*<h4>Create New Role</h4>*/}

                                        {/*<h3 className={"editCreateGroupTitle primary-header-text"}>{selectedGroupName.length ? selectedGroupName : "Create New Group"}</h3>*/}
                                        <label className={"field-label-large"}>{selectedRole.length ? selectedRole : "CREATE NEW ROLE"}</label>
                                        <legend className={'k-form-legend spacingLine'}></legend>

                                        <div className="col-md-12 mt-3 p-0">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <Label className={"field-label"}>Role Name</Label>
                                                        <input
                                                            type="text"
                                                            className="text-field text-field-formik"
                                                            // placeholder="Role Name"
                                                            name="role_name"
                                                            value={roleEditFormik.values.role_name}
                                                            onBlur={roleEditFormik.handleBlur}
                                                            onChange={roleEditFormik.handleChange}
                                                        />
                                                        {roleEditFormik.touched.role_name && roleEditFormik.errors.role_name &&
                                                            <span
                                                                className="validation-error">{roleEditFormik.errors.role_name}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <Label className={"field-label"}>Group Name</Label>
                                                        <select
                                                            className="form-control form-control-custom form-select select-dropdown"
                                                            name="owner_group_id"
                                                            value={roleEditFormik.values.owner_group_id}
                                                            onBlur={roleEditFormik.handleBlur}
                                                            onChange={roleEditFormik.handleChange}>
                                                            {groupsList}
                                                        </select>
                                                        {roleEditFormik.touched.owner_group_id && roleEditFormik.errors.owner_group_id &&
                                                            <span
                                                                className="validation-error">{roleEditFormik.errors.owner_group_id}</span>}
                                                    </div>
                                                </div>
                                                <LoadOverlay active={sectionLoader}>
                                                    <>
                                                        <div className="col-12 permissionsCheckboxesDiv">
                                                            <div className={"permissions-checkboxes-header-div"}>
                                                                <label className={"field-label-large"}>PERMISSIONS</label>
                                                                <div className="float-right role-pre-populate-section">
                                                                    <button type="button"
                                                                            className="btn btn-link dropdown-toggle"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false">
                                                                        Pre-populate Permissions with Role
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        {accessibleRoles?.length > 0
                                                                            && accessibleRoles.map((role, i) => {
                                                                                return (
                                                                                    <li key={role?.id}><a className="dropdown-item"
                                                                                                          href
                                                                                                          onClick={() => populatePermissionsOfSelectedRole(role)}>{role?.role_name}</a>
                                                                                    </li>
                                                                                )
                                                                            }, this)}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                {permissionsList}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 float-right">
                                                            {showDelete && <button
                                                                type="button"
                                                                className="button-standard button-red"
                                                                onClick={(e) => {
                                                                    setShowDeleteConfirmationDialog(true);
                                                                    // await deleteRole(e, dropdownRolesValueObj.roleObj);
                                                                    }
                                                                }
                                                            >
                                                                Delete
                                                            </button>}
                                                            <button
                                                                type="button"
                                                                className="button-white button-standard"
                                                                onClick={() => {
                                                                    setSelectedRole("");
                                                                    setShowDelete(true);
                                                                    setDropdownRolesValueObj({index: -1, role: "Select role", roleObj: {}});
                                                                    setShowUserSection(false);
                                                            }}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="button-standard button-submit"
                                                                onClick={saveRole}
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </>
                                                </LoadOverlay>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </LoadOverlay>
                </div>

            </div>}
        </div>
    );
}

export default RolesPage;
