import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const yahooConfig = {
  ...COMPANY_BASE_PROPERTIES,
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With Yahoo!",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "yahoo",

  /**
   * HTML that explains how to use the form
   */
  instructions:
    "Please provide the Start/End dates and your MDM ID. These fields must be completed to push an audience to your seat. You can optionally select Hashed Emails to be included with Device IDs as part of your segment."
    +"You have selected a platform that automatically reports usage for targeting."
    +"You will be billed for data usage by the platform unless the segment is applied for suppression purposes."
    +"Suppression segments are billed by Semcasting directly based on the discounted deployed record formula."
    +"Activating this audience means you agree to this billing method.",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "yahoo",
    text: "Yahoo!",
    img: "yahoo-logo.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink: "",

  layout: [
    {
      fieldType: "radioGroup",
      labelText: "Mobile Devices",
      fieldName: "mobile_devices",
      required: true,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: true,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },
      ],
    },
    {
      fieldType: "checkboxGroup",
      labelText: "Emails to Activate",
      fieldName: "device_type",
      required: false,
      checkboxes: [
        {
          labelText: "Hashed Emails",
          fieldName: "hashed_emails",
          value: "hashed_emails_sha256",
          checked: false,
        },
      ],
    },
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelect",
          fieldName: "mdm_id",
          labelText: "MDM ID",
          url: "/v2/secure/attributor/activate-credentials/yahoo/mdm_id",
          placeholder: "Select an MDM ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType: 'checkbox',
          fieldName: "save_credentials",
          labelText: 'Save MDM ID for future use',
          checked: false
        },
      ],
    },
    {
      fieldType: "pricing",
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },
    {
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    }
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["mdm_id"],

  formik: {
    initialValues: {
      mobile_devices: "ios_android",
      campaign_range_start: "",
      campaign_range_end: "",
      device_type: ["hashed_emails_sha256"],
      save_credentials: false,
      audience_usage:"",
      creativeTagIdDropDown:"",
      creativeTagId:"",
      mdm_id:""
    },
    // We used Yup here for input format validation.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      mdm_id: Yup.string()
          .required("MDM ID is required")
          .matches(/^[0-9]+$/, "MDM ID should only contain digits"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown:Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required"),
    }),
    onSubmit: (values) => {},
  },
};
