import React, {useEffect, useState} from "react";
import "./EmployeesRange.css";
import {MultiSelect} from "@progress/kendo-react-dropdowns";

const EmployeesRange = (props) => {

    const [value, setValue] = useState([]);

    const [employeesRangeOptions, setEmployeesRangeOptions] = useState([
        {text: "1 - 4", value: "A"},
        {text: "5 - 9", value: "B"},
        {text: "10 - 49", value: "C"},
        {text: "50 - 99", value: "D"},
        {text: "100 - 999", value: "E"},
        {text: "1000 - 9999", value: "F"},
        {text: "10000 - 99999", value: "G"},
        {text: "99999+", value: "H"}
    ]);

    useEffect(() => {
        let arr = [];
        props.audienceState.employees_total_cd.forEach(rangeValue => {
            employeesRangeOptions.find(option => {
                if (option.value === rangeValue) {
                    arr.push(option);
                }
                ;
            })
        })
        setValue(arr);
    }, [props.audienceState]);

    const onEmployeesRangeChange = (e) => {
        let values = [];
        setValue(e.value);
        e.value.forEach((val) => {
            values.push(val.value);
        });
        // props.audienceState.employees_total_cd = values;
        props.handleAudienceState(prevState => {
            return {...prevState, employees_total_cd: values}}
        );
    };

    // const lowValues = [
    //     {
    //         "value": "Any",
    //         "text": "Any",
    //         "id": 0
    //     },
    //     {
    //         "value": 1,
    //         "text": "1",
    //         "id": "A"
    //     },
    //     {
    //         "value": 5,
    //         "text": "5",
    //         "id": "B"
    //     },
    //     {
    //         "value": 10,
    //         "text": "10",
    //         "id": "C"
    //     },
    //     {
    //         "value": 50,
    //         "text": "50",
    //         "id": "D"
    //     },
    //     {
    //         "value": 100,
    //         "text": "100",
    //         "id": "E"
    //     },
    //     {
    //         "value": 1000,
    //         "text": "1,000",
    //         "id": "F"
    //     },
    //     {
    //         "value": 10000,
    //         "text": "10,000",
    //         "id": "G"
    //     }
    // ];
    //
    // const highValues = [
    //     {
    //         "value": "Any",
    //         "text": "Any",
    //         "id": 0
    //     },
    //     {
    //         "value": 4,
    //         "text": "4",
    //         "id": "A"
    //     },
    //     {
    //         "value": 9,
    //         "text": "9",
    //         "id": "B"
    //     },
    //     {
    //         "value": 49,
    //         "text": "49",
    //         "id": "C"
    //     },
    //     {
    //         "value": 99,
    //         "text": "99",
    //         "id": "D"
    //     },
    //     {
    //         "value": 999,
    //         "text": "999",
    //         "id": "E"
    //     },
    //     {
    //         "value": 9999,
    //         "text": "9,999",
    //         "id": "F"
    //     },
    //     {
    //         "value": 99999,
    //         "text": "99,999",
    //         "id": "G"
    //     },
    //     {
    //         "value": 9999999,
    //         "text": "99,999+",
    //         "id": "H"
    //     }
    // ]

    return (
        <>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={employeesRangeOptions}
                    autoClose={false}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    onChange={onEmployeesRangeChange}
                    size="large"
                    value={value}
                    // style={{width: "32rem"}}
                />

            </div>
        </>
    );
}

export default EmployeesRange;
