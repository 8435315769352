import React, {useEffect, useState} from "react";
import {
    ALL_ACCOUNT_DETAILS,
    PRICING,
    ADMIN_ACCOUNT_PERMISSIONS, SEMCASTING_TILES,
} from "../../../api/constants";
import {noDataIndication, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {semcastAxios} from "../../../Utils/Common";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import cellEditFactory, {Type} from "react-bootstrap-table2-editor";
import {UserContext} from "../../../contexts/UserContext";
import {Alert} from "react-bootstrap";
import LoadOverlay from "../../Components/SharedComponents/LoadOverlay";
import "./AccountTabStyles.css";

//totalChecked is used for logging
let totalChecked;

function AccountPricing2(props) {
    console.log("AccountPricing2 props =", props);
    const {id, setShowPricing} = props;

    const {user, setUser} = React.useContext(UserContext);
    const [accounts, setAccounts] = useState([]);
    const [accountsIndex, setAccountsIndex] = useState("");
    const [pricing, setPricing] = useState([]);
    const [permission, setPermission] = useState([]);
    const [pricingNew, setPricingNew] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [alert, setAlert] = useState(true);
    const [semcastingtiles, setSemcastingtiles] = useState([]);

    useEffect(() => {
        if (id) {
            getDetails(id);
            setAccountsIndex(id);
        }else {
            setShowPricing(false)
        }
    }, [id]);

    const changeChecked = (row, e) => {
        // Toggle checked state
        row.checked = !row.checked;

        let isAllChecked = true;
        let isAllUnchecked = true;
        console.log("changeCheckedsemcastingtiles=", semcastingtiles);

        pricingNew.forEach(item => {
            if (item.company === 'attribution' || semcastingtiles.includes(item.company)) {
                return;
            }

            if (item.checked) {
                isAllUnchecked = false;
            } else {
                isAllChecked = false;
            }
        });

        setAllChecked(isAllChecked);
        setAlert(isAllUnchecked);
    };

    const checkboxFormatter = (data, row) => {
        if (accountsIndex === "0") {
            return (
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={true}
                    disabled
                />
            );
        }
        console.log("checkboxFormatterSemcastingtiles=", semcastingtiles);
        if (row.company === "attribution" || semcastingtiles.includes(row.company)) {
            console.log("ReturningNullCompany=", row.company);
            return null;
        }

        return (
            <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={row.checked}
                onChange={(e) => changeChecked(row, e)}
            />
        );
    };

    const onPricingStructureChange = (row, e, rowIndex) => {
        let updatedList = pricingNew.map(item => {
            if (item.company === row.company){
                return {...item, pricing_structure: e.target.value};
            }
            return item;
        });
        row.pricing_structure = e.target.value;
        setPricingNew(updatedList);
    }
    const pricingStructureFormatter = (data, row, rowIndex) => {

        return (
            <div className="form-group">
                <select
                    defaultValue={data}
                    onChange={(e) => onPricingStructureChange(row, e, rowIndex)}
                    className="form-control form-control-custom form-select"
                >
                    <option value="CPM">CPM</option>
                    <option value="DEPLOYED">Deployed</option>
                    <option value="PCT-MEDIA-UNDER-CAP">Percent of Media under Cap</option>
                </select>
            </div>
        );
    };
    const companyNameFormatter = (data, _) =>
        data === "ttd" ? "TradeDesk3rdParty" : data;
    const priceFormatter = (data, row) => {
        if (row.company === "xandr" || row.company === "semcasting_xandr")
            return (
                <>
                <select
                    defaultValue={data}
                    onChange={(e) => {
                        row.price = e.target.value; row.price_code = e.target.selectedOptions[0].id
                    }}
                    id={"pricing-cell"}
                    className="form-control form-control-custom form-select"
                >
                    <option value={0.0} id={"9516"}>Custom Segment - $0.00</option>
                    <option value={0.5} id={"9432"}>Custom Segment - $0.50</option>
                    <option value={0.55} id={"9514"}>Custom Segment - $0.55</option>
                    <option value={0.65} id={"9515"}>Custom Segment - $0.65</option>
                    <option value={0.75} id={"9433"}>Custom Segment - $0.75</option>
                    <option value={1} id={"9434"}>Custom Segment - $1.00</option>
                    <option value={1.25} id={"9435"}>Custom Segment - $1.25</option>
                    <option value={1.5} id={"9436"}>Custom Segment - $1.50</option>
                    <option value={1.75} id={"9437"}>Custom Segment - $1.75</option>
                </select>
                </>
            );
        return (
            <div className="pricing-cell">
                <div className="form-control" id={"pricing-cell"}>{data}</div>
            </div>
        );
    };
    const defaultMediaCostRateFormatter = (data, row) => {
        //Trick to hide this column when pricing_structure is not PCT-MEDIA-UNDER-CAP, used with onChange function below
        if(row.pricing_structure !== "PCT-MEDIA-UNDER-CAP"){
            return (
                <div className="default_media_cost_rate">
                    <div className="form-control"></div>
                </div>
            );
        }
        return (
            <div className="default_media_cost_rate">
                <div className="form-control">{data}</div>
            </div>
        );
    };
    const defaultCpmMinCapFormatter = (data, row) => {
        //Trick to hide this column when pricing_structure is not PCT-MEDIA-UNDER-CAP, used with onChange function below
        if(row.pricing_structure !== "PCT-MEDIA-UNDER-CAP"){
            return (
                <div className="default_cpm_min_cap">
                    <div className="form-control"></div>
                </div>
            );
        }
        return (
            <div className="default_cpm_min_cap">
                <div className="form-control">{data}</div>
            </div>
        );
    };
    const defaultCpmFormatter = (data, row) => {
        //Trick to hide this column when pricing_structure is not PCT-MEDIA-UNDER-CAP, used with onChange function below
        if(row.pricing_structure !== "PCT-MEDIA-UNDER-CAP"){
            return (
                <div className="default_cpm">
                    <div className="form-control"></div>
                </div>
            );
        }
        return (
            <div className="default_cpm">
                <div className="form-control">{data}</div>
            </div>
        );
    };



    const columns = [
        {
            dataField: "company",
            text: "Company",
            sort: true,
            formatter: companyNameFormatter,
            editable: false,
        },
        {
            dataField: "checked",
            text: (
                <>
                    {accountsIndex === "0" ? (
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={true}
                            disabled
                        />
                    ) : (
                        <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={() => {
                                if (allChecked) {
                                    let newPrice = pricingNew;
                                    newPrice.forEach((obj) => (obj.checked = false));
                                    setPricingNew(newPrice);
                                    totalChecked = 0;
                                    setAlert(true);
                                } else {
                                    let newPrice = pricingNew;
                                    newPrice.forEach((obj) => (obj.checked = true));
                                    // console.log("onChangeNewPrice=", newPrice);
                                    setPricingNew(newPrice);
                                    totalChecked = newPrice.length - 1;
                                    setAlert(false);
                                }
                                setAllChecked(!allChecked);
                            }}
                            checked={allChecked}
                        />
                    )}
                    &nbsp; Is Visible
                </>
            ),
            formatter: checkboxFormatter,
            editable: false,
        },
        {
            dataField: "pricing_structure",
            text: "CPM / Deployed / Percent of Media under Cap",
            formatter: pricingStructureFormatter,
            editable: false,
        },
        {
            dataField: "price",
            text: "Pricing",
            formatter: priceFormatter,
            editable: (content, row, rowIndex, columnIndex) =>
                row.company !== "xandr" && row.company !== "semcasting_xandr",
            validator: (value, _, __) => {
                if (isNaN(value)) {
                    return {
                        valid: false,
                        message: "Price should be numeric",
                    };
                }
                return true;
            },
        },
        {
            dataField: "default_media_cost_rate",
            text: "Default Media Cost Rate",
            formatter: defaultMediaCostRateFormatter,
            editable: (content, row) =>
                row.pricing_structure === "PCT-MEDIA-UNDER-CAP",
            validator: (value, _, __) => {
                if (isNaN(value)) {
                    return {
                        valid: false,
                        message: "Price should be numeric",
                    };
                }
                return true;
            },
        },
        {
            dataField: "default_cpm_min_cap",
            text: "Default CPM Min Cap",
            formatter: defaultCpmMinCapFormatter,
            editable: (content, row) =>
                row.pricing_structure === "PCT-MEDIA-UNDER-CAP",
            validator: (value, _, __) => {
                if (isNaN(value)) {
                    return {
                        valid: false,
                        message: "Price should be numeric",
                    };
                }
                return true;
            },
        },
        {
            dataField: "default_cpm",
            text: "Default CPM",
            formatter: defaultCpmFormatter,
            editable: (content, row) =>
                row.pricing_structure === "PCT-MEDIA-UNDER-CAP",
            validator: (value, _, __) => {
                if (isNaN(value)) {
                    return {
                        valid: false,
                        message: "Price should be numeric",
                    };
                }
                return true;
            },
        },
    ];

    const getDetails = async (index, val = 1) => {
        let permissions = null;
        if (val) {
            setAlert(false);
        }
        setLoading(true);

        let res = await semcastAxios.get(
            ADMIN_ACCOUNT_PERMISSIONS + id,
            {
                withCredentials: true,
            }
        ).catch((err) => {
            showErrorToastMessage(err, 10);
            setLoading(false);
            return null;  // Returning null to clearly signify a failed request
        });

        // Check if res is null which means the request failed
        if (!res) {
            showErrorToastMessage("Invalid Response", 10);
            setLoading(false);
            return null;
        }

        // Further checking if res.data or res.data.data are undefined
        console.log("res=", res);
        console.log("res.data=", res.data);

        if (!res.data) {
            console.log("Invalid or missing data in response");
            showErrorToastMessage("Missing data in response", 10);
            setLoading(false);
            return null;
        }

        let responseData = res.data;
        // console.log("Response data:", responseData);
        console.log("responseData.data:", responseData.data);

        if (!responseData || !responseData.data || !Array.isArray(responseData.data)) {
            console.log("Invalid or missing 'data' key in response data, or 'data' is not an array");
            setLoading(false);
            return null; // Stop execution if data key is missing or not formatted correctly
        }

        // Successfully parsed and validated response data
        //console.log("Validated response data:", responseData);

        // Extract the first element if the array has at least one element
        if (responseData.data.length > 0) {
            let firstPart = responseData.data[0];
            console.log("First part of the data:", firstPart);
            // Continue to use firstPart as needed
        }

        // Process the first element if present
        if (responseData.data.length > 0) {
            let permission_name = responseData.data[0].permission_name;
            permissions = responseData.data[0].permission_value;
            console.log("permissions=", permissions);
            setPermission(permissions);
            if (permission_name === "semcastingTiles") {
                setSemcastingtiles(permissions);
            }
        } else {
            console.log("No data available in responseData.data");
        }

        // Check for the second element
        if (responseData.data.length > 1) {
            const secondElement = responseData.data[1];
            console.log("secondElement=", secondElement);
            if (secondElement.permission_name === SEMCASTING_TILES) {
                //&& typeof secondElement.permission_value === 'string') {
                //const secondElementValues = secondElement.permission_value.split(',').map(item => item.trim());
                const secondElementValues = secondElement.permission_value;
                console.log("secondElementValues=", secondElementValues);
                setSemcastingtiles(secondElementValues);
            }
        }
        semcastAxios
            .get(PRICING + id, {withCredentials: true})
            .then((res) => {
                // console.log(accounts[index].email, " AccountPriceDATA=", res.data.data );
                let newPrice = res.data.data;

                // console.log(accounts[index].email, " AccountPricePermissions=", permissions);
                newPrice.forEach((obj, ind) => {
                    obj.__id = ind;
                    obj.checked = true;
                    if (obj.pricing_structure === "") obj.pricing_structure = "CPM";
                });
                // console.log("newPriceAfterAdjustingPricingStructure=", newPrice);


                let localAllChecked = true;
                let localAlert = true;
                let localTotalChecked = 0;
                // if (permissions && permissions.length !== 0) {
                if (permissions) {
                    newPrice.forEach((price, index) => {
                        if (permissions.includes(price.company)) {
                            price.checked = false;
                            localAllChecked = false;
                        } else {
                            price.checked = true;
                            localAlert = false;

                            if (price.company !== 'attribution' || semcastingtiles.includes(price.company)) {
                                localTotalChecked++;
                            } else {
                                console.log("SkippingCompany=", price.company);
                            }
                        }
                    }
                    );
                    //commented out - this logic is streamlined
                    //totalChecked = 20 - permissions.length;
                    //totalChecked = newPrice.length -  permissions.length;
                } else {
                    localAlert = false;
                }
                //setTotalChecked(localTotalChecked);
                totalChecked = localTotalChecked;
                //commented out - this logic is streamlined
                // else {
                //     totalChecked = newPrice.length
                // }

                //this piece was already commented
                // const uniqueArray = (a) =>
                //   [...new Set(a.map((o) => JSON.stringify(o)))].map((s) =>
                //     JSON.parse(s)
                //   );
                // newPrice = uniqueArray(newPrice); //[{id:1},{id:2}]
                //console.log("unique Array", newPrice);
                 setPricingNew(newPrice);
                 setLoading(false);
                //commented out - this logic is streamlined
                //if (totalChecked === 20) setAllChecked(true);
                // if (totalChecked === newPrice.length) setAllChecked(true);
                // if (permissions.length == 0) setAllChecked(true);
                // if (totalChecked === 0) setAlert(true);
                // console.log("AfterInitTotalChecked=", totalChecked);
                setAllChecked(localAllChecked);
                setAlert(localAlert);

            }).catch((err) => {
                showErrorToastMessage(err, 10);
                setLoading(false)
            });
    };

    const onSubmit = async (e) => {
        // console.log("TotlanCHecked", totalChecked);
        e.preventDefault();
        setLoading(true);
        let permissions = {hidden_onboarding_companies: []};
        pricingNew.forEach((obj) => {
            if (obj.checked === false)
                permissions.hidden_onboarding_companies.push(obj.company);
        });
        semcastAxios
            .post(
                ADMIN_ACCOUNT_PERMISSIONS + id,
                permissions,
                {
                    withCredentials: true,
                }
            )
            .then((res) => {
                // console.log("Permission Response", res);
            })
            .catch((err) => {
                showErrorToastMessage(err.message, 10);
                console.log(err);
            });
        let formData = pricingNew;
        formData.forEach((obj) => delete obj.checked);
        //console.log("fomdta==>", formData);
        semcastAxios
            .post(PRICING + id, formData, {
                withCredentials: true,
            })
            .then((res) => {
                //console.log("FromData Response  ", res);
                showSuccessToastMessage("Account Info Saved.")
                // getDetails(accountsIndex, 0);
                setShowPricing(false)
            })
            .catch((err) => {
                showErrorToastMessage(err.message, 10);
                console.log(err);
            });
    };
    const defaultSorted = [
        {
            dataField: "company",
            order: "asc",
        },
    ];
    // useEffect(() => {
    //     get();
    // }, []);
    const handleCancel = () => {
        // if (originalFounderId) {
        //     getDetails(originalFounderId);
        // } else {
        //     resetFormValues();
        // }
        //navigate(-1);
        setShowPricing(false);
        setAccountsIndex(null);
    };


    return (
        <>
            {user?.isAdmin &&
                <div className="container-fluid admin-account-pricing">
                    <div className="row ">
                        <LoadOverlay active={loading}>
                        <form>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="tab-content mt-3">
                                    <div className="card">
                                        <div className="card-header d-block">
                                            <div className="row">
                                                  <div className="col-12 text-right">
                                                    {pricingNew.length > 0 && !loading && (
                                                        <>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary float-right"
                                                                onClick={(e) => {
                                                                    onSubmit(e);
                                                                }}
                                                            >
                                                                Save
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-primary float-right"
                                                                onClick={handleCancel}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">

                                                {alert && (
                                                    <Alert variant='danger' onClose={() => setAlert(false)} dismissible>
                                                        All Onboarding Companies Are Hidden. This Account will not be
                                                        able to onboard their data
                                                    </Alert>
                                                )}

                                                <ToolkitProvider
                                                    keyField="__id"
                                                    bootstrap4={true}
                                                    data={pricingNew}
                                                    columns={columns}
                                                    columnToggle
                                                >
                                                    {(props) => (
                                                        <div>
                                                            {/*<ToggleList { ...props.columnToggleProps } />*/}
                                                            {/*<CustomToggleList {...props.columnToggleProps} />*/}
                                                            <hr />
                                                            {console.log('pricingNew=', pricingNew)}
                                                            <BootstrapTable
                                                                {...props.baseProps}
                                                                filter={filterFactory()}
                                                                defaultSorted={defaultSorted}
                                                                cellEdit={cellEditFactory({
                                                                    mode: "click",
                                                                    blurToSave: true,
                                                                })}
                                                                bordered={false}
                                                                noDataIndication={noDataIndication}
                                                                hover
                                                                condensed
                                                            />
                                                        </div>
                                                    )}
                                                </ToolkitProvider>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </LoadOverlay>
                    </div>
                </div>
            }
        </>
    );
}

export default AccountPricing2;
