import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const xandrdv360Config = {
  ...COMPANY_BASE_PROPERTIES,

  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With DV360 via Xandr",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "xandrdv360",

  /**
   * HTML that explains how to use the form
   */
  instructions:
    "You have selected a platform that automatically reports usage for targeting. " +
    "You will be billed for data usage by the platform unless the segment is applied for suppression purposes. " +
    "Suppression segments are billed by Semcasting directly based on the discounted deployed record formula. " +
    "Activating this audience means you agree to this billing method. ",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "xandrdv360",
    text: "DV360 via Xandr",
    img: "xandr-dv360.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink: "",

  layout:[
    {
      fieldType:'radioGroup',
      labelText:'Ad Type',
      fieldName:'ad_type',
      required:true,
      radioButtons:[
        {
          labelText:'Banner',
          fieldName:'banner',
          value:'banner',
          checked:true
        },{
          labelText:'Video',
          fieldName:'video',
          value:'video',
          checked:false
        },{
          labelText:'Native',
          fieldName:'native',
          value:'native',
          checked:false
        },{
          labelText:'Audio',
          fieldName:'audio',
          value:'audio',
          checked:false
        }
      ]
    },{
      fieldType:'radioGroup',
      labelText:'Mobile Devices',
      fieldName:'mobile_devices',
      required:true,
      radioButtons:[
        {
          labelText:'Top Devices',
          fieldName:'top_devices',
          value:'top_ios_android',
          checked:true
        },{
          labelText:'All Devices',
          fieldName:'all_devices',
          value:'ios_android',
          checked:false
        }
      ]
    },{
      fieldType:'checkboxGroup',
      labelText:'Device Types',
      fieldName:'xandr_device_types',
      required:true,
      checkboxes:[
        {
          labelText:'Phone',
          fieldName:'phone',
          value:'phone',
          checked:true
        },{
          labelText:'Tablet',
          fieldName:'tablet',
          value:'tablet',
          checked:true
        },{
          labelText:'PC',
          fieldName:'pc',
          value:'pc',
          checked:true
        },{
          labelText:'TV',
          fieldName:'tv',
          value:'tv',
          checked:false
        },{
          labelText:'Game Console',
          fieldName:'gameconsole',
          value:'gameconsole',
          checked:false
        },{
          labelText:'Set Top Box',
          fieldName:'stb',
          value:'stb',
          checked:false
        }
      ]
    },{
      fieldType:'dateRange',
      fieldName:'campaign_range',
      labelText:'Campaign Date Range',
      required:'dateRangeRequired'
    }, {
      fieldType: 'textField',
      fieldName: 'billing_contact_email',
      labelText: 'Billing Contact Email',
      required: true,
      validation: [{
        pattern: 'email',
        msg: 'Please enter a valid email'
      }]
    } ,
// BKR 2020-12-18 & 2021-02-03
    {
      fieldType:'savableFieldsWrapper',
      layout:[
        {
          fieldType:'searchSelect',
          fieldName:'memberId',
          labelText:'DV360 Seat ID',
          url:'/v2/secure/attributor/activate-credentials/xandrdv360/memberId',
          placeholder:'Select a DV360 Seat ID or add a new one',
          namePropName:'value',
          textPropName:'value',
          valuePropName:'id',
          required:true,
          validation: [{
            required: function (value, attr, computed) {
              if (!value) {
                return 'DV360 Seat ID is required'
              }
            }
          }]
        },{
          fieldType:'checkbox',
          fieldName:'save_credentials',
          labelText:'Save DV360 Seat ID for future use',
          checked: false
        }
      ]
    },
    {
      fieldType:'radioGroup',
      labelText:'Planned Usage of this Segment:',
      fieldName:'audience_usage',
      required:true,
      radioButtons:[
        {
          labelText:'Targeting',
          fieldName:'use_type_inclusion',
          value:'I',
          checked:false
        }, {
          labelText:'Suppression',
          fieldName:'use_type_exclusion',
          value:'X',
          checked:false
        }
      ]
    },{
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },{
      fieldType:'pricing'
    },{
      fieldType:'checkbox',
      fieldName:'client_agreement',
      required:true,
      labelText:'Please Confirm: By Checking this Box and Activating, you agree to all associated costs and fees described above.',
      value:false
    }
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: [
    // BKR 2020-12-18 & 2021-02-03
    "memberId",
  ],
  formik: {
    initialValues: {
      ad_type: "banner",
      mobile_devices: "top_ios_android",
      xandr_device_types: ["phone", "tablet", "pc"],
      campaign_range_start: "",
      campaign_range_end: "",
      billing_contact_email:"",
      save_credentials: false,
      memberId: "",
      audience_usage:"",
      client_agreement:"",
      creativeTagIdDropDown: "",
      creativeTagId:""
    },
    // We used Yup here for data validation and condition.
    validationSchema: Yup.object().shape({
      // device_type: Yup.array().oneOf([true],"Devices to Activate is required"),
      mobile_devices: Yup.string().required("Mobile Devices is required"),
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      memberId: Yup.string().required("DV360 Seat ID is required"),
      billing_contact_email:Yup.string().required("Billing Contact Email is required").email("Please enter a valid email format"),
      xandr_device_types: Yup.array().min(1, "Please choose at least one"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      client_agreement: Yup.boolean().required("Please Confirm"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
    }),

    onSubmit: (values) => {},
  },
};
