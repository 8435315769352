import React, {useContext, useEffect, useRef, useState} from "react";
import {GOOGLE_MAPS_API_KEY} from "../../../api/constants";
import {Button} from "@progress/kendo-react-buttons";
import {Input} from "@progress/kendo-react-inputs";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {loadAccountsData} from "../../../actions/AccountActions";
import {UserContext} from "../../../contexts/UserContext";

const MapComponent = (props) => {
    const [map, setMap] = useState(null);
    const [drawingManager, setDrawingManager] = useState();
    const [width, setWidth] = useState("100%");
    const [height, setHeight] = useState("400px");
    const [address, setAddress] = useState("");
    const [defaultAddress, setDefaultAddress] = useState("");
    const [mapExtended, setMapExtended] = useState(false);
    const {user, setUser} = React.useContext(UserContext);

    let extended = false;
    let extendCollapseTip = "Extend Map";

    const ref = useRef(null);

    const geoCodeInputChange = (location, zoom) => {
        const geocoder = new window.google.maps.Geocoder;
        geocoder.geocode({'address': location}, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                map.setCenter({lat: lat, lng: lng});
                if (!zoom) {
                    map.setZoom(16);
                } else {
                    map.setZoom(zoom);
                }
            } else if (status === "ZERO_RESULTS") {
                const msg = "Locating the address: " + address + ' was unsuccessful.';
                props.setMsg(msg);
                // alert(msg);
            }
        });
    };

    const handleAddress = (e) => {
        props.setMsg("");
        setAddress(e.currentTarget.value);
    };

    const onGeocodeInputChange = (e) => {
        e.preventDefault();
        if (address === '') return;
        geoCodeInputChange(address);
    };

    const expandCollapse = (e) => {
        e.preventDefault();
        if (!extended) {
            extended = true;
            document.getElementById("extendButton").title = "Collapse Map";
            document.getElementById("svgPath").setAttributeNS(null, "d", "M456 224H312c-13.3 0-24-10.7-24-24V56c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l40 40L442.3 5.7C446 2 450.9 0 456 0s10 2 13.7 5.7l36.7 36.7C510 46 512 50.9 512 56s-2 10-5.7 13.7L433 143l40 40c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8zm0 64c9.7 0 18.5 5.8 22.2 14.8s1.7 19.3-5.2 26.2l-40 40 73.4 73.4c3.6 3.6 5.7 8.5 5.7 13.7s-2 10-5.7 13.7l-36.7 36.7C466 510 461.1 512 456 512s-10-2-13.7-5.7L369 433l-40 40c-6.9 6.9-17.2 8.9-26.2 5.2s-14.8-12.5-14.8-22.2V312c0-13.3 10.7-24 24-24H456zm-256 0c13.3 0 24 10.7 24 24V456c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-40-40L69.7 506.3C66 510 61.1 512 56 512s-10-2-13.7-5.7L5.7 469.7C2 466 0 461.1 0 456s2-10 5.7-13.7L79 369 39 329c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8H200zM56 224c-9.7 0-18.5-5.8-22.2-14.8s-1.7-19.3 5.2-26.2l40-40L5.7 69.7C2 66 0 61.1 0 56s2-10 5.7-13.7L42.3 5.7C46 2 50.9 0 56 0s10 2 13.7 5.7L143 79l40-40c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2V200c0 13.3-10.7 24-24 24H56z");
        } else  {
            extended = false;
            document.getElementById("extendButton").title = "Extend Map";
            document.getElementById("svgPath").setAttributeNS(null, "d", "M200 32H56C42.7 32 32 42.7 32 56V200c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l40-40 79 79-79 79L73 295c-6.9-6.9-17.2-8.9-26.2-5.2S32 302.3 32 312V456c0 13.3 10.7 24 24 24H200c9.7 0 18.5-5.8 22.2-14.8s1.7-19.3-5.2-26.2l-40-40 79-79 79 79-40 40c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H456c13.3 0 24-10.7 24-24V312c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2l-40 40-79-79 79-79 40 40c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2V56c0-13.3-10.7-24-24-24H312c-9.7 0-18.5 5.8-22.2 14.8s-1.7 19.3 5.2 26.2l40 40-79 79-79-79 40-40c6.9-6.9 8.9-17.2 5.2-26.2S209.7 32 200 32z");
        }
        setMapExtended(extended);
    };

    const createCustomControls = () => {
        const searchControlDiv = document.createElement("div");
        searchControlDiv.style.paddingTop = ".5rem";
        searchControlDiv.style.paddingLeft = ".5rem";
        searchControlDiv.style.width = "60%";
        const form = document.createElement("form");
        form.style.border = "none";
        const searchButton = document.createElement("button");
        searchButton.id = "searchButton";
        searchButton.type = "submit";
        searchButton.className = "k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md k-icon-button search-button button-standard";
        searchButton.style.backgroundColor = "rgb(255, 255, 255)";
        const iconSpan = document.createElement("span");
        iconSpan.role = "presentation";
        iconSpan.className = "k-button-icon map-search-icon fa-crosshairs";
        searchButton.appendChild(iconSpan);
        searchButton.onclick = (e) => {
            onGeocodeInputChange(e);
        };
        form.appendChild(searchButton);
        const input = document.createElement("input");
        input.type = "search";
        input.name = "segment_name";
        input.className = "search-input k-input k-input-md k-rounded-md k-input-solid";
        input.style.backgroundColor = "rgb(255, 255, 255)";
        input.placeholder = "Search for a Location";
        input.onchange = (e) => {
            handleAddress(e);
        };
        input.value = address;
        form.appendChild(input);
        const extendButton = document.createElement("Button");
        extendButton.id = "extendButton";
        extendButton.title = "";
        if (extendCollapseTip === "Extend Map") {
            extendButton.title = "Extend Map";
        } else if (extendCollapseTip === "Collapse Map") {
            extendButton.title = "Collapse Map";
        }
        extendButton.className = "k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md open-map";
        extendButton.style.backgroundColor = "rgb(255, 255, 255)";
        const extendButtonSpan = document.createElement("span");
        extendButtonSpan.className = "k-button-text";
        const extendButtonDiv = document.createElement("div");
        extendButtonDiv.style.alignItems = "center";
        const extendButtonSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        extendButtonSvg.setAttributeNS(null, "viewBox", "0 0 448 512");
        extendButtonSvg.setAttributeNS(null, "width", "1em");
        extendButtonSvg.setAttributeNS(null, "height", "1em");
        extendButtonSvg.setAttributeNS(null, "stroke", "currentColor");
        extendButtonSvg.setAttributeNS(null, "fill", "currentColor");
        extendButtonSvg.setAttributeNS(null, "stroke-width", "0");
        if (extendCollapseTip === "Extend Map") {
            extendButtonSvg.setAttributeNS(null, "title", "Extend Map");
        } else if (extendCollapseTip === "Collapse Map") {
            extendButtonSvg.setAttributeNS(null, "title", "Collapse Map");
        }
        extendButtonSvg.style.color = "rgb(38, 157, 182)";
        extendButtonSvg.style.fontSize = "18px";
        const svgPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
        svgPath.id = "svgPath";
        svgPath.setAttributeNS(null, "d", "M200 32H56C42.7 32 32 42.7 32 56V200c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l40-40 79 79-79 79L73 295c-6.9-6.9-17.2-8.9-26.2-5.2S32 302.3 32 312V456c0 13.3 10.7 24 24 24H200c9.7 0 18.5-5.8 22.2-14.8s1.7-19.3-5.2-26.2l-40-40 79-79 79 79-40 40c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H456c13.3 0 24-10.7 24-24V312c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2l-40 40-79-79 79-79 40 40c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2V56c0-13.3-10.7-24-24-24H312c-9.7 0-18.5 5.8-22.2 14.8s-1.7 19.3 5.2 26.2l40 40-79 79-79-79 40-40c6.9-6.9 8.9-17.2 5.2-26.2S209.7 32 200 32z");
        extendButtonSvg.appendChild(svgPath);
        extendButtonDiv.appendChild(extendButtonSvg);
        extendButtonSpan.appendChild(extendButtonDiv);
        extendButton.appendChild(extendButtonSpan);
        extendButton.onclick = (e) => {
            expandCollapse(e);
        };
        form.appendChild(extendButton);
        searchControlDiv.appendChild(form);
        return searchControlDiv;
    };
    useEffect(() => {
            if (ref.current) {
                const gMap = new window.google.maps.Map(ref.current, {
                    center: props.options.center,
                    zoom: props.options.zoom,
                });
                gMap.setOptions(props.options);
                setMap(gMap);
                // props.onLoad(gMap);
            }
    },[ref]);

    useEffect(() => {
        if (map !== null) {
            let drawingMgr = new window.google.maps.drawing.DrawingManager();
            drawingMgr.setMap(map);
            drawingMgr.setOptions({
                drawingMode: null,
                drawingControl: true,
                drawingControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        window.google.maps.drawing.OverlayType.CIRCLE,
                        window.google.maps.drawing.OverlayType.POLYGON,
                        window.google.maps.drawing.OverlayType.RECTANGLE,
                    ],
                }
            });
            const customControls = createCustomControls();
            map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(customControls);
            props.onLoad(map, drawingMgr);
        }
    }, [map]);

    useEffect(()  => {
        if (!mapExtended) {
            setWidth("100%");
            setHeight("400px");
        } else {
            setWidth("100%");
            setHeight("700px");
        }

    }, [mapExtended]);

    useEffect(() => {
        if (address !== "") {
            geoCodeInputChange(address);
        }
    }, [address]);

    // Position Map default location to user's account detail location
    useEffect(async () => {
        const accountsData = await loadAccountsData().then((res) => {
            if (Object.keys(res).length > 0 || user?.founder || user?.isAdmin) {
                if (((res.address !== "" && res.address !== undefined) && (res.city !== "" && res.city !== undefined) &&
                    (res.state !== "" && res.state !== undefined)) || (res.zip_code !== "" && res.zip_code !== undefined)) {
                    setDefaultAddress(res.address + " " + res.city + " " + res.state + " " + res.zip_code);
                    if (defaultAddress !== "") geoCodeInputChange(defaultAddress, 10);
                }
            }
        })
    }, [defaultAddress]);

    return (
        <div ref={ref} id = "mapdiv" className={"col-md-12"} style={{ width: width, height: height }}>
            <div style={{paddingTop: ".5rem", paddingLeft: ".5rem", width: "60%"}}>
                <form>
                    <Button
                        className={"search-button button-standard"}
                        // onClick={(event) => onGeocodeInputChange(event)}
                        iconClass="map-search-icon fa-crosshairs"
                        type="submit"
                        style={{backgroundColor: "#FFFFFF"}}
                    >
                    </Button>
                    <Input
                        className={"search-input"}
                        type="search"
                        placeholder="Search for a Location"
                        // onChange={handleAddress}
                        // value={address}
                        style={{backgroundColor: "#FFFFFF"}}
                    >
                    </Input>
                    <Tooltip
                        position="right"
                        anchorElement="target"
                        tooltipStyle={{
                            width: "6rem",
                            borderRadius: "0.25rem",
                        }}
                    >
                        <Button
                            className={"open-map"}
                            // onClick={(e) => expandCollapse(e)}
                            // title={!mapExtended ? "Extend Map" : "Collapse Map"}
                            style={{backgroundColor: "#FFFFFF"}}>
                            <div style={{alignItems: "center"}}>
                                <svg style={{color: "#269DB6", fontSize: "18px"}}
                                     stroke="currentColor"
                                     fill="currentColor"
                                     strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em"
                                     // title={!mapExtended ? "Extend Map" : "Collapse Map"}
                                     xmlns="http://www.w3.org/2000/svg">
                                    {/*{!mapExtended && (<path*/}
                                    {/*    d="M200 32H56C42.7 32 32 42.7 32 56V200c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l40-40 79 79-79 79L73 295c-6.9-6.9-17.2-8.9-26.2-5.2S32 302.3 32 312V456c0 13.3 10.7 24 24 24H200c9.7 0 18.5-5.8 22.2-14.8s1.7-19.3-5.2-26.2l-40-40 79-79 79 79-40 40c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H456c13.3 0 24-10.7 24-24V312c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2l-40 40-79-79 79-79 40 40c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2V56c0-13.3-10.7-24-24-24H312c-9.7 0-18.5 5.8-22.2 14.8s-1.7 19.3 5.2 26.2l40 40-79 79-79-79 40-40c6.9-6.9 8.9-17.2 5.2-26.2S209.7 32 200 32z"/>)}*/}
                                    {/*{mapExtended && (<path*/}
                                    {/*    d="M456 224H312c-13.3 0-24-10.7-24-24V56c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l40 40L442.3 5.7C446 2 450.9 0 456 0s10 2 13.7 5.7l36.7 36.7C510 46 512 50.9 512 56s-2 10-5.7 13.7L433 143l40 40c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8zm0 64c9.7 0 18.5 5.8 22.2 14.8s1.7 19.3-5.2 26.2l-40 40 73.4 73.4c3.6 3.6 5.7 8.5 5.7 13.7s-2 10-5.7 13.7l-36.7 36.7C466 510 461.1 512 456 512s-10-2-13.7-5.7L369 433l-40 40c-6.9 6.9-17.2 8.9-26.2 5.2s-14.8-12.5-14.8-22.2V312c0-13.3 10.7-24 24-24H456zm-256 0c13.3 0 24 10.7 24 24V456c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-40-40L69.7 506.3C66 510 61.1 512 56 512s-10-2-13.7-5.7L5.7 469.7C2 466 0 461.1 0 456s2-10 5.7-13.7L79 369 39 329c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8H200zM56 224c-9.7 0-18.5-5.8-22.2-14.8s-1.7-19.3 5.2-26.2l40-40L5.7 69.7C2 66 0 61.1 0 56s2-10 5.7-13.7L42.3 5.7C46 2 50.9 0 56 0s10 2 13.7 5.7L143 79l40-40c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2V200c0 13.3-10.7 24-24 24H56z"/>)}*/}
                                </svg>
                            </div>
                            {/*Expand*/}
                        </Button>
                    </Tooltip>
                </form>
            </div>
        </div>
    )
}

export default MapComponent;