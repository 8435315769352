import React, {useState} from "react";
import Map from "./Map";
import {LoadScript} from "@react-google-maps/api";
import TargetingFull from "./TargetingFull";
import AboutAudience from "./AboutAudience";
import {postFromFile} from "../../../actions/OnBoardingActions";
import {Card, CardBody} from "@progress/kendo-react-layout";
import "./MapArea.css";
import {useFormik} from "formik";
import * as Yup from "yup";

const MapArea = (props) => {

    const [selectedTargetType, setSelectedTargetType] = useState(props.formik && props.formik.initialValues ? props.formik.initialValues.target_type : "CONSUMER")
    const [mapFormik, setMapFormik] = useState(props.mapAreaFormik ? props.mapAreaFormik : {});
    const [targetingFullFormik, setTargetingFullFormik] = useState(props.targetingFullFormik ? props.targetingFullFormik : {});
    const [aboutAudienceFormik, setAboutAudienceFormik] = useState(props.aboutAudienceFormik ? props.aboutAudienceFormik : {});

    // const mapAreaFormik = useFormik({
    //     initialValues: {
    //         audience_file_id: mapFormik.audience_file_id,
    //         generate_pdf_reports: props.aboutAudienceFormik && props.aboutAudienceFormik.initialValues ? props.aboutAudienceFormik.initialValues.generate_pdf_reports : true,
    //         generate_top_sites: aboutAudienceFormik.generate_top_sites,
    //         group_id : aboutAudienceFormik.group_id,
    //         notes: aboutAudienceFormik.notes,
    //         segment_design: targetingFullFormik.segment_design
    //     },
    //     onSubmit: () => {},
    // });

    const handleSelectedTargetType = (targetType) => {
        setSelectedTargetType(targetType)
        if (props.selectedTargetType) {
            props.handleSelectedTargetType(targetType);
        }
    };

    const handleMapFormik = (mapFormik) => {
        setMapFormik(mapFormik);
    };

    const handleTargetingFullFormik = (targetingFullFormik) => {
        setTargetingFullFormik(targetingFullFormik);
    };

    const handleAboutAudienceFormik = (aboutAudienceFormik) => {
        setAboutAudienceFormik(aboutAudienceFormik);
        if (props.aboutAudienceFormik) {
            props.handleAboutAudienceFormik(aboutAudienceFormik);
        }
    };

    const defaultValues = {
        segment_design: {
            target_type: "CONSUMER",
            primaryData: {},
        },
    };

    const lib = ["drawing"];

    const onSubmit = (formData) => {
        postFromFile({...formData, group_id: parseInt(formData.group_id)}).then((res) => {
            console.log(res);
        });
    };

    return (
        <div className={"map-area-outer-div"}>
            <div className={"map-area-card"}>
                <CardBody>
                    <div className="col-md-12 p-0">
                        <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                            <Map formik={props.formik} handleDisableSubmit={props.handleDisableSubmit}/>
                        </div>
                    </div>
                </CardBody>
            </div>

            {/*<div className="col-md-12 pt-3">*/}
            {/*    <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">*/}
            {/*        <TargetingFull*/}
            {/*            formik={props.formik}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="col-md-12 pt-3">*/}
            {/*    <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">*/}
            {/*        <AboutAudience*/}
            {/*            formik={props.formik}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default MapArea;
