import React, {useEffect, useState} from "react";
import {Label} from "@progress/kendo-react-labels";
import {Button} from "@progress/kendo-react-buttons";
import {Input} from "@progress/kendo-react-inputs";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import adsLogo from "../assets/img/ads_logo.fec9a72f.png";
import "./ResetPassword.css"
import { useParams, useLocation } from 'react-router-dom';
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";
import { SvgIcon } from "@progress/kendo-react-common";
import {
    facebookIcon,
    checkOutlineIcon
} from "@progress/kendo-svg-icons";
import PasswordChecklist from "react-password-checklist";


const ResetPassword = ()  => {

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    const NEW_PASSWORD_REQUIREMENTS = "Password must contain at least 10 characters, 1 uppercase letter, 1 number, and 1 special character (~ ` ! @ # $ % ^ & * + = - ; , / ( ) { } : < > ?)";
    const CONFIRM_PASSWORD_HINT = "Both passwords must match";
    // const { id, t } = useParams();
    //
    // console.log("id:", id);
    // console.log("token", t);

    let query = new URLSearchParams(useLocation().search);

    let id = query.get('id');
    let token = query.get('t');

    // console.log("id2:", id);
    // console.log("token2", token);

    const newPasswordValidator = value => {
        if(!/[^a-zA-Z\d\s:]/g.test(value) && !/[A-Z]/g.test(value) && !/[0-9]/g.test(value)) return "Must contain at least 10 characters, 1 uppercase letter, 1 number, and 1 special character";
        else if(value.length < 10) return "Must contain at least 10 characters";
        else if(!/[A-Z]/g.test(value)) return "Must contain at least 1 uppercase letter";
        else if(!/[0-9]/g.test(value)) return "Must contain at least 1 number";
        else if(!/[^a-zA-Z\d\s:]/g.test(value)) return "Must contain at least 1 special character listed:  ~ ` ! @ # $ % ^ & * + = - ; , / ( ) { } : < > ?";
        else return "";
    }

    const confirmNewPasswordValidator = value => !value ? " " : newPassword === confirmNewPassword ? "" : "Passwords do not match";


    // const newPasswordNotValid = value => {
    //     return !/[^a-zA-Z\d\s:]/g.test(value) && !/[A-Z]/g.test(value) && !/[0-9]/g.test(value);
    // }

    const newPasswordValid = value => {
        return /[~`!@#$%^&*+=\-;,/(){}:<>?]/.test(value) && /[A-Z]/.test(value) && /\d/.test(value) && value.length >= 10;
    }

    const checkPasswordRequirements = () => {
        const lengthRegex = /.{10,}/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[~`!@#$%^&*+=\-;,/(){}:<>?]/;

        const isLengthValid = lengthRegex.test(newPassword);
        const isUppercaseValid = uppercaseRegex.test(newPassword);
        const isNumberValid = numberRegex.test(newPassword);
        const isSpecialCharValid = specialCharRegex.test(newPassword);

        return {
            isLengthValid,
            isUppercaseValid,
            isNumberValid,
            isSpecialCharValid,
        };
    };

    const passwordRequirements = checkPasswordRequirements();



    useEffect(() => {

    },[])

    const resetPassword = async () => {
        setDisableSubmitButton(true);

        // API request to reset password
        let response = await semcastAxios.post("/v2/attributor/update-password",
            {
                id: id,
                token: token,
                password: newPassword,
                confirm_password: confirmNewPassword
            },
            {
                withCredentials: true,
            })
            .then((res) => {
                console.log("resetPass res:", res);

                if(res.data.code === 200) {
                    showSuccessToastMessage('Password reset successfully. ', 3);
                    setShowSuccessDialog(true);
                    setDisableSubmitButton(false);
                } else {
                    showErrorToastMessage("Error occurred. Password not reset. Please contact support. ", 10);
                    setDisableSubmitButton(false);
                }
            }).catch((error) => {
                // showErrorToastMessage("Error occurred. Password not reset. " + error.message + ". " + error.response.data.data, 10);
                setDisableSubmitButton(false);
                if (error.response) {
                    console.log('Response Error:', error.response.data);
                    showErrorToastMessage("Error occurred. Password not reset. Response Error: " + error.message + ". " + error.response.data.data, 10);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Request Error:', error.request);
                    showErrorToastMessage("Error occurred. Password not reset. Request Error: " + error.message + ". " + error.request.data.data, 10);
                }
            })

    };

    const redirectToLoginPage = () => {
        window.location.href ="/"
    };

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword)
    }

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    return (
        <div className={"reset-page-outer-container"}>
            <div className={"splash-page-background"}>
                <div className={"reset-page-dialog-container"}>
                    <Dialog
                        width={500}
                        closeIcon={false}
                        className={"dialog-standard reset-page-dialog"}
                    >
                        {showSuccessDialog ?
                            <div className={"reset-password-success-container"}>
                                <div className={"landing-page-logo-wrapper"}>
                                    {/*<a href="https://localhost:3000/">*/}
                                    {/*    <img src={adsLogo}   className={"landing-page-logo"}/>*/}
                                    {/*</a>*/}
                                    <img src={adsLogo}   className={"landing-page-logo"}/>
                                    <a href="https://semcasting.com/" target="_blank" className={"splash-page-semcasting-link"}>Powered by Semcasting</a>
                                </div>

                                <div className={"reset-password-svg-icon-container"}>
                                    <SvgIcon icon={checkOutlineIcon} size={"large"} className={"reset-successful-check-icon"}/>
                                </div>

                                {/*<div className={"reset-success-text-1"}>*/}
                                {/*    Password Reset*/}
                                {/*</div>*/}

                                <Label
                                    className={"field-label"}
                                >
                                    Your password has been successfully reset.
                                </Label>
                                <Label
                                    className={"field-label"}
                                >
                                    Click below to return to sign in page.
                                </Label>




                                {/*<div className={"reset-success-text-2"}>*/}
                                {/*    Your password has been successfully reset.*/}
                                {/*</div>*/}
                                {/*<div className={"reset-success-text-3"}>*/}
                                {/*    Click below to return to log in screen.*/}
                                {/*</div>*/}


                            </div>
                            :
                            <div className={"reset-password-form-container"}>

                                <div className={"landing-page-logo-wrapper"}>
                                    {/*<a href="https://localhost:3000/">*/}
                                    {/*    <img src={adsLogo}   className={"landing-page-logo"}/>*/}
                                    {/*</a>*/}
                                    <img src={adsLogo}   className={"landing-page-logo"}/>
                                    <a href="https://semcasting.com/" target="_blank" className={"splash-page-semcasting-link"}>Powered by Semcasting</a>
                                </div>

                                <div className={"reset-password-new-container"}>
                                    <Label
                                        className={"field-label"}
                                    >
                                        New Password
                                    </Label>

                                    <div className={"password-field-wrapper"}>
                                        <Input
                                            name="new_password"
                                            className={"new-password-field"}
                                            value={newPassword}
                                            type={showNewPassword ? 'text' : 'password'}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            // placeholder={""}
                                        >
                                        </Input>
                                        <Button type={"button"} className="toggle-view-password" icon="eye"
                                                onClick={toggleShowNewPassword} disabled={!newPassword.length}></Button>
                                    </div>
                                </div>

                                <PasswordChecklist
                                    rules={["minLength", "capital", "number", "specialChar", "match"]}
                                    minLength={10}
                                    value={newPassword}
                                    valueAgain={confirmNewPassword}
                                    messages = {{
                                        minLength: "At least 10 characters",
                                        specialChar: "At least 1 special character",
                                        number: "At least 1 number",
                                        capital: "At least one capital letter",
                                        match: "Passwords must match",
                                    }}
                                >
                                </PasswordChecklist>

                                <div className={"reset-password-confirm-new-container"}>
                                    <Label
                                        className={"field-label"}
                                    >
                                        Confirm New Password
                                    </Label>

                                    <div className={"password-field-wrapper"}>
                                        <Input
                                            name="confirm_new_password"
                                            className={"confirm-password-field"}
                                            value={confirmNewPassword}
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                                            // placeholder={""}
                                        >
                                        </Input>
                                        <Button type={"button"} className="toggle-view-password" icon="eye"
                                                onClick={toggleShowConfirmPassword} disabled={!confirmNewPassword.length}></Button>
                                    </div>

                                    {/*<Label className={"hint-standard new-password-hint"}>{CONFIRM_PASSWORD_HINT}</Label>*/}
                                </div>
                            </div>}



                        {/*<div className={"reset-password-form-container"}>*/}

                        {/*    <div className={"landing-page-logo-wrapper"}>*/}
                        {/*        /!*<a href="https://localhost:3000/">*!/*/}
                        {/*        /!*    <img src={adsLogo}   className={"landing-page-logo"}/>*!/*/}
                        {/*        /!*</a>*!/*/}
                        {/*        <img src={adsLogo}   className={"landing-page-logo"}/>*/}
                        {/*        <a href="https://semcasting.com/" target="_blank" className={"splash-page-semcasting-link"}>Powered by Semcasting</a>*/}
                        {/*    </div>*/}

                        {/*    <div className={"reset-password-new-container"}>*/}
                        {/*        <Label*/}
                        {/*            className={"field-label"}*/}
                        {/*        >*/}
                        {/*            New Password*/}
                        {/*        </Label>*/}
                        {/*        <Input*/}
                        {/*            name="new_password"*/}
                        {/*            value={newPassword}*/}
                        {/*            onChange={(e) => setNewPassword(e.target.value)}*/}
                        {/*            // placeholder={""}*/}
                        {/*        >*/}
                        {/*        </Input>*/}
                        {/*        <Label className={"hint-standard new-password-hint"}>{NEW_PASSWORD_REQUIREMENTS}</Label>*/}
                        {/*    </div>*/}

                        {/*    <div className={"reset-password-confirm-new-container"}>*/}
                        {/*        <Label*/}
                        {/*            className={"field-label"}*/}
                        {/*        >*/}
                        {/*            Confirm New Password*/}
                        {/*        </Label>*/}
                        {/*        <Input*/}
                        {/*            name="confirm_new_password"*/}
                        {/*            value={confirmNewPassword}*/}
                        {/*            onChange={(e) => setConfirmNewPassword(e.target.value)}*/}
                        {/*            // placeholder={""}*/}
                        {/*        >*/}
                        {/*        </Input>*/}
                        {/*        <Label className={"hint-standard new-password-hint"}>{CONFIRM_PASSWORD_HINT}</Label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <DialogActionsBar>
                            {showSuccessDialog ?
                                <Button
                                    className={"button-standard button-submit reset-password-button"}
                                    onClick={redirectToLoginPage}
                                >
                                    Continue
                                </Button>
                                :
                                <Button
                                    className={"button-standard button-submit reset-password-button"}
                                    disabled={(newPassword !== confirmNewPassword) || !newPasswordValid(newPassword) || disableSubmitButton}
                                    onClick={resetPassword}
                                >
                                    Reset Password
                                </Button>
                            }
                        </DialogActionsBar>

                    </Dialog>



                </div>
            </div>
        </div>
    )

};

export default ResetPassword;
