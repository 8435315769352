import React, {useEffect, useState} from "react";
import {DropDownList, MultiSelect} from "@progress/kendo-react-dropdowns";
import { Label } from '@progress/kendo-react-labels';
import { RangeSlider, SliderLabel } from '@progress/kendo-react-inputs';
import "./AgeRange.css"

const AgeRange = (props) => {

    const [value, setValue] = useState(props.controlsState ?
        props.controlsState.get("age_range") : []);

    const [ageRangeOptions, setAgeRangeOptions] = useState([
        {text: "18 - 22", value: "A"},
        {text: "23 - 29", value: "B"},
        {text: "30 - 39", value: "C"},
        {text: "40 - 49", value: "D"},
        {text: "50 - 59", value: "E"},
        {text: "60 - 69", value: "F"},
        {text: "70 - 79", value: "G"},
        {text: "80 - 89", value: "H"},
        {text: "90 - 99", value: "I"}
    ]);

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.age_range) {
                props.segmentEditInfo.data.segment_design.primaryData.age_range.forEach(rangeValue => {
                    ageRangeOptions.find(option => {
                        if (option.value === rangeValue) {
                            arr.push(option);
                        }
                        ;
                    })
                })
                setValue(arr);
            }
        }
    }, [props.segmentEditInfo]);

    const onAgeRangeChange = (e) => {
        let values = [];
        setValue(e.value);
        e.value.forEach((val) => {
            values.push(val.value);
        });
        props.formik.values.segment_design.primaryData.age_range = values;
        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("age_range", e.value)));
        }
    };

    const itemRender = (li, itemProps) => {
        const index = itemProps.index;
        const itemChildren = (
            <span
                style={{
                    // color: "#00424D",
                }}
            >
                {li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }

    return (
        <>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={ageRangeOptions}
                    autoClose={false}
                    itemRender={itemRender}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    onChange={onAgeRangeChange}
                    size="large"
                    value={value}
                    // style={{width: "32rem"}}
                />

            </div>
        </>
    );
}

export default AgeRange;
