import React, {useEffect, useMemo, useState} from "react";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import {ethnicBackgroundConfig} from "../../../config/ethnicBackgroundConfig";
import {filterBy} from "@progress/kendo-data-query";
import "./EthnicBackground.css";

const EthnicBackground = (props) => {

    const [value, setValue] = useState(props.controlsState ?
        props.controlsState.get("sem_ethnic_cd") : []);

    const [ethnicBackgroundOptions, setEthnicBackgroundOptions] = useState([]);
    const [filteredEthnicBackgroundOptions, setFilteredEthnicBackgroundOptions] = useState([]);

    useEffect(() => {
        let optionsArr = [];
        Object.keys(ethnicBackgroundConfig).forEach(configOption => {
            let obj = {};
            obj.text = ethnicBackgroundConfig[configOption];
            obj.value = configOption
            optionsArr.push(obj);
        })
        setEthnicBackgroundOptions(optionsArr);
    }, []);

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.controlsState) return;
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.sem_ethnic_cd) {
                props.segmentEditInfo.data.segment_design.primaryData.sem_ethnic_cd.forEach(rangeValue => {
                    ethnicBackgroundOptions.find(option => {
                        if (option.value === rangeValue) {
                            arr.push(option);
                        }
                        ;
                    });
                });
                setValue(arr);
            }
        }
    }, [props.segmentEditInfo]);

    const onEthnicBackgroundFilterChange = (e) => {
        const filter = e.filter;
        const allData = ethnicBackgroundOptions.slice();
        const newData = filter.value.length >= 1 ? filterBy(allData, filter) : allData;
        if (newData.length > 0) {
            setFilteredEthnicBackgroundOptions(newData);
        }
    };

    const onEthnicBackgroundChange = (e) => {
        let values = [];
        setValue(e.value);
        e.value.forEach((val) => {
            values.push(val.value);
        });
        props.formik.values.segment_design.primaryData.sem_ethnic_cd = values;
        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("sem_ethnic_cd", e.value)));
        }
    };

    return (
        <div>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={ethnicBackgroundOptions}
                    autoClose={false}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    filterable={true}
                    onFilterChange={onEthnicBackgroundFilterChange}
                    onChange={onEthnicBackgroundChange}
                    size="large"
                    value={value}
                />
            </div>
        </div>
    );
}
export default EthnicBackground;
