import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const semcastingdirectmailConfig = {
    ...COMPANY_BASE_PROPERTIES,
    /**
     * User Readable name of company the user is onBoarding with
     */
    companyText: "Direct Mail via Semcasting",

    /**
     * lowercase no spaces or underscores name of company
     * This will be passed to the activate model and
     * used when saving to create the correct submission url
     */
    company: "semcasting_directmail",
    isConsumer: null,
    isThirdPartySegment: null,
    /**
     * HTML that explains how to use the form
     */
    instructions:"",
    // "Select required fields from Location Properties followed by any listed platform identifiers, email types, devices etc. For every selected Platform ID a sample of up to 5 are provided. Consumer Segments created or edited from Audience Design will not be eligible for any PII options.",
    /**
     * data used to create a company activation block
     */
    companyBlockOptions: {
        val: 'semcasting_directmail',
        text: 'Semcasting-DirectMail',
        img: 'semcasting-postal.png'
    },

    /**
     * anchor tag used to login to a specific platform
     */
    loginLink:
        '',

    layout: [
        {
            fieldType: "radioGroup",
            labelText: "Select Type",
            fieldName: "activation_type",
            required: true,
            radioButtons: [
                {
                    labelText: "Single Use - $10 per thousand",
                    fieldName: "single_use",
                    value: "single_use",
                    checked: false,
                },
                {
                    labelText: "Multi Use (1 year) - $25 per thousand",
                    fieldName: "multi_use",
                    value: "multi_use",
                    checked: false,
                },
            ],
        },
        {
            fieldType: 'checkbox',
            fieldName: 'client_agreement',
            required: true,
            labelText: '<strong>Please Confirm: By Checking this Box and Activating, you agree to all associated costs and fees described above</strong>',
            value: false
        },
        {
            fieldType: "selectPickerFTPConnection",
            fieldName: "selectPickerFTPConnection",
            labelText: "FTP Setting Connection",
            url: "/v2/secure/attributor/creative-tags",
            namePropName: "selectPickerFTPConnection",
            textPropName: "selectPickerFTPConnection",
            valuePropName: "selectPickerFTPConnection",
            required: true,
        },
    ],

    /**
     * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
     */
    savableDataNames: [],

    formik: {
        initialValues: {
            device_type:"mail_address",
            activation_type: "",
            client_agreement: "",
            selectPickerFTPConnection: ""
        },
        // We used Yup here for input format validation.
        validationSchema: Yup.object().shape({
            activation_type: Yup.string().required("Activation type is required."),
            client_agreement: Yup.boolean().required("Please confirm."),
            selectPickerFTPConnection: Yup.object().required("Please select one from the list"),
        }),
        onSubmit: (values) => {},
    },
};
