import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const liverampConfig = {
  ...COMPANY_BASE_PROPERTIES,
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With LiveRamp",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "liveramp",

  /**
   * HTML that explains how to use the form
   */
  instructions:
    "Please provide the Advertiser ID and the Start/End date. All fields must be completed " +
    "to push an audience to your seat.  Please note that the audience may take up 3 and 5 days for " +
    "the audience to show up in your platform.",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "liveramp",
    text: "LiveRamp",
    img: "liveramp-logo.png",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink:
    '<a href="https://connect.liveramp.com/login" target="_blank">Log in to LiveRamp</a>',

  layout: [
    {
      fieldType: "selectPickerLR",
      fieldName: "liveramp_platform_id",
      labelText: "LiveRamp Platform",
      url: "/v2/secure/attributor/activate-credentials/liveramp-platforms",
      namePropName: "platform_name",
      textPropName: "platform_name",
      valuePropName: "platform_id",
      showDeleteButton: false,
      allowAdditions: false,
      options: [
        { text: 'A&E Networks', val: "17136"},
        { text: 'AcuityAds', val: "10549"},
        { text: 'Adara', val: "4629"},
        { text: "AdColony (Opera Mediaworks)", val: "3366"},
        { text: 'Adelphic', val: "5336"},
        { text: 'AdRoll', val: "1858"},
        { text: 'Adswizz', val: "7536"},
        { text: 'Amazon', val: "15099"},
        { text: 'AMC Networks', val: "15209"},
        { text: 'Ampersand', val: "17146"},
        { text: 'AOL', val: "1458" },
        { text: 'AudienceXpress (Freewheel)', val: "19191" },
        { text: 'Beeswax', val: "6546" },
        { text: 'Bidtellect', val: "7356" },
        { text: 'Cadreon - TV', val: "17431" },
        { text: 'Centro (SiteScout)', val: "3676" },
        { text: 'Choozle', val: "11249" },
        { text: 'Collective', val: "1558" },
        { text: 'Column6', val: "15349" },
        { text: "Conversant Media", val: "8636"},
        { text: 'CreditCards.com', val: "6906"},
        { text: 'DeepIntent', val: "13876" },
        { text: 'Digilant', val: "1408" },
        { text: 'Dish/Sling TV', val: "14186" },
        { text: 'Disney Ad Sales', val: "21991" },
        { text: 'Eyeota', val: "7916" },
        { text: 'Flashtalking', val: "2609" },
        { text: 'Foursquare', val: "5316" },
        { text: 'Fox TV', val: "16509" },
        { text: 'FreeWheel', val: "7516" },
        { text: 'Google | Data Marketplace', val: "1428" },
        { text: 'Huddled Masses - ID Sync', val: "11639" },
        { text: 'iHeart Media [update]', val: "16559" },
        { text: 'inMarket', val: "6556" },
        { text: 'InMobi', val: "3406" },
        { text: 'Innovid', val: "5026" },
        { text: 'Innovid - CTV', val: "15489" },
        { text: 'Instinctive', val: "6266" },
        { text: 'LG Ad Solutions', val: "6036" },
        { text: 'LiveIntent', val: "1728" },
        { text: 'LiveNation', val: "12076" },
        { text: 'Lucid', val: "6006" },
        { text: 'MadHive - ID Sync', val: "16039" },
        { text: 'MediaMath', val: "1021"},
        { text: 'Meredith', val: "6586"},
        { text: "Moasis", val: "2539"},
        { text: 'MobileFuse', val: "13406"},
        { text: 'NBC Universal', val: "15369"},
        { text: 'NinthDecimal', val: "2438"},
        { text: 'OpenX', val: "5006"},
        { text: 'Outbrain', val: "7486"},
        { text: 'OwnerIQ', val: "1468"},
        { text: 'Place Exchange', val: "19551"},
        { text: 'PubMatic', val: "1255"},
        { text: 'PulsePoint', val: "5786"},
        { text: 'Roku', val: "5936"},
        { text: 'Samsung Ads', val: "4756"},
        { text: 'Sharethrough', val: "12386"},
        { text: 'Simpli.fi', val: "2048"},
        { text: 'Snapchat', val: "6606"},
        { text: "Spotad", val: "10069"},
        { text: 'Spotify', val: "7326"},
        { text: 'SpotX', val: "5446" },
        { text: 'Tapad', val: "1908" },
        { text: 'TikTok', val: "19161" },
        { text: 'Twitch', val: "18371" },
        { text: 'Twitter', val: "4896" },
        { text: 'Viant', val: "1738" },
        { text: 'Videology', val: "1938" },
        { text: 'Walmart', val: "10589" },
        { text: "xAd", val: "3176"},
        { text: 'Zeta DSP (FKA Sizmek/Rocket Fuel)', val: "1011" },
      ],
      required: true,
    },
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelect",
          fieldName: "advertiserId",
          labelText: "Advertiser ID",
          url: "/v2/secure/attributor/activate-credentials/liveramp/advertiserId",
          placeholder: "Select an Advertiser ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType: 'checkbox',
          fieldName: "save_credentials",
          labelText: 'Save Advertiser ID for future use',
          checked: false,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },{
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
    {
      fieldType: "pricing",
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["advertiserId"],
  formik: {
    initialValues: {
      liveramp_platform_id: "",
      device_type: ["ios_android"],
      campaign_range_start: "",
      campaign_range_end: "",
      save_credentials: false,
      advertiserId: "",
      audience_usage: "",
      creativeTagIdDropDown: "",
      creativeTagId:""
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      liveramp_platform_id: Yup.string().required(
        "LiveRamp Platform is required"
      ),
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      //save fields
      advertiserId: Yup.string().required("Advertiser ID is required"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
    }),
    onSubmit: (values) => {},
  },
};
