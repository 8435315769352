import React, {useEffect, useState} from "react";
import {loadNotifications, updateNotifications} from "../../actions/NotificationActions";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Button} from "@progress/kendo-react-buttons";
import {Grid, GridColumn as Column} from "@progress/kendo-react-grid";
import {process} from '@progress/kendo-data-query';
import EventsEmitter from "../../Utils/EventsEmitter";

const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "created_at",
            operator: "contains",
            value: ""
        }
    ]
};

const NotificationDashboard = () => {

    const [overlayActive, setOverlayActive] = useState(false);

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(initialFilter);

    const initialSort = [
        {
            field: "created_at",
            dir: "desc"
        }
    ]
    const [sort, setSort] = useState(initialSort);
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [modifiedRows, setModifiedRows] = useState({});

    const dataState = {
        take,
        skip,
        sort,
        filter
    };

    const onDataStateChange = React.useCallback(
        (event) => {
            setTake(event.dataState.take);
            setSkip(event.dataState.skip);
            setSort(event.dataState.sort);
            setFilter(event.dataState.filter);
        },
        [setTake, setSkip, setSort]
    );

    const processedData = process(data, dataState);

    const notificationsLoad = async () => {
        try {
            const values = await Promise.allSettled([loadNotifications()]);
            setData(values[0].value);
            EventsEmitter.emit("UpdatedNotificationsEvent", values[0].value);
        } catch (e) {
            // console.log("Error loading users:", e);
        }
    }

    const refreshTable = async () => {
        setOverlayActive(true);
        await notificationsLoad();
        setOverlayActive(false);
    }


    const manualTaskCompletedDropdownRender = (props) => {
        console.log ("manualTaskCompletedDropdownRenderPropsDataItem=", props.dataItem);
        const isDropdownEnabled = props.dataItem.manual_task_required && !props.dataItem.manual_task_completed;

        if (isDropdownEnabled) {
            return (
                <td>
                    <DropDownList
                        data={['YES', 'NO']}
                        onChange={(e) => handleChangeTaskCompletedDropdown(e, props.dataItem)}
                        defaultValue={'NO'}
                        className="fields dropdown-standard"
                    />
                </td>
            );
        } else {
            return (
                <td>
                    {props.dataItem.manual_task_completed ? 'YES' : 'NO'}
                </td>
            );
        }
    }


    const handleSaveNotification = (item) => {
        updateNotifications(item).then(r => {
        setModifiedRows(prev => ({ ...prev, [item.id]: false }));
        EventsEmitter.emit("UpdatedNotificationsEvent", 0)
        });
    }

    const handleChangeTaskCompletedDropdown = (e, item) => {
        let newData = data.map((itm) => {
            if (itm.id === item.id) {
                itm.manual_task_completed = e.value === 'YES' ? 1 : 0;
                // Mark as modified
                setModifiedRows(prev => ({ ...prev, [itm.id]: true }));
            }
            return itm;
        })
        setData(newData)
    }


    const rowRender = (trElement, props) => {

        const changeAllowedStyle = {
            backgroundColor: "lightgoldenrodyellow",
        };

        const defaultStyle = {};

        // Determine if change is allowed
        const isChangeAllowed = props.dataItem.manual_task_required && !props.dataItem.manual_task_completed;

        // Apply style based on condition
        const trProps = {
            style: isChangeAllowed ? changeAllowedStyle : defaultStyle,
        };

        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };


    const actionsRender = (props) => {
        //const shouldShowButton = props.dataItem.manual_task_required && !props.dataItem.manual_task_completed;
        const shouldShowButton = modifiedRows[props.dataItem.id] || (props.dataItem.manual_task_required && !props.dataItem.manual_task_completed);


        return (
            <td>
                {shouldShowButton && (
                    <Button
                        className="button-standard button-submit button-no-left-margin"
                        iconClass="icon-edit"
                        onClick={() => handleSaveNotification(props.dataItem)}
                    >
                        Save
                    </Button>
                )}
            </td>
        );
    }

    useEffect(() => {
        refreshTable();
        const intervalCall = setInterval(() => {

            refreshTable();
        }, 20000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };
    }, [])

    const manualTaskRequiredCellRender = (props) => {
        return (
            <td>
                {props.dataItem[props.field] ? "YES" : "NO"}
            </td>
        );
    };



    return (
        <div className={"users-permissions-datagrid-container"}>
            {/*<LoadOverlay active={overlayActive} height={50} width={50}>*/}
            <Grid
                {...dataState}
                data={processedData}
                filterable={true}
                onDataStateChange={onDataStateChange}
                sortable={false}
                rowRender={rowRender}
                pageable={{
                    buttonCount: 4,
                    pageSizes: [5, 10, 25, 50, 100],
                }}
            >
                <Column field="notification_message" title="Notification Message" filterTitle={"message-filter"}/>
                <Column field="created_at" title="Created At" filterTitle={"date-filter"}/>
                {/*<Column field="manual_task_required" title="Manual task Required" filterable={false}/>*/}
                <Column field="manual_task_required" title="Manual Task Required" cell={manualTaskRequiredCellRender} filterable={false}/>
                <Column cell={manualTaskCompletedDropdownRender} title="Manual Task Completed" filterable={false}
                        width={"221px"}/>
                <Column cell={actionsRender} filterable={false} width={"121px"}/>
            </Grid>
            {/*</LoadOverlay>*/}
        </div>
    )
};

export default NotificationDashboard;