import {
  DEMOGRAPHIC_HOME_VALUE,
  DEMOGRAPHIC_INCOME,
  FILE_VALIDATE_API,
  GEO_TYPES,
  NAICS_CODES,
  PPK_DISTRICT_SEGMENTS,
  PPK_SEGMENTS, SEGMENT_DETAILS_API, SEGMENT_ID_PLACE_HOLDER,
  SEGMENTS, SEGMENTS_SUPPRESSION,
  SUCCESS_STATUS,
} from "../api/constants";
import {
  semcastAxios,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "../Utils/Common";

export const getPpkSegments = async (payload, groupType) => {
  try {
    let response = await semcastAxios.get(PPK_SEGMENTS + "/" + payload, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.data.status === SUCCESS_STATUS) {
      return { ...response.data, groupType } || { data: [], groupType };
    }
  } catch {
    return { data: [], groupType };
  }
};

export const getDemographicIncome = async () => {
  try {
    let response = await semcastAxios.get(DEMOGRAPHIC_INCOME, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.data.status === SUCCESS_STATUS) {
      return response.data || { data: [] };
    }
  } catch {
    return { data: [] };
  }
};

export const getDemographicHomeValue = async () => {
  try {
    let response = await semcastAxios.get(DEMOGRAPHIC_HOME_VALUE, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.data.status === SUCCESS_STATUS) {
      return response.data || { data: [] };
    }
  } catch {
    return { data: [] };
  }
};

export const patchSegmentName = async (payload, id) => {
  let url = SEGMENTS + "/" + id + "/rename";
  try {
    let response = await semcastAxios.patch(url, payload, {
      withCredentials: true,
    });
    if (response.data.status === SUCCESS_STATUS) {
      showSuccessToastMessage("Segment Updates In Progress ...");
      // FD: 10698 localStorage.clear();
      // return response.data.id || null;
      return response;
    }
  } catch (error) {
    console.log(error);
    // showErrorToastMessage("Something Went Wrong!!!, Please Try Again Later");
    showErrorToastMessage(error.response.data.data);
    return null;
  }
};

export const putSegments = async (payload, id, saveAs) => {
  console.log("Research putSegments");
  let segments = {};
  let url = "";
  if (saveAs) {
    url = SEGMENTS + '/' + id + '/saveas';
  } else {
    url = SEGMENTS + '/' + id;
  }
  payload.segment_design = { ...payload.segment_design, ...segments };
  try {
    let response = await semcastAxios.put(url, payload, {
      withCredentials: true,
    });
    if (response.data.status === SUCCESS_STATUS) {
      showSuccessToastMessage("Segment Updates In Progress ...");
      // FD: 10698 localStorage.clear();
      // return response.data.id || null;
      return response;
    }
  } catch(error) {
    console.log(error);
    // showErrorToastMessage("Something Went Wrong!!!, Please Try Again Later");
    showErrorToastMessage(error.response.data.data);
    return null;
  }
};

export const postSegments = async (payload) => {
  // const keys = Object.keys(localStorage);
  let segments = {};
  // for (let key of keys) {
  //   if (key !== "segment_name") {
  //     const value = localStorage.getItem(key);
  //     if (value && value !== "") {
  //       let formattedKey = key.replace("Most", "");
  //       formattedKey = formattedKey.replace("Check", "");
  //       segments[formattedKey] = value.split();
  //     }
  //   }
  // }
  console.log("Research postSegments URL=", SEGMENTS);

  payload.segment_design = { ...payload.segment_design, ...segments };
  console.log("Research postSegments payload=", JSON.stringify(payload));
  try {
    let response = await semcastAxios.post(SEGMENTS, payload, {
      withCredentials: true,
    });
    if (response.data.status === SUCCESS_STATUS) {
      showSuccessToastMessage("Segment Creation In Progress ...");
      // FD: 10698 localStorage.clear();
      // return response.data.id || null;
      return response;
    }
  } catch(error) {
    // showErrorToastMessage("Something Went Wrong!!!, Please Try Again Later");
    showErrorToastMessage(error.response.data.data);
    return null;
  }
};

export const getSegments = async (id) => {
  console.log("Research getSegments");
  try {
    let response = await semcastAxios.get(`${SEGMENTS}/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.data.status === SUCCESS_STATUS) {
      return response.data || { data: {} };
    }
  } catch {
    return { data: {} };
  }
};

export const getNaicsCodes = async (params) => {
  try {
    console.log("Research NAICS_CODES");
    let response = await semcastAxios.get(NAICS_CODES, {
      params,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.data.status === SUCCESS_STATUS) {
      return response.data || { data: [] };
    }
  } catch {
    return { data: [] };
  }
};
export const getGeoTypes = async (id, params) => {
  try {
    let response = await semcastAxios.get(`${GEO_TYPES}/${id}`, {
      params,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.data.status === SUCCESS_STATUS) {
      return response.data || { data: [] };
    }
  } catch {
    return { data: [] };
  }
};

export const validateFile = async (bodyFormData) => {
  try {
    const response = await semcastAxios.post(FILE_VALIDATE_API, bodyFormData, {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.data.status === SUCCESS_STATUS) {
      return response.data || { data: {} };
    }
  } catch (e) {
    return { data: {} };
  }
};

export const getSuppressions = async () => {
  try {
    console.log("Research GetSuppressions =", SEGMENTS_SUPPRESSION);
    let response = await semcastAxios.get(SEGMENTS_SUPPRESSION, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.data.status === SUCCESS_STATUS) {
      return response.data || { data: [] };
    }
  } catch {
    return { data: [] };
  }
};

export const getPpkDistrictSegments = async (params) => {
  try {
    let response = await semcastAxios.get(PPK_DISTRICT_SEGMENTS, {
      params,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.data.status === SUCCESS_STATUS) {
      return { ...response.data } || { data: [] };
    }
  } catch {
    return { data: [] };
  }
};
