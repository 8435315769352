import React, {useEffect, useState} from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import {socialMatrixConfig} from "../../../config/socialMatrixConfig";
import {filterBy} from "@progress/kendo-data-query";
import "./SocialMatrix.css";

const SocialMatrix = (props) => {
    const [socialMatrixOptions, setSocialMatrixOptions] = useState([]);
    const [filteredSocialMatrixOptions, setFilteredSocialMatrixOptions] = useState([]);
    const [value, setValue] = useState([]);

    useEffect(() => {
        let arr = [];
        socialMatrixConfig.forEach(option => {
            arr.push({text: option.segment_name+" - "+option.description, value:option.demographics_column});
        })
        setSocialMatrixOptions(arr);
    }, []);

    useEffect(() => {
        let arr = [];
        props.audienceState.social_matrix.forEach(optionValue => {
            socialMatrixOptions.find(option => {
                if (option.value === optionValue) {
                    arr.push(option);
                }
                ;
            });
        });
        setValue(arr);
    }, [props.audienceState]);

    const onSocialMatrixFilterChange = (e) => {
        const filter = e.filter;
        const allData = socialMatrixOptions.slice();
        const newData = filter.value.length >= 1 ? filterBy(allData, filter) : allData;
        if (newData.length > 0) {
            setFilteredSocialMatrixOptions(newData);
        }
    };

    const onSocialMatrixChange = (e) => {
        let valArray = e.target.value.map((e) => {
            return {text: e.text.split('-')[0], value: e.value};
        });

        setValue(valArray);
        let values = [];
        e.value.forEach((val) => {
            values.push(val.value);
        });
        // props.audienceState.social_matrix = values;
        props.handleAudienceState(prevState => {
            return {...prevState, social_matrix: values}}
        );
    };

    return (
        <div>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={filteredSocialMatrixOptions.length > 0 ? filteredSocialMatrixOptions : socialMatrixOptions}
                    autoClose={false}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    filterable={true}
                    value={value}
                    onFilterChange={onSocialMatrixFilterChange}
                    onChange={onSocialMatrixChange}
                    size="large"
                    // style={{width:"32rem"}}
                />
            </div>
        </div>
    );
}
export default SocialMatrix;
