import "../../Pages/Dashboard.css";
import React, {useEffect, useState} from "react";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage, sortItemsByField} from "../../../Utils/Common";
import {
    ASCENDING_ORDER, DESCENDING_ORDER,
    NOT_A_MEMBER,
    PASSWORD_RESET_API,
    SUCCESS_STATUS,
    USER_DELETE_API,
    USER_ID_PLACE_HOLDER, USER_REINSTATE_API,
    USER_SUSPEND_API,
    USERS_BASIC_INFO_UPDATE_API,
    USERS_GROUPS_EDIT_PUT_API
} from "../../../api/constants";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    loadAccessibleGroups,
    loadAccessibleRoles,
    loadUserEditGroupsNRoles, loadUserPermissions,
    loadUsers
} from "../../../actions/UserActions";
import {hasAtLeastOneInAnyGroup, UsersHelpers} from "../../../helpers/UsersHelpers";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Button} from "@progress/kendo-react-buttons";
import "./UsersPage.css";
import { Field, Form, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import EditUser from "./EditUser";
import {Label} from "@progress/kendo-react-labels";
import axios from "axios";

import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";


function UsersPage() {
    let userHelpers = new UsersHelpers();
    // let topLevelGroupId = ''
    const [topLevelGroupId, setTopLevelGroupId] = useState("");
    const [tableSortOrder, setTableSortOrder] = useState(ASCENDING_ORDER);

    const [showUserSection, setShowUserSection] = useState(true);
    const [userEditSectionData, setUserEditSectionData] = useState(null);
    const [users, setUsers] = useState([]);

    const [dropdownUsers, setDropdownUsers] = useState([]);
    const [dropdownUsersValueObj, setDropdownUsersValueObj] = useState({index: 0, user: "Select user", userObj: {}});
    const [dropdownLoading, setDropdownLoading] = useState(false);

    const [accessibleGroups, setAccessibleGroups] = useState([]);
    const [accessibleRoles, setAccessibleRoles] = useState([]);

    const [canCreateUser, setCanCreateUser] = useState(false);
    const [canViewUser, setCanViewUser] = useState(false);
    const [canEditUser, setCanEditUser] = useState(false);
    const [canDeleteUser, setCanDeleteUser] = useState(false);

    const [sectionLoader, setSectionLoader] = useState(false)
    const [pageLoader, setPageLoader] = useState(false)
    const [showHideActions, setShowHideActions] = useState({})

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const [alwaysTrue, setAlwaysTrue] = useState(true);

    const onChangeDropDown = e => {

        if(e.target.value) {
            setDropdownUsersValueObj(e.target.value);
            loadEditUserData(e.target.value.userObj).then(() => {
                // console.log("line 75 userGroupsEditFormik.values:", userGroupsEditFormik.values);
                setAlwaysTrue(!alwaysTrue);
            });
        }

        // setDropdownUsersValueObj(e.target.value);

        // console.log("In onChangeDropDown. User object hopefully:", e);
        // console.log("In onChangeDropDown. User object hopefully:", e.target);

        // loadEditUserData(e.target.value.userObj);
    }

    useEffect(() => {
        console.log("line 89:", userGroupsEditFormik.values);
        console.log("line 90:", userGroupsEditFormik.values.groupNRoles);
    }, [alwaysTrue])


    const userCreationFormik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            group_id: '',
            role_id: ''
        },
        // We used Yup here.
        validationSchema: Yup.object().shape({
            first_name: Yup.string().required('First Name is required'),
            last_name: Yup.string().required('Last Name is required'),
            group_id: Yup.string().required("Group is required"),
            role_id: Yup.string().required("Role is required"),
            email: Yup.string()
                .email("Invalid email address")
                .required("Email Address is required")
        }),
        onSubmit: values => {
        },
    })

    const userEditFormik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
        },
        // We used Yup here.
        validationSchema: Yup.object().shape({
            first_name: Yup.string().required('First Name is required'),
            last_name: Yup.string().required('Last Name is required'),
            email: Yup.string()
                .email("Invalid email address")
                .required("Please enter an email address")
        }),
        enableReinitialize: true,
        onSubmit: values => {
        },
    })

    const userGroupsEditFormik = useFormik({
        initialValues: {},
        // We used Yup here.
        validationSchema: Yup.object().shape({
            groupNRoles: Yup.array().of(
                Yup.object().shape({
                    group_id: Yup.string(),
                    group_name: Yup.string(),
                    role_id: Yup.string(),
                    founding_assignment: Yup.boolean(),
                    user_id: Yup.string()
                })
            )
        }),
        onSubmit: values => {
        },
    })

    // const testFormik = useFormik( {
    //     initialValues: {},
    //     // We used Yup here.
    //     validationSchema: Yup.object().shape({
    //         groupNRoles: Yup.array().of(
    //             Yup.object().shape({
    //                 group_id: Yup.string(),
    //                 group_name: Yup.string(),
    //                 role_id: Yup.string(),
    //                 founding_assignment: Yup.boolean(),
    //                 user_id: Yup.string()
    //             })
    //         )
    //     }),
    //     onSubmit: values => {
    //     },
    // })

    //     <DropDownList className={"dropdownField"}  textField={"user"} value={dropdownUsersValueObj}
    // onChange={onChangeDropDown} data={dropdownUsers} />


    const usersAccGroupsAccRolesPermissions = async () => {
        setPageLoader(true);
        setDropdownLoading(true);
        try {
            const values = await Promise.allSettled([loadUsers(), loadAccessibleGroups(), loadAccessibleRoles(), loadUserPermissions()])
            try {
                console.log("SETTING USERS TO:", values[0].value);
                setUsers(values[0].value);
                let tempArr = [];
                values[0].value.forEach((user,index) => {
                    tempArr.push({index: index, user: user.first_name + " " + user.last_name, userObj: user});
                    // console.log("line 114:", user.first_name, user.last_name);
                })
                if(dropdownUsersValueObj.user === "") {
                    setDropdownUsersValueObj({index: 0, user: "Select user", userObj: {}});
                    // loadEditUserData(tempArr[0].userObj);
                }
                console.log("value of temp arr:", tempArr);
                setDropdownUsers(tempArr);
            } catch (e) {
                console.log(e)
            } finally {
                setDropdownLoading(false);
            }


            try {
                ((groups) => {
                    console.log("SETTING ACCESS GROUPS TO:", groups);
                    setAccessibleGroups(groups)

                    console.log("groups from old users page:", groups);

                    if (groups) {
                        setTopLevelGroupId(groups.find(({top_level_group}) => top_level_group)?.id);
                        let groupNRoles = groups.map(group => {
                            return {
                                group_id: group.id,
                                group_name: group.group_name,
                                role_id: '',
                                founding_assignment: false
                            }
                        })
                        sortItemsByField(groupNRoles, 'group_name', tableSortOrder)
                        userGroupsEditFormik.setValues({groupNRoles})
                    }
                })(values[1].value)
            } catch (e) {
                console.log(e)
            }
            try {
                //roles loaded
                ((roles) => {
                    /*if (roles)
                        roles.splice(0, 0, {id: '', role_name: NOT_A_MEMBER})*/
                    console.log("SETTING ACCESS ROLES TO:", roles);
                    setAccessibleRoles(roles);
                })(values[2].value)
            } catch (e) {
                console.log(e)
            }

            try {
                //permissions loaded
                ((permissionsObj) => {
                    console.log("PERMISSIONS OBJECT in UsersPage.js", permissionsObj);
                    console.log("type of PERMISSIONS OBJECT:", typeof(permissionsObj));
                    setCanCreateUser(hasAtLeastOneInAnyGroup(['CREATE_USER_OWN_GROUP', 'CREATE_USER_CHILD_GROUP'], permissionsObj))
                    setCanViewUser(hasAtLeastOneInAnyGroup(['VIEW_USER_OWN_GROUP', 'VIEW_USER_CHILD_GROUP'], permissionsObj))
                    setCanEditUser(hasAtLeastOneInAnyGroup(['UPDATE_USER_OWN_GROUP', 'UPDATE_USER_CHILD_GROUP'], permissionsObj))
                    setCanDeleteUser(hasAtLeastOneInAnyGroup(['DELETE_USER_OWN_GROUP', 'DELETE_USER_CHILD_GROUP'], permissionsObj))
                })(values[3].value)
            } catch (e) {
                console.log(e)
            }
            setPageLoader(false)


            try {
                // console.log("User groups edit formik:", userGroupsEditFormik);
                //
                // console.log("GroupsNRoles Array:", userGroupsEditFormik.values.groupNRoles);
            } catch(e) {
                // console.log("Error while trying to print Groups and Roles", e);
            }



            /*loadUsers().then(users => {
                setUsers(users)
            })
            loadAccessibleGroups().then(groups => {
                setAccessibleGroups(groups)
                if (groups) {
                    let groupNRoles = groups.map(group => {
                        return {
                            group_id: group.id,
                            group_name: group.group_name,
                            role_id: '',
                            founding_assignment: false
                        }
                    })
                    userGroupsEditFormik.setValues({groupNRoles})
                }
            })
            loadAccessibleRoles().then(roles => {
                setAccessibleRoles(roles)
            })*/
        } catch (e) {
            console.log(e)
            setPageLoader(false)
        }
    }

    useEffect( () => {

        usersAccGroupsAccRolesPermissions().then(r => "");

        console.log("user edit section data from useEffect:", userEditSectionData);

        console.log("user edit formik values:", userEditFormik.values);

    }, [userEditSectionData])

    useEffect(() => {
        setComboBoxData(dropdownUsers.slice());
    }, [dropdownUsers])


    const loadEditUserData = async (user) => {

        console.log("In loadEditUserData. User:", user);

        try {
            if (user) {
                setSectionLoader(true)
                setShowUserSection(true)
                //pre-populate basic profile fields
                setUserEditFormikData(user)
                //pre-populate the user groups roles in edit form
                let groupNRolesIds = await loadUserEditGroupsNRoles(user.id);

                console.log("groupNRolesIds:", groupNRolesIds);
                console.log("[...userGroupsEditFormik.values.groupNRoles]:", [...userGroupsEditFormik.values.groupNRoles]);
                console.log("userGroupsEditFormik.values.groupNRoles:", userGroupsEditFormik.values.groupNRoles);

                if (groupNRolesIds) {
                    try {
                        let groupIdRoleIdDict = groupNRolesIds.reduce((a, x) => ({...a, [x.group_id]: x}), {});

                        console.log("groupIdRoleIdDict:", groupIdRoleIdDict);

                        let groupNRoles = [...userGroupsEditFormik.values.groupNRoles].map((obj) => ({
                            ...obj,
                            role_id: groupIdRoleIdDict[obj.group_id].role_id,
                            user_id: user.id,
                            founding_assignment: groupIdRoleIdDict[obj.group_id].founding_assignment || false
                        }))

                        console.log("line 319 groupNRoles:", groupNRoles);

                        await userGroupsEditFormik.setValues({groupNRoles});
                    } catch (e) {
                        console.log("error occurred while while pre populating existing groups & roles of a user in user edit " + e)
                    }
                }
            }
        } finally {
            setSectionLoader(false)
        }

    }

    const createUser = async (formik) => {
        try {
            setPageLoader(true)
            // await formik.submitForm()
            if (formik.isValid) {
                let response = await semcastAxios.post(USERS_BASIC_INFO_UPDATE_API, {
                    ...formik.values
                }, {
                    withCredentials: true,
                });
                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage(`${formik?.values?.first_name} ${formik?.values?.last_name}  has been created`)
                    loadUsers().then(users => setUsers(users))


                    setShowUserSection(false);
                    await userCreationFormik.resetForm();
                    defaultValuesForDropdowns();

                    await usersAccGroupsAccRolesPermissions();
                }
            }
        } catch (error) {
            console.log("this is the error:", error);
            showErrorToastMessage(`${formik?.values?.first_name} ${formik?.values?.last_name}  has not created`)
        } finally {
            setPageLoader(false)
        }
    }

    const deleteUser = async (e, user) => {
        try {
            setPageLoader(true)
            e.stopPropagation()
            if (user) {
                let response = await semcastAxios.delete(USER_DELETE_API + user.id, {
                    withCredentials: true,
                });
                if (response.data.status === SUCCESS_STATUS) {
                    let userIndex = users.findIndex(u => u.id === user.id);
                    if (userIndex > -1) {
                        users.splice(userIndex, 1)
                        setUsers(users)
                    }
                    showSuccessToastMessage(`${user.first_name} ${user.last_name} has been Deleted`);


                    setShowUserSection(false);
                    await userCreationFormik.resetForm();
                    defaultValuesForDropdowns();
                    await usersAccGroupsAccRolesPermissions();
                    setDropdownUsersValueObj({index: 0, user: "Select user", userObj: {}});
                }
            }
        } catch (error) {
            // console.log(error)
            showErrorToastMessage(`${user.first_name} ${user.last_name} has not Deleted.`)
        } finally {
            setPageLoader(false)
        }
    }

    const handleResetPassword = (e, user) => {
        // e.preventDefault();

        console.log("user email", user.email);
        axios
            .post("https://audiencedesigner.com/v2/attributor/password-reset", {
                email: user.email,
            })
            .then(function (response) {
                console.log(response);
                if (response.data.status === "success") {
                    showSuccessToastMessage(
                        `An email has been sent to ${user.email} with further instructions.`
                    );
                }
            })
            .catch(function (error) {
                showErrorToastMessage(`An email could not be sent to ${user.email}. Please try again or contact support@semcasting.com for further assistance.`);
            });
    };

    const resetPassword = async (e, user) => {

        // console.log("in resetPassword, user.id:", user.id);
        // console.log("in resetPassword, user.first_name:", user.first_name);
        // console.log("in resetPassword, user.last_name:", user.last_name);

        try {
            setSectionLoader(true);
            e.stopPropagation()
            if (user) {
                let response = await semcastAxios.patch(PASSWORD_RESET_API + user.id, {}, {
                    withCredentials: true,
                });
                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage(`${user.first_name} ${user.last_name}  Reset Password Requested.`)
                }
            }
        } catch (error) {
            console.log(error);
            showErrorToastMessage(`${user.first_name} ${user.last_name}  Reset Password Requested Fail.`)
        } finally {
            setSectionLoader(false);
            setPageLoader(false);
        }
    }

    const suspendOrReinstateUser = async (e, user) => {
        const performingAction = user?.deactivated_at ? "Reinstated" : "Suspended";

        console.log("Performing action:", performingAction);

        try {
            setPageLoader(true)
            e.stopPropagation()
            let response = await semcastAxios.patch((performingAction === "Reinstated" ? USER_REINSTATE_API : USER_SUSPEND_API).replace(USER_ID_PLACE_HOLDER, user.id), {}, {
                withCredentials: true,
            });
            if (response.data.status === SUCCESS_STATUS) {
                updateUserDataAfterOperations(response?.data?.data, user.id)
                showSuccessToastMessage(`${user.first_name} ${user.last_name}  has been ${performingAction}.`)
            }

        } catch (error) {
            // console.log(error)
            showErrorToastMessage(`${user.first_name} ${user.last_name} has not been ${performingAction}.`)
        } finally {
            setPageLoader(false)
        }
    }

    const updateUserBasicData = async (formik) => {
        console.log("update user basic data", formik.values);
        // console.log("valid formik?", formik.isValid);
        // console.log("userEditSectionData", userEditSectionData);

        try {
            setSectionLoader(true)
            // await formik.submitForm()
            if (formik.isValid) {
                let userUpdatedData = formik.values
                let response = await semcastAxios.patch(USERS_BASIC_INFO_UPDATE_API + '/' + formik.values.id, userUpdatedData, {
                    withCredentials: true,
                });
                if (response.data.status === SUCCESS_STATUS) {
                    updateUserDataAfterOperations(response?.data?.data, formik.values.id)
                    showSuccessToastMessage(formik.values.first_name + " " + formik.values.last_name + " has been updated.")
                }
            }
        } catch (error) {
            console.log(error)
            showErrorToastMessage(formik.values.first_name + " " + formik.values.last_name + " not updated.")
        } finally {
            setSectionLoader(false)
        }

        // try {
        //     setSectionLoader(true)
        //     // await formik.submitForm()
        //     if (formik.isValid) {
        //         let userUpdatedData = {...userEditSectionData, ...formik.values}
        //         let response = await semcastAxios.patch(USERS_BASIC_INFO_UPDATE_API + '/' + userEditSectionData.id, userUpdatedData, {
        //             withCredentials: true,
        //         });
        //         if (response.data.status === SUCCESS_STATUS) {
        //             updateUserDataAfterOperations(response?.data?.data, userEditSectionData.id)
        //             showSuccessToastMessage(userEditSectionData.first_name + " " + userEditSectionData.last_name + " has been updated.")
        //         }
        //     }
        // } catch (error) {
        //     // console.log(error)
        //     showErrorToastMessage(userEditSectionData.first_name + " " + userEditSectionData.last_name + " not updated.")
        // } finally {
        //     setSectionLoader(false)
        // }
    }

    const updateUserGroupsData = async (formik) => {
        console.log("update user groups data", formik.values);


        try {
            setSectionLoader(true)
            await userGroupsEditFormik.submitForm();

            console.log("userGroupsEditFormik.isValid:", userGroupsEditFormik.isValid);

            console.log("userGroupsEditFormik:", userGroupsEditFormik.values.groupNRoles);

            console.log("formik:", formik);
            console.log("formik:", formik.values.groupNRoles);


            if (userGroupsEditFormik.isValid) {
                let payload = {assignments: userGroupsEditFormik.values.groupNRoles}

                console.log("payload:", payload);

                let response = await semcastAxios.put(USERS_GROUPS_EDIT_PUT_API, payload, {
                    withCredentials: true,
                });

                console.log("response from USERS_GROUPS_EDIT_PUT_API call:", response);

                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage(userEditSectionData.first_name + " " + userEditSectionData.last_name + " has been updated.")
                }
            }
        } catch (error) {
            console.log("User groups/roles not updated. Error:", error);
            showErrorToastMessage(userEditSectionData.first_name + " " + userEditSectionData.last_name + " Groups/Roles not updated.")
        } finally {
            setSectionLoader(false)
        }
    }

    const setUserEditFormikData = (user) => {
        setShowUserSection(true)
        if (user) {

            console.log("user to be set as userEditSectionData:", user);

            setUserEditSectionData(user)
            userEditFormik.setValues(user)
        } else {
            setUserEditSectionData(null)
            defaultValuesForDropdowns();
            userEditFormik.resetForm()
        }
    }

    const updateUserDataAfterOperations = (data, userId) => {
        try {
            setUserEditSectionData(data)
            // update user data in user list
            let userIndex = users.findIndex(u => u.id === userId);
            users[userIndex] = data;
            setUsers(users)
        } catch (e) {
        }
    }

    const usersList = users?.length > 0
        && users.map((user, i) => {
            return (
                <li className={`dz-chat-user nav-link ${user.id === userEditSectionData?.id ? "active" : null}`} key={i}
                    onClick={() => loadEditUserData(user)}>
                    <div className="d-flex bd-highlight" onMouseOver={(e) => {
                        setShowHideActions({[user?.id]: true})
                    }} onMouseLeave={() => {
                        setShowHideActions({[user?.id]: false})
                    }}>
                        {/*<div className="img_cont">
                            <img
                                src="/src/Admin"
                                className="rounded-circle user_img"
                                alt=""
                            />
                            <span className="online_icon"/>
                        </div>*/}
                        <div className="user_info">
                            <span>{user?.first_name} {user?.last_name}</span>
                        </div>
                        {(showHideActions[user?.id]) && <div className="actions">
                            {canEditUser && <i title={user?.deactivated_at ? "Reinstate User" : "Suspend User"}
                                               className={`fa ${user?.deactivated_at ? 'fa-unlock' : 'fa-lock'}`}
                                               aria-hidden="true"
                                               onClick={(e) => suspendOrReinstateUser(e, user)}></i>}
                            {canEditUser && <i title="Reset Password" className="fa fa-key" aria-hidden="true"
                                               onClick={(e) => resetPassword(e, user)}></i>}
                            {canDeleteUser && <i title="Delete User" className="fa fa-user-times" aria-hidden="true"
                                                 onClick={(e) => deleteUser(e, user)}></i>}
                        </div>
                        }
                    </div>
                </li>
            )
        }, this);

    // console.log("users list", usersList);
    // console.log("users", users);

    const groupsList = accessibleGroups?.length > 0
        && accessibleGroups.map((item, i) => {
            return (
                <option key={i} value={item.id}>{item.group_name}</option>
            )
        }, this);

    const rolesList = (groupId) => {
        return groupId && accessibleRoles?.length > 0
            && accessibleRoles.map((item, i) => {
                let isPickerForTopLevelGroup = (topLevelGroupId === groupId)
                let addOption = (userHelpers.isAdministrator(item) && isPickerForTopLevelGroup)
                    || userHelpers.isDefaultRole(item)
                    || userHelpers.roleBelongsToGroup(item, groupId)
                //console.log(groupId, item.id, addOption, isPickerForTopLevelGroup)
                return (
                    addOption && <option className={"select-dropdown-option"} key={i} value={item.id}>{item.role_name}</option>
                )
            }, this);
    }

    const userGroupsEdit = userGroupsEditFormik.values.groupNRoles?.length > 0
        && userGroupsEditFormik.values.groupNRoles.map((item, i) => {
            return (
                <tr key={i}>
                    <td className='group_name'>
                        <b>{userGroupsEditFormik.values.groupNRoles[i].group_name}</b>
                    </td>
                    <td>
                        <select name={`groupNRoles.${i}.role_id`}
                                className="form-control form-control-custom form-select select-dropdown"
                                // className="select-dropdown"
                                disabled={userGroupsEditFormik.values.groupNRoles[i].founding_assignment}
                                value={userGroupsEditFormik.values.groupNRoles[i].role_id}
                                onBlur={userGroupsEditFormik.handleBlur}
                                onChange={userGroupsEditFormik.handleChange}>
                            <option key="NOT_A_MEMBER" value="">{NOT_A_MEMBER}</option>
                            {rolesList(userGroupsEditFormik.values.groupNRoles[i].group_id)}</select>
                    </td>
                </tr>
            )
        }, this);

    const defaultValuesForDropdowns = (setDefaultGroup = true, setDefaultRole = true) => {
        try {
            if (setDefaultGroup && accessibleGroups?.length)
                userCreationFormik.setFieldValue('group_id', '' + accessibleGroups[0].id)
            if (setDefaultRole && accessibleRoles?.length)
                userCreationFormik.setFieldValue('role_id', '1')
        } catch (e) {
        }
    }
    const userBasicProfileFields = (formik) => {
        return (
            <>
                <div className="permissionsUserFirstName ftpField permUserFieldDiv">
                    <div className="field-wrapper-formik">
                            <Label className={"field-label"}>First Name</Label>
                            <input
                                type="text"
                                className="text-field-formik text-field"
                                // placeholder="First Name *"
                                name="first_name"
                                value={formik.values.first_name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.first_name && formik.errors.first_name &&
                                <span
                                    className="validation-error">{formik.errors.first_name}</span>}
                    </div>
                </div>
                <div className="permissionsUserLastName ftpField permUserFieldDiv">
                    <div className="field-wrapper-formik">
                        <Label className={"field-label"}>Last Name</Label>
                        <input
                            type="text"
                            className="text-field-formik text-field"
                            // placeholder="Last Name *"
                            name="last_name"
                            value={formik.values.last_name}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.last_name && formik.errors.last_name &&
                            <span
                                className="validation-error">{formik.errors.last_name}</span>}
                    </div>
                </div>
                <div className="permissionsUserEmail ftpField permUserFieldDiv">
                    <div className="field-wrapper-formik">
                        <Label className={"field-label"} >Email Address</Label>
                        <input
                            type="email"
                            className="text-field-formik text-field"
                            // placeholder="Email Address *"
                            name="email"
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.email && formik.errors.email &&
                            <span
                                className="validation-error">{formik.errors.email}</span>}
                    </div>
                </div>
            </>
        )
    }

    const filterData = filter => {
        const data = dropdownUsers.slice();
        return filterBy(data, filter);
    };

    const filterChange = event => {
        setComboBoxData(filterData(event.filter));
    };

    const [comboBoxData, setComboBoxData] = useState(dropdownUsers.slice());
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [comboBoxValue, setComboBoxValue] = useState(null);

    // useEffect(() => {
    //     setComboBoxData(dropdownUsers.slice())
    // })

    const deleteConfirmed = (e) => {
        setShowDeleteConfirmationDialog(false);
        deleteUser(e, userEditFormik.values);
    }

    const deleteDenied = () => {
        setShowDeleteConfirmationDialog(false);
    }

    return (
        // <LoadOverlay active={pageLoader}>
        <div>
            <LoadOverlay active={dropdownLoading} height={50} width={50}>

            {showDeleteConfirmationDialog && (

                <div className={"admin-users-delete-dialog-container"}>
                    <Dialog title={"Delete User"} onClose={deleteDenied} className={"admin-users-delete-dialog dialog-standard"}>
                        <p className={"admin-users-delete-dialog-message-text"}>Are you sure you want to delete <strong>{userEditSectionData?.first_name} {userEditSectionData?.last_name}</strong>?</p>

                        <DialogActionsBar>
                            <Button className="button-standard button-white" onClick={deleteDenied}>No</Button>
                            <Button className="button-standard button-submit" onClick={deleteConfirmed}>Yes</Button>
                        </DialogActionsBar>
                    </Dialog>
                </div>

            )}



            <div className={"userHeaderDiv section-card section-card-inner-margins"}>

                {/*<h4>Users</h4>*/}
                {/*{canViewUser && <ul className="contacts">*/}
                {/*    {usersList}*/}
                {/*</ul>*/}
                {/*}*/}

                {/*{canCreateUser && <button*/}
                {/*    type="button"*/}
                {/*    className="mb-3 btn btn-rounded btn-primary bnt-block  custom-btn"*/}
                {/*    onClick={() => {*/}
                {/*        setUserEditFormikData(null)*/}
                {/*    }}>*/}
                {/*    New User*/}
                {/*</button>}*/}
                {/*<h4>Users</h4>*/}
                {/*{canViewUser && <ul className="contacts">*/}
                {/*    {usersList}*/}
                {/*</ul>*/}
                {/*}*/}

                <div className={"userDropdownDiv"}>
                    {/*<h4 className={"header-secondary"}>Users</h4>*/}
                    {/*<LoadOverlay active={dropdownLoading} height={50} width={50}>*/}

                    {/*<DropDownList className={"dropdown-standard"}  textField={"user"} value={dropdownUsersValueObj}*/}
                    {/*              onChange={onChangeDropDown} data={dropdownUsers} disabled={!canViewUser}/>*/}

                    {/*</LoadOverlay>*/}

                    <ComboBox className={"combobox-standard"} textField={"user"} data={comboBoxData} placeholder={"Search or Select User"}
                               disabled={!canViewUser} filterable={true} onFilterChange={filterChange} onChange={onChangeDropDown}
                                >
                    </ComboBox>


                </div>

                <div className={"userManagerHeaderDiv"}>
                    {/*<h3 className={"userManagerText header-primary"}>User Manager</h3>*/}
                    {/*<legend className={'k-form-legend spacingLine'}></legend>*/}

                    {/*<h6 className={"userManagerSecondaryText admin-permissions-below-manager-text"}>Select a user to edit or create a new user</h6>*/}

                    {/*<p>Select a user to edit or create a new user*/}
                    {/*    /!*<a href="javascript:void(0)" className="create-new"*!/*/}
                    {/*    /!*   onClick={() => setUserEditFormikData(null)}> Create*!/*/}
                    {/*    /!*    a New User</a>*!/*/}
                    {/*</p>*/}
                </div>

                <div className={"newUserButtonDiv"}>
                    <Button className={"button-standard button-submit"} type={"button"} disabled={dropdownLoading || !canCreateUser} onClick={() => {
                        setDropdownUsersValueObj({index: 0, user: "Select user", userObj: {}});
                        setUserEditFormikData(null);
                    }}>
                        New User
                    </Button>
                </div>

            </div>
            </LoadOverlay>



            {/*<div className={"editCreateUserSection"}>*/}

            {/*    <EditUser userEditSectionData={userEditSectionData} users={users} accessibleGroups={accessibleGroups}*/}
            {/*              rolesList={rolesList} firstName={firstName}*/}
            {/*                setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} email={email} setEmail={setEmail}/>*/}
            {/*</div>*/}



            {showUserSection && <div className={"editCreateUserSection section-card"}>

                <LoadOverlay active={pageLoader}>
                    <div className="row">
                        {/*<div className="col-md-3">*/}

                        {/*Old User manager header that is within the User section*/}
                        {/*<div className={"userHeaderDiv"}>*/}

                        {/*    /!*<h4>Users</h4>*!/*/}
                        {/*    /!*{canViewUser && <ul className="contacts">*!/*/}
                        {/*    /!*    {usersList}*!/*/}
                        {/*    /!*</ul>*!/*/}
                        {/*    /!*}*!/*/}


                        {/*    /!*{canCreateUser && <button*!/*/}
                        {/*    /!*    type="button"*!/*/}
                        {/*    /!*    className="mb-3 btn btn-rounded btn-primary bnt-block  custom-btn"*!/*/}
                        {/*    /!*    onClick={() => {*!/*/}
                        {/*    /!*        setUserEditFormikData(null)*!/*/}
                        {/*    /!*    }}>*!/*/}
                        {/*    /!*    New User*!/*/}
                        {/*    /!*</button>}*!/*/}
                        {/*    /!*<h4>Users</h4>*!/*/}
                        {/*    /!*{canViewUser && <ul className="contacts">*!/*/}
                        {/*    /!*    {usersList}*!/*/}
                        {/*    /!*</ul>*!/*/}
                        {/*    /!*}*!/*/}
                        {/*    */}
                        {/*    <div className={"userDropdownDiv"}>*/}
                        {/*        <h4>Users</h4>*/}

                        {/*        <DropDownList className={"dropdownField"}  textField={"user"} value={dropdownUsersValueObj}*/}
                        {/*                      onChange={onChangeDropDown} data={dropdownUsers} />*/}
                        {/*    </div>*/}

                        {/*    <div className={"userManagerHeaderDiv"}>*/}
                        {/*        <h3>User Manager</h3>*/}
                        {/*        <p>Click a User in the left hand column to edit or*/}
                        {/*            <a href="javascript:void(0)" className="create-new"*/}
                        {/*               onClick={() => setUserEditFormikData(null)}> Create*/}
                        {/*                a New User</a></p>*/}
                        {/*    </div>*/}

                        {/*    <div className={"newUserButtonDiv"}>*/}
                        {/*        <Button className={"newUserButton"} type={"button"} onClick={() => setUserEditFormikData(null)}>*/}
                        {/*            New User*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*{!showUserSection &&*/}
                        {/*    <div className="col-md-9 p-0">*/}
                        {/*        <h3>User Manager</h3>*/}
                        {/*        <p>Click a User in the left hand column to edit or*/}
                        {/*            <a href="javascript:void(0)" className="create-new"*/}
                        {/*               onClick={() => setUserEditFormikData(null)}> Create*/}
                        {/*                a New User</a></p>*/}
                        {/*    </div>*/}
                        {/*}*/}

                        {showUserSection && !userEditSectionData && <div className="col-md-9 createNewUserDiv">
                            <label className={"field-label-large"}>CREATE NEW USER</label>
                            <legend className={'k-form-legend spacingLine'}></legend>
                            <div className="col-md-12 mt-3 p-0">
                                <div className="row permissionsCreateNewUser">
                                    {userBasicProfileFields(userCreationFormik)}
                                    <div className="col-6">
                                        <div className="form-group">
                                            <Label className={"field-label"}>Group</Label>
                                            <select className="form-control-custom form-select select-dropdown"
                                                    name="group_id"
                                                    value={userCreationFormik.values.group_id}
                                                    onBlur={userCreationFormik.handleBlur}
                                                    onChange={userCreationFormik.handleChange}>
                                                {groupsList}
                                            </select>
                                            {userCreationFormik.touched.group_id && userCreationFormik.errors.group_id &&
                                                <span
                                                    className="validation-error">{userCreationFormik.errors.group_id}</span>}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <Label className={"field-label"} >Role</Label>
                                            <select className="form-control-custom form-select select-dropdown"
                                                    name="role_id"
                                                    value={userCreationFormik.values.role_id}
                                                    onBlur={userCreationFormik.handleBlur}
                                                    onChange={userCreationFormik.handleChange}>
                                                {rolesList(userCreationFormik.values.group_id)}
                                            </select>
                                            {userCreationFormik.touched.role_id && userCreationFormik.errors.role_id &&
                                                <span
                                                    className="validation-error">{userCreationFormik.errors.role_id}</span>}
                                        </div>
                                    </div>
                                    <div className="col-12 float-right">
                                        <button
                                            type="button"
                                            className="button-standard button-white"
                                            onClick={async () => {
                                                setShowUserSection(false)
                                                await userCreationFormik.resetForm();
                                                defaultValuesForDropdowns();


                                                // setComboBoxValue({index: 0, user: "Search or Select User", userObj: {}});
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        {canCreateUser && <button
                                            type="button"
                                            className="button-standard button-submit"
                                            onClick={(e) => {

                                                // console.log("userCreationFormik:", userCreationFormik);


                                                createUser(userCreationFormik);
                                            }}
                                        >
                                            Save
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </div>}




                        {showUserSection && userEditSectionData && <div className="col-md-9 editUserDiv">
                            {/*<h3 className={"primary-header-text"}>{userEditSectionData?.first_name} {userEditSectionData?.last_name} <label*/}
                            {/*    className="sub-text status-label status-label-error">{userEditSectionData?.deactivated_at ? 'Suspended' : ''}</label>*/}
                            {/*</h3>*/}
                            <span className={"permissions-user-name-container"}>
                                <h3 className={"header-primary"}>{userEditSectionData?.first_name} {userEditSectionData?.last_name} </h3>
                                {userEditSectionData?.deactivated_at &&
                                    <div className={"permissions-user-suspended-container"}>
                                        <h6 className={"header-tertiary permissions-user-suspended-text"}> Suspended </h6>
                                    </div>
                                }
                            </span>
                            <legend className={'k-form-legend spacingLine'}></legend>
                            {/*<h4>Edit User Data</h4>*/}
                            <div className="col-md-12 mt-3 p-0">
                                <div className="permissionsUserFields">
                                    {userBasicProfileFields(userEditFormik)}
                                    {/*<div className="col-12 float-right">*/}
                                    {/*    {canEditUser && <button*/}
                                    {/*        type="button"*/}
                                    {/*        className="btn btn-rounded btn-primary bnt-block custom-btn "*/}
                                    {/*        onClick={() => updateUserBasicData(userEditFormik)}*/}
                                    {/*    >*/}
                                    {/*        Save*/}
                                    {/*    </button>}*/}
                                    {/*    <button*/}
                                    {/*        type="button"*/}
                                    {/*        className="ml-2 btn btn-rounded btn-primary bnt-block custom-btn "*/}
                                    {/*        onClick={() => setShowUserSection(false)}*/}
                                    {/*    >*/}
                                    {/*        Cancel*/}
                                    {/*    </button>*/}

                                    {/*</div>*/}
                                </div>
                            </div>
                            {/*<h4>Groups & Roles</h4>*/}
                            <LoadOverlay active={sectionLoader}>

                                <div className="col-md-12 mt-3 p-0">
                                    <div className="row permissionsUserForm">
                                        <table className="col-md-9  table">
                                            <thead>
                                            <tr>
                                                <th scope="col" className={"profile-page-lower-section-label"}>Group<i onClick={() => {
                                                    let order = tableSortOrder === ASCENDING_ORDER ? DESCENDING_ORDER : ASCENDING_ORDER
                                                    sortItemsByField(userGroupsEditFormik.values.groupNRoles, 'group_name', order)
                                                    setTableSortOrder(order)
                                                }}
                                                                        className={`fa ${tableSortOrder === ASCENDING_ORDER ? 'fa-sort-up sort-icon-up' : 'fa-sort-down sort-icon-down'} sort-icon`}></i>
                                                </th>
                                                <th scope="col" className={"profile-page-lower-section-label"}>Roles</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {userGroupsEdit}
                                            </tbody>
                                        </table>

                                        {/*<div className="col-12 float-right">*/}
                                        {/*    {canEditUser && <button*/}
                                        {/*        type="button"*/}
                                        {/*        className="btn btn-rounded btn-primary bnt-block custom-btn"*/}
                                        {/*        onClick={() => updateUserGroupsData()}*/}
                                        {/*    >*/}
                                        {/*        Save*/}
                                        {/*    </button>}*/}
                                        {/*    <button*/}
                                        {/*        type="button"*/}
                                        {/*        className="ml-2 btn btn-rounded btn-primary bnt-block custom-btn"*/}
                                        {/*        onClick={() => setShowUserSection(false)}*/}
                                        {/*    >*/}
                                        {/*        Cancel*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}


                                        <div className={"ftpFormSection thirdRow"}>
                                            {canDeleteUser &&
                                            <Button className={"button-standard button-red"} type={'button'}
                                                    disabled={false} onClick={(e) => {

                                                        setShowDeleteConfirmationDialog(true);



                                                        // deleteUser(e, userEditFormik.values);

                                                    }
                                            }>
                                                Delete User
                                            </Button>}
                                            {canEditUser &&
                                            <Button className={"button-standard button-white suspend-user-button"}  type={'button'}
                                                    disabled={false} onClick={(e) => {
                                                        // suspendOrReinstateUser(e, userEditFormik.values);

                                                        suspendOrReinstateUser(e, userEditSectionData);
                                                    }
                                            }>
                                                {userEditSectionData?.deactivated_at ? "Reinstate User" : "Suspend User"}
                                            </Button>}
                                            {canEditUser &&
                                            <Button className={"button-standard button-white reset-pw-button"} type={'button'}
                                                    disabled={false} onClick={async (e) => {
                                                        e.preventDefault();

                                                        // console.log("in reset password", userEditSectionData?.email);
                                                        //
                                                        // console.log("In reset password userEditFormik:", userEditFormik.values.id);


                                                        // await resetPassword(e, userEditFormik.values);

                                                        await handleResetPassword(e, userEditFormik.values);


                                                        // axios
                                                        //     .post("https://audiencedesigner.com/v2/attributor/password-reset", {
                                                        //         email: email,
                                                        //     })
                                                        //     .then(function (response) {
                                                        //         console.log(response);
                                                        //         if (response.data.status === "success") {
                                                        //             showSuccessToastMessage(
                                                        //                 `An email has been sent to ${email} with further instructions. If you can't find it be sure and check your spam folder.`
                                                        //             );
                                                        //         }
                                                        //     })
                                                        //     .catch(function (error) {
                                                        //         showErrorToastMessage(`An email could not be sent to ${email}. Please try again or contact support@semcasting.com for further assistance.`);
                                                        //     });
                                                }
                                            }>
                                                Reset Password
                                            </Button>}
                                            <Button className={"button-standard button-white"} type={'button'}
                                                    disabled={false} onClick={() => {
                                                        setShowUserSection(false);
                                                        setDropdownUsersValueObj({index: 0, user: "Select user", userObj: {}});
                                                    }
                                            }>
                                                Cancel
                                            </Button>
                                            <Button className={"button-standard button-submit"} type={'button'}
                                                    disabled={false} onClick={() => {
                                                        updateUserBasicData(userEditFormik)
                                                        .then(() => {
                                                            updateUserGroupsData(userGroupsEditFormik);
                                                        })
                                                            .then(() => {

                                                            })
                                                    }
                                            }>
                                                Save
                                            </Button>
                                        </div>




                                    </div>
                                </div>

                            </LoadOverlay>
                        </div>}
                    </div>
                </LoadOverlay>


            </div>}



            {/*<div*/}
            {/*    className="tab-pane"*/}
            {/*    id="flamingo"*/}
            {/*    role="tabpanel"*/}
            {/*    aria-labelledby="flamingo-tab">*/}
            {/*    <div className="card mt-3 p-3">*/}
            {/*        <div className="card-body">*/}
                        {/*<LoadOverlay active={pageLoader}>*/}
                        {/*    <div className="row">*/}
                        {/*        /!*<div className="col-md-3">*!/*/}


                        {/*        /!*Old User manager header that is within the User section*!/*/}
                        {/*        /!*<div className={"userHeaderDiv"}>*!/*/}

                        {/*        /!*    /!*<h4>Users</h4>*!/*!/*/}
                        {/*        /!*    /!*{canViewUser && <ul className="contacts">*!/*!/*/}
                        {/*        /!*    /!*    {usersList}*!/*!/*/}
                        {/*        /!*    /!*</ul>*!/*!/*/}
                        {/*        /!*    /!*}*!/*!/*/}


                        {/*        /!*    /!*{canCreateUser && <button*!/*!/*/}
                        {/*        /!*    /!*    type="button"*!/*!/*/}
                        {/*        /!*    /!*    className="mb-3 btn btn-rounded btn-primary bnt-block  custom-btn"*!/*!/*/}
                        {/*        /!*    /!*    onClick={() => {*!/*!/*/}
                        {/*        /!*    /!*        setUserEditFormikData(null)*!/*!/*/}
                        {/*        /!*    /!*    }}>*!/*!/*/}
                        {/*        /!*    /!*    New User*!/*!/*/}
                        {/*        /!*    /!*</button>}*!/*!/*/}
                        {/*        /!*    /!*<h4>Users</h4>*!/*!/*/}
                        {/*        /!*    /!*{canViewUser && <ul className="contacts">*!/*!/*/}
                        {/*        /!*    /!*    {usersList}*!/*!/*/}
                        {/*        /!*    /!*</ul>*!/*!/*/}
                        {/*        /!*    /!*}*!/*!/*/}
                        {/*        /!*    *!/*/}
                        {/*        /!*    <div className={"userDropdownDiv"}>*!/*/}
                        {/*        /!*        <h4>Users</h4>*!/*/}

                        {/*        /!*        <DropDownList className={"dropdownField"}  textField={"user"} value={dropdownUsersValueObj}*!/*/}
                        {/*        /!*                      onChange={onChangeDropDown} data={dropdownUsers} />*!/*/}
                        {/*        /!*    </div>*!/*/}

                        {/*        /!*    <div className={"userManagerHeaderDiv"}>*!/*/}
                        {/*        /!*        <h3>User Manager</h3>*!/*/}
                        {/*        /!*        <p>Click a User in the left hand column to edit or*!/*/}
                        {/*        /!*            <a href="javascript:void(0)" className="create-new"*!/*/}
                        {/*        /!*               onClick={() => setUserEditFormikData(null)}> Create*!/*/}
                        {/*        /!*                a New User</a></p>*!/*/}
                        {/*        /!*    </div>*!/*/}

                        {/*        /!*    <div className={"newUserButtonDiv"}>*!/*/}
                        {/*        /!*        <Button className={"newUserButton"} type={"button"} onClick={() => setUserEditFormikData(null)}>*!/*/}
                        {/*        /!*            New User*!/*/}
                        {/*        /!*        </Button>*!/*/}
                        {/*        /!*    </div>*!/*/}
                        {/*        /!*</div>*!/*/}




                        {/*        {!showUserSection &&*/}
                        {/*            <div className="col-md-9 p-0">*/}
                        {/*                <h3>User Manager</h3>*/}
                        {/*                <p>Click a User in the left hand column to edit or*/}
                        {/*                    <a href="javascript:void(0)" className="create-new"*/}
                        {/*                       onClick={() => setUserEditFormikData(null)}> Create*/}
                        {/*                        a New User</a></p>*/}
                        {/*            </div>*/}
                        {/*        }*/}
                        {/*        {showUserSection && !userEditSectionData && <div className="col-md-9">*/}
                        {/*            <h4>Create New User</h4>*/}
                        {/*            <div className="col-md-12 mt-3 p-0">*/}
                        {/*                <div className="row">*/}
                        {/*                    {userBasicProfileFields(userCreationFormik)}*/}
                        {/*                    <div className="col-6">*/}
                        {/*                        <div className="form-group">*/}
                        {/*                            <select className="form-control form-control-custom form-select"*/}
                        {/*                                    name="group_id"*/}
                        {/*                                    value={userCreationFormik.values.group_id}*/}
                        {/*                                    onBlur={userCreationFormik.handleBlur}*/}
                        {/*                                    onChange={userCreationFormik.handleChange}>*/}
                        {/*                                {groupsList}*/}
                        {/*                            </select>*/}
                        {/*                            {userCreationFormik.touched.group_id && userCreationFormik.errors.group_id &&*/}
                        {/*                                <span*/}
                        {/*                                    className="validation-error">{userCreationFormik.errors.group_id}</span>}*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="col-6">*/}
                        {/*                        <div className="form-group">*/}
                        {/*                            <select className="form-control form-control-custom form-select"*/}
                        {/*                                    name="role_id"*/}
                        {/*                                    value={userCreationFormik.values.role_id}*/}
                        {/*                                    onBlur={userCreationFormik.handleBlur}*/}
                        {/*                                    onChange={userCreationFormik.handleChange}>*/}
                        {/*                                {rolesList(userCreationFormik.values.group_id)}*/}
                        {/*                            </select>*/}
                        {/*                            {userCreationFormik.touched.role_id && userCreationFormik.errors.role_id &&*/}
                        {/*                                <span*/}
                        {/*                                    className="validation-error">{userCreationFormik.errors.role_id}</span>}*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="col-12 float-right">*/}
                        {/*                        {canCreateUser && <button*/}
                        {/*                            type="button"*/}
                        {/*                            className="btn btn-rounded btn-primary bnt-block custom-btn "*/}
                        {/*                            onClick={() => createUser(userCreationFormik)}*/}
                        {/*                        >*/}
                        {/*                            Save*/}
                        {/*                        </button>}*/}
                        {/*                        <button*/}
                        {/*                            type="button"*/}
                        {/*                            className="ml-2 btn btn-rounded btn-primary bnt-block custom-btn "*/}
                        {/*                            onClick={async () => {*/}
                        {/*                                setShowUserSection(false)*/}
                        {/*                                await userCreationFormik.resetForm()*/}
                        {/*                                defaultValuesForDropdowns();*/}
                        {/*                            }}*/}
                        {/*                        >*/}
                        {/*                            Cancel*/}
                        {/*                        </button>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>}*/}
                        {/*        {showUserSection && userEditSectionData && <div className="col-md-9">*/}
                        {/*            <h3>{userEditSectionData?.first_name} {userEditSectionData?.last_name} <label*/}
                        {/*                className="sub-text status-label status-label-error">{userEditSectionData?.deactivated_at ? 'Suspended' : ''}</label>*/}
                        {/*            </h3>*/}
                        {/*            <h4>Edit User Data</h4>*/}
                        {/*            <div className="col-md-12 mt-3 p-0">*/}
                        {/*                <div className="row">*/}
                        {/*                    {userBasicProfileFields(userEditFormik)}*/}
                        {/*                    <div className="col-12 float-right">*/}
                        {/*                        {canEditUser && <button*/}
                        {/*                            type="button"*/}
                        {/*                            className="btn btn-rounded btn-primary bnt-block custom-btn "*/}
                        {/*                            onClick={() => updateUserBasicData(userEditFormik)}*/}
                        {/*                        >*/}
                        {/*                            Save*/}
                        {/*                        </button>}*/}
                        {/*                        <button*/}
                        {/*                            type="button"*/}
                        {/*                            className="ml-2 btn btn-rounded btn-primary bnt-block custom-btn "*/}
                        {/*                            onClick={() => setShowUserSection(false)}*/}
                        {/*                        >*/}
                        {/*                            Cancel*/}
                        {/*                        </button>*/}

                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <h4>Groups & Roles</h4>*/}
                        {/*            <LoadOverlay active={sectionLoader}>*/}

                        {/*                <div className="col-md-12 mt-3 p-0">*/}
                        {/*                    <div className="row">*/}
                        {/*                        <table className="col-md-9  table">*/}
                        {/*                            <thead>*/}
                        {/*                            <tr>*/}
                        {/*                                <th scope="col">Group<i onClick={() => {*/}
                        {/*                                    let order = tableSortOrder === ASCENDING_ORDER ? DESCENDING_ORDER : ASCENDING_ORDER*/}
                        {/*                                    sortItemsByField(userGroupsEditFormik.values.groupNRoles, 'group_name', order)*/}
                        {/*                                    setTableSortOrder(order)*/}
                        {/*                                }}*/}
                        {/*                                                        className={`fa ${tableSortOrder === ASCENDING_ORDER ? 'fa-sort-up sort-icon-up' : 'fa-sort-down sort-icon-down'} sort-icon`}></i>*/}
                        {/*                                </th>*/}
                        {/*                                <th scope="col">Roles</th>*/}
                        {/*                            </tr>*/}
                        {/*                            </thead>*/}
                        {/*                            <tbody>*/}
                        {/*                            {userGroupsEdit}*/}
                        {/*                            </tbody>*/}
                        {/*                        </table>*/}

                        {/*                        <div className="col-12 float-right">*/}
                        {/*                            {canEditUser && <button*/}
                        {/*                                type="button"*/}
                        {/*                                className="btn btn-rounded btn-primary bnt-block custom-btn"*/}
                        {/*                                onClick={() => updateUserGroupsData()}*/}
                        {/*                            >*/}
                        {/*                                Save*/}
                        {/*                            </button>}*/}
                        {/*                            <button*/}
                        {/*                                type="button"*/}
                        {/*                                className="ml-2 btn btn-rounded btn-primary bnt-block custom-btn"*/}
                        {/*                                onClick={() => setShowUserSection(false)}*/}
                        {/*                            >*/}
                        {/*                                Cancel*/}
                        {/*                            </button>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}

                        {/*            </LoadOverlay>*/}
                        {/*        </div>}*/}
                        {/*    </div>*/}
                        {/*</LoadOverlay>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
        // </LoadOverlay>
    );
}

export default UsersPage;
