import React, {useEffect} from "react";
import "./FAQPage.css";
import {PanelBar, PanelBarItem, TabStrip, TabStripTab} from "@progress/kendo-react-layout";
import create_audiences from  "../Components/Segmentation/temporaryresources/faqimages/create_audiences.png";
import activate_segment from  "../Components/Segmentation/temporaryresources/faqimages/activate_segment.png";
import onboarding_filetypedropdown from "../Components/Segmentation/temporaryresources/faqimages/onboarding_filetypedropdown.jpeg"
import address from "../Components/Segmentation/temporaryresources/docs/address.csv";
import haddress from "../Components/Segmentation/temporaryresources/docs/address.csv";
import companyAddress from "../Components/Segmentation/temporaryresources/docs/company_name_and_address.txt";
import email from "../Components/Segmentation/temporaryresources/docs/email.csv";
import sha256Email from "../Components/Segmentation/temporaryresources/docs/sha256_email.csv";
import sha1Email from "../Components/Segmentation/temporaryresources/docs/sha1_email.csv";
import md5Email from "../Components/Segmentation/temporaryresources/docs/md5_email.csv";
import xandrId from "../Components/Segmentation/temporaryresources/docs/xandr_id.csv";
import deviceId from "../Components/Segmentation/temporaryresources/docs/device_id.csv";
import npiId from "../Components/Segmentation/temporaryresources/docs/npi_id.csv";
import impressions from "../Components/Segmentation/temporaryresources/docs/impressions.csv";
import usPhone from "../Components/Segmentation/temporaryresources/docs/us_phone.csv";
import domainName from "../Components/Segmentation/temporaryresources/docs/domain_name.csv";
import company_name_and_naics from "../Components/Segmentation/temporaryresources/docs/company_name_and_naics.csv";
import zip5 from "../Components/Segmentation/temporaryresources/docs/zip5.txt";
import zip9 from "../Components/Segmentation/temporaryresources/docs/zip9.txt";
import zip11 from "../Components/Segmentation/temporaryresources/docs/zip11.txt";
import datatrust from "../Components/Segmentation/temporaryresources/docs/datatrust_id.csv";
import freewheel from "../Components/Segmentation/temporaryresources/docs/freewheel_ids.csv";
import latLong from "../Components/Segmentation/temporaryresources/docs/lat_long.csv";
import purplelab from "../Components/Segmentation/temporaryresources/docs/PurpleLabID.csv";
import voterId from "../Components/Segmentation/temporaryresources/docs/voter_id.csv";
import L2DataVoter from "../Components/Segmentation/temporaryresources/docs/l2_data_voter_id.csv";
import healthwise from "../Components/Segmentation/temporaryresources/docs/healthwise_keys.csv";
import i360_id from "../Components/Segmentation/temporaryresources/docs/i360_id.csv";
import mriid from "../Components/Segmentation/temporaryresources/docs/mri_id.csv";
import pdi from "../Components/Segmentation/temporaryresources/docs/pdi_id.csv";
import internetKeywords from "../Components/Segmentation/temporaryresources/docs/internet_keywords.csv";
import internetSites from "../Components/Segmentation/temporaryresources/docs/internet_sites.csv";
import audienceacuity from "../Components/Segmentation/temporaryresources/docs/audienceacuity_id.csv";
import semcastingid from "../Components/Segmentation/temporaryresources/docs/persistent_id.csv";
import pubmatic from "../Components/Segmentation/temporaryresources/docs/pubmatic_id.csv";

const FAQPage = (loggedIn) => {
    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };
    useEffect(() => {

    }, []);

    return (
        <React.Fragment>
        <div className={"faq-page-container"}>
            <TabStrip
                selected={selected}
                onSelect={handleSelect}
                tabPosition={"left"}
            >
                <TabStripTab title="Onboarding">
                    <div className="panelbar-wrapper">
                        <PanelBar expandMode={"single"}>
                            <PanelBarItem expanded={true} title="I uploaded a list of 1,756 addresses, but ADS matched 15,248 Households. Why did this happen?">
                                <div>
                                    <p>
                                        There could be several reasons for this discrepancy. One possibility is that the match happened on a broader scale, such as ZIP+9, which could result in multiple addresses being associated with a single input address. It’s important to verify the match key used.
                                    </p>
                                    <p>
                                        Sometimes, ADS might default to a less specific match type, like ZIP code, if it doesn’t recognize the address column properly. It's recommended to double-check the file before uploading to ensure the address column is correctly formatted, and you can also contact support for assistance.
                                    </p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title={"Best Practices for Onboarding First Party Data?"} className={"question"}>
                                <div>
                                    <ul>
                                        <li>Remove:</li>
                                            <ul>
                                                <li>Double Spaces.</li>
                                                <li>Symbols such as , . - / # : .</li>
                                                <li>Extra blank rows.</li>
                                                <li>Duplicates (the system does remove them but your overall MR will show higher in the platform if you remove them prior to loading).</li>
                                                <li>If your file has both a mailing and residential address listed, delete the mailing address and match to the residential address only. Typically mailing addresses contain PO Boxes which are not matchable.</li>
                                            </ul>
                                        <li>Tips/Tricks:</li>
                                            <ul>
                                                <li>We don't match on PO boxes, first/last names, or locations outside of US.</li>
                                                <li>Please make sure 0 is added to the front of specific zip codes in your file or they will not match (Save file as TXT).</li>
                                                <li>If you are matching on addresses, make sure address, city, state and zip are in 4 separate fields.</li>
                                                <li>Address 2 should be in a separate field to ensure higher match rates. The system does not recognize unit, suite, apt etc. in the Address 1 line.</li>
                                                <li>
                                                    If you have emails and addresses in the same file, you can either split them into individual files to match on each, or you will need to onboard the same file twice, choose address the first time and email the second (see screenshot below). This is the same for all other data (phone numbers, company names, etc.).
                                                    <div className={"image-wrapper"}>
                                                        <img
                                                            src={onboarding_filetypedropdown}
                                                            alt="image"
                                                        />
                                                    </div>
                                                </li>
                                            </ul>
                                    </ul>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="What file types do you accept?">
                                <div>
                                    <p>File extension needs to be one of the following: csv, tsv, txt, and log.</p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="What file formats do you accept?">
                                <div className={"accepted-file-format-list"}>
                                    <p>
                                        <p>Below are the sample formats we accept in Onboarding > Create Segment > My Computer. Click on the link to download a sample file.</p>
                                        <div>
                                            <ul>
                                                <li><a href={address} download="address.csv">Address</a></li>
                                                <li><a href={audienceacuity} download="audienceacuity_id.csv">Audience Acuity ID</a></li>
                                                <li><a href={companyAddress} download="company_name_address.csv">Company Name and Address</a></li>
                                                <li><a href={company_name_and_naics} download="company_name_naics.csv">Company Name and Naics Code</a></li>
                                                <li><a href={latLong} download="lat_long.csv">Coordinates</a></li>
                                                <li><a href={datatrust} download="datatrust_id.csv">DataTrust ID</a></li>
                                                <li><a href={deviceId} download="device_id.csv">Device ID</a></li>
                                                <li><a href={domainName} download="domain_name.csv">Domain Name</a></li>
                                                <li><a href={email} download="email.csv">Email</a></li>
                                                <li><a href={freewheel} download="freewheel_ids.csv">FreeWheel ID</a></li>
                                                <li><a href={haddress} download="humana_address.csv">H* Address</a></li>
                                                <li><a href={healthwise} download="healthwise_keys.csv">HealthWise ID</a></li>
                                                <li><a href={i360_id} download="i360_id.csv">i360 ID</a></li>
                                                <li><a href={internetKeywords} download="internet_keyword.csv">Internet Keyword</a></li>
                                                <li><a href={internetSites} download="internet_sites.csv">Internet Sites</a></li>
                                                <li><a href={impressions} download="impressions.csv">IP and Timestamp</a></li>
                                                <li><a href={L2DataVoter} download="l2data_voter_id.csv">L2Data Voter ID</a></li>
                                                <li><a href={md5Email} download="md5_email.csv">Md5 Email</a></li>
                                                <li><a href={mriid} download="mri_id.csv">MRI ID</a></li>
                                                <li><a href={npiId} download="npi_id.csv">NPI ID</a></li>
                                                <li><a href={pdi} download="pdi_id.csv">PDI ID</a></li>
                                                <li><a href={usPhone} download="us_phone.csv">Phone Number</a></li>
                                                <li><a href={pubmatic} download="pubmatic_id.csv">PubMatic ID</a></li>
                                                <li><a href={purplelab} download="purple-md5.csv">Purple Md5 ID</a></li>
                                                <li><a href={semcastingid} download="persistent_id.csv">Semcasting ID</a></li>
                                                <li><a href={sha1Email} download="sha1_email.csv">Sha1 Email</a></li>
                                                <li><a href={sha256Email} download="sha256_email.csv">Sha256 Email</a></li>
                                                <li><a href={voterId} download="voter_id.csv">Voter ID</a></li>
                                                <li><a href={xandrId} download="xandr_id.csv">Xandr ID</a></li>
                                                <li><a href={zip5} download="zip5.csv">5 Digit Zip Codes</a></li>
                                                <li><a href={zip9} download="zip9.csv">9 Digit Zip Codes</a></li>
                                                <li><a href={zip11} download="zip11.csv">11 Digit Zip Codes</a></li>
                                            </ul>
                                        </div>
                                    </p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="How do I upload my 1st Party Data into the platform?">
                                <div>
                                    <p>You can either upload directly from your computer or through an FTP or S3 server.</p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="What is the max file size for onboarding?">
                                <div>
                                    <p>2GB.</p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="Is there a minimum file size for onboarding and matching?">
                                <div>
                                    <p>Consumer Records need to have 1,000 unique records.</p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="When merging files, what is the difference between common and distinct?">
                                <div>
                                    <p>Common will merge the identical records in each segment, ie AND statement.</p>
                                    <p>Distinct will merge all records into a new segment; ie OR statement.</p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="What Match Keys are available?">
                                <div>
                                    <p>We have over a dozen match keys available on our platform. These include: Zip Code, Address, Company name and address, Email, NPI, IP and Timestamp, Phone Number, Domain Name, NAICS code, Keywords, and various IDs.</p>
                                </div>
                            </PanelBarItem>
                        </PanelBar>
                    </div>
                </TabStripTab>
                <TabStripTab title="Measurement">
                    <div className="panelbar-wrapper">
                        <PanelBar expandMode={"single"}>
                            <PanelBarItem expanded={true} title={"Does the impression tracker for real-time measurement work on every campaign?"} className={"question"}>
                                <div>
                                    <p>The impression tracker for real time measurement can NOT be used for Facebook campaigns or Linear TV campaigns.</p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="Why is there a discrepancy between the number of impressions from the exposure file and the DSP?">
                                <div>
                                    <p>
                                        When exporting an exposure file of those served an ad via measurement, the impression count may be lower than the total in the DSP, as the measurement exposure in ADS will only take into account the individuals in the audience served, where as the DSP may serve to ads to individuals seen at a different IP or location at that time that are not included in your audience.
                                    </p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="How do I get my unmatched records back?">
                                <div>
                                    <p>Step 1: If you haven't done so, setup a new FTP or S3 connection in Admin Settings > Connections Manager. You can also select a connection and in the Edit dialogue, test the connection to make sure it is still valid.</p>
                                    <p>Step 2: When doing an Onboarding,  under the About Audience section, select the designated FTP connection under Direct Non-Match File to Connection.</p>
                                    <p>Step 3: Once the Onboarding process is submitted and completed, the unmatched records will be exported into the designated FTP.</p>
                                </div>
                            </PanelBarItem>
                        </PanelBar>
                    </div>
                </TabStripTab>
                <TabStripTab title="Activation">
                    <div className="panelbar-wrapper">
                        <PanelBar expandMode={"single"}>
                            <PanelBarItem expanded={true} title={"Can you also please let us know what is the secret key or how do I get it for the StackAdapt activation?"} className={"question"}>
                                <div>
                                    <p>You will need to get the Secret Key from your contact at StackAdapt. Let them know you need an API Secret Key to use with Audience Designer. It should be a 32-character string.</p>
                                </div>
                            </PanelBarItem>
                        </PanelBar>
                    </div>
                </TabStripTab>
                <TabStripTab title="Settings/Accounts">
                    <div className="panelbar-wrapper">
                        <PanelBar expandMode={"single"}>
                            <PanelBarItem title="How do I add new users?" expanded={true}>
                                <div>
                                    <p>Only the account Admin has this permission. Go into Settings > Permissions > Users > + New.</p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="Is there a max number of users I can add to my account?">
                                <div>
                                    <p>There is no maximum number of users to add to the Admin account.</p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="What is a Group?">
                                <div>
                                    <p>A Group has many different organizational purposes. Think of it as a folder or file directory. Agencies can make separate Groups for their different clients or campaigns. Brands can make separate Groups divided by campaigns if they have multiple segments running at once.</p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="Can I create an account for my client to onboard their file directly into ADS?">
                                <div>
                                    <p>Yes. We also recommend that you create a new Group and assign this new user account to this specific Group to ensure that they are only seeing their own segments.</p>
                                    <p>To achieve this, create a new Group first, then, create a new User and assign the corresponding Role to this user in the desired Group.</p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title="Can I save segments to multiple groups?">
                                <div>
                                    <p>You can achieve this by repeating the segment creation process and saving it to different Groups by changing the value of "Save to Group".</p>
                                </div>
                            </PanelBarItem>
                        </PanelBar>
                    </div>
                </TabStripTab>
            </TabStrip>
        </div>
        </React.Fragment>
    );
};

export default FAQPage;