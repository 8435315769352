import React, {useEffect, useRef, useState} from "react";
import { Button } from "@progress/kendo-react-buttons";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";
import {useFormik} from "formik";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import './S3Settings.css';

import {
    deleteSettings,
    saveSettings,
    testExistingConnection,
    testNewConnection,
    updateSettings,
} from "../../../actions/OnBoardingActions";
import * as Yup from "yup";
import {S3_STRING} from "../../../api/constants";

const S3Settings = (props) => {
    const [pageLoader, setPageLoader] = useState(false);
    const [visible, setVisible] = useState(props.visible);

    const [disableSubmit, setDisableSubmit] = useState(false);

    const regions = [
        {text: "US East (N. Virginia)", id: "us-east-1"},
        {text: "US East (Ohio)", id: "us-east-2"},
        {text: "US West (N. California)", id: "us-west-1"},
        {text: "US West (Oregon)", id: "us-west-2"},
        {text: "Canada (Central)", id: "ca-central-1"},
        {text: "EU (Frankfurt)", id: "eu-central-1"},
        {text: "EU (Ireland)", id: "eu-west-1"},
        {text: "EU (London)", id: "eu-west-2"},
        {text: "EU (Paris)", id: "eu-west-3"},
        {text: "Asia Pacific (Mumbai)", id: "ap-south-1"},
        {text: "Asia Pacific (Tokyo)", id: "ap-northeast-1"},
        {text: "Asia Pacific (Seoul)", id: "ap-northeast-2"},
        {text: "China (Beijing)", id: "cn-north-1"},
        {text: "China (Ningxia)", id: "cn-northwest-1"},
        {text: "South America (Sao Paulo)", id: "sa-east-1"}
    ];
    const [selectedRegion, setSelectedRegion] = useState(() => {
        let retRegion = null;
        console.log("props.mode=", props.mode);
        if (props.mode === 'EDIT') {
            const matchingRegion = regions.find(region => region.id === props.s3Settings.host);
            retRegion = matchingRegion || regions[0];
            //return matchingRegion || regions[0];
        } else {
            retRegion = regions[0]; // Default to the first region if not in EDIT mode
        }
        console.log("retInitialRegion=", retRegion);
        return retRegion;
    });


    const selectedRegionChangedLocally = useRef(false);
    const handleChangeRegion = (event) => {
        console.log("handleChangeRegionEvent:", event);
        const selectedId = event.target.value.id;
        console.log("selectedId=", selectedId);
        console.log("regions=", regions);
        const matchingRegion = regions.find(region => region.id === selectedId);
        if (matchingRegion) {
            setSelectedRegion(matchingRegion);
            console.log("Region selected by user:", matchingRegion);
            selectedRegionChangedLocally.current = true;
        } else {
            setSelectedRegion(regions[0]); // Fallback to the first region if no matching region found
            console.log("No matching region found. Setting to default:", regions[0]);
        }

    };

    //console.log("Rendered with selectedRegion:", selectedRegion);

    const s3Formik = useFormik({
        initialValues: {
            connection_name: "",
            delimiter: "/",
            host: "us-east-1",
            user_name: "",
            password: "",
            starting_path: "",
            transfer_protocol: "s3"
        },
        validationSchema: Yup.object().shape({
            host:Yup.string().required("Region is required"),
            delimiter:Yup.string().required("Delimiter is required"),
            //connection_name: Yup.string().required("Connection  name is required"),
            connection_name: Yup.string().required("Connection name is required")
                .test('no-apostrophes', 'The string must not contain apostrophes', (value) => {
                    return value && !value.includes("'");
                }),
            user_name: Yup.string().required("Access key is required"),
            password: Yup.string().required("Secret key is required")
        }),
        onSubmit: () => {

        },
    });

    const createPayload = (s3Formik, selectedRegion) => {
        let startingPath = s3Formik.values.starting_path;

        // Ensure startingPath ends with delimiter if it has more than one part
        const delimiter = s3Formik.values.delimiter;
        if (delimiter && startingPath.split(delimiter).length > 1 && !startingPath.endsWith(delimiter)) {
            startingPath += delimiter;
        }

        return {
            connection_name: s3Formik.values.connection_name,
            host: selectedRegion.id,
            delimiter: delimiter,
            password: s3Formik.values.password,
            starting_path: startingPath,
            transfer_protocol: s3Formik.values.transfer_protocol,
            user_name: s3Formik.values.user_name
        };
    };
    const testS3Settings = async (editDialogSubmit= false) => {

        const payload = createPayload(s3Formik, selectedRegion);
        console.log("testS3SettingsPayload=",payload);
        setPageLoader(true);
        if (props.mode ==='EDIT') {
            testExistingConnection(payload,S3_STRING,editDialogSubmit).then((res) => {
                console.log(res);
                setPageLoader(false);
                return res;
            });
        } else {
            testNewConnection(payload,S3_STRING,editDialogSubmit).then((res) => {
                console.log(res);
                setPageLoader(false);
                return res;
            });
        }
    };

    const saveS3Settings = async () => {

        console.log("StartsaveS3Settings selectedRegion.id=", selectedRegion.id);

        const payload = createPayload(s3Formik, selectedRegion);
        console.log ("saveS3SettingsPayload=", payload);
        if (props.mode === 'EDIT') {
            await updateSettings(payload, props.s3Settings.id, s3Formik.values.connection_name).then(async (res) => {
                props.getAllSavedSettings();
                setPageLoader(false);
                s3Formik.resetForm();

                await props.refreshTable().then(() => {
                    setVisible(!visible);
                    props.setS3Visible(false);
                })

            })
        } else {
            await saveSettings(payload, s3Formik.values.connection_name).then(async (res) => {
                props.getAllSavedSettings();
                setPageLoader(false);
                s3Formik.resetForm();

                await props.refreshTable().then(() => {
                    setVisible(!visible);
                    props.setS3Visible(false);
                })

            });
        }
    };

    const removeS3Settings = async () => {
        setPageLoader(true);
        await deleteSettings(props.s3Settings.id, s3Formik.values.connection_name).then(async (res) => {
            setPageLoader(false);
            props.getAllSavedSettings();
            s3Formik.resetForm();

            await props.refreshTable().then(() => {
                setVisible(!visible);
                props.setS3Visible(false);
            })

        });
    };

    useEffect(() => {
        if (props.mode === 'EDIT') {
            s3Formik.values.connection_name = props.s3Settings.connection_name;
            s3Formik.values.host = props.s3Settings.host;
            s3Formik.values.password = props.s3Settings.password;
            s3Formik.values.starting_path = props.s3Settings.starting_path;
            s3Formik.values.transfer_protocol = props.s3Settings.transfer_protocol;
            s3Formik.values.user_name = props.s3Settings.user_name;
            s3Formik.values.delimiter = props.s3Settings.delimiter;
        }
    }, []);

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);


    const deleteConfirmed = (e) => {
        setShowDeleteConfirmationDialog(false);
        removeS3Settings().then(() => {
            props.refreshTable();
            props.setEnableEdit(false);
            props.setEnableDelete(false);
            // props.setSelectedConnections({});
        })
    }

    const deleteDenied = () => {
        setShowDeleteConfirmationDialog(false);
    }

    return (
        <div>
            {visible && (
                <LoadOverlay active={pageLoader}>

                {showDeleteConfirmationDialog && (
                    <div className={"admin-users-delete-dialog-container"}>
                        <Dialog title={"Delete S3 Setting"} onClose={deleteDenied} className={"admin-users-delete-dialog dialog-standard"}>
                            <p className={"admin-users-delete-dialog-message-text"}>Are you sure you want to delete settings for <strong>{props.s3Settings.connection_name}</strong>?</p>
                            <DialogActionsBar>
                                <Button className="button-standard button-white" onClick={deleteDenied}>No</Button>
                                <Button className="button-standard button-submit" onClick={deleteConfirmed}>Yes</Button>
                            </DialogActionsBar>
                        </Dialog>
                    </div>
                )}

                <Dialog title={props.createEdit === "Create" ? "New S3 Connection" : "Edit S3 Connection"} width={1000} closeIcon={false} className={"dialog-standard ftps3-dialog"}>
                    <div className={"required-fields-dialog-message"}>Fields marked with <span className="required-field">*</span> are required</div>

                    <GridLayout>
                        <GridLayoutItem row={1} col={1}>
                            <div style={{width:"100%"}}>
                                <Label
                                    className="field-label"
                                >
                                    Connection Name
                                    <span className="required-field">*</span>
                                </Label>
                                <Input
                                    name="connection_name"
                                    minLength={3}
                                    maxLength={64}
                                    defaultValue={props.mode==='EDIT' ? props.s3Settings.connection_name : ''}
                                    onChange={s3Formik.handleChange}
                                    onBlur={s3Formik.handleBlur}
                                    className={"text-field"}
                                    style={{borderColor: s3Formik.touched.connection_name && s3Formik.errors.connection_name ? "red" : ''}}>
                                </Input>
                                {s3Formik.touched.connection_name && s3Formik.errors.connection_name && (
                                    <span style={{color: "red"}}>{s3Formik.errors.connection_name}</span>
                                )}
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={2} col={1}>
                            <div style={{width:"100%", paddingTop:"1.8rem"}}>
                                <Label
                                    className="field-label"
                                    style={{width:"85%"}}
                                >
                                    Region
                                </Label>
                                <Label
                                    className="field-label"
                                    style={{width:"15%"}}
                                >
                                    Delimiter
                                    <span className="required-field">*</span>
                                </Label>
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={3} col={1}>
                            <div style={{width:"100%"}}>
                                <DropDownList
                                    data={regions}
                                    value={selectedRegion}
                                    dataItemKey="id"
                                    textField="text"
                                    style={{width: "82%", marginRight:"2.9%"}}
                                    className={"dropdown-standard"}
                                    onChange={handleChangeRegion}
                                />
                                <Input
                                    name="delimiter"
                                    maxLength={5}
                                    defaultValue={props.mode==='EDIT' ? props.s3Settings.delimiter : s3Formik.initialValues.delimiter}
                                    onChange={s3Formik.handleChange}
                                    onBlur={s3Formik.handleBlur}
                                    style={{width:"15%", borderColor: s3Formik.touched.delimiter && s3Formik.errors.delimiter ? "red" : ""}}
                                    className={"text-field"}
                                >
                                </Input>
                                <div style={{width:"100%"}}>
                                    {s3Formik.touched.delimiter && s3Formik.errors.delimiter && (
                                        <span style={{float:"right", color: "red", position: "relative", left: "-1.3rem"}}>
                                            {s3Formik.errors.delimiter}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={4} col={1}>
                            <div style={{width:"100%", paddingTop:"1.8rem"}}>
                                <Label
                                    className="field-label"
                                    style={{width:"100%"}}
                                >
                                    Starting Path
                                </Label>
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={5} col={1}>
                            <div style={{width:"100%"}}>
                                <Input
                                    name="starting_path"
                                    minLength={3}
                                    maxLength={64}
                                    defaultValue={props.mode==='EDIT' ? props.s3Settings.starting_path : ''}
                                    style={{width:"100%"}}
                                    className={"text-field"}
                                    onChange={s3Formik.handleChange}
                                    onBlur={s3Formik.handleBlur}
                                >
                                </Input>
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={6} col={1}>
                            <div style={{width:"100%", paddingTop:"1.8rem"}}>
                                <Label
                                    className="field-label"
                                    style={{width:"71%"}}
                                >
                                    Access Key
                                    <span className="required-field">*</span>
                                </Label>
                                <Input
                                    name="user_name"
                                    maxLength={64}
                                    defaultValue={props.mode==='EDIT' ? props.s3Settings.user_name : ''}
                                    onChange={s3Formik.handleChange}
                                    onBlur={s3Formik.handleBlur}
                                    style={{width:"100%", borderColor: s3Formik.touched.user_name && s3Formik.errors.user_name ? "red" : ""}}
                                    className={"text-field"}
                                >
                                </Input>
                                <div>
                                    {s3Formik.touched.user_name && s3Formik.errors.user_name && (
                                        <span style={{color: "red"}}>{s3Formik.errors.user_name}</span>
                                    )}
                                </div>
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={7} col={1}>
                            <div style={{width:"100%", paddingTop:"1.8rem"}}>
                                <Label
                                    className="field-label"
                                    style={{width:"71%"}}
                                >
                                    Secret Key
                                    <span className="required-field">*</span>
                                </Label>
                                <Input
                                    name="password"
                                    type="password"
                                    maxLength={64}
                                    defaultValue={props.mode==='EDIT' ? props.s3Settings.password : ''}
                                    onChange={s3Formik.handleChange}
                                    onBlur={s3Formik.handleBlur}
                                    style={{width:"100%", borderColor: s3Formik.touched.password && s3Formik.errors.password ? "red" : ""}}
                                    className={"text-field"}
                                >
                                </Input>
                                <div>
                                    {s3Formik.touched.password && s3Formik.errors.password && (
                                        <span style={{color: "red"}}>{s3Formik.errors.password}</span>
                                    )}
                                </div>
                            </div>
                        </GridLayoutItem>
                    </GridLayout>
                    <DialogActionsBar>
                        <div className={"dialog-buttons-container"}>
                            <div className={"dialog-buttons-left-div"}>
                                {props.mode === 'EDIT' && (<Button
                                    fillMode="link"
                                    className={"button-standard button-red dialog-button-delete"}
                                    onClick={ async () => {
                                        setShowDeleteConfirmationDialog(true);
                                    }}>
                                    Delete
                                </Button>)}
                                <Button
                                    className={"button-standard button-white test-con-button"}
                                    onClick={async () => {
                                        await s3Formik.submitForm();
                                        const errors = await s3Formik.validateForm();
                                        if (!Object.keys(errors).length) {
                                            await testS3Settings();
                                        }
                                    }}>
                                    Test Connection
                                </Button>
                            </div>
                            <div className={"dialog-buttons-right-div"}>
                                <Button
                                    className={"button-standard button-white"}
                                    onClick={() => {
                                        props.cancelS3Settings();
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    className={"button-standard button-submit"}
                                    disabled={disableSubmit}
                                    onClick={ async () => {
                                        await setDisableSubmit(true);
                                        await s3Formik.submitForm();
                                        const errors = await s3Formik.validateForm();

                                        if (!Object.keys(errors).length) {
                                            //const payload = { ...s3Formik.values };
                                            const payload = createPayload(s3Formik, selectedRegion);
                                            console.log("TestConnectionPayload=", payload);
                                            setPageLoader(true);
                                            if (props.mode ==='EDIT') {
                                                testExistingConnection(payload,S3_STRING,true).then(async (res) => {
                                                    setPageLoader(false);
                                                    if(res) {
                                                        await saveS3Settings().then(() => {
                                                            props.refreshTable();
                                                        });
                                                    }
                                                });
                                            } else {
                                                testNewConnection(payload,S3_STRING,true).then(async (res) => {
                                                    setPageLoader(false);
                                                    if(res) {
                                                        await saveS3Settings().then(() => {
                                                            props.refreshTable();
                                                        });
                                                    }
                                                });
                                            }
                                        }
                                        setDisableSubmit(false);
                                    }}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
                </LoadOverlay>
            )}
        </div>
    )
}

export default S3Settings;