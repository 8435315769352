export const aicpaSoc = require('./21972-312_SOC_NonCPA.png')
export const accessSupportMd = require('./accessSupportMd.png')
export const activateLogoBlack = require('./activateLogoBlack.png')
export const activateLogoBlackOLD = require('./activateLogoBlackOLD.png')
export const activateLogoWhite = require('./activateLogoWhite.png')
export const activateLogoWhiteOLD = require('./activateLogoWhiteOLD.png')
export const activation = require('./activation.png')
export const acxiomPng= require('./acxiom.png')
export const acxiomSvg = require('./acxiom.svg')
export const adformIconPng = require('./adform-icon.png')
export const adformLogoSvg = require('./adform-logo.svg')
export const adobeIconPng = require('./adobe-icon.png')
export const adobeLogoSvg = require('./adobe-logo.svg')
export const advContextualIconPng = require('./adv_contextual_icon.png')
export const advContextualIconSvg = require('./adv_contextual_icon.svg')
export const advContextualLogoDarkWebP = require('./ADVANCED CONTEXTUAL co-logo-dark-text-50.webp')
export const advContextualLogoVerticalPng = require('./Advanced Contextual logo vertical.png')
export const advContextualLogoVerticalSvg = require('./Advanced Contextual logo vertical.svg')
export const advContextualLogoDarkSvg = require('./ADVANCED-CONTEXTUAL-co-logo-dark-text-50.svg')
export const affinityRev1Svg = require('./Affinity_Rev_01.svg')
export const affinityRev2Svg = require('./Affinity_Rev_02.svg')
export const affinityRev3Svg = require('./Affinity_Rev_03.svg')
export const affinityRev4Svg = require('./Affinity_Rev_04.svg')
export const affinitySolutionsPng = require('./affinity_solutions.png')
export const affinitySolutionsSvg = require('./affinity_solutions.svg')
export const amazonIconPng = require('./amazon-icon.png')
export const amazonLogoSvg = require('./amazon-logo.svg')
export const ambioIconSvg = require('./ambio-icon.svg')
export const ambioLogoSvg = require('./ambio-logo.svg')
export const amnetLogoSvg = require('./amnet-logo.svg')
export const amnetIconPng = require('./amnet-icon.png')
export const amobeeIconPng = require('./amobee-icon.png')
export const amobeeLogoSvg = require('./amobee-logo.svg')
export const aolIconPng = require('./aol-icon.png')
export const aolLogoSvg = require('./aol-logo.svg')
export const attributionPng = require('./attribution.png')
export const audienceDesignerLogoPng = require('./Audience Designer - Logo 1.png')
export const audienceBuilderPng = require('./audience-builder.png')
export const barChartSvg = require('./barchart.svg')
export const beeswaxIconPng = require('./beeswax-icon.png')
export const beeswaxLogoSvg = require('./beeswax-logo.svg')
export const chartPng = require('./chart.png')
export const cookieFreePng = require('./cookie-free.png')
export const datastreamPng = require('./datastream.png')
export const datastreamPSvg = require('./datastream.svg')
export const dataxuLogoSvg = require('./dataxu-logo.svg')
export const directoryPng= require('./directory.png')
export const directoryupPng = require('./directoryup.png')
export const drumLogoPng = require('./drumLogo.png')
export const dv360IconPng = require('./dv360-icon.png')
export const dv360LogoPng = require('./DV360-Logo.png')
export const dv360LogoSvg = require('./dv360-logo.svg')
export const expandCollapsePng = require('./expand-collapse.png')
export const expandCollapseSvg = require('./expand-collapse.svg')
export const facebookIconPng = require('./facebook-icon.png')
export const facebookLogoSvg = require('./facebook-logo.svg')
export const filePng = require('./file.png')
export const googleIconPng = require('./google-icon.png')
export const googleLogoSvg = require('./google-logo.svg')
export const googleadIconPng = require('./googleads-icon.png')
export const googleadLogoSvg = require('./googleads-logo.jpeg')
export const HealthwiseDataLogoSansBkgrndSvg = require('./Healthwise Data logo-no background.svg')
export const healthwiseJpg = require('./healthwise.jpg')
export const healthwisePng = require('./healthwise.png')
export const healthwiseSvg = require('./healthwise.svg')
export const heroHomeJpg = require('./hero-home.jpg')
export const huluIconPng = require('./hulu-icon.png')
export const huluLogoSvg = require('./hulu-logo.svg')
export const iconFile2Png = require('./icon-file2.png')
export const iconFolderUp2Png = require('./icon-folder-up2.png')
export const iqmPng = require('./iqm-logo.png')
export const instephealthlogostackedSvg =  require('./InStep-Health-logo-stacked.svg')
export const killiSvg = require('./killi.svg')
export const knowWhoPng = require('./KnowWho.png')
export const knowWhoSvg = require('./KnowWho.svg')
export const liverampIconPng = require('./liveramp-icon.png')
export const liverampLogoPng = require('./liveramp-logo.png')
export const logoBlackPng = require('./logo-black.png')
export const logoBlackOldPng = require('./logo-blackOLD.png')
export const logoBlackOldSvg = require('./logo-blackOLD.svg')
export const logoWhitePng = require('./logo-white.png')
export const logoWhiteSvg = require('./logo-white.svg')
export const logoWhiteOldSvg = require('./logo-whiteOLD.svg')
export const logoPng = require('./logo.png')
export const logoSvg = require('./logo.svg')
export const logoWhiteTwoSvg = require('./logowhite.svg')
export const mappingTypesPng = require('./mapping-types.png')
export const mapScreenJpg = require('./mapScreen.jpg')
export const matchRatesPng = require('./match-rates.png')
export const mediamathIconPng = require('./mediamath-icon.png')
export const mediamathLogoSvg = require('./mediamath-logo.svg')
export const moneyTrashSvg = require('./moneyTrash.svg')
export const ntHomeHeroJpg = require('./nt-home-hero.jpg')
export const omniChannelPng = require('./omni-channel.png')
export const platformsPng = require('./platforms-icon.png')
export const poweredBySemcastingJpg = require('./PoweredbySemcasting.jpg')
export const privacyPng = require('./privacy.png')
export const purplelabSvg = require('./purplelab.svg')
export const rebelaiIconPng = require('./rebelai-icon.png')
export const rebelaiLogoPng = require('./rebelai-logo.png')
export const resetdigitalIconPng = require('./resetdigital-icon.png')
export const resetdigitalLogoPng = require('./resetdigital-logo.png')
export const rokuIconPng = require('./roku-icon.png')
export const rokuLogoSvg = require('./roku-logo.svg')
export const rokuOneviewFormerDataxuPng = require('./roku-oneview-formerly-dataxu.png')
export const rokuOneviewFormerDataxuSvg = require('./roku-oneview-formerly-dataxu.svg')
export const scheduleSvg = require('./schedule.svg')
export const semcastingAdobeIconPng = require('./semcasting-adobe-icon.png')
export const semcastingAdobeSvg = require('./semcasting-adobe.svg')
export const semcastingAmobeeIconPng = require('./semcasting-amobee-icon.png')
export const semcastingAmobeeLogoSvg = require('./semcasting-amobee-logo.svg')
export const semcastingIcon00a9c5Svg = require('./semcasting-icon-00a9c5.svg')
export const semcastingLogo00a9c5Svg = require('./semcasting-logo-00a9c5.svg')
export const semcastingTradedeskIconPng = require('./semcasting-tradedesk-icon.png')
export const semcastingTradedeskLogoSvg = require('./semcasting-tradedesk-logo.svg')
export const semcastingTradedeskThirdPartyLogoSvg = require('./semcasting-tradedesk-third-party-logo.svg')
export const semcastingXandrIconPng = require('./semcasting-xandr-icon.png')
export const semcastingXandrLogoPng = require('./semcasting-xandr-logo.png')
export const spectrumIconBluePng = require('./spectrum-icon-blue.png')
export const spectrumIconWhitePng = require('./spectrum-icon-white.png')
export const speedPng = require('./speed.png')
export const springserveIconPng = require('./springserve-icon.png')
export const springserveLogoPng = require('./springserve-logo.png')
export const surveyMdPng = require('./surveyMd.png')
export const titleBasedTargeting5Png = require('./title based targeting 5.png')
export const titleBasedTargeting5Svg = require('./title based targeting 5.svg')
export const tradedeskIconPng = require('./tradedesk-icon.png')
export const tradedeskLogoSvg= require('./tradedesk-logo.svg')
export const training2MdPng = require('./training2Md.png')
export const trainingMdPng = require('./trainingMd.png')
export const transparencyPng = require('./transparency.png')
export const tvsciIconPng = require('./tvsci-icon.png')
export const tvsciLogoPng = require('./tvsci-logo.png')
export const tvscientificIconPng = require('./tvscientific-icon.png')
export const tvscientificLogoPng = require('./tvscientific-logo.png')
export const twitterIconPng = require('./twitter-icon.png')
export const twitterLogoSvg = require('./twitter-logo.svg')
export const VerizonMediaLogoWinePng = require('./Verizon_Media-Logo.wine.png')
export const VerizonMediaLogoWineSvg = require('./Verizon_Media-Logo.wine.svg')
export const verizonIconPng = require('./verizon-icon.png')
export const womanThinkingSvg = require('./womanThinking.svg')
export const xandrIconPng = require('./xandr-icon.png')
export const xandrLogoSvg = require('./xandr-logo.svg')
export const yahooIconJpg = require('./yahoo-icon.jpg')
export const yahooLogoSvg = require('./yahoo-logo.svg')
export const zetaIconPng = require('./zeta-icon.png')
export const zetaLogoPng = require('./zeta-logo.png')
export const multiPlatform = require('./platforms-icon.png')

export const basisIconPng = require('./basis-icon.png')
export const basisLogoPng = require('./basis-logo.png')
export const stackadaptIconPng = require('./stackadapt-icon.png')
export const stackadaptLogoPng = require('./stackadapt-logo.png')
export const digitalremedyIconPng = require('./digital-remedy-icon.png')
export const digitalremedyLogoPng = require('./digital-remedy-logo.png')
export const fundprogressIconPng = require('./fund-progress-icon.png')
export const fundprogressLogoPng = require('./fund-progress-logo.png')
export const pubmaticIconPng = require('./pubmatic-icon.png')
export const pubmaticLogoPng = require('./pubmatic-logo.svg')
export const pinterestIconPng = require('./pinterest-icon.png')
export const pinterestLogoPng = require('./pinterest_logo.svg')
export const tiktokIconPng = require('./tiktok-icon.png')
export const tiktokLogoPng = require('./tiktok-logo.png')
