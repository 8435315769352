import React, {useEffect, useState} from "react";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import {ethnicBackgroundConfig} from "../../../config/ethnicBackgroundConfig";
import {filterBy} from "@progress/kendo-data-query";
import "./EthnicBackground.css";

const EthnicBackground = (props) => {

    const [value, setValue] = useState([]);

    const [ethnicBackgroundOptions, setEthnicBackgroundOptions] = useState([]);
    const [filteredEthnicBackgroundOptions, setFilteredEthnicBackgroundOptions] = useState([]);

    useEffect(() => {
        let optionsArr = [];
        Object.keys(ethnicBackgroundConfig).forEach(configOption => {
            let obj = {};
            obj.text = ethnicBackgroundConfig[configOption];
            obj.value = configOption
            optionsArr.push(obj);
        })
        setEthnicBackgroundOptions(optionsArr);
    }, []);

    useEffect(() => {
        let arr = [];
        props.audienceState.sem_ethnic_cd.forEach(rangeValue => {
            ethnicBackgroundOptions.find(option => {
                if (option.value === rangeValue) {
                    arr.push(option);
                }
                ;
            });
        });
        setValue(arr);
    }, [props.audienceState]);

    const onEthnicBackgroundFilterChange = (e) => {
        const filter = e.filter;
        const allData = ethnicBackgroundOptions.slice();
        const newData = filter.value.length >= 1 ? filterBy(allData, filter) : allData;
        if (newData.length > 0) {
            setFilteredEthnicBackgroundOptions(newData);
        }
    };

    const onEthnicBackgroundChange = (e) => {
        let values = [];
        setValue(e.value);
        e.value.forEach((val) => {
            values.push(val.value);
        });
        // props.audienceState.sem_ethnic_cd = values;
        props.handleAudienceState(prevState => {
            return {...prevState, sem_ethnic_cd: values}}
        );
    };

    return (
        <div>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={ethnicBackgroundOptions}
                    autoClose={false}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    filterable={true}
                    onFilterChange={onEthnicBackgroundFilterChange}
                    onChange={onEthnicBackgroundChange}
                    size="large"
                    value={value}
                />
            </div>
        </div>
    );
}
export default EthnicBackground;
