import {useEffect, useState} from "react";
import {
    boostrap2TableSearchOptions,
    defaultSortedOnTotal,
    ExportCSV, exportCSVOptions,
    noDataIndication,
    paginationOptions,
    semcastAxios
} from "../../../../Utils/Common";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider,{Search} from 'react-bootstrap-table2-toolkit';
import {
    ACTIVATED_SEGMENTS_TOTALS_BY_ACCOUNT_API, CREATED_SEGMENTS_TOTALS_BY_ACCOUNT_API, NO_DATA_TO_SHOW,
    SUCCESS_STATUS,
    YYYY_MM_DD_DATEFORMAT
} from "../../../../api/constants";
import LoadOverlay from "../../../Components/SharedComponents/LoadOverlay";
// es6
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css'
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import moment from "moment";
import "../../Pages/AppStatus.css"
function CreatedSegmentsTotalsByAccount(props) {
    const propsToPass = props.data.propsToPass
    const [loader, setLoader] = useState(false)
    const [rows, setRows] = useState([]);
    const { SearchBar } = Search;
    const columns = [{
        dataField: 'company',
        text: 'Company',
        sort: true
    }, {
        dataField: 'total',
        text: 'Total',
        sort: true
    }];
    const broadcastReloadEvent = (count)=>{
        props.broadcastReloadEvent(props.uniqueKey, count);
    }
    useEffect(async () => {
        try {
            setLoader(true)
            await loadRows();
        } catch (e) {
            setRows([])
            broadcastReloadEvent(0)
        } finally {
            setLoader(false)
        }
    }, [propsToPass]);

    const loadRows = async () => {
        const res = await semcastAxios.get(CREATED_SEGMENTS_TOTALS_BY_ACCOUNT_API +
            `?created_at_start=${moment(
                propsToPass.startDate
            ).format(YYYY_MM_DD_DATEFORMAT)}&created_at_end=${moment(
                propsToPass.endDate
            ).format(
                YYYY_MM_DD_DATEFORMAT
            )}`, {
            withCredentials: true,
        });
        if (res.data.status === SUCCESS_STATUS) {
            if (res?.data?.data?.length) {
                res?.data?.data.forEach((obj, index) => {
                    obj['id'] = index
                })
                setRows(res.data.data)
                broadcastReloadEvent(res?.data?.data.reduce( (a, b)=>{
                    return a + b['total'];
                }, 0))
            }else{
                broadcastReloadEvent(0)
            }
        }
    };

    return (
        <>
            <LoadOverlay active={loader}>
              <ToolkitProvider
                    keyField="id"
                    bootstrap4={true}
                    data={rows}
                    columns={columns}
                    search={boostrap2TableSearchOptions()}
                    exportCSV={exportCSVOptions()}
                >
                    {
                        props => (
                            <div>
                                <SearchBar { ...props.searchProps }
                                           className="form-control form-control-custom"/>
                                <ExportCSV {...props.csvProps}/>
                                <BootstrapTable {...props.baseProps}
                                                pagination={paginationFactory(paginationOptions(rows))}
                                                filter={filterFactory()}
                                                defaultSorted={defaultSortedOnTotal}
                                                bordered={false}
                                                noDataIndication={noDataIndication}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </LoadOverlay>
        </>
    )
}

export default CreatedSegmentsTotalsByAccount