import * as Yup from "yup";

export const tvsciConfig = {
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With TvScientific",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "tvsci",

  /**
   * HTML that explains how to use the form
   */
  instructions:
    "Please provide the Start/End date. All fields must be completed " +
    "to push an audience to your seat.  Please note that the audience may take up 3 and 5 days for " +
    "the audience to show up in your platform.",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "tvsci",
    text: "TvScientific",
    img: "tvscientific-logo.png",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink: "",

  layout: [
    {
      fieldType: "radioGroup",
      labelText: "Mobile Devices",
      fieldName: "mobile_devices",
      required: true,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: false,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },
      ],
    },
    {
      fieldType: "textField",
      fieldName: "segment_description",
      labelText: "Segment Description (Optional)",
      prepopulateWith: "text",
      required: false,
    },
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },
    {
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
    {
      fieldType: "pricing",
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: [],
  formik: {
    initialValues: {
      segment_description: "",
      mobile_devices: "top_ios_android",
      campaign_range_start: "",
      campaign_range_end: "",
      device_type: ["ios_android"],
      audience_usage:"",
      creativeTagIdDropDown: "",
      creativeTagId:""
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({      
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required"),
      segment_description: Yup.string().matches(/^[^:"“;`‘'<>|]{1,256}$/, "Please remove invalid character(s) such as < > : \" “ ; ` ‘ ' | and keep the message length within 256 characters"),
    }),
    onSubmit: (values) => {},
  },
};
