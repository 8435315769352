import React, {useEffect, useMemo, useState} from "react";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import "./EthnicGroup.css";

const EthnicGroup = (props) => {

    const [value, setValue] = useState(props.controlsState ?
        props.controlsState.get("sem_ethnic_region_cd") : []);

    const [ethnicGroupOptions, setEthnicGroupOptions] = useState([
        {text: "African American", value: "AFR"},
        {text: "Asian", value: "ASN"},
        {text: "Hispanic", value: "HIS"},
        {text: "Middle Eastern", value: "MID"},
        {text: "White", value: "WHT"}
    ]);

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.sem_ethnic_region_cd) {
                props.segmentEditInfo.data.segment_design.primaryData.sem_ethnic_region_cd.forEach(rangeValue => {
                    ethnicGroupOptions.find(option => {
                        if (option.value === rangeValue) {
                            arr.push(option);
                        }
                        ;
                    });
                });
                setValue(arr);
            }
        }
    }, [props.segmentEditInfo]);

    const onEthnicGroupChange = (e) => {
        let values = [];
        setValue(e.value);
        e.value.forEach((val) => {
            values.push(val.value);
        });
        props.formik.values.segment_design.primaryData.sem_ethnic_region_cd = values;
        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("sem_ethnic_region_cd", e.value)));
        }
    };

    return (
        <div>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={ethnicGroupOptions}
                    autoClose={false}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    onChange={onEthnicGroupChange}
                    size="large"
                    value={value}
                    // style={{width: "32rem"}}
                />

            </div>
        </div>
    );
}
export default EthnicGroup;
