import React, { useState } from 'react';

export const UserContext = React.createContext({
   user: {
       id: 0,
       email: '',
       phone: '',
       company: '',
       resourceGroupId: 0,
       founder: false,
       resourceType:'',
       childUser: true,
       firstName: '',
       lastName: '',
       logo: null,
       accountId: '',
       paymentMethodId: '',
       opportunityId: '',
       contactId: '',
       autoBill: false,
       last4: '',
       deactivatedReason:'',
       lastLoginIp: '',
       deactivatedAt: '',
       deletedAt: '',
       lastLoginAt: '',
       createdAt: '',
       updatedAt: '',
       freeTrialEndDate: '',
       foundingGroupId: '',
       founderId: 0,
       createdBy:0,
       defaultGroupId: 0,
       isAdmin:false,
       canViewPermissionsPage:false
   }
});

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});
    return (
        <UserContext.Provider
            value={{
                user,
                setUser
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

