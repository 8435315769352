export const PERMISSIONS_NEEDED_TO_VIEW_PERMISSIONS_PAGE  = ["CREATE_CHILD_GROUP",
    "UPDATE_OWN_GROUP",
    "REASSIGN_USER_CHILD_GROUP",
    "UPDATE_CHILD_GROUP",
    "DELETE_CHILD_GROUP",
    "REASSIGN_USER_OWN_GROUP",

    "UPDATE_ROLES_OWN_GROUP",
    "DELETE_ROLES_OWN_GROUP",
    "UPDATE_ROLES_CHILD_GROUP",
    "DELETE_ROLES_CHILD_GROUP",
    "CREATE_ROLES_CHILD_GROUP",
    "CREATE_ROLES_OWN_GROUP",

    "DELETE_USER_OWN_GROUP",
    "CREATE_USER_OWN_GROUP",
    "CREATE_USER_CHILD_GROUP",
    "VIEW_USER_CHILD_GROUP",
    "UPDATE_USER_CHILD_GROUP",
    "DELETE_USER_CHILD_GROUP",
    "VIEW_USER_OWN_GROUP",
    "UPDATE_USER_OWN_GROUP"]

/**
 * determines if the user has the required permissions
 * @param requiredPermissions
 * @param permissionsObj {{[group_id]:permissions[]}}
 * @returns {boolean}
 */
export const storeGroupToStorage = (groupsObject) => {
    try {
        let groupIds = [];
        groupsObject?.permissions.map((id, item) => {
            groupIds.push(id);
        })
        localStorage.setItem('user_groups', JSON.stringify(groupIds));
        return groupIds;
    } catch (e) {
        console.log(e)
    }
}

export const hasAtLeastOneInAnyGroup = (requiredPermissions, permissionsObj) => {
    try {
        requiredPermissions = typeof requiredPermissions === 'string' ? [requiredPermissions] : requiredPermissions;
        for (let groupId in permissionsObj) {
            if (hasAtleastOne(requiredPermissions, permissionsObj[groupId])) {
                return true;
            }
        }
    } catch (e) {
        console.log(e)
    }
    return false;
}


/**
 * determines if a user has required permissions
 * @param requiredPermissions {String|[String]} a permission value or array of permission values
 * @param groupPermissionsObj {{}} groupPermissionsObj of group
 * @returns {boolean} true if the user has all the requiredPermissions else false
 */
export const hasAtleastOne = (requiredPermissions, groupPermissionsObj) => {

    let hasPermission = false
    try {
        let permissionList = groupPermissionsObj?.permissions.map(p => {
            return p.permission_value
        }) || []
        requiredPermissions = typeof requiredPermissions === 'string' ? [requiredPermissions] : requiredPermissions;
        if (requiredPermissions?.length && permissionList?.length) {
            hasPermission = !!requiredPermissions.find((item) => {
                return permissionList.indexOf(item) > -1;
            });
        }
    } catch (e) {
        console.log(e)
    }

    return hasPermission;
}


/**
 * determines if the user has the required permissions
 * @param requiredPermissions
 * @param permissionsObj {{[group_id]:permissions[]}}
 * @returns {boolean}
 */
export const hasPermissionInAnyGroup = (requiredPermissions, permissionsObj) => {
    try {
        requiredPermissions = typeof requiredPermissions === 'string' ? [requiredPermissions] : requiredPermissions;
        for (let groupId in permissionsObj) {
            if (hasPermissions(requiredPermissions, permissionsObj[groupId])) {
                return true;
            }
        }
    } catch (e) {
        console.log(e)
    }
    return false;
}

/**
 * determines if a user has required permissions
 * @param requiredPermissions {String|[String]} a permission value or array of permission values
 * @param groupPermissionsObj {{}} groupPermissionsObj of group
 * @returns {boolean} true if the user has all the requiredPermissions else false
 */
export const hasPermissions = (requiredPermissions, groupPermissionsObj) => {

    let hasPermission = false
    try {
        let permissionList = groupPermissionsObj?.permissions.map(p => {
            return p.permission_value
        }) || []
        requiredPermissions = typeof requiredPermissions === 'string' ? [requiredPermissions] : requiredPermissions;
        if (requiredPermissions?.length && permissionList?.length) {
            hasPermission = !!requiredPermissions.every((item) => {
                return permissionList.indexOf(item) > -1;
            });
        }
    } catch (e) {
        console.log(e)
    }

    return hasPermission;
}

export class UsersHelpers {

    roleBelongsToGroup(role, groupId) {
        return role.owner_group_id === groupId
    }

    isDefaultRole(role) {
        return role.owner_group_id === 0;
    }

    isAdministrator(role) {
        return role.role_name === 'Administrator';
    }
}