import React, {useEffect, useState} from "react";

import {Button} from "@progress/kendo-react-buttons";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Label} from "@progress/kendo-react-labels";
import "./AdvancedTargeting.css";
import {FieldWrapper, Form, FormElement} from "@progress/kendo-react-form";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import {RadioGroup} from "@progress/kendo-react-inputs";
import audienceDesignConfig from "../../../config/audienceDesignConfig";
import {getPpkSegments} from "../../../actions/AudienceDesignActions";
import {filterBy} from "@progress/kendo-data-query";
import CategorySegments from "./CategorySegments";
import LoadOverlay from "../SharedComponents/LoadOverlay";

const AdvancedTargeting = (props) => {

    const [options, setOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [targetingData, setTargetingData] = useState({});
    const [activeTiles, setActiveTiles] = useState([]);
    const [value, setValue] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedSegmentTitle, setSelectedSegmentTitle] = useState("");
    const [segmentGroupTitles, setSegmentGroupTitles] = useState({});
    const [segmentsState, setSegmentsState] = useState({});
    const [selectedTile, setSelectedTile] = useState({});
    const [selectedTileConfig, setSelectedTileConfig] = useState({});
    const [selectionType, setSelectionType] = useState( "DISTINCT");
    const selectionTypes = [
        {label: "Chosen value can be in ANY selection (OR statement)", value: "DISTINCT"},
        {label: "Chosen value must be in ALL selections (AND statement)", value: "COMMON"}
    ]
    const [consumerList, setConsumerList] = useState([]);
    const [businessList, setBusinessList] = useState([]);
    const [loadOverlay, setLoadOverlay] = useState(true);
    const [valueLength, setValueLength] = useState(null);
    const [memorizeState, setMemorizeState] = useState({});
    let tempState = {};

    const handleSelectionType = (e) => {
        setSelectionType(e.value);
        props.formik.values.segment_design.merge_type = e.value;
    };

    const handleVisible = () => {
        setVisible(true);
    }

    const handleSegmentsState = (state) => {
        setSegmentsState(state);
    }

    const saveSegmentSelections = (state, e) => {
        setVisible(false);
    }

    const onFilterChange = (e) => {
        const filter = e.filter;
        const allData = options.slice();
        if (filter.value.length < 3) return;
        const newData = filter.value.length >= 3 ? filterBy(allData, filter) : allData;
        setFilteredOptions(newData);
    };
    function getValueFromKey(obj, str) {
        const normalizedStr = str.toLowerCase().replace(/\s+/g, '');

        for (const key in obj) {
            const normalizedKey = key.toLowerCase().replace(/\s+/g, '');
            if (normalizedKey === normalizedStr) {
                return key;
            }
        }
        return undefined; // Return undefined if the key is not found
    }
    const generateTempState = (a, prevTempState) => {

        const newState = { ...prevTempState };

        if (a.length > 0) {
            const lastItem = a[a.length - 1];
            const { segment_group, segment_id, segment_category, groupName } = lastItem.value;

            if (!newState[segment_category]) {
                newState[segment_category] = [];
            }

            // Check if the item with the same value already exists
            const exists = newState[segment_category].some(item => item.value === segment_id);
            // If it doesn't exist, push the new item
            if (!exists) {
                newState[segment_category].push({
                    label: lastItem.label,
                    value: segment_id,
                    checked: false,
                });

                    if (props.targetType === "CONSUMER") {
                        const keyFound = getValueFromKey(props.formik.values.segment_design,segment_group);
                        if (!props.formik.values.segment_design[keyFound]) {
                            props.formik.values.segment_design[keyFound] = [];
                        }
                        props.formik.values.segment_design[keyFound].push(segment_id);
                    } else if (props.targetType === "BUSINESS"){
                        const keyFound =  businessList.find(item => item.groupName === groupName);
                        if (!props.formik.values.segment_design[keyFound.groupType]) {
                            props.formik.values.segment_design[keyFound.groupType] = [];
                        }
                        props.formik.values.segment_design[keyFound.groupType].push(segment_id);
                    }
            }
        }
        return newState;
    };

    const onSegmentDataChange = (e) => {
        setValueLength(e.value.length);
        if (e.value.length === 0) {
            setActiveTiles([]);
            setValue(e.value);
            return;
        }
        let currentActiveTiles = new Set();
        e.value.forEach((info) => {
            let tile = props.formik.values.target_type === "CONSUMER" ?
                consumerList.find(obj => obj.groupName === info.value.groupName) :
                businessList.find(obj => obj.groupName === info.value.groupName)
            // currentActiveTiles.add(info.value.groupName);
            if (tile) currentActiveTiles.add(tile);
        });
        if (currentActiveTiles.size > 0) {
            const arr = Array.from(currentActiveTiles).sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
            setActiveTiles(arr);
        }
        setValue(e.value);

        if(valueLength < e.value.length || valueLength === null) {
            tempState = generateTempState(e.value, memorizeState);
            setMemorizeState(tempState);
        }
};
    useEffect(() => {
        handleSegmentsState(memorizeState);
    }, [memorizeState]);

    useEffect(() => {
        if (props.formik.values.target_type === "BUSINESS" &&
            props.formik.values.segment_design.titleMatching.length > 0) {
            props.setMsg("You will now be creating a new segment targeting Consumers where the industry and title match the Industry Naics Codes of these businesses.");
        } else {
            props.setMsg("");
        }
    }, [props.formik.values.segment_design.titleMatching.length])

    useEffect(() => {
        if (JSON.stringify(segmentsState) === '{}' && selectedTileConfig.groupType === "titleMatching") {
            props.setMsg("");
        }
    }, [JSON.stringify(segmentsState) === '{}' && selectedTileConfig.groupType === "titleMatching"])

    useEffect(() => {
        options.length = 0;
        filteredOptions.length = 0;
        value.length = 0;
        setActiveTiles([]);
        fetchData();
        setSegmentsState({});
        setMemorizeState({});
        setValueLength(null);
    }, [props.targetType]);

    useEffect(() => {
        for (let property in targetingData) {
            // console.log(property);
            targetingData[property].map((obj) => {
                obj["groupName"] = targetingData[property].groupName;
                // NOTE: this special case logic will go away once we clean up prepackaged_segment_descriptor table..
                // if (targetingData[property].groupName === "Retail" ||
                //     targetingData[property].groupName === "Personal Finance" ||
                //     targetingData[property].groupName === "Sports Attendees") {
                //     label = obj.segment_category + " : " + obj.description;
                // } else {
                //     if (obj.segment_name !== '') label = obj.segment_category + " : " + obj.segment_name;
                // }
                let s = !(obj.segment_name.includes(":")) ? obj.segment_name : obj.description;
                options.push({label:s, value:obj});
            })
        }

//        console.log(options);
        options.sort();
    }, [targetingData])

    const fetchData = async () => {
        setLoadOverlay(true);
        const allReq = [];
        let cList = [];
        let bList = [];
        for (const key in audienceDesignConfig) {
            allReq.push(
                await getPpkSegments(
                    audienceDesignConfig[key].endPoint,
                    audienceDesignConfig[key].groupType
                )
            );
            // console.log(audienceDesignConfig[key]);
            if ((audienceDesignConfig[key].targetType === "CONSUMER" ||
                audienceDesignConfig[key].targetType === "BOTH")) {
                cList.push(audienceDesignConfig[key]);
            }
            if ((audienceDesignConfig[key].targetType === "BUSINESS" ||
                    audienceDesignConfig[key].targetType === "BOTH")) {
                bList.push(audienceDesignConfig[key]);
            }
        }
        // Sort for display order
        cList.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        bList.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        // FD#11981
        cList = cList.filter((key)=>{return key.groupType !== 'smllearning'})
        setConsumerList(cList);
        setBusinessList(bList);

        await Promise.all(allReq).then((res) => {
            const allData = {};
            // console.log(res);
            res.forEach((reqData) => {
                const {data, groupType} = reqData;
                data.sort((a,b) => a.segment_name.localeCompare(b.segment_name, 'en', {numeric: true}))
                if (audienceDesignConfig[groupType].targetType === "BOTH" ||
                    audienceDesignConfig[groupType].targetType === props.targetType ||
                    audienceDesignConfig[groupType].groupName === "Net Worth" ||
                    audienceDesignConfig[groupType].groupName === "Discretionary Income")
                    {
                    allData[groupType] = data;
                    allData[groupType].groupName = audienceDesignConfig[groupType].groupName;
                    allData[groupType].targetType = audienceDesignConfig[groupType].targetType;
                }
            });
            if (allData['personalFinance']) {
                handleDeciles(allData);
            }
            //FD#12181 Removing these 2 groups because tiles not found under Business Target Type. Same group can still be found under Consumer-personalFinance.
            delete allData["discretionaryIncome"];
            delete allData["netWorth"];
            //FD#11981
            delete allData["smllearning"];
            setTargetingData(allData);
            setLoadOverlay(false);
            // setPageLoader(false);
            // setIsDataLoaded(true);
        });
    };


    //FD:10151 - Special case for decile categories
    const handleDeciles = (data) => {
        // console.log(data);
        let personalFinance = data['personalFinance'];
        if (personalFinance  === undefined) return;
        let netWorth = data['netWorth'];
        let discretionaryIncome = data['discretionaryIncome'];
        netWorth.map((obj) => {
            obj.segment_group = 'Personal Finance';
            obj.segment_name = obj.description;
        })
        discretionaryIncome.map((obj) => {
            obj.segment_group = 'Personal Finance';
            obj.segment_name = obj.description;
        })
        discretionaryIncome.sort((a,b) => a.segment_id.localeCompare(b.segment_id, 'en', {numeric: true}))
        data['personalFinance'] = personalFinance.concat(netWorth).concat(discretionaryIncome);
        // console.log(data['personalFinance']);
    }

    const displayTile = (tileName) => {
        // if (activeTiles.length === 0 || Array.from(activeTiles).includes(tileName)) return true;
        if (activeTiles.length === 0 || Array.from(activeTiles).some(entry => entry.groupName === tileName)) return true;
        return false;
    };

    const clickTileHandler = (e, tile) => {
        // This is click handler for tiles.
        const title = tile.dialogTitle;
        const groupType = tile.groupType;
        setSelectedSegmentTitle(title);
        setSelectedTile(targetingData[groupType]);
        setSelectedTileConfig(tile);

        handleVisible();
    };

    const clickInfoLinkHandler = (e, tile)  => {
        // This is click handler for 'Info' link on each tile.
        const pdfWindow = window.open(tile.dataSheet, "pdf show", "width=800, height=600");
        pdfWindow.focus();
        e.stopPropagation();
    };

    const clickEditCategoryHandler = (group, state, event) => {
        const groupName = group;
        const title = segmentGroupTitles[groupName];
        setSelectedSegmentTitle(title);
        handleVisible();
    };

    const handleTargetedChange = (e, key) => {
        if (e.value.length === 0) {
            delete segmentsState[key];
            setSegmentsState({...segmentsState});
        } else {
            segmentsState[key] = e.value;
            setSegmentsState({...segmentsState});
        }
    };

    const displayTiles = () => {
        let displayTypes = [];
        if (props.targetType === "CONSUMER") {
            displayTypes = activeTiles.length > 0 ?
                [...new Set(activeTiles.map(item => item.displayType))] :
                [...new Set(consumerList.map(item => item.displayType))];
            const renderList = displayTypes.map((type, index) =>
                displayTileGroup(type, consumerList, index)
            )
            return (
                <>
                    <div>{renderList}</div>
                </>
            );
        } else if (props.targetType === "BUSINESS") {
            displayTypes = activeTiles.length > 0 ?
                [...new Set(activeTiles.map(item => item.displayType))] :
                [...new Set(businessList.map(item => item.displayType))];
            const renderList = displayTypes.map((type, index) =>
                displayTileGroup(type, businessList, index)
            )
            return (
                <>
                    <div>{renderList}</div>
                </>
            );
        }
    };
const infoSheetAvailable = ['Analytics IQ', 'Meds & Treatment', 'HealthPlan', 'PurpleLabHCP', 'SalesIntel Techno.', 'SalesIntel Dept.']; //FD#11854
    const displayTileGroup = (type, tilesList, index) => {
        const header = <Label className={"sub-section-label"}>{type}</Label>;
        const renderList = tilesList
            .filter(tile => tile.displayType === type)
            .map((tile, index) => <Button key={index}
                                          id={tile.groupType}
                                          className={"tile-buttons"}
                                          style={displayTile(tile.groupName) ? {} : {display: "none"}}
                                          onClick={(e) => clickTileHandler(e, tile)}>
                <Label className={"tile-button-group-name"}>{tile.title}</Label>
                <div className="image-container image">
                    <img src={tile.icon} alt={""} style={{width: tile.iconWidth}}/>
                </div>
                <div className={"info-link-container"}>
                    <label className={"price-container"}>{tile.price}</label>
                    {/*FD#11854*/}
                    {infoSheetAvailable.includes(tile.title) && <a href={"#"} onClick={(e) => clickInfoLinkHandler(e, tile)} style={{
                        fontSize: "13px",
                        marginBottom: "1rem"
                    }}>Info</a>}
                </div>
            </Button>);

        return (
            <>
                {renderList.length > 0 && header}
                <div className={"advanced-targeting-button-grp"}>{renderList}</div>

            </>
        );
    }

    const displayTargetedSegments = () => {
        // console.log(segmentsState);
        let renderList = null;
        let arr = Object.keys(segmentsState);
        renderList = arr.map((item, index) => (
            <MultiSelect
                key={index}
                label={arr[index]}
                data={segmentsState[arr[index]]}
                autoClose={false}
                textField={"label"}
                dataItemKey="value"
                popupSettings={{height: 150}}
                onChange={(e) => handleTargetedChange(e, arr[index])}
                value={segmentsState[arr[index]] ? segmentsState[arr[index]] : []}
                size={"large"}/>
        ));

        return (
            <>
                {renderList}
            </>
        )
    };


    return (
        <>
            <Card>
                <CardTitle>
                    <Label className="section-label">ADVANCED TARGETING</Label>
                </CardTitle>
                <CardBody>
                    <Form render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                        <legend className={'k-form-legend spacingLine'}></legend>
                        <div className={"cost-msg-div"}>
                            <Label className={"cost-msg-label"}>
                                <span>Cost is capped at the highest listed CPM value. When multiple segments are used the CPM value is proportionately applied.</span>
                            </Label>
                        </div>
                        <div className="advanced-targeting-container">
                            <Form render={formRenderProps => <FormElement style={{
                                width: "100%"
                            }}>
                                <FieldWrapper >
                                    <Label className={"labels targeting-selection-type-label"}>Selection Type</Label>
                                    {/*Moved here - demo feedback - 1/27/23*/}
                                    <RadioGroup
                                        data={selectionTypes}
                                        value={selectionType}
                                        onChange={handleSelectionType}
                                        layout="vertical"
                                        style={{width:"100%", fontSize:"16px"}}
                                    />
                                </FieldWrapper>
                                <FieldWrapper style={{paddingBottom: "1.5rem"}}>
                                    <MultiSelect style={{display:"none"}} className={"fields"}/>
                                    <MultiSelect
                                        className={"fields advance-targeting-search-bar"}
                                        data={filteredOptions.length > 0 ? filteredOptions.filter((i)=>{return i.label !== "All"}) : options.filter((i)=>{return i.label !== "All"})}
                                        placeholder="Search / Filter Segments"
                                        autoClose={false}
                                        textField="label"
                                        dataItemKey="value"
                                        filterable={true}
                                        onFilterChange={onFilterChange}
                                        onChange={onSegmentDataChange}
                                        value={value}
                                        size="large"
                                        style={{width: "100%", borderRadius: "0.25rem"}}
                                    />
                                </FieldWrapper>
                            </FormElement>}/>
                        </div>
                    </FormElement>}/>
                    <LoadOverlay active={loadOverlay} width={'50%'} height={'100px'}>
                    {displayTiles()}
                    </LoadOverlay>
                    <Card>
                        <CardBody>
                            <Label className={"sub-section-label"}>Targeted Segments</Label>
                            <div className={"targeted-segments-group"}>
                                {displayTargetedSegments()}
                            </div>
                        </CardBody>
                    </Card>
                </CardBody>
            </Card>

            {visible && (
                <div>
                    <CategorySegments
                        formik={props.formik}
                        visible={true}
                        setVisible={setVisible}
                        allData={targetingData}
                        selectedTile={selectedTile}
                        selectedTileConfig={selectedTileConfig}
                        filteredData={value}
                        segmentsState={segmentsState}
                        handleSegmentsState={handleSegmentsState}
                        selectedSegmentTitle={selectedSegmentTitle}
                        saveSegmentSelections={saveSegmentSelections}/>
                </div>
            )}

        </>
    )
}

export default AdvancedTargeting;