import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const ttdConfig = {
  ...COMPANY_BASE_PROPERTIES,
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "3rd Party Onboarding With The Trade Desk",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "ttd",
  /**
   * HTML that explains how to use the form
   */
     instructions:
     "You have selected a platform that automatically reports usage for targeting. " +
     "You will be billed for data usage by the platform unless the segment is applied for suppression purposes. " +
     "Suppression segments are billed by Semcasting directly based on the discounted deployed record formula. " +
     "Activating this audience means you agree to this billing method. ",
  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "ttd",
    text: "The Trade Desk",
    img: "tradedesk-logo.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink: "", //'<a href="https://auth.thetradedesk.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fresponse_type%3Did_token%26response_mode%3Dform_post%26scope%3Dopenid%2520profile%26client_id%3Ddesk%26nonce%3D733520271c7af38289e092f210a2bfd4%26state%3DGBUGoL1Q3aLFIbvGDJPQZDFg7rpc4lEv79%252F6rS%252Fdj0RMq4rZdShJ%252BQ81%252FnKkL0EWkzkB10V8Ct0mARYRI9uwzDngzyJTZNEoHJYTWCOyqrCmpJMz14ibuqfx7R8X9UEF5pTLPiUtSDjIu7eT6MC6A9GMoTXpXYXZ8jWxyPcs1sP5W%252BZilcMPv0knZJOCqd7d7Pq0hb4fRN4eOl0DfKLc%252Fxa2ZUPTiIh1xBohGqAv5yE%253D%26redirect_uri%3Dhttps%253A%252F%252Fdesk.thetradedesk.com%252Fsignin-oidc%26appReturnUrl%3Dhttps%25253A%25252F%25252Fdesk.thetradedesk.com%25252F%26appUrl%3Dhttps%25253A%25252F%25252Fdesk.thetradedesk.com%25252FAccount%25252FLogOn" target="_blank">Log in to The Trade Desk</a>',

  layout: [
    {
      fieldType: "radioGroup",
      labelText: "Mobile Devices",
      fieldName: "mobile_devices",
      required: true,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: false,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },
      ],
    },
    {
      fieldType: "checkboxGroup",
      labelText: "UID2",
      fieldName: "device_type",
      required: false,
      checkboxes: [
        {
          labelText: "UID2",
          fieldName: "UID2", // FD#2963
          value: "UID2", // FD#2963
          checked: false,
        },
      ],
    },
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType: "textField",
      fieldName: "segment_description",
      labelText: "Segment Description (Optional)",
      prepopulateWith: "text",
      required: false,
    },
    {
      fieldType: "radioGroup",
      labelText: "Audience Availability",
      fieldName: "audience_availability",
      required: true,
      radioButtons: [
        {
          labelText: "Partner ID",
          fieldName: "partner",
          value: "partner",
          subtext:
            "This will make the audience available across all Advertisers",
          checked: true,
        },
        {
          labelText: "Advertiser ID",
          fieldName: "advertiser",
          value: "advertiser",
          subtext:
            "This will make the audience available to a specific Advertiser",
          checked: false,
        },
      ],
    },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelectTTDPartner",
          fieldName: "partner_id",
          labelText: "Partner ID",
          url: "/v2/secure/attributor/activate-credentials/ttd/partner_id",
          placeholder: "Select an Partner ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
          show: false,
        },
        {
          fieldType: "searchSelectTTDAdvertiser",
          fieldName: "advertiser_id",
          labelText: "Advertiser ID",
          url: "/v2/secure/attributor/activate-credentials/ttd/advertiser_id",
          placeholder: "Select an Advertiser ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
          show: false,
        },
        {
          fieldType: 'checkbox',
          fieldName: "save_credentials",
          labelText: 'Save TTD Partner/Advertiser ID for future use',
          checked: false,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },
    {
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
    {
      fieldType: "pricing",
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["partner_id", "advertiser_id"],
  formik: {
    initialValues: {
      device_type: [],
      mobile_devices: "top_ios_android",
      campaign_range_start: "",
      campaign_range_end: "",
      audience_availability: "partner",
      save_credentials: false,
      partner_id: "",
      advertiser_id: "",
      audience_usage:"",
      segment_description:"",
      creativeTagIdDropDown: "",
      creativeTagId:"",

      extraDataForFormOps: {
        partner_id: {
          show: true,
        },
        advertiser_id: {
          show: true,
        },
      },
    },
    // We used Yup here for input format validation.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      audience_availability: Yup.string().required(
        "Audience Availability is required"
      ),
      audience_usage: Yup.string().required("Planned Usage is required"),

      partner_id: Yup.string().when("audience_availability", {
        is: "partner",
        then: Yup.string().required("Partner ID is required"),
      }),
      advertiser_id: Yup.string().when("audience_availability", {
        is: "advertiser",
        then: Yup.string().required("Advertiser ID is required"),
      }),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
    }),
    onSubmit: (values) => {},
  },
};
