import React, {useEffect, useState} from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import "./InflationSensitivityIndex.css";

const InflationSensitivityIndex = (props) => {

    const [value, setValue] = useState([]);

    const inflationOptions = [
        {text: "Least Sensitivity", value: "least_sensitivity", num: ['09','10']},
        {text: "Below Average", value: "below_average", num: ['07','08']},
        {text: "Average", value: "average", num: ['05','06']},
        {text: "Above Average", value: "above_average", num: ['03','04']},
        {text: "Most Sensitivity", value: "most_sensitivity", num: ['01','02']}
    ];

    useEffect(() => {
        let arr = [];
        props.audienceState.inflation_sensitivity_index.forEach(optionValue => {
            inflationOptions.find(option => {
                if (option.num.includes(optionValue)) {
                    if (!arr.includes(option)) arr.push(option);
                }
                ;
            })
        })
        setValue(arr);
    }, [props.audienceState]);

    const onInflationSensitivityChange = (e) => {
        let values = [];
        let controlValues = [];
        e.value.forEach((val) => {
            values = values.concat(val.num);
            controlValues.push(val);
        });
        setValue(controlValues);
        // props.audienceState.inflation_sensitivity_index = values;
        props.handleAudienceState(prevState => {
            return {...prevState, inflation_sensitivity_index: values}}
        );
    };

    return (
        <div>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={inflationOptions}
                    autoClose={false}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    onChange={onInflationSensitivityChange}
                    size="large"
                    value={value}
                    // style={{width:"32rem"}}
                    // value={socialMatrixOptions}
                />
            </div>
        </div>
    );
}
export default InflationSensitivityIndex;
