import React, {useEffect, useState} from "react";
import "./TreeSegmentsTable.css";
import {ColumnChooser} from "./ColumnChooser";
import {ColumnMenuContext} from './ColumnMenuContext';
import {
    GroupNameCell,
    CreatedAtCell,
    LastUpdatedCell,
    ScheduledCell,
    SegmentPartyTypeCell,
    SegmentSizeCell,
    SegmentTableNameCell,
    SegmentTableTypeCell,
    StatusCell,
    UserNameCell,
    PctServedCell,
} from "./TreeCells.js";
import {
    ColumnMenuTextColumn,
    Pager
} from '@progress/kendo-react-data-tools';
import {
    extendDataItem,
    filterBy,
    mapTree,
    orderBy,
    TreeList,
    TreeListHeaderSelectionCell,
    TreeListSelectionCell,
} from "@progress/kendo-react-treelist";
import {getter} from '@progress/kendo-react-common';
import {
    TreeStatusMultiSelectFilter,
    TreeGroupMultiSelectFilter,
    TreeTypeMultiSelectFilter,
    TreeUserMultiSelectFilter, TreeNameSearchFilter
} from './TreeFilter';
import TreeSegmentDashboardAppBar from "./TreeSegmentDashboardAppBar";

const TreeSegmentsTable = (props) => {
    const DATA_ITEM_KEY = 'id';
    const subItemsField = "children";
    const expandField = "expanded";
    const selectedField = "selected";
    const idGetter = getter(DATA_ITEM_KEY);
    const table = React.useRef();

    const storedTake = JSON.parse(localStorage.getItem('pageTake'));
    const storedSkip = JSON.parse(localStorage.getItem('pageSkip'));
    const [take, setTake] = React.useState(storedTake ? storedTake : 10);
    const [skip, setSkip] = React.useState(storedSkip ? storedSkip : 0);
    const [segmentName, setSegmentName] = useState([]);

    React.useEffect(() => {
        let allExpanded = [];
        mapTree(props.items, subItemsField, (item) => {
            allExpanded.push(item[DATA_ITEM_KEY]);
        });
        setExpandedState(allExpanded);
    }, []);
    const [selectedState, setSelectedState] = React.useState({});
    const [expandedState, setExpandedState] = React.useState({});
    const [itemsLength, setItemsLength] = React.useState(props.items.length);
    // const total = props.items.length;
    const pageSizes = [5, 10, 20, 25, 30];
    // const intervalRef = React.useRef(null);
    let groupDropDownData = [];
    let userDropDownData = [];
    let statusDropDownData = [];
    let typeDropDownData = [];
    const groups = new Set();
    const users = new Set();
    const status = new Set();
    const type = new Set();
    mapTree(props.items, subItemsField, (item) => {
        // type.add(item.objectType);
        type.add(item.subType);
        typeDropDownData = Array.from(type).sort();
        //Make dropdown pretty
        // for(let i = 0; i < typeDropDownData.length ; i++){
        //     typeDropDownData[i] = typeDropDownData[i].charAt(0).toUpperCase() + typeDropDownData[i].substr(1);
        // }
        groups.add(item.groupName);
        groupDropDownData = Array.from(groups).sort((a, b) => a.localeCompare(b));
        users.add(item.userName);
        userDropDownData = Array.from(users).sort();
        status.add(item.status);
        // statusDropDownData = Array.from(status).sort();
        //Make dropdown pretty
        statusDropDownData = Array.from(status).map(w=>w.toLowerCase()).sort();
        for(let i = 0; i < statusDropDownData.length ; i++){
            statusDropDownData[i] = statusDropDownData[i].charAt(0).toUpperCase() + statusDropDownData[i].substr(1);
        }
        return extendDataItem(item, subItemsField, {});
    });
    // const addExpandField = (dataTree) => {
    //     const expanded = expandedState;
    //     return mapTree(dataTree, subItemsField, (item) =>
    //         extendDataItem(item, subItemsField, {
    //             expanded: expanded.includes(item.id)
    //         })
    //     );
    // };
    const handlePageChange = (event) => {
        const { skip, take } = event;
        localStorage.setItem('pageSkip', skip);
        localStorage.setItem('pageTake', take);
        setSkip(skip);
        setTake(take);
        setTimeout(() => {
            table.current.element.scrollTop = 0;
        });
    };

    const headerSelectionValue = (dataState, selectedState) => {
        let allSelected = true;
        mapTree(dataState, subItemsField, (item) => {
            allSelected = allSelected && selectedState[idGetter(item)];
            return item;
        });
        return allSelected;
    };
    const itemsRender = (rendering, colProps) => {
        const items = [...rendering];
        items.pop();
        items.push(
            // <ColumnLock key="lock" {...colProps} />,
            <ColumnChooser key="columns" {...colProps} />); //Customize Column Menu Dropdown, Columns list
        return items;
    };
    const ColumnMenuTextColumnCustom = props => <ColumnMenuTextColumn {...props} itemsRender={itemsRender} />;


    const [state, setState] = React.useState({
        data: [...props.items],
        dataState: {
            sort: [
                {
                    field: "updatedAt",
                    dir: "desc",
                },
            ],

            multiple: true,
        },
        filter: [],
        // skip:0,
        // take:10,
        // columns
    });
    const dropDownFilterOperator = (value, current) => {
        let newValue =
            !value ||
            !value.length ||
            value.find((val) =>
                val === 'Not Selected' && current === null
                    ? 'isnull'
                    : value.find((val) => val === current)
            );
        return newValue;
    };
    const processData = () => {
        const storedFilterStates = JSON.parse(localStorage.getItem("filterState"));

        const { data, dataState } = state;
        const sortedData = orderBy(data, dataState.sort, subItemsField);

        let filteredData;

        if(storedFilterStates){
            storedFilterStates.forEach(e => {
                if(e.operator === undefined){
                    e.operator = dropDownFilterOperator.bind(undefined, e.value)
                }
            })
            filteredData = filterBy(sortedData, storedFilterStates, subItemsField);
        }else{
            filteredData = filterBy(sortedData, state.filter, subItemsField);
        }

        return mapTree(filteredData, subItemsField, (item) => {
                return extendDataItem(item, subItemsField, {
                    selected: selectedState[idGetter(item)],
                    // expanded: true,
                    expanded: expandedState[idGetter(item)],
                })
            }
        );
    };
    const NameFilter = (props) => {
        return (
            <TreeNameSearchFilter
                {...props}
                defaultItem="Filter Name"
            />
        )
    };
    const TypeFilter = (props) => {
        return (
            <TreeTypeMultiSelectFilter
                {...props}
                data={typeDropDownData}
                defaultItem="Filter Type"
            />
        )
    };
    const GroupFilter = (props) => {
       return (
            <TreeGroupMultiSelectFilter
                {...props}
                data={groupDropDownData}
                defaultItem="Filter Group"
            />
        )
    };
    const UserFilter = (props) => (
        <TreeUserMultiSelectFilter
            {...props}
            data={userDropDownData}
            defaultItem="Filter User"
        />
    );
    const StatusFilter = (props) => (

        <TreeStatusMultiSelectFilter
            {...props}
            data={statusDropDownData}
            defaultItem="Filter Status"

        />
    );
     const initialColumns = [
        {
            field: "selected",
            headerSelectionValue: headerSelectionValue(processData(), selectedState),
            // headerSelectionValue: newHeaderSelectionValue,
            cell: TreeListSelectionCell,
            headerCell: TreeListHeaderSelectionCell,
        },
         // {
         //     title: "Id",
         //     field: 'id',
         //     // expandable: true,
         //     // filterCell:TreeListTextFilter,
         //     // cell:SegmentTableNameCell,
         //     // columnMenu: ColumnMenuTextColumnCustom,
         //     width:"10px"
         // },
        {
            title: "Name",
            field: 'objectName',
            expandable: true,
            filterCell:NameFilter,
            cell:SegmentTableNameCell,
            columnMenu: ColumnMenuTextColumnCustom,
        },
        // {
        //     title: "Segment Type",
        //     field: 'objectType',
        //     filterCell:TreeListTextFilter,
        //     cell:SegmentTypeCell,
        //     columnMenu: ColumnMenuTextColumnCustom,
        //     width:"20px"
        // },
         {
             title: "Party",
             field: 'party',
             cell:SegmentPartyTypeCell,
             // filterCell:TreeListTextFilter,
             // columnMenu: ColumnMenuTextColumnCustom,
             // width:"1%"
         },
        {
            title: "Type",
            field: 'subType',
            cell:SegmentTableTypeCell,
            filterCell:TypeFilter,
            // columnMenu: ColumnMenuTextColumnCustom,
            width:"10px"
        },
         {
             title: "Scheduled",
             field: 'scheduled',
             cell:ScheduledCell,
             // filterCell:TreeListTextFilter,
             // columnMenu: ColumnMenuTextColumnCustom,
             // width:"1%"
         },
         {
             title: "Size",
             field: 'objectSize',
             cell:SegmentSizeCell,
             // filterCell:TreeListTextFilter,
             // columnMenu: ColumnMenuNumericColumnCustom,
             // width:"2%"
        },
        {
             title: "Served",
             field: 'pctServed',
             cell:PctServedCell,
             // filterCell:TreeListTextFilter,
             // columnMenu: ColumnMenuNumericColumnCustom,
             // width:"12px"
        },
        {
             title: "Created",
             field: 'createdAt',
             cell:CreatedAtCell,
             // filterCell:TreeListTextFilter,
             // columnMenu: ColumnMenuDateColumnCustom,
             // width:"2%"
        },
        {
            title: "Updated",
            field: 'updatedAt',
            cell:LastUpdatedCell,
            // filterCell:TreeListTextFilter,
            // columnMenu: ColumnMenuDateColumnCustom,
            // width:"2%"
        },
        {
            title: "Status",
            field: 'status',
            filterCell:StatusFilter,
            cell:StatusCell,
            // columnMenu: ColumnMenuTextColumnCustom,
            // width:"2%"

        },{
             title: "Group",
             field: 'groupName',
             expandable: true,
             filterCell:GroupFilter,
             cell:GroupNameCell,
             // columnMenu: ColumnMenuTextColumnCustom,
             // width:"200px"
         },
         {
             title: "User",
             field: 'userName',
             expandable: true,
             filterCell:UserFilter,
             cell:UserNameCell,
             // columnMenu: ColumnMenuTextColumnCustom,
             // width:"200px"
         },

         // {
         //     title: "Membership Changed",
         //     field: 'profileDataObsolete'
         //     // omit: hideData === true
         //     // cell:ProfileDataObsoleteCell,
         //     // filterCell:TreeListTextFilter,
         //     // columnMenu: ColumnMenuNumericColumnCustom,
         //     // width:"2%"
         // },
         // FD:10606
        // {
        //     title: "Actions",
        //     field: 'actions',
        //     cell: ActionsCell,
        //     // columnMenu: ColumnMenuTextColumnCustom,
        //     // width:"2%"
        // }
    ];
    const [columns, setColumns] = React.useState(initialColumns);
    let savedColumns = JSON.parse(localStorage.getItem("visibleColumns"));
    const [visibleColumns, setVisibleColumns] = React.useState(savedColumns !== null ? savedColumns : columns.map(c => c.field));
    const onLock = React.useCallback(({
                                          field,
                                          locked,
                                          closeMenu
                                      }) => {
        const index = columns.findIndex(c => c.field === field);
        const column = columns[index];
        if (column) {
            const newColumns = [...columns];
            newColumns.splice(index, 1, {
                ...column,
                locked: !locked
            });
            setColumns(newColumns);
            closeMenu();
        }
    }, [columns]);


    const onExpandChange = React.useCallback(
        (e) => {
            setExpandedState({
                ...expandedState,
                [idGetter(e.dataItem)]: !e.value
            });
        },
        [expandedState]
    );
    // const lastSelectedIndexRef = React.useRef(0);

    const onSelectionChange = React.useCallback(
        (event) => {

            const checkboxElement = event.syntheticEvent.target;
            const checked = checkboxElement.checked;

            setSelectedState({
                ...selectedState,
                [idGetter(event.dataItem)]: checked,
            });

           if(checked === true){
               props.profileFormik.values.selectedSegments.push(event.dataItem);
               props.profileFormik.setFieldValue();
           }else if(checked === false){
               props.profileFormik.setFieldValue("selectedSegments", props.profileFormik.values.selectedSegments.filter((i)=>{return i.id !== event.dataItem.id}));
           }

        },
        [selectedState, props.profileFormik.values]
    );

    useEffect(() => {
        let initialState = {};
        props.items.map((item) => {
            initialState[item.id] = true;
            expandItem(initialState, item);
        });
        setExpandedState(initialState);
    }, []);

    const expandItem = (initialState, item) => {
        let children = item.children;
        children.forEach((child) => {
            expandItem(initialState, child);
        });
        initialState[item.id] = true;
    };

    useEffect(()=>{

    },[props.items,selectedState,props.profileFormik.values,onSelectionChange,skip,take]);

    useEffect(()=>{
        //Update total items when filter changes.
        setItemsLength(processData().length);
    },[processData().length]);

    const onHeaderSelectionChange = React.useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};

        event.dataItems.forEach((item) => {
            if(item.objectType !== 'Activation' && item.objectType !== 'Impression-Activation'){
                newSelectedState[idGetter(item)] = checked;
                item.selected = checked;
                if(item.selected === true){
                    props.profileFormik.values.selectedSegments.push(item);
                    props.profileFormik.setFieldValue();
                }else if (item.selected === false){
                    props.profileFormik.setFieldValue("selectedSegments", []);
                }
            }
        });
        setSelectedState(newSelectedState);

    }, [skip, take, selectedState, props.profileFormik.values]);

    useEffect(()=>{

    },[onHeaderSelectionChange, state, expandedState, itemsLength]);

    useEffect(() => {

            const intervalCall = setInterval(() => {

                props.loadItems();
                setState(prevState => ({
                    ...prevState,
                    data: props.items,
                }));
                // console.log('streaming')
            }, 20000);
            return () => {
                // clean up
                clearInterval(intervalCall);
            };
        },
        [props.items]

    );


    const onColumnResize = (event) => {
        if (table) {
            table.current.element.style.width = `${event.totalWidth}px`;
        }
        if (event.end) {
            // Update both the standalone columns state and the state object
            setColumns(event.columns);
            setState(prevState => ({
                ...prevState,
                columns: event.columns,
            }));
        }
    };

    const onColumnReorder = (event) => {
        setColumns(event.columns);
    };


    const onColumnToggle = React.useCallback(({visible}) => {
        localStorage.setItem("visibleColumns",JSON.stringify(visible));
        console.log(visible);
        setVisibleColumns(visible);
    }, []);

    const handleDataStateChange = (event) => {
        setState({
            ...state,
            dataState: event.dataState,
        });
    };
    const TreeListPager = (props) => {
        return (
            <div className={"tree-list-pager-wrapper"}>
                <Pager
                    {...props}
                    skip={skip}
                    take={take}
                    total={itemsLength}
                    buttonCount={5}
                    info={true}
                    pageSizes={pageSizes ? pageSizes : undefined}
                    previousNext={true}
                    // Configure the Pager props here.
                    // See example: https://www.telerik.com/kendo-react-ui/components/datatools/pager/
                />
             </div>
        );
    };
    const onFilterChange = (event) => {
        setState({
            ...state,
            filter: event.filter,
        });
        setSkip(0);
        localStorage.setItem('pageSkip', skip);
    };
    //Assign Row Color
    const rowRender = (row, props) => {
        let color = '';
        let level = props.level.length;
        const hasChildren =
            props.dataItem[subItemsField] && props.dataItem[subItemsField].length > 0;

        if (!hasChildren && level === 1) {
            color = '#fff';
        } else {
            if (level === 1) {
                color = 'rgb(89, 165, 213, 0.2)';
            } else if (level === 2) {
                color = 'rgb(255, 239, 186, 0.2)';
            } else if (level === 3) {
                color = 'rgb(210, 202, 250, 0.2)';
            } else if (level === 4) {
                color = 'rgb(210, 255, 186, 0.2)';
            } else if (level === 5) {
                color = 'rgb(250, 159, 2, 0.2)';
            } else if (level === 6) {
                color = 'rgb(2, 23, 250, 0.2)';
            } else if (level === 7) {
                color = 'rgb(89, 165, 213, 0.2)';
            } else if (level === 8) {
                color = 'rgb(255, 239, 186, 0.2)';
            } else if (level === 9) {
                color = 'rgb(210, 202, 250, 0.2)';
            } else if (level === 10) {
                color = 'rgb(210, 255, 186, 0.2)';
            } else if (level === 11) {
                color = 'rgb(250, 159, 2, 0.2)';
            } else if (level === 12) {
                color = 'rgb(2, 23, 250, 0.2)';
            }
        }

        let style = { ...row.props.style, backgroundColor: color};
        return <tr {...row.props} style={style}></tr>;
    };
    return (
        <>
            <div style={{height: "auto"}}>
                <ColumnMenuContext.Provider value={{
                    onLock,
                    onColumnToggle,
                    columns,
                    visibleColumns
                }}>
                <TreeList
                {...state.dataState}
                columns={columns.filter(c => visibleColumns.includes(c.field))}
                ref={table}
                rowRender={rowRender}
                style={{
                    width: "100%",
                    maxHeight: "calc(100vh - 140px)",
                    overflow: "auto",
                }}
                tableProps={{
                    ref: table,
                    style: {
                        width: "100%",
                    },
                }}
                rowHeight={1}
                sortable={true}
                reorderable={true}
                // resizable={true}
                // skip={skip}
                // take={take}
                onPageChange={handlePageChange}
                onFilterChange={onFilterChange}
                filter={state.filter}
                expandField={expandField}
                subItemsField={subItemsField}
                selectedField={selectedField}
                onExpandChange={onExpandChange}
                data={processData().slice(skip, skip+take)}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                // onColumnResize={onColumnResize}
                onColumnReorder={onColumnReorder}
                onDataStateChange={handleDataStateChange}
                pager={TreeListPager}
                />
                </ColumnMenuContext.Provider>
            </div>
        </>
    );
};

export default TreeSegmentsTable;