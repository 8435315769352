export const ethnicBackgroundConfig = {
    "AFR": "African American",
    "AUS": "Australian",
    "BRZ": "Brazil",
    "CAS": "Central Asian Other (all Central Asian codes in Other)",
    "CHI": "Chinese",
    "CUB": "Cuban",
    "DOM": "Dominican",
    "DUT": "Dutch",
    "ENG": "English",
    "EUO": "East European Other (all minus Pol/Rus)",
    "FEO": "Far East Other",
    "FRA": "French",
    "GER": "German",
    "HON": "Honduran",
    "HSO": "Hispanic Other",
    "IND": "Indian",
    "IRE": "Irish",
    "ITA": "Italian",
    "JAP": "Japanese",
    "JEW": "Jewish",
    "KOR": "Korean",
    "MEO": "Middle East Other",
    "MEX": "Mexican",
    "NTV": "Native American",
    "NZL": "New Zealand",
    "PAK": "Pakistani",
    "PHL": "Filipino",
    "PLN": "Polynesian Other (all Polynesian codes in Other)",
    "POL": "Polish",
    "PRC": "Puerto Rican",
    "RUS": "Russian",
    "SAO": "Southeast Asian Other",
    "SAU": "Saudi Arabia",
    "SCO": "Scottish",
    "SNO": "Scandinavian Other (all minus Sweden)",
    "SPN": "Spanish",
    "SWE": "Swedish",
    "SYR": "Syria",
    "TAI": "Thai",
    "TUR": "Turkey",
    "VTN": "Vietnamese",
    "WLS": "Welsh",
    "WUO": "West European Other",
};
