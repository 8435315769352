import { Bar } from "test-react-chartjs-2";
import { Chart as ChartJS, Legend } from "chart.js";
import "./TopBusinessOrganizations.css";
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NumberFormat from "react-number-format";
const TopBusinessOrganizations = ({ data, chartId, organizationsRef, topBusinessOrgHCOptions }) => {
  const topBusinessOrgData = {
    labels: data.labels,
    datasets: [
      {
        label: "Top Business Organizations",
        data: data.values,
        backgroundColor: data.backgroundColor,
      },
    ],
  };

  const pluginOptions = {
    legend: {
      display: false,
      labels: {
        font: {
          size: 18,
          weight: 500,
        },
      },
    },
  };

  ChartJS.defaults.font.size = 17;
  ChartJS.defaults.color = "#000000";

  return (
    <>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split" id={chartId}>
        <div className="card">
          <div className="text-center">
            <h4>Top Business Organizations</h4>
          </div>
          <div className="row">
      <div className="col-xl-6 col-lg-6 col-md-6">
        <div className="report-card">
          <div className="card-body">
            <div className="table-responsive business-org">
              <table className="table table-responsive-md table-striped">
                <thead>
                  <tr>
                    <th className="col-xl-4 col-lg-4 col-md-4">
                      <strong>Organization</strong>
                    </th>
                    <th className="right col-xl-2 col-lg-2 col-md-2">
                      <strong>Revenue in millions</strong>
                    </th>
                    {/* <th className="col-xl-2 col-lg-2 col-md-2"></th> */}
                  </tr>
                </thead>
                <tbody>
                  {data.rows.map((item, index) => {
                    return (
                      <tr>
                        <td className="text-nowrap">{item["Organization"]}</td>
                        {/* <td className="right">{item["Revenue in millions"].toFixed(2)}</td> */}
                        {/*<td className="right">{item["Revenue in millions"]}</td>*/}
                        <td className="right">
                          <NumberFormat
                              thousandsGroupStyle="thousand"
                              value={item["Revenue in millions"]}
                              decimalSeparator="."
                              displayType="text"
                              type="text"
                              thousandSeparator={true}
                              allowNegative={true}
                          />
                        </td>
                        {/* <td /> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
        <div className="card">
          <div className="card-body-businessgraphs business-org">
            <Bar
                ref={organizationsRef}
              data={topBusinessOrgData}
              // width={600}
              // height={600}
              options={{
                maintainAspectRatio: false,
                plugins: pluginOptions,
                indexAxis: "y",
              }}
            />
            {/*<HighchartsReact*/}
            {/*    constructorType={"chart"}*/}
            {/*    options={topBusinessOrgHCOptions}*/}
            {/*    highcharts={Highcharts}*/}
            {/*/>*/}
          </div>
        </div>
      </div>
      </div>
        </div>
      </div>
    </>
  );
};
export default TopBusinessOrganizations;
