import React, {useEffect, useState} from "react";

import {getDemographicHomeValue} from "../../../actions/AudienceDesignActions";
import "./HomeValueRange.css";
import {MultiSelect} from "@progress/kendo-react-dropdowns";

const HomeValueRange = (props) => {

    const [value, setValue] = useState([]);

    const [homeValueRangeOptions, setHomeValueRangeOptions] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const res = await getDemographicHomeValue();
            if (res) {
                let options = [];
                (res.data || []).forEach((item) => {
                    let start = "";
                    let end = "";
                    if (item.min_val < 1000) {
                        start = item.min_val.toString()+"K";
                    } else {
                        if (item.min_val % 1000 > 0) {
                            start = Math.trunc(item.min_val/1000).toString()+"M+";
                        } else {
                            start = (item.min_val/1000).toString()+"M";
                        }
                    }
                    if (item.max_val < 1000) {
                        end = item.max_val.toString()+"K";
                    } else {
                        if (item.max_val % 1000 > 0) {
                            end = Math.trunc(item.max_val/1000).toString()+"M+";
                        } else {
                            end = (item.max_val/1000).toString()+"M";
                        }
                    }

                    let range = {text: start+" - "+end, value: item.home_value_cd };
                    options.push(range);
                });
                setHomeValueRangeOptions(options);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        let arr = [];
        props.audienceState.home_value_cd.forEach(rangeValue => {
            homeValueRangeOptions.find(option => {
                if (option.value === rangeValue) {
                    arr.push(option);
                }
                ;
            })
        })
        setValue(arr);
    }, [props.audienceState, homeValueRangeOptions]);

    const onHomeValueRangeChange = (e) => {
        let values = [];
        setValue(e.value)
        e.value.forEach((val) => {
            values.push(val.value);
        });
        // props.audienceState.home_value_cd = values;
        props.handleAudienceState(prevState => {
            return {...prevState, home_value_cd: values}}
        );
    };

    const itemRender = (li, itemProps) => {
        const itemChildren = (
            <span
                style={{
                    // color: "#00424D",
                }}
            >
                {li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }

    return (
        <>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={homeValueRangeOptions}
                    autoClose={false}
                    itemRender={itemRender}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    onChange={onHomeValueRangeChange}
                    size="large"
                    value={value}
                    // style={{width: "32rem"}}
                />
            </div>
        </>
    );
}

export default HomeValueRange;
