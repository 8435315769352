import {useEffect, useState} from "react";
import {
    boostrap2TableSearchOptions,
    dateFormatter,
    emailFormatter,
    ExportCSV, exportCSVOptions,
    loginColumnFormatter,
    noDataIndication,
    paginationOptions,
    progressbarFormatter,
    semcastAxios
} from "../../../../Utils/Common";
import {SEGMENT_IN_PROGRESS_API, SUCCESS_STATUS} from "../../../../api/constants";
import LoadOverlay from "../../../Components/SharedComponents/LoadOverlay";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";

function SegmentInProgress(props) {

    const [loader, setLoader] = useState(false)
    const [rows, setRows] = useState([]);
    const {SearchBar} = Search;
    const defaultSorted = [{
        dataField: 'updated_at',
        order: 'desc'
    }];
    const columns = [
        {
            dataField: 'user_id',
            text: 'Login As',
            formatter: loginColumnFormatter
        },
        {
            dataField: 'email',
            text: 'User',
            formatter: emailFormatter,
            sort: true
        },
        {
            dataField: 'company',
            text: 'Company',
            sort: true
        },
        {
            dataField: 'segment_name',
            text: 'Segment',
            sort: true
        },
        {
            dataField: 'group_name',
            text: 'Segment Group',
            sort: true
        },
        {
            dataField: 'overall_percent',
            text: 'Progress',
            sort: true,
            formatter: progressbarFormatter
        },
        {
            dataField: 'updated_at',
            text: 'Last Update',
            sort: true,
            formatter: dateFormatter
        },
        {
            dataField: 'created_at',
            text: 'Created On',
            sort: true,
            formatter: dateFormatter
        }
    ];

    useEffect(async () => {
        try {
            setLoader(true)
            await loadRows();
        } catch (e) {
            setRows([])
        } finally {
            setLoader(false)
        }
    }, []);

    const broadcastReloadEvent = (count)=>{
        props.broadcastReloadEvent(props.uniqueKey, count);
    }


    const loadRows = async () => {
        const res = await semcastAxios.get(SEGMENT_IN_PROGRESS_API, {
            withCredentials: true,
        });
        if (res.data.status === SUCCESS_STATUS) {
            if (res?.data?.data?.length) {
                res?.data?.data.forEach((obj, index) => {
                    obj['id'] = index
                })
                setRows(res.data.data)
                broadcastReloadEvent(res?.data?.data.length)
            }
        }
    };

    return (
        <>
            <LoadOverlay active={loader}>
                <ToolkitProvider
                    keyField="id"
                    bootstrap4={true}
                    data={rows}
                    columns={columns}
                    search={boostrap2TableSearchOptions()}
                    exportCSV={exportCSVOptions()}
                >
                    {
                        props => (
                            <div>
                                <ExportCSV {...props.csvProps}/>
                                <SearchBar {...props.searchProps}
                                           className="form-control form-control-custom"/>
                                <BootstrapTable {...props.baseProps}
                                                pagination={paginationFactory(paginationOptions(rows))}
                                                filter={filterFactory()}
                                                defaultSorted={defaultSorted}
                                                bordered={false}
                                                noDataIndication={noDataIndication}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>

            </LoadOverlay>
        </>
    )
}

export default SegmentInProgress