import React, {useEffect, useState} from "react";

import {getDemographicIncome} from "../../../actions/AudienceDesignActions";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import "./IncomeRange.css"

const IncomeRange = (props) => {

    const [value, setValue] = useState(props.controlsState ?
        props.controlsState.get("income_cd") : []);

    const [incomeRangeOptions, setIncomeRangeOptions] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const res = await getDemographicIncome();
            if (res) {
                let options = [];
                (res.data || []).forEach((item) => {
                    // let range = {"lowValue": item.min_amt, "highValue": item.max_amt, "id": item.income_cd};
                    // rangeValues.push(range);
                    let start = item.min_amt < 1000 ? item.min_amt.toString()+"K" : item.min_amt.toString()+"M";
                    let end = item.max_amt < 1000 ? item.max_amt.toString()+"K" : (item.max_amt/1000).toString()+"M";
                    let range = {text: start+" - "+end, value: item.income_cd };
                    options.push(range);
                });
                setIncomeRangeOptions(options);
            }
        }
        fetchData();
    }, []);

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.controlsState) return;
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.income_cd) {
                props.segmentEditInfo.data.segment_design.primaryData.income_cd.forEach(rangeValue => {
                    incomeRangeOptions.find(option => {
                        if (option.value === rangeValue) {
                            arr.push(option);
                        }
                        ;
                    })
                })
                setValue(arr);
            }
        }
    }, [props.segmentEditInfo]);

    const onIncomeRangeChange = (e) => {
        let values = [];
        setValue(e.value);
        e.value.forEach((val) => {
            values.push(val.value);
        });
        props.formik.values.segment_design.primaryData.income_cd = values;
        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("income_cd", e.value)));
        }
    };

    const itemRender = (li, itemProps) => {
        const index = itemProps.index;
        const itemChildren = (
            <span
                style={{
                    // color: "#00424D",
                }}
            >
                {li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }

    return (
        <>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={incomeRangeOptions}
                    autoClose={false}
                    itemRender={itemRender}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    onChange={onIncomeRangeChange}
                    size="large"
                    value={value}
                    // style={{width: "32rem"}}
                />

            </div>
        </>
    );
}

export default IncomeRange;
