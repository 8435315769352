import {useState} from "react";
import React, { Fragment } from 'react';
import {Badge, Tab, Tabs} from "react-bootstrap";
import SegmentErrors from "../components/JobStatuses/SegmentErrors";
import SegmentInProgress from "../components/JobStatuses/SegmentInProgress";
import ActivationErrors from "../components/JobStatuses/ActivationErrors";
import ActivationInProgress from "../components/JobStatuses/ActivationInProgress";
import AttributionErrors from "../components/JobStatuses/AttributionErrors";
import AttributionInProgress from "../components/JobStatuses/AttributionInProgress";
import {UserContext} from "../../../contexts/UserContext";
import "./JobStatus.css";

function JobStatus() {
    const SEGMENT_ERRORS = 'segment_errors'
    const SEGMENT_IN_PROGRESS = 'segment_in_progress'
    const ACTIVATION_ERRORS = 'activation_errors'
    const ACTIVATION_IN_PROGRESS = 'activation_in_progress'
    const ATTRIBUTION_ERRORS = 'attribution_errors'
    const ATTRIBUTION_IN_PROGRESS = 'attribution_in_progress'

    const { user, setUser } = React.useContext(UserContext);
    const [segmentsBlockKey, setSegmentsBlockKey] = useState(SEGMENT_ERRORS); // or segment_in_progress
    const [activationsBlockKey, setActivationsBlockKey] = useState(ACTIVATION_ERRORS); // or activation_in_progress
    const [attributionsBlockKey, setAttributionsBlockKey] = useState(ATTRIBUTION_ERRORS); // or attribution_in_progress

    const [segment_errors, setSegment_errors] = useState(0);
    const [segment_in_progress, setSegment_in_progress] = useState(0);
    const [activation_errors, setActivation_errors] = useState(0);
    const [activation_in_progress, setActivation_in_progress] = useState(0);
    const [attribution_errors, setAttribution_errors] = useState(0);
    const [attribution_in_progress, setAttribution_in_progress] = useState(0);


    const reloadEventFromChild = async (key,value) => {
        switch (key) {
            case SEGMENT_ERRORS: {
                setSegment_errors(value)
                break;
            }
            case SEGMENT_IN_PROGRESS: {
                setSegment_in_progress(value)
                break;
            }
            case ACTIVATION_ERRORS: {
                setActivation_errors(value)
                break;
            }
            case ACTIVATION_IN_PROGRESS: {
                setActivation_in_progress(value)
                break;
            }
            case ATTRIBUTION_ERRORS: {
                setAttribution_errors(value)
                break;
            }
            case ATTRIBUTION_IN_PROGRESS: {
                setAttribution_in_progress(value)
                break;
            }
            default: {
                break;
            }
        }
        //console.log("reload event fired ", key,value)
      }
    return (
        <>
            { user?.isAdmin &&
                <div>
                    {/*className="content-body"*/}
                    {/* row */}
                    <div className="container-fluid admin-job-status">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">

                                <div className="tab-content mt-3">
                                    <div className="card">
                                        <div className="card-header d-block">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <div className="card-title mt-3">Segments</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Tabs
                                                id="controlled-tab-example"
                                                activeKey={segmentsBlockKey}
                                                onSelect={(k) => setSegmentsBlockKey(k)}
                                                className="mb-3"
                                            >
                                                <Tab eventKey={SEGMENT_ERRORS} className="errors-tab"
                                                     title={
                                                         <React.Fragment>
                                                             Segment Errors &nbsp;
                                                             <Badge pill bg="danger">{segment_errors}</Badge>
                                                         </React.Fragment>
                                                     }>
                                                    <SegmentErrors uniqueKey={SEGMENT_ERRORS}
                                                                   broadcastReloadEvent={reloadEventFromChild}/>
                                                </Tab>
                                                <Tab eventKey={SEGMENT_IN_PROGRESS}
                                                     title={
                                                         <React.Fragment>
                                                             In Progress &nbsp;
                                                             <Badge pill bg="secondary">{segment_in_progress}</Badge>
                                                         </React.Fragment>
                                                     }>
                                                    <SegmentInProgress uniqueKey={SEGMENT_IN_PROGRESS}
                                                                       broadcastReloadEvent={reloadEventFromChild}/>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header d-block">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <div className="card-title mt-3">Activations</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Tabs
                                                id="controlled-tab-example"
                                                activeKey={activationsBlockKey}
                                                onSelect={(k) => setActivationsBlockKey(k)}
                                                className="mb-3"
                                            >
                                                <Tab eventKey={ACTIVATION_ERRORS}
                                                     title={
                                                         <React.Fragment>
                                                             Activation Errors &nbsp;
                                                             <Badge pill bg="danger">{activation_errors}</Badge>
                                                         </React.Fragment>
                                                     }>
                                                    <ActivationErrors uniqueKey={ACTIVATION_ERRORS}
                                                                      broadcastReloadEvent={reloadEventFromChild}/>
                                                </Tab>
                                                <Tab eventKey={ACTIVATION_IN_PROGRESS}
                                                     title={
                                                         <React.Fragment>
                                                             Activation In Progress &nbsp;
                                                             <Badge pill bg="secondary">{activation_in_progress}</Badge>
                                                         </React.Fragment>
                                                     }>
                                                    <ActivationInProgress uniqueKey={ACTIVATION_IN_PROGRESS}
                                                                          broadcastReloadEvent={reloadEventFromChild}/>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header d-block">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <div className="card-title mt-3">Attributions</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <Tabs
                                                id="controlled-tab-example"
                                                activeKey={attributionsBlockKey}
                                                onSelect={(k) => setAttributionsBlockKey(k)}
                                                className="mb-3"
                                            >
                                                <Tab eventKey={ATTRIBUTION_ERRORS}
                                                     title={
                                                         <React.Fragment>
                                                             Attribution Errors &nbsp;
                                                             <Badge pill bg="danger">{attribution_errors}</Badge>
                                                         </React.Fragment>
                                                     }>
                                                    <AttributionErrors uniqueKey={ATTRIBUTION_ERRORS}
                                                                       broadcastReloadEvent={reloadEventFromChild}/>
                                                </Tab>
                                                <Tab eventKey={ATTRIBUTION_IN_PROGRESS}
                                                     title={
                                                         <React.Fragment>
                                                             Attribution In Progress &nbsp;
                                                             <Badge pill
                                                                    bg="secondary">{attribution_in_progress}</Badge>
                                                         </React.Fragment>
                                                     }>
                                                    <AttributionInProgress uniqueKey={ATTRIBUTION_IN_PROGRESS}
                                                                           broadcastReloadEvent={reloadEventFromChild}/>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/***********************************
                     Content body end
                     ************************************/}
                </div>
            }
        </>
    );
}

export default JobStatus;
