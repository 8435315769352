import "./TopContextualSamples.css";

import React from "react";

const TopContextualSampleCounts = ({data, chartId}) => {

    return (
        <>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split" id={chartId}>
                <div className="card prevent-split">
                    <div className="text-center prevent-split">
                        <h4>Top Contextual Sample Counts</h4>
                    </div>
                    <div className="row prevent-split">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split">
                            <div className="report-card prevent-split">
                                <div className="card-body prevent-split">
                                    <div className="table-responsive business-topcontextual prevent-split">
                                        <table className="table table-responsive-md business table-striped prevent-split">
                                            <thead>
                                            <tr>
                                                <th className="col-xl-1 col-lg-1 col-md-1">
                                                    <strong>Rank</strong>
                                                </th>
                                                <th className="col-xl-2 col-lg-2 col-md-2">
                                                    <strong>Sites</strong>
                                                </th>
                                                <th className="col-xl-2 col-lg-2 col-md-2">
                                                    <strong>People</strong>
                                                </th>
                                                <th className="col-xl-2 col-lg-2 col-md-2">
                                                    <strong>Places</strong>
                                                </th>
                                                <th className="col-xl-2 col-lg-2 col-md-2">
                                                    <strong>Organizations</strong>
                                                </th>
                                                <th className="white-space col-xl-2 col-lg-2 col-md-2">
                                                    <strong>Categories</strong>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className={"prevent-split"}>
                                            {data.rows.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <td>{item["Rank"]}</td>
                                                        <td>{item["Sites"]}</td>
                                                        <td>{item["People"]}</td>
                                                        <td>{item["Places"]}</td>
                                                        <td>{item["Organizations"]}</td>
                                                        <td>{item["Categories"]}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default TopContextualSampleCounts;
