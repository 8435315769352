import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {
    ActivateView,
    COMPANIES,
    curateCOMPANIES,
    dspCONNECTIONS,
    MeasurementTiles,
    IndirectTiles,
    SupplySideTiles,
    SocialTiles,
    DirectIntegrationsTiles,
    AllInOneTiles, DirectMailTiles
} from "../../helpers/ActivateSegmentHelpers";
import ActivateSegmentForm from "../Components/Segmentation/ActivateSegmentForm"
import LoadOverlay from "../Components/SharedComponents/LoadOverlay"
import "./ActivateSegment.css"
import {semcastAxios, showErrorToastMessage} from "../../Utils/Common";
import {ACTIVATION_CREATIVE_TAGs, PLATFORM_NAME_PLACE_HOLDER} from "../../api/constants";
import {getFTPSettings} from "../../actions/OnBoardingActions";

function ActivateForm() {
    //   const {id} = useParams();
    const searchParams = new URLSearchParams(document.location.search)
    const id = searchParams.get('id');
    const activatedCompany = searchParams.get('company');
    const [uploadedRecords, setUploadedRecords] = useState([]);
    const [totalRecordsUploaded, setTotalRecordsUploaded] = useState(0)
    const [selectedCreativeTags, setSelectedCreativeTags] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [hiddenOnBoardingCompanies, setHiddenOnBoardingCompanies] = useState([]);

    const [companyFormLoader, setCompanyFormLoader] = useState(false)
    const [companiesLoader, setCompaniesLoader] = useState(false);
    const [allSavedSettings, setAllSavedSettings] = useState([]);
    const [currentConnection, setCurrentConnection] = useState(
        {text: '', id: 0}
    );
    const [activateView, setActivateView] = useState(new ActivateView());
    // const activateView = new ActivateView();
    const myRef = useRef(null);
    let history = useHistory();
    const executeScroll = () => {
        myRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    }

    const reloadEventFromChild = async () => {
        setCompaniesLoader(true)
        await activateView.fetchAlreadyActivatedData(id).then(r => {
            activateView.markCompanyBlocks();
            setCompaniesLoader(false);
        }).catch((err) => {
            if (err.status === 403 || err.status === 401) {
                showErrorToastMessage("Your account did not have the permission to proceed, please contact support", 7)
                history.push('/segmentation-dashboard');
            }
        });
    }
    const formCloseEvent = async () => {
        setSelectedCompany(null)
    }
    const getSelectedCreativeTagInfo = async(company) => {
        if (company === 'semcasting' || company === 'semcastingmeasurement' || company === 'instepmeasurement') return;
        const url= ACTIVATION_CREATIVE_TAGs.replace(PLATFORM_NAME_PLACE_HOLDER, company);
        let response = null;
        try {
            response = await semcastAxios.get((url), {withCredentials: true});
        } catch(error) {
        }
        if (response) {
            setSelectedCreativeTags(response.data.creativeTags);
        } else {
            setSelectedCreativeTags([]);
        }
    }
    const connections = allSavedSettings?.map((item, i) => {
        if (allSavedSettings.length > 0) {
            return (
                {text: item.connection_name, id: item.id, protocol: item.transfer_protocol}
            )
        } else {
            return (
                {text: "No Saved Connections", id: 0}
            )
        }
    });
    const getFTPConnectionList = async(company) => {
        if (company === 'semcasting_directmail') {
            getFTPSettings().then((res) => {
                if (res && res.data.length) {
                    setAllSavedSettings(res.data);
                    const setting = res.data[res.data.length-1];
                    if(setting) {
                        setCurrentConnection({
                            ...currentConnection,
                            ['text']: setting.connection_name,
                            ['id']: setting.id,
                            ['protocol']: setting.transfer_protocol
                        })
                    } else {
                        setCurrentConnection({text: '', id: 0});
                    }
                }
            });
        };
    }
    useEffect(() => {
        async function fetchDetails() {
            try {
                activateView.fetchAccountPricing(id).then() //can run async
                getSelectedCreativeTagInfo(activatedCompany);
                getFTPConnectionList(activatedCompany);
                setCompaniesLoader(true)
                const values = await Promise.allSettled([activateView.fetchAccountPermissions()
                    , activateView.fetchAlreadyActivatedData(id)
                    , activateView.fetchSegment(id)])
                values.map(value=> {
                    if (value.status === "rejected") {
                        showErrorToastMessage("Your account did not have the permission to proceed, please contact support")
                        history.push("/segmentation-dashboard");
                    }

                })
                //operations with account permisssions data
                setHiddenOnBoardingCompanies(activateView.hiddenCompaniesArray)
                //operations with activated data
                activateView.markCompanyBlocks()
                try {
                    //operations with segment data
                    setTotalRecordsUploaded(activateView.segment?.total_records_uploaded)
                    setUploadedRecords(activateView.segmentationUploadedRecords(activateView.segment))
                    //start loader and get company form data
                    setCompanyFormLoader(true)
                    setSelectedCompany(null);
                    setTimeout(() => {
                        setSelectedCompany({...IndirectTiles,...AllInOneTiles,...MeasurementTiles,...SupplySideTiles,...SocialTiles,...DirectIntegrationsTiles, ...DirectMailTiles}[activatedCompany])
                        setCompanyFormLoader(false)
                        executeScroll()
                    }, 500)
                } catch (error) {
                    console.error("ActivateFormFetchDetailsError1=", error);
                    showErrorToastMessage(" You did not have the permission to proceed, please contact support", 15);
                    history.push('/segmentation-dashboard');
                }
                setCompaniesLoader(false)
            } catch (e) {
                console.error("ActivateFormFetchDetailsError2=", e);
                setCompaniesLoader(false);
                history.push('/segmentation-dashboard');
            } finally {
            }
        }

        fetchDetails().then(r => "").catch((err) => {
            if(err.status === 403 || err.status === 401 ){
                console.error("ActivateFormFetchDetailsError3=", err);
                history.push('/segmentation-dashboard');
            }
        });
    }, []);

    useEffect(() => {
    }, [activateView.companyTileColorTextData]);

    const uploadedRecordsList = uploadedRecords.length > 0
        && uploadedRecords.map((record, i) => {
            return (
                <>
                    {record?.size > 0 &&
                        <div className="list-group-item">
                            {record.text === 'CPM' ? (
                                <>
                                    <div className={"record-text"}>{record.text}</div>
                                    <span style={{minWidth:'10px'}}></span>
                                    <div className="record-number">{record.size.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 2,
                                    })}</div>
                                </>
                            ) : (
                                <>
                                    <div className={"record-text"}>{record.text}</div>
                                    <span style={{minWidth:'10px'}}></span>
                                    <div className="record-number">{record?.size?.toLocaleString()}</div>
                                </>
                            )}
                        </div>
                    }
                </>
            )
        }, this);


    return (

        <div className="activate-segment-content">
            <div className="container-fluid">
                <LoadOverlay active={companiesLoader}>
                    <div className="row nav-pills samcast-menu">
                        <div className="col-md-9 col-lg-9">
                            <div className="tab-content">
                                <div className="card p-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12"><h3
                                                className="w-100">{activateView?.segment?.segment_name}</h3>
                                            </div>
                                        </div>
                                        <div className="company-list">
                                            {/*{companyList}*/}
                                            {/*<legend className={'k-form-legend spacingLine'}>Curate Deals</legend>*/}
                                            {/*{curateCompanyList}*/}
                                            {/*<legend className={'k-form-legend spacingLine'}> Special DSP Connection*/}
                                            {/*</legend>*/}
                                            {/*{dspCompanyList}*/}
                                            {/*<legend className={'k-form-legend spacingLine'}>All In One</legend>*/}
                                            {/*{allInOneTileList}*/}
                                            {/*<div className={'license-holder-legend'} style={{color:'skyblue',width:"100%"}}>For License Holders:</div>*/}
                                            {/*<legend className={'k-form-legend spacingLine'}>Direct Integrations</legend>*/}
                                            {/*{directIntegrationTileList}*/}
                                            {/*<legend className={'k-form-legend spacingLine'}>Social</legend>*/}
                                            {/*{socialTileList}*/}
                                            {/*<legend className={'k-form-legend spacingLine'}>Supply Side - Deal ID</legend>*/}
                                            {/*{supplySideTileList}*/}
                                            {/*<legend className={'k-form-legend spacingLine'}>Measurement</legend>*/}
                                            {/*{measurementTileList}*/}
                                            {/*<legend className={'k-form-legend spacingLine'}>All</legend>*/}
                                            {/*{allCompanyList}*/}
                                        </div>
                                    </div>
                                </div>
                                <div ref={myRef}>
                                    <LoadOverlay active={companyFormLoader}>
                                        {selectedCompany?.company &&
                                            <ActivateSegmentForm
                                                broadcastFormCloseEvent={formCloseEvent}
                                                broadcastReloadEvent={reloadEventFromChild}
                                                data={{companyData: selectedCompany, creativeTags: selectedCreativeTags, activateView: activateView, ftpConnections: connections}}/>
                                        }
                                    </LoadOverlay>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card">
                                <div className="card-body" style={{display:'flex'}}>
                                    {/*<div className="row">*/}
                                        <div className="uploaded-records">
                                            <h4>UPLOADED RECORDS</h4>
                                            {totalRecordsUploaded && totalRecordsUploaded > 0 ?
                                                <h1>{totalRecordsUploaded.toLocaleString()}</h1> : null}
                                            <div className="basic-list-group">
                                                <div className="list-group">
                                                    {uploadedRecordsList}
                                                </div>
                                            </div>
                                        </div>
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadOverlay>
            </div>
        </div>
    );
}

export default ActivateForm;
