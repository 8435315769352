import React, {useEffect, useState} from "react";
import "./ContactUsPage.css";
import { Field, Form, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {Button} from "@progress/kendo-react-buttons";
import { Label, Error } from '@progress/kendo-react-labels';
import { Input, TextArea, MaskedTextBox } from '@progress/kendo-react-inputs';
import {
    emailValidator,
    phoneValidator,
    firstNameValidator,
    lastNameValidator,
    messageValidator
} from './validators';
import {Link} from "react-router-dom";

import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";
import {CONTACT_US_API} from "../../api/constants";

const ContactUsPage = () => {

    const user = JSON.parse(sessionStorage.getItem('user'));
    const [firstName, setFirstName] = useState(user.first_name !== null ? user.first_name:"");
    const [lastName, setLastName] = useState(user.last_name !== null ? user.last_name:"");
    const [phoneNumber, setPhoneNumber] = useState(user.phone !== null ?user.phone:"");
    const [email, setEmail] = useState(user.email !== null ?user.email:"");
    const [message, setMessage] = useState("");
    const formRef = React.useRef(null);
    // Need to verify how POST request is intended to be sent
    // Currently does not submit correctly

    const handleSubmit = (form,e) => {
        try {
            e.preventDefault();

            const raw = JSON.stringify({
                "first_name": firstName,
                "last_name": lastName,
                "phone": phoneNumber,
                "email": email,
                "message": message
            });

            const response = semcastAxios({
                method: "post",
                url: CONTACT_US_API,
                withCredentials:true,
                data: raw,
                headers: {"Content-Type": "application/json"},
            }).then(function (response) {
                    console.log(response);
                if (response.status === 200) {
                    showSuccessToastMessage('Thank you! A Semcasting representative will contact you shortly.', 60);
                } else {
                    showErrorToastMessage('Error occurred. Please try again or contact adssupport@semcasting.com.', 60);
                }
                }).catch(function (e) {
                showErrorToastMessage(e + ' Please try again or contact adssupport@semcasting.com.', 60);
                    console.log(e);
                });
        } catch (e) {
            showErrorToastMessage('Error occurred. Please try again or contact adssupport@semcasting.com.', 60);
            console.log(e)

        } finally {
        }
        formRef.current.resetForm();
    };

    useEffect(() => {
        document.body.style.zoom = "100%";
    },[]);

    return (
        <div className={"contact-us-container"}>
            {/* <div className={"splash-page-header"}> */}
                {/* <div className={"splash-page-logo-div"}> */}
                    {/* <NavLink className="splash-page-login-link splash-page-link" to="/"> */}
                        {/* <img src={adsLogo} className={"splash-page-logo"}/> */}
                    {/* </NavLink> */}
                    {/* <a href="https://semcasting.com/" target="_blank" className={"splash-page-semcasting-link"}>Powered by Semcasting</a> */}
                {/* </div> */}
                {/*<div className={"link-to-semcasting"}>*/}
                {/*    <a href="https://semcasting.com/" target="_blank" className={"splash-page-semcasting-link"}>Powered by Semcasting</a>*/}
                {/*</div>*/}




                {/* <div className={"splash-page-links-div"}> */}
                    {/*<a href="/contact" className={"splash-page-link"}>*/}
                    {/*    <Button className={"splash-page-link-button"}>*/}
                    {/*        Contact Us*/}
                    {/*    </Button>*/}
                    {/*</a>*/}
                    {/*<a href="/login" className={"splash-page-link"}>*/}
                    {/*    <Button className={"splash-page-link-button"}>*/}
                    {/*        Login*/}
                    {/*    </Button>*/}
                    {/*</a>*/}


                    {/*<NavLink className="splash-page-contact-link splash-page-link" to="/contact">*/}
                    {/*    <Button className={"splash-page-link-button"}>*/}
                    {/*        Contact Us*/}
                    {/*    </Button>*/}
                    {/*</NavLink>*/}
                    {/* <NavLink className="splash-page-login-link splash-page-link" to="/">
                        <Button className={"splash-page-link-button"}>
                            Sign In
                        </Button>
                    </NavLink> */}
                {/* </div> */}

            {/* </div> */}



            <div className="container contactUsContainer contactUsOuterContainer">
                <div className="contact-us-column-1 box section-card contact-us-page-card box-shadow-standard section-card-outer-margins">
                    {/* <div className={"contactUsTitle fontContainer"}>
                        <h1 className={"contactUsTitle contactUsText"}>Contact Us</h1>
                    </div> */}

                    <Form ref={formRef} onSubmit={handleSubmit} initialValues={{firstName: firstName, lastName: lastName, phoneNumber: phoneNumber, email:email}} render={formRenderProps =>
                        <FormElement style={{width: "100%"}}>
                        <fieldset className={'k-form-fieldset contactUsFormFields signInDialogButtonDiv signInDialogFormFieldsDiv'}>
                            {/* <legend className={'k-form-legend spacingLine'}></legend> */}

                            <div className={"nameFieldsDiv"}>
                                <Field id={'firstName'} className={'contactUsField text-field'} name={'firstName'} label={'First Name'} component={Input}
                                       validator={firstNameValidator} onChange={(e) => setFirstName(e.target.value)}/>
                                <br/>
                                <Field id={'lastName'} className={'contactUsField text-field'} name={'lastName'} label={'Last Name'} component={Input}
                                       validator={lastNameValidator} onChange={(e) => setLastName(e.target.value)}/>
                            </div>
                            <br/>
                            <Field id={'phoneNumber'} className={'contactUsField text-field'} name={'phoneNumber'} label={'Phone Number'} mask={'9990000000'} component={MaskedTextBox}
                                   validator={phoneValidator} onChange={(e) => setPhoneNumber(e.target.value)}/>
                            <br/>
                            <Field id={'email'} className={'contactUsField text-field'} name={'email'} label={'Email'} type={'email'} component={Input}
                                   validator={emailValidator} onChange={(e) => setEmail(e.target.value)}/>
                            <br/>
                            {/*<Field id={'company'} className={'contactUsField text-field'} name={'company'} label={'Company'} component={FormInput}*/}
                            {/*       validator={companyValidator} onChange={(e) => setCompany(e.target.value)}/>*/}
                            {/*<br/>*/}
                            {/*<Field id={'jobTitle'} className={'contactUsField text-field'} name={'jobTitle'} label={'Job Title'} component={FormInput}*/}
                            {/*       validator={jobTitleValidator} onChange={(e) => setJobTitle(e.target.value)}/>*/}
                            {/*<br/>*/}
                            <FieldWrapper>
                                <Label editorId={"comments"} className={"messageFieldLabel"}>Message</Label>
                                <Field id={'comments'} className={'messageField'} rows={5} name={'message'} label={'Message'} autoSize={false} optional={true} placeholder="Type message here..." component={TextArea}
                                       validator={messageValidator} onChange={(e) => setMessage(e.target.value)}/>
                                {formRenderProps.visited && <Error>{formRenderProps.errors.message}</Error>}
                            </FieldWrapper>

                            <br/>
                            <div className="k-form-buttons k-buttons-end">
                                <Button className={"contactUsSubmitButton button-standard button-submit"} type={'submit'}
                                        disabled={!formRenderProps.allowSubmit || !firstName.length || !lastName.length || !phoneNumber.length ||
                                        !email.length || emailValidator(email) || phoneValidator(phoneNumber) || !message.length}>
                                    Send
                                </Button>
                            </div>
                        </fieldset>
                    </FormElement>} />
                </div>
                <div className={'infoBoxesDiv'}>
                    <div className="contact-us-column-2 box fontContainer section-card contact-us-page-card box-shadow-standard section-card-outer-margins">
                        <h4 className={"contactUsText"}>Corporate HQ</h4>
                        <legend className={'k-form-legend spacingLine'}></legend>
                        <div>43 High St Suite 310</div>
                        <div>North Andover, MA 01845</div>
                        <br />
                        <div>
                            <span>
                                <span title={"Phone"} className="fa fa-phone fa-lg"></span>
                                <span>&nbsp;&nbsp;978 684-7580</span>
                            </span>
                        </div>
                        <div>
                            <span>
                                <span title={'Fax'} className="k-icon k-i-print k-i-printer"></span>
                                <span>&nbsp;978 684-7585</span>
                            </span>
                        </div>
                        <div>
                            <span>
                                <span title={"Email"} className="k-icon k-i-email k-i-envelop k-i-letter"></span>
                                <span>&nbsp;<a href="mailto:adssupport@semcasting.com">adssupport@semcasting.com</a></span>
                            </span>
                        </div>
                    </div>
                    <div className="column-2 box fontContainer section-card contact-us-page-card box-shadow-standard section-card-outer-margins">
                        <h4 className={"contactUsText"}>Privacy</h4>
                        <legend className={'k-form-legend spacingLine'}></legend>
                        <div className={'sideText'}>
                            We will use your information to communicate with you about
                            this general contact form and other solutions, events and
                            related resources that may be of interest to you. You may
                            opt-out at any time using the unsubscribe option in messages you
                            receive from us. For more information, please see our&nbsp;
                            <Link to="privacy-policy">Privacy Policy.</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/*<LandingPageFooter></LandingPageFooter>*/}
        </div>
    );
};

export default ContactUsPage;