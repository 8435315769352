import React, {useEffect, useState} from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import {socialMatrixConfig} from "../../../config/socialMatrixConfig";
import {filterBy} from "@progress/kendo-data-query";
import "./SocialMatrix.css";

const SocialMatrix = (props) => {
    const [socialMatrixOptions, setSocialMatrixOptions] = useState([]);
    const [filteredSocialMatrixOptions, setFilteredSocialMatrixOptions] = useState([]);
    const [value, setValue] = useState(props.controlsState ?
        props.controlsState.get("social_matrix") : []);

    useEffect(() => {
        let arr = [];
        socialMatrixConfig.forEach(option => {
            arr.push({text: option.segment_name+" - "+option.description, value:option.demographics_column});
        })
        setSocialMatrixOptions(arr);
    }, []);

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.controlsState) return;
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.social_matrix) {
                props.segmentEditInfo.data.segment_design.primaryData.social_matrix.forEach(optionValue => {
                    socialMatrixOptions.find(option => {
                        if (option.value === optionValue) {
                            arr.push(option);
                        }
                        ;
                    });
                });
                setValue(arr);
            }
        }
    }, [props.segmentEditInfo]);

    const onSocialMatrixFilterChange = (e) => {
        const filter = e.filter;
        const allData = socialMatrixOptions.slice();
        const newData = filter.value.length >= 1 ? filterBy(allData, filter) : allData;
        if (newData.length > 0) {
            setFilteredSocialMatrixOptions(newData);
        }
    };

    const onSocialMatrixChange = (e) => {
        let valArray = e.target.value.map((e) => {
            return {text: e.text.split('-')[0], value: e.value};
        });

        setValue(valArray);
        let values = [];
        e.value.forEach((val) => {
            values.push(val.value);
        });
        props.formik.values.segment_design.primaryData.social_matrix = values;
        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("social_matrix", valArray)));
        }
    };

    return (
        <div>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={filteredSocialMatrixOptions.length > 0 ? filteredSocialMatrixOptions : socialMatrixOptions}
                    autoClose={false}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    filterable={true}
                    value={value}
                    onFilterChange={onSocialMatrixFilterChange}
                    onChange={onSocialMatrixChange}
                    size="large"
                    // style={{width:"32rem"}}
                />
            </div>
        </div>
    );
}
export default SocialMatrix;
