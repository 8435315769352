import { getter } from '@progress/kendo-react-common';
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
export const termsValidator = value => value ? "" : "It's required to agree with Terms and Conditions.";
// Contact-Us Page validators
export const emailValidator = value => !value ? "Email is required." : emailRegex.test(value) ? "" : "Email is not in a valid format.";
export const nameValidator = value => !value ? "Full Name is required" : value.length < 7 ? "Full Name should be at least 7 characters long." : "";
export const firstNameValidator = value => !value ? "First Name is required" : value.length < 1 ? "First Name is required." : "";
export const lastNameValidator = value => !value ? "Last Name is required" : value.length < 1 ? "Last Name is required." : "";
export const companyValidator = value => !value ? "Company is required" : value.length < 1 ? "Company is required." : "";
export const jobTitleValidator = value => !value ? "Job Title is required" : value.length < 1 ? "Job Title is required." : "";
export const messageValidator = value => !value ? "Please briefly describe the situation" : value.length < 1 ? "Please briefly describe the situation." : "";

export const currentPasswordValidator = value => {
    return !value ? "Password is required" : value.length < 1 ? "Password is required." : "";
}

export const newPasswordValidator = value => {
    if(!/[^a-zA-Z\d\s:]/g.test(value) && !/[A-Z]/g.test(value) && !/[0-9]/g.test(value)) return "Must contain at least 10 characters, 1 uppercase letter, 1 number, and 1 special character";
    else if(value.length < 10) return "Must contain at least 10 characters";
    else if(!/[A-Z]/g.test(value)) return "Must contain at least 1 uppercase letter";
    else if(!/[0-9]/g.test(value)) return "Must contain at least 1 number";
    else if(!/[^a-zA-Z\d\s:]/g.test(value)) return "Must contain at least 1 special character listed:  ~ ` ! @ # $ % ^ & * + = - ; , / ( ) { } : < > ?";
    else return "";
}

export const segmentNameValidator = (value) => {
    if (value && value === "") return "A name is required.";
    const regex = RegExp(/^[a-zA-Z0-9_\- ]{3,64}$/);
    if (!regex.test(value)) return "Name must be 3-64 chars containing letters, numbers, hyphens, spaces and underscores.";
    return "";
}

export const userNameValidator = value => !value ? "User Name is required" : value.length < 5 ? "User name should be at least 5 characters long." : "";
export const phoneValidator = value => !value ? "Phone number is required." : phoneRegex.test(value) ? "" : "Not a valid phone number.";
export const cardValidator = value => !value ? "Credit card number is required. " : ccardRegex.test(value) ? "" : "Not a valid credit card number format.";
export const cvcValidator = value => !value ? "CVC code is required," : cvcRegex.test(value) || value.length !== 3 ? "" : "Not a valid CVC code format.";
export const guestsValidator = value => !value ? "Number of guests is required" : value < 5 ? "" : "Maximum 5 guests";
export const nightsValidator = value => value ? "" : "Number of Nights is required.";
export const arrivalDateValidator = value => value ? "" : "Arrival Date is required.";
export const colorValidator = value => value ? "" : "Color is required.";
export const requiredValidator = value => value ? "" : "Error: This field is required.";
export const passwordValidator = value => value && value.length > 8 ? '' : 'Password must be at least 8 symbols.';
export const addressValidator = value => value ? "" : "Address is required.";
const userNameGetter = getter('username');
const emailGetter = getter('email');
export const formValidator = values => {
    const userName = userNameGetter(values);
    const emailValue = emailGetter(values);

    if (userName && emailValue && emailRegex.test(emailValue)) {
        return {};
    }

    return {
        VALIDATION_SUMMARY: 'Please fill in the following fields.',
        ['username']: !userName ? 'User Name is required.' : '',
        ['email']: emailValue && emailRegex.test(emailValue) ? '' : 'Email is required and should be in a valid format.'
    };
};