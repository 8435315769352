import React, {useEffect, useState} from "react";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./HomeOwnershipButtonGroup.css";

const HomeOwnershipButtonGroup = (props) => {
    const determineActive = (flag) => {
        const arr = props.audienceState.homeowner_flg;
        if (arr) {
            if (flag === 'O' && arr.includes('Y')) return true;
            if (flag === 'R' && arr.includes('N')) return true;
        }
    };
    const [homeOwnerActive, setHomeOwnerActive] = useState(props.audienceState ?
        determineActive('O') : false);
    const [homeRenterActive, setHomeRenterActive] = useState(props.audienceState ?
        determineActive('R') :false);

    useEffect(() => {
        let arr = [];
        let values = [];
        if (homeOwnerActive && homeRenterActive) {
            arr = [];
            arr.push('Y');
            arr.push('N');
        } else if (homeRenterActive && !homeOwnerActive) {
            arr = [];
            arr.push('N');
        } else if (homeOwnerActive && !homeRenterActive) {
            arr = [];
            arr.push('Y');
        }
        // props.audienceState.homeowner_flg = arr;
        props.handleAudienceState(prevState => {
            return {...prevState, homeowner_flg: arr}}
        );
    }, [homeOwnerActive, homeRenterActive]);


    useEffect(() => {
        let arr = [];
        props.audienceState.homeowner_flg.forEach(optionValue => {
            if (optionValue === "Y") setHomeOwnerActive(true);
            if (optionValue === "N") setHomeRenterActive(true);
        })
    }, [props.audienceState]);

    const onHomeOwnerChange = (e) => {
        e.preventDefault();
        setHomeOwnerActive(!homeOwnerActive);
        // let arr = [];
        // if (homeOwnerActive) arr.push('Y');
        // if (homeRenterActive) arr.push('N');
        // // props.audienceState.homeowner_flg = arr;
        // props.handleAudienceState(prevState => {
        //     return {...prevState, homeowner_flg: arr}}
        // );
    };

    const onHomeRenterChange = (e) => {
        e.preventDefault();
        setHomeRenterActive(!homeRenterActive);
        // let arr = [];
        // if (homeRenterActive) arr.push('N');
        // if (homeOwnerActive) arr.push('Y');
        // // props.audienceState.homeowner_flg = arr;
        // props.handleAudienceState(prevState => {
        //     return {...prevState, homeowner_flg: arr}}
        // );
    };

    return (
        <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 mb-3">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <ButtonGroup className={"targeting-full-onboarding-button-group"}>
                    <Button
                        className={homeOwnerActive ? "selected-ownership" : "default-ownership"}
                        togglable={true}
                        onClick={(e) => onHomeOwnerChange(e)}
                        selected={homeOwnerActive}
                        iconClass="fas fa-home">
                        Owner
                    </Button>
                    <Button
                        className={homeRenterActive ? "selected-ownership" : "default-ownership"}
                        togglable={true}
                        onClick={(e) => onHomeRenterChange(e)}
                        selected={homeRenterActive}
                        iconClass="fas fa-building">
                        Renter
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default HomeOwnershipButtonGroup;
