import React, { useEffect, useState } from "react";
import { isUserLoggedIn } from "../../Utils/Common";
import {removeDataFromStorage} from "../../Utils/Common";
import { useIdleTimer } from 'react-idle-timer';
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";

import stopwatch from "./Segmentation/temporaryresources/images/stopwatch.png";
import "./ActivityChecker.css";

export const timeout = process.env.REACT_APP_SESSION_TIMEOUT;
// export const timeout = 60000;
const promptBeforeIdle = 30000;

const ActivityChecker = () => {

    const [remaining, setRemaining] = useState(0);
    const [visible, setVisible] = useState(false);
    const [intervalId, setIntervalId] = useState(0);
    const [shouldIntervalBeCancelled, setShouldIntervalBeCancelled] = useState(false);

    const onIdle = () => {
        if (isUserLoggedIn()) {
            removeDataFromStorage();
            window.location.href = "/?timeout=true";
            setShouldIntervalBeCancelled(true);
        }
    }

    const onActive = () => {
        if (isUserLoggedIn()) {
            setVisible(false);
        }
    }

    const onPrompt = () => {
        if (isUserLoggedIn()) {
            setVisible(true);
        }
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    });

    useEffect(() => {
        let interval = null;
        if (isUserLoggedIn()) {
            interval = setInterval(() => {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }, 500)
            setIntervalId(interval);

            return () => {
                clearInterval(interval);
            }
        } else {
            clearInterval(interval);
        }
    }, [getRemainingTime]);

    useEffect(() => {
        if (shouldIntervalBeCancelled) {
            clearInterval(intervalId);     // this being inside a useEffect makes sure that it gets the fresh value of state
        }
    }, [shouldIntervalBeCancelled, intervalId]);

    const handleStillHere = () => {
        activate()
    };

    const handleSignOut = () => {
        setVisible(false);
        if (isUserLoggedIn()) {
            removeDataFromStorage();
            window.location.href = "/";
            setShouldIntervalBeCancelled(true);
        }
    };

    return (
        <>
            {visible && (
                <Dialog
                    title={"Your session is about to expire!"}
                    width={500}
                    height={250}
                    closeIcon={false}
                    className={"dialog-standard"}
                >
                    <div className={"activity-tracker-msg-div"}>
                        <img className={"activity-tracker-stopwatch"} src={stopwatch} alt=""/>
                        <div className={"field-label"}>
                            You will be signed out in {remaining} seconds.
                        </div>
                    </div>

                    <DialogActionsBar layout={"end"}>
                        <Button
                            className={"button-standard button-submit"}
                            onClick={() => {
                                handleSignOut();
                            }}
                        >
                            Sign Out
                        </Button>

                        <Button
                            className={"button-standard button-submit"}
                            onClick={() => {
                                handleStillHere();
                            }}
                        >
                            Continue
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )};
        </>
    );

}

export default ActivityChecker;