import "./PrivacyPolicy";
import "./PrivacyPolicy.css"
import LandingPageFooter from "./LandingPageFooter";
import React, {useEffect} from "react";

function PrivacyPolicy() {

  useEffect(() => {
    document.body.style.zoom = "100%";
  })

  return (
    <>
      <main id="main" >
        <div className={"privacy-policy-container"} >
          <div className="container section-card privacy-policy-card box-shadow-standard section-card-outer-margins">
            <div className="row">
              <div className="col-md-12">
                <div className="c24">
                  <h1 className="header-primary privacy-policy-title" id="h.3wzoapzb3izn">
                    Privacy Policy
                  </h1>
                  <p className="c5">
                    <span className="c11 c23">
                      Last Updated and Effective On: December 3, 2020
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.7iwhjc9eujik">
                    <span className="c6">Summary</span>
                  </h2>
                  <p className="c5">
                    <span>Semcasting, Inc. (“</span>
                    <span className="c9">Semcasting</span>
                    <span>”) has created this privacy statement (the “</span>
                    <span className="c9">Privacy Policy</span>
                    <span className="c2">
                      ”) for the following Semcasting websites to communicate
                      our information gathering and distribution practices, as
                      well as to demonstrate our commitment to privacy. They may
                      be referred to as Sites or Services throughout this
                      Policy.
                    </span>
                  </p>
                  <ul className="c18 lst-kix_mv0srcf2jbcf-0 start">
                    <li className="c1 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://www.semcasting.com/&sa=D&source=editors&ust=1636632042019000&usg=AOvVaw1KH0Zu7OvOsCCjqmgIlvaO"
                        >
                          https://www.semcasting.com
                        </a>
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://www.udxleads.com/&sa=D&source=editors&ust=1636632042019000&usg=AOvVaw0i0n3ezFImzLRlEUxStcrx"
                        >
                          https://www.udxleads.com
                        </a>
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://audiencedesigner.com&sa=D&source=editors&ust=1636632042020000&usg=AOvVaw0sf9azp4DcE_8CxXP905wM"
                        >
                          https://audiencedesigner.com
                        </a>
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://privacychoice.com/&sa=D&source=editors&ust=1636632042020000&usg=AOvVaw0z4k9wclYJWLedbMGxth9W"
                        >
                          https://privacychoice.com
                        </a>
                      </span>
                    </li>
                  </ul>
                  <p className="c5">
                    <span className="c2">
                      By using the Site and the Services, you are agreeing to
                      the terms of our Privacy Policy. Although this Site
                      contains links to other websites, please be aware that we
                      are not responsible for the privacy practices of those or
                      any other websites. If you follow a link to another
                      website, you should carefully read their privacy policy.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      This Privacy Policy covers the following topics:
                    </span>
                  </p>
                  <ul className="c18 lst-kix_hohv66mzzyfv-0 start">
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Personal information and our business model
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        The personal information we collect and how we collect
                        it
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Your consent to our collection of personal information
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        How we use your personal information
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        How we disclose personal information
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        How we secure your personal information
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Retention of personal information
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        How we may update our privacy policy
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">Do not track</span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">Child privacy</span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Your rights to your personal information
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Do Not Sell My Personal Information and Opt-Out Options
                      </span>
                    </li>
                  </ul>
                  <br/>
                  <h2 className="header-primary" id="h.wla70oeipe52">
                    <span className="c6">
                      Personal Information and Our Business Model
                    </span>
                  </h2>
                  <p className="c5">
                    <span>
                      Semcasting provides audience targeting and attribution
                      technology solutions that support our clients (our “
                    </span>
                    <span className="c9">Clients</span>
                    <span className="c2">
                      ”) and their agency of record. We facilitate advertising
                      services using Demand Side Platforms and the execution of
                      attribution studies on our Clients’ behalf. Our role is to
                      provide the matching, onboarding, optimization and proof
                      of performance for those audiences identified for our
                      Clients.
                    </span>
                  </p>
                  <p className="c5">
                    <span>
                      Our Services use a Client’s first-party CRM customer or
                      prospect data (“
                    </span>
                    <span className="c9">Client</span>
                    <span>&nbsp;</span>
                    <span className="c9">Data</span>
                    <span>
                      ”) to support advertising on desktops, mobile devices,
                      connected TVs, video, or other forms of information
                      delivery over the Internet. We receive Client Data using
                      secured, encrypted methods in two ways: (i) our Clients
                      supply us with Client Data directly, and (ii) we collect
                      information on visitors to our Client’s websites. Client
                      Data includes names, addresses, emails, phone numbers,
                      locations, Internet Protocol addresses, and website
                      traffic logs, and in some instances may include medical
                      information, some of which may be covered under the Health
                      Insurance Portability and Accountability Act of 1996
                      (“HIPAA”), such as name, physical address, email address,
                      medical coverage, prescription drugs, physician and
                      medical provider. We control a Client’s Client Data only
                      during and for the performance of our Services for such
                      Client.{" "}
                    </span>
                    <span className="c9 c4 c20">
                      We are only able to remove or revise your information
                      located in Client Data that we control and that has been
                      provided to us by our Clients. We have no control over
                      Client Data that has not been provided to us and remains
                      in the control of our Clients. Please contact our Clients
                      directly to request the removal or revision of your
                      information stored in their databases.
                    </span>
                  </p>
                  <p className="c5">
                    <span>
                      Semcasting also gathers data independently. Semcasting (i)
                      compiles publicly available information on households and
                      businesses to support our first-party matching process,
                      and (ii) purchases data from third party distributors
                      (collectively, “
                    </span>
                    <span className="c9">Semcasting</span>
                    <span>&nbsp;</span>
                    <span className="c9">Data</span>
                    <span>
                      ”). Semcasting Data adheres to online and offline privacy
                      recommendations, code-of-conduct and guidelines of the
                      Direct Marketing Association, which has now combined with
                      the Association of National Adverisers (
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=https://thedma.org/&sa=D&source=editors&ust=1636632042023000&usg=AOvVaw3lc8srxF8CRT1dNrrtPQJp"
                      >
                        https://thedma.org
                      </a>
                    </span>
                    <span>) (the “DMA”), Interactive Advertising Bureau (</span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=https://www.iab.com/&sa=D&source=editors&ust=1636632042023000&usg=AOvVaw2c9ydsMGsDm98WSbLci9ib"
                      >
                        https://www.iab.com
                      </a>
                    </span>
                    <span>
                      ) (the “IAB”), and the Association of National Advertisers
                      (
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=https://www.ana.net/&sa=D&source=editors&ust=1636632042023000&usg=AOvVaw2I1q6L6h5TUeb_x-REWTps"
                      >
                        https://www.ana.net
                      </a>
                    </span>
                    <span className="c2">
                      ) (“ANA”). We follow The HITRUST CSF in our management of
                      sensitive data and physical security as well as Trustwave
                      for online data management.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      Semcasting uses Client Data and Semcasting Data only for
                      providing the Services.
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.s55nfj64k49i">
                    <span className="c6">
                      The Personal Information We Collect and How We Collect It
                    </span>
                  </h2>
                  <p className="c5">
                    <span className="c2">
                      We and our service providers and marketing partners
                      collect the following personal information about you:
                    </span>
                  </p>
                  <ol className="c18 lst-kix_gg78r5t7ha97-0 start" start={1}>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Semcasting collects Client Data from our Clients as
                        described above to perform Services for our Clients.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Semcasting collects publicly-available bulk data from
                        government agency filings, such as postal addresses,
                        housing transactions, voter registration information and
                        voter preferences. Although not personal information,
                        Semcasting also collects publicly-available aggregated
                        data, such as local and regional economic performance
                        indicators and summarized healthcare information.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Semcasting purchases data from third party distributors
                        to provide the Services. This data includes transaction
                        data, mapping data, and mobile device data from the
                        following trusted partners:
                      </span>
                    </li>
                  </ol>
                  <ul className="c18 lst-kix_gg78r5t7ha97-1 start" >
                    <li className="c0 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://www.complementics.com/terms-of-use/&sa=D&source=editors&ust=1636632042025000&usg=AOvVaw3dWtTRD69NWoHYvqdYIOdi"
                        >
                          Complementics
                        </a>
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://www.cuebiq.com/privacypolicy/&sa=D&source=editors&ust=1636632042025000&usg=AOvVaw2MWwTML7nXDl8nVIP_NcB6"
                        >
                          Cuebiq
                        </a>
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://www.digitalenvoy.com/privacy-policy/&sa=D&source=editors&ust=1636632042025000&usg=AOvVaw2YhO34hHei8vlYjhKTCQ6y"
                        >
                          Digital Envoy
                        </a>
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://www.digmap.com/privacy-policy/&sa=D&source=editors&ust=1636632042026000&usg=AOvVaw3Mu2xV0nFJSACWS5eNYpzp"
                        >
                          Digital Map Products
                        </a>
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://www.dmtispatial.com/privacy-policy/&sa=D&source=editors&ust=1636632042026000&usg=AOvVaw3Lasc3QNll7MDI229gMlK8"
                        >
                          DMTI Spatial
                        </a>
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://www.kochava.com/support-privacy/&sa=D&source=editors&ust=1636632042026000&usg=AOvVaw1hndQ5u-v8mGLcpDPNPQhr"
                        >
                          Kochava
                        </a>
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://www.peopledatalabs.com/privacy-policy&sa=D&source=editors&ust=1636632042027000&usg=AOvVaw12sRQ0ui8r3m5rcWcRsovU"
                        >
                          People Data Labs
                        </a>
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://www.safegraph.com/privacy-policy&sa=D&source=editors&ust=1636632042027000&usg=AOvVaw1wA3ZT4jmbCsm8MyWajx6w"
                        >
                          Safegraph
                        </a>
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c7">
                        <a
                          className="c13"
                          href="https://www.google.com/url?q=https://privacy.targetsmart.com/&sa=D&source=editors&ust=1636632042028000&usg=AOvVaw3xz9qc_EpXfkJSYVmGKGnY"
                        >
                          TargetSmart Communications
                        </a>
                      </span>
                      <span className="c2">
                        <br />
                      </span>
                    </li>
                  </ul>
                  <ol className="c18 lst-kix_gg78r5t7ha97-0" start={4}>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Visitors to the Site may wish to subscribe to the
                        Semcasting newsletter or blog or may request further
                        information about our marketing solutions and use of
                        data via our contact form available at our Site.
                        Individuals requesting more information will be asked to
                        provide their first and last name, email address, job
                        title, phone number and company name. We do not merge
                        any of the personal information collected via our
                        contact form with other information collected
                        automatically on our Site.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Our Site offers publicly accessible blogs that allow
                        user comment. You should be aware that any comments you
                        make might be read, collected, and used by others who
                        access the blogs.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Semcasting records from all visitors to our Sites, such
                        as website traffic logs, number of visits or clicks on a
                        given page, pages viewed or links to our Site, and
                        internet protocol (“IP”) addresses. An IP address is the
                        unique number assigned to your server by the Internet
                        Service Provider.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Semcasting may collect personal information about you
                        provided to us by third party services, including:
                      </span>
                    </li>
                  </ol>
                  <ul className="c18 lst-kix_gg78r5t7ha97-1 start" start={1}>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Identifiers, such as a real name, alias, postal address,
                        unique personal identifier, online identifier, Internet
                        Protocol address, email address, or other similar
                        identifiers;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Name, address, education, medical information, health
                        insurance information, and age;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Characteristics of protected classifications under
                        California or federal law, such as race, religion, age
                        or gender;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">Geolocation data;</span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Device-related characteristics, such as operating
                        system, device ID, Internet Protocol timestamp, internet
                        service provider, latitude/longitude;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Voter-related characteristics, such as voting history,
                        registered address, political affiliation and voter
                        interest; and
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Business-related characteristics, such as business name,
                        address, and NAICS code.
                      </span>
                    </li>
                  </ul>
                  <ol className="c18 lst-kix_gg78r5t7ha97-0" start={8}>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Semcasting uses cookies and web beacons to collect
                        information about visitors to our Site and our Clients’
                        websites. Cookies are small text files that we place in
                        visitors’ computer browsers to store their preferences.
                        Cookies themselves do not contain any personally
                        identifiable information but enable us to remember you
                        each time you return to visit our Site or use our
                        Services. Users can control the use of cookies at the
                        individual browser level. If you reject cookies, you may
                        still use our Site, but your ability to use some
                        features or areas of our Site may be limited. “Web
                        beacons” are small pieces of code placed on a web page
                        to monitor the behavior and collect data about the
                        visitors viewing a web page. For example, web beacons
                        can be used to count the users who visit a web page or
                        to deliver a cookie to the browser of a visitor viewing
                        that page. We may use web beacons on our Site from time
                        to time for this purpose.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Our Site includes Social Media Features, such as the
                        Facebook Like button, and Widgets, such as the Share
                        This button or interactive mini-programs that run on our
                        Site. These Features may collect your IP address, which
                        page you are visiting on our Site, and may set a cookie
                        to enable the feature to function properly. Social Media
                        Features and Widgets are either hosted by a third party
                        or hosted directly on our Site. Your interactions with
                        these features are governed by the privacy statement of
                        the company providing it.
                      </span>
                    </li>
                  </ol>
                  <p className="c5">
                    <span className="c2">
                      Semcasting provides Services to European customers based
                      on completely de-identified data that is aggregated at a
                      postal code level with no ability to re-identify a person,
                      device or household. Semcasting has no access to European
                      consumer information other than government-sanctioned
                      census level information which is aggregated at a postal
                      code level.
                    </span>
                  </p>
                  <p className="c21">
                    <span className="c10 c12 c4">
                      In the preceding 12 months Semcasting has collected, the
                      following categories of personal information from its
                      consumers:
                    </span>
                  </p>
                  <ol className="c18 lst-kix_oa00sewj0cw0-0 start" start={1}>
                    <li className="c16 li-bullet-0">
                      <span className="c10 c12 c4">
                        Identifiers, such as real name, alias, postal address,
                        unique personal identifier, Internet Protocol address,
                        email address, or other similar identifiers;
                      </span>
                    </li>
                    <li className="c16 li-bullet-0">
                      <span className="c10 c12 c4">
                        Telephone number and education;
                      </span>
                    </li>
                    <li className="c16 li-bullet-0">
                      <span className="c10 c12 c4">
                        Characteristics of protected classifications under
                        California or federal law, such as race, religion,
                        gender;
                      </span>
                    </li>
                    <li className="c16 li-bullet-0">
                      <span className="c10 c12 c4">
                        Commercial information, including records of real
                        property, products or services considered, or other
                        purchasing or consuming tendencies;
                      </span>
                    </li>
                    <li className="c16 li-bullet-0">
                      <span className="c10 c12 c4">
                        Internet or other electronic network suppliers,
                        including, but not limited to, browsing history, and
                        information regarding a consumer’s interaction with an
                        Internet Web site, or advertisement;
                      </span>
                    </li>
                    <li className="c16 li-bullet-0">
                      <span className="c10 c12 c4">Geolocation data;</span>
                    </li>
                    <li className="c16 li-bullet-0">
                      <span className="c10 c12 c4">
                        Audio, electronic, visual, or similar information;
                      </span>
                    </li>
                    <li className="c16 li-bullet-0">
                      <span className="c10 c4 c12">
                        Professional or industry associations and related
                        information; and
                      </span>
                    </li>
                    <li className="c16 li-bullet-0">
                      <span className="c10 c12 c4">
                        Inferences drawn from any of the other information
                        categories to create a profile about a consumer
                        reflecting the consumer’s preferences, characteristics,
                        including trends, predispositions, behavior, attitudes,
                        intelligence, abilities, and aptitudes.
                      </span>
                    </li>
                  </ol>
                  <br/>
                  <h2 className="header-primary" id="h.lvjpbg1msqzi">
                    <span className="c6">
                      Your Consent to Our Collection of Your Personal
                      Information
                    </span>
                  </h2>
                  <p className="c5">
                    <span>
                      By using our Services, providing us with your personal
                      information or accessing our Site, you agree to this
                      Privacy Policy and give your explicit, informed consent to
                      the collection, use and processing of your personal
                      information in accordance with this Privacy Policy.{" "}
                    </span>
                    <span className="c20 c9 c4">
                      If you do not agree to this Privacy Policy, please do not
                      visit, use, register to, or otherwise access our Site or
                      Services.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      You may withdraw your consent at any time, unless such
                      withdrawal would frustrate our ability to comply with a
                      legal obligation. Your withdrawal may restrict our ability
                      to provide our Services or access to our Site. You may
                      request that we delete any personal information we have
                      collected about you, though please note that we are not
                      required to delete personal information if it is necessary
                      for us, among other things, (i) to detect security
                      threats, (ii) to debug and correct errors that impair
                      existing intended functionality, or (iii) to use the
                      personal information solely for internal purposes that are
                      reasonably aligned with your expectations based on your
                      relationship with us.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      There are limited circumstances in which we may collect,
                      use or disclose personal information without your consent.
                      They are:
                    </span>
                  </p>
                  <ol className="c18 lst-kix_enxoh08667s1-0 start" start={1}>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        When the collection, use or disclosure of personal
                        information is permitted or required by law;
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        In an emergency that threatens an individual’s life,
                        health or personal security;
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        When the personal information is available from a public
                        source;
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        When we require legal advice from a lawyer;
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        For the purposes of collecting debt;
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To protect ourselves from fraud; or
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To investigate an anticipated breach of an agreement or
                        contravention of law.
                      </span>
                    </li>
                  </ol>
                  <br/>
                  <h2 className="header-primary" id="h.4q1a8z8vk5rk">
                    <span className="c6">
                      How We Use Your Personal Information
                    </span>
                  </h2>
                  <p className="c5">
                    <span className="c2">
                      We may use your personal information to fulfill the
                      purposes identified in this Privacy Policy, and for
                      purposes related thereto, and to provide the Services and
                      access to our Site, including as follows:
                    </span>
                  </p>
                  <ol className="c18 lst-kix_46yurvrrvgkk-0 start" start={1}>
                    <li className="c1 li-bullet-0">
                      <span className="c2">Within our organization.</span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        At your request or with your consent.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To provide the Services to our Clients. Even though
                        Semcasting Data is collected from publicly-available
                        sources, opt-in mobile devices and purchased data from
                        third-parties, Semcasting Data may include your personal
                        information. Semcasting is compensated by our Clients
                        for providing Clients with Semcasting Data, and this
                        service may be considered a sale of personal
                        information. &nbsp;In the last 12 months, Semcasting
                        Data provided to our Clients included the following
                        types of personal information:
                      </span>
                    </li>
                  </ol>
                  <ul className="c18 lst-kix_46yurvrrvgkk-1 start" start={1}>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Identifiers, such as real name, alias, postal address,
                        unique personal identifier, Internet Protocol address,
                        email address, or other similar identifiers;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">Telephone number, education;</span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Characteristics of protected classifications under
                        California or federal law, such as race, religion,
                        gender;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Commercial information, including records of real
                        property, products or services considered, or other
                        purchasing or consuming tendencies;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Internet or other electronic network suppliers,
                        including, but not limited to, browsing history, and
                        information regarding a consumer’s interaction with an
                        Internet Web site, or advertisement;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">Geolocation data;</span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Audio, electronic, visual, or similar information;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Professional or industry associations and related
                        information;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Medical information, such as name, physical address,
                        email address, medical coverage, prescription drugs,
                        physician and medical provider; and
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Inferences drawn from any of the other information
                        categories to create a profile about a consumer
                        reflecting the consumer’s preferences, characteristics,
                        including trends, predispositions, behavior, attitudes,
                        intelligence, abilities, and aptitudes.
                      </span>
                    </li>
                  </ul>
                  <ol className="c18 lst-kix_46yurvrrvgkk-0" start={4}>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        We may provide your personal information to companies
                        that provide services to help us support business
                        activities such as offering customer service or data
                        processing. These companies are authorized to use your
                        personal information only as necessary to provide these
                        services to us. In the last 12 months, Semcasting has
                        provided the following types of personal information to
                        our service providers:
                      </span>
                    </li>
                  </ol>
                  <ul className="c18 lst-kix_46yurvrrvgkk-1 start" start={1}>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Identifiers, such as real name, alias, postal address,
                        unique personal identifier, Internet Protocol address,
                        email address, or other similar identifiers;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">Telephone number, education;</span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Characteristics of protected classifications under
                        California or federal law, such as race, religion,
                        gender;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Commercial information, including records of real
                        property, products or services considered, or other
                        purchasing or consuming tendencies;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Internet or other electronic network suppliers,
                        including, but not limited to, browsing history, and
                        information regarding a consumer’s interaction with an
                        Internet Web site, or advertisement;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">geolocation data;</span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Audio, electronic, visual, or similar information;
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">Professional or industry associations and related
                        information; and
                      </span>
                    </li>
                    <li className="c0 li-bullet-0">
                      <span className="c2">
                        Inferences drawn from any of the other information
                        categories to create a profile about a consumer
                        reflecting the consumer’s preferences, characteristics,
                        including trends, predispositions, behavior, attitudes,
                        intelligence, abilities, and aptitudes.
                        <br />
                      </span>
                    </li>
                  </ul>
                  <ol className="c18 lst-kix_46yurvrrvgkk-0" start={5}>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Certain information may be shared with third parties or
                        published when you follow links to third party websites.
                        We do not own or control these third parties and when
                        you interact with them you may be providing information
                        to them directly. Such parties will have their own rules
                        and policies in respect of the collection, use and
                        disclosure of personal information and we encourage you
                        to review these rules and policies.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Public information, for example, information you choose
                        to share, such as testimonials or posts on our blog, may
                        be visible to the public and may be indexed through
                        third party search engines – we do not control the
                        practices of third-party search engines.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        In certain situations, Semcasting may be required to
                        disclose personal data in response to lawful requests by
                        public authorities, including to meet national security
                        or law enforcement requirements. We may also disclose
                        your personal information as required by law, such as to
                        comply with a subpoena, bankruptcy proceedings, or
                        similar legal process when we believe in good faith that
                        disclosure is necessary to protect our rights, protect
                        your safety or the safety of others, investigate fraud,
                        or respond to a government request.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        In connection with a merger, acquisition, or other
                        corporate transaction, including if one of our business
                        units are acquired by, sold to, or merged with another
                        company.
                      </span>
                    </li>
                  </ol>
                  <p className="c5">
                    <span className="c2">
                      We do not rent, sell or share any personal information
                      collected on the Site with third parties for direct
                      marketing purposes.
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.bpzzkuhiv6j">
                    <span className="c15 c9">Consumer Data</span>
                  </h2>
                  <p className="c5">
                    <span className="c2">
                      We use the personal information of consumers to provide
                      the Services, as described in Personal Information and Our
                      Business Model, above.
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.rlwumff1c2or">
                    <span className="c15 c9">Technical Site Data</span>
                  </h2>
                  <p className="c5">
                    <span className="c2">
                      As discussed above, our web servers may collect
                      information including the IP address of visitors to our
                      Sites, browser type and version, time zone setting,
                      browser plug-in types and versions, operating system and
                      platform. We use this information for the following
                      purposes:
                    </span>
                  </p>
                  <ol className="c18 lst-kix_yy1j5rpthp43-0 start" start={1}>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To measure the use of our Sites, including number of
                        visits, average time spent on the Sites, pages viewed,
                        page interaction information (such as scrolling, clicks,
                        and mouse-overs), and to improve the content we offer.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To administer the Sites and for internal operations,
                        including troubleshooting, data analysis, testing,
                        research, statistical and survey purposes.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        As part of our efforts to keep the Sites safe and
                        secure.
                      </span>
                    </li>
                  </ol>
                  <br/>
                  <h2 className="header-primary" id="h.smiz1uxdv71i">
                    <span className="c15 c9">
                      Contact Information and Communications
                    </span>
                  </h2>
                  <p className="c5">
                    <span className="c2">
                      As discussed above, we may collect your contact
                      information and your personal details in connection with,
                      among other things, your general questions, support
                      requests, and job applications. We may use this
                      information in the following ways:
                    </span>
                  </p>
                  <ol className="c18 lst-kix_1jml7htoa0l8-0 start" start={1}>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To respond to your questions and comments.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To deliver requested evaluation materials.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To coordinate webinars and events.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To evaluate candidates for potential employment
                        opportunities.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To send e-mail notifications and reminders related to
                        the Sites and Services.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To send you communications, including newsletters,
                        service announcements, administrative messages,
                        marketing messages or other marketing information.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To establish and maintain relationships with our
                        visitors.
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        To meet and comply with legal and regulatory
                        requirements.
                      </span>
                    </li>
                  </ol>
                  <br/>
                  <h2 className="header-primary" id="h.wqgtoidth2z2">
                    <span className="c15 c9">Medical Information</span>
                  </h2>
                  <p className="c5">
                    <span className="c2">
                      As mentioned above, Client Data we receive from certain of
                      our Clients may include medical information necessary for
                      us to perform our Services. &nbsp;We only use such medical
                      information to provide our Services specified by the
                      Client that provides such Client Data. &nbsp;Some of your
                      rights and our obligations under this Privacy Policy may
                      not apply to medical information that is covered under
                      HIPAA; if your medical information is protected under
                      HIPAA, your rights with respect to that data will be
                      governed by HIPAA.{" "}
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.t0tn7y9p30k">
                    <span className="c6">
                      How We Secure Your Personal Information
                    </span>
                  </h2>
                  <p className="c5">
                    <span>
                      The Site has industry standard security measures in place
                      to protect against the loss, misuse and alteration of the
                      information under our control. These protections include
                      compliance with IAB, the Network Advertising Initiative (
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=https://www.networkadvertising.org/&sa=D&source=editors&ust=1636632042042000&usg=AOvVaw0ybnr8hACy4aVzTjzVrYvf"
                      >
                        https://www.networkadvertising.org
                      </a>
                    </span>
                    <span className="c2">
                      ), the HITRUST CSF and Trustwave.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      In specific industries such as healthcare, finance, or
                      industries where any sensitive information is to be
                      associated with a digital ID, Semcasting requires that our
                      Clients utilize methodologies to assure that all
                      personally identifiable information is anonymized,
                      encrypted and protected as required by applicable law.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      Finally, we use a proprietary matching process to maintain
                      the anonymity of all users. We require each of our Clients
                      to state in their respective privacy policy that users’
                      digital information is being collected in compliance with
                      DMA/ANA and IAB guidelines, and for our Clients to allow
                      users to opt-out on their websites.
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.nzg1d79tj1eb">
                    <span className="c6">
                      Retention of Your Personal Information
                    </span>
                  </h2>
                  <p className="c5">
                    <span className="c2">
                      Semcasting retains personal data we process on behalf of
                      our Clients for as long as required to provide Services to
                      our Client. Semcasting will retain this personal
                      information as necessary to comply with our legal
                      obligations, resolve disputes, and enforce our agreements.
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.trnxrfo3khz9">
                    <span className="c6">
                      How We May Update Our Privacy Policy
                    </span>
                  </h2>
                  <p className="c5">
                    <span className="c2">
                      Semcasting reserves the right, at any time, and without
                      notice, to add to, change, modify, or update this Privacy
                      Policy by posting a revised policy on this Site. Any
                      revised Privacy Policy shall be effective immediately on
                      posting. We encourage you to periodically review this page
                      for the latest information on our privacy practices.
                      Whether or not our practices change, we will review this
                      Privacy Policy at least once every twelve months.
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.8j10tam0ee0">
                    <span className="c6">Do Not Track</span>
                  </h2>
                  <p className="c5">
                    <span className="c2">
                      We, Semcasting and our partners, affiliates, or service
                      providers, use technologies such as cookies, beacons,
                      tags, website traffic logs and scripts, to analyze trends,
                      administer the Site, track users’ movements around the
                      Site, and to gather demographic information about our user
                      base. We may receive or generate reports based on the use
                      of these technologies on an individual and aggregated
                      basis. &nbsp;Third parties [may not collect personal
                      information about your online activities over time and
                      across different websites when you access the Site.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      We use cookies to support online advertising retargeting.
                      Users can control the use of cookies at the individual
                      browser level. If you reject cookies, you may still use
                      our Site, but your ability to use some features or areas
                      of our Site may be limited.
                    </span>
                  </p>
                  <p className="c5">
                    <span>
                      We partner with service providers to either display
                      advertising on our Site or to manage advertising on other
                      sites. We may share information about your activities,
                      such as cookies, with our service providers to provide you
                      advertising based upon your browsing activities and
                      interests. In addition to rejecting all cookies, if you
                      wish to not have this information used for the purpose of
                      serving you interest-based ads, you may selectively
                      opt-out by clicking:
                    </span>
                    <span>
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=http://optout.aboutads.info/&sa=D&source=editors&ust=1636632042043000&usg=AOvVaw2ulv9D5b_PKObZikdhg3bk"
                      >
                        &nbsp;
                      </a>
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=http://optout.aboutads.info/&sa=D&source=editors&ust=1636632042044000&usg=AOvVaw2hhV7nzaXv393md9dXVv5G"
                      >
                        http://optout.aboutads.info/
                      </a>
                    </span>
                    <span>&nbsp;or</span>
                    <span>
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=http://optout.networkadvertising.org/&sa=D&source=editors&ust=1636632042044000&usg=AOvVaw0b1WEIKvSh7tCiOpsIg84m"
                      >
                        &nbsp;
                      </a>
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=http://optout.networkadvertising.org/&sa=D&source=editors&ust=1636632042044000&usg=AOvVaw0b1WEIKvSh7tCiOpsIg84m"
                      >
                        http://optout.networkadvertising.org/
                      </a>
                    </span>
                    <span>&nbsp;(or if located in the European Union:</span>
                    <span>
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=http://www.youronlinechoices.eu/&sa=D&source=editors&ust=1636632042044000&usg=AOvVaw3Pey3gBmuasM4E9bux7Z1H"
                      >
                        &nbsp;
                      </a>
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=http://www.youronlinechoices.eu/&sa=D&source=editors&ust=1636632042045000&usg=AOvVaw0STF7cy3RMJHtHE0BcU80K"
                      >
                        http://www.youronlinechoices.eu/
                      </a>
                    </span>
                    <span className="c2">
                      ). Please note this does not opt you out of being served
                      ads. You may continue to receive generic ads.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      We do not override any “do-not-track” signal from your web
                      browser and will not collect or store your movements on
                      our Sites if you use such a signal.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      Third parties with whom we partner to provide certain
                      features on our Site or to display advertising based upon
                      your web browsing activity use HTML5 to automatically
                      collect and store information. Various browsers may offer
                      their own management tools for removing HTML5.
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.g7yshaemwwod">
                    <span className="c6">Child Privacy</span>
                  </h2>
                  <p className="c5">
                    <span className="c2">
                      If you are under the age of sixteen (16), please do not
                      use or access the Site. It is not our intention to collect
                      or use personal information from anyone under the age of
                      sixteen, and we will not knowingly do so. If we are made
                      aware that we have collected any personal information from
                      minors and are asked to delete such information from our
                      databases, we will promptly do so. &nbsp;Semcasting does
                      not knowingly sell the personal information of minors
                      under the age of 16.{" "}
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.vmzxbi35ho0">
                    <span className="c6">
                      Your Rights to Your Personal Information
                    </span>
                  </h2>
                  <p className="c5">
                    <span className="c2">
                      Semcasting has no direct relationship with the individuals
                      whose personal data it processes on behalf of its Clients.
                      An individual who seeks access, or who seeks to correct,
                      amend, or delete inaccurate (or all their) data should
                      direct their query both to Semcasting’s Client (the data
                      controller) and Semcasting (the processor). As mentioned
                      above, we are only able to remove or revise your
                      information located in Client Data we control. We have no
                      control over Client Data that has not been provided to us
                      and remains in the control of our Clients. Please contact
                      our Clients directly to request the removal or revision of
                      your information stored in their databases.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      You have several rights associated with your personal
                      information. &nbsp;You may contact us directly to exercise
                      these rights and to request (i) access to your specific
                      personal information we have collected, (ii) to know the
                      categories of personal information we have collected about
                      you in the preceding 12 months, (iii) to know the
                      categories of sources from which your personal information
                      was collected in the preceding 12 months, (iv) to know the
                      business or commercial purposes for collecting or selling
                      your information, (v) to know how we use your personal
                      information, (vi) to know to whom it has been disclosed or
                      sold in the preceding 12 months, (vii) that we correct
                      your personal information and (viii) that we delete your
                      personal information, subject to limited exceptions under
                      applicable law. Requests should be made in writing, by
                      email, or by telephone. We must be able to verify your
                      identity before we are able to release your personal
                      information to you or to delete your personal information.
                      &nbsp;Your request will need to include your full legal
                      name and current residential address for these
                      verifications. &nbsp;We will confirm requests to know or
                      requests to delete, and provide information regarding
                      Semcasting’s verification process, within 10 days of
                      receiving your request. &nbsp;We will respond to requests
                      to know or requests to delete within 45 days or provide
                      notice if we require longer but will respond within 90
                      days of receiving the request. We will provide you with
                      your personal information in a portable, readily useable
                      format free of charge, but we may charge a reasonable fee
                      for excessive requests. Providing you with your personal
                      information more than twice in a 12-month period is at our
                      discretion. While we will make every effort to comply with
                      your requests, we may not be able to release, revise or
                      remove your personal information to the extent we are
                      unable to find it in our records. In such an event, we
                      will let you know following the timetable detailed above.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      We may not discriminate against you for exercising any of
                      your rights permitted by law, including your right to
                      request your personal information as described above.
                    </span>
                  </p>
                  <p className="c5">
                    <span className="c2">
                      You may have an authorized agent exercise your rights,
                      outlined above, and submit a request to access, a request
                      to delete, or a request to opt-out so long as you provide
                      the authorized agent with written permission to submit the
                      relevant request and you verify your own identity directly
                      with Semcasting. &nbsp;Such restrictions do not apply
                      where you provide your authorized agent with power of
                      attorney pursuant to California Probate Code sections 4121
                      to 4130.
                    </span>
                  </p>
                  {/*<p className="c5">*/}
                  {/*  <span className="c2">&nbsp; </span>*/}
                  {/*</p>*/}
                  <p className="c5">
                    <span className="c2">
                      In the previous calendar year, Semcasting:
                    </span>
                  </p>
                  <ol className="c18 lst-kix_ne27tbp4r19a-0 start" start={1}>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Received 0 requests to know, complied with 0 requests to
                        know, in whole or in part, and denied 0 requests to know
                        in the aggregate;
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Received 0 requests to delete, complied with 0 requests
                        to delete, in whole or in part, and denied 0 requests to
                        delete in the aggregate; and
                      </span>
                    </li>
                    <li className="c1 li-bullet-0">
                      <span className="c2">
                        Received 0 requests to opt-out, complied with 0 requests
                        to opt-out, in whole or in part, and denied 0 requests
                        to opt-out in the aggregate.
                      </span>
                    </li>
                  </ol>
                  <p className="c5">
                    <span className="c2">
                      Semcasting respects your control over your information
                      and, upon request, we will confirm whether we hold or are
                      processing information that we have collected from you.
                      You also have the right to amend or update inaccurate or
                      incomplete personal information, request deletion of your
                      personal information, or request that we no longer use it.
                      Under certain circumstances we will not be able to fulfill
                      your request, such as if it interferes with our regulatory
                      obligations, affects legal matters, we cannot verify your
                      identity, or it involves disproportionate cost or effort,
                      but in any event, we will respond to your request within a
                      reasonable timeframe and provide you an explanation. To
                      make such a request of us, please contact us at:
                    </span>
                  </p>
                  <p className="c5 c17">
                    <span className="c2" />
                  </p>
                  <p className="c25">
                    <span className="c9">
                      Semcasting, Inc.
                      <br />
                      41 High Street
                      <br />
                      North Andover, MA 01845
                      <br />
                    </span>
                    <span className="c7 c9">
                      <a
                        className="c13"
                        href="mailto:privacychoice@semcasting.com"
                      >
                        privacychoice@semcasting.com
                      </a>
                    </span>
                    <span className="c20 c9 c4">
                      <br />
                      Phone Number: 978-684-7580
                      <br />
                      Toll Free Phone Number (U.S. Only): 888-401-0387
                    </span>
                  </p>
                  <p className="c5">
                    <span>
                      If you have an unresolved privacy or data use concern that
                      we have not addressed satisfactorily, please also contact
                      our U.S.-based third-party dispute resolution provider
                      (free of charge) at
                    </span>
                    <span>
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=https://feedback-form.truste.com/watchdog/request&sa=D&source=editors&ust=1636632042048000&usg=AOvVaw0aGPOw6sRmo_Dwx0lCxSxe"
                      >
                        &nbsp;
                      </a>
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=https://feedback-form.truste.com/watchdog/request&sa=D&source=editors&ust=1636632042048000&usg=AOvVaw0aGPOw6sRmo_Dwx0lCxSxe"
                      >
                        https://feedback-form.truste.com/watchdog/request
                      </a>
                    </span>
                    <span className="c2">.</span>
                  </p>
                  {/*<h2 className="c14" id="h.wmbe6wem39hf">*/}
                  {/*  <span className="c9 c11">*/}
                  {/*    Do Not Sell My Personal Information and Opt-Out Options*/}
                  {/*  </span>*/}
                  {/*</h2>*/}
                  <br/>
                  <h2 className="header-primary" id="h.1pc9i1pyj8nz">
                    <span className="c15 c9">
                      Do Not Sell My Personal Information
                    </span>
                  </h2>
                  <p className="c5">
                    <span>
                      You may direct us at any time not to sell your personal
                      information or use it to perform our services. You may opt
                      out of the sale of information by using this
                    </span>
                    <span className="c7">
                      &nbsp;
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=https://privacychoice.com/&sa=D&source=editors&ust=1636632042050000&usg=AOvVaw2VdRwCd3maLKP_TQLAAs0y"
                      >
                        Form
                      </a>
                    </span>
                    <span>
                      &nbsp;to submit your request, by calling our toll free
                      number (U.S. only) 888-401-0387, or by clicking{" "}
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="mailto:privacychoice@semcasting.com"
                      >
                        here
                      </a>
                    </span>
                    <span className="c2">
                      . &nbsp;We will comply with any requests to opt-out of the
                      sale of personal information as soon as feasibly possible,
                      but in any event no later than 15 business days from the
                      date Semcasting receives your request. &nbsp;{" "}
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.twaq5zxhrylb">
                    <span className="c9 c15">
                      Communications Opt-Out Policies
                    </span>
                  </h2>
                  <p className="c5">
                    <span>
                      Our Sites provides users the opportunity to opt-out of
                      receiving communications from us at the point where we
                      request information about the visitor. Our email
                      communication materials also give users the option to
                      remove their information from our database to not receive
                      future communications by clicking the unsubscribe link.
                      You can also send an email to{" "}
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="mailto:unsubscribe@semcasting.com"
                      >
                        unsubscribe@semcasting.com
                      </a>
                    </span>
                    <span className="c2">.</span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.9bmkfmpuj2h6">
                    <span className="c15 c9">Newsletters and Blogs</span>
                  </h2>
                  <p className="c5">
                    <span>
                      If you wish to subscribe to our newsletter and/or our
                      blogs, we will use your email address to send the
                      newsletter to you. If you no longer wish to receive these
                      email communications, you can follow the unsubscribe
                      instructions contained in the emails you receive or by
                      contacting us at{" "}
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="mailto:unsubscribe@semcasting.com"
                      >
                        unsubscribe@semcasting.com
                      </a>
                    </span>
                    <span>
                      . To request removal of your personal information from our
                      blog, contact us at{" "}
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="mailto:unsubscribe@semcasting.com"
                      >
                        unsubscribe@semcasting.com
                      </a>
                    </span>
                    <span className="c2">.</span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.ws0p6qrwmqxp">
                    <span className="c15 c9">Direct Mail Solicitations</span>
                  </h2>
                  <p className="c5">
                    <span>
                      As mentioned above, Semcasting is a member of the Direct
                      Marketing Association/Association of National Advertisers.
                      All DMA/ANA members are required to use DMA/ANA’s Mail
                      Preference Service (MPS) to comply with consumer requests
                      not to receive direct mail advertising. By registering
                      your name on the DMA/ANA MPS file, you will be certain to
                      get your name suppressed from the largest number of
                      marketing files and not just Semcasting’s data products.
                      To do so, please contact the DMA/ANA’s Consumer Assistance
                      website page
                    </span>
                    <span>
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=https://thedma.org/accountability/dma-choice/&sa=D&source=editors&ust=1636632042052000&usg=AOvVaw1-c_gRP8Fa3ChtFr2611LL"
                      >
                        &nbsp;
                      </a>
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="https://www.google.com/url?q=https://thedma.org/accountability/dma-choice/&sa=D&source=editors&ust=1636632042052000&usg=AOvVaw1-c_gRP8Fa3ChtFr2611LL"
                      >
                        https://thedma.org/accountability/dma-choice/
                      </a>
                    </span>
                    <span className="c2">
                      &nbsp;or by calling the DMA/ANA at 212-768-7277.
                      Semcasting cannot honor requests for the removal of your
                      personal information from direct mail solicitations
                      outside of Semcasting’s control.
                    </span>
                  </p>
                  <br/>
                  <h2 className="header-primary" id="h.qu18qbhe7nsm">
                    <span className="c15 c9">Contact Information</span>
                  </h2>
                  <p className="c25">
                    <span>
                      Semcasting, Inc.
                      <br />
                      41 High Street
                      <br />
                      North Andover, MA 01845
                      <br />
                    </span>
                    <span className="c7">
                      <a
                        className="c13"
                        href="mailto:privacychoice@semcasting.com"
                      >
                        privacychoice@semcasting.com
                      </a>
                    </span>
                    <span>
                      <br />
                    </span>
                    <span className="c4">Phone: 978-684-7580</span>
                  </p>
                  <p className="c17 c22">
                    <span className="c2" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <LandingPageFooter></LandingPageFooter>

    </>
  );
}

export default PrivacyPolicy;
