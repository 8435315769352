import React, { useEffect, useState } from "react";
import {
  semcastAxios,
  signInAs,
  paginationOptions,
  noDataIndication, boostrap2TableSearchOptions,
} from "../../../Utils/Common";
import {
  ALL_ACCOUNT_DETAILS,
  SUCCESS_STATUS,
} from "../../../api/constants";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import Loader from "../../Components/Loader";
import {UserContext} from "../../../contexts/UserContext";

function UserSearch() {
  const { user, setUser } = React.useContext(UserContext);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const get = async () => {
    setLoading(true);
    const res = await semcastAxios.get(ALL_ACCOUNT_DETAILS, {
      withCredentials: true,
    });
    if (res.data.status === SUCCESS_STATUS) {
      setAccounts(res.data.data);
      setLoading(false);
    }
  };
  const signInFormatter = (data, _) => (
    <a
      href="#/"
      className="btn btn-primary shadow btn-xs sharp mr-1"
      onClick={() => signInAs(data)}
    >
      <i className="fa fa-sign-in" />
    </a>
  );
  const nameFormatter = (data, row) => data + " " + row.last_name;

  const dateFormatter = (data, _) =>
    data && moment(data).format("MM/DD/YY h:mm A");
  const { SearchBar } = Search;

  const defaultSorted = [
    {
      dataField: "email",
      order: "asc",
    },
  ];
  const columns = [
    {
      dataField: "id",
      text: "ID",
      formatter: signInFormatter,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "first_name",
      text: "Name",
      formatter: nameFormatter,
      sort: true,
    },
    {
      dataField: "company",
      text: "Company",

      sort: true,
    },

    {
      dataField: "last_login_at",
      text: "Last Login at",
      formatter: dateFormatter,

      sort: true,
    },
  ];
  useEffect(() => {
    get();
  }, []);
  return (
    <>
      { user?.isAdmin &&
       <div className="content-body">
        {/* row */}
        <div className="container-fluid">
          <div className="row ">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="tab-content mt-3">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">User Search</h4>
                  </div>
                  <div className="card-body">
                    {loading ? (
                        <Loader height={"100%"}/>
                    ) : (
                        <ToolkitProvider
                            keyField="__id"
                            bootstrap4={true}
                            data={accounts}
                            columns={columns}
                            search={boostrap2TableSearchOptions()}
                        >
                          {(props) => (
                              <div>
                                <SearchBar
                                    {...props.searchProps}
                                    className="form-control form-control-custom"
                                />
                                <BootstrapTable
                                    {...props.baseProps}
                                    pagination={paginationFactory(
                                        paginationOptions(accounts)
                                    )}
                                    filter={filterFactory()}
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    noDataIndication={noDataIndication}
                                />
                              </div>
                          )}
                        </ToolkitProvider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/***********************************
         Content body end
         ************************************/}
      </div>
     }
    </>
  );
}

export default UserSearch;
