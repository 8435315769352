export const socialMatrixConfig = [
  {
    "segment_id": "SM1",
    "demographics_column": "seg_timecards_ct",
    "segment_name": "Timecards and Happy Hour",
    "description": "Age 18-25, income < $49k",
  },
  {
    "segment_id": "SM10",
    "demographics_column": "seg_core_family_ct",
    "segment_name": "Core Families",
    "description": "Age 40-51, married, low ITA",
  },
  {
    "segment_id": "SM11",
    "demographics_column": "seg_fam_planner_ct",
    "segment_name": "Family Planners",
    "description": "Age 40-51, married, high ITA",
  },
  {
    "segment_id": "SM12",
    "demographics_column": "seg_day_challenges_ct",
    "segment_name": "Daily Challenges",
    "description": "Age 52-64, married, low income",
  },
  {
    "segment_id": "SM13",
    "demographics_column": "seg_lux_life_ct",
    "segment_name": "Luxury Lifestyles",
    "description": "Age 52-64, urban areas, professional",
  },
  {
    "segment_id": "SM14",
    "demographics_column": "seg_comf_indep_ct",
    "segment_name": "Comfortable Independents",
    "description": "Age 52-64, female head of HH/single adult",
  },
  {
    "segment_id": "SM15",
    "demographics_column": "seg_on_approach_ct",
    "segment_name": "On Approach",
    "description": "Age 52-64, married, low ITA",
  },
  {
    "segment_id": "SM16",
    "demographics_column": "seg_tax_free_save_ct",
    "segment_name": "Tax Free Savers",
    "description": "Age 52-64, married, high ITA",
  },
  {
    "segment_id": "SM17",
    "demographics_column": "seg_big_box_ct",
    "segment_name": "Big Box Greeters",
    "description": "Age 65-73, low income, low ITA",
  },
  {
    "segment_id": "SM18",
    "demographics_column": "seg_solid_slow_ct",
    "segment_name": "Solid and Slow",
    "description": "Age 65-73, low income, high ITA",
  },
  {
    "segment_id": "SM19",
    "demographics_column": "seg_public_links_ct",
    "segment_name": "Public Links",
    "description": "Age 65-73, retired singles, low ITA",
  },
  {
    "segment_id": "SM2",
    "demographics_column": "seg_learn_ropes_ct",
    "segment_name": "Learning the Ropes",
    "description": "Age 18-25, income $49k+",
  },
  {
    "segment_id": "SM20",
    "demographics_column": "seg_pace_protect_ct",
    "segment_name": "Pace and Protection",
    "description": "Age 65-73, retired singles, high ITA",
  },
  {
    "segment_id": "SM21",
    "demographics_column": "seg_snow_birds_ct",
    "segment_name": "Snow Birds",
    "description": "Age 65-73, married, affluent and active",
  },
  {
    "segment_id": "SM22",
    "demographics_column": "seg_early_bird_ct",
    "segment_name": "Early Bird Specials",
    "description": "Age 74+, low income couples",
  },
  {
    "segment_id": "SM23",
    "demographics_column": "seg_lunch_bunch_ct",
    "segment_name": "Lunch Bunch",
    "description": "Age 74+, single",
  },
  {
    "segment_id": "SM24",
    "demographics_column": "seg_gardening_ct",
    "segment_name": "Gardening",
    "description": "Age 74+, married, moderate income",
  },
  {
    "segment_id": "SM3",
    "demographics_column": "seg_urb_yuppie_ct",
    "segment_name": "Urban Yuppies",
    "description": "Age 26-39, urban areas",
  },
  {
    "segment_id": "SM4",
    "demographics_column": "seg_career_family_ct",
    "segment_name": "Career and Family Roots",
    "description": "Age 26-39, high income",
  },
  {
    "segment_id": "SM5",
    "demographics_column": "seg_paychk_planner_ct",
    "segment_name": "Paycheck Planners",
    "description": "Age 26-39, low ITA index",
  },
  {
    "segment_id": "SM6",
    "demographics_column": "seg_follow_orders_ct",
    "segment_name": "Following Orders",
    "description": "Age 40-51, non-savers",
  },
  {
    "segment_id": "SM7",
    "demographics_column": "seg_asset_builder_ct",
    "segment_name": "Asset Builders",
    "description": "Age 40-51, urban areas",
  },
  {
    "segment_id": "SM8",
    "demographics_column": "seg_ind_women_ct",
    "segment_name": "Independent Women",
    "description": "Age 40-51, female head of household",
  },
  {
    "segment_id": "SM9",
    "demographics_column": "seg_ind_single_ct",
    "segment_name": "Independent Singles",
    "description": "Age 40-51, single adult",
  },
];

