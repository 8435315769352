import { Bar } from "test-react-chartjs-2";
import { Chart as ChartJS, Legend } from "chart.js";
import "./Employees.css";
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Employees = ({ data, chartId, employeesRef, employeesHCOptions }) => {
  const employeeData = {
    labels: data.labels,
    datasets: [
      {
        label: "Employees",
        data: data.values,
        backgroundColor: data.backgroundColor,
      },
    ],
  };

  const pluginOptions = {
    legend: {
      display: false,
      labels: {
        font: {
          size: 18,
          weight: 500,
        },
      },
    },
  };


  ChartJS.defaults.font.size = 17;
  ChartJS.defaults.color = "#000000";

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 prevent-split" id={chartId}>
        <div className="card">
          <div className="text-center">
            <h4>Employees</h4>
          </div>
          <div className="row revenue-businessreport">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="report-card">
                <div className="card-body">
                  <div className="table-responsive business-employees">
                    <table className="table table-responsive-md table-striped">
                      <thead>
                        <tr>
                          <th className="col-xl-4 col-lg-4 col-md-4">
                            <strong>Value</strong>
                          </th>
                          <th className="right col-xl-2 col-lg-2 col-md-2">
                            <strong>Match %</strong>
                          </th>
                          <th className="col-xl-2 col-lg-2 col-md-2"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.rows.map((item, index) => {
                          return (
                            <tr>
                              <td>{item["Value"]}</td>
                              <td className="right">{item["Match %"]} %</td>
                              <td />
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="card">
                <div className="card-body-businessgraphs">
                  <Bar
                      ref={employeesRef}
                    data={employeeData}
                    // width={600}
                    height={500}
                    options={{
                      maintainAspectRatio: false,
                      plugins: pluginOptions,
                    }}
                  />
                  {/*<HighchartsReact*/}
                  {/*    constructorType={"chart"}*/}
                  {/*    options={employeesHCOptions}*/}
                  {/*    highcharts={Highcharts}*/}
                  {/*/>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Employees;
