import React from "react";
import FileUpload from "./FileUpload";

export default function MyComputer({ handleSubmit }) {
  return (
    <div className={"my-computer-outer-div"}>
      <FileUpload />
    </div>
  );
}
