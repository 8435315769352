import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const liverampBeeswaxConfig = {
  ...COMPANY_BASE_PROPERTIES,
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With Beeswax via LiveRamp",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "liverampbeeswax",

  /**
   * HTML that explains how to use the form
   */
  instructions:
    "Please provide the Beeswax Buzz Key and the Start/End date. Your Beeswax buzz key" +
    " should be all lowercase. Please contact your Beeswax account representative if" +
    " unsure of which value to use here. No whitespace or other special characters, please." +
    " All fields must be completed to push an audience to your seat.  Please note that the" +
    " audience may take up to 3 to 5 days for the audience to show up in your platform.",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "liverampbeeswax",
    text: "Beeswax via LiveRamp",
    img: "beeswax-logo.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink: "",

  layout: [
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelect",
          fieldName: "advertiserId",
          labelText: "Beeswax Buzz Key",
          url: "/v2/secure/attributor/activate-credentials/liverampbeeswax/advertiserId",
          placeholder: "Select an Beeswax Buzz Key or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType: 'checkbox',
          fieldName: "save_credentials",
          labelText: 'Save Beeswax Buzz Key for future use',
          checked: false,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },{
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
    {
      fieldType: "pricing",
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["advertiserId"],
  formik: {
    initialValues: {
      device_type: ["ios_android"],
      campaign_range_start: "",
      campaign_range_end: "",
      save_credentials: false,
      advertiserId: "",
      audience_usage:"",
      creativeTagIdDropDown:"",
      creativeTagId:""
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      advertiserId: Yup.string().required("Beeswax Buzz Key is required"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
    }),
    onSubmit: (values) => {},
  },
};
