import React, {useEffect, useState, useRef, useCallback} from "react";
import {useParams} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";

import AboutAudience from "../Components/Onboarding/AboutAudience";
import AdvancedTargeting from "../Components/AudienceDesign/AdvancedTargeting";
import DesignAudience from "../Components/AudienceDesign/DesignAudience";
import KeywordsSites from "../Components/AudienceDesign/KeywordsSites";
import audienceDesignConfig from "../../config/audienceDesignConfig";
import LoadOverlay from "../Components/SharedComponents/LoadOverlay";
import "./Dashboard.css";
import {
    getPpkSegments,
    postSegments,
} from "../../actions/AudienceDesignActions";
import $, {isEmptyObject} from "jquery";
import "./AudienceDesign.css"
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button} from "@progress/kendo-react-buttons";
import {showErrorToastMessage} from "../../Utils/Common";

const DataEnhancement = () => {

    return (
        <>

        </>
    );
}

export default DataEnhancement;
