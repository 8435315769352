import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const stackadaptConfig = {
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With StackAdapt",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "stackadapt",

  /**
   * HTML that explains how to use the form
   */
  instructions:
      "Please provide the Start/End dates of your campaign and your StackAdapt Secret Key. All fields must be completed to push an audience to your seat. " +
      "This Platform does not support clearing and billing for data usage. Segments will be billed on the CPM Rate expressed at the bottom of this page and is based on the number of deployed records.",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "stackadapt",
    text: "StackAdapt",
    img: "stackadapt-logo.png",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink: "",

  layout: [
    {
      fieldType: "radioGroup",
      labelText: "Mobile Devices",
      fieldName: "mobile_devices",
      required: true,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: false,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },
      ],
    },
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType: "radioGroup",
      labelText: "Advertiser Options",
      fieldName: "advertiser_options",
      required: true,
      radioButtons: [
        {
          labelText: "Advertiser",
          fieldName: "single_advertiser",
          value: "single_advertiser",
          checked: false,
        },
        {
          labelText: "All Advertisers",
          fieldName: "all_advertisers",
          value: "all_advertisers",
          checked: false,
        },
      ],
    },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelectStackAdapt",
          fieldName: "advertiserId",
          labelText: "Advertiser ID",
          url: "/v2/secure/attributor/activate-credentials/stackadapt/advertiserId",
          placeholder: "Select an Advertiser ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType: "searchSelect",
          fieldName: "secret_key",
          labelText: "Secret Key",
          url: "/v2/secure/attributor/activate-credentials/semcasting_tradedesk/secret_key",
          placeholder: "Select an Secret Key or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },
        {
          fieldType:'checkbox',
          fieldName: "save_credentials",
          labelText:'Save Advertiser ID and Secret Key for future use',
          checked: false,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },
    {
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
    {
      fieldType: "pricing",
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["advertiserId"],
  formik: {
    initialValues: {
      campaign_range_start: "",
      mobile_devices: "top_ios_android",
//      device_type: ["ios_android"],
      campaign_range_end: "",
      advertiser_options:"single_advertiser",
      advertiserId: "",
      secret_key: "",
      save_credentials: false,
      audience_usage:"",
      creativeTagIdDropDown: "",
      creativeTagId:""
    },
    // We used Yup here for input format validation.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
          "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
          "Campaign Date Range is required"
      ),
      advertiserId: Yup.string().when("advertiser_options", {
        is: "single_advertiser",
        then: Yup.string().required("Advertiser ID is required"),
      }),
      secret_key: Yup.string().required("Secret Key is required"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required"),
      advertiser_options: Yup.string().required("Advertiser Options is required"),
    }),
    onSubmit: (values) => {},
  },
};