import React, {useContext, useEffect, useState} from "react";
import FTPSettings from "../../Admin/Components/Onboarding/FTPSettings";
import S3Settings from "../../Admin/Components/Onboarding/S3Settings"
import "./FTPS3Dashboard.css";
import {getFTPSettings, deleteSettings} from "../../actions/OnBoardingActions";
// import {Grid, GridColumn, getSelectedState, GridToolbar} from '@progress/kendo-react-grid';
// import { groupBy } from '@progress/kendo-data-query';
// import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import {Button, DropDownButton} from "@progress/kendo-react-buttons";
import { getter } from '@progress/kendo-react-common';
import LoadOverlay from "../Components/SharedComponents/LoadOverlay";
import {showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";

import { filterBy, orderBy } from "@progress/kendo-data-query";


import { DataResult, process, State, groupBy } from '@progress/kendo-data-query';

import {
    Grid,
    GridColumn as Column,
    GridDataStateChangeEvent,
    GridExpandChangeEvent,
    GridToolbar,
    GridHeaderSelectionChangeEvent,
    getSelectedState,
    GridSelectionChangeEvent,
} from '@progress/kendo-react-grid';
import {
    setGroupIds,
    getGroupIds,
    setExpandedState,
} from '@progress/kendo-react-data-tools';
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";

const initialDataState = {
    skip: 0,
    group: [
        { field: 'ftps3' },
        // { field: 'ProductName' },
        // { field: 'UnitPrice' },
    ],
};

interface Connection {
    id?: number;
    password?: string;
    host?: string;
    port?: string;
    delimiter?: string;
    user_id?: number;
    user_name?: string;
    transfer_protocol?: string;
    passive_transfer_mode?: string;
    starting_path?: string;
    created_at?: number;
    updated_at?: number;
    deleted_at?: number;
    ftps3?: string;
}

const processWithGroups = (data: Connection[], dataState: State) => {
    const newDataState = process(data, dataState);

    setGroupIds({ data: newDataState.data, group: dataState.group });

    return newDataState;
};

const FTPS3DashV3 = (props) => {

    const [allConnDash, setAllConnDash] = useState([]);

    const [pageLoader, setPageLoader] = useState(false);
    const [allSavedSettings, setAllSavedSettings] = useState([]);
    const [ftpVisible, setFtpVisible] = useState(false);
    const [s3Visible, setS3Visible] = useState(false);
    const [mode, setMode] = useState("");
    const [createEdit, setCreateEdit] = useState("Create");

    const [selectedConnections, setSelectedConnections] = useState({})

    const [enableEdit, setEnableEdit] = useState(false);
    const [enableDelete, setEnableDelete] = useState(false);

    // current connection (default / chosen) details
    const [connection, setConnection] = useState(
        {
            id: 0,
            connectionName: '',
            protocol: 'ftps',
            host: '',
            port: '',
            destinationDir: '',
            delimiter: '',
            transferMode: 'Passive',
            username: '',
            password: '',
        }
    );

    const settingsTemplate = {
        id: 0,
        connection_name: "",
        delimiter: "/",
        host: "us-east-1",
        passive_transfer_mode: true,
        user_name: "",
        password: "",
        port: "",
        starting_path: "",
        transfer_protocol: "ftps",
    }


    const getAllSavedSettings = (storageServiceParam) => {
        setPageLoader(true);
        getFTPSettings().then((res) => {
            if (res && res.data.length) {
                let ftpConnections = res.data.filter(item => {
                    return item.transfer_protocol === "ftps" || item.transfer_protocol === "sftp";
                })
                let s3Connections = res.data.filter(item => {
                    return item.transfer_protocol === "s3";
                })

                storageServiceParam === "FTP" ? setAllSavedSettings(ftpConnections) : setAllSavedSettings(s3Connections);

                const setting = (storageServiceParam === "FTP") ? ftpConnections[ftpConnections.length-1] : s3Connections[s3Connections.length-1];

                setConnection(setting);
            }
            setPageLoader(false);
        });
    };


    const handleNewSetting = (e) => {
        if (e === "FTP") {
            setMode("NEW");
            setCreateEdit("Create");
            setFtpVisible(!ftpVisible);
        } else if (e === "S3") {
            setMode("NEW");
            setCreateEdit("Create");
            setS3Visible(!s3Visible);
        }
    };

    const handleEditSetting = (e) => {
        let currentConnection = selectedConnections[Object.keys(selectedConnections)[0]]

        if (currentConnection['transfer_protocol'] === "ftps" || currentConnection['transfer_protocol'] === "sftp") {
            setCreateEdit("Edit");
            setFtpVisible(!ftpVisible);
            setMode("EDIT");
        } else if (currentConnection['transfer_protocol'] === "s3") {
            setCreateEdit("Edit");
            setS3Visible(!s3Visible);
            setMode("EDIT");
        }
    };

    const cancelFTPSettings = () => {
        setFtpVisible(false);
    };

    const cancelS3Settings = () => {
        setS3Visible(false);
    };

    useEffect( () => {
        refreshTable();
    }, [])


    /////////////// Start of kendo code ///////////////

    const idGetter = getter('id');
    const [currentSelectedState, setCurrentSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});
    const [dataState, setDataState] = React.useState<State>(initialDataState);
    const [resultState, setResultState] = React.useState<DataResult>(
        processWithGroups(
            allConnDash.map((item) => ({
                ...item,
                ['selected']: currentSelectedState[idGetter(item)],
            })),
            initialDataState
        )
    );

    const [collapsedState, setCollapsedState] = React.useState<string[]>([]);

    const onDataStateChange = React.useCallback(
        (event: GridDataStateChangeEvent) => {
            const newDataState = processWithGroups(
                allConnDash.map((item) => ({
                    ...item,
                    ['selected']: currentSelectedState[idGetter(item)],
                })),
                event.dataState
            );

            setDataState(event.dataState);
            setResultState(newDataState);
        },
        [allConnDash]
    );

    const onExpandChange = React.useCallback(
        (event: GridExpandChangeEvent) => {
            const item = event.dataItem;

            if (item.groupId) {
                const collapsedIds = !event.value
                    ? [...collapsedState, item.groupId]
                    : collapsedState.filter((groupId) => groupId !== item.groupId);
                setCollapsedState(collapsedIds);
            }
        },
        [collapsedState]
    );

    const onGroupsToggle = React.useCallback(() => {
        const dataStateWithoutPaging = processWithGroups(
            allConnDash.map((item) => ({
                ...item,
                ['selected']: currentSelectedState[idGetter(item)],
            })),
            {
                group: dataState.group,
            }
        );

        setCollapsedState(
            collapsedState.length
                ? []
                : getGroupIds({ data: dataStateWithoutPaging.data })
        );
    }, [collapsedState, dataState]);

    const setSelectedValue = (data: any[]) => {
        let newData = data.map((item) => {
            if (item.items) {
                return {
                    ...item,
                    items: setSelectedValue(item.items),
                };
            } else {
                return {
                    ...item,
                    ['selected']: currentSelectedState[idGetter(item)],
                };
            }
        });
        return newData;
    };

    const newData = setExpandedState({
        data: setSelectedValue(resultState.data),
        collapsedIds: collapsedState,
    });

    const onHeaderSelectionChange = React.useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState = {};
            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setCurrentSelectedState(newSelectedState);
        },
        []
    );

    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            let selectedID = event.dataItems[event.endRowIndex].id;

            let selectedConnectionsTemp = selectedConnections;

            if(event.dataItem) {
                if(selectedConnections[selectedID]) {
                    delete selectedConnectionsTemp[selectedID];
                } else if(selectedID != null) {
                    selectedConnectionsTemp[selectedID] = event.dataItems[event.endRowIndex];
                }
            } else {
                selectedConnectionsTemp = {};
                selectedConnectionsTemp[selectedID] = event.dataItems[event.endRowIndex];
            }

            setSelectedConnections(selectedConnectionsTemp);

            if(Object.keys(selectedConnectionsTemp).length === 1) {
                setEnableEdit(true);
                setEnableDelete(true);
            }
            else if(Object.keys(selectedConnectionsTemp).length === 0) {
                setEnableEdit(false);
                setEnableDelete(false);
            } else {
                setEnableEdit(false);
                setEnableDelete(true);
            }

            setConnection(event.dataItems[event.endRowIndex]);

            const newSelectedState = getSelectedState({
                event,
                selectedState: currentSelectedState,
                dataItemKey: 'id',
            });

            // console.log("currentSelectedState:", currentSelectedState);
            // console.log("newSelectedState:", newSelectedState);

            // console.log("selectedConnectionsTemp", selectedConnectionsTemp);
            // console.log("selectedConnections", selectedConnections);

            setCurrentSelectedState(newSelectedState);

        },
        [currentSelectedState]
    );

    const getNumberOfItems = (data: any[]) => {
        let count = 0;
        data.forEach((item) => {
            if (item.items) {
                count = count + getNumberOfItems(item.items);
            } else {
                count++;
            }
        });
        return count;
    };

    const getNumberOfSelectedItems = (data: any[]) => {
        let count = 0;
        data.forEach((item) => {
            if (item.items) {
                count = count + getNumberOfSelectedItems(item.items);
            } else {
                count = count + (item.selected === true ? 1 : 0);
            }
        });
        return count;
    };

    const checkHeaderSelectionValue = () => {
        let selectedItems = getNumberOfSelectedItems(newData);
        return newData.length > 0 && selectedItems === getNumberOfItems(newData);
    };

    const handleItemClick = (event: any) => {
        if(event.item) {
            handleNewSetting(event.item.text);
        }
    }

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

    const deleteConfirmed = (e) => {
        setShowDeleteConfirmationDialog(false);

        refreshTable().then(() => {
            Object.keys(selectedConnections).forEach((conn) => {
                deleteSettings(conn, selectedConnections[conn].connection_name).then((res) => {
                    // showSuccessToastMessage(`${selectedConnections[conn].connection_name} successfully deleted`);
                    refreshTable();
                })
            })

            setEnableDelete(false);
            setEnableEdit(false);
        })
    }

    const deleteDenied = () => {
        setShowDeleteConfirmationDialog(false);
    }

    const refreshTable = async () => {
        setPageLoader(true);

        let allConnectionsDash = [];

        await getFTPSettings().then((res) => {
            if (res && res.data.length) {
                allConnectionsDash = res.data;
                allConnectionsDash.forEach((conn) => {
                    if(conn.transfer_protocol === "ftps" || conn.transfer_protocol === "sftp") {
                        conn.ftps3 = "FTP";
                    } else {
                        conn.ftps3 = "S3";
                    }
                })

                setAllConnDash(allConnectionsDash);
            }
        })

        await setPageLoader(false);
    }

    useEffect(async () => {
        let connNameFilterCell = document.querySelector('[title="connection-name-filter"]');

        if(connNameFilterCell) connNameFilterCell.placeholder = "Search..."


        await refreshTable();

    }, [])

    useEffect(() => {

        const newDataState = processWithGroups(
            allConnDash.map((item) => ({
                ...item,
                ['selected']: null,
            })),
            dataState
        )
        setResultState(newDataState);
    }, [allConnDash])

    useEffect(() => {
        let selectedConnectionsTemp = selectedConnections;

        if(Object.keys(selectedConnectionsTemp).length) {
            for(let i = 0; i < allConnDash.length; i++) {
                if(selectedConnectionsTemp[Object.keys(selectedConnectionsTemp)[0]].id === allConnDash[i].id) {
                    selectedConnectionsTemp[Object.keys(selectedConnectionsTemp)[0]] = allConnDash[i];
                    break;
                }
            }
        }
        setSelectedConnections(selectedConnectionsTemp);

    },[resultState])

    useEffect(() => {
        if(!enableDelete) {
            const selectedConnectionsTemp = selectedConnections;
            Object.keys(selectedConnections).forEach((id) => {
                delete selectedConnectionsTemp[id];
            })
            setSelectedConnections({selectedConnectionsTemp});
        }
    },[enableDelete])


    const initialSort = [
        {
            field: "connection_name",
            // dir: "asc"
        }
    ]

    const [sort, setSort] = useState(initialSort);

    const initialFilter = {
        logic: "and",
        filters: [
            {
                field: "connection_name",
                operator: "contains",
                value: ""
            }
        ]
    };

    const [filter, setFilter] = useState(initialFilter);


    return (
        <div>
            <LoadOverlay active={pageLoader}>
                {ftpVisible && (<FTPSettings
                    visible={true}
                    ftpSettings={Object.keys(selectedConnections).length ? selectedConnections[Object.keys(selectedConnections)[0]] : settingsTemplate}
                    setFtpSettings={setConnection}
                    setFtpVisible={setFtpVisible}
                    cancelFTPSettings={cancelFTPSettings}
                    getAllSavedSettings={getAllSavedSettings}
                    mode={mode}
                    createEdit={createEdit}
                    allConnDash={allConnDash}
                    setAllConnDash={setAllConnDash}
                    getFTPSettings={getFTPSettings}
                    refreshTable={refreshTable}
                    processWithGroups={processWithGroups}
                    currentSelectedState={currentSelectedState}
                    idGetter={idGetter}
                    dataState={dataState}
                    setResultState={setResultState}
                    setEnableDelete={setEnableDelete}
                    setEnableEdit={setEnableEdit}
                    setSelectedConnections={setSelectedConnections}
                />)}
                {s3Visible && (<S3Settings
                    visible={true}
                    s3Settings={Object.keys(selectedConnections).length ? selectedConnections[Object.keys(selectedConnections)[0]] : settingsTemplate}
                    setS3Settings={setConnection}
                    setS3Visible={setS3Visible}
                    cancelS3Settings={cancelS3Settings}
                    getAllSavedSettings={getAllSavedSettings}
                    mode={mode}
                    createEdit={createEdit}
                    allConnDash={allConnDash}
                    setAllConnDash={setAllConnDash}
                    getFTPSettings={getFTPSettings}
                    refreshTable={refreshTable}
                    processWithGroups={processWithGroups}
                    currentSelectedState={currentSelectedState}
                    idGetter={idGetter}
                    dataState={dataState}
                    setResultState={setResultState}
                    setEnableDelete={setEnableDelete}
                    setEnableEdit={setEnableEdit}
                    setSelectedConnections={setSelectedConnections}
                />)}
                {showDeleteConfirmationDialog && (

                    <div className={"admin-users-delete-dialog-container"}>
                        <Dialog title={"Delete Connection"} onClose={deleteDenied} className={"admin-users-delete-dialog dialog-standard"}>
                            <p className={"admin-users-delete-dialog-message-text"}>Are you sure you want to delete <strong>{Object.keys(selectedConnections).length}</strong> connection(s)?</p>
                            <ul>
                                {Object.keys(selectedConnections).map((conn : any) => {
                                    return <li key={selectedConnections[conn].id} style={{fontSize: "16px"}}> {selectedConnections[conn].connection_name} </li>
                                })}
                            </ul>

                            <DialogActionsBar>
                                <Button className="button-standard button-white" onClick={deleteDenied}>No</Button>
                                <Button className="button-standard button-submit" onClick={deleteConfirmed}>Yes</Button>
                            </DialogActionsBar>
                        </Dialog>
                    </div>
                )}

                <Grid
                    className={"connections-manager-grid"}
                    groupable={true}
                    data={orderBy(filterBy(newData, filter), sort)}
                    filterable={true}
                    filter={filter}
                    total={resultState.total}
                    onDataStateChange={onDataStateChange}
                    {...dataState}
                    onExpandChange={onExpandChange}
                    expandField="expanded"
                    selectedField={'selected'}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    onSelectionChange={onSelectionChange}
                    // rowSelection={true}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: 'multiple',
                    }}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                        setSort(e.sort);
                    }}
                >
                    <GridToolbar>
                        <div className={"ftpS3Grid"}>
                            <div className={"admin-ftp-s3-button-group"}>
                                <DropDownButton className={"ftp-s3-new-button-dropdown"}
                                                items={[{
                                                    text: "FTP",
                                                },
                                                    {
                                                        text: "S3",
                                                    },
                                                ]}
                                                iconClass={"icon-plus"}
                                                text={"New"}
                                                buttonClass={"ftp-s3-new-button-dropdown button-standard button-submit new-ftp-button"}
                                                onItemClick={handleItemClick}
                                                popupSettings={{
                                                    popupClass: "ftp-s3-dropdown-popup",
                                                    anchorAlign: {
                                                        horizontal: "left",
                                                        vertical: "bottom"
                                                    },
                                                    popupAlign: {
                                                        horizontal: "left",
                                                        vertical: "top"
                                                    }
                                                }}
                                >

                                </DropDownButton>

                                <Button
                                    className={"button-standard button-submit"}
                                    iconClass="icon-edit"
                                    disabled={!enableEdit}
                                    onClick={(e) => handleEditSetting(e)}
                                    // onClick={() => getAllSavedSettingsDashboard()}
                                >
                                    Edit
                                </Button>
                            </div>

                            <div className={"admin-ftp-s3-delete-button-div"}>

                                <Button
                                    className={"button-standard button-red"}
                                    iconClass="fa fa-trash"
                                    disabled={!enableDelete}
                                    onClick={(e) => {
                                        setShowDeleteConfirmationDialog(true);
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>

                    </GridToolbar>
                    <Column
                        field="selected"
                        width={50}
                        headerSelectionValue={checkHeaderSelectionValue()}
                        filterable={false}
                    />
                    <Column field="connection_name" title="Connection Name" filterTitle={"connection-name-filter"}/>
                    <Column field="host" title="Host/Region" filterable={false}/>
                    <Column field="user_name" title="User Name/Access Key" filterable={false}/>
                    <Column field="ftps3" filterable={false} title="FTP/S3" width="80px" />
                </Grid>
            </LoadOverlay>
        </div>
    )
}

export default FTPS3DashV3;