import React, {useEffect, useState} from "react";
import AgeRange from "../Onboarding/AgeRange";
import AnnualRevenue from "../Onboarding/AnnualRevenue";
import DigitalActivityIndex from "../Onboarding/DigitalActivityIndex";
import EmployeesRange from "../Onboarding/EmployeesRange";
import EthnicBackground from "../Onboarding/EthnicBackground";
import EthnicGroup from "../Onboarding/EthnicGroup";
import GenderButtonGroup from "../Onboarding/GenderButtonGroup";
import HomeOwnershipButtonGroup from "../Onboarding/HomeOwnershipButtonGroup";
import HomeValueRange from "../Onboarding/HomeValueRange";
import IncomeRange from "../Onboarding/IncomeRange";
import InflationSensitivityIndex from "../Onboarding/InflationSensitivityIndex";
import LegislativeDistricts from "../Onboarding/LegislativeDistricts";
import Locations from "./Locations";
import Naics from "../Onboarding/Naics";
import PoliticalPartyButtonGroup from "../Onboarding/PoliticalPartyButtonGroup";
import SocialMatrix from "../Onboarding/SocialMatrix";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Form, FormElement, FieldWrapper} from "@progress/kendo-react-form";
import {Label} from "@progress/kendo-react-labels";
import {RadioGroup} from "@progress/kendo-react-inputs";
import "./DesignAudience.css";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {Notification} from "@progress/kendo-react-notification";

const DesignAudience = (props) => {
    const [selectedTargetType, setSelectedTargetType] = useState(props.formik.values.target_type);

    const targetTypes = [
        { label: "Consumer", value: "CONSUMER"},
        { label: "Business", value: "BUSINESS"}
    ]

    useEffect(() => {
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            setSelectedTargetType(props.segmentEditInfo.data.segment_design.target_type);
            props.handleTargetType(props.segmentEditInfo.data.segment_design.target_type);
        }
    }, [props.segmentEditInfo]);

    useEffect(() => {
        if (props.formik.values.target_type === "BUSINESS" &&
            props.formik.values.segment_design.titleMatching.length > 0) {
            props.setMsg("You will now be creating a new segment targeting Consumers where the industry and title match the Industry Naics Codes of these businesses.");
        } else {
            props.setMsg("");
        }
    }, [props.formik.values.target_type === "BUSINESS" && props.formik.values.segment_design.titleMatching.length > 0])

    // Reset formik tile values when changing target type.
    const handleTargetType = (e) => {
        setSelectedTargetType(e.value);
        props.formik.values.target_type = e.value;
        props.formik.values.segment_design.target_type = e.value;
        props.formik.values.segment_design.consumer = [];
        props.formik.values.segment_design.sportsAttendees = [];
        props.formik.values.segment_design.politicalAffiliations = [];
        props.formik.values.segment_design.homeOwner = [];
        props.formik.values.segment_design.personalFinance =  [];
        props.formik.values.segment_design.specialtySegments = [];
        props.formik.values.segment_design.specialtySegmentsBusiness  = [];
        props.formik.values.segment_design.titleMatching = [];
        props.formik.values.segment_design.acxiomLifestyle = [];
        props.formik.values.segment_design.hcpspecialtySegmentsBusiness = [];
        // props.formik.values.segment_design.healthWise = [];
        // props.formik.values.segment_design.i360 = [];
        // props.formik.values.segment_design.iqvia = [];
        props.formik.values.segment_design.knowWhoConsumer = [];
        props.formik.values.segment_design.l2Data = [];
        props.formik.values.segment_design.purpleLab = [];
        props.formik.values.segment_design.purpleLabHCP = [];
        props.formik.values.segment_design.purpleLabHPD = [];
        props.formik.values.segment_design.salesintel = [];
        props.formik.values.segment_design.salesinteltechno = [];
        props.formik.values.segment_design.spectrumMedia = [];
        props.formik.values.segment_design.travelSegments = [];
        props.handleTargetType(e.value);
    };

    const handleControlsState = (state) => {
        props.handleControlsState(state);
    };

    return (
        <>
            <Card>
                <CardTitle>
                    <Label className="section-label">DESIGN AUDIENCE</Label>
                </CardTitle>
                <CardBody>
                    <Form render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                        <legend className={'k-form-legend spacingLine'}></legend>

                        <div className={"basic-segment-div"}>
                            <Label className={"basic-segment-label"}>
                                <span>Basic Segments are $1.00 per thousand</span>
                            </Label>
                        </div>
                        <div className={"notification-div"} style={{textAlign: "center", display: props.msg.length > 0 ? "" : "none"}}>
                            <Notification
                                style={{backgroundColor:"#FFFFCC"}}
                                type={{style:"none"}}
                                closable={false}>
                                <span>{props.msg}</span>
                            </Notification>
                        </div>
                        <div className={"design-audience-container"}>
                            <div className={"design-audience-column-1"}>
                                <Form render={formRenderProps => <FormElement style={{
                                    width: "100%"
                                }}>
                                    <FieldWrapper className={"targeting-first-column-field"}>
                                        <Label className={"field-label"} >Target Type</Label>
                                        <RadioGroup
                                            data={targetTypes}
                                            value={selectedTargetType}
                                            onChange={handleTargetType}
                                            layout="horizontal"
                                            style={{width:"100%", fontSize:".9rem"}}
                                        />
                                    </FieldWrapper>
                                    {selectedTargetType === "CONSUMER" && (
                                        <>
                                            <FieldWrapper className={"targeting-first-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Age Range</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "8rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                        <i className="fa fa-question-circle-o tooltip-question-icon"
                                                           aria-hidden="true"
                                                           title={"Age Range."}></i>
                                                    </Tooltip>
                                                </span>
                                                <AgeRange
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-first-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Income Range</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                        <i className="fa fa-question-circle-o tooltip-question-icon"
                                                           aria-hidden="true"
                                                           title={"Income Range specifies estimated Income ranges."}></i>
                                                    </Tooltip>
                                                </span>
                                                <IncomeRange
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-first-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Home Value</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                        <i className="fa fa-question-circle-o tooltip-question-icon"
                                                           aria-hidden="true"
                                                           title={"Home Value specifies estimated Home Value ranges."}></i>
                                                    </Tooltip>
                                                </span>
                                                <HomeValueRange
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-first-column-field"}>
                                                <div className={"targeting-full-row"}>
                                                    <div className={"column"}>
                                                        <span className={"tooltip-label-and-icon-wrapper"}>
                                                            <Label className={"field-label"}>Home Ownership</Label>
                                                            <Tooltip
                                                                position="right"
                                                                anchorElement="target"
                                                                tooltipStyle={{
                                                                    width: "15rem",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                    aria-hidden="true"
                                                                    title={"Home Ownership identifies an individual as a Home Owner, Renter or both."}></i>
                                                            </Tooltip>
                                                        </span>
                                                        <HomeOwnershipButtonGroup
                                                            formik={props.formik}
                                                            controlsState={props.controlsState}
                                                            handleControlsState={props.handleControlsState}
                                                            segmentEditInfo={props.segmentEditInfo}/>
                                                    </div>
                                                    <div className={"column"}>
                                                        <span className={"tooltip-label-and-icon-wrapper"}>
                                                            <Label className={"field-label"}>Gender</Label>
                                                            <Tooltip
                                                                position="right"
                                                                anchorElement="target"
                                                                tooltipStyle={{
                                                                    width: "15rem",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                   aria-hidden="true"
                                                                   title={"Gender identifies individuals as Men or Women."}></i>
                                                            </Tooltip>
                                                        </span>
                                                        <GenderButtonGroup
                                                            formik={props.formik}
                                                            controlsState={props.controlsState}
                                                            handleControlsState={props.handleControlsState}
                                                            segmentEditInfo={props.segmentEditInfo}/>
                                                    </div>
                                                </div>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-first-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Political Party</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                        <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Political Party is an organization that coordinates candidates to compete in a particular country's elections."}></i>
                                                    </Tooltip>
                                                </span>
                                                <PoliticalPartyButtonGroup
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-first-column-field design-audience-locations-div"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>
                                                        Legislative Districts
                                                    </Label>

                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Legislative Districts refer to areas from which members are elected to state or equivalent entity legislatures."}></i>
                                                    </Tooltip>
                                                </span>

                                                {/*<Label className={"field-label"}>Legislative Districts</Label>*/}
                                                <LegislativeDistricts
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                        </>
                                    )}
                                    {selectedTargetType === "BUSINESS" && (
                                        <>
                                            <FieldWrapper className={"targeting-first-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                <Label className={"field-label"}>Annual Revenue</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                 aria-hidden="true"
                                                                 title={"Annual Revenue specifies Revenue estimate ranges."}></i>
                                                    </Tooltip>
                                                </span>
                                                <AnnualRevenue
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-first-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>
                                                        NAICS Codes
                                                    </Label>

                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"NAICS Codes refer to 3 or 6 digit Industry Codes."}></i>
                                                    </Tooltip>
                                                </span>

                                                {/*<Label className={"field-label"}>NAICS Codes</Label>*/}
                                                <Naics
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                        </>
                                    )}
                                </FormElement>} />
                            </div>
                            <div className={"design-audience-column-2"}>
                                <Form render={formRenderProps => <FormElement style={{
                                    width: "100%"
                                }}>
                                    <FieldWrapper className={"targeting-second-column-field"}>
                                        <Label className={"field-label targeting-selection-type-label"} style={{paddingBottom:"3.4rem"}}></Label>
                                        {/*Moved to Advanced Targeting Section - demo feedback - 1/27/23*/}
                                        {/*<RadioGroup*/}
                                        {/*    data={selectionTypes}*/}
                                        {/*    value={selectionType}*/}
                                        {/*    onChange={handleSelectionType}*/}
                                        {/*    layout="vertical"*/}
                                        {/*    style={{width:"100%", fontSize:"16px"}}*/}
                                        {/*/>*/}
                                    </FieldWrapper>
                                    {selectedTargetType === "CONSUMER" && (
                                        <>
                                            <FieldWrapper className={"targeting-first-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>
                                                        Ethnic Group
                                                    </Label>

                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Ethnic Group refers to a specific community of people who share a common cultural, linguistic, and/or religious identity, and who are recognized as a distinct social group."}></i>
                                                    </Tooltip>

                                                </span>

                                                {/*<Label className={"field-label"}>Ethnic Group</Label>*/}
                                                <EthnicGroup
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-first-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                   <Label className={"field-label"}>
                                                       Ethnic Background
                                                   </Label>

                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Ethnic Background refers to a person's individual cultural and ancestral heritage, such as their language, customs, and traditions."}></i>
                                                    </Tooltip>
                                                </span>

                                                {/*<Label className={"field-label"}>Ethnic Background</Label>*/}
                                                <EthnicBackground
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-second-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>
                                                        Social Matrix
                                                    </Label>

                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Social Matrix refers to personas based on Age, Life Style & Affluence."}></i>
                                                    </Tooltip>

                                                </span>


                                                {/*<Label className={"field-label"}>Social Matrix</Label>*/}
                                                <SocialMatrix
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-second-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>
                                                        Digital Activity Index
                                                    </Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Digital Activity Index refers to the measurement of internet engagement and time online."}></i>
                                                    </Tooltip>
                                                </span>
                                                {/*<Label className={"field-label"}>Digital Activity Index</Label>*/}
                                                <DigitalActivityIndex
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-second-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>
                                                        Inflation Sensitivity Index
                                                    </Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Inflation Sensitivity Index identifies how vulnerable a particular household may be in the current economic climate."}></i>
                                                    </Tooltip>
                                                </span>

                                                {/*<Label className={"field-label"}>Inflation Sensitivity Index</Label>*/}
                                                <InflationSensitivityIndex
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-second-column-field design-audience-locations-div"} style={{marginTop:"-.4rem"}}>
                                                <Label className={"field-label"}>Locations</Label>
                                                <Locations
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                        </>
                                    )}
                                    {selectedTargetType === "BUSINESS" && (
                                        <>
                                            <FieldWrapper className={"targeting-second-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Number of Employees</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Number of Employees specify ranges for the Number of Employees at a business."}></i>
                                                    </Tooltip>
                                                </span>
                                                <EmployeesRange
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-second-column-field"}>
                                                <Label className={"field-label"}>Locations</Label>
                                                <Locations
                                                    formik={props.formik}
                                                    controlsState={props.controlsState}
                                                    handleControlsState={props.handleControlsState}
                                                    segmentEditInfo={props.segmentEditInfo}/>
                                            </FieldWrapper>
                                        </>
                                    )}
                                </FormElement>} />
                            </div>
                        </div>
                    </FormElement>} />
                </CardBody>
            </Card>
        </>
    )
}

export default DesignAudience;