import React, { useEffect, useState} from "react";
import {
    loadAccessibleGroups,
    loadAccessibleRoles,
    loadGroupEditUserRoles,
    loadUsers
} from "../../../actions/UserActions";
import {useFormik} from "formik";
import * as Yup from "yup";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage, sortItemsByField} from "../../../Utils/Common";
import {
    ASCENDING_ORDER, DESCENDING_ORDER,
    GROUPS_CREATE_API,
    GROUPS_DELETE_API,
    GROUPS_UPDATE_PATCH_API,
    NOT_A_MEMBER,
    SUCCESS_STATUS,
    USERS_GROUPS_EDIT_PUT_API
} from "../../../api/constants";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import {filterBy} from "@progress/kendo-data-query";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import {Button} from "@progress/kendo-react-buttons";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import PermissionsEditGroup from "./PermissionsEditGroup";
import {UsersHelpers} from "../../../helpers/UsersHelpers";

const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "groupName",
            operator: "contains",
            value: ""
        }
    ]
};

const GroupsPermissionsDashboard = () => {

    let userHelpers = new UsersHelpers();

    const [users, setUsers] = useState([]);
    const [tableSortOrder, setTableSortOrder] = useState(ASCENDING_ORDER);
    const [dashboardOverlayActive, setDashboardOverlayActive] = useState(false);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(initialFilter);
    const [dialogMode, setDialogMode] = useState("new");
    const [openEditCreateDialog, setOpenEditCreateDialog] = useState(false);
    const [editGroupObj, setEditGroupObj] = useState({});
    const [allUsersRoles, setAllUsersRoles] = useState([])

    const groupEditFormik = useFormik({
        initialValues: {
            group_name: '',
            group_id: '',
            userNRoles: []
        },
        // We used Yup here.
        validationSchema: Yup.object().shape({
            group_name: Yup.string().required('Group Name is required'),
            userNRoles: Yup.array().of(
                Yup.object().shape({
                    user_id: Yup.string(),
                    user_name: Yup.string(),
                    role_id: Yup.number(),
                    group_id: Yup.number(),
                    founding_assignment: Yup.boolean()
                })
            )
        }),
        onSubmit: values => {
        },
    });

    const usersLoad = async () => {
        try {
            const values = await Promise.allSettled([loadUsers()]);
            // console.log("In accessible users load:", values[0].value);

            setUsers(values[0].value);

            await resetGroupUserRolesForm(values[0].value);

        } catch (e) {
        }
    }

    const accessibleGroupsLoad = async () => {
        const groupsRes = await Promise.allSettled([loadAccessibleGroups()]);
        // console.log("In accessible groups load:", groupsRes[0].value);

        const groups = groupsRes[0].value;

        // console.log("GROUPS:", groups);

        setData(groups);
    }

    const resetGroupUserRolesForm = async (usersList = users) => {
        let usersArr = usersList
        if (usersArr) {
            let userNRoles = usersArr.map(user => {
                return {
                    user_id: user.id,
                    user_name: `${user.first_name} ${user.last_name}`,
                    role_id: '',
                    group_id: '',
                    founding_assignment: false,
                    role_name: "Not a Member",
                }
            });
            sortItemsByField(userNRoles, 'user_name', tableSortOrder);

            setAllUsersRoles(userNRoles);

            await groupEditFormik.setValues({group_name: '', group_id: '', userNRoles})
        }
    }

    const setGroupEditFormikData = async (group) => {
        // setShowUserSection(true)
        await resetGroupUserRolesForm()
        if (group) {
            // setGroupEditSectionData(group)

            let userNRoles = groupEditFormik.values.userNRoles;


            await groupEditFormik.setValues({...group, group_id: group.id, userNRoles})
        } else {
            // setGroupEditSectionData(null)
        }
    }

    const refreshTable = async () => {
        setDashboardOverlayActive(true);

        // console.log("In refreshTable()");

        await Promise.allSettled([usersLoad(), accessibleGroupsLoad()]);

        setDashboardOverlayActive(false);
    }

    useEffect(async () => {

        let groupNameFilterCell = document.querySelector('[title="group-name-filter"]');
        groupNameFilterCell.placeholder = "Search..."

        await refreshTable();
    }, []);

    const openEdit = (group) => {
        setOpenEditCreateDialog(true);
        setEditGroupObj(group);
        setDialogMode("edit");
    }

    const closeEdit = () => {
        setOpenEditCreateDialog(false);
    }

    const MyEditCommandCell = (props) => {
        return (
            <EditCommandCell {...props} openEdit={openEdit}/>
        )
    };

    const EditCommandCell = (props) => {

        return (
            <td>
                <Button
                    className="button-standard button-submit button-no-left-margin"
                    // onClick={() => props.enterEdit(props.dataItem)}
                    iconClass="icon-edit"
                    onClick={async (e) => {
                        // console.log("edit button event:", e);
                        // console.log("props.dataItem", props.dataItem);

                        // await loadEditGroupData(props.dataItem);

                        props.openEdit(props.dataItem);
                    }}
                >
                    Edit
                </Button>
            </td>
        );
    };

    return (
        <div className={"perm-groups-dash-container"}>
            <LoadOverlay active={dashboardOverlayActive} height={50} width={50}>

            <Grid
                data={filterBy(data, filter)}
                filterable={true}
                filter={filter}
                onFilterChange={(e) => setFilter(e.filter)}
            >
                <GridToolbar>
                    <div className={"perm-users-grid-toolbar-container"}>
                        <div className={"permissions-users-grid-toolbar-buttons"}>
                            <Button
                                className={"button-standard button-submit button-no-left-margin"}
                                iconClass={"icon-plus"}
                                onClick={async () => {
                                    setDialogMode("new");
                                    await resetGroupUserRolesForm().then(() => {
                                        setOpenEditCreateDialog(true);
                                    })
                                }}
                            >
                                New
                            </Button>
                        </div>
                    </div>
                </GridToolbar>

                <Column field="group_name" title="Group Name" filterTitle={"group-name-filter"}/>
                <Column cell={MyEditCommandCell} filterable={false} width={"121px"}/>

            </Grid>

            {openEditCreateDialog && (
                <PermissionsEditGroup
                    dialogMode={dialogMode}
                    openEditCreateDialog={openEditCreateDialog}
                    setOpenEditCreateDialog={setOpenEditCreateDialog}
                    editGroupObj={editGroupObj}
                    groupEditFormik={groupEditFormik}
                    // accessibleRoles={accessibleRoles}
                    allUsersRoles={allUsersRoles}
                    userHelpers={userHelpers}
                    refreshTable={refreshTable}
                >
                </PermissionsEditGroup>
            )}

            </LoadOverlay>
        </div>
    )
}

export default GroupsPermissionsDashboard;