import * as Yup from "yup";
import { COMPANY_BASE_PROPERTIES } from "../../../../api/constants";

export const xandrttdConfig = {
  ...COMPANY_BASE_PROPERTIES,
  /**
   * User Readable name of company the user is onBoarding with
   */
  companyText: "Onboarding With TTD via Xandr",

  /**
   * lowercase no spaces or underscores name of company
   * This will be passed to the activate model and
   * used when saving to create the correct submission url
   */
  company: "xandrttd",

  /**
   * HTML that explains how to use the form
   */
   instructions:
   "You have selected a platform that automatically reports usage for targeting. " +
   "You will be billed for data usage by the platform unless the segment is applied for suppression purposes. " +
   "Suppression segments are billed by Semcasting directly based on the discounted deployed record formula. " +
   "Activating this audience means you agree to this billing method. ",

  /**
   * data used to create a company activation block
   */
  companyBlockOptions: {
    val: "xandrttd",
    text: "The Trade Desk via Xandr",
    img: "xandr-ttd.svg",
  },

  /**
   * anchor tag used to login to a specific platform
   */
  loginLink: "",

  layout: [
    {
      fieldType: "radioGroup",
      labelText: "Ad Type",
      fieldName: "ad_type",
      required: true,
      radioButtons: [
        {
          labelText: "Banner",
          fieldName: "banner",
          value: "banner",
          checked: true,
        },
        {
          labelText: "Video",
          fieldName: "video",
          value: "video",
          checked: false,
        },
        {
          labelText: "Native",
          fieldName: "native",
          value: "native",
          checked: false,
        },
        {
          labelText: "Audio",
          fieldName: "audio",
          value: "audio",
          checked: false,
        },
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Mobile Devices",
      fieldName: "mobile_devices",
      required: true,
      radioButtons: [
        {
          labelText: "Top Devices",
          fieldName: "top_devices",
          value: "top_ios_android",
          checked: true,
        },
        {
          labelText: "All Devices",
          fieldName: "all_devices",
          value: "ios_android",
          checked: false,
        },
      ],
    },
    {
      fieldType: "checkboxGroup",
      labelText: "Device Types",
      fieldName: "xandr_device_types",
      required: true,
      checkboxes: [
        {
          labelText: "Phone",
          fieldName: "phone",
          value: "phone",
          checked: false,
          required: true,
        },
        {
          labelText: "Tablet",
          fieldName: "tablet",
          value: "tablet",
          checked: false,
          required: false,
        },
        {
          labelText: "PC",
          fieldName: "pc",
          value: "pc",
          checked: false,
          required: false,
        },
        {
          labelText: "TV",
          fieldName: "tv",
          value: "tv",
          checked: false,
          required: false,
        },
        {
          labelText: "Game Console",
          fieldName: "gameconsole",
          value: "gameconsole",
          checked: false,
          required: false,
        },
        {
          labelText: "Set Top Box",
          fieldName: "stb",
          value: "stb",
          checked: false,
          required: false,
        },
      ],
    },
    {
      fieldType: "dateRange",
      fieldName: "campaign_range",
      labelText: "Campaign Date Range",
      required: "dateRangeRequired",
    },
    {
      fieldType: "savableFieldsWrapper",
      layout: [
        {
          fieldType: "searchSelect",
          fieldName: "memberId",
          labelText: "DSP Member ID",
          url: "/v2/secure/attributor/activate-credentials/xandrttd/memberId",
          placeholder: "Select a DSP Member ID or add a new one",
          namePropName: "value",
          textPropName: "value",
          valuePropName: "id",
          required: true,
        },{
          fieldType:'checkbox',
          fieldName:'save_credentials',
          labelText:'Save DSP Member ID for future use',
          checked: false
        }
      ],
    },
    {
      fieldType: "radioGroup",
      labelText: "Planned Usage of this Segment:",
      fieldName: "audience_usage",
      required: true,
      radioButtons: [
        {
          labelText: "Targeting",
          fieldName: "use_type_inclusion",
          value: "I",
          checked: false,
        },
        {
          labelText: "Suppression",
          fieldName: "use_type_exclusion",
          value: "X",
          checked: false,
        },
      ],
    },{
      fieldType: "selectPickerTag",
      fieldName: "creativeTagIdDropDown",
      labelText: "Creative Tag ID",
      url: "/v2/secure/attributor/creative-tags",
      placeholder: "Select a New or existing Tag for your creative",
      namePropName: "creativeTagId",
      textPropName: "creativeTagId",
      valuePropName: "creativeTagId",
      required: true,
    },
    {
      fieldType: "creativeTagId",
      fieldName: "creativeTagId",
      labelText: "Creative Tag ID",
      required: true,
    },
    {
      fieldType: "creativeTag",
      fieldName: "creativeTag",
      labelText: "Creative Tag",
      required: true,
    },
    {
      fieldType: "pricing",
    },
  ],

  /**
   * an array of property names that can be saved and repeatedly used to "Activate/OnBoard" with a specific company
   */
  savableDataNames: ["memberId"],
  formik: {
    initialValues: {
      ad_type: "banner",
      mobile_devices: "top_ios_android",
      xandr_device_types: ["phone", "tablet", "pc"],
      campaign_range_start: "",
      campaign_range_end: "",
      planned_usage: "target",
      memberId: "",
      save_credentials: false,
      audience_usage:"",
      creativeTagIdDropDown: "",
      creativeTagId:""
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      campaign_range_start: Yup.date().required(
        "Campaign Date Range is required"
      ),
      campaign_range_end: Yup.date().required(
        "Campaign Date Range is required"
      ),
      memberId: Yup.number().typeError('DSP Member ID accept numeric values only').required("DSP Member ID is required").positive("DSP Member ID accept numeric values only").integer("DSP Member ID accept numeric values only"),
      xandr_device_types: Yup.array().min(1, "Must choose at least one"),
      audience_usage: Yup.string().required("Planned Usage is required"),
      creativeTagIdDropDown: Yup.string().required("Creative Tag ID is required"),
      creativeTagId: Yup.string().required("Creative Tag ID is required")
    }),

    onSubmit: (values) => {},
  },
};
