import React, {useEffect, useMemo, useState} from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import "./DigitalActivityIndex.css";

const DigitalActivityIndex = (props) => {

    const [value, setValue] = useState(props.controlsState ?
        props.controlsState.get("digital_activity_index") : []);


    // const digitalActivityOptions = [
    //     {text: "No Activity", value: ['00']},
    //     {text: "Least Activity", value: ['09','10']},
    //     {text: "Below Average", value: ['07','08']},
    //     {text: "Average", value: ['05','06']},
    //     {text: "Above Average", value: ['03','04']},
    //     {text: "Most Active", value: ['01','02']}
    // ];
    const digitalActivityOptions = [
        {text: "No Activity", value: "no_activity", num: ['00']},
        {text: "Least Activity", value: "least_activity", num: ['09','10']},
        {text: "Below Average", value: "below_average", num: ['07','08']},
        {text: "Average", value: "average", num: ['05','06']},
        {text: "Above Average", value: "above_average", num: ['03','04']},
        {text: "Most Active", value: "most_active", num: ['01','02']}
    ];

    // const digitalActivityIndexValuesMap = {
    //     'no_activity':['00'],
    //     'least_activity':['09','10'],
    //     'below_average':['07','08'],
    //     'average':['05','06'],
    //     'above_average':['03','04'],
    //     'most_active':['01','02']
    // };

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.digital_activity_index) {
                props.segmentEditInfo.data.segment_design.primaryData.digital_activity_index.forEach(optionValue => {
                    digitalActivityOptions.find(option => {
                        if (option.num.includes(optionValue)) {
                            if (!arr.includes(option) ) arr.push(option);
                        }
                        ;
                    })
                })
                setValue(arr);
            }
        }
    }, [props.segmentEditInfo]);

    const onDigitalActivityChange = (e) => {
        let values = [];
        let controlValues = [];
        e.value.forEach((val) => {
            // values = values.concat(digitalActivityIndexValuesMap[val.value]);
            values = values.concat(val.num);
            controlValues.push(val);
            console.log(val);
        });
        setValue(controlValues);
        props.formik.values.segment_design.primaryData.digital_activity_index = values;
        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("digital_activity_index", controlValues)));
        }
    };

    return (
        <div>
            <div className="form-group">
                <MultiSelect
                    className={"fields"}
                    data={digitalActivityOptions}
                    autoClose={false}
                    placeholder="Select..."
                    textField="text"
                    dataItemKey="value"
                    onChange={onDigitalActivityChange}
                    size="large"
                    value={value}
                    // style={{width:"32rem"}}
                    // value={socialMatrixOptions}
                />
            </div>
        </div>
    );
}
export default DigitalActivityIndex;
