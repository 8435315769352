import React, {useEffect, useState} from "react";
import { filterBy } from "@progress/kendo-data-query";
import {getNaicsCodes} from "../../../actions/AudienceDesignActions";
import {Label} from "@progress/kendo-react-labels";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import "./LegislativeDistricts.css";

const Naics = (props) => {

    const [value, setValue] = useState([]);

    const [naicsOptions, setNaicsOptions] = useState([]);
    const [allData, setAllData] = useState([]);
    const [segmentCategory, setSegmentCategory] = useState("");
    const [segmentId, setSegmentId] = useState("");

    // Loads existing naics codes from backend into control's value
    useEffect(() => {
        // let arr = [];
        if (props.audienceState && props.audienceState.naics &&
            props.audienceState.naics.length > 0) {
            let codes = "";
            props.audienceState.naics.forEach(code => {
                codes += code + ","
            });
            const queryParam = {};
            queryParam.naics = codes;
            getNaicsCodes(queryParam).then((res) => {
                if (res) {
                    let data = [];
                    res.data.forEach((obj) => {
                        data.push({
                            text: obj.title,
                            value: obj.naics,
                        });
                    });
                    setNaicsOptions(data);
                    setValue(data);
                }
            });
        }
    }, [props.audienceState]);

    useEffect(() => {
        const queryParam = {};
        if (segmentCategory !== "") queryParam.q = segmentCategory;
        getNaicsCodes(queryParam).then((res) => {
            if (res) {
                let data = [];
                res.data.forEach((obj) => {
                    data.push({
                        text: obj.title,
                        value: obj.naics,
                    });
                });
                if (data.length > 1) {
                    let newData = data.slice();
                    newData.unshift({text:"Select All", value:"Select All"});
                    data = newData;
                }
                setNaicsOptions(data);
            }
        });
    }, [segmentCategory]);

    // useEffect(() => {
    //     let arr = [];
    //     props.audienceState.naics.forEach(optionValue => {
    //         naicsOptions.find(option => {
    //             if (option.value === Number(optionValue)) {
    //                 arr.push(option);
    //             }
    //             ;
    //         })
    //     })
    //     setValue(arr);
    // }, [naicsOptions]);

    const onNaicsFilterChange = (e) => {
        const filter = e.filter;
        if (filter.value.length < 3) return;
        if (filter.value.length === 3) {
            setSegmentCategory(filter.value.toUpperCase());
        } else {
            setSegmentCategory(filter.value.toUpperCase());
        }
        const allData = naicsOptions.slice();
        const newData = filter.value.length >= 1 ? filterBy(allData, filter) : allData;
        setNaicsOptions(newData);
    };

    const onNaicsChange = (e) => {
        let values = [];
        if (e.value.some(e  => e.text === "Select All")) {
            // selectAll();
            let allValues = [];
            let existingValues = e.value.filter(item => item.text !== "Select All");
            naicsOptions.shift();
            allValues = existingValues.concat(naicsOptions);
            setValue(allValues);
            allValues.forEach((val) => {
                values.push(val.value);
            });
            props.handleAudienceState(prevState => {
                return {...prevState, naics: values}}
            );
        } else {
            setValue(e.value)
            e.value.forEach((val) => {
                values.push(val.value);
            });
            props.handleAudienceState(prevState => {
                return {...prevState, naics: values}}
            );
            if (e.value.length === 0) setNaicsOptions([]);
        }
    };

    const selectAll = () => {
        let values = [];
        naicsOptions.shift();
        setValue(naicsOptions);
        naicsOptions.forEach((val) => {
            values.push(val.value);
        });
        props.handleAudienceState(prevState => {
            return {...prevState, naics: values}}
        );
    };

    return (
        <div>
            <div className="form-group">
              <MultiSelect
                  className={"fields"}
                  data={naicsOptions}
                  autoClose={false}
                  placeholder="Select..."
                  textField="text"
                  dataItemKey="value"
                  filterable={true}
                  onFilterChange={onNaicsFilterChange}
                  onChange={onNaicsChange}
                  size="large"
                  value={value}
                  // style={{width: "32rem"}}
              />
              <Label style={{fontSize:"14px"}}>Search by industry name or NAICS Code.  Enter at least 3 characters.</Label>
            </div>
        </div>
    );
}

export default Naics;
