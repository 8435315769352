import React, {useEffect, useState} from "react";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import {
    validateFile,
} from "../../../actions/AudienceDesignActions";
import {Label} from "@progress/kendo-react-labels";
import "./Locations.css";
import {
    SEGMENT_LOCATIONS_CITIES_API,
    SEGMENT_LOCATIONS_STATES_API,
    SEGMENT_LOCATIONS_ZIP3_API,
    SEGMENT_LOCATIONS_ZIP5_API,
    SUCCESS_STATUS
} from "../../../api/constants";
import {semcastAxios} from "../../../Utils/Common";

const Locations = (props) => {
    const [value, setValue] = useState([]);

    const [locationOptions, setLocationOptions] = useState([]);
    const FILE_INFO_MESSAGE = "File extension must be .txt.  Place each entry on a new line.  Cities must be in this format - Boston, MA";
    const [locations, setLocations] = useState([]);
    const [unknownLocations, setUnknownLocations] = useState([]);
    const [uploadMessage, setUploadMessage] = useState("");
    const [locationEntryMessage, setLocationEntryMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLocationValid, setIsLocationValid] = useState(false);
    const [isFileValid, setIsFileValid] = useState(true);
    const MAX_FILE_SIZE = 10000;
    const [isDataSetFromFile, setIsDataSetFromFile] = useState(false);
    const [currentType, setCurrentType] = useState("");

    const onFilterChange = async (event) => {
        const searchTerm = (event.filter.value.toUpperCase());
        if (searchTerm.match(/^[0-9]{3}$/g)) { //if zip3
            let url = SEGMENT_LOCATIONS_ZIP3_API + "?location=" + searchTerm + "&orderby=location";
            let response = await semcastAxios.get(url, {withCredentials: true});
            // buildOptionsList(filterBy(response.data.data), event.filter);
            buildOptionsList(response.data.data, "ZIP3");
        } else if (searchTerm.match(/^[0-9]{4,5}$/g)) { //if full or partial zip code
            let url = SEGMENT_LOCATIONS_ZIP5_API + "?location=" + searchTerm + "&orderby=location";
            let response = await semcastAxios.get(url, {withCredentials: true});
            buildOptionsList(response.data.data, "ZIP5");
        } else if (searchTerm.match(/^[a-zA-Z]{2}$/gi)) { //if 2 letters
            let url = SEGMENT_LOCATIONS_STATES_API + "?location=" + searchTerm;
            let response = await semcastAxios.get(url, {withCredentials: true});
            buildOptionsList(response.data.data, "STATE");
        } else if (searchTerm.match(/[a-zA-Z]{3,}/gi)) {
            let url = SEGMENT_LOCATIONS_CITIES_API + "?location=" + searchTerm + "&orderby=state_name,location";
            let response = await semcastAxios.get(url, {withCredentials: true});
            buildOptionsList(response.data.data, "CITY");
        }
    };

    const buildOptionsList = (data, type) => {
        const optionsList = [];
        data.forEach((item) => {
            if (type === "ZIP3" || type === "ZIP5" || type === "STATE") {
                optionsList.push(item.location);
            } else if (type === "CITY") {
                optionsList.push(item.location + ", " + item.state);
            }
        });
        setLocations([...optionsList]);
    };

    useEffect(() => {
        let arr = [];
        if (props.audienceState && props.audienceState.zip3 &&
            props.audienceState.zip3.length > 0) {
            props.audienceState.zip3.forEach(optionValue => {
                arr.push(optionValue);
            })
            setValue(arr);
        }
        if (props.audienceState && props.audienceState.zip5 &&
            props.audienceState.zip5.length > 0) {
            props.audienceState.zip5.forEach(optionValue => {
                arr.push(optionValue);
            })
            setValue(arr);
        }
        if (props.audienceState && props.audienceState.state &&
            props.audienceState.state.length > 0) {
            props.audienceState.state.forEach(optionValue => {
                arr.push(optionValue);
            })
            setValue(arr);
        }
        if (props.audienceState && props.audienceState.city &&
            props.audienceState.city.length > 0) {
            props.audienceState.city.forEach(optionValue => {
                arr.push(optionValue);
            })
            setValue(arr);
        }
    }, [props.audienceState]);

    useEffect(() => {
        if (isDataSetFromFile && value.length > 0) {
            console.log(value);
            propagateValuesToState(value);
            setIsDataSetFromFile(false);
        }
    }, [isDataSetFromFile])

    const onLocationClick = (event) => {
        console.log(event);
        const { target = {} } = event || {};
        target.value = "";
    };

    const onLocationChange = (event) => {
        if (!validateLocationTypes(event.value)) {
            setLocationEntryMessage("All locations must be of the same type (either zip3, zip5, city or state.");
        } else {
            propagateValuesToState(event.value);
            setLocationEntryMessage("");
        }
        // propagateValuesToState(event.value);
    };

    const validateLocationTypes = (locationsValue) => {
        let types = [];
        for (let location of locationsValue) {
            if (location.match(/^[0-9]{3}$/g)) {
                if (types.length > 0 && !types.includes("ZIP3")) {
                    return false;
                }
                types.push("ZIP3");
            } else if (location.match(/^[0-9]{4,5}$/g)) {
                if (types.length > 0 && !types.includes("ZIP5")) {
                    return false;
                }
                types.push("ZIP5");
            } else if (location.match(/^[a-zA-Z]{2}$/gi)) {
                if (types.length > 0 && !types.includes("STATE")) {
                    return false;
                }
                types.push("STATE");
            } else if (location.match(/[a-zA-Z]{3,}/gi)) {
                if (types.length > 0 && !types.includes("CITY")) {
                    return false;
                }
                types.push("CITY");
            }
        }
        return true;
    }

    const onFileSelected = async (event) => {
        const fileInput = event.currentTarget;
        if (!fileInput.files || !fileInput.files.length) {
            return;
        }
        var file = fileInput.files[0];
        if (file.type !== 'text/plain' || !file.name.endsWith('.txt')) {
            const fileExt = file.name.substr(file.name.lastIndexOf('.'));
            setIsFileValid(false);
            setUploadMessage("File extension must be  .txt .  This file has an extension of " + fileExt)
            fileInput.value = '';
            return;
        } else {
            setUploadMessage("");
            setIsFileValid(true);
        }
        if (file.size > MAX_FILE_SIZE) {
            const maxFileSizeInKb = (MAX_FILE_SIZE / 1000) + 'KB';
            const fileSizeInKb = Math.round(file.size / 1000) + 'KB';
            setIsFileValid(false);
            setUploadMessage("File size must be under " + maxFileSizeInKb + ".  This file has a size of " + fileSizeInKb + ".");
            fileInput.value = '';
            return;
        } else {
            setIsFileValid(true);
        }
        const bodyFormData = new FormData();
        bodyFormData.append("file", file, file.name);
        const response = await validateFile(bodyFormData);
        if (response.status === SUCCESS_STATUS) {
            const data = response.data;
            let values = [];
            let unknownLocs = [];
            for (let key in data) {
                const obj = data[key];
                const {unknowns} = obj;
                if (unknowns) {
                    unknownLocs = unknownLocs.concat(unknowns);
                }
                const {exists} = obj;
                if (exists) {
                    values = values.concat(exists);
                }
            }
            const additionalValues = value.concat(values);
            const uniqueValues = [...new Set(additionalValues)];
            const newValues = Array.from(uniqueValues);
            if (!validateLocationTypes(newValues)) {
                setLocationEntryMessage("All locations must be of the same type (either zip3, zip5, city or state.");
                return;
            }
            setLocationEntryMessage("");
            setValue([...newValues]);
            const newUnknownLocations = unknownLocations.concat(unknownLocs);
            setUnknownLocations([...newUnknownLocations]);
            setIsLocationValid(true);
            setIsDataSetFromFile(true);
        }
        console.log(response);
    };

    const propagateValuesToState = (value) => {
        if (props.formik) {
            props.formik.values.segment_design.primaryData.zip3 = [];
            props.formik.values.segment_design.primaryData.zip5 = [];
            props.formik.values.segment_design.primaryData.state = [];
            props.formik.values.segment_design.primaryData.city = [];
            setValue([...value]);
            if (value.length === 0) {
                setIsLocationValid(false);
                setLocations([]);
                if (props.controlsState) {
                    props.controlsState.delete("locations");
                }
            } else {
                setIsLocationValid(true);
                props.handleControlsState(new Map(props.controlsState.set("locations", value)));
                value.map((location) => {
                    if (location.match(/^[0-9]{3}$/g)) {
                        props.formik.values.segment_design.primaryData.zip3.push(location);
                    } else if (location.match(/^[0-9]{4,5}$/g)) {
                        props.formik.values.segment_design.primaryData.zip5.push(location);
                    } else if (location.match(/^[a-zA-Z]{2}$/gi)) {
                        props.formik.values.segment_design.primaryData.state.push(location);
                    } else if (location.match(/[a-zA-Z]{3,}/gi)) {
                        props.formik.values.segment_design.primaryData.city.push(location);
                    }
                });
            }
            console.log(props.formik.values.segment_design.primaryData);
        }
        if (props.audienceState) {
            props.audienceState.zip3 = [];
            props.audienceState.zip5 = [];
            props.audienceState.state = [];
            props.audienceState.city = [];
            setValue([...value]);
            if (value.length === 0) {
                setIsLocationValid(false);
                setLocations([]);
                props.audienceState.zip3 = [];
                props.audienceState.zip5 = [];
                props.audienceState.state = [];
                props.audienceState.city = [];
            } else {
                setIsLocationValid(true);
                let zip3 = [];
                let zip5 = [];
                let state = [];
                let city = [];
                value.map((location) => {
                    if (location.match(/^[0-9]{3}$/g)) {
                        zip3.push(location);
                        // props.audienceState.zip3 = locValue;
                    } else if (location.match(/^[0-9]{4,5}$/g)) {
                        zip5.push(location);
                        // props.audienceState.zip5 = locValue;
                    } else if (location.match(/^[a-zA-Z]{2}$/gi)) {
                        state.push(location);
                        // props.audienceState.state = locValue;
                    } else if (location.match(/[a-zA-Z]{3,}/gi)) {
                        city.push(location);
                        // props.audienceState.city = locValue;
                    }
                });
                props.handleAudienceState(prevState => {
                        return {...prevState, zip3: zip3, zip5: zip5, state: state, city: city}
                    }
                );
            }
        }
    }

    // const validateFile = async (file) => {
    //   try {
    //     const bodyFormData = new FormData();
    //     bodyFormData.append("file", file, file.name);
    //     const response = await semcastAxios.post(FILE_VALIDATE_API, bodyFormData, {
    //       withCredentials: true,
    //       headers: {"Content-Type": "multipart/form-data"},
    //
    //     });
    //     return response;
    //   } catch (e) {
    //     console.log(e)
    //   }
    // };

    const uploadFile = (event) => {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        validateFile(formData).then((res) => {
            if (res && res.data) {
                let values = [];
                const {data} = res;
                for (var key in data) {
                    if (key === "unknowns") {
                        continue;
                    }
                    var obj = data[key];
                    if (obj.hasOwnProperty("exists")) {
                        values = values.concat(obj.exists);
                    }
                }
                setLocationOptions(values.map((c) => ({value: c, label: c})));
                console.log(values);
                // setValue("segment_design.primaryData.city", values);
            }
        });
    };

    return (
        <>
            <div className={"form-group"}>
                <MultiSelect
                    className={"fields"}
                    data={locations}
                    autoClose={false}
                    filterable={true}
                    onFilterChange={onFilterChange}
                    onChange={onLocationChange}
                    value={value}
                    placeholder="Enter State Abbreviation, City, Zip3 or Zip Code..."
                    // size="large"
                    style={{width: "83.5%"}}
                />
                {/*<Button*/}
                {/*    // icon="folder"*/}
                {/*    iconClass="fas fa-upload"*/}
                {/*    // style={{*/}
                {/*    //   borderColor: !isFileValid ? "red" : ""*/}
                {/*    // }}*/}
                {/*    onClick={() => fileRef.current.click()}*/}
                {/*    style={{width: "8rem"}}*/}
                {/*>Upload File*/}
                {/*    /!*<Label style={{fontSize: "12px"}}>Upload File</Label>*!/*/}
                {/*    /!*<Label style={{fontSize: "16px", fontFamily: "Helvetica, Arial, \"Roboto\", sans-serif"}}>Upload</Label>*!/*/}
                {/*</Button>*/}
                <input
                    id={"file-upload"}
                    className={"file-input"}
                    onChange={onFileSelected}
                    onClick={onLocationClick}
                    multiple={false}
                    type="file"
                    hidden
                />
                <label className="file-upload" for="file-upload">
                    <i className="fas fa-upload" style={{paddingRight:".3rem"}}></i>
                    Upload File
                </label>
                <Label className={"hint-standard"}>{FILE_INFO_MESSAGE}</Label>
                <Label className={"error-message"}>{locationEntryMessage}</Label>
                <Label className={"error-message"}>{uploadMessage}</Label>
            </div>
        </>
    );
}
export default Locations;
