import React, {useState, useEffect, useContext} from "react";
import {Drawer, DrawerContent, DrawerItem} from '@progress/kendo-react-layout';
import {useHistory, withRouter} from 'react-router-dom';
import {CONTACT_US_ROUTE, SIGN_OUT_API} from "../../api/constants";
import {removeDataFromStorage, semcastAxios} from "../../Utils/Common";
import SideNavigationBarHeader from "./SideNavigationBarHeader";
import { Tooltip } from "@progress/kendo-react-tooltip";
// import * as shepherd from "shepherd.js";

import "./SideNavigationBar.css";
import {ShepherdTourContext, useShepherdTour,ShepherdTour} from "react-shepherd";
import {
    adminsettingsTourSteps,
    audiencedesignTourSteps,
    dashboardTourSteps,
    onboardingTourSteps,
    tourOptions
} from "../Pages/GuidedTour"
import {UserContext} from "../../contexts/UserContext";
import Shepherd from "shepherd.js";


const SideNavigationBar = (props) => {
    const [adminExpanded, setAdminExpanded] = useState(false);
    let history = useHistory();

    const {user, setUser} = useContext(UserContext);

    const dashboardTour = useShepherdTour({ tourOptions, steps: dashboardTourSteps });
    const onboardingTour = useShepherdTour({ tourOptions, steps: onboardingTourSteps });
    const audiencedesignTour = useShepherdTour({ tourOptions, steps: audiencedesignTourSteps });
    const adminsettingsTour = useShepherdTour({ tourOptions, steps: adminsettingsTourSteps });
    let pathname = window.location.pathname;


    const CustomItem = itemProps => {
        const {
            visible,
            ...others
        } = itemProps;
        if (itemProps.id === 4) {
            console.log(itemProps);
            if (props.drawerExpanded) {
                return <React.Fragment>
                    <DrawerItem {...others}>
                        <div id={"enhancements-icon"}>
                                <span className={"k-item-text"} >
                                    <span className={"k-icon"} >
                                        <svg aria-hidden="true" data-prefix="fal" data-icon="toolbox" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                             className="svg-inline--fa fa-toolbox fa-w-16 fa-7x"><path
                                            fill="currentColor"
                                            d="M502.63 214.63l-45.25-45.26c-6-6-14.14-9.37-22.63-9.37H384V80c0-26.47-21.53-48-48-48H176c-26.47 0-48 21.53-48 48v80H77.25c-8.49 0-16.62 3.37-22.63 9.37L9.37 214.63c-6 6-9.37 14.14-9.37 22.63V448c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32V237.25c0-8.48-3.37-16.62-9.37-22.62zM160 80c0-8.83 7.19-16 16-16h160c8.81 0 16 7.17 16 16v80H160V80zm320 368H32v-96h96v24c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-24h192v24c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-24h96v96zm-96-128v-24c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v24H160v-24c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v24H32v-82.75L77.25 192h357.49L480 237.25V320h-96z"
                                            className=""></path></svg>
                                    </span>
                                                          Enhancement
                                </span>
                        </div>
                    </DrawerItem>
                </React.Fragment>;
            } else {
                return <React.Fragment>
                    <DrawerItem {...others} >
                        <Tooltip tooltipClassName={"side-nav-item-tooltip"}>
                           <span title={"Enhancement"} className={"k-item-text"}>
                                <span className={"k-icon"}>
                                    <svg title={"Enhancement"} aria-hidden="true" data-prefix="fal" data-icon="toolbox" role="img"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                         className="svg-inline--fa fa-toolbox fa-w-16 fa-7x"><path fill="currentColor"
                                                                                                   d="M502.63 214.63l-45.25-45.26c-6-6-14.14-9.37-22.63-9.37H384V80c0-26.47-21.53-48-48-48H176c-26.47 0-48 21.53-48 48v80H77.25c-8.49 0-16.62 3.37-22.63 9.37L9.37 214.63c-6 6-9.37 14.14-9.37 22.63V448c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32V237.25c0-8.48-3.37-16.62-9.37-22.62zM160 80c0-8.83 7.19-16 16-16h160c8.81 0 16 7.17 16 16v80H160V80zm320 368H32v-96h96v24c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-24h192v24c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-24h96v96zm-96-128v-24c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v24H160v-24c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v24H32v-82.75L77.25 192h357.49L480 237.25V320h-96z"
                                                                                                   className=""></path></svg>
                                </span>
                            </span>
                        </Tooltip>
                    </DrawerItem>
                </React.Fragment>;
            }
        }
        if (itemProps.id === 5) {
            if (itemProps['data-expanded'] === true) {
                setAdminExpanded(true);
            } else {
                setAdminExpanded(false);
            }
        }
        if (itemProps.id === 11) {
            if (props.drawerExpanded) {
                return <React.Fragment>
                    {props.visible === false ? null : <DrawerItem {...others}>
                        <div className={"contactUsSidebar"}>
                            <div className="copyright" style={{textAlign:"center"}}>
                                <p style={{color:"lightgrey"}}>
                                    ©2024 All Rights Reserved
                                </p>
                            </div>
                        </div>
                    </DrawerItem>}
                </React.Fragment>;
            } else {
                return null;
            }
        }
        if (itemProps.id === 12) {
            if (props.drawerExpanded) {
                return <React.Fragment>
                    <DrawerItem {...others}>
                        <div title={"Start Tour"} id={"guided-tour-icon"}>
                            <span className={"k-item-text"} title={"Start Tour"}>
                                <span className={"k-icon"} title={"Start Tour"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                         className="bi bi-signpost-fill" viewBox="0 0 16 16" >
                                        <path
                                        d="M7.293.707A1 1 0 0 0 7 1.414V4H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h5v6h2v-6h3.532a1 1 0 0 0 .768-.36l1.933-2.32a.5.5 0 0 0 0-.64L13.3 4.36a1 1 0 0 0-.768-.36H9V1.414A1 1 0 0 0 7.293.707"/>
                                    </svg>
                                </span>
                                                      Start Tour
                            </span>
                        </div>
                    </DrawerItem>
                </React.Fragment>;
            } else {
                return <React.Fragment>
                    <DrawerItem {...others}>
                        <Tooltip tooltipClassName={"side-nav-item-tooltip"}>
                           <span className={"k-item-text"} title={"Start Tour"}>
                                <span className={"k-icon"}>
                                    <svg title={"Start Tour"} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-signpost-fill"
                                         viewBox="0 0 16 16">
                                      <path
                                          d="M7.293.707A1 1 0 0 0 7 1.414V4H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h5v6h2v-6h3.532a1 1 0 0 0 .768-.36l1.933-2.32a.5.5 0 0 0 0-.64L13.3 4.36a1 1 0 0 0-.768-.36H9V1.414A1 1 0 0 0 7.293.707"/>
                                    </svg>
                                </span>
                            </span>
                        </Tooltip>
                    </DrawerItem>
                </React.Fragment>;
            }
        }
        const arrowDir = itemProps['data-expanded'] ? 'k-i-arrow-chevron-down' : 'k-i-arrow-chevron-right';
        return <React.Fragment>
            {itemProps.visible === false ? null : <DrawerItem {...others}>

                <Tooltip tooltipClassName={"side-nav-item-tooltip"}><span className={'k-item-text'}><span title={itemProps.text ? "" : itemProps.tipText} className={'k-icon ' + itemProps.icon} />{itemProps.text}</span></Tooltip>
                {itemProps['data-expanded'] !== undefined && <span className={"k-icon " + arrowDir} style={{
                    position: "relative",
                    right: 0
                }} />}
            </DrawerItem>}
        </React.Fragment>;
    };
    const determineActivateMediaRoute = () => {
        if (localStorage.getItem("selectedSegmentId")) {
            const selectedSegmentId = localStorage.getItem("selectedSegmentId");
            localStorage.removeItem("selectedSegmentId");
            return "/segmentation/activate/" + selectedSegmentId;
        } else {
            return "/activate-media";
        }
    }

    // If the user is an ADS ToolBox user...
    useEffect(() => {
        if (user?.canViewToolBox) {
            const newData = [
                {
                    text: 'Dashboard',
                    icon: 'icon-dashboard',
                    id: 1,
                    selected: true,
                    route: '/segmentation-dashboard',
                    tipText: 'Dashboard'
                    // style: {paddingLeft:"1.5rem", paddingTop:"1rem"}
                }, {
                    text: 'Onboarding',
                    icon: 'icon-onboard-sam',
                    id: 2,
                    route: '/onboarding',
                    tipText: 'Onboarding'
                    // style: {paddingLeft:"1.5rem"}
                }, {
                    text: 'Audience Design',
                    icon: 'icon-add-design',
                    id: 3,
                    route: '/audience-design',
                    tipText: 'Audience Design'
                },
                {
                    text: 'Enhancement',
                    id: 4,
                    route: '/data-enhancement',
                    tipText: 'Enhancement'
                },
                {
                    text: 'Admin Settings',
                    icon: 'icon-settings',
                    id: 5,
                    route: '/admin-settings',
                    tipText: 'Admin Settings'
                    // style: {paddingLeft:"1.5rem"}
                },
                {
                    text: 'Help Center',
                    icon: 'k-i-info-circle',
                    id: 10,
                    route: "/help-center",
                    tipText: 'Help Center'
                },
                {
                    text: 'Sign Out',
                    icon: 'icon-logout',
                    id: 9,
                    tipText: 'Sign Out'
                },
                {
                    text: 'Start Tour',
                    tipText: 'Start Tour',
                    id: 12,
                    style: {position: "absolute", bottom: 50, backgroundColor: "none", alignItems: "center"},
                },
                {
                    id: 11,
                    style: {position: "absolute", bottom: 0, backgroundColor: "none"},
                    className: 'contact-us-class'
                }
            ];
            setItems(newData);
        } else {
            const newData = [
                {
                    text: 'Dashboard',
                    icon: 'icon-dashboard',
                    id: 1,
                    selected: true,
                    route: '/segmentation-dashboard',
                    tipText: 'Dashboard'
                    // style: {paddingLeft:"1.5rem", paddingTop:"1rem"}
                }, {
                    text: 'Onboarding',
                    icon: 'icon-onboard-sam',
                    id: 2,
                    route: '/onboarding',
                    tipText: 'Onboarding'
                    // style: {paddingLeft:"1.5rem"}
                }, {
                    text: 'Audience Design',
                    icon: 'icon-add-design',
                    id: 3,
                    route: '/audience-design',
                    tipText: 'Audience Design'
                },
                {
                    text: 'Admin Settings',
                    icon: 'icon-settings',
                    id: 5,
                    route: '/admin-settings',
                    tipText: 'Admin Settings'
                    // style: {paddingLeft:"1.5rem"}
                },
                {
                    text: 'Help Center',
                    icon: 'k-i-info-circle',
                    id: 10,
                    route: "/help-center",
                    tipText: 'Help Center'
                },
                {
                    text: 'Sign Out',
                    icon: 'icon-logout',
                    id: 9,
                    tipText: 'Sign Out'
                },
                {
                    text: 'Start Tour',
                    tipText: 'Start Tour',
                    id: 12,
                    style: {position: "absolute", bottom: 50, backgroundColor: "none", alignItems: "center"},
                },
                {
                    id: 11,
                    style: {position: "absolute", bottom: 0, backgroundColor: "none"},
                    className: 'contact-us-class'
                }
            ];
            setItems(newData);
        }
    }, [user.canViewToolBox])

    const [items, setItems] = useState([
        {
            text: 'Dashboard',
            icon: 'icon-dashboard',
            id: 1,
            selected: true,
            route: '/segmentation-dashboard',
            tipText: 'Dashboard'
            // style: {paddingLeft:"1.5rem", paddingTop:"1rem"}
        }, {
            text: 'Onboarding',
            icon: 'icon-onboard-sam',
            id: 2,
            route: '/onboarding',
            tipText: 'Onboarding'
            // style: {paddingLeft:"1.5rem"}
        }, {
            text: 'Audience Design',
            icon: 'icon-add-design',
            id: 3,
            route: '/audience-design',
            tipText: 'Audience Design'
        },
        {
            text: 'Admin Settings',
            icon: 'icon-settings',
            id: 5,
            route: '/admin-settings',
            tipText: 'Admin Settings'
            // style: {paddingLeft:"1.5rem"}
        },
        {
            text: 'Help Center',
            icon: 'k-i-info-circle',
            id: 10,
            route: "/help-center",
            tipText: 'Help Center'
        },
        {
            text: 'Sign Out',
            icon: 'icon-logout',
            id: 9,
            tipText: 'Sign Out'
        },
        {
            text: 'Start Tour',
            tipText: 'Start Tour',
            id: 12,
            style: {position: "absolute", bottom: 50, backgroundColor: "none", alignItems: "center"},
        },
        {
            id: 11,
            style: {position: "absolute", bottom: 0, backgroundColor: "none"},
            className: 'contact-us-class'
        }
    ]);

    const onSelect = (ev) => {
        const currentItem = ev.itemTarget.props;
        if (currentItem.text === "Sign Out" || currentItem.tipText === "Sign Out") {
            signOut(ev);
        }
        const isParent = currentItem["data-expanded"] !== undefined;
        const nextExpanded = !currentItem["data-expanded"];
        const newData = items.map((item) => {
            const {
                selected,
                ["data-expanded"]: currentExpanded,
                id,
                ...others
            } = item;
            const isCurrentItem = currentItem.id === id;
            return {
                selected: isCurrentItem,
                ["data-expanded"]:
                    isCurrentItem && isParent ? nextExpanded : currentExpanded,
                id,
                ...others,
            };
        });
        if (currentItem.id === 12 && pathname.localeCompare("/segmentation-dashboard") === 0 ) {
            // console.log("1");
            dashboardTour.start();
        }else if(currentItem.id === 12 && pathname.localeCompare("/onboarding") === 0){
            // console.log("2");
            onboardingTour.start();
        }else if(currentItem.id === 12 && pathname.localeCompare("/audience-design") === 0){
            // console.log("3");
            audiencedesignTour.start();
        }else if(currentItem.id === 12 && pathname.localeCompare("/admin-settings") === 0){
            // console.log("4");
            adminsettingsTour.start();
        }else if(currentItem.id === 12 && ev.itemTarget.props.route === undefined){
                return
        }
        props.history.push(ev.itemTarget.props.route);
        // if (localStorage.getItem("selectedSegmentId")) {
        //     const selectedSegmentId = localStorage.getItem("selectedSegmentId");
        //     newData[3].route = "/segmentation/activate/" + selectedSegmentId;
        //     props.history.push("/segmentation/activate/" + selectedSegmentId);
        // } else {
        //     newData[3].route =  "/activate-media";
        //     props.history.push(ev.itemTarget.props.route);
        // }
        setItems(newData);
        localStorage.removeItem("selectedSegmentId");
    };

    const signOut = async (e) => {
        // e.preventDefault();
        try {
            await semcastAxios.get(SIGN_OUT_API, {withCredentials: true});
            removeDataFromStorage();

            // reset login attempts cookie
            document.cookie = "attempts=0";

            window.location.href = "/"
            // history.push("/");
        } catch (e) {
        }
    };

    const setSelectedItem = pathName => {
        let currentPath = items.find(item => item.route === pathName);

        if (currentPath.text) {
            return currentPath.text;
        }
    };
    let selected = setSelectedItem("/segmentation-dashboard");
    // setDrawerExpanded(!props.menuToggle);

    const data = items.map((item) => {
        const {parentId, ...others} = item;
        if (parentId !== undefined) {
            const parent = items.find((parent) => parent.id === parentId);
            if (!props.drawerExpanded) {
                return {...others, text: '', visible: parent["data-expanded"]};
            } else {
                return {...others, visible: parent["data-expanded"]};
            }
        }
        if (!props.drawerExpanded) {
            if (item.text === "Activate Media") {
                return {text: '', icon: item.icon, id: item.id, disabled: true, selected: item.selected, route: item.route, tipText: item.tipText};
            }
            return {text: '', icon: item.icon, id: item.id, selected: item.selected, route: item.route, tipText: item.tipText};
        } else {
            // 09-20-23 - RWK - Disable until page route is available
            if (item.text === "Activate Media") {
                item.disabled = true;
            }
            return item;
        }
    });

    return (
        <>
        <div>
            <SideNavigationBarHeader drawerExpanded={props.drawerExpanded} />
        </div>
        <div className="side-nav-item-wrapper">
            <Drawer
                expanded={props.drawerExpanded}
                mode={'push'}
                mini={true}
                miniWidth={65}
                width={235}
                items={data}
                item={CustomItem}
                onSelect={onSelect} style={{height:"80rem"}}>
                <DrawerContent>
                    {props.children}
                </DrawerContent>
            </Drawer>
        </div>
        </>
    )

}

export default withRouter(SideNavigationBar);